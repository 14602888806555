import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Link,
  } from 'framework7-react';

  export default class QuickAddHawker extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        phone:'',
        status:1,
        remarks:'',
        frmsuccess:false,
        resid:'',
        resname:'',
      };

      this.handleUserInput = this.handleUserInput.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      });

    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    saveDetail(){
      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const toastWithButton = app.toast.create({
        text: 'Please enter all required field.',
        closeButton: true,
        closeTimeout: 3000,
      });

      app.input.validateInputs('.adddata');

      if(this.state.name === "")
      {
        toastWithButton.open();
        return false;
      }

      const bodyFormData = {
          Mode:'AddHawker',
          clientid:self.state.clientdetail.id,
          name:self.state.name,
          phone:self.state.phone,
          status:Number(self.state.status),
          remarks:self.state.remarks,
      };

      app.preloader.show();

      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                      name:'',
                      phone:'',
                      status:1,
                      remarks:'',
                      frmsuccess:true,
                      resid:resdata.recordid,
                      resname:resdata.name,
                    }
                })
              ])
              .then(() => {

                this.Dom7('.item-content').removeClass('item-input-with-error-message');
                this.Dom7('.item-content').removeClass('item-input-invalid');
  
              });

            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){

      const{resid, resname, frmsuccess} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{setHawkerData}  = value;

          if(frmsuccess === true)
          {
            Promise.all([
              setTimeout(()=> {

                setHawkerData(Number(resid), resname, true);
    
              }, 500)
              
            ])
            .then(() => {

              setTimeout(()=> {

                this.$$('.popup-close').trigger('click');
    
              }, 500)
              
            })
          }

          return(
            <Popup className="demo-popup">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link popupClose>
                      <i className="icon icon-back"></i>                      
                    </Link>
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                  </NavLeft>
                  <NavTitle>Add New Hawker</NavTitle>
                </Navbar>
                <Page pageContent={false} className="page" name="QuickAddHawker">
                    <PageContent>
                    <Block>
                    <List noHairlinesMd>

                        <ListInput
                            name="name"
                            className="adddata"
                            label="Name"
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="Name"
                            value={this.state.name}
                            clearButton required validate
                        >
                        </ListInput>
                    
                        <ListInput
                            name="phone"
                            label="Phone Number"
                            floatingLabel
                            type="tel"
                            onInput={(e)=>{
                        
                              let tempphone = e.target.value;
                              let mobile    = tempphone.replace(/ /g,'');
                              
                              if(tempphone.slice(0,1)=='+')
                              {
                                mobile = mobile.substring(3);
                              }
      
                              if(tempphone.slice(0,1)=='0')
                              {
                                mobile = mobile.substring(1);
                              }
      
                              if(mobile.length > 10)
                              {
                                return;
                              }
                              
                              this.setState({
                                phone:mobile
                              })
                            }}
                            placeholder="Phone number"
                            value={this.state.phone}
                            clearButton validate
                        >
                        </ListInput>

                        <ListItem title="Status">
                          <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                    </List>
                  </Block>
                  <br /><br />
                  </PageContent>
                  <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
              </Page>
            </Popup>
          );
        }}
        </FilterConsumer>
      );
    }
}