import React,{Component,Fragment} from 'react';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer, FilterContext } from '../context/FilterContext';

import {
  Page,
  LoginScreenTitle,
  List,
  ListButton,
  Button,
  Popup,
  Row,
  Col,
  Icon,
  Navbar,
  NavRight,
  Link
} from 'framework7-react';

export default class extends Component {
    constructor(props) {
      super(props);
  
      this.state = {

        type:'',
        addedcontact:this.props.f7route.params.addedcontact,
        oldcontact:this.props.f7route.params.oldcontact,
        totalcontact:this.props.f7route.params.totalcontact,
        isdatareset:false,
        buttontext:'',
        filtervars:[],

      };

    }

    componentDidMount(){

        this.setState(()=>{
            return{
                type:this.props.f7route.params.type,
                filtervars:this.props.$f7route.query
            }
        },()=>{

            const{type} = this.state;

            let addmorebtntext  = "";

            if(type === "addcustomersuccess")
            {
                addmorebtntext  = "Add More Customer";
            }
            else if(type === "addpaymentsuccess")
            {
                addmorebtntext  = "Close";
            }
            else if(type === "generateinvoicesuccess")
            {
                addmorebtntext  = "Add More Bill";
            }
            else if(type === "addpurchasesuccess")
            {
                addmorebtntext  = "Add More Purchase";
            }
            else if(type === "addsalesuccess")
            {
                addmorebtntext  = "Add More Sale";
            }
            else if(type === "sendmessagesuccess")
            {
                addmorebtntext  = "Send More Message";
            }
            else if(type === "addmanagersuccess")
            {
                addmorebtntext  = "Add More Manager";
            }
            else if(type === "addareasuccess")
            {
                addmorebtntext  = "Add More Area";
            }
            else if(type === "addlinesuccess")
            {
                addmorebtntext  = "Add More Line";
            }
            else if(type === "addsublinesuccess")
            {
                addmorebtntext  = "Add More Sub Line";
            }
            else if(type === "addlinemansuccess")
            {
                addmorebtntext  = "Add More Lineman";
            }
            else if(type === "addhawkersuccess")
            {
                addmorebtntext  = "Add More Hawker";
            }
            else if(type === "addholidaysuccess")
            {
                addmorebtntext  = "Add More Holiday";
            }
            else if(type === "adddroppingpointsuccess")
            {
                addmorebtntext  = "Add More Dropping Point";
            }
            else if(type === "addstocksuccess")
            {
                addmorebtntext  = "Add More Stock";
            }
            else if(type === "addgallerysuccess")
            {
                addmorebtntext  = "Add More Image";
            }
            else if(type === "updatewebsitesuccess")
            {
                addmorebtntext  = "Update Website";
            }            
            else if(type === "addcampaignsuccess" || type === "resendcampaignsuccess")
            {
                addmorebtntext  = "Add More Message";
            }
            else if(type === "addsmscreditsuccess")
            {
                addmorebtntext  = "Add More SMS Credit";
            }
            this.setState({
                buttontext:addmorebtntext
            });
        })

    }
      
    render() {
        
    const{type, buttontext, isdatareset} = this.state;

    return(
        <FilterConsumer>{(value)=>{
            const{setDroppingPointData}  = value;
            
            if(type === "addareasuccess" && !isdatareset)
            {
                setTimeout(()=> {
                    this.setState({isdatareset:true},()=>{
                        setDroppingPointData('','Select', false);
                    })
                }, 500);
            }

            return (
                <Popup>
                    <Page noToolbar noNavbar noSwipeback loginScreen>
                        {
                            type === "addpaymentsuccess" ? (
                                <Navbar sliding={true}>
                                    <NavRight>
                                        <Link href="#"
                                        onClick={()=>{

                                            let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker, page, fromarea, openingbalanceid, filteropeningbalance, nameandphone} = this.state.filtervars;

                                            if(Number(areaid) < 1 || areaid === "NaN")
                                            {
                                              areaid      = "";
                                              filterarea  = "";
                                            }
                              
                                            if(Number(lineid) < 1 || lineid === "NaN")
                                            {
                                              lineid      = "";
                                              filterline  = "";
                                            }
                                            
                                            if(Number(hawkerid) < 1 || hawkerid === "NaN")
                                            {
                                              hawkerid      = "";
                                              filterhawker  = "";
                                            }

                                            if(Number(openingbalanceid) < 1 || openingbalanceid === "NaN" || openingbalanceid === "undefined" || openingbalanceid === undefined)
                                            {
                                              openingbalanceid      = "";
                                              filteropeningbalance  = "Select";
                                            }
                                      
                                            if(nameandphone === "" || nameandphone === undefined)
                                            {
                                              nameandphone  = "";
                                            }
                                    
                                            if(Number(page) < 1 || page === "NaN" || page === undefined)
                                            {
                                              page      = "";
                                            }
                                    
                                            if(fromarea === "" || fromarea === undefined)
                                            {
                                              fromarea  = "";
                                            }

                                            if(fromarea === 'managecustomer')
                                            {
                                                this.$f7.views.main.router.back({
                                                    url:`/managecustomers/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&nameandphone=${nameandphone}&page=${page}&fromarea=${fromarea}`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                                this.$f7.views.main.router.navigate(`/managecustomers/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&nameandphone=${nameandphone}&page=${page}&fromarea=${fromarea}`);
                                            }
                                            else
                                            {
                                                this.$f7.views.main.router.back({
                                                    url:`/customersoutstanding/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}`,
                                                    options:{
                                                        force:true
                                                    }
                                                });
                                                this.$f7.views.main.router.navigate(`/customersoutstanding/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}`);
                                            }
                                            
                                        }}>Close</Link>
                                    </NavRight>
                                </Navbar>
                            ):null
                        }
                        <Row>
                            <Col>
                                <img className="loginlogo" src={scanlogo} alt="logo" />
                            </Col>
                        </Row>
                        <LoginScreenTitle>Success</LoginScreenTitle>
                        <Row noGap>
                            <Col width="100" style={{textAlign:'center'}}>
                                <Icon f7="checkmark_alt_circle_fill" style={{color:'green',fontSize:'3rem'}}></Icon>
                            </Col>
                        </Row>
                        {(()=>{
                            if(type === "addcustomersuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Customer added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "generateinvoicesuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Bills generated successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addpurchasesuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Purchase added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addstocksuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Stock added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addgallerysuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Image added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "updatewebsitesuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Website detail successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addsalesuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Sale added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "sendmessagesuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Message sent successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addmanagersuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Manager added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addareasuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Area added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "adddroppingpointsuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Dropping point added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "resendcampaignsuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Campaign resend successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addlinesuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Line added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addsublinesuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Sub Line added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addlinemansuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Lineman added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addhawkersuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Hawker added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addholidaysuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Holiday added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addcampaignsuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Message added successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "resendcampaignsuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            Message resend successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addsmscreditsuccess")
                            {
                                return(
                                    <Row noGap>
                                        <Col width="10"></Col>
                                        <Col width="80" style={{textAlign:'center'}}>
                                            <br />
                                            SMS credit request sent successfully.
                                        </Col>
                                        <Col width="10"></Col>
                                    </Row>
                                )
                            }
                        })()}
                        <br />
                        <Row>
                            <Col width="20"></Col>
                            <Col width="60">
                            {
                                type === "addpaymentsuccess" ? (
                                    <Button fill color="blue"
                                    onClick={()=>{

                                        let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker, page, fromarea, openingbalanceid, filteropeningbalance, nameandphone} = this.state.filtervars;

                                        if(Number(areaid) < 1 || areaid === "NaN")
                                        {
                                          areaid      = "";
                                          filterarea  = "";
                                        }
                          
                                        if(Number(lineid) < 1 || lineid === "NaN")
                                        {
                                          lineid      = "";
                                          filterline  = "";
                                        }
                                        
                                        if(Number(hawkerid) < 1 || hawkerid === "NaN")
                                        {
                                          hawkerid      = "";
                                          filterhawker  = "";
                                        }
                          
                                        if(Number(openingbalanceid) < 1 || openingbalanceid === "NaN" || openingbalanceid === "undefined" || openingbalanceid === undefined)
                                        {
                                          openingbalanceid      = "";
                                          filteropeningbalance  = "Select";
                                        }
                                  
                                        if(nameandphone === "" || nameandphone === undefined)
                                        {
                                          nameandphone  = "";
                                        }
                                
                                        if(Number(page) < 1 || page === "NaN" || page === undefined)
                                        {
                                          page      = "";
                                        }
                                
                                        if(fromarea === "" || fromarea === undefined)
                                        {
                                          fromarea  = "";
                                        }

                                        if(fromarea === 'managecustomer')
                                        {
                                            this.$f7.views.main.router.back({
                                                url:`/managecustomers/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&nameandphone=${nameandphone}&page=${page}&fromarea=${fromarea}`,
                                                options:{
                                                    force:true
                                                }
                                            });
                                            this.$f7.views.main.router.navigate(`/managecustomers/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&nameandphone=${nameandphone}&page=${page}&fromarea=${fromarea}`);
                                        }
                                        else
                                        {
                                            this.$f7.views.main.router.back({
                                                url:`/customersoutstanding/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}`,
                                                options:{
                                                    force:true
                                                }
                                            });
                                            this.$f7.views.main.router.navigate(`/customersoutstanding/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}`);
                                        }

                                    }}>{buttontext}</Button>
                                ):(<Button fill color="blue" popupClose>{buttontext}</Button>)
                            }
                            </Col>
                            <Col width="20"></Col>
                        </Row>
                        <br /><br />
                        {(()=>{
                            if(type === "addcustomersuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                    this.$f7.views.main.router.back({
                                                        url:`/managecustomers/`,
                                                        options:{
                                                            force:true
                                                        }
                                                    });
                                                    this.$f7.views.main.router.navigate(`/managecustomers/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Customers <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addpaymentsuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/payments/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/payments/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Payments <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "generateinvoicesuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/generateinvoice/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/generateinvoice/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Generate Bill <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addpurchasesuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                    this.$f7.views.main.router.back({
                                                            url:`/purchase/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                    this.$f7.views.main.router.navigate(`/purchase/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Purchase <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addstocksuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                    this.$f7.views.main.router.back({
                                                            url:`/stock/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                    this.$f7.views.main.router.navigate(`/stock/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Stock <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addgallerysuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                    this.$f7.views.main.router.back({
                                                            url:`/gallery/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                    this.$f7.views.main.router.navigate(`/gallery/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Gallery <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "updatewebsitesuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                    this.$f7.views.main.router.back({
                                                            url:`/aboutus/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/aboutus/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Website <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addsalesuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/sale/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                    this.$f7.views.main.router.navigate(`/sale/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Sale <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "sendmessagesuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/messages/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                    this.$f7.views.main.router.navigate(`/messages/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Messages <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addmanagersuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/areamanagers/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/areamanagers/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Area Manager <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addareasuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/areas/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/areas/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Area <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "adddroppingpointsuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/droppingpoint/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/droppingpoint/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Dropping Point <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addlinesuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/lines/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/lines/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Lines <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addsublinesuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/sublines/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/sublines/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Sub-Line <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addlinemansuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/lineman/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/lineman/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Lineman <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addhawkersuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/hawkers/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/hawkers/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Hawker <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addholidaysuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/holiday/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                        this.$f7.views.main.router.navigate(`/holiday/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage Holiday <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addcampaignsuccess" || type === "resendcampaignsuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/messages/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                    this.$f7.views.main.router.navigate(`/messages/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to Manage SMS <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                            else if(type === "addsmscreditsuccess")
                            {
                                return(
                                    <Row>
                                        <Col width="20"></Col>
                                        <Col width="60">
                                            <List style={{marginTop:'0px',marginBottom:'0px'}} className="thankspage">
                                                <ListButton
                                                    onClick={()=>{
                                                        this.$f7.views.main.router.back({
                                                            url:`/smscredits/`,
                                                            options:{
                                                                force:true
                                                            }
                                                        });
                                                    this.$f7.views.main.router.navigate(`/smscredits/`);
                                                    }}
                                                    style={{color:'green !important'}}>Go to SMS Credit <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                            </List>
                                        </Col>
                                        <Col width="20"></Col>
                                    </Row>
                                )
                            }
                        })()}
                        <Row>
                            <Col width="20"></Col>
                            <Col width="60">
                                <List style={{marginTop:'0px',marginBottom:'0px'}}>
                                    <ListButton
                                        onClick={()=>{
                                            this.$f7.views.main.router.back({
                                                url:`/dashboard/`,
                                                options:{
                                                    force:true
                                                }
                                            });
                                            this.$f7.views.main.router.navigate(`/dashboard/`);
                                        }}
                                        style={{color:'green !important'}}>Go to dashboard <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                                </List>
                            </Col>
                            <Col width="20"></Col>
                        </Row>
                    </Page>
                </Popup>
            );

        }}</FilterConsumer>
      )
    }
  }