import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Link,
    Block,
    List,
    Row,
    Col,
    ListItem,
    Fab,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    AccordionContent
  } from 'framework7-react';

  export default class OutStandingReportSummary extends Component {
    
    constructor(props) {
      
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startyear = today.getFullYear();
      let endyear   = today.getFullYear();
      let startmonth = currmonth-1;
      
      if(startmonth < 1)
      {
        startmonth  = 12;
        startyear   = Number(startyear - 1);
      }

      let startdate = startyear+'-'+Number(startmonth)+'-01';
      let enddate   = endyear+'-'+currmonth+'-'+currday;
      let paymentstartdate = endyear+'-'+currmonth+'-01';      

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
      this.state = {
        clientdetail:[],
        isfetched:false,
        recordset:[],
        isptr:false,
        ismount:false,
        hasallcustomer:false,
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        hasdataavailable:false,
        effect:'blink',
        hasrecord:false,
        totaloutstanding:0,
        totalrecord:'',
        monthyear:startdate,
        filtermonthyear:monthNames[Number(startmonth-1)]+' - '+startyear,
        monthyearlist:[],
        enddate:enddate,
        usefromdate:false,
        paymentstartdate:paymentstartdate,
        isdataloading:false,
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getOutstandingReportSummary = this.getOutstandingReportSummary.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        if(this.state.areaid !== undefined && this.state.areaid !== null && this.state.areaid !== "")
        {
          this.getOutstandingReportSummary();
        }
        else
        {
          setTimeout(() => {
            
            this.openFilterPopup();
            
          }, 500);
        }

        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getOutstandingReportSummary(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online || (this.state.areaid === undefined || this.state.areaid === null || this.state.areaid === "") || this.state.isdataloading)
        {
          return;
        }
    
        const bodyFormData = {
          Mode:'GetOutstandingReportSummary',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          areaid:self.state.areaid,
          lineid:self.state.lineid,
          linemanid:self.state.linemanid,
          hawkerid:self.state.hawkerid,
          monthyear:self.state.monthyear,
          filtermonthyear:self.state.filtermonthyear,
          enddate:self.state.enddate,
          usefromdate:Number(self.state.usefromdate),
          paymentstartdate:self.state.paymentstartdate,
        };

        this.setState(()=>{

          return{
            isfetched:false,
            isdataloading:true,
          }
          
        },()=>{

          setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                    isfetched:true,
                    isdataloading:false,
                    recordset:resdata.paymentlist,
                    hasdataavailable:true,
                    totaloutstanding:resdata.totaloutstanding,
                    totalrecord:resdata.totalrecord,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });

              }
              else
              {
                this.setState({
                    isfetched:true,
                    isdataloading:false,
                    recordset:[],
                    hasdataavailable:false,
                    totaloutstanding:0,
                    totalrecord:0,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        },500);

      });
    }

    getOutstandingReportSummaryPDF(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetOutstandingReportSummaryPDF',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          areaid:self.state.areaid,
          lineid:self.state.lineid,
          linemanid:self.state.linemanid,
          hawkerid:self.state.hawkerid,
          monthyear:self.state.monthyear,
          filtermonthyear:self.state.filtermonthyear,
          enddate:self.state.enddate,
          usefromdate:Number(self.state.usefromdate),
          paymentstartdate:self.state.paymentstartdate,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });          

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                window.open(resdata.pdffilepath);
              }
              else
              {
                const toastWithButton = app.toast.create({
                  text: resdata.msg,
                  closeButton: true,
                  closeTimeout: 3000,
                });
                  
                toastWithButton.open();
              }
          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            lineid:lineid,
            linemanid:linemanid,
            hawkerid:hawkerid,
            filterarea:filterarea,
            filterline:filterline,
            filterlineman:filterlineman,
            filterhawker:filterhawker,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
            monthyear:monthyear,
            filtermonthyear:filtermonthyear,
            enddate:enddate,
            usefromdate:usefromdate,
            paymentstartdate:paymentstartdate,
          }
  
        },()=>{
  
          if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || (this.state.monthyear !== "" && this.state.monthyear !== undefined) || (this.state.enddate !== "" && this.state.enddate !== undefined) || (Number(this.state.usefromdate) > 0 && (this.state.paymentstartdate !== "" && this.state.paymentstartdate !== undefined)))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getOutstandingReportSummary();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      const{monthyear, filtermonthyear, areaid, lineid, hawkerid, filterarea, filterline, filterhawker, enddate, usefromdate, paymentstartdate} = this.state;

      this.$f7router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&monthyear=${monthyear}&filtermonthyear=${filtermonthyear}&enddate=${enddate}&usefromdate=${Number(usefromdate)}&paymentstartdate=${paymentstartdate}`);

      /*this.$f7router.navigate('filterpopup/');*/
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){

      const{isfetched, recordset, hasallcustomer, areaid, lineid, linemanid, hawkerid, hasfilter, filterarea, filterline, filterlineman, filterhawker, removefiltername, applyremovefilter, ismount, hasdataavailable, totaloutstanding, totalrecord, filtermonthyear, monthyear, enddate, usefromdate, paymentstartdate, isdataloading} = this.state;

      let dateend     = "";
      let newenddate  = "";

      if(enddate !== "" && enddate !== undefined)
      {
        newenddate = new Date(enddate);

        let currmonth = newenddate.getMonth()+1;
        if(currmonth < 10)
        {
          currmonth = "0"+currmonth;
        }
        dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
      }

      let date_paymentstartdate   = "";
      let new_paymentstartdate    = "";

      if(paymentstartdate !== "" && paymentstartdate !== undefined && Number(usefromdate) > 0)
      {
        new_paymentstartdate = new Date(paymentstartdate);

        let currmonth = new_paymentstartdate.getMonth()+1;
        if(currmonth < 10)
        {
          currmonth = "0"+currmonth;
        }
        date_paymentstartdate = new_paymentstartdate.getDate()+"-"+currmonth+"-"+new_paymentstartdate.getFullYear();
      }

      return(
        <Page className="page" name="OutStandingReportSummary">
        <Navbar sliding={true}>
          <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
            <Link href={`/dashboard/`} className="clearmenupadding">
              <img className="navbarlogo" src={scanlogo} alt="logo" />
            </Link>
          </NavLeft>
          <NavTitle>Outstanding (Summary)</NavTitle>
          <NavRight className="right-with-menu">
          <Link onClick={()=>{

            this.getOutstandingReportSummary();

          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
          </NavRight>
        </Navbar>
      <FilterConsumer>{(value)=>{

        const{outstandingsummaryfilterdata, applyOutStandingSummaryFilter} = value;

        if(ismount === true)
        {
          setTimeout(()=> {

            if(removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(removefiltername === 'area')
              {
                applyOutStandingSummaryFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, '', '', monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate);
              }
              else if(removefiltername === 'line')
              {
                applyOutStandingSummaryFilter('', linemanid, hawkerid, '', filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate);
              }
              else if(removefiltername === 'lineman')
              {
                applyOutStandingSummaryFilter(lineid, '', hawkerid, filterline, '', filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate);
              }
              else if(removefiltername === 'hawker')
              {
                applyOutStandingSummaryFilter(lineid, linemanid, '', filterline, filterlineman, '', areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate);
              }
            }

            if(((outstandingsummaryfilterdata.lineid !== lineid) && outstandingsummaryfilterdata.lineid !== undefined) || ((outstandingsummaryfilterdata.linemanid !== linemanid) && outstandingsummaryfilterdata.linemanid !== undefined) || ((outstandingsummaryfilterdata.hawkerid !== hawkerid) && outstandingsummaryfilterdata.hawkerid !== undefined) || ((outstandingsummaryfilterdata.areaid !== areaid) && outstandingsummaryfilterdata.areaid !== undefined) || ((outstandingsummaryfilterdata.monthyear !== monthyear) && outstandingsummaryfilterdata.monthyear !== undefined) || ((outstandingsummaryfilterdata.enddate !== enddate) && outstandingsummaryfilterdata.enddate !== undefined) || ((outstandingsummaryfilterdata.usefromdate !== usefromdate) && outstandingsummaryfilterdata.usefromdate !== undefined) || ((outstandingsummaryfilterdata.paymentstartdate !== paymentstartdate) && outstandingsummaryfilterdata.paymentstartdate !== undefined) || applyremovefilter === true)
            {
              this.setNewFilterData(outstandingsummaryfilterdata.lineid, outstandingsummaryfilterdata.linemanid, outstandingsummaryfilterdata.hawkerid, outstandingsummaryfilterdata.filterline, outstandingsummaryfilterdata.filterlineman, outstandingsummaryfilterdata.filterhawker, outstandingsummaryfilterdata.areaid, outstandingsummaryfilterdata.filterarea, outstandingsummaryfilterdata.monthyear, outstandingsummaryfilterdata.filtermonthyear, outstandingsummaryfilterdata.enddate, outstandingsummaryfilterdata.usefromdate, outstandingsummaryfilterdata.paymentstartdate);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }

          }, 500)
        }
        
      return(
            <Block>
              {
                hasfilter ? (
                  <Block strong className="selectedfilter" style={{marginTop:0}}>
                    {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                    {filtermonthyear ? (<Chip text={`Bill Month : ${filtermonthyear}`} mediaBgColor="green" media={filtermonthyear.toUpperCase().substring(0, 1)}/>):null}
                    {usefromdate ? (<Chip text={`Payment Start Date : (${date_paymentstartdate})`} mediaBgColor="yellow" media={`S`}/>):null}
                    {dateend ? (<Chip text={`Payment Upto Date : (${dateend})`} mediaBgColor="red" media={`E`} 
                    />):null}
                    {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)}
                    />):null}
                    {Number(lineid) > 0 && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="pink" media={filterline.toUpperCase().substring(0, 1)} deleteable
                    onClick={()=>{
                            if(this.$f7.online && !isdataloading)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'line'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="green" media={filterlineman.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                            if(this.$f7.online && !isdataloading)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'lineman'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(hawkerid) > 0 && filterhawker !=="" ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                            if(this.$f7.online && !isdataloading)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'hawker'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                        if(this.$f7.online && !isdataloading)
                        {
                          this.$f7router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&monthyear=${monthyear}&filtermonthyear=${filtermonthyear}&enddate=${enddate}&usefromdate=${Number(usefromdate)}&paymentstartdate=${paymentstartdate}`);
                        }
                      /*this.openFilterPopup();*/
                    }}/>
                  </Block>
                ):null
              }

              {
                isfetched ? (
                  <Fragment>
                  {
                    hasdataavailable ? (<Fragment>
                      <BlockTitle className="totalrec">Total Records : {totalrecord}</BlockTitle>
                      <List accordionList>
                      {
                        recordset.map((inventory)=>{

                        /*let title = inventory.name;

                        if(inventory.outstanding !== "")
                        {
                          title = `${title}`;
                        }*/

                        return(
                                <ListItem accordionItem title={`${inventory.name}`} key={inventory.areaid} after={`Rs. ${inventory.outstanding}`} className="accordion-item-opened2">
                                <AccordionContent>
                                  <Row noGap className='rowwithbottomborder'>
                                      <Col width='25' className="tableheader textalignleft">
                                          S.No.
                                      </Col>
                                      <Col width='50' className="tableheader textalignleft">
                                          Line
                                      </Col>
                                      <Col width='25' className="tableheader textalignright">
                                          Due Balance
                                      </Col>
                                  </Row>
                                  {
                                      inventory.details.map((detail)=>{

                                          return(
                                          <Row key={detail.serialno} noGap className='rowwithbottomborder' onClick={()=>{this.navigateToLink(`ledger/${detail.id}`)}}>
                                              <Col width='25' className="ledgercolumn textalignleft">
                                                  {detail.serialno}
                                              </Col>
                                              <Col width='50' className="ledgercolumn textalignleft">
                                                  {detail.name}
                                              </Col>
                                              <Col width='25' className="ledgercolumn textalignright">
                                                  {detail.outstanding}
                                              </Col>
                                          </Row>
                                          );
                                      })
                                  }
                                </AccordionContent>
                                </ListItem>
                            );
                        })
                      }
                      </List>
                    </Fragment>):(<AppItemNotFound notfoundtext ="No record found." />)
                  }
                  </Fragment>
                ):(<AppListItemLoading />)
              }
              <br /><br /><br />
            </Block>
          );

        }}
        </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>

          {
            hasdataavailable ? (
              <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                  if(this.$f7.online)
                  {
                    this.getOutstandingReportSummaryPDF();
                  }
                }}>
                <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
              </Fab>
            ):null
          }
        </Page>
      );
    }
}