import React,{Component, Fragment} from 'react';

import {
    List,
    ListItem,
  } from 'framework7-react';

  export default class AppListItemLoading extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          effect:'blink',
        };
    }
    
    render(){

        return(
            <Fragment>
                <List mediaList className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                    <ListItem
                    title="Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                    title="Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                    title="Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                    title="Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                    title="Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                    title="Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                    title="Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                    title="Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                    title="Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                    title="Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                </List>
                <br /><br />
            </Fragment>
        );
    }
}