import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';
import DownloadAppFav from '../pages/downloadappfav';

import { CustomerOutstandingConsumer } from '../context/CustomerOutstandingContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Toolbar,
    Link,
    Block,
    List,
    Row,
    Col,
    ListInput,
    Icon,
    Chip,
    Button,
    ListItem,
    Fab,
  } from 'framework7-react';

  export default class LatepaymentReport extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isdataloaded:false,
        clientdetail:[],
        filtervars:{areaid:'',filterarea:'All Area'},
        areaid:'',
        filterarea:'All Area',
      }

      this.areaAutocompletePopup  = this.areaAutocompletePopup.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{
        this.areaAutocompletePopup();
      })
    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();

    }    

    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              fromarea:'showallarea',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-area-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              areaid:id,
              filterarea:name,
              filtervars:{areaid:id,filterarea:name}
            });
          },
        },
      });
    }

    render(){
        
        const {areaid, filterarea, filtervars} = this.state;

        return(
            <CustomerOutstandingConsumer>{(value)=>{
              const{handleUserInput, handleUserInputLatePayment, getOutstandingLatePaymentReport, initLatePaymentReport, PagingLatePaymentReport, manageCustomerLateAction, getLatepaymentReportPDF} = value;

              const{isfetched, hasdataavailable, totalrecord, totaloutstandingbalance, recordset, showpages, page, circulationdate, paginglist, totalpages, toolbarPosition, latepaymentdays, outstandingamountabove} = value;

              let extraspaceclass = "";

              if(showpages)
              {
                extraspaceclass = "withtoolbar";
              }

                setTimeout(() => {
                  if(this.state.isdataloaded === false)
                  {
                    this.setState(()=>{
                      return{
                        isdataloaded:true,
                      }
                    },()=>{

                        initLatePaymentReport(filtervars);
                      
                    })
                    
                  }
                }, 100);

                let date  = "";

                let newcirculationdate  = "";

                if(circulationdate !== "" && circulationdate !== undefined)
                {
                  newcirculationdate = new Date(circulationdate);
          
                  let currmonth = newcirculationdate.getMonth()+1;
                  if(currmonth < 10)
                  {
                      currmonth = "0"+currmonth;
                  }
                  date = newcirculationdate.getDate()+"-"+currmonth+"-"+newcirculationdate.getFullYear();
                }

                return(
                    <Page pageContent={false} className="page" name="CustomersOutstanding">
                        <Navbar sliding={true}>
                          <NavLeft>
                            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                            <Link href={`/dashboard/`} className="clearmenupadding">
                              <img className="navbarlogo" src={scanlogo} alt="logo" />
                            </Link>
                          </NavLeft>
                          <NavTitle>Late Payment</NavTitle>
                          <NavRight className="right-with-menu">
                          <Link onClick={()=>{

                            initLatePaymentReport(filtervars);

                            /*getOutstandingLatePaymentReport();*/

                          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                          </NavRight>
                        </Navbar>
                        <PageContent>
                            <Block>
                              <Row noGap>
                                  <Col width="100">  
                                  <List className="zeromargin">
                                    <ListItem link="#" id="autocomplete-area-popup" title="Area" after={filterarea}></ListItem>
                                    <ListInput
                                        name="latepaymentdays"
                                        id="latepaymentdays"
                                        label="No payment Days"
                                        placeholder="No payment Days"
                                        type="number"
                                        value={latepaymentdays}
                                        onChange={handleUserInput}
                                    >
                                    </ListInput>
                                    <ListInput
                                        name="outstandingamountabove"
                                        id="outstandingamountabove"
                                        label="Outstanding Amount Above"
                                        placeholder="Outstanding Amount Above"
                                        type="number"
                                        value={outstandingamountabove}
                                        onChange={handleUserInput}
                                    >
                                    </ListInput>
                                  </List>
                                  </Col>
                              </Row>
                              <Row>
                                <Col width="35">
                                </Col>
                                <Col width="30">
                                  <Button fill color="blue" 
                                      onClick={()=>{
                                          if(this.$f7.online)
                                          {
                                              this.setState({
                                              hasfilter:true
                                              },()=>{
                                                  initLatePaymentReport(filtervars);
                                                  /*getOutstandingLatePaymentReport();*/
                                              })
                                          }
                                      }}
                                  >Fetch</Button>
                                </Col>
                                <Col width="35">
                                </Col>
                              </Row>
                            </Block>
                            {
                              isfetched ? (<Fragment>
                                {
                                  hasdataavailable ? (<Fragment>
                                    <BlockTitle className="totalrec">Total Records : {totalrecord}</BlockTitle>
                                    <BlockTitle className="totalrec">Total Outstanding : {totaloutstandingbalance}</BlockTitle>
                                  </Fragment>):null
                                }
                                  {
                                    hasdataavailable ? (
                                      <div className="data-table">
                                        <table>
                                          <thead>
                                            <tr>
                                              <th className="label-cell">Name</th>
                                              <th className="numeric-cell">Area</th>
                                              <th className="numeric-cell">Address</th>
                                              <th className="numeric-cell">Due</th>
                                              <th className="numeric-cell">Last Payment</th>
                                              <th className="numeric-cell">&nbsp;</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                        {
                                          recordset.detail.map((detail)=>{

                                            return(
                                              <tr key={detail.id} style={{color:detail.blockcolor}}>
                                                <td className="label-cell">
                                                  {detail.name}
                                                  {
                                                    detail.phone ? (<span>
                                                      <br />
                                                      <Link external href={`tel:${detail.phone}`} target="_system" style={{color:detail.blockcolor}}>
                                                      {detail.phone} <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                      </Link>
                                                    </span>):null
                                                  }
                                                </td>
                                                <td className="numeric-cell" style={{paddingRight:'0',paddingLeft:'0'}}>{detail.areaname}</td>
                                                <td className="numeric-cell">{detail.address}</td>
                                                <td className="numeric-cell">{detail.outstandingbalance}</td>
                                                <td className="numeric-cell">
                                                  {
                                                    detail.paymentnum > 0 ? (<div>
                                                    {detail.paymentamount}
                                                    <br/>
                                                    ({detail.lastpaymentdate})
                                                    </div>):`---`
                                                  }
                                                </td>
                                                <td className="numeric-cell">
                                                  <Link onClick={()=>{
                                                      if(this.$f7.online)
                                                      {
                                                        manageCustomerLateAction(detail.id, detail.phone);
                                                      }
                                                    }}><Icon ios="f7:line_horizontal_3" aurora="f7:line_horizontal_3" md="material:dashboard"></Icon></Link>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        }
                                        </tbody>
                                        </table>
                                        <br /><br /><br />
                                      </div>
                                    ):(<AppItemNotFound notfoundtext ="No record found."/>)
                                  }
                                  </Fragment>):(<AppListItemLoading />)
                            }
                            <br /><br />
                        </PageContent>
                        {
                          hasdataavailable  && showpages ? (
                            <Toolbar position={toolbarPosition}>
                            {
                              (()=>{
                                if(Number(page) > 1)
                                {
                                  return(
                                    <Link className="paging" onClick={()=>{PagingLatePaymentReport('previous')}}>
                                    <Icon f7="arrow_left_square_fill" ></Icon>
                                    </Link>
                                  );
                                }
                                else
                                {
                                  return(
                                    <Link href="#"></Link>
                                  );
                                }
                              })()
                            }
                              <List className="droppage" noHairlines>
                                  <ListInput
                                    name="page"
                                    type="select"
                                    value={page}
                                    onChange={handleUserInputLatePayment}
                                    placeholder="Please choose..."
                                    >
                                    {
                                      paginglist.map((pages)=>{
                                      return(<option key={pages.index} value={pages.page}>{pages.name}</option>);
                                      })
                                    }
                                  </ListInput>
                              </List>
                              {
                                (()=>{
                                  if(Number(page) < Number(totalpages))
                                  {
                                    return(
                                      <Link className="paging" onClick={()=>{PagingLatePaymentReport('next')}}>
                                      <Icon f7="arrow_right_square_fill" ></Icon>
                                      </Link>
                                    );
                                  }
                                  else
                                  {
                                    return(
                                      <Link href="#"></Link>
                                    );
                                  }
                                })()
                              }
                            </Toolbar>
                          ):null
                        }
                        {
                          hasdataavailable ? (
                            <DownloadAppFav position="center-bottom" text="Download" getLatepaymentReportPDF={getLatepaymentReportPDF}/>
                          ):null
                        }
                        {/*
                          hasdataavailable ? (
                            <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                                if(this.$f7.online)
                                {
                                  getLatepaymentReportPDF();
                                }
                              }}>
                              <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                              <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                            </Fab>
                          ):null
                        */}
                    </Page>
                );
            }}
            </CustomerOutstandingConsumer>            
        );
    }
}