import React,{Component} from 'react';

const FilterContext = React.createContext();
//Provider
//Consumer

class FilterProvider extends Component{

	constructor(props){
		super(props);
		this.state = {
		  teststate: 'I am test state',
		  singlearea: [],
		  singledroppingpoint: [],
		  singleline: [],
		  singlesubline: [],
		  singlelineman: [],
		  singlehawker: [],
		  filterdata: [],
		  circulationfilterdata: [],
		  customerlistfilterdata: [],
		  billprintfilterdata: [],
		  registerfilterdata: [],
		  magazinesalefilterdata: [],
		  managemagazinesalefilterdata: [],
		  ledgerfilterdata: [],
		  outstandingsummaryfilterdata: [],
		  billcollectionsummarydata: [],
		  invoicefilterdata:[],
		  clientdetail:[],
		  sublinefilterdata: [],
		  linemanfilterdata: [],
		  purchasesummaryfilterdata: [],
		  closecustomerfilterdata: [],
		  billstatementfilterdata: [],
		  billstatementsummaryfilterdata: [],
		  billoutstandingfilterdata: [],
		  monthlybillofnewspaperdata: [],
		  restartcustomerfilterdata: [],
		  paymentfilterdata: [],
		  areafilterdata: [],
		  adminid: '',
		  smshistoryfilterdata: [],
		  ledgerfilterdata: [],
		};

		this.DemoFunction						= this.DemoFunction.bind(this);
		this.setAreaData						= this.setAreaData.bind(this);
		this.setDroppingPointData				= this.setDroppingPointData.bind(this);
		this.setLineData						= this.setLineData.bind(this);
		this.setSubLineData						= this.setSubLineData.bind(this);
		this.setLinemanData						= this.setLinemanData.bind(this);
		this.setHawkerData						= this.setHawkerData.bind(this);
		this.applyFilter						= this.applyFilter.bind(this);
		this.applyCirculationFilter				= this.applyCirculationFilter.bind(this);
		this.applyCustomerListFilter			= this.applyCustomerListFilter.bind(this);
		this.applyBillPrintingFilter			= this.applyBillPrintingFilter.bind(this);
		this.applyPaymentRegisterFilter			= this.applyPaymentRegisterFilter.bind(this);
		this.applyCustomerLedgerFilter			= this.applyCustomerLedgerFilter.bind(this);
		this.applyOutStandingSummaryFilter		= this.applyOutStandingSummaryFilter.bind(this);
		this.applyBillCollectionSummaryFilter	= this.applyBillCollectionSummaryFilter.bind(this);
		this.applyInvoiceFilter					= this.applyInvoiceFilter.bind(this);
		this.initMenu							= this.initMenu.bind(this);
		this.applySublineFilter					= this.applySublineFilter.bind(this);
		this.applyPurchaseSummaryFilter			= this.applyPurchaseSummaryFilter.bind(this);
		this.numberWithCommas					= this.numberWithCommas.bind(this);
		this.applyCloseCustomerFilter			= this.applyCloseCustomerFilter.bind(this);
		this.applyRestartCustomerFilter			= this.applyRestartCustomerFilter.bind(this);
		this.applyLinemanFilter					= this.applyLinemanFilter.bind(this);
		this.applyBillStatementFilter			= this.applyBillStatementFilter.bind(this);
		this.applyBillStatementSummaryFilter	= this.applyBillStatementSummaryFilter.bind(this);
		this.applyOutstandingFilter				= this.applyOutstandingFilter.bind(this);
		this.applyMonthlyBillOfNewspaperFilter	= this.applyMonthlyBillOfNewspaperFilter.bind(this);
		this.applyPaymentFilter					= this.applyPaymentFilter.bind(this);
		this.applyAreaFilter					= this.applyAreaFilter.bind(this);
		this.applySMSHistoryFilter				= this.applySMSHistoryFilter.bind(this);
		this.applyMagazineSaleDetailFilter		= this.applyMagazineSaleDetailFilter.bind(this);
		this.applyManageMagazineSaleDetailFilter= this.applyManageMagazineSaleDetailFilter.bind(this);
		this.applyLedgerFilter					= this.applyLedgerFilter.bind(this);
	}
	numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	DemoFunction(areaid){

		console.log("I am areaid "+areaid);

	}

	setAreaData(id, name, isnewadded){

		let dataset	= [...this.state.singlearea];

		dataset.id			= id;
		dataset.name		= name;
		dataset.isnewadded	= isnewadded;

		this.setState({

			singlearea:dataset

		});

	}

	setDroppingPointData(id, name, isnewadded){

		let dataset	= [...this.state.singledroppingpoint];

		dataset.id			= id;
		dataset.name		= name;
		dataset.isnewadded	= isnewadded;

		this.setState({

			singledroppingpoint:dataset

		});

	}

	setLineData(id, name, isnewadded){

		let dataset	= [...this.state.singleline];

		dataset.id			= id;
		dataset.name		= name;
		dataset.isnewadded	= isnewadded;

		this.setState({

			singleline:dataset

		});

	}

	setSubLineData(id, name){

		let dataset	= [...this.state.singlesubline];

		dataset.id		= id;
		dataset.name	= name;

		this.setState({

			singlesubline:dataset

		});

	}

	setLinemanData(id, name){

		let dataset	= [...this.state.singlelineman];

		dataset.id		= id;
		dataset.name	= name;

		this.setState({

			singlelineman:dataset

		});

	}

	setHawkerData(id, name, isnewadded){

		let dataset	= [...this.state.singlehawker];

		dataset.id			= id;
		dataset.name		= name;
		dataset.isnewadded	= isnewadded;

		this.setState({

			singlehawker:dataset

		});

	}

	applyFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea,openingbalanceid,openingblancefilter){

		let dataset	= [...this.state.filterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.openingbalanceid= openingbalanceid;
		dataset.openingblancefilter		= openingblancefilter;

		this.setState({

			filterdata:dataset

		});

	}

	applyCustomerListFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, inventoryid, inventoryname){

		let dataset	= [...this.state.customerlistfilterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.inventoryid		= inventoryid;
		dataset.inventoryname	= inventoryname;

		this.setState({

			customerlistfilterdata:dataset

		});

	}

	applyBillPrintingFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, billnumberfrom,billnumberto,filterbillnumberfrom,filterbillnumberto,isbillnumber,isdatefilter,billstartdate, billenddate, filterstartmonthyear, filterendmonthyear, issingledatefilter, billprintingdate, isborderprinting){

		let dataset	= [...this.state.billprintfilterdata];

		dataset.lineid					= lineid;
		dataset.linemanid				= linemanid;
		dataset.hawkerid				= hawkerid;
		dataset.areaid					= areaid;
		dataset.filterline				= filterline;
		dataset.filterlineman			= filterlineman;
		dataset.filterhawker			= filterhawker;
		dataset.filterarea				= filterarea;
		dataset.isbillnumber			= isbillnumber;
		dataset.billnumberfrom			= billnumberfrom;
		dataset.billnumberto			= billnumberto;
		dataset.isdatefilter			= isdatefilter;
		dataset.billstartdate			= billstartdate;
		dataset.billenddate				= billenddate;
		dataset.filterbillnumberfrom	= filterbillnumberfrom;
		dataset.filterbillnumberto		= filterbillnumberto;
		dataset.filterstartmonthyear	= filterstartmonthyear;
		dataset.filterendmonthyear		= filterendmonthyear;
		dataset.issingledatefilter		= issingledatefilter;
		dataset.billprintingdate		= billprintingdate;
		dataset.isborderprinting		= isborderprinting;

		this.setState({

			billprintfilterdata:dataset

		});
	}

	applyPaymentRegisterFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, paymenttype){

		let dataset	= [...this.state.registerfilterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.startdate		= startdate;
		dataset.enddate			= enddate;
		dataset.paymenttype		= paymenttype;

		this.setState({

			registerfilterdata:dataset

		});

	}

	applyCustomerLedgerFilter(customerid, customername, startdate, enddate, areaid, lineid, filterarea, filterline, hasdatefilter, customerphone){

		let dataset	= [...this.state.ledgerfilterdata];

		dataset.customerid		= customerid;
		dataset.customername	= customername;
		dataset.startdate		= startdate;
		dataset.enddate			= enddate;
		dataset.areaid			= areaid;
		dataset.lineid			= lineid;
		dataset.filterarea		= filterarea;
		dataset.filterline		= filterline;
		dataset.hasdatefilter	= hasdatefilter;
		dataset.customerphone	= customerphone;

		this.setState({

			ledgerfilterdata:dataset

		});

	}

	applyBillCollectionSummaryFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, filterstartmonthyear, filterendmonthyear){

		let dataset	= [...this.state.billcollectionsummarydata];

		dataset.lineid					= lineid;
		dataset.linemanid				= linemanid;
		dataset.hawkerid				= hawkerid;
		dataset.areaid					= areaid;
		dataset.filterline				= filterline;
		dataset.filterlineman			= filterlineman;
		dataset.filterhawker			= filterhawker;
		dataset.filterarea				= filterarea;
		dataset.startdate				= startdate;
		dataset.enddate					= enddate;
		dataset.filterstartmonthyear	= filterstartmonthyear;
		dataset.filterendmonthyear		= filterendmonthyear;

		this.setState({

			billcollectionsummarydata:dataset

		});

	}

	applyInvoiceFilter(year, month, filteryear, filtermonth, lineid, hawkerid, filterline, filterhawker, areaid, filterarea){

		let dataset	= [...this.state.invoicefilterdata];

		dataset.year			= year;
		dataset.month			= month;
		dataset.filteryear		= filteryear;
		dataset.filtermonth		= filtermonth;
		dataset.lineid			= lineid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;

		this.setState({

			invoicefilterdata:dataset

		});

	}

	initMenu(){

		let clientdetail  = JSON.parse(localStorage.getItem('agency_clientdetail'));
		let adminid       = Number(localStorage.getItem('agency_adminid'));

		if(clientdetail !== undefined && clientdetail !== null)
		{
			this.setState({
				clientdetail:clientdetail,
				adminid:adminid,
			})
		}
	}

	applySublineFilter(lineid, filterline, areaid, filterarea){

		let dataset	= [...this.state.sublinefilterdata];

		dataset.lineid			= lineid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterarea		= filterarea;

		this.setState({

			sublinefilterdata:dataset

		});

	}

	applyPurchaseSummaryFilter(startdate, enddate, droppingpointtype, droppingpointid, droppingpointname, isstock, inventoryid, inventoryname){

		let dataset	= [...this.state.purchasesummaryfilterdata];

		dataset.startdate			= startdate;
		dataset.enddate				= enddate;
		dataset.droppingpointtype	= droppingpointtype;
		dataset.droppingpointid		= droppingpointid;
		dataset.droppingpointname	= droppingpointname;
		dataset.isstock				= isstock;
		dataset.inventoryid			= inventoryid;
		dataset.inventoryname		= inventoryname;

		this.setState({

			purchasesummaryfilterdata:dataset

		});

	}

	applyCloseCustomerFilter(startdate, enddate, areaid, areaname, inventoryid, inventoryname, lineid, linename,reporttype){

		let dataset	= [...this.state.closecustomerfilterdata];

		dataset.reporttype		= reporttype;
		dataset.startdate		= startdate;
		dataset.enddate			= enddate;
		dataset.areaid			= areaid;
		dataset.areaname		= areaname;
		dataset.inventoryid		= inventoryid;
		dataset.inventoryname	= inventoryname;
		dataset.lineid			= lineid;
		dataset.linename		= linename;

		this.setState({

			closecustomerfilterdata:dataset

		});

	}

	applyLinemanFilter(lineid, filterline, areaid, filterarea){

		let dataset	= [...this.state.linemanfilterdata];

		dataset.lineid			= lineid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterarea		= filterarea;

		this.setState({

			linemanfilterdata:dataset

		});

	}

	applyBillStatementFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear){

		let dataset	= [...this.state.billstatementfilterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.monthyear		= monthyear;
		dataset.filtermonthyear	= filtermonthyear;

		this.setState({

			billstatementfilterdata:dataset

		});

	}

	applyBillStatementSummaryFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate){

		let dataset	= [...this.state.billstatementsummaryfilterdata];

		dataset.lineid				= lineid;
		dataset.linemanid			= linemanid;
		dataset.hawkerid			= hawkerid;
		dataset.areaid				= areaid;
		dataset.filterline			= filterline;
		dataset.filterlineman		= filterlineman;
		dataset.filterhawker		= filterhawker;
		dataset.filterarea			= filterarea;
		dataset.monthyear			= monthyear;
		dataset.filtermonthyear		= filtermonthyear;
		dataset.enddate				= enddate;
		dataset.usefromdate			= usefromdate;
		dataset.paymentstartdate	= paymentstartdate;

		this.setState({

			billstatementsummaryfilterdata:dataset

		});

	}

	applyOutstandingFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate){

		let dataset	= [...this.state.billoutstandingfilterdata];

		dataset.lineid				= lineid;
		dataset.linemanid			= linemanid;
		dataset.hawkerid			= hawkerid;
		dataset.areaid				= areaid;
		dataset.filterline			= filterline;
		dataset.filterlineman		= filterlineman;
		dataset.filterhawker		= filterhawker;
		dataset.filterarea			= filterarea;
		dataset.monthyear			= monthyear;
		dataset.filtermonthyear		= filtermonthyear;
		dataset.enddate				= enddate;
		dataset.usefromdate			= usefromdate;
		dataset.paymentstartdate	= paymentstartdate;

		this.setState({

			billoutstandingfilterdata:dataset

		});

	}

	applyOutStandingSummaryFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate){

		let dataset	= [...this.state.outstandingsummaryfilterdata];

		dataset.lineid				= lineid;
		dataset.linemanid			= linemanid;
		dataset.hawkerid			= hawkerid;
		dataset.areaid				= areaid;
		dataset.filterline			= filterline;
		dataset.filterlineman		= filterlineman;
		dataset.filterhawker		= filterhawker;
		dataset.filterarea			= filterarea;
		dataset.monthyear			= monthyear;
		dataset.filtermonthyear		= filtermonthyear;
		dataset.enddate				= enddate;
		dataset.usefromdate			= usefromdate;
		dataset.paymentstartdate	= paymentstartdate;

		this.setState({

			outstandingsummaryfilterdata:dataset

		});

	}

	applyCirculationFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, catid, catname, droppingpointid, droppingpointname, useenddate){

		let dataset	= [...this.state.circulationfilterdata];

		dataset.lineid				= lineid;
		dataset.linemanid			= linemanid;
		dataset.hawkerid			= hawkerid;
		dataset.areaid				= areaid;
		dataset.filterline			= filterline;
		dataset.filterlineman		= filterlineman;
		dataset.filterhawker		= filterhawker;
		dataset.filterarea			= filterarea;
		dataset.startdate			= startdate;
		dataset.enddate				= enddate;
		dataset.catid				= catid;
		dataset.catname				= catname;
		dataset.droppingpointid		= droppingpointid;
		dataset.droppingpointname	= droppingpointname;
		dataset.useenddate			= Number(useenddate);

		this.setState({

			circulationfilterdata:dataset

		});

	}

	applyMonthlyBillOfNewspaperFilter(startdate, enddate, filtermonthyear, catid, catname){

		let dataset	= [...this.state.monthlybillofnewspaperdata];

		dataset.startdate			= startdate;
		dataset.enddate				= enddate;
		dataset.filtermonthyear		= filtermonthyear;
		dataset.catid				= catid;
		dataset.catname				= catname;

		this.setState({

			monthlybillofnewspaperdata:dataset

		});

	}

	applyRestartCustomerFilter(startdate, enddate, areaid, areaname, inventoryid, inventoryname, lineid, linename){

		let dataset	= [...this.state.restartcustomerfilterdata];

		dataset.startdate		= startdate;
		dataset.enddate			= enddate;
		dataset.areaid			= areaid;
		dataset.areaname		= areaname;
		dataset.inventoryid		= inventoryid;
		dataset.inventoryname	= inventoryname;
		dataset.lineid			= lineid;
		dataset.linename		= linename;

		this.setState({

			restartcustomerfilterdata:dataset

		});

	}

	applyPaymentFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, startdate, enddate2, paymenttype, filterby){

		let dataset	= [...this.state.paymentfilterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.monthyear		= monthyear;
		dataset.filtermonthyear	= filtermonthyear;
		dataset.enddate			= enddate;
		dataset.startdate		= startdate;
		dataset.enddate2		= enddate2;
		dataset.paymenttype		= paymenttype;
		dataset.showpayment		= filterby.showpayment;
		dataset.showdiscount	= filterby.showdiscount;
		dataset.showcoupon		= filterby.showcoupon;

		this.setState({

			paymentfilterdata:dataset

		});

	}

	applyAreaFilter(areaid, filterarea, droppingpointid, droppingpointname){

		let dataset	= [...this.state.areafilterdata];

		dataset.areaid				= areaid;
		dataset.filterarea			= filterarea;
		dataset.droppingpointid		= droppingpointid;
		dataset.droppingpointname	= droppingpointname;

		this.setState({

			areafilterdata:dataset

		});

	}

	applySMSHistoryFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate){

		let dataset	= [...this.state.smshistoryfilterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.startdate		= startdate;
		dataset.enddate			= enddate;

		this.setState({

			smshistoryfilterdata:dataset

		});

	}

	applyMagazineSaleDetailFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, inventoryid, filterinventoryname){

		let dataset	= [...this.state.magazinesalefilterdata];

		dataset.lineid				= lineid;
		dataset.linemanid			= linemanid;
		dataset.hawkerid			= hawkerid;
		dataset.areaid				= areaid;
		dataset.filterline			= filterline;
		dataset.filterlineman		= filterlineman;
		dataset.filterhawker		= filterhawker;
		dataset.filterarea			= filterarea;
		dataset.startdate			= startdate;
		dataset.enddate				= enddate;
		dataset.inventoryid			= inventoryid;
		dataset.filterinventoryname	= filterinventoryname;

		this.setState({

			magazinesalefilterdata:dataset

		});

	}
	applyManageMagazineSaleDetailFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, stockid, filterinventoryname){

		let dataset	= [...this.state.managemagazinesalefilterdata];

		dataset.lineid				= lineid;
		dataset.linemanid			= linemanid;
		dataset.hawkerid			= hawkerid;
		dataset.areaid				= areaid;
		dataset.filterline			= filterline;
		dataset.filterlineman		= filterlineman;
		dataset.filterhawker		= filterhawker;
		dataset.filterarea			= filterarea;
		dataset.startdate			= startdate;
		dataset.enddate				= enddate;
		dataset.stockid				= stockid;
		dataset.filterinventoryname	= filterinventoryname;

		this.setState({

			managemagazinesalefilterdata:dataset

		});

	}

	applyLedgerFilter(datetype, startdate){

		let dataset	= [...this.state.ledgerfilterdata];

		dataset.datetype		= datetype;
		dataset.startdate		= startdate;

		this.setState({

			ledgerfilterdata:dataset

		});

	}

	render(){
		return (
			<FilterContext.Provider value={{
			...this.state,
				DemoFunction:this.DemoFunction,
				setAreaData:this.setAreaData,
				setDroppingPointData:this.setDroppingPointData,
				setLineData:this.setLineData,
				setSubLineData:this.setSubLineData,
				setLinemanData:this.setLinemanData,
				setHawkerData:this.setHawkerData,
				applyFilter:this.applyFilter,
				applyCirculationFilter:this.applyCirculationFilter,
				applyCustomerListFilter:this.applyCustomerListFilter,
				applyBillPrintingFilter:this.applyBillPrintingFilter,
				applyPaymentRegisterFilter:this.applyPaymentRegisterFilter,
				applyCustomerLedgerFilter:this.applyCustomerLedgerFilter,
				applyOutStandingSummaryFilter:this.applyOutStandingSummaryFilter,
				applyBillCollectionSummaryFilter:this.applyBillCollectionSummaryFilter,
				applyInvoiceFilter:this.applyInvoiceFilter,
				initMenu:this.initMenu,
				applySublineFilter:this.applySublineFilter,
				applyPurchaseSummaryFilter:this.applyPurchaseSummaryFilter,
				numberWithCommas:this.numberWithCommas,
				applyCloseCustomerFilter:this.applyCloseCustomerFilter,
				applyLinemanFilter:this.applyLinemanFilter,
				applyBillStatementFilter:this.applyBillStatementFilter,
				applyBillStatementSummaryFilter:this.applyBillStatementSummaryFilter,
				applyOutstandingFilter:this.applyOutstandingFilter,
				applyMonthlyBillOfNewspaperFilter:this.applyMonthlyBillOfNewspaperFilter,
				applyRestartCustomerFilter:this.applyRestartCustomerFilter,
				applyPaymentFilter:this.applyPaymentFilter,
				applyAreaFilter:this.applyAreaFilter,
				applySMSHistoryFilter:this.applySMSHistoryFilter,
				applyMagazineSaleDetailFilter:this.applyMagazineSaleDetailFilter,
				applyManageMagazineSaleDetailFilter:this.applyManageMagazineSaleDetailFilter,
				applyLedgerFilter:this.applyLedgerFilter,
			}}
			>
			{this.props.children}
			</FilterContext.Provider>
		);
	}
}

const FilterConsumer = FilterContext.Consumer;

export {FilterProvider, FilterConsumer, FilterContext};