import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';
import processingimage from '../assets/images/loader.gif';
import {
  Page,
  LoginScreenTitle,
  List,
  Link,
  ListButton,
  Button,
  Popup,
  Row,
  Col,
  Icon
} from 'framework7-react';

export default class extends Component {
    constructor(props) {
      super(props);
  
      this.state = {

        paymentid:'',
        frompage:'',
        buttontext:'',
        paymentlink:''

      };
      this.GetPaymentLink = this.GetPaymentLink.bind(this);
    }
    
    
    componentDidMount(){

        this.setState(()=>{
            return{
                paymentid:this.props.f7route.params.paymentid,
                frompage:this.props.f7route.params.frompage,
            }
        },()=>{

            const{frompage} = this.state;

            let addmorebtntext  = "Back to sms credits";
            if(frompage == 'pending')
            {
                addmorebtntext  = "Back to pending credits";    
            }
            this.setState({
                buttontext:addmorebtntext
            });
            this.checkpaymetinterval = setInterval(() => {
              this.verifyPayments();
              }, 2000);
            this.GetPaymentLink();
            //clearInterval(interval);
        })
        window.addEventListener("backKeyDown", this.onBackKeyDown);
      }

      componentWillUnmount(){
        clearInterval(this.checkpaymetinterval);
      }

      onBackKeyDown(e) { 
        e.preventDefault();
        alert('testing')
          this.$f7.views.main.router.back({
            url:`/smscredits/`,
            options:{
                force:true
            }
        });
      };
    verifyPayments(){

        const self      = this;
        const app       = self.$f7;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }
  
        const bodyFormData = {
            Mode:'verifypayments',
            paymentid:this.props.f7route.params.paymentid,
        };

        this.setState(()=>{
          return{
            loaded:false
          }
        },()=>{

          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');
  
          }, 50);

          // Simulate Ajax Request
          setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request
        
                const resdata = JSON.parse(res.data);

                if(resdata.success === true)
                {
                    if(resdata.paymentstatus == 'paid')
                    {
                      this.$f7.views.main.router.navigate(`/smscredits/`);   
                    }
                }
            }).catch(e => {
                console.log(e);
            });
    
          }, 500);

        })
    }

    GetPaymentLink(){

        const self      = this;
        const app       = self.$f7;

        if(this.state.internetstatus === "offline")
        {
             const toastWithButton = app.toast.create({
                 text: "No internet connection",
             });
               
             toastWithButton.open();
             
             return;
        }
  
        const bodyFormData = {
            Mode:'getpaymentlink',
            paymentid:this.props.f7route.params.paymentid,
        };

        this.setState(()=>{
          return{
            loaded:false
          }
        },()=>{

          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');
  
          }, 50);

          // Simulate Ajax Request
          setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request
        
                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                      this.setState(()=>{
                        return{
                            paymentlink:resdata.paymentlink,
                        }
                      })  
                }
            }).catch(e => {
                console.log(e);
            });
    
          }, 500);

        })
    }
    render() {
        
        const{buttontext,frompage,paymentlink} = this.state;

      return (
        <Popup>
            <Page noToolbar noNavbar noSwipeback loginScreen>
                <Row>
                    <Col>
                        <img className="loginlogo" src={scanlogo} alt="logo" />
                    </Col>
                </Row>
                <LoginScreenTitle>Payments</LoginScreenTitle>
                <Row noGap>
                    <Col width="100" style={{textAlign:'center'}}>
                    <img src={processingimage} alt="processing" width='40'/>
                    </Col>
                </Row>
                <Row noGap>
                    <Col width="10"></Col>
                    <Col width="80" style={{textAlign:'center'}}>
                        <br />
                       Processing..
                    </Col>
                    <Col width="10"></Col>
                </Row>
                <br />
                <Row>
                    <Col width="20"></Col>
                    <Col width="60"><Button fill color="blue" onClick={()=>{
                                   if(frompage == 'pending')
                                   {
                                    this.$f7.views.main.router.navigate(`/smscreditpending/`);
                                   }
                                   else
                                   {
                                    this.$f7.views.main.router.navigate(`/smscredits/`);
                                   }
                                }}>{buttontext}</Button></Col>
                    <Col width="20"></Col>
                </Row>
                <Row>
                    <Col width="20"></Col>
                    <Col width="60">
                        <List style={{marginTop:'0px',marginBottom:'0px'}}>
                            <ListButton
                                onClick={()=>{
                                    this.$f7.views.main.router.navigate(`/dashboard/`);
                                }}
                                style={{color:'green !important'}}>Go to dashboard <Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>
                              {
                                paymentlink ? 
                                ( <ListButton
                                  onClick={()=>{
                                      window.open(paymentlink,'_blank');
                                  }}
                                  style={{color:'green !important'}}>Retry Payment<Icon f7="arrow_right" style={{color:'green',fontSize:'1rem'}}></Icon></ListButton>) :null
                              }
                        </List>
                    </Col>
                    <Col width="20"></Col>
                </Row>
                <Row noGap>
                    <Col width="10"></Col>
                    <Col width="80" style={{textAlign:'center'}}>
                       <br />
                       <b>For support Call / Whatsapp<br/>@<br/><Link href="tel:9811168031" external>9811168031</Link></b>
                    </Col>
                    <Col width="10"></Col>
                </Row>
                <br />
            </Page>
        </Popup>
      )
    }
  }