import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Toggle,
    Toolbar,
    Link,
    Tabs,
    Tab,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Fab,
    Icon,
    AccordionContent,
  } from 'framework7-react';

  export default class Updatemonthprice extends Component {
    constructor(props) {
      super(props);

      this.state = {
        clientdetail:[],
        inventorylist:[],
        hasmonth:false,
        year:'',
        month:'',
        monthname:'',
        monthhasinvoice:false,
        isptr:false,
        totaldays:"",
        ismonthdataloaded:false,
        effect:'blink',
      };

      this.handleInventoryInput     = this.handleInventoryInput.bind(this);
      this.handleInventoryDatePrice = this.handleInventoryDatePrice.bind(this);
      this.saveInventoryPricing     = this.saveInventoryPricing.bind(this);
      this.handlePricingType        = this.handlePricingType.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          year:this.props.f7route.params.year,
          month:this.props.f7route.params.month,
          monthname:this.props.f7route.params.monthname
        }
      },()=>{

        this.hideSplashScreen();
        this.getActiveInventoryDetail();

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    getActiveInventoryDetail(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetSubscribeInventory',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          year:self.state.year,
          month:self.state.month,
      };

      this.setState({
        ismonthdataloaded:false
      });

      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              app.preloader.hide();

              app.ptr.done();

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({

                    inventorylist:resdata.inventorylist,
                    totaldays:resdata.totaldays,
                    isptr:false,
                    ismonthdataloaded:true,

                });
              }
              else
              {
                this.setState({

                  isptr:false,
                  ismonthdataloaded:true,

              });
              }

          }).catch(e => {

              console.log(e);

          });

      },500);
    }

    handleInventoryInput(e){

        const value = e.target.value;

        const catid         = e.target.getAttribute('catid');
        const inventoryid   = e.target.getAttribute('inventoryid');
        const fieldname     = e.target.getAttribute('fieldname');

        let tempInventoryList       = [...this.state.inventorylist];
        const tempInventoryList2    = tempInventoryList.find(category => Number(category.id) === Number(catid));
        const tempInventory         = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(inventoryid));

        tempInventory[fieldname]  = value;

        this.setState(()=>{
          
          return{
            inventorylist: tempInventoryList
          }

        });
    }

    handleInventoryDatePrice(e){

      const value = e.target.value;

      const catid         = e.target.getAttribute('catid');
      const inventoryid   = e.target.getAttribute('inventoryid');
      const date          = e.target.getAttribute('date');
      const fieldname     = e.target.getAttribute('fieldname');

      let tempInventoryList       = [...this.state.inventorylist];
      const tempInventoryList2    = tempInventoryList.find(category => Number(category.id) === Number(catid));
      const tempInventoryList3    = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(inventoryid));
      const tempInventory         = tempInventoryList3.datepricing.find(pricing => Number(pricing.date) === Number(date));

      tempInventory[fieldname]  = value;

      this.setState(()=>{
        
        return{
          inventorylist: tempInventoryList
        }

      });
    }

    saveInventoryPricing(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      /*let tempInventoryList       = [...this.state.inventorylist];

      let newtempInventoryList  = [];

      tempInventoryList.forEach((tempcat)=>{

        const tempInventoryList2  = tempInventoryList.find(category => Number(category.id) === Number(tempcat.id));

        tempInventoryList2.recordlist.forEach((tempstock)=>{

          const tempInventoryList3  = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(tempstock.id));

          const tempdatepricing = [...tempInventoryList3.datepricing];

          tempdatepricing.filter(dateitem=>dateitem.dateprice != "" && dateitem.dateprice !== null && dateitem.dateprice !== undefined);

          if(Object.keys(tempdatepricing).length > 0)
          {
            newtempInventoryList  = [...newtempInventoryList];
          }
        });

      });

      console.log(`after filter`);

      console.log(tempInventoryList);

      return false;*/

      /*tempInventory[fieldname]  = value;*/

      const bodyFormData = {
          Mode:'SaveInventoryPricing',
          clientid:self.state.clientdetail.id,
          cityid:self.state.clientdetail.cityid,
          stateid:self.state.clientdetail.stateid,
          month:self.state.month,
          year:self.state.year,
          inventorylist:self.state.inventorylist,
      };

      app.preloader.show();

      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
 
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
    }

    handlePricingType(catid, inventoryid, value){

      let tempInventoryList       = [...this.state.inventorylist];
      const tempInventoryList2    = tempInventoryList.find(category => Number(category.id) === Number(catid));
      const tempInventory         = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(inventoryid));

      tempInventory.pricingtype  = value;

      this.setState(()=>{
        
        return{

          inventorylist: tempInventoryList

        }

      });

    }

    render(){
        
        const{year, month, monthname, inventorylist, ismonthdataloaded} = this.state;

        return(
            <Page className="page" name="Updatemonthprice">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Update Price By Month</NavTitle>
                  <NavRight className="right-with-menu">
                  <Link onClick={()=>{
        
                    this.getActiveInventoryDetail();
        
                  }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                <Block>
                <BlockTitle medium>{`${monthname} - ${year}`}</BlockTitle>
                {
                    ismonthdataloaded? (<div>{
                        inventorylist.map((category)=>{
                        return(
                            <Fragment key={category.id}>
                                <BlockTitle medium>{category.title}</BlockTitle>
                                <List accordionList>
                                {
                                    category.recordlist.map((inventory)=>{

                                    let totaldays = 0;
                                    let totalcost = 0;

                                    return(
                                            <ListItem accordionItem title={inventory.name} key={inventory.id} className="accordion-item-opened2">
                                            <AccordionContent>
                                                <Block>
                                                  <BlockTitle>Pricing Calculation</BlockTitle>
                                                  <Row>
                                                    <Col>
                                                        <List className="pricetype">
                                                        <ListItem radio value="0" name={`pricetype_${inventory.id}`} checked={Number(inventory.pricingtype) === 0} onClick={()=>{this.handlePricingType(category.id,inventory.id, 0)}} title="Day Base"></ListItem>
                                                        </List>
                                                    </Col>
                                                    <Col>
                                                        <List className="pricetype">
                                                        <ListItem radio value="1" name={`pricetype_${inventory.id}`} checked={Number(inventory.pricingtype) === 1} onClick={()=>{this.handlePricingType(category.id,inventory.id, 1)}} title="Date Base"></ListItem>
                                                        </List>
                                                    </Col>
                                                  </Row>
                                                  {(()=>{
                                                  if(Number(inventory.pricingtype) === 0)
                                                  {
                                                    if(inventory.price > 0)
                                                    {
                                                      totaldays += Number(inventory.days);
                                                      totalcost += Number(inventory.price);
                                                    }
                                                      return(
                                                          <List>
                                                          <ListItem title="Total Price">
                                                              <input
                                                              name={`unitprice_${inventory.id}`}
                                                              catid={category.id}
                                                              fieldname="price"
                                                              inventoryid={inventory.id}
                                                              label="Total Price"
                                                              type="number"
                                                              placeholder="Price"
                                                              value={inventory.price}
                                                              onChange={this.handleInventoryInput}
                                                              required
                                                              slot="after"
                                                              style={{width:'100px'}}
                                                              />
                                                          </ListItem>
                                                          <ListItem title="Total Days">
                                                              <input
                                                              name={`days_${inventory.id}`}
                                                              catid={category.id}
                                                              fieldname="days"
                                                              inventoryid={inventory.id}
                                                              label="Total Days"
                                                              type="number"
                                                              placeholder="Days"
                                                              value={inventory.days}
                                                              onChange={this.handleInventoryInput}
                                                              required
                                                              slot="after"
                                                              style={{width:'100px'}}
                                                              />
                                                          </ListItem>
                                                          </List>
                                                      );
                                                  }
                                                  else if(Number(inventory.pricingtype) === 1)
                                                  {
                                                      return(<List>
                                                      {
                                                      inventory.datepricing.map((pricing)=>{
                                                        if(pricing.dateprice > 0)
                                                        {
                                                          totaldays += 1;
                                                          totalcost += Number(pricing.dateprice);
                                                        }
                                                          return(
                                                          <ListItem title={`${pricing.displayname} ${monthname}`} key={pricing.date}>
                                                          {
                                                            pricing.hasholiday ? (<div slot="after" style={{width:'100px',color:'#ff0000'}}>Holiday</div>):(
                                                              <input
                                                                catid={category.id}
                                                                inventoryid={inventory.id}
                                                                date={pricing.date}
                                                                fieldname="dateprice"
                                                                name={`dateprice_${inventory.id}_${pricing.date}`}
                                                                label="Price"
                                                                type="number"
                                                                placeholder="Price"
                                                                value={pricing.dateprice}
                                                                onChange={this.handleInventoryDatePrice}
                                                                required
                                                                slot="after"
                                                                disabled={pricing.disableprice}
                                                                style={{width:'100px'}}
                                                              />
                                                            )
                                                          }
                                                          </ListItem>
                                                          )
                                                      })
                                                      }
                                                      </List>);
                                                  }
                                                  })()}
                                                  <List>
                                                    <ListItem className="monthpricetotal" title={`Total Days : ${totaldays}`}>
                                                      <div slot="after" style={{width:'100px',fontWeight:'bold'}}>{totalcost.toFixed(2)}</div>
                                                    </ListItem>
                                                  </List>
                                                  <br/>
                                                </Block>
                                            </AccordionContent>
                                            </ListItem>
                                        );
                                    })
                                }
                                </List>
                            </Fragment>
                            );
                        })
                    }</div>):(<AppListItemLoading />)
                }
                <br /><br />
                </Block>
                {
                    ismonthdataloaded ? (
                        <Toolbar position="bottom">
                            <Row style={{width:'100%'}}>
                                <Col width="60">
                                <Button fill color="orange" style={{marginRight:'.4rem',marginLeft:'.4rem'}}
                                onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    this.$f7.views.main.router.navigate(`/addholiday/pricing/${year}/${month}/${monthname}/`);
                                  }
                                }}
                                ><Icon f7="calendar_badge_plus" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Add Holiday</Button>
                                </Col>
                                <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                                onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    this.saveInventoryPricing();
                                  }
                                }}
                                ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                            </Row>
                        </Toolbar>
                    ):null
                }
            </Page>
      );
    }
}