import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    BlockTitle,
    Link
  } from 'framework7-react';

  export default class AddHoliday extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        holidaytype:0,
        customerlist:[],
        customerid:'',
        checkcustomerid:'',
        customername:'Select',
        inventorytype:0,
        inventorylist:[],
        inventoryid:'',
        inventoryname:'Select',
        startdate:'',
        enddate:'',
        reason:'',
        hassmartcustomer:false,
        hassmartinventory:false,
        filtervars:[],
      };

      this.handleUserInput            = this.handleUserInput.bind(this);
      this.customerAutocompletePopup  = this.customerAutocompletePopup.bind(this);
      this.inventoryAutocompletePopup = this.inventoryAutocompletePopup.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{
        
        let{customerid} = this.state.filtervars;

        if(Number(customerid) < 1 || customerid === "NaN")
        {
          customerid  = "";
        }

        if(customerid > 0)
        {
          this.setState({
            customerid:customerid,
            checkcustomerid:customerid,
            holidaytype:1
          },()=>{
            this.hideSplashScreen();
            this.getDetail();
          })
        }
        
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteCustomerPopup.destroy();
      self.autocompleteInventoryPopup.destroy();

    }

    /*componentWillReceiveProps(){
      
      if(this.props.reload === true)
      {
        Promise.all([this.getAllCustomers()])
        .then(() => {
            this.getAllInventory();
    	  })
      } 
    }*/

    customerAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteCustomerPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-customer-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectcustomer/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
            Mode:'GetAllSubscribeCustomer',
            clientid:self.state.clientdetail.id,
            loginlinemanid:Number(self.state.clientdetail.linemanid),
            ismanager:Number(self.state.clientdetail.ismanager),
            areamanagerid:Number(self.state.clientdetail.areamanagerid),
            areaids:self.state.clientdetail.areaids,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
              Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    customerlist:res.recordlist,
                  }
                },()=>{

                  const searchcustomerlist  = self.state.customerlist;

                  for (let i = 0; i < searchcustomerlist.length; i += 1) {
                    if (searchcustomerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchcustomerlist[i].phone.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchcustomerlist[i].customerid.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchcustomerlist[i]);
                  }
                  
                })
              }

              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-customer-popup').find('.item-title').text(value[0].name);
            $('#autocomplete-customer-popup').find('.item-content').removeClass('error');
            
            /*$('#autocomplete-line-popup').find('input').val(value[0].id);*/

            self.setState(()=>{
              return{
                customerid:value[0].id,
                customername:value[0].name,
              }
            },()=>{
              //self.getInventoryDetail();
            })

          },
        },
      });
    }

    inventoryAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteInventoryPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-inventory-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectinventory/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
            Mode:'GetAllInventory',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    inventorylist:res.inventorylist,
                  }
                },()=>{

                  const searchinventorylist  = self.state.inventorylist;

                  for (let i = 0; i < searchinventorylist.length; i += 1) {
                    if (searchinventorylist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchinventorylist[i]);
                  }
                  
                })
              }

              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-inventory-popup').find('.item-title').text(value[0].name);
            $('#autocomplete-inventory-popup').find('.item-content').removeClass('error');
            
            /*$('#autocomplete-line-popup').find('input').val(value[0].id);*/

            self.setState(()=>{
              return{
                inventoryid:value[0].id,
                inventoryname:value[0].name,
              }
            })

          },
        },
      });
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{
        if(name === 'holidaytype')
        {
          if(value > 0)
          {
            if(this.state.hassmartcustomer === false)
            {
              this.setState({
                hassmartcustomer:true
              },()=>{
                this.customerAutocompletePopup();
              })
            }
          }
          else
          {
            this.setState({hassmartcustomer:false,hassmartinventory:false});

            if(this.state.hassmartinventory === false)
            {
              this.setState({
                hassmartinventory:true
              },()=>{
                this.inventoryAutocompletePopup();
              })
            }
          }
        }
        if(name === 'inventorytype' && value > 0)
        {
          this.inventoryAutocompletePopup();
        }
      });
    }

    getDetail(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {          
        return;
      }

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      const bodyFormData = {
        Mode:'GetCustomerDetail',
        recordid:self.state.customerid,
      };

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

        // Hide Pull To Refresh
        app.ptr.done();

        app.preloader.hide();

        const resdata = JSON.parse(res.data);

        if(resdata.success === true)
        {
          this.setState(()=>{
            return{
              customername:`#${resdata.customerdetail.customerid} ${resdata.customerdetail.name} (${resdata.customerdetail.phone})`,
            }
          });
        }

      }).catch(e => {
          console.log(e);
      });
    }

    saveDetail(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {        
        return;
      }

      let errmsg    = "";

      app.input.validateInputs('.adddata');

      /*let d = new Date(value);

      let newdate = d.getMonth()+"/"+d.getDate()+"/"+d.getFullYear();*/

      /*if((Number(this.state.holidaytype) > 0 && this.state.customerid === "") || (Number(this.state.inventorytype) > 0 && this.state.inventoryid === ""))
      {
        errmsg  = "Please enter all required field.";
      }*/
      if((Number(this.state.holidaytype) > 0 && this.state.customerid === ""))
      {
        this.$$('#autocomplete-customer-popup').find('.item-content').addClass('error');
        errmsg  = "Please select a customer.";
      }
      else
      {
        this.$$('#autocomplete-customer-popup').find('.item-content').removeClass('error');
      }
      if((Number(this.state.inventorytype) > 0 && this.state.inventoryid === "") && Number(this.state.holidaytype) > 0 && errmsg == '')
      {
        this.$$('#autocomplete-inventory-popup').find('.item-content').addClass('error');
        errmsg  = "Please select an inventory.";
      }
      else
      {
        this.$$('#autocomplete-inventory-popup').find('.item-content').removeClass('error');
      }
      if((this.state.startdate === "" || this.state.enddate === "") && errmsg == "")
      {
        errmsg  = "Start Date and End Date is required.";
      }
      if(errmsg != "")
      {
        const toastWithButton = app.toast.create({
            text: errmsg,
            closeButton: true,
            closeTimeout: 3000,
        });

        toastWithButton.open();
        return false;
      }

      /*const startdate       = new Date(this.state.startdate);
      const tempStartDate   = startdate.getMonth()+"/"+startdate.getDate()+"/"+startdate.getFullYear();

      const enddate       = new Date(this.state.enddate);
      const tempEndDate   = enddate.getMonth()+"/"+enddate.getDate()+"/"+enddate.getFullYear();*/

      const bodyFormData = {
          Mode:'AddHoliday',
          ClientID:self.state.clientdetail.id,
          CustomerType:self.state.holidaytype,
          CustomerID:self.state.customerid,
          InventoryType:self.state.inventorytype,
          InventoryID:self.state.inventoryid,
          StartDate:self.state.startdate,
          EndDate:self.state.enddate,
          Reason:self.state.reason,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/holiday.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            if(resdata.success !== true)
            {
              toastWithButton.open();
            }

            if(resdata.success === true)
            {
              Promise.all([
                /*this.setState(()=>{
                  return{
                        inventoryid:'',
                        customerid:'',
                    }
                })*/
              ])
              .then(() => {

                this.Dom7('.item-content').removeClass('item-input-with-error-message');
                this.Dom7('.item-content').removeClass('item-input-invalid');
                //this.props.fetchNewLineman(resdata.recordid, resdata.name);
                if(this.props.f7route.params.type === 'pricing')
                {
                  this.$f7.views.main.router.navigate(`/updatemonthprice/${this.props.f7route.params.year}/${this.props.f7route.params.month}/${this.props.f7route.params.monthname}/`);
                }
                else
                {
                  this.$f7.views.main.router.navigate(`thanks/addholidaysuccess/`);
                }

              });

            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){
       
        const{holidaytype, inventorytype, startdate, enddate, reason, customerid, checkcustomerid, customername} = this.state;

      return(
            <Page pageContent={false} className="page" name="AddHoliday">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Add Holiday</NavTitle>
                    <NavRight className="right-with-menu">
                    </NavRight>
                  </Navbar>
                  <PageContent>
                  <Block>
                      <List noHairlinesMd>
                      {
                        !checkcustomerid ? <BlockTitle style={{marginLeft:'15px'}}>Holiday Type</BlockTitle>:null
                      }
                      {
                        !checkcustomerid ? (
                          <Row noGap>
                            <Col width="50">
                              <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                  <ListItem radio value={0} name="holidaytype"
                                  onChange={this.handleUserInput} title="All Customer"
                                  checked={Number(holidaytype) === 0}
                                  >
                                  </ListItem>
                                </List>
                            </Col>
                            <Col width="50">
                              <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListItem radio value={1} name="holidaytype" 
                                  onChange={this.handleUserInput} title="Single Customer" 
                                  checked={Number(holidaytype) === 1}
                                  >
                                </ListItem>
                              </List>
                            </Col>
                          </Row>
                        ):null
                      }
                      {(()=>{
                          if(Number(holidaytype) > 0 && checkcustomerid > 0)
                          {
                            return(
                              <ListItem id="autocomplete-customer-popup" title="Customer" after={`${customername}`}>
                                <input name="customerid" type="hidden" value={customerid}/>
                              </ListItem>
                            );
                          }
                          else if(Number(holidaytype) > 0 && checkcustomerid < 1)
                          {
                            return(
                              <ListItem link="#" id="autocomplete-customer-popup" title="Customer" after="Select">
                                <input name="customerid" type="hidden" value={customerid}/>
                              </ListItem>
                            );
                          }
                      })()}
                      </List>
                      {(()=>{
                        if(Number(holidaytype) < 1)
                        {
                          return (
                            <List noHairlinesMd>
                                <BlockTitle style={{marginLeft:'15px'}}>Stock Type</BlockTitle>
                                <Row noGap>
                                  <Col width="50">
                                    <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                      <ListItem radio value={0} name="inventorytype"
                                      onChange={this.handleUserInput} title="Global"
                                      checked={Number(inventorytype) === 0}
                                      >
                                      </ListItem>
                                    </List>  
                                  </Col>
                                  <Col width="50">
                                      <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                      <ListItem radio value={1} name="inventorytype"
                                        onChange={this.handleUserInput} title="Single Stock" 
                                        checked={Number(inventorytype) === 1}
                                        >
                                        </ListItem>
                                      </List>
                                  </Col>
                                </Row>
                                {(()=>{
                                    if(Number(inventorytype) > 0)
                                    {
                                      return(
                                        <ListItem link="#" id="autocomplete-inventory-popup" title="Stock" after="Select">
                                          <input name="inventoryid" type="hidden" value={this.state.inventoryid}/>
                                        </ListItem> 
                                      );
                                    }
                                })()}
                            </List>
                          )
                        }
                      })()}
                      <List noHairlinesMd>
                        <ListInput
                          name="startdate"
                          id="startdate"
                          className="adddata"
                          label="Start Date"
                          placeholder="Start Date"
                          type="date"
                          value={startdate}
                          onChange={this.handleUserInput}
                          calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                          required validate
                        >
                        </ListInput>

                        <ListInput
                          name="enddate"
                          id="enddate"
                          className="adddata"
                          label="End Date"
                          placeholder="End Date"
                          type="date"
                          value={enddate}
                          onChange={this.handleUserInput}
                          calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                          required validate
                        >
                        </ListInput>

                        <ListInput
                            name="reason"
                            label="Reason"
                            floatingLabel
                            type="textarea"
                            onInput={this.handleUserInput}
                            value={reason}
                            resizable
                            placeholder="Remarks"
                        >
                        </ListInput>
                      </List>
                  </Block>
                <br /><br />
              </PageContent>
              <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
            </Page>
      );
    }
}