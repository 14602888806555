import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Link,
    Block,
    Row,
    Col,
    Fab,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
  } from 'framework7-react';

  export default class CustomerListMagazine extends Component {
    
    constructor(props) {
      super(props);

      this._isMounted = false;
     
      this.state = {
        clientdetail:[],
        isfetched:false,
        recordset:[],
        inventorylist:[],
        isptr:false,
        circulationdate:'',
        ismount:false,
        hasallcustomer:true,
        inventoryid:'',
        inventoryname:'',
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        hasdetail:false,
        detail:[],
        effect:'blink',
        cattype:0,
        hasdataavailable:false,
        totalsubscription:0,
        totalrecord:0
      };
      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getInventoryCustomer = this.getInventoryCustomer.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
    }
    
    componentDidMount(){

      Promise.all([

        this._isMounted = true

      ])
      .then(() => {

        if(this._isMounted)
        {
          let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
  
          this.setState(()=>{
            return{
              clientdetail:clientdetail,
            }
          },()=>{
    
            setTimeout(()=> {
    
              this.setState({ismount:true});
    
            }, 1200);
    
            this._isMounted && this.getInventoryCustomer();
            this._isMounted && this.hideSplashScreen();
          });
    
        }
        
      })

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getInventoryCustomer(){
      
        const self      = this;
        const app       = self.$f7;

        this.setState({
          isfetched:false
        });
  
        const bodyFormData = {
            Mode:'GetCustomerInventorySummary',
            clientid:self.state.clientdetail.id,
            inventoryid:self.state.inventoryid,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            lineid:self.state.lineid,
            linemanid:self.state.linemanid,
            hawkerid:self.state.hawkerid,
            areaid:self.state.areaid,
            cattype:self.state.cattype,
        };
    
        // Simulate Ajax Request
        setTimeout(()=> {
  
            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
  
            app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                // We got user data from request
  
                // Hide Preloader
                app.preloader.hide();
  
                // Hide Pull To Refresh
                app.ptr.done();                
  
                const resdata = JSON.parse(res.data);
  
                if(resdata.success === true)
                {
                  let temphasdataavailable  = true;

                  if(resdata.hasdetail === true)
                  {
                    temphasdataavailable  = false;
                  }

                  this._isMounted && this.setState({
  
                      inventorylist:resdata.inventorylist,
                      detail:resdata.recordset,
                      hasdetail:resdata.hasdetail,
                      isptr:false,
                      isfetched:true,
                      hasdataavailable:temphasdataavailable,
                      totalsubscription:resdata.totalsubscription,
                      totalrecord:resdata.totalrecord
  
                  },()=>{
  
                    this.Dom7('.subscription').addClass('animate-bottom');
  
                  });
                }
                else
                {
                  this._isMounted && this.setState({

                    inventorylist:'',
                    isptr:false,
                    isfetched:true,
                    hasdataavailable:false,
                    totalrecord:"0"
  
                  });
                }
  
            }).catch(e => {
                console.log(e);
            });
  
        },500);
    }

    getInventoryCustomerPDF(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetCustomerInventorySummaryPDF',
        clientid:self.state.clientdetail.id,
        inventoryid:self.state.inventoryid,
        stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        lineid:self.state.lineid,
        linemanid:self.state.linemanid,
        hawkerid:self.state.hawkerid,
        areaid:self.state.areaid,
        cattype:self.state.cattype,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      this._isMounted && setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                  /*window.open(resdata.pdffilepath);*/
                  window.open(resdata.filepath);
              }
              else
              {
                  const toastWithButton = app.toast.create({
                      text: resdata.msg,
                      closeButton: true,
                      closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, inventoryid, inventoryname){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            lineid:lineid,
            linemanid:linemanid,
            hawkerid:hawkerid,
            filterarea:filterarea,
            filterline:filterline,
            filterlineman:filterlineman,
            filterhawker:filterhawker,
            inventoryid:inventoryid,
            inventoryname:inventoryname,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
          }
  
        },()=>{
  
          if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || Number(this.state.inventoryid) > 0)
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getInventoryCustomer();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        Promise.all([

          this._isMounted = false

        ])
        .then(() => {      
          
            this.$f7router.navigate(`/${link}/`);
  
        })
      }

    }

    componentWillUnmount(){

      this._isMounted = false;

    }

    render(){
        
      const{isfetched, circulationdate, hasallcustomer, inventoryid, areaid, lineid, hawkerid, hasfilter, filterarea, filterline, filterlineman, filterhawker, ismount, inventoryname, inventorylist, hasdetail, detail, hasdataavailable, totalsubscription, totalrecord} = this.state;

      let date  = "";

      let newcirculationdate  = "";

      if(circulationdate !== "" && circulationdate !== undefined)
      {
        newcirculationdate = new Date(circulationdate);

        let currmonth = newcirculationdate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        date = newcirculationdate.getDate()+"-"+currmonth+"-"+newcirculationdate.getFullYear();
      }

      return(
        <Page className="page" name="CustomerListMagazine">
        <Navbar sliding={true}>
            <NavLeft>
              <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
              <Link href={`/dashboard/`} className="clearmenupadding">
                <img className="navbarlogo" src={scanlogo} alt="logo" />
              </Link>
            </NavLeft>
            <NavTitle>Customer List By Magazine</NavTitle>
            <NavRight className="right-with-menu">
            <Link onClick={()=>{

              this.getInventoryCustomer();

            }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
            </NavRight>
        </Navbar>
      <FilterConsumer>{(value)=>{

        const{customerlistfilterdata, applyCustomerListFilter} = value;

        if(ismount === true)
        {
          setTimeout(()=> {

            if(this.state.removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(this.state.removefiltername === 'area')
              {
                applyCustomerListFilter(this.state.lineid, this.state.linemanid, this.state.hawkerid, this.state.filterline, this.state.filterlineman, this.state.filterhawker, '', '', this.state.inventoryid, this.state.inventoryname);
              }
              else if(this.state.removefiltername === 'line')
              {
                applyCustomerListFilter('', this.state.linemanid, this.state.hawkerid, '', this.state.filterlineman, this.state.filterhawker, areaid, filterarea, this.state.inventoryid, this.state.inventoryname);
              }
              else if(this.state.removefiltername === 'lineman')
              {
                applyCustomerListFilter(this.state.lineid, '', this.state.hawkerid, this.state.filterline, '', this.state.filterhawker, areaid, filterarea, this.state.inventoryid, this.state.inventoryname);
              }
              else if(this.state.removefiltername === 'hawker')
              {
                applyCustomerListFilter(this.state.lineid, this.state.linemanid, '', this.state.filterline, this.state.filterlineman, '', areaid, filterarea, this.state.inventoryid, this.state.inventoryname);
              }
              else if(this.state.removefiltername === 'inventory')
              {
                applyCustomerListFilter(this.state.lineid, this.state.linemanid, this.state.hawkerid, this.state.filterline, this.state.filterlineman, this.state.filterhawker, areaid, filterarea,'','');
              }
            }

            if(((customerlistfilterdata.lineid !== this.state.lineid) && customerlistfilterdata.lineid !== undefined) || ((customerlistfilterdata.linemanid !== this.state.linemanid) && customerlistfilterdata.linemanid !== undefined) || ((customerlistfilterdata.hawkerid !== this.state.hawkerid) && customerlistfilterdata.hawkerid !== undefined) || ((customerlistfilterdata.areaid !== areaid) && customerlistfilterdata.areaid !== undefined) || ((customerlistfilterdata.inventoryid !== this.state.inventoryid) && customerlistfilterdata.inventoryid !== undefined) || this.state.applyremovefilter === true)
            {
              this.setNewFilterData(customerlistfilterdata.lineid, customerlistfilterdata.linemanid, customerlistfilterdata.hawkerid, customerlistfilterdata.filterline, customerlistfilterdata.filterlineman, customerlistfilterdata.filterhawker, customerlistfilterdata.areaid, customerlistfilterdata.filterarea, customerlistfilterdata.inventoryid, customerlistfilterdata.inventoryname);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }

          }, 500)
        }
        
      return(
            <Block>
              {
                hasfilter ? (
                  <Block strong className="selectedfilter" style={{marginTop:0}}>
                    {hasallcustomer ? (<Chip text={`Stock : All`} mediaBgColor="blue" media="A"/>):null}
                    {date ? (<Chip text={`Date : ${date}`} mediaBgColor="blue" media={`D`} deleteable
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'circulationdate'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'area'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(lineid) > 0 && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="pink" media={filterline.toUpperCase().substring(0, 1)} deleteable
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'line'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="green" media={filterlineman.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'lineman'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(hawkerid) > 0 && filterhawker !== "" ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'hawker'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(inventoryid) > 0 && inventoryname !== "" ? (<Chip text={`Stock : ${inventoryname}`} mediaBgColor="orange" media={inventoryname.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'inventory'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.$f7router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&inventoryid=${inventoryid}&inventoryname=${inventoryname}`);
                        }
                      /*this.openFilterPopup();*/
                    }}/>
                  </Block>
                ):null
              }
              {
                isfetched ? (<Fragment>
                {
                  hasdataavailable ? (<Fragment>
                  <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center',fontSize:'1.2rem'}}>Total Records : {totalrecord}</BlockTitle>
                  {
                    hasdetail ? (
                          <div className="subscription">
                          {
                              detail.subscriptionlist ? (<Fragment>
                              <BlockTitle medium style={{textAlign:'center'}}>{detail.inventoryname}</BlockTitle>
                              <Row noGap className='rowwithbottomborder'>
                                  <Col width='15' className="tableheader textalignleft">
                                      S.No.
                                  </Col>
                                  <Col width='45' className="tableheader textalignleft">
                                      Customer
                                  </Col>
                                  <Col width='40' className="tableheader">
                                      Date
                                  </Col>
                              </Row>
                              {
                                  detail.subscriptionlist.map((inventory)=>{

                                  return(
                                      <Row key={inventory.customerid} noGap className='rowwithbottomborder'>
                                          <Col width='15' className="ledgercolumn textalignleft">
                                          {inventory.serialno}
                                          </Col>
                                          <Col width='45' className="ledgercolumn textalignleft">
                                          {inventory.name}
                                          </Col>
                                          <Col width='40' className="ledgercolumn">
                                          {inventory.date}
                                          </Col>
                                      </Row>
                                      );
                                  })
                              }
                              </Fragment>):null
                          }
                          </div>
                    ):(<div className="subscription">
                    {
                      inventorylist ? (<Fragment>
                        <Row noGap className='rowwithbottomborder'>
                            <Col width='25' className="tableheader textalignleft">
                                S.No.
                            </Col>
                            <Col width='40' className="tableheader textalignleft">
                                Inventory
                            </Col>
                            <Col width='25' className="tableheader textalignright">
                                Subscription
                            </Col>
                            <Col width='10' className="tableheader textalignright">
                                &nbsp;
                            </Col>
                        </Row>
                        {
                          inventorylist.map((inventory)=>{

                            let tempurl = `/${inventory.id}`;

                            if(Number(areaid) > 0)
                            {
                              tempurl += `/${areaid}`;
                            }
                            else
                            {
                              tempurl += "/undefined";
                            }

                            if(Number(lineid) > 0)
                            {
                              tempurl += `/${lineid}`;
                            }
                            else
                            {
                              tempurl += "/undefined";
                            }

                            if(Number(hawkerid) > 0)
                            {
                              tempurl += `/${hawkerid}`;
                            }
                            else
                            {
                              tempurl += "/undefined";
                            }

                            return(
                              <Row key={inventory.id} noGap className='rowwithbottomborder' onClick={()=>{this.navigateToLink(`inventorydetail${tempurl}`)}}>
                                  <Col width='25' className="ledgercolumn textalignleft">
                                    {inventory.serialno}
                                  </Col>
                                  <Col width='40' className="ledgercolumn textalignleft">
                                    {inventory.name}
                                  </Col>
                                  <Col width='25' className="ledgercolumn">
                                    {inventory.totalinventory}
                                  </Col>
                                  <Col width='10' className="ledgercolumn">
                                    <Icon f7="chevron_right"></Icon>
                                  </Col>
                              </Row>
                              );
                          })
                        }
                        <Row noGap className='rowwithbottomborder'>
                            <Col width='25' className="ledgercolumn textalignleft">
                              &nbsp;
                            </Col>
                            <Col width='40' className="ledgercolumn textalignleft">
                              <b>Total Subscription</b>
                            </Col>
                            <Col width='25' className="ledgercolumn">
                              {totalsubscription}
                            </Col>
                            <Col width='10' className="ledgercolumn">
                              &nbsp;
                            </Col>
                        </Row>
                      </Fragment>):null
                    }
                    </div>)
                  }
                  </Fragment>):(<AppItemNotFound notfoundtext ="No record found." />)
                }
                </Fragment>):(<AppListItemLoading />)
              }
              <br /><br /><br />
            </Block>
          );

        }}
        </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {
            hasdataavailable ? (
               <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                    if(this.$f7.online)
                    {
                      this.getInventoryCustomerPDF();
                    }
                  }}>
                  <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                  <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                </Fab>
            ):null
        }
        </Page>

      );
    }
}