import React from 'react';
import AppMenu from '../pages/AppMenu';
import { FilterProvider } from '../context/FilterContext';
import { CustomerOutstandingProvider } from '../context/CustomerOutstandingContext';
import { CustomerProvider } from '../context/CustomerContext';
import { ReportProvider } from '../context/ReportContext';
import { CampaignProvider } from '../context/CampaignContext';
import { CommonProvider } from '../context/CommonContext';
import { WebsiteProvider } from '../context/WebsiteContext';
import { HolidayProvider } from '../context/HolidayContext';
import { SaleProvider } from '../context/SaleContext';
import { SMSCreditProvider } from '../context/SMSCreditContext';

import {
  App,
  View
} from 'framework7-react';

import routes from '../js/routes';

export default class extends React.Component {
  constructor() {
    super();

    let serverurl = "/";

    if(process.env.NODE_ENV === "development")
    {
      serverurl = "http://orlopay/agency/";
    }

    this.state = {
      // Framework7 Parameters
      f7params: {
        name: 'orlopay', // App name
        theme: 'auto', // Automatic theme detection
        serverurl: serverurl, // App server url
        internetstatus:navigator.onLine ? "online" : "offline", // Current internet status
        // App routes
        routes: routes,
      },
      // Login screen demo data
      username: '',
      password: '',
      isloaded: '',
    };

    this.updateOnlineStatus   = this.updateOnlineStatus.bind(this);
  }

  componentDidMount() {

    this.$f7ready(() => {

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
  
      // Call F7 APIs here
    });
  }

  updateOnlineStatus(){

    if(this.state.isloaded  === true)
    {
      let intstatus	= "offline";

      intstatus		= navigator.onLine ? "online" : "offline";

      this.setState(()=>{
          return{

            f7params: {
              name: 'orlopay', // App name
              theme: 'auto', // Automatic theme detection
              serverurl: this.state.f7params.serverurl, // App server url
              internetstatus:intstatus,
              // App routes
              routes: this.state.f7params.routes,
            }

          };
      },()=>{

          const self      = this;
          const app       = self.$f7;

          if(this.state.f7params.internetstatus === "offline")
          {
            const toastWithButton = app.toast.create({
              text: "No internet connection",
            });
              
            toastWithButton.open();
          }
          else
          {
            const toastWithButton = app.toast.create({
              text: "Internet connected",
              closeButton: true,
              closeTimeout: 3000,
            });
            
            toastWithButton.open();
          }
      });
    }
    else
    {
      this.setState({
        isloaded:true
      });
    }
  }

  render() {
    return (
      <CommonProvider>
        <FilterProvider>
          <CustomerProvider>
            <CustomerOutstandingProvider>
              <ReportProvider>
                <CampaignProvider>
                  <WebsiteProvider>
                    <HolidayProvider>
                      <SaleProvider>
                        <SMSCreditProvider>
                          <App params={ this.state.f7params } >
                            <AppMenu />
                            {/* Your main view, should have "view-main" class 
                            <View main className="safe-areas" url="/" pushState={true} pushStateSeparator="" passRouteParamsToRequest={true} pushStateOnLoad={true} />*/}
                            <View main className="safe-areas" url="/" pushState={true} passRouteParamsToRequest={true} pushStateOnLoad={true} cache={false}/>
                            
                          </App>
                        </SMSCreditProvider>
                      </SaleProvider>
                    </HolidayProvider>
                  </WebsiteProvider>
                </CampaignProvider>
              </ReportProvider>
            </CustomerOutstandingProvider>
          </CustomerProvider>
        </FilterProvider>
      </CommonProvider>
    )
  }
}