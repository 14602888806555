import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';

import { CustomerOutstandingConsumer } from '../context/CustomerOutstandingContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Toolbar,
    Link,
    Block,
    List,
    Row,
    Col,
    ListInput,
    Icon,
    Chip,
  } from 'framework7-react';

  export default class Leads extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isdataloaded:false,
        clientdetail:[],
        filtervars:[],
      }

    }

    componentDidMount(){

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        this.setState(()=>{
            return{
              clientdetail:clientdetail,
              filtervars:this.props.$f7route.query
            }
        })
    }

    render(){
      
        return(
            <CustomerOutstandingConsumer>{(value)=>{
              const{handleUserInput, getOutstandingReport, init, openFilterPopup, navigateToLink, Paging, deleteFilterAction, manageOutstandingAction} = value;

              const{hasfilter, hasallcustomer, filterarea, filterline, filterlineman, filterhawker, isfetched, hasdataavailable, totalrecord, totaloutstandingbalance, recordset, showpages, page, circulationdate, paginglist, totalpages, toolbarPosition, areaid, lineid, hawkerid} = value;

              let extraspaceclass = "";

              if(showpages)
              {
                extraspaceclass = "withtoolbar";
              }

                setTimeout(() => {
                  if(this.state.isdataloaded === false)
                  {
                    this.setState(()=>{
                      return{
                        isdataloaded:true,
                      }
                    },()=>{

                      init(this.state.filtervars);
                      
                    })
                    
                  }
                }, 100);

                let date  = "";

                let newcirculationdate  = "";

                if(circulationdate !== "" && circulationdate !== undefined)
                {
                  newcirculationdate = new Date(circulationdate);
          
                  let currmonth = newcirculationdate.getMonth()+1;
                  if(currmonth < 10)
                  {
                      currmonth = "0"+currmonth;
                  }
                  date = newcirculationdate.getDate()+"-"+currmonth+"-"+newcirculationdate.getFullYear();
                }

                return(
                    <Page pageContent={false} className="page" name="CustomersOutstanding">
                        <Navbar sliding={true}>
                          <NavLeft>
                            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                            <Link href={`/dashboard/`} className="clearmenupadding">
                              <img className="navbarlogo" src={scanlogo} alt="logo" />
                            </Link>
                          </NavLeft>
                          <NavTitle>Outstanding</NavTitle>
                          <NavRight className="right-with-menu">
                          <Link onClick={()=>{

                            getOutstandingReport();

                          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                          </NavRight>
                        </Navbar>
                        <PageContent>
                        {
                          isfetched ? (<Fragment>
                            {
                              hasdataavailable ? (<Fragment>
                                <BlockTitle className="totalrec">Total Records : {totalrecord}</BlockTitle>
                                <BlockTitle className="totalrec">Total Outstanding : {totaloutstandingbalance}</BlockTitle>
                              </Fragment>):null
                            }
                              {
                                  hasfilter ? (
                                  <Block strong className="selectedfilter" style={{marginTop:0}}>
                                    {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}

                                    {date ? (<Chip text={`Campaign : ${campname}`} mediaBgColor="orange" media={campname.toUpperCase().substring(0, 1)} deleteable onClick={()=>{
                                        if(this.$f7.online)
                                        {
                                          deleteFilterAction('campaign');
                                        }
                                      }}
                                    />):null}

                                    {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                                    onClick={()=>{
                                            if(this.$f7.online)
                                            {
                                              deleteFilterAction('area');
                                            }
                                          }}
                                    />):null}

                                    {Number(lineid) > 0 && filterline ? (<Chip text={`Line : ${filterline}`} mediaBgColor="yellow" media={filterline.toUpperCase().substring(0, 1)} deleteable
                                    onClick={()=>{
                                            if(this.$f7.online)
                                            {
                                              deleteFilterAction('line');
                                            }
                                          }}
                                    />):null}

                                    {filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="green" media={filterlineman.toUpperCase().substring(0, 1)} deleteable 
                                    onClick={()=>{
                                        if(this.$f7.online)
                                        {
                                          deleteFilterAction('lineman');
                                        }
                                      }}
                                    />):null}

                                    {Number(hawkerid) > 0 && filterhawker ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                                    onClick={()=>{
                                            if(this.$f7.online)
                                            {
                                              deleteFilterAction('hawker');
                                            }
                                          }}
                                    />):null}

                                    &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                      if(this.$f7.online)
                                      {
                                        this.$f7router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}`);
                                      }
                                      /*openFilterPopup();*/
                                    }}/>
                                  </Block>
                                ):null
                              }
                              {
                                hasdataavailable ? (
                                  <div className="outstandingwrapper">
                                    <Row noGap className='rowwithbottomborder' style={{borderTop:'1px solid #000000', borderBottom:'1px solid #000000', fontWeight:'bold'}}>
                                        <Col width='35' className="tableheader1 textalignleft">
                                            Name
                                        </Col>
                                        <Col width='20' className="tableheader1 textalignleft">
                                            Address
                                        </Col>
                                        <Col width='15' className="tableheader1 textalignright">
                                            Due
                                        </Col>
                                        <Col width='20' className="tableheader1 textalignright">
                                            &nbsp;
                                        </Col>
                                    </Row>
                                    {
                                      recordset.detail.map((detail)=>{

                                        return(
                                          <Row key={detail.id} noGap className='rowwithbottomborder' style={{color:detail.blockcolor}}>
                                              <Col width='35' className="ledgercolumn textalignleft">
                                                {detail.name}
                                                {
                                                  detail.phone ? (<span>
                                                    <br />
                                                    <Link external href={`tel:${detail.phone}`} target="_system" style={{color:detail.blockcolor}}>
                                                    {detail.phone} <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                    </Link>
                                                  </span>):null
                                                }
                                              </Col>
                                              <Col width='30' className="ledgercolumn textalignleft">
                                                {detail.address}
                                              </Col>
                                              <Col width='15' className="ledgercolumn textalignright">
                                                {detail.amount}
                                              </Col>
                                              <Col width='20' className="ledgercolumn">
                                                <Link onClick={()=>{
                                                  if(this.$f7.online)
                                                  {
                                                    manageOutstandingAction(detail.id, detail.phone, detail.hasinvoiceid, detail.invoiceid);
                                                  }
                                                }}><Icon ios="f7:line_horizontal_3" aurora="f7:line_horizontal_3" md="material:dashboard"></Icon></Link>

                                                &nbsp;&nbsp;

                                                <Link onClick={() =>{

                                                  if(this.$f7.online)
                                                  {
                                                    this.$f7router.navigate(`/makepayment/${detail.id}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&fromarea=customersoutstanding`);
                                                  }
                                                  
                                                }}><Icon f7="chevron_right_circle_fill"></Icon></Link>
                                              </Col>
                                          </Row>
                                        );
                                      })
                                    }
                                    <br /><br /><br />
                                  </div>
                                ):(<AppItemNotFound notfoundtext ="No record found."/>)
                              }
                              </Fragment>):(<AppListItemLoading />)
                        }
                        <br /><br />
                        </PageContent>
                        {
                          hasdataavailable  && showpages ? (
                            <Toolbar position={toolbarPosition}>
                            {
                              (()=>{
                                if(Number(page) > 1)
                                {
                                  return(
                                    <Link className="paging" onClick={()=>{Paging('previous')}}>
                                    <Icon f7="arrow_left_square_fill" ></Icon>
                                    </Link>
                                  );
                                }
                                else
                                {
                                  return(
                                    <Link href="#"></Link>
                                  );
                                }
                              })()
                            }
                              <List className="droppage" noHairlines>
                                  <ListInput
                                      name="page"
                                      type="select"
                                      value={page}
                                      onChange={handleUserInput}
                                      placeholder="Please choose..."
                                      >
                                      {
                                        paginglist.map((pages)=>{
                                        return(<option key={pages.index} value={pages.page}>{pages.name}</option>);
                                        })
                                      }
                                  </ListInput>
                              </List>
                              {
                                (()=>{
                                  if(Number(page) < Number(totalpages))
                                  {
                                    return(
                                      <Link className="paging" onClick={()=>{Paging('next')}}>
                                      <Icon f7="arrow_right_square_fill" ></Icon>
                                      </Link>
                                    );
                                  }
                                  else
                                  {
                                    return(
                                      <Link href="#"></Link>
                                    );
                                  }
                                })()
                              }
                            </Toolbar>
                          ):null
                        }
                        {/*
                          hasdataavailable ? (
                            <AppFav position="center-bottom" text="Make Payment" icon="plus" url="makepayment" />
                          ):null
                        */}
                    </Page>
                );
            }}
            </CustomerOutstandingConsumer>            
        );
    }
}