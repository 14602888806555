import React,{Component} from 'react';
const SaleContext = React.createContext();
//Provider
//Consumer

class SaleProvider extends Component{

	constructor(props){
    super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startdate = today.getFullYear()+'-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+currday;

      this.state = {
        clientdetail:[],
        isfetched:false,
        sales:[],
        isptr:false,
        ismount:false,
        hasallcustomer:true,
        startdate:startdate,
        enddate:enddate,
        stockid:'-1',
        areaid:'',
        lineid:'',
        hawkerid:'',
        hasfilter:true,
        filterstock:'All Stock',
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        effect:'blink',
        isavailable:false,
        recordid:'',
		salesfilterdata:[],
        toolbarPosition:'bottom',
        page:1,
        perpage:'',
        totalpages:'',
        showpages:false,
        paginglist:[],
        totalrecord:'',
        serialindex:1,
        deleterecordid:'',
        saledate:'',
        saletype:606,
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getAllSale           = this.getAllSale.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
      this.deleteFilterAction   = this.deleteFilterAction.bind(this);
      this.init                 = this.init.bind(this);
      this.deleteRecordAction   = this.deleteRecordAction.bind(this);
	}

  componentDidMount(){

    let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

    this.setState(()=>{
      return{
        clientdetail:clientdetail,
        customerid:''
      }
    },()=>{

      setTimeout(()=> {

        this.setState({ismount:true});

      }, 1200);

      this.hideSplashScreen();
    });

  }

  hideSplashScreen(){
    document.getElementById("splash-screen").style.visibility = "hidden";
  }
  
  handleUserInput(e){

    const name  = e.target.name;
    const value = e.target.value;

    if(name === 'holidaytype')
    {
        this.setState(()=>{
            return{
                [name]: value
            }
        });
    }
    else
    {
        this.setState(()=>{
            return{
                [name]: value
            }
        },()=>{

            this.Paging('select');

        });
    }

  }

  init(filtervars){

    let{stockid, filterstock, areaid, filterarea, lineid, filterline, hawkerid, filterhawker, saletype} = filtervars;

    if(stockid === undefined || stockid === "" || stockid === 'undefined' || stockid === "NaN")
    {
      stockid      = "-1";
      filterstock  = "All Stock";
    }

    if(Number(areaid) < 1 || areaid === "NaN")
    {
      areaid      = "";
      filterarea  = "All Area";
    }

    if(Number(lineid) < 1 || lineid === "NaN")
    {
      lineid      = "";
      filterline  = "All Line";
    }
    
    if(Number(hawkerid) < 1 || hawkerid === "NaN")
    {
      hawkerid      = "";
      filterhawker  = "All Hawker";
    }

    if(saletype === "NaN" || saletype === "" || saletype === undefined)
    {
        saletype = 606;
    }

    this.setState(()=>{
      return{
        stateid:'',
        cityid:'',
        stockid:stockid,
        lineid:lineid,
        hawkerid:hawkerid,
        areaid:areaid,
        filterstock:filterstock,
        filterarea:filterarea,
        filterline:filterline,
        filterlineman:'',
        filterhawker:filterhawker,
        saletype:saletype,
        /*startdate:startdate,
        enddate:enddate,*/
        hasfilter:true,
        hasallcustomer:false,
        page:1,
      }
    },()=>{
      if(Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== "")
      {
        this.setState({hasfilter:true,hasallcustomer:false});
      }
      else
      {
        this.setState({hasfilter:true,hasallcustomer:true});
      }
      this.getAllSale();
    })
  }

  getAllSale(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }
  
      const bodyFormData = {
        Mode:'GetAllSaleNew',
        stockid:self.state.stockid,
        areaid:self.state.areaid,
        lineid:self.state.lineid,
        hawkerid:self.state.hawkerid,
        catid:self.state.saletype,
        startdate:self.state.startdate,
        enddate:self.state.enddate,
      };

      this.setState(()=>{

        return{
          isfetched:false
        }
        
      },()=>{

        setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/sale.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                isfetched:true,
                sales:resdata.recordlist,
                isavailable:true,
                totalrecord:resdata.totalrecord,
              },()=>{

                this.Dom7('.waiting').addClass('animate-bottom');

              });

            }
            else
            {
              this.setState({

                isfetched:true,
                isavailable:false,
                totalrecord:0,

              },()=>{

                this.Dom7('.waiting').addClass('animate-bottom');

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      },500);

    });
  }

  setNewFilterData(stockid, areaid, lineid, hawkerid, filterstock, filterarea, filterline, filterhawker, saletype, startdate, enddate){

    if(!this.$f7.online)
    {
      return;
    }

	let dataset	= [...this.state.salesfilterdata];

	dataset.lineid			= lineid;
	dataset.areaid			= areaid;
	dataset.hawkerid		= hawkerid;
	dataset.stockid			= stockid;
	dataset.filterarea		= filterarea;
	dataset.filterline		= filterline;
	dataset.filterhawker	= filterhawker;
	dataset.filterstock		= filterstock;
	dataset.enddate			= enddate;
	dataset.startdate		= startdate;
	dataset.saletype		= saletype;

	Promise.all([
          
      setTimeout(()=> {

        this.Dom7('.waiting').addClass('animate-bottom');

      }, 50)

    ])
    .then(() => {

      this.setState(()=>{
      
        return{
          saletype:saletype,
          stockid:stockid,
          areaid:areaid,
          lineid:lineid,
          hawkerid:hawkerid,
          filterstock:filterstock,
          filterarea:filterarea,
          filterline:filterline,
          filterhawker:filterhawker,
          startdate:startdate,
          enddate:enddate,
          loaded:false,
          applyfilter:false,
		  salesfilterdata:dataset
		}

      },()=>{
		if(this.state.stockid !== '' || Number(this.state.saletype) > 0 || Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
        {
          this.setState({hasfilter:true,hasallcustomer:false});
        }
        else
        {
          this.setState({hasfilter:true,hasallcustomer:true});
        }

        this.getAllSale();

      });

    })
    
  }

  openFilterPopup(){
      
    if(!this.$f7.online)
    {
      return;
    }

    this.$f7.views.main.router.navigate('filterpopup/');

  }

  deleteFilter(){

    this.setState({
      applyremovefilter:true
    })
  }

  navigateToLink(link){

    if(this.$f7.online)
    {
      this.$f7.views.main.router.navigate(`/${link}/`);
    }

  }

  deleteFilterAction(filtername){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              if(filtername === "area")
              {
                this.setState(()=>{
                  return{
                    areaid:'',
                    filterarea:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== "")
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
                  
                  this.getAllSale();

                })
              }
              else if(filtername === "line")
              {
                this.setState(()=>{
                  return{
                    lineid:'',
                    filterline:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== "")
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getAllSale();

                })
              }
              else if(filtername === "lineman")
              {
                this.setState(()=>{
                  return{
                    linemanid:'',
                    filterlineman:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== "")
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getAllSale();

                })
              }
              else if(filtername === "hawker")
              {
                this.setState(()=>{
                  return{
                    hawkerid:'',
                    filterhawker:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== "")
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getAllSale();

                })
              }
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

  deleteRecordAction(recordid,saledate){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              this.setState(()=>{
                return{
                  deleterecordid:recordid,
				  saledate:saledate,
                }
              },()=>{

                this.deleteRecord();

              })
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

  deleteRecord(){

    const self      = this;
    const app       = self.$f7;

    if(!this.$f7.online)
    {
      return;
    }

    const bodyFormData = {
      Mode:'DeleteSale',
      clientid:this.state.clientdetail.id,
      recordid:this.state.deleterecordid,
      saledate:this.state.saledate,
    };

    app.preloader.show();

    setTimeout(()=> {

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/sale.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

          if(resdata.success === true)
          {
            this.setState(
              ()=>{
                return {
                  deleterecordid:'',
                  saledate:'',
                }
              },()=>{
                this.getAllSale();
              }
            );
          }

      }).catch(e => {
          console.log(e);
      });

    }, 500);

  }

	render(){
		return (
			<SaleContext.Provider value={{
			  ...this.state,
        init:this.init,
				handleUserInput:this.handleUserInput,
				getAllSale:this.getAllSale,
				setNewFilterData:this.setNewFilterData,
				openFilterPopup:this.openFilterPopup,
				deleteFilter:this.deleteFilter,
				navigateToLink:this.navigateToLink,
				deleteFilterAction:this.deleteFilterAction,
				deleteRecordAction:this.deleteRecordAction,
			}}
			>
			{this.props.children}
			</SaleContext.Provider>
		);
	}
}

const SaleConsumer = SaleContext.Consumer;

export {SaleProvider, SaleConsumer, SaleContext};