import React,{Component} from 'react';
import logo404 from '../assets/images/orlo404.png';

import {
    Block,
    BlockTitle,
    Row,
    Col,
  } from 'framework7-react';

  export default class AppItemNotFound extends Component {
    constructor(props) {
      super(props);

      this.state = {

      };
    }
    
    render(){

        const{notfoundtext} = this.props;

        return(
            <Block>
                <br />
                <Row>
                    <Col>
                        <img src={logo404} className="norecimg" />
                        <BlockTitle className="errmsg">{notfoundtext}</BlockTitle>
                    </Col>
                </Row>
            </Block>
        );
    }
}