import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Link,
    Block,
    Fab,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton
  } from 'framework7-react';

  export default class PaymentRegister extends Component {
    
    constructor(props) {
      
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startdate = today.getFullYear()+'-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+currday;
     
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        isfetched:false,
        recordset:[],
        isptr:false,
        ismount:false,
        hasallcustomer:false,
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
        totalrecord:0,
        totalpayment:0,
        paymenttype:'all',
        summarytotal:{totalamount:'0.00',totaldiscount:'0.00',totalcoupon:'0.00'},
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getPaymentRegister = this.getPaymentRegister.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getPaymentRegister();
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getPaymentRegister(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
    
        const bodyFormData = {
            Mode:'GetPaymentRegisterData',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            startdate:self.state.startdate,
            enddate:self.state.enddate,
            areaid:self.state.areaid,
            lineid:self.state.lineid,
            linemanid:self.state.linemanid,
            hawkerid:self.state.hawkerid,
            paymenttype:self.state.paymenttype,
        };

        this.setState(()=>{

          return{
            isfetched:false
          }
          
        },()=>{

          setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                    isfetched:true,
                    recordset:resdata.paymentlist,
                    totalrecord:resdata.totalrecord,
                    totalpayment:resdata.totalpayment,
                    summarytotal:resdata.summarytotal,
                    hasdataavailable:true,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });

              }
              else
              {
                this.setState({
                    isfetched:true,
                    hasdataavailable:false,
                    totalrecord:0,
                    totalpayment:0,
                    summarytotal:{totalamount:'0.00',totaldiscount:'0.00',totalcoupon:'0.00'},
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        },500);

      });
    }

    getPaymentRegisterPDF(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetPaymentRegisterDataPDF',
        clientid:self.state.clientdetail.id,
        stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        startdate:self.state.startdate,
        enddate:self.state.enddate,
        areaid:self.state.areaid,
        lineid:self.state.lineid,
        linemanid:self.state.linemanid,
        hawkerid:self.state.hawkerid,
        paymenttype:self.state.paymenttype,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                  window.open(resdata.pdffilepath);
              }
              else
              {
                  const toastWithButton = app.toast.create({
                    text: resdata.msg,
                    closeButton: true,
                    closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, paymenttype){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            lineid:lineid,
            linemanid:linemanid,
            hawkerid:hawkerid,
            filterarea:filterarea,
            filterline:filterline,
            filterlineman:filterlineman,
            filterhawker:filterhawker,
            startdate:startdate,
            enddate:enddate,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
            paymenttype:paymenttype,
          }
  
        },()=>{
  
          if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || (this.state.startdate !== "" && this.state.startdate !== undefined) || (this.state.enddate !== "" && this.state.enddate !== undefined) || (this.state.paymenttype !== "" && this.state.paymenttype !== undefined))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getPaymentRegister();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){
        
      const{isfetched, recordset, startdate, enddate, hasallcustomer, areaid, lineid, linemanid, hawkerid, hasfilter, filterarea, filterline, filterlineman, filterhawker, removefiltername, applyremovefilter, ismount, hasdataavailable, totalrecord, summarytotal, paymenttype} = this.state;

      let datestart     = "";
      let newstartdate  = "";

      if(startdate !== "" && startdate !== undefined)
      {
        newstartdate = new Date(startdate);

        let currmonth = newstartdate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        datestart = newstartdate.getDate()+"-"+currmonth+"-"+newstartdate.getFullYear();
      }

      let dateend     = "";
      let newenddate  = "";

      if(enddate !== "" && enddate !== undefined)
      {
        newenddate = new Date(enddate);

        let currmonth = newenddate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
      }

      return(
        <Page className="page" name="PaymentRegister">
        <Navbar sliding={true}>
          <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
            <Link href={`/dashboard/`} className="clearmenupadding">
              <img className="navbarlogo" src={scanlogo} alt="logo" />
            </Link>
          </NavLeft>
          <NavTitle>Payment Register</NavTitle>
          <NavRight className="right-with-menu">
          <Link onClick={()=>{

            this.getPaymentRegister();

          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
          </NavRight>
        </Navbar>
      <FilterConsumer>{(value)=>{

        const{registerfilterdata, applyPaymentRegisterFilter} = value;

        if(ismount === true)
        {
          setTimeout(()=> {

            if(removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(removefiltername === 'area')
              {
                applyPaymentRegisterFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, '', '', startdate, enddate, paymenttype);
              }
              else if(removefiltername === 'line')
              {
                applyPaymentRegisterFilter('', linemanid, hawkerid, '', filterlineman, filterhawker, areaid, filterarea, startdate, enddate, paymenttype);
              }
              else if(removefiltername === 'lineman')
              {
                applyPaymentRegisterFilter(lineid, '', hawkerid, filterline, '', filterhawker, areaid, filterarea, startdate, enddate, paymenttype);
              }
              else if(removefiltername === 'hawker')
              {
                applyPaymentRegisterFilter(lineid, linemanid, '', filterline, filterlineman, '', areaid, filterarea, startdate, enddate, paymenttype);
              }
              else if(removefiltername === 'daterange')
              {
                applyPaymentRegisterFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, '', '', paymenttype);
              }
            }

            if(((registerfilterdata.lineid !== lineid) && registerfilterdata.lineid !== undefined) || ((registerfilterdata.linemanid !== linemanid) && registerfilterdata.linemanid !== undefined) || ((registerfilterdata.hawkerid !== hawkerid) && registerfilterdata.hawkerid !== undefined) || ((registerfilterdata.areaid !== areaid) && registerfilterdata.areaid !== undefined) || ((registerfilterdata.startdate !== startdate) && registerfilterdata.startdate !== undefined) || ((registerfilterdata.enddate !== enddate) && registerfilterdata.enddate !== undefined) || ((registerfilterdata.paymenttype !== paymenttype) && registerfilterdata.paymenttype !== undefined) || applyremovefilter === true)
            {
              this.setNewFilterData(registerfilterdata.lineid, registerfilterdata.linemanid, registerfilterdata.hawkerid, registerfilterdata.filterline, registerfilterdata.filterlineman, registerfilterdata.filterhawker, registerfilterdata.areaid, registerfilterdata.filterarea, registerfilterdata.startdate, registerfilterdata.enddate, registerfilterdata.paymenttype);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }

          }, 500)
        }
        
      return(
            <Block>
              {
                hasfilter ? (
                  <Block strong className="selectedfilter" style={{marginTop:0}}>
                    {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                    {datestart ? (<Chip text={`Date : (${datestart}) - (${dateend})`} mediaBgColor="blue" media={`D`} deleteable
                    onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.setState(()=>{
                            return {
                              removefiltername:'daterange'
                            }
                          },()=>{
                            this.refs.actionsFilterGroup.open()
                          })
                        }
                      }}
                    />):null}
                    {areaid !== "" && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                    onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.setState(()=>{
                            return {
                              removefiltername:'area'
                            }
                          },()=>{
                            this.refs.actionsFilterGroup.open()
                          })
                        }
                      }}
                    />):null}
                    {lineid !== "" && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="pink" media={filterline.toUpperCase().substring(0, 1)} deleteable
                    onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.setState(()=>{
                            return {
                              removefiltername:'line'
                            }
                          },()=>{
                            this.refs.actionsFilterGroup.open()
                          })
                        }
                      }}
                    />):null}
                    {filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="green" media={filterlineman.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.setState(()=>{
                            return {
                              removefiltername:'lineman'
                            }
                          },()=>{
                            this.refs.actionsFilterGroup.open()
                          })
                        }
                      }}
                    />):null}
                    {Number(hawkerid) > 0 && filterhawker !== "" ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.setState(()=>{
                            return {
                              removefiltername:'hawker'
                            }
                          },()=>{
                            this.refs.actionsFilterGroup.open()
                          })
                        }
                      }}
                    />):null}
                    {` `}
                    {paymenttype ? (<Chip text={`Payment Type : ${paymenttype.charAt(0).toUpperCase() + paymenttype.slice(1)}`} mediaBgColor="green" media={paymenttype.toUpperCase().substring(0, 1)}/>):null}
                    &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.$f7router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&startdate=${startdate}&enddate=${enddate}&paymenttype=${paymenttype}`);
                        }
                    }}/>
                  </Block>
                ):null
              }
              {
                isfetched? (<Fragment>
                {
                  hasdataavailable ? (<Fragment>
                    <BlockTitle className="totalrec">Total Records : {totalrecord}</BlockTitle>
                    <div className="data-table">
                      <table>
                        <thead>
                          <tr>
                            <th className="label-cell">Day</th>
                            <th className="numeric-cell">Date</th>
                            <th className="numeric-cell">Payment</th>
                            <th className="numeric-cell">Coupon</th>
                            <th className="numeric-cell">Discount</th>
                            <th className="numeric-cell">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          recordset.map((linedetail)=>{

                          return(
                              <tr key={linedetail.paymentdate}>
                                <td className="label-cell">{linedetail.paymentday}</td>
                                <td className="numeric-cell" style={{paddingRight:'0',paddingLeft:'0'}}>{linedetail.name}</td>
                                <td className="numeric-cell">{linedetail.amount}</td>
                                <td className="numeric-cell">{linedetail.coupon}</td>
                                <td className="numeric-cell">{linedetail.discount}</td>
                                <td className="numeric-cell">
                                  <Link className="dashboardlink" onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.$f7router.navigate(`paymentregisterdetail/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&paymentdate=${linedetail.paymentdate}&paymenttype=${paymenttype}`);
                                    }
                                  }} ignoreCache={true}>
                                    <Icon f7="arrow_up_right_square_fill"></Icon>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        }
                        <tr>
                          <td className="label-cell">&nbsp;</td>
                          <td className="numeric-cell"><b>Total</b></td>
                          <td className="numeric-cell">{summarytotal.totalamount}</td>
                          <td className="numeric-cell">{summarytotal.totalcoupon}</td>
                          <td className="numeric-cell">{summarytotal.totaldiscount}</td>
                          <td className="numeric-cell">&nbsp;</td>
                        </tr>
                      </tbody>
                      </table>
                    </div>
                  </Fragment>):(<AppItemNotFound notfoundtext ="No record found." />)
                }
                </Fragment>):(<AppListItemLoading />)
              }
              <br /><br /><br />
            </Block>
          );
        }}
        </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {
            hasdataavailable ? (
               <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                    if(this.$f7.online)
                    {
                      this.getPaymentRegisterPDF();
                    }
                  }}>
                  <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                  <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                </Fab>
            ):null
          }
        </Page>
      );
    }
}