import React,{Component} from 'react';
import { SaleConsumer } from '../context/SaleContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    ListInput,
    Link,
    Row,
    Col,
  } from 'framework7-react';

  export default class ManageSaleFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startdate = today.getFullYear()+'-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+currday;
  
      this.state = {
        clientdetail:[],
        stockid:'',
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        filterstock:'',
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        arealist:[],
        linelist:[],
        linemanlist:[],
        hawkerlist:[],
        hassmartline:false,
        hassmartlineman:false,
        hassmarthawker:false,
        hassmartarea:false,
        saletype:606,
        filtervars:[],
        startdate:startdate,
        enddate:enddate,
      };

      this.areaAutocompletePopup     = this.areaAutocompletePopup.bind(this);
      this.lineAutocompletePopup     = this.lineAutocompletePopup.bind(this);
      this.hawkerAutocompletePopup   = this.hawkerAutocompletePopup.bind(this);
      this.applyFilter               = this.applyFilter.bind(this);
      this.handleUserInput           = this.handleUserInput.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{

        this.hideSplashScreen();

        let{stockid, filterstock, areaid, filterarea, lineid, filterline, hawkerid, filterhawker, saletype, startdate, enddate} = this.state.filtervars;

        if(stockid === "" || stockid === "NaN" || stockid === "undefined")
        {
          stockid      = "-1";
          filterstock  = "All Stock";
        }
        
        if(Number(areaid) < 1 || areaid === "NaN" || areaid === "undefined")
        {
          areaid      = "";
          filterarea  = "All Area";
        }

        if(Number(lineid) < 1 || lineid === "NaN" || lineid === "undefined")
        {
          lineid      = "";
          filterline  = "All Line";
        }
        
        if(Number(hawkerid) < 1 || hawkerid === "NaN" || hawkerid === "undefined")
        {
          hawkerid      = "";
          filterhawker  = "All Hawker";
        }

        this.setState({
          stockid:stockid,
          areaid:areaid,
          lineid:lineid,
          hawkerid:hawkerid,
          filterstock:filterstock,
          filterarea:filterarea,
          filterline:filterline,
          filterhawker:filterhawker,
          saletype:saletype,
          startdate:startdate,
          enddate:enddate,
        },()=>{
            /*this.$$('#autocomplete-area-popup').find('.item-after').text(this.state.filterarea);
            this.$$('#autocomplete-line-popup').find('.item-after').text(this.state.filterline);
            this.$$('#autocomplete-hawker-popup').find('.item-after').text(this.state.filterhawker);*/

            this.inventoryAutocompletePopup();
            this.areaAutocompletePopup();
            this.lineAutocompletePopup();
            this.hawkerAutocompletePopup();

        })

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteInventoryPopup.destroy();
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();
      self.hawkerAutocomplete.destroy();

    }

    inventoryAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteInventoryPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-inventory-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectinventory/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
            Mode:'GetAllInventory',
            clientid:self.state.clientdetail.id,
            catid:Number(self.state.saletype),
            fromarea:'salefilter',
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    inventorylist:res.inventorylist,
                  }
                },()=>{

                  const searchinventorylist  = self.state.inventorylist;
                  

                  for (let i = 0; i < searchinventorylist.length; i += 1) {
                    if (searchinventorylist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchinventorylist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.setState({
              stockid:value[0].id,
              filterstock:value[0].name,
              areaid:'',
              filterarea:'All Area',
              lineid:'',
              filterline:'All Line',
              hawkerid:'',
              filterhawker:'All Hawker',
            },()=>{
              $('.autocomplete-inventory-popup').removeClass('error');
            });

          },
        },
      });
    }

    areaAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              fromarea:'salefilter',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-area-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              areaid:id,
              filterarea:name
            });            
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              fromarea:'salefilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-line-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              lineid:id,
              filterline:name
            });

          },
        },
      });
    }

    hawkerAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      self.hawkerAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-hawker-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selecthawker/',
        requestSourceOnOpen:true,
        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetHawker',
              type:'customerfilter',
              fromarea:'salefilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    hawkerlist:res.recordlist,
                  }
                },()=>{

                  const searchhawkerlist  = self.state.hawkerlist;

                  for (let i = 0; i < searchhawkerlist.length; i += 1) {
                    if (searchhawkerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchhawkerlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            $('#autocomplete-hawker-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              hawkerid:id,
              filterhawker:name
            });

          },
        },
      });
    }

    applyFilter(){
      if(!this.$f7.online)
      {
        return;
      }
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    render(){

      const{stockid, lineid, hawkerid, filterstock, filterline, filterhawker, areaid, filterarea, saletype, startdate, enddate} = this.state;

      return(
        <SaleConsumer>{(value)=>{

          const{setNewFilterData}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="ManageSaleFilter">
                        <Block>
                        <p style={{marginLeft:'15px', fontWeight:'bold'}}>Stock Type</p>
                        <Row noGap>
                            <Col width="40">
                                <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="606" name="saletype" checked={Number(saletype) === 606} title="Magazine"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                            <Col width="60">
                                <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="605" name="saletype" checked={Number(saletype) === 605} title="Newspaper"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                        </Row>
                        <List noHairlinesMd style={{marginTop:'5px'}}>
                          <ListItem link="#" id="autocomplete-inventory-popup" title="Stock" after={filterstock}></ListItem>
                          <ListItem link="#" id="autocomplete-area-popup" title="Area" after={filterarea}></ListItem>
                          <ListItem link="#" id="autocomplete-line-popup" title="Line" after={filterline}></ListItem>
                          <ListItem link="#" id="autocomplete-hawker-popup" title="Hawker" after={filterhawker}></ListItem>
                            <ListInput
                                name="startdate"
                                id="startdate"
                                label="Start Date"
                                placeholder="Start Date"
                                type="date"
                                value={startdate}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                            <ListInput
                                name="enddate"
                                id="enddate"
                                label="End Date"
                                placeholder="End Date"
                                type="date"
                                value={enddate}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                        </List>
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                          Promise.all([
                            setTimeout(()=> {

                            setNewFilterData(stockid, areaid, lineid, hawkerid, filterstock, filterarea, filterline, filterhawker, saletype, startdate, enddate);
                  
                            }, 500)
                            
                          ])
                          .then(() => {
              
                            setTimeout(()=> {
              
                              this.$$('.popup-close').trigger('click');
                  
                            }, 500)
                            
                          })

                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </SaleConsumer>
      );
    }
}