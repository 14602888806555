import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Block,
    BlockTitle,
    Chip,
    Row,
    Col,
    Link,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    Searchbar,
    List,
    ListInput,
    Toolbar,
    ListItem
  } from 'framework7-react';

  export default class ManageInvoices extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        loaded:false,
        isavailable:true,
        allinvoices:[],
        invoices:[],
        actionGridOpened: false,
        oneGroupOpened: false,
        deleterecordid:'',
        searchkeyword:this.props.f7route.params.searchkeyword,
        isptr:false,
        toolbarPosition:'bottom',
        page:1,
        perpage:'',
        totalpages:'',
        popupOpened:false,
        applyfilter:false,
        showpages:false,
        year:this.props.f7route.params.year,
        month:this.props.f7route.params.month,
        lineid:'',
        hawkerid:'',
        paginglist:[],
        totalrecord:'',
        effect:'blink',
        hasfilter:false,
        filteryear:'',
        filtermonth:'',
        filterhawker:'',
        removefiltername:'',
        detailid:'',
        popupDetailOpened:false,
        ismount:false,
        areaid:'',
        filterarea:'',
        applyremovefilter:false,
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.getAllInvoices     = this.getAllInvoices.bind(this);
      this.searchInvoice      = this.searchInvoice.bind(this);
      this.setNewFilterData   = this.setNewFilterData.bind(this);
      this.openPopup          = this.openPopup.bind(this);
      this.openDetailPopup    = this.openDetailPopup.bind(this);
      this.closeDetailPopup   = this.closeDetailPopup.bind(this);
      this.navigateToLink     = this.navigateToLink.bind(this);
      this.deleteFromPopUp    = this.deleteFromPopUp.bind(this);
      this.applySearchInvoice = this.applySearchInvoice.bind(this);
    }

    componentDidMount(){
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);
        
        this.hideSplashScreen();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-buttom');

          }, 50)

        ])
        .then(() => {

          setTimeout(()=> {

            if(this.state.searchkeyword !== "" || this.state.year !=="" || this.state.month !=="")
            {
              this.setState(()=>{

                return{

                  hasfilter:true
                  
                }

              },()=>{

                this.getAllInvoices();

              });
            }
            else
            {
              this.getAllInvoices();
            }
            
          }, 200)

        })
      });

      this.$$('.searchbar-disable-button').on('click',()=>{
        this.setState(()=>{
          return{
            searchkeyword:'',
            page:1
          }
        },()=>{

          if(Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || this.state.searchkeyword !== "" || this.state.year !== "" || this.state.month !== "")
          {
            this.setState({hasfilter:true});
          }
          else
          {
            this.setState({hasfilter:false});
          }
  
          this.getAllInvoices();

        })
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    openPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');

    }

    openDetailPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.setState(()=>{

        return{
          popupDetailOpened:!this.state.popupDetailOpened
        }

      });
      
    }

    closeDetailPopup(){

      if(!this.$f7.online)
      {
        return;
      }

      this.setState(()=>{

        return{
          popupDetailOpened:false,
          detailid:''
        }

      });
    }

    setNewFilterData(year, month, filteryear, filtermonth, lineid, hawkerid, filterline, filterhawker, areaid, filterarea){

      if(!this.$f7.online)
      {        
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            year:year,
            month:month,
            lineid:lineid,
            hawkerid:hawkerid,
            filteryear:filteryear,
            filtermonth:filtermonth,
            filterline:filterline,
            filterhawker:filterhawker,
            areaid:areaid,
            filterarea:filterarea,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
          }
  
        },()=>{
  
          if(Number(this.state.year) > 0 || Number(this.state.month) > 0 || Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.searchkeyword !== "")
          {
            this.setState({hasfilter:true,applyremovefilter:false});
          }
          else
          {
            this.setState({hasfilter:false,applyremovefilter:false});
          }
  
          this.getAllInvoices();

        });

      })
      
    }

    applySearchInvoice(){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        if(Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.searchkeyword !== "")
        {
          this.setState({hasfilter:true});
        }
        else
        {
          this.setState({hasfilter:false});
        }

        this.getAllInvoices();

      })

    }

    deleteFromPopUp(recordid){
      this.setState(()=>{
        return{
          deleterecordid:recordid
        }
      },()=>{
        this.deleteRecord();
      })
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
          Mode:'DeleteInvoice',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              let tempInvoices		= [...this.state.invoices];
              tempInvoices	= tempInvoices.filter(invoice => invoice.id !== this.state.deleterecordid);
        
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                    invoices:tempInvoices
                  }
                },()=>{
                  if(this.state.invoices.length < 1)
                  {
                    this.setState(()=>{
                      return {
                        isavailable:false
                      }
                    });
                  }
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    deleteFilter(){
      if(this.state.removefiltername === 'year')
      {
        this.setState(()=>{
        return{
            loaded:false,
            year:'',
            filteryear:'',
            page:1,
        }
        },()=>{
        if(Number(this.state.year) > 0 || Number(this.state.month) > 0 || Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || this.state.searchkeyword !== "")
        {
            this.setState({hasfilter:true});
        }
        else
        {
            this.setState({hasfilter:false});
        }
        this.getAllInvoices();
        })
      }
     else if(this.state.removefiltername === 'month')
      {
        this.setState(()=>{
        return{
            loaded:false,
            month:'',
            filtermonth:'',
            page:1,
        }
        },()=>{
        if(Number(this.state.year) > 0 || Number(this.state.month) > 0 || Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || this.state.searchkeyword !== "")
        {
            this.setState({hasfilter:true});
        }
        else
        {
            this.setState({hasfilter:false});
        }
        this.getAllInvoices();
        })
      }
      else if(this.state.removefiltername === 'line')
      {
        this.setState(()=>{
          return{
            loaded:false,
            lineid:'',
            filterline:'',
            page:1,
          }
        },()=>{
          if(Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || this.state.searchkeyword !== "")
          {
            this.setState({hasfilter:true});
          }
          else
          {
            this.setState({hasfilter:false});
          }
          this.getAllInvoices();
        })
      }
      else if(this.state.removefiltername === 'hawker')
      {
        this.setState(()=>{
          return{
            loaded:false,
            hawkerid:'',
            filterhawker:'',
            page:1,
          }
        },()=>{
          if(Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || this.state.searchkeyword !== "")
          {
            this.setState({hasfilter:true});
          }
          else
          {
            this.setState({hasfilter:false});
          }
          this.getAllInvoices();
        })
      }
      else if(this.state.removefiltername === 'searchkeyword')
      {
        this.setState(()=>{
          return{
            loaded:false,
            searchkeyword:'',
            page:1,
          }
        },()=>{
          if(Number(this.state.lineid) > 0 || Number(this.state.hawkerid) > 0 || this.state.searchkeyword !== "")
          {
            this.setState({hasfilter:true});
          }
          else
          {
            this.setState({hasfilter:false});
          }
          this.getAllInvoices();
        })
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        this.Paging('select');

      });

    }

    Paging(mode){

      let pageerror = "";

      if(!this.$f7.online)
      {
        return;
      }

      let temppage    = '';

      const currpage  = this.state.page;

      if(mode === 'previous')
      {
        temppage  = Number(this.state.page) - 1;
      }
      if(mode === 'next')
      {
        temppage  = Number(this.state.page) + 1;
      }
      if(mode === 'select')
      {
        temppage  = Number(this.state.page);
      }

      if(temppage < 1)
      {
        pageerror = "You are on first page";
      }

      if(temppage > Number(this.state.totalpages))
      {
        pageerror = "You are on last page";
      }

      if(pageerror !== "")
      {
        const toastWithButton = this.$f7.toast.create({
          text: pageerror,
          closeButton: true,
          closeTimeout: 3000,
        });
          
        toastWithButton.open();
        
        return;
      }

      if(temppage <= 0)
      {
        temppage  = 1;
      }

      if(temppage >= Number(this.state.totalpages))
      {
        temppage  = Number(this.state.totalpages);
      }

      this.setState(()=>{

        return{
          page:temppage,
          loaded:false
        }

      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          if(mode === 'select')
          {
            this.getAllInvoices();
          }
          else
          {
            if(Number(this.state.page) !== Number(currpage))
            {
              this.getAllInvoices();
            }
          }

        })

      })

    }

    getAllInvoices(){

        if(!this.$f7.online)
        {
          return;
        }

        this.setState({
          loaded:false
        });

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
          Mode:'GetAllInvoices',
          clientid:Number(this.state.clientdetail.id),
          page:this.state.page,
          perpage:this.state.perpage,
          searchkeyword:this.state.searchkeyword,
          year:this.state.year,
          month:this.state.month,
          lineid:this.state.lineid,
          hawkerid:this.state.hawkerid,
          areaid:this.state.areaid,
        };

        /*if(this.state.isptr !== true)
        {
          app.preloader.show();
        }*/
  
        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  loaded:true,
                  isavailable:true,
                  invoices:resdata.recordlist,
                  filteryear:resdata.filteryear,
                  filtermonth:resdata.filtermonth,
                  allinvoices:resdata.recordlist,
                  perpage:Number(resdata.perpage),
                  totalpages:Number(resdata.totalpages),
                  paginglist:resdata.paginglist,
                  showpages:resdata.showpages,
                  totalrecord:resdata.totalrecord,
                  isptr:false,
                },()=>{
                   // this.setNewFilterData(this.state.year,this.state.month,this.state.lineid, this.state.linemanid, this.state.hawkerid, this.state.filteryear, this.state.filtermonth, this.state.filterline, this.state.filterlineman, this.state.filterhawker);
                  });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  isptr:false,
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);
    }

    searchInvoice(){

      const{allinvoices} = this.state;

      let tempInvoices  = [];

      if(this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined)
      {
				allinvoices.forEach(invoice => {

          if((invoice.name.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (invoice.phone.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (invoice.email.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (invoice.name.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (invoice.address1.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (invoice.customerid.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1))
          {
            tempInvoices = [...tempInvoices, invoice];
          }

        });
        
        if(tempInvoices.length > 0)
        {
          this.setState(()=>{
            return{
              invoices:tempInvoices,
              isavailable:true
            }
          });
        }
        else
        {
          this.setState(()=>{
            return{
              invoices:[],
              isavailable:false
            }
          });
        }
      }
      else
      {
        this.setState(()=>{
          return{
            invoices:allinvoices,
            isavailable:true
          }
        });
      }

    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        Promise.all([
          this.setState(()=>{

            return{
              popupDetailOpened:false
            }
    
          })
        ])
        .then(() => {
          
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/

        })

      }

    }

    render(){

       const{isavailable, loaded, invoices, paginglist, showpages, totalrecord, hasfilter,filteryear, filtermonth, filterline, filterhawker, searchkeyword, page, totalpages, ismount, filterarea, removefiltername, year, month, lineid, hawkerid, areaid, applyremovefilter} = this.state;

       /*console.log(filteryear);*/

       return(
        <Page pageContent={false} className="page" name="ManageInvoices">
          <Navbar sliding={true}>
            <NavLeft>
              <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
              <Link href={`/dashboard/`} className="clearmenupadding">
                <img className="navbarlogo" src={scanlogo} alt="logo" />
              </Link>
            </NavLeft>
            <NavTitle>Bills</NavTitle>
            <NavRight className="right-with-menu">
              {/*<Link searchbarEnable=".searchbar-customer" iconIos="f7:search" iconAurora="f7:search" iconMd="material:search" className="smallbox3"></Link>
              <Link onClick={()=>{this.openPopup()}} className="smallbox2"><Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon></Link>*/}
              <Link onClick={()=>{

                this.getAllInvoices();

              }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
            </NavRight>
            <Searchbar
              className="searchbar-customer"
              expandable
              disableButton={!this.$theme.aurora}
              customSearch={true}
              clearButton={false}
              value={searchkeyword}
              onInput={(e) => {
                this.setState({ searchkeyword: e.target.value},()=>{
                  this.applySearchCustomer();
                });
              }}
            ></Searchbar>
          </Navbar>
        <FilterConsumer>{(value)=>{

          const{invoicefilterdata, applyInvoiceFilter} = value;

          /*console.log(invoicefilterdata);
          console.log(this.state.removefiltername);*/

          if(ismount === true)
          {
            setTimeout(()=> {

              if(removefiltername !== "" && applyremovefilter === true)
              {
                if(removefiltername === 'year')
                {
                  applyInvoiceFilter('', month, '', filtermonth, lineid, hawkerid, filterline, filterhawker, areaid, filterarea);
                }
                else if(removefiltername === 'month')
                {
                  applyInvoiceFilter(year, '', filteryear, '', lineid, hawkerid, filterline, filterhawker, areaid, filterarea);
                }
                else if(removefiltername === 'area')
                {
                  applyInvoiceFilter(year, month, filteryear, filtermonth, lineid, hawkerid, filterline, filterhawker, '', '');
                }
                else if(removefiltername === 'line')
                {
                  applyInvoiceFilter(year, month, filteryear, filtermonth, '', hawkerid, '', filterhawker, areaid, filterarea);
                }
                else if(removefiltername === 'hawker')
                {
                  applyInvoiceFilter(year, month, filteryear, filtermonth, lineid, '', filterline, '', areaid, filterarea);
                }
              }

              if(((invoicefilterdata.lineid !== lineid) && invoicefilterdata.lineid !== undefined) || ((invoicefilterdata.hawkerid !== hawkerid) && invoicefilterdata.hawkerid !== undefined) || ((invoicefilterdata.areaid !== areaid) && invoicefilterdata.areaid !== undefined) || ((invoicefilterdata.year !== year) && invoicefilterdata.year !== undefined) || ((invoicefilterdata.month !== month) && invoicefilterdata.month !== undefined) || applyremovefilter === true)
              {
                this.setNewFilterData(invoicefilterdata.year, invoicefilterdata.month, invoicefilterdata.filteryear, invoicefilterdata.filtermonth, invoicefilterdata.lineid, invoicefilterdata.hawkerid, invoicefilterdata.filterline, invoicefilterdata.filterhawker, invoicefilterdata.areaid, invoicefilterdata.filterarea);

                this.setState({
                  removefiltername:'',
                  applyremovefilter:false
                })

              }
              
            }, 500)
          }

            return(
              <PageContent>
                    {
                      loaded ? (<Fragment>
                        {
                          isavailable ? (<BlockTitle className="totalrec">Total Bills : {totalrecord}</BlockTitle>):null
                        }
                        {
                          hasfilter ? (
                            <Block strong className="selectedfilter">
                              {filteryear ? (<Chip text={`Year : ${filteryear}`} mediaBgColor="pink" media={filteryear.substring(-1, 2)} deleteable
                               onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    this.setState(()=>{
                                      return {
                                        removefiltername:'year'
                                      }
                                    },()=>{
                                      this.refs.actionsFilterGroup.open()
                                    })
                                  }
                                }}
                              />):null}
                              {filtermonth ? (<Chip text={`Month : ${filtermonth}`} mediaBgColor="pink" media={filtermonth.toUpperCase().substring(0, 1)} deleteable
                               onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    this.setState(()=>{
                                      return {
                                        removefiltername:'month'
                                      }
                                    },()=>{
                                      this.refs.actionsFilterGroup.open()
                                    })
                                  }
                                }}
                              />):null}
                              {Number(areaid) ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="orange" media={filterarea.toUpperCase().substring(0, 1)} deleteable 
                              onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    this.setState(()=>{
                                      return {
                                        removefiltername:'area'
                                      }
                                    },()=>{
                                      this.refs.actionsFilterGroup.open()
                                    })
                                  }
                                }}
                              />):null}
                              {Number(lineid) ? (<Chip text={`Line : ${filterline}`} mediaBgColor="pink" media={filterline.toUpperCase().substring(0, 1)} deleteable
                               onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    this.setState(()=>{
                                      return {
                                        removefiltername:'line'
                                      }
                                    },()=>{
                                      this.refs.actionsFilterGroup.open()
                                    })
                                  }
                                }}
                              />):null}
                              {Number(hawkerid) ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                              onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    this.setState(()=>{
                                      return {
                                        removefiltername:'hawker'
                                      }
                                    },()=>{
                                      this.refs.actionsFilterGroup.open()
                                    })
                                  }
                                }}
                              />):null}
                              &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                this.openPopup();
                              }}/>
                            </Block>
                          ):null
                        }
                        {
                          isavailable ? (
                            <div className="search-list searchbar-found">
                              <Row noGap className='rowwithbottomborder'>
                                  <Col width='40' className="tableheader textalignleft">
                                      Customer
                                  </Col>
                                  <Col width='30' className="tableheader">
                                      Amount
                                  </Col>
                                  <Col width='30' className="tableheader">
                                      Options
                                  </Col>
                              </Row>
                              {
                                  invoices.map((invoice)=>{
                                    return(
                                      <Row key={invoice.id} noGap className='rowwithbottomborder'>
                                          <Col width='40' className="ledgercolumn textalignleft">
                                            {invoice.name}
                                          </Col>
                                          <Col width='30' className="ledgercolumn textalignleft">
                                            {invoice.amount}
                                          </Col>
                                          <Col width='30' className="ledgercolumn">
                                            <Link onClick={()=>{this.navigateToLink(`invoicedetail/${invoice.id}`)}} ignoreCache={true}><Icon f7="pencil_circle_fill" ></Icon></Link>
                                            {
                                              !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                                <Link onClick={()=>{
                                                  if(this.$f7.online)
                                                  {
                                                    this.setState(()=>{
                                                      return {
                                                        deleterecordid:invoice.id
                                                      }
                                                    },()=>{
                                                      this.refs.actionsOneGroup.open()
                                                    })
                                                  }
                                                }}><Icon f7="trash_circle_fill"></Icon></Link>
                                              ):(<div>&nbsp;</div>)
                                            }
                                          </Col>
                                      </Row>
                                    );
                                  })
                              }
                            </div>
                          ):(<AppItemNotFound notfoundtext ="No bills record found." />)
                        }
                      </Fragment>):(<AppListItemLoading />)
                    }
                {/*</PageContent>*/}
                <br /><br /><br /><br />
              </PageContent>
          );

        }}
        </FilterConsumer>
          {
            isavailable  && showpages ? (
              <Toolbar position={this.state.toolbarPosition}>
              {
                (()=>{
                  if(page > 1)
                  {
                    return(
                      <Link className="paging" onClick={()=>{this.Paging('previous')}}>
                      <Icon f7="arrow_left_square_fill" ></Icon>
                      </Link>
                    );
                  }
                  else
                  {
                    return(
                      <Link href="#"></Link>
                    );
                  }
                })()
              }
                <List className="droppage" noHairlines>
                    <ListInput
                        name="page"
                        type="select"
                        value={this.state.page}
                        onChange={this.handleUserInput}
                        placeholder="Please choose..."
                        >
                        {
                          paginglist.map((pages)=>{
                          return(<option key={pages.page} value={pages.page}>{pages.name}</option>);
                          })
                        }
                    </ListInput>
                </List>
                {
                  (()=>{
                    if(page < totalpages)
                    {
                      return(
                        <Link className="paging" onClick={()=>{this.Paging('next')}}>
                        <Icon f7="arrow_right_square_fill" ></Icon>
                        </Link>
                      );
                    }
                    else
                    {
                      return(
                        <Link href="#"></Link>
                      );
                    }
                  })()
                }
              </Toolbar>
            ):null
          }
          <Actions ref="actionsOneGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteRecord()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{
                this.setState(()=>{
                  return{
                    applyremovefilter:true
                  }
                },()=>{
                  this.deleteFilter();
                })
              }}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
        </Page>
      );

    }
}