import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';
import AppListItemLoading from '../pages/applistitemloading';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Block,
    List,
    Row,
    Col,
    ListItem,
    Icon,
    Toolbar,
    Button,
    Chip,
  } from 'framework7-react';

  export default class Ledger extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startdate = today.getFullYear()+'-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+currday;      
      
      let filtervars  = {};

      if(this.props.$f7route.query)
      {
        let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker, openingbalanceid, filteropeningbalance, nameandphone, page, fromarea} = this.props.$f7route.query;

        if(Number(areaid) < 1 || areaid === "NaN" || areaid === undefined)
        {
          areaid      = "";
          filterarea  = "Select";
        }
  
        if(Number(lineid) < 1 || lineid === "NaN" || lineid === undefined)
        {
          lineid      = "";
          filterline  = "Select";
        }
        
        if(Number(hawkerid) < 1 || hawkerid === "NaN" || hawkerid === undefined)
        {
          hawkerid      = "";
          filterhawker  = "Select";
        }
       
        if(Number(openingbalanceid) < 1 || openingbalanceid === "NaN" || openingbalanceid === "undefined" || openingbalanceid === undefined)
        {
          openingbalanceid      = "";
          filteropeningbalance  = "Select";
        }
  
        if(nameandphone === "" || nameandphone === undefined)
        {
          nameandphone  = "";
        }

        if(Number(page) < 1 || page === "NaN" || page === undefined)
        {
          page      = "";
        }

        if(fromarea === "" || fromarea === undefined)
        {
          fromarea  = "";
        }

        filtervars  = {areaid:areaid, filterarea:filterarea, lineid:lineid, filterline:filterline, hawkerid:hawkerid, filterhawker:filterhawker, openingbalanceid:openingbalanceid, filteropeningbalance:filteropeningbalance, nameandphone:nameandphone, page:page, fromarea:fromarea};
      }

      this.state = {
        clientdetail:[],
        customerid:'',
        customername:'Select',
        customerlist:[],
        grandtotal:0,
        ledgerlist:[],
        isptr:false,
        Ledgertype:1,
        loaded:false,
        effect:'blink',
        hasrecord:false,
        hasfilter:true,
        filtervars:filtervars,
        datetype:'begining',
        startdate:startdate,
        ismount:false,
        removefiltername:'',
        applyremovefilter:false,
      };

      this.handleUserInput            = this.handleUserInput.bind(this);
      /*this.customerAutocompletePopup  = this.customerAutocompletePopup.bind(this);*/
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getLedgerDetail();

        /*this.getAllCustomers();

        Promise.all([
            
          setTimeout(()=> {

            this.setState(()=>{
              return{
                loaded:false
              }
            },()=>{

              this.Dom7('.waiting').addClass('animate-bottom');

            })

          }, 50)

        ])
        .then(() => {

          this.getLedgerDetail();
          
        })*/

        this.hideSplashScreen();

      });

    }

    /*componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteCustomerPopup.destroy();

    }*/

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    customerAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchlinelist  = this.state.customerlist;

      self.autocompleteCustomerPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-customer-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectcustomer/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinelist.length; i += 1) {
            if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchlinelist[i].phone.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchlinelist[i].customerid.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            $('#autocomplete-customer-popup').find('.item-title').text(value[0].name);
            $('#autocomplete-customer-popup').find('.item-content').removeClass('error');
            
            /*$('#autocomplete-line-popup').find('input').val(value[0].id);*/

            self.setState(()=>{
              return{
                customerid:value[0].id,
                customername:value[0].name,
                loaded:false,
              }
            },()=>{
                Promise.all([
                    
                  setTimeout(()=> {

                    self.Dom7('.waiting').addClass('animate-bottom');

                  }, 50)

                ])
                .then(() => {

                  self.getLedgerDetail();
                  
                })
              
            })

          },
        },
      });
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        if(name === 'customerid')
        {
          this.getLedgerDetail();
        }

      });

    }

    setNewFilterData(datetype, startdate){

      if(!this.$f7.online)
      {
        return;
      }

      let canapplyfilter  = false;
      let hasfilter       = false;

      if(datetype !== this.state.datetype || startdate !== this.state.startdate)
      {
        canapplyfilter  = true;
      }

      if(datetype !== '' || startdate !== '')
      {
        hasfilter = true;
      }      

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            datetype:datetype,
            startdate:startdate,
            hasfilter:hasfilter,
          }
  
        },()=>{
  
          if(canapplyfilter)
          {
            this.getLedgerDetail();
          }
  
        });

      })
      
    }

    getLedgerDetail(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
            Mode:'GetLedger',
            clientid:self.state.clientdetail.id,
            customerid:self.state.customerid,
            datetype:self.state.datetype,
            startdate:self.state.startdate,
        };

        this.setState({
          loaded:false
        });

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                  this.setState({

                      ledgerlist:resdata.ledgerlist,
                      grandtotal:resdata.grandtotal,
                      customername:resdata.customername,
                      isptr:false,
                      loaded:true,
                      hasrecord:true,

                  });
                }
                else
                {
                  this.setState({

                    isptr:false,
                    loaded:true,
                    customername:resdata.customername,
                    hasrecord:false,

                  });
                }

            }).catch(e => {
                console.log(e);
            });

        },1000);
    }

    getAllCustomers(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetAllSubscribeCustomer',
            clientid:self.state.clientdetail.id,
            loginlinemanid:Number(self.state.clientdetail.linemanid),
            ismanager:Number(this.state.clientdetail.ismanager),
            areamanagerid:Number(this.state.clientdetail.areamanagerid),
            areaids:this.state.clientdetail.areaids,
        };
  
        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
                this.setState(()=>{
                    return{
                        customerlist:resdata.recordlist,
                        isptr:false
                    }
                },()=>{
                  this.customerAutocompletePopup();
                });
            }
            else
            {
                this.setState(()=>{
                    return{
                        isptr:false
                    }
                });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    getLedgerPDF(){

      const self      = this;
      const app       = self.$f7;
  
      const bodyFormData = {
          Mode:'GetCustomerLederPDF',
          clientid:self.state.clientdetail.id,
          customerid:self.state.customerid,
          datetype:self.state.datetype,
          startdate:self.state.startdate,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
      
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request

          // Hide Preloader
          app.preloader.hide();

          // Hide Pull To Refresh
          app.ptr.done();                

          const resdata = JSON.parse(res.data);
          if(resdata.success === true)
          {
            window.open(resdata.pdffilepath);
          }
          else
          {
            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
              
            toastWithButton.open();
          }
        }).catch(e => {
            console.log(e);
        });

      },500);
    }

    resendInvoiceMessage(smscredits){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'ResendInvoiceMessage',
        customerid:self.state.customerid,
        smscredits:smscredits,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    getMessagePreviewToSend(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'GetInvoiceMessagePreview',
        customerid:self.state.customerid,
        clientid:this.state.clientdetail.id,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          if(resdata.success)
          {
            /*const parser        = new DOMParser();
            const msgpreviewhtml  = parser.parseFromString(resdata.preview, 'text/html').documentElement.textContent;*/
            
            app.dialog.create({
              title: 'Message Preview',
              text: resdata.preview,
              closeByBackdropClick: 'true',
              buttons: [
                {
                  text: 'Cancel',
                },
                {
                  text: 'Send',
                  onClick: () => {
                    this.resendInvoiceMessage(resdata.smscredits);
                  },
                },
              ],
            }).open();
          }
          else
          {
            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();
          }

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    render(){
        
        const{customerid, ledgerlist, loaded, hasrecord, hasfilter, datetype, startdate, ismount, removefiltername, applyremovefilter} = this.state;

        const{areaid, filterarea, lineid, filterline, hawkerid, filterhawker, openingbalanceid, filteropeningbalance, nameandphone, page, fromarea} = this.state.filtervars;

        let tempurl = "";

        if(customerid !== ""){tempurl = `makepayment/${customerid}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&nameandphone=${nameandphone}&page=${page}&fromarea=${fromarea}`;}else{tempurl = `makepayment/${customerid}?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&nameandphone=${nameandphone}&page=${page}&fromarea=${fromarea}`;}

        let datestart     = "";
        let newstartdate  = "";
  
        if(startdate !== "" && startdate !== undefined)
        {
          newstartdate = new Date(startdate);
  
          let currmonth = newstartdate.getMonth()+1;
          if(currmonth < 10)
          {
              currmonth = "0"+currmonth;
          }
          datestart = newstartdate.getDate()+"-"+currmonth+"-"+newstartdate.getFullYear();
        }
  
        return(
            <Page pageContent={false} className="page" name="ledgerpage">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Ledger</NavTitle>
                  <NavRight className="right-with-menu">
                  <Link onClick={()=>{

                    this.getLedgerDetail();

                  }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                <FilterConsumer>{(value)=>{
                  
                  const{ledgerfilterdata, applyLedgerFilter} = value;

                  if(ismount === true)
                  {
                    setTimeout(()=> {
          
                      /*if(removefiltername !== "" && this.state.applyremovefilter === true)
                      {
                        if(removefiltername === 'area')
                        {
                          applyLedgerFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, '', '', startdate, enddate, paymenttype);
                        }
                        else if(removefiltername === 'line')
                        {
                          applyLedgerFilter('', linemanid, hawkerid, '', filterlineman, filterhawker, areaid, filterarea, startdate, enddate, paymenttype);
                        }
                      }*/
          
                      if(((ledgerfilterdata.datetype !== datetype) && ledgerfilterdata.datetype !== undefined) || ((ledgerfilterdata.startdate !== startdate) && ledgerfilterdata.startdate !== undefined) || applyremovefilter === true)
                      {
                        this.setNewFilterData(ledgerfilterdata.datetype, ledgerfilterdata.startdate);
          
                        this.setState({
                          removefiltername:'',
                          applyremovefilter:false
                        })
          
                      }
          
                    }, 500)
                  }

                  return(
                    <PageContent>
                      <Block>
                          {
                            customerid !== "" && hasfilter ? (
                              <Block strong className="selectedfilter" style={{marginTop:0}}>
                                {datetype !== '' ? (<Chip text={`Date Type : ${datetype === 'begining' ? `Begining`:`Selected Date`}`} mediaBgColor="pink" media={`D`}
                                />):null}
                                {datetype === 'selectdate' && startdate !== '' ? (<Chip text={`Start Date : ${datestart}`} mediaBgColor="blue" media={`D`}
                                />):null}
      
                                &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.$f7router.navigate(`ledgerfilter/?datetype=${datetype}&startdate=${startdate}`);
                                    }
                                }}/>
                              </Block>
                            ):null
                          }
                          <List noHairlinesMd className="zeromargin">
                            <ListItem id="autocomplete-customer-popup" title={`Customer`} after={this.state.customername}>
                            </ListItem>
                          </List>
                          {
                            customerid ? (
                              <Fragment>
                              {
                                loaded ? (
                                  <Fragment>
                                    {
                                      hasrecord ? (<Fragment>
                                        <Row noGap className='rowwithbottomborder' style={{borderTop:'1px solid #000000', borderBottom:'1px solid #000000', fontWeight:'bold'}}>
                                          <Col width='15' className="tableheader1">Date</Col>
                                          <Col width='15' className="tableheader1">Item</Col>
                                          <Col width='15' className="tableheader1 tableheaderamount1 textalignright">Pay. ID</Col>
                                          <Col width='15' className="tableheader1 tableheaderamount1 textalignright">Due</Col>
                                          <Col width='15' className="tableheader1 tableheaderamount1 textalignright">Paid</Col>
                                          <Col width='15' className="tableheader1 tableheaderamount1 textalignright">Bal.</Col>
                                          <Col width='10' className="tableheader1 tableheaderamount1">&nbsp;</Col>
                                        </Row>
                                        {
                                          ledgerlist.map((ledger,index)=>{
                                            let balclass ='ledgercolumn ledgercolumnamount ledgerbalancepositive';
                                            
                                            if(ledger.balance < 1)
                                            {
                                              balclass='ledgercolumn ledgercolumnamount ledgerbalancenegative';
                                            }
                                            let ledgerdue = ledger.due;
                                            let ledgerpaid = ledger.paid;
                                            if(ledger.item == 'Opening Balance')
                                            {
                                              ledgerdue = '--';
                                              ledgerpaid ='--';
                                            }
                                            return(
                                              <Row noGap key={index} className='rowwithbottomborder'>
                                                <Col width='15' className="ledgercolumn">{ledger.date}</Col>
                                                <Col width='15' className="ledgercolumn">{ledger.item}</Col>
                                                <Col width='15' className="ledgercolumn ledgercolumnamount">{ledger.paymentid}</Col>
                                                <Col width='15' className="ledgercolumn ledgercolumnamount">{ledgerdue}</Col>
                                                <Col width='15' className="ledgercolumn ledgercolumnamount">{ledgerpaid}</Col>
                                                <Col width='15' className={balclass}>{ledger.balance}</Col>
                                                <Col width='10' className="ledgercolumn">
                                                {
                                                  ledger.invoiceid > 0 ? <Link onClick={() =>{
                                                    this.$f7router.navigate(`/invoicedetail/${ledger.invoiceid}/`);
                                                  }}><Icon f7="chevron_right_circle_fill"></Icon></Link>:<div>&nbsp;</div>
                                                }
                                                </Col>
                                              </Row>
                                            );
                                          })
                                        }
                                        <Row noGap className='rowwithbottomborder'>
                                            <Col width='80' className="tableheader tableheaderamount">Final Balance</Col>
                                            <Col width='20'className="tableheader tableheaderamount">{this.state.grandtotal}</Col>
                                        </Row>
                                      </Fragment>):(<AppItemNotFound notfoundtext ="No ledger record found." />)
                                    }
                                  </Fragment>
                                ):(
                                  <AppListItemLoading />
                                )
                              }
                              </Fragment>
                            ):(<div className="errmsg">Please select a customer</div>)
                          }
                          <br /><br /><br />
                      </Block>
                    </PageContent>
                  );

                }}</FilterConsumer>
              <Toolbar position="bottom">
                <Row style={{width:'100%'}}>
                    <Col width="40">
                      <Button fill color="blue" style={{marginRight:'.4rem'}}
                      onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.navigateToLink(`${tempurl}`)
                        }
                      }}
                      ><Icon f7="plus" style={{display:'inline-block'}}></Icon>&nbsp; Payment</Button>
                    </Col>
                    <Col width="30"><Button fill color="blue" style={{marginRight:'.4rem'}}
                    onClick={()=>{
                      if(this.$f7.online)
                      {
                        this.getLedgerPDF();
                      }
                    }}
                    ><Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>&nbsp;</Button></Col>
                    {
                      !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                        <Col width="30"><Button fill color="blue" style={{marginRight:'.4rem'}}
                        onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.getMessagePreviewToSend();
                          }
                        }}
                        ><Icon f7="envelope_fill" style={{display:'inline-block'}}></Icon>&nbsp;Send</Button></Col>
                      ):null
                    }
                </Row>
              </Toolbar>
              {/*<AppFav position="center-bottom" text="Make Payment" icon="plus" url={tempurl} />*/}
            </Page>
      );
    }
}