import React,{Component, Fragment} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import AppItemNotFound from '../pages/appitemnotfound';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Row,
    Col,
    Link,
    Toolbar,
    Button,
    BlockTitle
  } from 'framework7-react';

  export default class Sequence extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        areaid:'',
        lineid:'',
        hawkerid:'',
        filterarea:'',
        filterline:'',
        filterhawker:'Select',
        arealist:[],
        linelist:[],
        hawkerlist:[],
        customerlist:[],
        stocksummarylist:[],
        totalsubscription:0,
        effect:'blink',
        hassmarthawker:false,
        perpage:100,
        totalrecord:'',
        isfetched:false,
        type:'sequence'
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.checkSingleHawker  = this.checkSingleHawker.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.hideSplashScreen();

        this.areaAutocompletePopup();
        this.lineAutocompletePopup();

        if(!this.state.clientdetail.ishawker)
        {
          this.hawkerAutocompletePopup();
        }
        else
        {
          this.setState(()=>{
            return{
              hawkerid:this.state.clientdetail.ishawker
            }
          },()=>{
            this.getCustomers();
          });
        }

      });

      const self = this;
      const app = self.$f7;
      const $$  = self.Dom7;

      app.on('sortableSort', ()=> {

        let tempcustomerlist    = [...this.state.customerlist];

        /*$$('.activelist').each((index, el)=>{*/
        $$('.customerlist').each((index, el)=>{

          const recordid      = $$(el).attr('id');
          const newsequence   = Number(index)+1;

          const tempcustomerlist2     = tempcustomerlist.find(customer => Number(customer.id) === Number(recordid));
          tempcustomerlist2.sequence  = newsequence;

        });

        this.setState(()=>{
        
            return{
              customerlist: tempcustomerlist
            }

        });

      })

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();
      self.hawkerAutocomplete.destroy();

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-area-popup').find('.item-after').text(value[0].name);
            $('#autocomplete-line-popup').find('.item-after').text('Select');
            /*$('#autocomplete-hawker-popup').find('.item-after').text('Select');*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              areaid:id,
              filterarea:name,
              filterhawker:'Select',
              customerlist:[],
              stocksummarylist:[],
              lineid:'',
              hawkerid:'',
              hascustomer:false,
            },()=>{
              self.getCustomers();
            });
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-line-popup').find('.item-after').text(value[0].name);
            /*$('#autocomplete-hawker-popup').find('.item-after').text('Select');*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              lineid:id,
              hawkerid:'',
              filterline:name,
              filterhawker:'Select',
              customerlist:[],
              stocksummarylist:[],
              hascustomer:false,
            },()=>{
              self.checkSingleHawker();
            });

          },
        },
      });
    }

    hawkerAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      self.hawkerAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-hawker-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selecthawker/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetHawker',
              type:'sequencefilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    hawkerlist:res.recordlist,
                  }
                },()=>{

                  const searchhawkerlist  = self.state.hawkerlist;

                  for (let i = 0; i < searchhawkerlist.length; i += 1) {
                    if (searchhawkerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchhawkerlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            /*$('#autocomplete-hawker-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              hawkerid:id,
              filterhawker:name
            },()=>{
              self.getCustomers();
            });

          },
        },
      });
    }

    checkSingleHawker(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
        Mode:'CheckHawker',
        type:'sequencefilter',
        clientid:self.state.clientdetail.id,
        areaid:self.state.areaid,
        lineid:self.state.lineid,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({

                hawkerid:resdata.recordlist.id,
                filterhawker:resdata.recordlist.name,
                hascustomer:false,
                isfetched:false,

              },()=>{
                this.getCustomers();
              });
            }
            else
            {
              this.setState({

                hawkerid:'',
                filterhawker:'Select',
                hascustomer:false,
                isfetched:false,

              },()=>{
                this.getCustomers();
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }
    
    getCustomers(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
          Mode:'GetAllCustomers',
          clientid:clientdetail.id,
          areaid:self.state.areaid,
          lineid:self.state.lineid,
          linemanid:self.state.linemanid,
          hawkerid:this.state.hawkerid,
          perpage:this.state.perpage,
          page:1,
          type:this.state.type,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({

                customerlist:resdata.recordlist,
                stocksummarylist:resdata.subscriptionsummary,
                totalsubscription:resdata.totalsubscription,
                totalrecord:resdata.totalrecord,
                hascustomer:true,
                isfetched:true,

              });
            }
            else
            {
              this.setState({

                customerlist:[],
                stocksummarylist:[],
                totalsubscription:0,
                totalrecord:0,
                hascustomer:false,
                isfetched:true,

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    saveDetail(){

      const self  = this;
      const app   = self.$f7;

      let errmsg    = "";

      if(!this.$f7.online)
      {
        return;
      }

      app.input.validateInputs('.adddata');

      if(this.state.hawkerid === "" && errmsg === "")
      {
        errmsg  = "Please select a hawker";
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
          Mode:'UpdateSequence',
          clientid:self.state.clientdetail.id,
          areaid:self.state.areaid,
          lineid:self.state.lineid,
          linemanid:self.state.linemanid,
          hawkerid:self.state.hawkerid,
          customerlist:self.state.customerlist,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    getSequencePDF(){

        const self      = this;
        const app       = self.$f7;
   
        const bodyFormData = {
            Mode:'GetSequencePDF',
            clientid:self.state.clientdetail.id,
            areaid:self.state.areaid,
            lineid:self.state.lineid,
            linemanid:self.state.linemanid,
            hawkerid:self.state.hawkerid,
            printingtype:self.state.printingtype,
            bulkprinting:self.state.bulkprinting,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                    //console.log(resdata.pdffilepath);
                   // window.location.href= 'http://orlopay/agency/';
                    window.open(resdata.pdffilepath);
                }
                else
                {
                  const toastWithButton = app.toast.create({
                    text: resdata.msg,
                    closeButton: true,
                    closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();
                }
            }).catch(e => {
                console.log(e);
            });

        },500);
    }

    render(){

      const{areaid, lineid, hawkerid, customerlist, isfetched, hascustomer, clientdetail, stocksummarylist, totalsubscription, totalrecord, filterhawker} = this.state;

        return(
            <Page className="page" name="Sequence">
                <Navbar sliding={true}>
                    <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                    </NavLeft>
                    <NavTitle>Sequence</NavTitle>
                    <NavRight className="right-with-menu">
                    <Link sortableToggle=".sortable">Edit</Link>
                    </NavRight>
                </Navbar>
                <Block>
                {
                  clientdetail.ishawker ? null:(
                    <List noHairlinesMd className="zeromargin">
                      <ListItem link="#" id="autocomplete-area-popup" title="Area" after="Select"></ListItem>
                      <ListItem link="#" id="autocomplete-line-popup" title="Line" after="Select"></ListItem>
                      <ListItem link="#" id="autocomplete-hawker-popup" title="Hawker" after={filterhawker}></ListItem>
                    </List>
                  )
                }
                {
                  areaid ? (<Fragment>
                    {
                      isfetched ? (<Fragment>
                      {
                        hascustomer ? (
                          <Fragment>
                            <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center'}}>Summary</BlockTitle>
                            <Row noGap className='rowwithbottomborder'>
                                <Col width='20' className="tableheader">
                                    S.No.
                                </Col>
                                <Col width='50' className="tableheader textalignleft">
                                    Stock Name
                                </Col>
                                <Col width='30' className="tableheader">
                                    Quantity
                                </Col>
                            </Row>
                            {
                              stocksummarylist.map((inventory, index)=>{

                                return(
                                  <Row key={inventory.serial} noGap className='rowwithbottomborder'>
                                      <Col width='20' className="ledgercolumn textalignleft">
                                          {index+1}
                                      </Col>
                                      <Col width='50' className="ledgercolumn textalignleft">
                                          {inventory.name}
                                      </Col>
                                      <Col width='30' className="ledgercolumn">
                                          {inventory.quantity}
                                      </Col>
                                  </Row>
                                  );
                              })
                            }
                            <Row noGap className='rowwithbottomborder'>
                                <Col width='20' className="ledgercolumn textalignleft">
                                    &nbsp;
                                </Col>
                                <Col width='50' className="ledgercolumn textalignleft">
                                    <b>Total Stock</b>
                                </Col>
                                <Col width='30' className="ledgercolumn">
                                    {totalsubscription}
                                </Col>
                            </Row>
                            {
                              hawkerid ? (<Fragment>
                                <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center'}}>Customer List</BlockTitle>
                                <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center',fontSize:'18px'}}>Total Customers : {totalrecord}</BlockTitle>
                                <List sortable sortableOpposite className="sortable">
                                {
                                  customerlist.map((customer)=>{
                                    return(
                                      <ListItem key={customer.customerid} className={`customerlist ${customer.statusclass}`} id={customer.id} style={{color:customer.blockcolor}}>
                                          {customer.name2}<br/>
                                          {customer.fulladdress}<br/>
                                          {
                                            customer.hascustomerholiday ? `Holiday : ${customer.customerholidays}`:`${customer.subscriptions}`
                                          }
                                          <br/>
                                      </ListItem>
                                    );
                                  })
                                }
                                </List>
                              </Fragment>):null
                            }
                            <br/>
                          </Fragment>
                        ):(<AppItemNotFound notfoundtext ="No Customer record found." />)
                      }
                      </Fragment>):(<AppListItemLoading />)
                    }
                    </Fragment>
                  ):(<div className="errmsg">Please select a hawker</div>)
                }
            </Block>
            <br />
            {
              areaid && lineid && hawkerid && hascustomer ? (
                  <Toolbar position="bottom">
                      <Row style={{width:'100%'}}>
                          <Col width="60">
                          </Col>
                          <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                          onClick={()=>{
                              if(this.$f7.online)
                              {
                                this.saveDetail();
                              }
                          }}
                          ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                      </Row>
                  </Toolbar>
              ):null
            }
            {
              areaid && lineid && hawkerid && hascustomer ? (
                <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                    if(this.$f7.online)
                    {
                      this.getSequencePDF();
                    }
                  }}>
                  <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                  <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                </Fab>
              ):null
            }
            </Page>
        );
    }
}