import React,{Component} from 'react';
import { SMSCreditConsumer } from '../context/SMSCreditContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    ListInput,
    Link,
    Row,
    Col,
  } from 'framework7-react';

  export default class SMSCreditFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }
      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let lastdate = lastDay.getDate();

      /*let startdate = today.getFullYear()+'-'+currmonth+'-01';*/
      let startdate = '2020-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+lastdate;
  
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        datetype:'begining',
        filtervars:[],
      };

      this.applyFilter      = this.applyFilter.bind(this);
      this.handleUserInput  = this.handleUserInput.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{
        this.hideSplashScreen();

        let{datetype, startdate, enddate} = this.state.filtervars;

        this.setState({
          datetype:datetype,
          startdate:startdate,
          enddate:enddate,
        })

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;

    }

    applyFilter(){

      if(!this.$f7.online)
      {
        return;
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    render(){

      const{datetype, startdate, enddate} = this.state;

      return(
        <SMSCreditConsumer>{(value)=>{

          const{setNewFilterData}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="BillStatementFilter">
                        <Block>
                        <p style={{marginLeft:'15px'}}>Date Type</p>
                        <Row noGap>
                            <Col width="40">
                                <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="begining" name="datetype" checked={datetype === 'begining'} title="Begining"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                            <Col width="60">
                                <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="selectdate" name="datetype" checked={datetype === 'selectdate'} title="Selected Date"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                        </Row>
                        {
                          datetype === 'selectdate' ? (
                          <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                            <ListInput
                              name="startdate"
                              id="startdate"
                              label="Start Date"
                              placeholder="Start Date"
                              type="date"
                              value={startdate}
                              onChange={this.handleUserInput}
                              calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                            <ListInput
                              name="enddate"
                              id="enddate"
                              label="End Date"
                              placeholder="End Date"
                              type="date"
                              value={enddate}
                              onChange={this.handleUserInput}
                              calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                          </List>
                          ):``
                        }
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                            let tempstartdate   = new Date(startdate);
                            let tempenddate     = new Date(enddate);

                            if(tempenddate.getTime() < tempstartdate.getTime())
                            {
                                const toastWithButton = this.$f7.toast.create({
                                    text: "Start date can't be less then End date",
                                    closeButton: true,
                                    closeTimeout: 3000,
                                });
                        
                                toastWithButton.open();
                        
                                return false;
                            }

                            Promise.all([
                                setTimeout(()=> {

                                  setNewFilterData(datetype, startdate, enddate);

                                }, 500)
                                
                            ])
                            .then(() => {
                
                                setTimeout(()=> {
                
                                this.$$('.popup-close').trigger('click');
                    
                                }, 500)
                                
                            })
                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </SMSCreditConsumer>
      );
    }
}