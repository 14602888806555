import React,{Component} from 'react';
const WebsiteContext = React.createContext();
//Provider
//Consumer

class WebsiteProvider extends Component{

	constructor(props){
    super(props);

	this.state = {
        clientdetail:[],
        loaded:false,
        isavailable:true,
        allcontacts:[],
        contacts:[],
        actionGridOpened: false,
        oneGroupOpened: false,
        deleterecordid:'',
        recordid:'',
        searchkeyword:'',
        isptr:false,
        toolbarPosition:'bottom',
        page:1,
        perpage:'',
        totalpages:'',
        popupOpened:false,
        applyfilter:false,
        showpages:false,
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        paginglist:[],
        totalrecord:'',
        effect:'blink',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        removefiltername:'',
        applyremovefilter:false,
        hasallcustomer:true,
        ismount:false,
        filtervars:[],
        isinit:false,
        nameandphone:'',
	};

    this.init                  = this.init.bind(this);
    this.handleUserInput       = this.handleUserInput.bind(this);
    this.getAllContacts       = this.getAllContacts.bind(this);
    this.searchCustomer        = this.searchCustomer.bind(this);
    this.setNewFilterData      = this.setNewFilterData.bind(this);
    this.openFilterPopup       = this.openFilterPopup.bind(this);
    this.navigateToLink        = this.navigateToLink.bind(this);
    this.applySearchCustomer   = this.applySearchCustomer.bind(this);
    this.handleOpeningBalance  = this.handleOpeningBalance.bind(this);
    this.changeOpeningBalance  = this.changeOpeningBalance.bind(this);
    this.updateOpeningBalance  = this.updateOpeningBalance.bind(this);
    this.deleteFilterAction    = this.deleteFilterAction.bind(this);
    this.Paging                = this.Paging.bind(this);
    this.manageContactAction  = this.manageContactAction.bind(this);
    this.deleteRecordAction    = this.deleteRecordAction.bind(this);
  }


  componentDidMount(){
    let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

    this.setState(()=>{
      return{
        clientdetail:clientdetail
      }
    },()=>{

      setTimeout(()=> {

        this.setState({ismount:true});

      }, 1200);
      
      this.hideSplashScreen();
      
    });

  }

  hideSplashScreen(){
    document.getElementById("splash-screen").style.visibility = "hidden";
  }

  openFilterPopup(){
    
    if(!this.$f7.online)
    {        
      return;
    }

    this.$f7.views.main.router.navigate('filterpopup/');
    
  }

  setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, openingbalanceid, filteropeningbalance, nameandphone){

    if(lineid === undefined)
    {
      lineid  = "";
    }
    if(linemanid === undefined)
    {
      linemanid  = "";
    }
    if(hawkerid === undefined)
    {
      hawkerid  = "";
    }
    if(areaid === undefined)
    {
      areaid  = "";
    }
    if(openingbalanceid === undefined)
    {
      openingbalanceid  = "";
    }
    if(nameandphone === undefined)
    {
      nameandphone  = "";
    }

    if(!this.$f7.online)
    {
      return;
    }

    Promise.all([
          
      setTimeout(()=> {

        this.Dom7('.waiting').addClass('animate-bottom');

      }, 50)

    ])
    .then(() => {

      this.setState(()=>{
      
        return{
          areaid:areaid,
          lineid:lineid,
          linemanid:linemanid,
          hawkerid:hawkerid,
          filterarea:filterarea,
          filterline:filterline,
          filterlineman:filterlineman,
          filterhawker:filterhawker,
          openingbalanceid:openingbalanceid,
          filteropeningbalance:filteropeningbalance,
          nameandphone:nameandphone,
          loaded:false,
          applyfilter:false,
          popupOpened:false,
          page:1,
        }

      },()=>{

        if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0  || Number(this.state.openingbalanceid) > 0 || (this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined) || (this.state.nameandphone !== "" && this.state.nameandphone !== undefined))
        {
          this.setState({hasfilter:true,hasallcustomer:false},()=>{
            this.getAllContacts();
          });
        }
        else
        {
          this.setState({hasfilter:true,hasallcustomer:true},()=>{
            this.getAllContacts();
          });
        }

      });

    })
    
  }

  applySearchCustomer(){

    if(!this.$f7.online)
    {
      return;
    }

    Promise.all([
          
      setTimeout(()=> {

        this.Dom7('.waiting').addClass('animate-bottom');

      }, 50)

    ])
    .then(() => {

      if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || (this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined))
      {
        this.setState({hasfilter:true,hasallcustomer:false});
      }
      else
      {
        this.setState({hasfilter:true,hasallcustomer:true});
      }
      this.getAllContacts();

    })

  }

  deleteRecord(){

    const self      = this;
    const app       = self.$f7;

    if(!this.$f7.online)
    {
      return;
    }

    const bodyFormData = {
      Mode:'DeleteContactRequest',
      clientid:this.state.clientdetail.id,
      recordid:this.state.deleterecordid,
    };

    app.preloader.show();

    setTimeout(()=> {

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/contact.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

          if(resdata.success === true)
          {
            this.setState(
              ()=>{
                return {
                  deleterecordid:''
                }
              },()=>{
                this.getAllContacts();
              }
            );
          }

      }).catch(e => {
          console.log(e);
      });

    }, 500);

  }

  deleteFilter(){

    this.setState({
      applyremovefilter:true
    })

  }

  handleUserInput(e){

    const name  = e.target.name;
    const value = e.target.value;

    this.setState(()=>{
      return{
        [name]: value
      }
    },()=>{

      this.Paging('select');

    });

  }

  Paging(mode){

    let pageerror = "";

    if(!this.$f7.online)
    {
      return;
    }

    let temppage    = '';

    const currpage  = this.state.page;

    if(mode === 'previous')
    {
      temppage  = Number(this.state.page) - 1;
    }
    if(mode === 'next')
    {
      temppage  = Number(this.state.page) + 1;
    }
    if(mode === 'select')
    {
      temppage  = Number(this.state.page);
    }

    if(temppage < 1)
    {
      pageerror = "You are on first page";
    }

    if(temppage > Number(this.state.totalpages))
    {
      pageerror = "You are on last page";
    }

    if(pageerror !== "")
    {
      const toastWithButton = this.$f7.toast.create({
          text: pageerror,
          closeButton: true,
          closeTimeout: 3000,
      });
          
      toastWithButton.open();
      
      return;
    }

    if(temppage <= 0)
    {
      temppage  = 1;
    }

    if(temppage >= Number(this.state.totalpages))
    {
      temppage  = Number(this.state.totalpages);
    }

    this.setState(()=>{

      return{
        page:temppage,
        loaded:false
      }

    },()=>{

      Promise.all([
          
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        if(mode === 'select')
        {
          this.getAllContacts();
        }
        else
        {
          if(Number(this.state.page) !== Number(currpage))
          {
            this.getAllContacts();
          }
        }

      })

    })

  }

  getAllContacts(){

      if(!this.$f7.online)
      {
        return;
      }

      this.setState({
        loaded:false
      });

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetAllContacts',
          page:this.state.page,
          perpage:this.state.perpage,
          searchkeyword:this.state.searchkeyword,
          areaid:this.state.areaid,
          lineid:this.state.lineid,
          linemanid:this.state.linemanid,
          hawkerid:this.state.hawkerid,
          openingbalanceid:this.state.openingbalanceid,
          nameandphone:this.state.nameandphone,
      };

      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/contact.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            app.preloader.hide();
            
            app.ptr.done();

            const resdata = JSON.parse(res.data);

            if(resdata.success === true)
            {
              this.setState({
                loaded:true,
                isavailable:true,
                contacts:resdata.recordlist,
                allcontacts:resdata.recordlist,
                perpage:Number(resdata.perpage),
                totalpages:Number(resdata.totalpages),
                paginglist:resdata.paginglist,
                showpages:resdata.showpages,
                totalrecord:resdata.totalrecord,
                isptr:false,
              });
            }
            else{
              this.setState({
                loaded:true,
                isavailable:false,
                isptr:false,
              });
            }

            if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || Number(this.state.openingbalanceid) > 0 || (this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined))
            {
              this.setState({hasallcustomer:false});
            }
            else
            {
              this.setState({hasallcustomer:true});
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
  }

  searchCustomer(){

    const{allcustomers} = this.state;

    let tempCustomers  = [];

    if(this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined)
    {
          allcustomers.forEach(customer => {

        if((customer.name.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (customer.phone.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (customer.email.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (customer.name.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (customer.address1.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1) || (customer.customerid.toLowerCase().indexOf(this.state.searchkeyword.toLowerCase()) !== -1))
        {
          tempCustomers = [...tempCustomers, customer];
        }

      });
      
      if(tempCustomers.length > 0)
      {
        this.setState(()=>{
          return{
            contacts:tempCustomers,
            isavailable:true
          }
        });
      }
      else
      {
        this.setState(()=>{
          return{
            contacts:[],
            isavailable:false
          }
        });
      }
    }
    else
    {
      this.setState(()=>{
        return{
            contacts:allcustomers,
          isavailable:true
        }
      });
    }

  }

  handleOpeningBalance(id){

    let tempcustomers       = [...this.state.customers];
    const tempcustomers2    = tempcustomers.find(person => Number(person.id) === Number(id));

    tempcustomers2.canchangebalance   = !tempcustomers2.canchangebalance;

    this.setState(()=>{
      
      return{

        contacts:tempcustomers

      }

    });
  }

  changeOpeningBalance(e){

    const value     = e.target.value;
    const personid  = e.target.id;

    let tempcustomers       = [...this.state.customers];
    const tempcustomers2    = tempcustomers.find(person => Number(person.id) === Number(personid));

    tempcustomers2.openingbalance   = value;

    this.setState(()=>{
      
      return{

        contacts:tempcustomers

      }

    });

  }

  updateOpeningBalance(id){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      let tempcustomers       = [...this.state.customers];
      const tempcustomers2    = tempcustomers.find(person => Number(person.id) === Number(id));
  
      const bodyFormData = {
          Mode:'UpdateOpeningBalance',
          clientid:this.state.clientdetail.id,
          recordid:id,
          isopeningbalance:1,
          openingbalance:tempcustomers2.openingbalance,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/contact.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              tempcustomers2.canchangebalance   = false;
              if(tempcustomers2.openingbalance !== "")
              {
                tempcustomers2.openingbalancetxt  = tempcustomers2.openingbalance;
              }
              else
              {
                tempcustomers2.openingbalancetxt  = "---";
              }

              this.setState({
                contacts:tempcustomers,
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
  }

  navigateToLink(link){

    if(this.$f7.online)
    {
      this.$f7router.navigate(`/${link}/`);
      /*this.$f7.views.main.router.navigate(`/${link}/`);*/
    }

  }

  deleteFilterAction(filtername){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    let isfilterremoved = false;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              if(filtername === "area")
              {
                this.setState(()=>{
                  return{
                    areaid:'',
                    filterarea:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  isfilterremoved = true;

                })
              }
              else if(filtername === "line")
              {
                this.setState(()=>{
                  return{
                    lineid:'',
                    filterline:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  isfilterremoved = true;

                })
              }
              else if(filtername === "lineman")
              {
                this.setState(()=>{
                  return{
                    linemanid:'',
                    filterlineman:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  isfilterremoved = true;

                })
              }
              else if(filtername === "hawker")
              {
                this.setState(()=>{
                  return{
                    hawkerid:'',
                    filterhawker:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  isfilterremoved = true;

                })
              }
              else if(filtername === "openingbalance")
              {
                this.setState(()=>{
                  return{
                    openingbalanceid:'',
                    filteropeningbalance:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  isfilterremoved = true;

                })
              }
              else if(filtername === "searchkeyword")
              {
                this.setState(()=>{
                  return{
                    searchkeyword:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  isfilterremoved = true;

                })
              }

              else if(filtername === "nameandphone")
              {
                this.setState(()=>{
                  return{
                    nameandphone:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  isfilterremoved = true;

                })
              }

              if(isfilterremoved)
              {
                if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.openingbalanceid !== "" || this.state.searchkeyword !== "" || this.state.nameandphone !== "")
                {
                  this.setState({hasfilter:true,hasallcustomer:false});
                }
                else
                {
                  this.setState({hasfilter:true,hasallcustomer:true});
                }
                this.getAllContacts();
              }
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

  manageContactAction(recordid){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    const appButton     = [{
        text: 'Select an action to proceed',
        label: true,
    }];
    
    appButton.push({
        text: 'Mark Complete',
        bold: true,
        color:'blue',
        onClick:()=>{
            this.markComplete(recordid);
        }
    });

    app.actions.create({
      buttons: [
      // First group
      appButton,
      // Second group
      [
        {
          text: 'Cancel',
          color: 'red'
        }
      ]]
    }).open();
  }

  markComplete(recordid){

    const self      = this;
    const app       = self.$f7;

    if(!this.$f7.online)
    {
      return;
    }

    const bodyFormData = {
      Mode:'MarkComplete',
      clientid:this.state.clientdetail.id,
      recordid:recordid,
    };

    app.preloader.show();

    setTimeout(()=> {

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/contact.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

          if(resdata.success === true)
          {
            this.getAllContacts();
          }

      }).catch(e => {
          console.log(e);
      });

    }, 500);

  }

  deleteRecordAction(recordid){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              this.setState(()=>{
                return{
                  deleterecordid:recordid
                }
              },()=>{

                this.deleteRecord();

              })
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

  init(filtervars, searchkeyword){

    let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker, openingbalanceid, filteropeningbalance, page, nameandphone} = filtervars;

    let canloadcustomer = false;

    Promise.all([
          
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        setTimeout(()=> {

          if(Number(page) < 1 || page === "NaN" || page === "undefined" || page === undefined)
          {
            page = this.state.page;
          }

          if(Number(areaid) > 0)
          {
            this.setState(()=>{

              return{

                areaid:Number(areaid),
                filterarea:filterarea,
                lineid:Number(lineid),
                filterline:filterline,
                hawkerid:Number(hawkerid),
                filterhawker:filterhawker,
                openingbalanceid:openingbalanceid,
                filteropeningbalance:filteropeningbalance,
                nameandphone:nameandphone,
                page:page,
                hasfilter:true,
                hasallcustomer:false
              }

            },()=>{
              canloadcustomer = true;
            });
          }
          else if(searchkeyword !== "" && searchkeyword !== undefined && searchkeyword !== "advancesearch")
          {
            this.setState(()=>{

              return{

                searchkeyword:searchkeyword,
                hasfilter:true,
                hasallcustomer:false
                
              }

            },()=>{
              canloadcustomer = true;
            });
          }
          else if(searchkeyword !== "" && searchkeyword === "advancesearch")
          {

            this.setState(()=>{

              return{

                popupOpened:true,
                
              }

            },()=>{
              canloadcustomer = true;
            });

          }
          else
          {
            this.setState(()=>{

              return{

                areaid:Number(areaid),
                filterarea:filterarea,
                lineid:Number(lineid),
                filterline:filterline,
                hawkerid:Number(hawkerid),
                filterhawker:filterhawker,
                openingbalanceid:openingbalanceid,
                filteropeningbalance:filteropeningbalance,
                page:page,
                hasfilter:true,
                hasallcustomer:false,
                isinit:true
                
              }

            },()=>{
              canloadcustomer = true;
            });
            
          }

          if(canloadcustomer)
          {
            this.getAllContacts();
          }
          
        }, 200)

      })
  }

	render(){
		return (
			<WebsiteContext.Provider value={{
              ...this.state,
                init:this.init,
                handleUserInput:this.handleUserInput,
                getAllContacts:this.getAllContacts,
                searchCustomer:this.searchCustomer,
                setNewFilterData:this.setNewFilterData,
                openFilterPopup:this.openFilterPopup,
                navigateToLink:this.navigateToLink,
                applySearchCustomer:this.applySearchCustomer,
                handleOpeningBalance:this.handleOpeningBalance,
                changeOpeningBalance:this.changeOpeningBalance,
                updateOpeningBalance:this.updateOpeningBalance,
                deleteFilterAction:this.deleteFilterAction,
                Paging:this.Paging,
                manageContactAction:this.manageContactAction,
                deleteRecordAction:this.deleteRecordAction,
			}}
			>
			{this.props.children}
			</WebsiteContext.Provider>
		);
	}
}

const WebsiteConsumer = WebsiteContext.Consumer;

export {WebsiteProvider, WebsiteConsumer, WebsiteContext};