import React,{Component, Fragment} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer, FilterContext } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Row,
    Col,
    BlockTitle,
    Toolbar,
    Button,
    Link
  } from 'framework7-react';

  export default class EditPurchase extends Component {
    constructor(props) {
      super(props);

      let today = new Date();

      let currmonth = today.getMonth()+1;
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }

      let currdate = today.getDate();
      if(currdate < 10)
      {
          currdate = "0"+currdate;
      }

      let date = today.getFullYear()+'-'+currmonth+'-'+currdate;
  
      this.state = {
        clientdetail:[],
        droppingpointid:'',
        droppingpointname:'Select',
        inventoryid:'',
        inventoryname:'Select',
        purchasedate:date,
        noofpices:'',
        inventorylist:[],
        droppingpointlist:[],
        hassmartinventory:false,
        type:true,
        issuedate:date,
        ismount:false,
        hassmartdroppingpoint:false,
        issmartdroppingpoint:false,
        purchaserate:'',
        colwidth:80,
      };

      this.handleUserInput                  = this.handleUserInput.bind(this);
      this.inventoryAutocompletePopup       = this.inventoryAutocompletePopup.bind(this);
      this.droppingPointAutocompletePopup   = this.droppingPointAutocompletePopup.bind(this);
      this.getDroppingPoint                 = this.getDroppingPoint.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.getDetail();
        this.hideSplashScreen();
      });

      setTimeout(()=> {

        this.setState({ismount:true});

      }, 1200);
    }
    droppingPointAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchdroppingpointlist  = this.state.droppingpointlist;

      self.autocompleteDroppingPointPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-droppingpoint-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectdroppingpoint/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchdroppingpointlist.length; i += 1) {
            if (searchdroppingpointlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchdroppingpointlist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-droppingpoint-name2').text(value[0].name);
            $('.autocomplete-droppingpoint-popup').removeClass('error');
            
            self.setState({
              droppingpointid:value[0].id,
              droppingpointname:value[0].name,
              issmartdroppingpoint:true,
            });

          },
        },
      });
    }
    fetchNewDroppingPoint(newdroppingpointid, newdroppingpointname){

      this.setState(()=>{

        return{

          droppingpointid:newdroppingpointid

        }

      },()=>{

        this.Dom7('#autocomplete-droppingpoint-name2').text(newdroppingpointname);
        this.getDroppingPoint();
        /*this.getLine();*/

      })

    }
    
    getDroppingPoint(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
          Mode:'GetDroppingPoint',
          clientid:clientdetail.id
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/droppingpoint.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                droppingpointlist:resdata.recordlist,
              },()=>{

                if(this.state.hassmartdroppingpoint === false)
                {
                  this.setState({hassmartdroppingpoint:true});
                  this.droppingPointAutocompletePopup();
                }
                
              });
            }
            else
            {
              this.setState({
                droppingpointlist:[],
              },()=>{

                if(this.state.hassmartdroppingpoint === false)
                {
                  this.setState({hassmartdroppingpoint:true});
                  this.droppingPointAutocompletePopup();
                }

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'type')
      {
        this.setState({type:!this.state.type});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    inventoryAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchlinelist  = this.state.inventorylist;

      self.autocompleteInventoryPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-inventory-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectinventory/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinelist.length; i += 1) {
            
            if((searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchlinelist[i].id.toLowerCase().indexOf(query.toLowerCase()) >= 0) && (searchlinelist[i].type === Number(self.state.type)))
            {
              results.push(searchlinelist[i]);
            }

          }
          render(results);
        },
        on: {
          change(value) {

            /*$('#autocomplete-inventory-popup').find('.item-title').text(value[0].name);*/
            self.setState(()=>{
              return{
                inventoryid:value[0].id,
                inventoryname:value[0].name,
              }
            },()=>{
                $('.autocomplete-inventory-popup').removeClass('error');
            })

          },
        },
      });
    }

    getAllInventory(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetAllInventory',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
        };
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                  this.setState(()=>{
                      return{
                          inventorylist:resdata.inventorylist,
                          isptr:false
                      }
                  },()=>{
                    if(this.state.hassmartinventory === false)
                    {
                      this.setState({hassmartinventory:true});
                      this.inventoryAutocompletePopup();
                    }
                  });
              }
              else
              {
                this.setState(()=>{
                return{
                    isptr:false
                }
                },()=>{
                    if(this.state.hassmartinventory === false)
                    {
                      this.setState({hassmartinventory:true});
                      this.inventoryAutocompletePopup();
                    }
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(!this.$f7.online)
        {
          return;
        }

        const bodyFormData = {
            Mode:'GetPurchaseDetail',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.id,
        };

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/purchase.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
  
                inventoryid:resdata.detail.inventoryid,
                droppingpointid:resdata.detail.droppingpointid,
                purchasedate:resdata.detail.purchasedate,
                noofpices:resdata.detail.noofpices,
                purchaserate:resdata.detail.purchaserate,
                issuedate:resdata.detail.issuedate,
                type:resdata.detail.type,
                inventoryname:resdata.detail.inventoryname,
                droppingpointname:resdata.detail.droppingpointname

              },()=>{
                this.getAllInventory();
                this.getDroppingPoint();
              });
            }
            else
            {
                this.getAllInventory();
                this.getDroppingPoint();
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    saveDetail(){

      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;
      const router    = self.$f7router;

      let errmsg    = "";

      if(!this.$f7.online)
      {
        return;
      }

      app.input.validateInputs('.adddata');

      if(this.state.droppingpointid === "" || this.state.droppingpointid === undefined)
      {
        errmsg  = "Please select a dropping point";
        this.$$('.autocomplete-droppingpoint-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-droppingpoint-popup').removeClass('error');
      }
      if((this.state.inventoryid === "" || this.state.inventoryid === undefined) && errmsg === "")
      {
        errmsg  = "Please select a stock";
      }

      if(this.state.noofpices === "" && errmsg === "")
      {
        errmsg  = "Please enter number of pieces";
      }

      if((this.state.purchaserate === "" || this.state.purchaserate < 0.001) && errmsg === "")
      {
        errmsg  = "Please enter purchase rate";
      }

      if(this.state.inventoryid === "" || this.state.inventoryid === undefined)
      {
        this.$$('.autocomplete-inventory-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-inventory-popup').removeClass('error');
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
          Mode:'EditPurchase',
          clientid:self.state.clientdetail.id,
          recordid:self.props.f7route.params.id,
          inventoryid:self.state.inventoryid,
          purchasedate:self.state.purchasedate,
          noofpices:self.state.noofpices,
          type:Number(self.state.type),
          issuedate:self.state.issuedate,
          droppingpointid:self.state.droppingpointid,
          purchaserate:self.state.purchaserate,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/purchase.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            if(resdata.success !== true)
            {
              toastWithButton.open();
            }

            if(resdata.success === true)
            {
                router.navigate('/purchase/');
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

    const{inventorylist,inventoryname, purchasedate, noofpices, type, issuedate, purchaserate,droppingpointid,droppingpointname,issmartdroppingpoint,ismount,colwidth} = this.state;
    return( 
            <FilterConsumer>{(value)=>{
              const{setDroppingPointData}  = value;

              const{singledroppingpoint} = value;

              if(ismount === true)
              {
                setTimeout(()=> {

                    if((droppingpointid !== singledroppingpoint.id) &&  singledroppingpoint.id > 0)
                    {
                        this.fetchNewDroppingPoint(singledroppingpoint.id, singledroppingpoint.name);
                    }                    
          
                }, 500)
                
              }
                return(
                  <Page pageContent={false} className="page" name="EditPurchase">
                        <Navbar sliding={true}>
                          <NavLeft>
                            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                            <Link href={`/dashboard/`} className="clearmenupadding">
                              <img className="navbarlogo" src={scanlogo} alt="logo" />
                            </Link>
                          </NavLeft>
                          <NavTitle>Edit Purchase</NavTitle>
                          <NavRight className="right-with-menu">
                          </NavRight>
                        </Navbar>
                        <PageContent>
                        <Block>
                          <Row noGap>
                              <Col width="40">
                                  <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                      <ListItem radio value="1" name="type" checked={Number(type) === 1} title="Newpaper"
                                      onChange={this.handleUserInput}
                                      ></ListItem>
                                  </List>
                              </Col>
                              <Col width="60">
                                  <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                      <ListItem radio value="1" name="type" checked={Number(type) === 0} title="Magazine"
                                      onChange={this.handleUserInput}
                                      ></ListItem>
                                  </List>
                              </Col>
                              <Col width="15"></Col>
                          </Row>
                          <Row>
                              <Col width={colwidth}>
                              <List noHairlinesMd className="zeromargin">
                                <ListItem link="#" id="autocomplete-droppingpoint-popup" className="autocomplete-droppingpoint-popup" title="Dropping Point">
                                  <div slot="after" id="autocomplete-droppingpoint-name2">{droppingpointname}</div>
                                </ListItem>
                              </List>
                              </Col>
                              {
                                  <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                                    <Link onClick={()=>{
                                      if(this.$f7.online)
                                      {
                                        this.$f7router.navigate('adddroppingpointpopup/')
                                      }
                                    }}>
                                      <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                                    </Link>
                                  </Col>
                              }
                          </Row>
                          <List noHairlinesMd style={{marginTop:'5px'}}>
                              <ListItem link="#" id="autocomplete-inventory-popup" className="autocomplete-inventory-popup" title="Stock">
                                  <div slot="after" id="autocomplete-inventory-name2">{inventoryname}</div>
                              </ListItem>
                              <ListInput
                                  name="purchasedate"
                                  id="calendar-purchasedate-input"
                                  label="Purchase Date"
                                  placeholder="Purchase Date"
                                  type="date"
                                  value={purchasedate}
                                  onChange={this.handleUserInput}
                                  calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                              >
                              </ListInput>
                              <ListInput
                                  name="noofpices"
                                  className="adddata"
                                  label="Number of Pieces"
                                  floatingLabel
                                  type="text"
                                  onInput={this.handleUserInput}
                                  placeholder="Number of Pieces"
                                  value={noofpices}
                                  maxlength="20"
                                  clearButton required validate
                              >
                              </ListInput>
                              {(()=>{
                                if(Number(type) === 0)
                                {
                                  return(
                                      <ListInput
                                          name="issuedate"
                                          id="calendar-issuedate-input"
                                          label="Issue Date"
                                          placeholder="Issue Date"
                                          type="date"
                                          value={issuedate}
                                          onChange={this.handleUserInput}
                                          calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                                      >
                                      </ListInput>
                                  );
                                }
                              })()}
                              <ListInput
                                  name="purchaserate"
                                  className="adddata"
                                  label="Purchase Rate"
                                  floatingLabel
                                  type="number"
                                  onInput={this.handleUserInput}
                                  placeholder="Purchase Rate"
                                  value={purchaserate}
                                  maxlength="20"
                                  clearButton required
                              >
                              </ListInput>
                          </List>
                    </Block>
                    <br /><br />
                    </PageContent>
                    <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
                  </Page>
                );

            }}</FilterConsumer>
         )
      }
}