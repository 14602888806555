import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Link,
    Block,
    List,
    ListItem,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    Row,
    Col
  } from 'framework7-react';

  export default class PurchaseSummary extends Component {
    
    constructor(props) {
      
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let lastdate = lastDay.getDate();

      let startdate = today.getFullYear()+'-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+lastdate;
     
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        isfetched:false,
        currentbreakupdiv:'',
        isptr:false,
        ismount:false,
        hasallcustomer:false,
        hasfilter:true,
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        hasdataavailable:false,
        effect:'blink',
        purchasesummary:[],
        droppingpointtype:'',
        droppingpointid:'',
        droppingpointname:'',
        isstock:'',
        inventoryid:'',
        inventoryname:'',
        totaldroppoint:0,
        totalstock:0,
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getPurchaseSummary   = this.getPurchaseSummary.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id,
          filtervars:this.props.$f7route.query,
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getPurchaseSummary();
        this.hideSplashScreen();

        let{startdate, enddate, filtermonthyear, catid, catname} = this.state.filtervars;
       
        this.setState({
          startdate:startdate,
          enddate:enddate,
          filtermonthyear:filtermonthyear,
          catid:catid,
          catname:catname,
        })

      });

    }
    showHide(divname)
    {
      if(this.state.currentbreakupdiv === divname)
      {
        this.setState(()=>{
          return{
            currentbreakupdiv:'',
          }
        })
      }
      else
      {
        this.setState(()=>{
          return{
            currentbreakupdiv:divname,
          }
        })  
      }
    }
    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getPurchaseSummary(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
    
        const bodyFormData = {
          Mode:'GetPurchaseSummary',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          startdate:self.state.startdate,
          enddate:self.state.enddate,
          droppingpointtype:self.state.droppingpointtype,
          droppingpointid:self.state.droppingpointid,
          isstock:self.state.isstock,
          inventoryid:self.state.inventoryid,
        };

        this.setState(()=>{

          return{
            isfetched:false
          }
          
        },()=>{

          setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                    isfetched:true,
                    purchasesummary:resdata.recordset,
                    totaldroppoint:resdata.totaldroppoint,
                    totalstock:resdata.totalstock,
                    hasdataavailable:true,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });

              }
              else
              {
                this.setState({
                    isfetched:true,
                    purchasesummary:[],
                    totaldroppoint:'0',
                    totalstock:'0',
                    hasdataavailable:false,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        },500);

      });
    }

    getPurchaseSummaryPDF(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
            Mode:'GetMonthlyBillOfNewspaperPDF',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            startdate:self.state.startdate,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                    //console.log(resdata.pdffilepath);
                   // window.location.href= 'http://orlopay/agency/';
                    window.open(resdata.pdffilepath);
                }
                else
                {
                    const toastWithButton = app.toast.create({
                        text: resdata.msg,
                        closeButton: true,
                        closeTimeout: 3000,
                    });
                      
                    toastWithButton.open();    
                }
            }).catch(e => {
                console.log(e);
            });

        },500);
    }

    setNewFilterData(startdate, enddate, droppingpointtype, droppingpointid, droppingpointname, isstock, inventoryid, inventoryname){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            startdate:startdate,
            enddate:enddate,
            droppingpointtype:droppingpointtype,
            droppingpointid:droppingpointid,
            droppingpointname:droppingpointname,
            isstock:isstock,
            inventoryid:inventoryid,
            inventoryname:inventoryname,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
          }
  
        },()=>{

         const{startdate, enddate, droppingpointtype, droppingpointid, isstock, inventoryid} = this.state;
  
          if((startdate !== "" && startdate !== undefined) || (enddate !== "" && enddate !== undefined) || (droppingpointtype !== "" && droppingpointtype !== undefined) || (droppingpointid !== "" && droppingpointid !== undefined) || (isstock !== "" && isstock !== undefined) || (inventoryid !== "" && inventoryid !== undefined))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getPurchaseSummary();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){

      const{isfetched, startdate, enddate, hasallcustomer, hasfilter, removefiltername, applyremovefilter, ismount, hasdataavailable, droppingpointtype, droppingpointid, droppingpointname, isstock, inventoryid, inventoryname, purchasesummary, totaldroppoint, totalstock} = this.state;

      let droppingpointtotal  = 0;
      let inventorytotal      = 0;

      let datestart     = "";
      let newstartdate  = "";

      if(startdate !== "" && startdate !== undefined)
      {
        newstartdate = new Date(startdate);

        let currmonth = newstartdate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        datestart = newstartdate.getDate()+"-"+currmonth+"-"+newstartdate.getFullYear();
      }

      let dateend     = "";
      let newenddate  = "";

      if(enddate !== "" && enddate !== undefined)
      {
        newenddate = new Date(enddate);

        let currmonth = newenddate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
      }

      return(
        <Page className="page" name="CloseCustomerList">
        <Navbar sliding={true}>
          <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
            <Link href={`/dashboard/`} className="clearmenupadding">
              <img className="navbarlogo" src={scanlogo} alt="logo" />
            </Link>
          </NavLeft>
          <NavTitle>Purchase Summary</NavTitle>
          <NavRight className="right-with-menu">
          <Link onClick={()=>{

            this.getPurchaseSummary();

          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
          </NavRight>
        </Navbar>
      <FilterConsumer>{(value)=>{

        const{purchasesummaryfilterdata, applyPurchaseSummaryFilter, numberWithCommas} = value;

        if(ismount === true)
        {
          setTimeout(()=> {

            if(removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(removefiltername === 'daterange')
              {
                applyPurchaseSummaryFilter('', '', droppingpointtype, droppingpointid, droppingpointname, isstock, inventoryid, inventoryname);
              }
              else if(removefiltername === 'droppingpoint')
              {
                applyPurchaseSummaryFilter(startdate, enddate, '', '', '', isstock, inventoryid, inventoryname);
              }
              else if(removefiltername === 'stock')
              {
                applyPurchaseSummaryFilter(startdate, enddate, droppingpointtype, droppingpointid, droppingpointname, '', '', '');
              }
            }

            if(((purchasesummaryfilterdata.startdate !== startdate) && purchasesummaryfilterdata.startdate !== undefined) || ((purchasesummaryfilterdata.enddate !== enddate) && purchasesummaryfilterdata.enddate !== undefined) || ((purchasesummaryfilterdata.droppingpointtype !== droppingpointtype) && purchasesummaryfilterdata.droppingpointtype !== undefined) || ((purchasesummaryfilterdata.droppingpointid !== droppingpointid) && purchasesummaryfilterdata.droppingpointid !== undefined) || ((purchasesummaryfilterdata.isstock !== isstock) && purchasesummaryfilterdata.isstock !== undefined) || ((purchasesummaryfilterdata.isstock !== isstock) && purchasesummaryfilterdata.isstock !== undefined) || ((purchasesummaryfilterdata.inventoryid !== inventoryid) && purchasesummaryfilterdata.inventoryid !== undefined) || applyremovefilter === true)
            {
              this.setNewFilterData(purchasesummaryfilterdata.startdate, purchasesummaryfilterdata.enddate, purchasesummaryfilterdata.droppingpointtype, purchasesummaryfilterdata.droppingpointid, purchasesummaryfilterdata.droppingpointname, purchasesummaryfilterdata.isstock, purchasesummaryfilterdata.inventoryid, purchasesummaryfilterdata.inventoryname);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }

          }, 500)
        }
        
          return(
            <Block>
              {
                hasfilter ? (
                    <Block strong className="selectedfilter" style={{marginTop:0}}>
                        {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                        {datestart ? (<Chip text={`Date : (${datestart}) - (${dateend})`} mediaBgColor="blue" media={`D`}
                        />):null}
                        {Number(droppingpointtype) && Number(droppingpointid) ? (<Chip text={`Dropping Point : ${droppingpointname}`} mediaBgColor="pink" media={droppingpointname.toUpperCase().substring(0, 1)} deleteable
                        onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.setState(()=>{
                                    return {
                                      removefiltername:'droppingpoint'
                                    }
                                  },()=>{
                                    this.refs.actionsFilterGroup.open()
                                  })
                                }
                              }}
                        />):null}
                        {Number(isstock) && Number(inventoryid) ? (<Chip text={`Inventory : ${inventoryname}`} mediaBgColor="pink" media={inventoryname.toUpperCase().substring(0, 1)} deleteable
                        onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.setState(()=>{
                                    return {
                                      removefiltername:'stock'
                                    }
                                  },()=>{
                                    this.refs.actionsFilterGroup.open()
                                  })
                                }
                              }}
                        />):null}
                        &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.$f7router.navigate(`filterpopup/?startdate=${startdate}&enddate=${enddate}&droppingpointtype=${droppingpointtype}&droppingpointid=${droppingpointid}&droppingpointname=${droppingpointname}&isstock=${isstock}&inventoryid=${inventoryid}&inventoryname=${inventoryname}&enddate=${enddate}`);
                            }
                            /*this.openFilterPopup();*/
                        }}/>
                    </Block>
                ):null
              }
              {
                isfetched? (<Fragment>
                    <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center',fontSize:'1.2rem'}}>Total Records : {totaldroppoint}</BlockTitle>
                    {
                        hasdataavailable ? (<Fragment>
                            <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center'}}>Dropping Point Summary</BlockTitle>
                            <div className="outstandingwrapper">
                                <Row noGap className='rowwithbottomborder'>
                                    <Col width='25' className="tableheader textalignleft">
                                        S.No.
                                    </Col>
                                    <Col width='50' className="tableheader textalignleft">
                                        Dropping Point
                                    </Col>
                                    <Col width='25' className="tableheader textalignright">
                                        Qty
                                    </Col>
                                </Row>
                                {
                                    purchasesummary.droppingpointdata.map((detail)=>{

                                        droppingpointtotal  += Number(detail.qty);
                                        let divname ="breakupdiv-drop-"+detail.id;
                                        return(
                                          <Fragment key={detail.serialno} >
                                        <Row noGap className='rowwithbottomborder'>
                                            <Col width='10' className="ledgercolumn textalignleft">
                                                {detail.serialno}
                                            </Col>
                                            <Col width='50' className="ledgercolumn textalignleft">
                                                {detail.name}
                                               
                                            </Col>
                                            <Col width='35' className="ledgercolumn textalignright">
                                                {numberWithCommas(detail.qty)}
                                                {
                                                (()=>{
                                                  if(detail.datesbreakup.length > 0)
                                                  {
                                                    return (<Fragment>
                                                      <Link onClick={(()=>{
                                                          this.showHide(divname)
                                                      })} className="smallbox2" ignoreCache={true}>
                                                         <Icon f7="arrow_up_arrow_down_circle_fill" style={{marginLeft:'10px'}}></Icon>
                                                     </Link></Fragment>)
                                                  }
                                                })()
                                                }
                                                
                                            </Col>
                                        </Row>
                                        {(()=>{
                                          if(this.state.currentbreakupdiv === divname)
                                          {
                                            let loop = 0;
                                            return(<Fragment >
                                              <Row noGap className={`rowwithbottomborder ${divname}`}>
                                                <Col width='60' className="tableheader textaligncenter">
                                                    <strong>Date</strong>
                                                </Col>
                                                <Col width='40' className="tableheader textaligncenter">
                                                <strong>Qty</strong>
                                                </Col>
                                              </Row>
                                              {
                                                detail.datesbreakup.map((day)=>{
                                                  let keyindex =""+'drop'+detail.id+'-'+loop+"";
                                                  loop = loop + 1;
                                                  return(
                                                    <Row key={keyindex} noGap className={divname}>
                                                    <Col width='60' style={{textAlign:'center'}}>
                                                      {day.date}
                                                    </Col>
                                                    <Col width='40' style={{textAlign:'center'}}>
                                                    {numberWithCommas(day.qty.toString())}
                                                  </Col>
                                                  </Row>
                                                )
                                              })
                                              }
                                              <Row noGap className={`rowwithbottomborder ${divname}`}>
                                                <Col width='30' className="tableheader textalignright">
                                                  Total Days:
                                                </Col>
                                                <Col width='20' className="tableheader textalignleft">
                                                  {detail.datesbreakup.length}
                                                </Col>
                                                <Col width='30' className="tableheader textalignright">
                                                  Grand Total:
                                                </Col>
                                                <Col width='20' className="tableheader textalignleft">
                                                  {numberWithCommas(detail.qty)}
                                                </Col>
                                              </Row>
                                            </Fragment>);
                                          }
                                        })()}
                                        </Fragment>);
                                    })
                                }
                                <Row noGap className='rowwithbottomborder'>
                                    <Col width='15' className="tableheader textalignleft">
                                        &nbsp;
                                    </Col>
                                    <Col width='50' className="tableheader textalignleft">
                                        Total
                                    </Col>
                                    <Col width='35' className="tableheader textalignright">
                                        {numberWithCommas(droppingpointtotal)}
                                    </Col>
                                </Row>
                            </div>
                            <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center',fontSize:'1.2rem'}}>Total Records : {totalstock}</BlockTitle>
                            <BlockTitle medium style={{marginTop:'20px',marginBottom:'10px',textAlign:'center'}}>Stock Summary</BlockTitle>
                            <div className="outstandingwrapper">
                                <Row noGap className='rowwithbottomborder'>
                                    <Col width='15' className="tableheader textalignleft">
                                        S.No.
                                    </Col>
                                    <Col width='50' className="tableheader textalignleft">
                                        Stock
                                    </Col>
                                    <Col width='35' className="tableheader textalignright">
                                        Qty
                                    </Col>
                                </Row>
                                {
                                    purchasesummary.inventorydata.map((detail)=>{

                                        inventorytotal  += Number(detail.qty);
                                        let intkey =""+'inv'+detail.id+"";
                                        let divname ="breakupdiv-inv-"+detail.id;
                                       return(
                                       <Fragment key={intkey}>
                                       <Row noGap className='rowwithbottomborder'>
                                            <Col width='15' className="ledgercolumn textalignleft">
                                                {detail.serialno}
                                            </Col>
                                            <Col width='50' className="ledgercolumn textalignleft">
                                                {detail.name}
                                            </Col>
                                            <Col width='35' className="ledgercolumn textalignright">
                                                {numberWithCommas(detail.qty)}
                                                {
                                                (()=>{
                                                  if(detail.datesbreakup.length > 0)
                                                  {
                                                    return (<Fragment>
                                                      <Link onClick={(()=>{
                                                          this.showHide(divname)
                                                      })} className="smallbox2" ignoreCache={true}>
                                                      <Icon f7="arrow_up_arrow_down_circle_fill" style={{marginLeft:'10px'}}></Icon> 
                                                      </Link></Fragment>)
                                                  }
                                                })()
                                                }
                                            </Col>
                                        </Row>
                                        {(()=>{
                                            if(this.state.currentbreakupdiv === divname)
                                            {
                                              let loop = 0;
                                              return(<Fragment>
                                                <Row noGap className={`rowwithbottomborder ${divname}`}>
                                                <Col width='60' className="tableheader textaligncenter">
                                                    <strong>Date</strong>
                                                </Col>
                                                <Col width='40' className="tableheader textaligncenter">
                                                <strong>Qty</strong>
                                                </Col>
                                              </Row>
                                                {
                                                  detail.datesbreakup.map((day)=>{
                                                    let keyindex2 =""+'inv'+detail.id+'-'+loop+"";
                                                    loop = loop+1;
                                                    return(
                                                      <Row key={keyindex2} noGap className={divname}>
                                                      <Col width='60' style={{textAlign:'center'}}>
                                                        {day.date}
                                                      </Col>
                                                      <Col width='40' style={{textAlign:'center'}}>
                                                      {numberWithCommas(day.qty.toString())}
                                                    </Col>
                                                    </Row>
                                                  )
                                                  })
                                                }
                                                <Row noGap className={`rowwithbottomborder ${divname}`}>
                                                  <Col width='30' className="tableheader textalignright">
                                                    Total Days:
                                                  </Col>
                                                  <Col width='20' className="tableheader textalignleft">
                                                    {detail.datesbreakup.length}
                                                  </Col>
                                                  <Col width='30' className="tableheader textalignright">
                                                    Grand Total:
                                                  </Col>
                                                  <Col width='20' className="tableheader textalignleft">
                                                    {numberWithCommas(detail.qty)}
                                                  </Col>
                                                </Row>
                                              </Fragment>);
                                            }
                                        })()}
                                        </Fragment>
                                        );
                                    })
                                }
                                <Row noGap className='rowwithbottomborder'>
                                    <Col width='25' className="tableheader textalignleft">
                                        &nbsp;
                                    </Col>
                                    <Col width='50' className="tableheader textalignleft">
                                        Total
                                    </Col>
                                    <Col width='25' className="tableheader textalignright">
                                        {numberWithCommas(inventorytotal)}
                                    </Col>
                                </Row>
                            </div>
                        </Fragment>):(<AppItemNotFound notfoundtext ="No record found." />)
                    }
                    </Fragment>):(<AppListItemLoading />)
              }
              <br /><br />
            </Block>
          );
        }}
      </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {/*
            hasdataavailable ? (
               <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                    if(this.$f7.online)
                        {
                          this.getPurchaseSummaryPDF();
                        }
                  }}>
                  <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                  <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                </Fab>
            ):null
         */}
        </Page>

      );
    }
}