import React,{Component, Fragment} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Link,
    Toolbar,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    BlockTitle,
    Block,
    List,
    Row,
    Col,
    ListItem,
    Toggle,
    ListInput,
    Button  } from 'framework7-react';

  export default class SMSCredit extends Component {
    constructor(props) {
      super(props);

      this.state = {
        clientdetail:[],
        customerid:'',
        quantity:'1',
        areaid:'',
        lineid:'',
        filterarea:'Select',
        filterline:'Select',
        arealist:[],
        linelist:[],
        customername:'Select',
        customerlist:[],
        inventorylist:[],
        isptr:false,
        filtervars:this.props.$f7route.query,
        ispreselectedcustomer:false,
      };
    }
    
    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      let areaid = "", lineid = "", customerid = "", filterarea = "Select", filterline = "Select", customername = "Select", ispreselectedcustomer = false;

      if(clientdetail.subscription_areaid !== null && clientdetail.subscription_areaid !== undefined)
      {
        areaid      = clientdetail.subscription_areaid;
        filterarea  = clientdetail.subscription_filterarea;
      }
      if(clientdetail.subscription_lineid !== null && clientdetail.subscription_lineid !== undefined)
      {
        lineid      = clientdetail.subscription_lineid;
        filterline  = clientdetail.subscription_filterline;
      }
      if(clientdetail.subscription_customerid !== null && clientdetail.subscription_customerid !== undefined)
      {
        customerid    = clientdetail.subscription_customerid;
        customername  = clientdetail.subscription_customername;
      }
      if(this.props.f7route.params.id !== null && this.props.f7route.params.id !== undefined)
      {
        customerid            = this.props.f7route.params.id;
        ispreselectedcustomer = true;
      }

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          areaid:areaid,
          lineid:lineid,
          customerid:customerid,
          ispreselectedcustomer:ispreselectedcustomer,
          filterarea:filterarea,
          filterline:filterline,
          customername:customername,
        }
      },()=>{

        if(!this.state.ispreselectedcustomer)
        {
          this.areaAutocompletePopup();
          this.lineAutocompletePopup();
          this.customerAutocompletePopup();
        }

        if(Number(this.state.customerid) > 0)
        {
          this.getInventoryDetail();
        }
        this.hideSplashScreen();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }
    
    onPageBeforeRemove(){

      if(!this.state.ispreselectedcustomer)
      {
        const self = this;
        self.autocompleteAreaPopup.destroy();
        self.autocompleteLinePopup.destroy();
        self.autocompleteCustomerPopup.destroy();
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
    
    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-area-popup').find('.item-after').text(value[0].name);
            /*$('#autocomplete-line-popup').find('.item-after').text('Select');*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              areaid:id,
              filterarea:name,
              filterline:'Select',
              customerid:'',
              customername:'Select',
              customerlist:[],
              inventorylist:[],
              lineid:'',
              hascustomer:false,
            },()=>{
              let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
              clientdetail       = {...clientdetail, "subscription_areaid":self.state.areaid, "subscription_filterarea":self.state.filterarea};

              localStorage.setItem("agency_clientdetail",JSON.stringify(clientdetail));
            });
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-line-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              lineid:id,
              filterline:name,
              customerid:'',
              customername:'Select',
              customerlist:[],
              inventorylist:[],
              hascustomer:false,
            },()=>{
              let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
              clientdetail       = {...clientdetail, "subscription_lineid":self.state.lineid, "subscription_filterline":self.state.filterline};
              localStorage.setItem("agency_clientdetail",JSON.stringify(clientdetail));
            });

          },
        },
      });
    }
    
    customerAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteCustomerPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-customer-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectcustomer/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
            Mode:'GetAllSubscribeCustomer',
            type:'subscribecustomer',
            clientid:self.state.clientdetail.id,
            areaid:self.state.areaid,
            lineid:self.state.lineid,
            /*loginlinemanid:Number(self.state.clientdetail.linemanid),
            ismanager:Number(self.state.clientdetail.ismanager),
            areamanagerid:Number(self.state.clientdetail.areamanagerid),
            areaids:self.state.clientdetail.areaids,*/
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
              Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    customerlist:res.recordlist,
                  }
                },()=>{

                  const searchcustomerlist  = self.state.customerlist;

                  for (let i = 0; i < searchcustomerlist.length; i += 1) {
                    if (searchcustomerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchcustomerlist[i].phone.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchcustomerlist[i].customerid.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchcustomerlist[i]);
                  }
                  
                })
              }

              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-customer-popup').find('.item-title').text(value[0].name);
            $('#autocomplete-customer-popup').find('.item-content').removeClass('error');*/
            
            self.setState(()=>{
              return{
                customerid:value[0].id,
                customername:value[0].name,
              }
            },()=>{
              
              let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
              clientdetail       = {...clientdetail, "subscription_customerid":self.state.customerid, "subscription_customername":self.state.customername};
              localStorage.setItem("agency_clientdetail",JSON.stringify(clientdetail));

              self.getInventoryDetail();
            })

          },
        },
      });
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        if(name === 'customerid')
        {
          Promise.all([
            
            setTimeout(()=> {

              this.getInventoryDetail()
  
            }, 50)
  
          ])
          .then(() => {

            this.Dom7('.subscription').addClass('animate-bottom');

          })
          
        }
      });

    }
    
    getInventoryDetail(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
          Mode:'GetCustomerInventory',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          pincode:self.state.clientdetail.pincode,
          customerid:self.state.customerid,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        if(self.state.ispreselectedcustomer)
        {
          this.setState({
            customername:'Loading...'
          })
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({

                  inventorylist:resdata.inventorylist,
                  customername:resdata.customername,
                  areaid:resdata.areaid,
                  lineid:resdata.lineid,
                  filterarea:resdata.areaname,
                  filterline:resdata.linename,
                  isptr:false,

                },()=>{

                  let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
                  clientdetail       = {...clientdetail, "subscription_areaid":self.state.areaid, "subscription_filterarea":self.state.filterarea, "subscription_lineid":self.state.lineid, "subscription_filterline":self.state.filterline, "subscription_customerid":self.state.customerid, "subscription_customername":self.state.customername};

                  localStorage.setItem("agency_clientdetail",JSON.stringify(clientdetail));

                  this.Dom7('.subscription').addClass('animate-bottom');

                });
              }
              else
              {
                this.setState({

                  isptr:false

                });
              }

            }).catch(e => {
                console.log(e);
            });

        },1000);
    }
    
    handleInventoryInput(catid, inventoryid, type, value){

        let tempInventoryList       = [...this.state.inventorylist];
        const tempInventoryList2    = tempInventoryList.find(category => Number(category.id) === Number(catid));
        const tempInventory         = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(inventoryid));

        if(type === "assign")
        {
          tempInventory.isassigned  = !tempInventory.isassigned;
        }
        else if(type === "date")
        {
          tempInventory.subscriptiondate  = value;
        }
        else if(type === "closedate")
        {
          tempInventory.unsubscribedate  = value;
        }
        else if(type === "quantity")
        {
          tempInventory.quantity  = value;
        }
        this.setState(()=>{
          
          return{

            inventorylist: tempInventoryList

          }

        });
    }

    handleInventoryDaysInput(catid, inventoryid, dayid){

        let tempInventoryList       = [...this.state.inventorylist];
        const tempInventoryList2    = tempInventoryList.find(category => Number(category.id) === Number(catid));
        const tempInventory         = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(inventoryid));

        const tempInventoryDays     = tempInventory.days.find(day => Number(day.id) === Number(dayid));

        tempInventoryDays.checked   = !tempInventoryDays.checked;

        this.setState(()=>{
          
          return{

            inventorylist: tempInventoryList

          }

        });
    }
    
    saveDetail(){
      
      const self    = this;
      const app     = self.$f7;
      const router  = self.$f7router;

      let errmsg    = "";

      if(!this.$f7.online)
      {
        return;
      }

      if(this.state.customerid === "" && errmsg === "")
      {
        errmsg  = "Please select a customer";
      }

      if(!this.state.ispreselectedcustomer)
      {
        if(self.state.customerid === "")
        {
          this.$$('#autocomplete-customer-popup').find('.item-content').addClass('error');
        }
        else
        {
          this.$$('#autocomplete-customer-popup').find('.item-content').removeClass('error');
        }
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }
      
      const bodyFormData = {
          Mode:'UpdateSubscription',
          customerid:self.state.customerid,
          clientid:self.state.clientdetail.id,
          inventorylist:self.state.inventorylist
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/subscription.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request

          // Hide Preloader
          app.preloader.hide();
  
          const resdata = JSON.parse(res.data);

          const toastWithButton2 = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton2.open();
          if(resdata.success === true)
          {
            if(self.state.filtervars.accessarea === 'makepayment')
            {
              router.navigate(`/makepayment/${this.state.customerid}/`);
            }
            else
            {
              this.getInventoryDetail();
            }
          }
        }).catch(e => {
            console.log(e);
        });

      }, 200);

    }

    render(){
      
      const{customerid, inventorylist, filterarea, filterline, customername, ispreselectedcustomer} = this.state;

        return(
          <Page className="page" name="SMSCredit">
            <Navbar sliding={true}>
              <NavLeft>
                <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                <Link href={`/dashboard/`} className="clearmenupadding">
                  <img className="navbarlogo" src={scanlogo} alt="logo" />
                </Link>
              </NavLeft>
              <NavTitle>Subscription / Log</NavTitle>
              <NavRight className="right-with-menu">
              <Link onClick={()=>{
    
                this.getInventoryDetail();
    
              }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
              </NavRight>
            </Navbar>
            <Block>
                <BlockTitle medium className="zeroleftmargin">Customer</BlockTitle>
                <List noHairlinesMd className="zeromargin">
                {
                  !ispreselectedcustomer ? <ListItem link="#" id="autocomplete-area-popup" title="Area" after={filterarea}></ListItem>:null
                }
                {
                  !ispreselectedcustomer ? <ListItem link="#" id="autocomplete-line-popup" title="Line" after={filterline}></ListItem>:null
                }
                {
                  !ispreselectedcustomer ? <ListItem link="#" id="autocomplete-customer-popup" title="Customer" after={customername}></ListItem>:null
                }
                {
                  ispreselectedcustomer ? <ListItem title="Customer" after={customername}></ListItem>:null
                }
                </List>
                <PageContent style={{paddingTop:0}}>
                {
                  customerid ? (
                    <div className="subscription">
                    {
                      inventorylist.map((category)=>{
                        return(
                            <Fragment key={category.id}>
                                <BlockTitle medium className="zeroleftmargin">{category.title}</BlockTitle>
                                {
                                    category.recordlist.map((inventory)=>{
                                    return(
                                      <Fragment key={inventory.id}>
                                      <List className="zeromargin">
                                        <ListItem title={inventory.name} >
                                          <Toggle slot="after" name={`inventory_${inventory.id}`} checked={Number(inventory.isassigned) === 1} value="1" onChange={()=>{this.handleInventoryInput(category.id,inventory.id,'assign','')}}></Toggle>
                                        </ListItem>
                                      </List>
                                        {
                                          inventory.frequency && Number(inventory.isassigned) ? (
                                            <List noHairlinesMd className="zeromargin">
                                             <ListInput
                                            name={`quantity_${inventory.id}`}
                                            id={`quantity_${inventory.id}`}
                                            lable ="Quantity"
                                            type="number"
                                            className="smallqtynumber"
                                            floatingLabel
                                            onInput={(e)=>{this.handleInventoryInput(category.id,inventory.id,'quantity',e.target.value)}}
                                            placeholder="Quantity"
                                            value={inventory.quantity}
                                           clearButton required validate pattern="[0-9]*"
                                          >
                                          </ListInput>
                                          <ListInput name={`subscriptiondate_${inventory.id}`}
                                                id={`subscriptiondate_${inventory.id}`}
                                                label="Subscription Date"
                                                placeholder="Subscription Date"
                                                type="date"
                                                value={inventory.subscriptiondate}
                                                onChange={(e)=>{this.handleInventoryInput(category.id,inventory.id,'date',e.target.value)}}
                                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                                            ></ListInput></List>
                                          ):null
                                        }
                                        {
                                          !inventory.isassigned && Number(inventory.issubscribed) ? (
                                            <List noHairlinesMd className="zeromargin">
                                            <ListInput name={`closedate_${inventory.id}`}
                                                id={`closedate_${inventory.id}`}
                                                className="closedate"
                                                label="Close Date"
                                                placeholder="Close Date"
                                                type="date"
                                                value={inventory.unsubscribedate}
                                                onChange={(e)=>{this.handleInventoryInput(category.id,inventory.id,'closedate',e.target.value)}}
                                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}></ListInput></List>
                                          ):null
                                        }
                                        {(()=>{
                                          if(inventory.frequency === 1 && Number(inventory.isassigned) === 1)
                                          {
                                            return(<Fragment>
                                              <List noHairlinesMd className="zeromargin">
                                              <Row noGap>
                                              {
                                                inventory.days.map((day)=>{
                                                  return(
                                                    <Col key={day.id} width='25' className='inventorydays'>
                                                      <ListItem checkbox title={day.name} name={`inventoryday_${day.id}`} checked={Number(day.checked) === 1} onChange={()=>{this.handleInventoryDaysInput(category.id,inventory.id,day.id)}} style={{margin:0,padding:0,listStyle:'none'}}></ListItem>
                                                    </Col>
                                                  );
                                                })
                                              }
                                              <Col width='25'></Col>
                                              </Row>
                                              </List>
                                            </Fragment>);
                                          }
                                        })()}
                                        </Fragment>
                                        );
                                    })
                                }
                            </Fragment>
                            );
                        })
                    }
                    </div>
                  ):(<div className="errmsg">Please select a customer</div>)
                }
                <br /><br />
                </PageContent>
            </Block>
            {
              customerid ? (
                <Toolbar position="bottom">
                  <Row style={{width:'100%'}}>
                    <Col width="60"><Button fill color="orange" style={{marginRight:'.4rem'}}
                    onClick={()=>{
                      if(this.$f7.online)
                      {
                        this.$f7router.navigate(`viewlog/${customerid}/`);
                      }
                    }}
                    ><Icon f7="chart_pie_fill" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;View Log</Button>
                    </Col>
                    <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                    onClick={()=>{
                      if(this.$f7.online)
                      {
                        this.saveDetail();
                      }
                    }}
                    ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button>
                    </Col>
                  </Row>
                </Toolbar>
              ):null
            }
          </Page>
        );
    }
}