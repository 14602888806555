import React,{Component,Fragment} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Toggle,
    Toolbar,
    Link,
    Tabs,
    Tab,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Fab,
    Icon,
  } from 'framework7-react';

  export default class Updatedateprice extends Component {
    constructor(props) {
      super(props);

      let today  = new Date();
      
      let month   = (today.getMonth()+1);
      if(month < 10)
      {
        month  = "0"+(today.getMonth()+1);
      }

      let curdate   = today.getDate();
      if(curdate < 10)
      {
        curdate  = "0"+today.getDate();
      }

      let date = today.getFullYear()+'-'+month+'-'+curdate;
      /*let date = '1984-09-08';*/

      this.state = {
        clientdetail:[],
        customerid:'',
        customername:'Select',
        customerlist:[],
        inventorylist:[],
        isptr:false,
        subscriptiontype:1,
        subscriptiondate:'',
        pricedate:date,
        isinventoryloaded:false,
        effect:'blink',
      };

      this.handleUserInput            = this.handleUserInput.bind(this);
      this.handleInventoryDatePrice   = this.handleInventoryDatePrice.bind(this);
      this.saveInventoryPricing       = this.saveInventoryPricing.bind(this);
      this.applyFilter                = this.applyFilter.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        this.getActiveInventoryDetail();
        this.hideSplashScreen();

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        if(name === 'pricedate')
        {
          this.applyFilter();
        }

        /*if(name === 'pricedate')
        {
          Promise.all([
            
            setTimeout(()=> {

              this.getActiveInventoryDetail()
  
            }, 50)
  
          ])
          .then(() => {

            this.Dom7('.subscription').addClass('animate-bottom');

          })
          
        }
        else if(name === 'subscriptiontype')
        {
          this.Dom7('.subscription').addClass('animate-bottom');
        }*/

      });

    }

    getActiveInventoryDetail(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetSubscribeInventoryByDate',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          year:self.state.year,
          month:self.state.month,
          pricedate:self.state.pricedate,
      };

      // Simulate Ajax Request

      /*if(this.state.isptr !== true)
      {
        app.preloader.show();
      }*/

      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({

                    inventorylist:resdata.inventorylist,
                    totaldays:resdata.totaldays,
                    isptr:false,
                    ismonthdataloaded:true,
                    isinventoryloaded:true,

                });
              }
              else
              {
                this.setState({

                  isptr:false,
                  ismonthdataloaded:true,
                  isinventoryloaded:true,

              });
              }

          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    handleInventoryDatePrice(e){

      const value = e.target.value;

      const catid         = e.target.getAttribute('catid');
      const inventoryid   = e.target.getAttribute('inventoryid');
      const date          = e.target.getAttribute('date');
      const fieldname     = e.target.getAttribute('fieldname');

      let tempInventoryList       = [...this.state.inventorylist];
      const tempInventoryList2    = tempInventoryList.find(category => Number(category.id) === Number(catid));
      const tempInventoryList3    = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(inventoryid));
      const tempInventory         = tempInventoryList3.datepricing.find(pricing => Number(pricing.date) === Number(date));

      tempInventory[fieldname]  = value;

      this.setState(()=>{
        
        return{
          inventorylist: tempInventoryList
        }

      },()=>{
        /*console.log(this.state.inventorylist);*/
      });

    }

    applyFilter(){
        Promise.all([
            
          setTimeout(()=> {

            this.setState({isinventoryloaded:false})

          }, 50)

        ])
        .then(() => {

            this.getActiveInventoryDetail();
          
        })
    }

    saveInventoryPricing(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
          Mode:'SaveInventoryPricing',
          type:'savedateprice',
          clientid:self.state.clientdetail.id,
          cityid:self.state.clientdetail.cityid,
          stateid:self.state.clientdetail.stateid,
          month:self.state.month,
          year:self.state.year,
          inventorylist:self.state.inventorylist,
          pricedate:self.state.pricedate,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
 
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();

            /*if(resdata.success === true)
            {
              this.getInvoiceMonth();
            }*/
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
    }

    render(){
        
        const{inventorylist, pricedate, isinventoryloaded} = this.state;

      return(
            <Page pageContent={false} className="page" name="Updatedateprice">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Update Price By Date</NavTitle>
                  <NavRight className="right-with-menu">
                  <Link onClick={()=>{
        
                    this.applyFilter();
        
                  }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                <PageContent>
                <Block>
                    <Row>
                        <Col>
                            <List noHairlinesMd className="searchwrapper">
                                <ListInput
                                    name="pricedate"
                                    id="pricedate"
                                    label="Select Date"
                                    placeholder="Date"
                                    type="date"
                                    value={pricedate}
                                    onChange={this.handleUserInput}
                                    calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                                >
                                </ListInput>
                            </List>
                        </Col>
                        {/*<Col width='30'>
                            <Button fill color="blue" onClick={()=>{
                            this.applyFilter();}} className="filterbutton">Update</Button>
                        </Col>*/}
                    </Row>
                    {
                        isinventoryloaded ? (
                            <div>
                            {
                                inventorylist.map((category)=>{
                                return(
                                    <Fragment key={category.id}>
                                        <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px'}}>{category.title}</BlockTitle>
                                        <List className="zeromargin">
                                        {
                                            category.recordlist.map((inventory)=>{
                                            return(
                                                <ListItem title={inventory.name} key={inventory.id}>
                                                {
                                                  inventory.datepricing.map((pricing)=>{
                                                    return(<div key={pricing.date} slot="after">
                                                    {
                                                      pricing.hasholiday ? (
                                                        <div style={{width:'100px',color:'#ff0000'}}>Holiday</div>
                                                      ):(
                                                      <input
                                                        key={pricing.date}
                                                        catid={category.id}
                                                        inventoryid={inventory.id}
                                                        date={pricing.date}
                                                        fieldname="dateprice"
                                                        name={`dateprice_${inventory.id}_${pricing.date}`}
                                                        label="Price"
                                                        type="number"
                                                        placeholder="Price"
                                                        value={pricing.dateprice}
                                                        onChange={this.handleInventoryDatePrice}
                                                        required
                                                        style={{width:'100px'}}
                                                      />
                                                      )
                                                    }</div>);
                                                  })
                                                }
                                                </ListItem>
                                                );
                                            })
                                        }
                                        </List>
                                    </Fragment>
                                    );
                                })
                            }
                            </div>
                        ):(<AppListItemLoading />)
                    }
                </Block>
                <br /><br />
                </PageContent>
                {
                    isinventoryloaded ? (
                      <AppSaveRecordButton saveDetail={this.saveInventoryPricing.bind(this)} />
                    ):null
                }
            </Page>
      );
    }
}