import React,{Component, Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Link,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    BlockTitle,
    List,
    Row,
    Col,
    ListItem,
    Block,
    Fab,
    Icon,
  } from 'framework7-react';

  export default class InventoryDetail extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        detail:[],
        clientdetail:[],
        deleterecordid:'',
        recordid:'',
        isdetailfetched:false,
        hasdetail:false,
        effect:'blink',
      };

      this.navigateToLink         = this.navigateToLink.bind(this);
      this.getDetail              = this.getDetail.bind(this);
      this.getInventoryDetailPDF  = this.getInventoryDetailPDF.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {
          this.getDetail();
        })

        this.hideSplashScreen();
      });

    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }

        this.setState({
          isdetailfetched:false
        });

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }
  
        const bodyFormData = {
            Mode:'InventoryDetail',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.inventoryid,
            areaid:self.props.f7route.params.areaid,
            lineid:self.props.f7route.params.lineid,
            hawkerid:self.props.f7route.params.hawkerid,
        };

        app.preloader.show();

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState(()=>{
                return{

                  detail:resdata.recordset,
                  isptr:false,
                  isdetailfetched:true,
                  hasdetail:true,
  
                }
              },()=>{
                this.Dom7('.subscription').addClass('animate-bottom');
              });
            }
            else
            {
              this.setState(()=>{
                return{

                  isdetailfetched:true,
                  hasdetail:false,
  
                }
              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    navigateToLink(link){

      if(this.$f7.online)
        {
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/
        }
    }

    getInventoryDetailPDF(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetCustomerListByNewspaperPDF',
        clientid:self.state.clientdetail.id,
        stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        inventoryid:self.props.f7route.params.inventoryid,
        areaid:self.props.f7route.params.areaid,
        lineid:self.props.f7route.params.lineid,
        hawkerid:self.props.f7route.params.hawkerid,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                  window.open(resdata.pdffilepath);
              }
              else
              {
                  const toastWithButton = app.toast.create({
                    text: resdata.msg,
                    closeButton: true,
                    closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
            console.log(e);
          });

      },500);
    }

    render(){

       const{detail, isdetailfetched, effect, hasdetail} = this.state;

      return(
            <Page className="page" name="InventoryDetail">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Stock Detail</NavTitle>
                  <NavRight className="right-with-menu">
                  <Link onClick={()=>{

                    Promise.all([
                        
                      setTimeout(()=> {

                        this.Dom7('.waiting').addClass('animate-bottom');

                      }, 50)

                    ])
                    .then(() => {
                      this.getDetail();
                    })

                  }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                {
                    isdetailfetched ? (
                        <div className="subscription">
                        {
                            detail.subscriptionlist ? (<Block>
                            <BlockTitle medium style={{textAlign:'center'}}>{detail.inventoryname}</BlockTitle>
                            <BlockTitle medium style={{textAlign:'center',fontSize:'1rem',marginTop:'5px'}}>Total Records : {detail.totalcustomer}</BlockTitle>
                            <Row noGap className='rowwithbottomborder'>
                                <Col width='10' className="tableheader textalignleft">
                                    S.No.
                                </Col>
				<Col width='15' className="tableheader textalignleft">
                                    Area
                                </Col>
				<Col width='15' className="tableheader textalignleft">
                                    Line
                                </Col>
                                <Col width='20' className="tableheader textalignleft">
                                    Customer
                                </Col>
                                <Col width='30' className="tableheader textalignleft">
                                    Address
                                </Col>
                                <Col width='10' className="tableheader">
                                    Date
                                </Col>
                            </Row>
                            {
                                detail.subscriptionlist.map((inventory)=>{

                                return(
                                    <Row key={inventory.customerid} noGap className='rowwithbottomborder'>
                                        <Col width='10' className="ledgercolumn textalignleft">
                                        {inventory.serialno}
                                        </Col>
					<Col width='15' className="ledgercolumn textalignleft">
                                        {inventory.area}
                                        </Col>
					<Col width='15' className="ledgercolumn textalignleft">
                                        {inventory.line}
                                        </Col>
                                        <Col width='20' className="ledgercolumn textalignleft">
                                        {inventory.name}
                                        </Col>
                                        <Col width='30' className="ledgercolumn textalignleft">
                                        {inventory.address}
                                        </Col>
                                        <Col width='10' className="ledgercolumn">
                                        {inventory.date}
                                        </Col>
                                    </Row>
                                    );
                                })
                            }
                            </Block>):null
                        }
                        </div>
                    ):(<AppListItemLoading />)
                }
              <br />
            {
              hasdetail ? (
                  <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                      if(this.$f7.online)
                      {
                        this.getInventoryDetailPDF();
                      }
                    }}>
                    <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                    <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                  </Fab>
              ):null
            }
            </Page>
      );
    }
}