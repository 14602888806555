import React,{Component} from 'react';
const CustomerOutstandingContext = React.createContext();
//Provider
//Consumer

class CustomerOutstandingProvider extends Component{

	constructor(props){
    super(props);

    let today = new Date();
    let currmonth = today.getMonth()+1;
    if(currmonth < 10)
    {
      currmonth = "0"+currmonth;
    }
    let date = today.getFullYear()+'-'+currmonth+'-'+today.getDate();

		this.state = {
      clientdetail:[],
      isfetched:false,
      recordset:[],
      isptr:false,
      /*circulationdate:date,*/
      circulationdate:'',
      ismount:false,
      hasallcustomer:true,
      areaid:'',
      lineid:'',
      linemanid:'',
      hawkerid:'',
      hasfilter:true,
      filterarea:'',
      filterline:'',
      filterlineman:'',
      filterhawker:'',
      removefiltername:'',
      applyremovefilter:false,
      isdatafetched:false,
      effect:'blink',
      hasdataavailable:false,
      recordid:'',
      toolbarPosition:'bottom',
      page:1,
      perpage:'',
      totalpages:'',
      showpages:false,
      paginglist:[],
      totalrecord:'',
      totaloutstandingbalance:'',
      serialindex:1,
      latepaymentdays:'90',
      outstandingamountabove:'400'
		};

    this.handleUserInput          = this.handleUserInput.bind(this);
    this.getOutstandingReport     = this.getOutstandingReport.bind(this);
    this.openFilterPopup          = this.openFilterPopup.bind(this);
    this.setNewFilterData         = this.setNewFilterData.bind(this);
    this.navigateToLink           = this.navigateToLink.bind(this);
    this.deleteFilterAction       = this.deleteFilterAction.bind(this);
    this.Paging                   = this.Paging.bind(this);
    this.init                     = this.init.bind(this);
    this.getMessagePreviewToSend  = this.getMessagePreviewToSend.bind(this);
    this.resendInvoiceMessage     = this.resendInvoiceMessage.bind(this);
    this.manageOutstandingAction          = this.manageOutstandingAction.bind(this);

    this.handleUserInputLatePayment       = this.handleUserInputLatePayment.bind(this);
    this.initLatePaymentReport            = this.initLatePaymentReport.bind(this);
    this.getOutstandingLatePaymentReport   = this.getOutstandingLatePaymentReport.bind(this);
    this.PagingLatePaymentReport           = this.PagingLatePaymentReport.bind(this);
    this.manageCustomerLateAction          = this.manageCustomerLateAction.bind(this);
    this.getLatepaymentReportPDF           = this.getLatepaymentReportPDF.bind(this);
	}

  componentDidMount(){

    let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

    this.setState(()=>{
      return{
        clientdetail:clientdetail,
        customerid:''
      }
    },()=>{

      setTimeout(()=> {

        this.setState({ismount:true});

      }, 1200);

      this.hideSplashScreen();
    });

  }

  hideSplashScreen(){
    document.getElementById("splash-screen").style.visibility = "hidden";
  }
  
  handleUserInput(e){

    const name  = e.target.name;
    const value = e.target.value;

    this.setState(()=>{
      return{
        [name]: value
      }
    },()=>{

      if(name !== "outstandingamountabove" && name !== "latepaymentdays")
      {
        this.Paging('select');
      }

    });

  }

  handleUserInputLatePayment(e){

    const name  = e.target.name;
    const value = e.target.value;

    this.setState(()=>{
      return{
        [name]: value
      }
    },()=>{

      this.Paging('select');

    });

  }

  init(filtervars){

    let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker} = filtervars;

    if(Number(areaid) < 1 || areaid === "NaN")
    {
      areaid      = "";
      filterarea  = "";
    }

    if(Number(lineid) < 1 || lineid === "NaN")
    {
      lineid      = "";
      filterline  = "";
    }
    
    if(Number(hawkerid) < 1 || hawkerid === "NaN")
    {
      hawkerid      = "";
      filterhawker  = "";
    }

    this.setState(()=>{
      return{
        circulationdate:'',
        stateid:'',
        cityid:'',
        lineid:lineid,
        linemanid:'',
        hawkerid:hawkerid,
        areaid:areaid,
        filterarea:filterarea,
        filterline:filterline,
        filterlineman:'',
        filterhawker:filterhawker,
        hasfilter:true,
        hasallcustomer:false,
        page:1,
      }
    },()=>{
      if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
      {
        this.setState({hasfilter:true,hasallcustomer:false});
      }
      else
      {
        this.setState({hasfilter:true,hasallcustomer:true});
      }
      this.getOutstandingReport();
    })
  }

  getOutstandingReport(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }
  
      const bodyFormData = {
        Mode:'GetCustomerOutstanding',
        circulationdate:self.state.circulationdate,
        /*stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        linemanid:self.state.linemanid,*/
        lineid:self.state.lineid,
        areaid:self.state.areaid,
        hawkerid:self.state.hawkerid,
        page:this.state.page,
        perpage:this.state.perpage,
        serialindex:this.state.serialindex,
      };

      this.setState(()=>{

        return{
          isfetched:false
        }
        
      },()=>{

        setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                isfetched:true,
                recordset:resdata.recordset,
                hasdataavailable:true,
                perpage:Number(resdata.perpage),
                totalpages:Number(resdata.totalpages),
                paginglist:resdata.paginglist,
                showpages:resdata.showpages,
                totalrecord:resdata.totalrecord,
                totaloutstandingbalance:resdata.totaloutstandingbalance,
                serialindex:resdata.serialindex,
              },()=>{

                this.Dom7('.outstandingwrapper').addClass('animate-bottom');

              });

            }
            else
            {
              this.setState({

                isfetched:true,
                hasdataavailable:false,
                totalrecord:0,
                totaloutstandingbalance:"0",

              },()=>{

                this.Dom7('.outstandingwrapper').addClass('animate-bottom');

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      },500);

    });
  }

  getOutstandingReportPDF(){

    const self      = this;
    const app       = self.$f7;

    const bodyFormData = {
      Mode:'GetCustomerOutstandingPDF',
      circulationdate:self.state.circulationdate,
      lineid:self.state.lineid,
      hawkerid:self.state.hawkerid,
      areaid:self.state.areaid,
      /*stateid:self.state.clientdetail.stateid,
      cityid:self.state.clientdetail.cityid,
      linemanid:self.state.linemanid,*/
    };

    if(this.state.isptr !== true)
    {
      app.preloader.show();
    }

    // Simulate Ajax Request
    setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();                

            const resdata = JSON.parse(res.data);
            if(resdata.success === true)
            {
                window.open(resdata.pdffilepath);
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: resdata.msg,
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();    
            }
        }).catch(e => {
            console.log(e);
        });

    },500);
  }

  initLatePaymentReport(filtervars){

    let{areaid, filterarea} = filtervars;

    if(Number(areaid) < 1 || areaid === "NaN")
    {
      areaid      = "";
      filterarea  = "";
    }

    this.setState(()=>{
      return{
        areaid:areaid,
        filterarea:filterarea,
        hasfilter:true,
        hasallcustomer:false,
        page:1,
      }
    },()=>{
      if(Number(this.state.areaid) > 0)
      {
        this.setState({hasfilter:true,hasallcustomer:false});
      }
      else
      {
        this.setState({hasfilter:true,hasallcustomer:true});
      }
      this.getOutstandingLatePaymentReport();
    })
  }

  getOutstandingLatePaymentReport(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }
  
      const bodyFormData = {
        Mode:'GetLatePayment',
        areaid:self.state.areaid,
        page:this.state.page,
        perpage:this.state.perpage,
        serialindex:this.state.serialindex,
        latepaymentdays:this.state.latepaymentdays,
        outstandingamountabove:this.state.outstandingamountabove,
      };

      this.setState(()=>{

        return{
          isfetched:false
        }
        
      },()=>{

        setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                isfetched:true,
                recordset:resdata.recordset,
                hasdataavailable:true,
                perpage:Number(resdata.perpage),
                totalpages:Number(resdata.totalpages),
                paginglist:resdata.paginglist,
                showpages:resdata.showpages,
                totalrecord:resdata.totalrecord,
                totaloutstandingbalance:resdata.totaloutstandingbalance,
                serialindex:resdata.serialindex,
              },()=>{

                this.Dom7('.outstandingwrapper').addClass('animate-bottom');

              });

            }
            else
            {
              this.setState({

                isfetched:true,
                hasdataavailable:false,
                totalrecord:0,
                totaloutstandingbalance:"0",

              },()=>{

                this.Dom7('.outstandingwrapper').addClass('animate-bottom');

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      },500);

    });
  }

  setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, circulationdate){

    if(!this.$f7.online)
    {
      return;
    }

    Promise.all([
          
      setTimeout(()=> {

        this.Dom7('.waiting').addClass('animate-bottom');

      }, 50)

    ])
    .then(() => {

      this.setState(()=>{
      
        return{
          areaid:areaid,
          lineid:lineid,
          linemanid:linemanid,
          hawkerid:hawkerid,
          filterarea:filterarea,
          filterline:filterline,
          filterlineman:filterlineman,
          filterhawker:filterhawker,
          circulationdate:circulationdate,
          loaded:false,
          applyfilter:false,
          page:1,
        }

      },()=>{

        if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
        {
          this.setState({hasfilter:true,hasallcustomer:false});
        }
        else
        {
          this.setState({hasfilter:true,hasallcustomer:true});
        }

        this.getOutstandingReport();

      });

    })
    
  }

  openFilterPopup(){
      
    if(!this.$f7.online)
    {
      return;
    }

    this.$f7.views.main.router.navigate('filterpopup/');

  }

  deleteFilter(){

    this.setState({
      applyremovefilter:true
    })
  }

  navigateToLink(link){

    if(this.$f7.online)
    {
      this.$f7.views.main.router.navigate(`/${link}/`);
    }

  }

  Paging(mode){

    let pageerror = "";

    /*if(!this.$f7.online)
    {
      return;
    }*/

    let temppage    = '';

    const currpage  = this.state.page;

    if(mode === 'previous')
    {
      temppage  = Number(this.state.page) - 1;
    }
    if(mode === 'next')
    {
      temppage  = Number(this.state.page) + 1;
    }
    if(mode === 'select')
    {
      temppage  = Number(this.state.page);
    }

    if(temppage < 1)
    {
      pageerror = "You are on first page";
    }

    if(temppage > Number(this.state.totalpages))
    {
      pageerror = "You are on last page";
    }

    if(pageerror !== "")
    {
      const toastWithButton = this.$f7.toast.create({
          text: pageerror,
          closeButton: true,
          closeTimeout: 3000,
      });
          
      toastWithButton.open();
      
      return;
    }

    if(temppage <= 0)
    {
      temppage  = 1;
    }

    if(temppage >= Number(this.state.totalpages))
    {
      temppage  = Number(this.state.totalpages);
    }

    this.setState(()=>{

      return{
        page:temppage,
        loaded:false
      }

    },()=>{

      Promise.all([
          
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        if(mode === 'select')
        {
          this.getOutstandingReport();
        }
        else
        {
          if(Number(this.state.page) !== Number(currpage))
          {
            this.getOutstandingReport();
          }
        }

      })

    })

  }

  PagingLatePaymentReport(mode){

    let pageerror = "";

    /*if(!this.$f7.online)
    {
      return;
    }*/

    let temppage    = '';

    const currpage  = this.state.page;

    if(mode === 'previous')
    {
      temppage  = Number(this.state.page) - 1;
    }
    if(mode === 'next')
    {
      temppage  = Number(this.state.page) + 1;
    }
    if(mode === 'select')
    {
      temppage  = Number(this.state.page);
    }

    if(temppage < 1)
    {
      pageerror = "You are on first page";
    }

    if(temppage > Number(this.state.totalpages))
    {
      pageerror = "You are on last page";
    }

    if(pageerror !== "")
    {
      const toastWithButton = this.$f7.toast.create({
          text: pageerror,
          closeButton: true,
          closeTimeout: 3000,
      });
          
      toastWithButton.open();
      
      return;
    }

    if(temppage <= 0)
    {
      temppage  = 1;
    }

    if(temppage >= Number(this.state.totalpages))
    {
      temppage  = Number(this.state.totalpages);
    }

    this.setState(()=>{

      return{
        page:temppage,
        loaded:false
      }

    },()=>{

      Promise.all([
          
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        if(mode === 'select')
        {
          this.getOutstandingLatePaymentReport();
        }
        else
        {
          if(Number(this.state.page) !== Number(currpage))
          {
            this.getOutstandingLatePaymentReport();
          }
        }

      })

    })

  }

  deleteFilterAction(filtername){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              if(filtername === "area")
              {
                this.setState(()=>{
                  return{
                    areaid:'',
                    filterarea:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
                  
                  this.getOutstandingReport();

                })
              }
              else if(filtername === "line")
              {
                this.setState(()=>{
                  return{
                    lineid:'',
                    filterline:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getOutstandingReport();

                })
              }
              else if(filtername === "lineman")
              {
                this.setState(()=>{
                  return{
                    linemanid:'',
                    filterlineman:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getOutstandingReport();

                })
              }
              else if(filtername === "hawker")
              {
                this.setState(()=>{
                  return{
                    hawkerid:'',
                    filterhawker:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getOutstandingReport();

                })
              }
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

  getMessagePreviewToSend(customerid){

    const self      = this;
    const app       = self.$f7;

    if(!this.$f7.online)
    {
      return;
    }

    const bodyFormData = {
      Mode:'GetInvoiceMessagePreview',
      customerid:customerid,
      clientid:this.state.clientdetail.id,
    };

    app.preloader.show();

    // Simulate Ajax Request
    setTimeout(()=> {

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        const resdata = JSON.parse(res.data);

        if(resdata.success)
        {          
          app.dialog.create({
            title: 'Message Preview',
            text: resdata.preview,
            closeByBackdropClick: 'true',
            buttons: [
              {
                text: 'Cancel',
              },
              {
                text: 'Send',
                onClick: () => {
                  this.resendInvoiceMessage(customerid, resdata.smscredits);
                },
              },
            ],
          }).open();
        }
        else
        {
          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();
        }

      }).catch(e => {
          console.log(e);
      });

    }, 500);

  }

  resendInvoiceMessage(customerid, smscredits){

    const self      = this;
    const app       = self.$f7;

    if(!this.$f7.online)
    {
      return;
    }

    const bodyFormData = {
      Mode:'ResendInvoiceMessage',
      customerid:customerid,
      smscredits:smscredits,
    };

    app.preloader.show();

    // Simulate Ajax Request
    setTimeout(()=> {

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        const resdata = JSON.parse(res.data);

        const toastWithButton = app.toast.create({
          text: resdata.msg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();

      }).catch(e => {
          console.log(e);
      });

    }, 500);

  }

  manageOutstandingAction(recordid, phone, hasinvoiceid, invoiceid){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    const clientdetail  = localStorage.getItem('agency_clientdetail') !== undefined && localStorage.getItem('agency_clientdetail') !== null ? JSON.parse(localStorage.getItem('agency_clientdetail')):{};

    const{areaid, filterarea, lineid, filterline, hawkerid, filterhawker} = this.state;

    const appButton     = [{
      text: 'Select an action to proceed',
      label: true,
    }];
    
    appButton.push({
      text: 'Make Payment',
      bold: true,
      color:'blue',
      onClick:()=>{

        if(this.$f7.online)
        {
          /*this.$f7.views.main.router.navigate(`/makepayment/${recordid}/`);*/
          this.$f7.views.main.router.navigate(`/makepayment/${recordid}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&fromarea=customersoutstanding`);
        }
      }
    });

    {
      !clientdetail.ismanager && !clientdetail.ishawker && !clientdetail.islineman && phone !== "" ? (
        appButton.push({
          text: 'Resend Bill SMS',
          bold: true,
          color:'blue',
          onClick:()=>{
  
            if(this.$f7.online)
            {
              this.getMessagePreviewToSend(recordid);
            }
          }
        })
      ):null
    }

    appButton.push({
      text: 'View Ledger',
      bold: true,
      color:'blue',
      onClick:()=>{

        if(this.$f7.online)
        {
          this.$f7.views.main.router.navigate(`/ledger/${recordid}/`);
        }
      }
    });

    {
      hasinvoiceid && Number(invoiceid) > 0 ? (
        appButton.push({
          text: 'View Last Invoice',
          bold: true,
          color:'blue',
          onClick:()=>{
    
            if(this.$f7.online)
            {
              this.$f7.views.main.router.navigate(`/invoicedetail/${invoiceid}/`);
            }
          }
        })
      ):null
    }

    app.actions.create({
      buttons: [
      // First group
      appButton,
      // Second group
      [
        {
          text: 'Cancel',
          color: 'red'
        }
      ]]
    }).open();
  }

  manageCustomerLateAction(recordid, phone){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    const {areaid, filterarea, lineid, filterline, hawkerid, filterhawker, openingbalanceid, filteropeningbalance, page} = this.state;

    const clientdetail  = localStorage.getItem('agency_clientdetail') !== undefined && localStorage.getItem('agency_clientdetail') !== null ? JSON.parse(localStorage.getItem('agency_clientdetail')):{};

    const appButton     = [{
        text: 'Select an action to proceed',
        label: true,
    }];
    

    appButton.push({
        text: 'Edit',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              this.$f7.views.main.router.navigate(`/editcustomer/${recordid}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&page=${page}`);
            }
        }
    });

    {
        clientdetail.islineman || clientdetail.ismanager ? null:(
            appButton.push({
              text: 'Invoices',
              bold: true,
              color:'blue',
              onClick:()=>{
      
                if(this.$f7.online)
                {
                  this.$f7.views.main.router.navigate(`/customerinvoices/${recordid}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&page=${page}&fromarea=managecustomer`);
                }
              }
            })
        )
    }

    appButton.push({
        text: 'Ledger',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              this.$f7.views.main.router.navigate(`/ledger/${recordid}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&page=${page}&fromarea=managecustomer`);
            }
        }
    },
    {
        text: 'Make Payment',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              this.$f7.views.main.router.navigate(`/makepayment/${recordid}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&page=${page}&fromarea=managecustomer`);
            }
        }
    },
    {
        text: 'Payment',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              this.$f7.views.main.router.navigate(`/payments/summary/${recordid}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&page=${page}&fromarea=managecustomer`);
            }
        }
    },
    {
        text: 'Subscription',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              this.$f7.views.main.router.navigate(`/subscription/${recordid}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&page=${page}&fromarea=managecustomer`);
            }
        }
    },
    {
        text: 'Add Holiday',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              this.$f7.views.main.router.navigate(`/addholiday/?customerid=${recordid}&areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&page=${page}&fromarea=managecustomer`);
            }
        }
    });

    {
      !clientdetail.ismanager && !clientdetail.ishawker && !clientdetail.islineman && phone !== "" ? (
        appButton.push({
          text: 'Resend Bill SMS',
          bold: true,
          color:'blue',
          onClick:()=>{
  
            if(this.$f7.online)
            {
              this.getMessagePreviewToSend(recordid);
            }
          }
        })
      ):null
    }

    app.actions.create({
      buttons: [
      // First group
      appButton,
      // Second group
      [
        {
          text: 'Cancel',
          color: 'red'
        }
      ]]
    }).open();
  }

  

  getLatepaymentReportPDF(){

    const self      = this;
    const app       = self.$f7;

    const bodyFormData = {
      Mode:'GetLatepaymentReportPDF',
      areaid:self.state.areaid,
      page:this.state.page,
      perpage:this.state.perpage,
      serialindex:this.state.serialindex,
      latepaymentdays:this.state.latepaymentdays,
      outstandingamountabove:this.state.outstandingamountabove,
    };

    if(this.state.isptr !== true)
    {
      app.preloader.show();
    }

    // Simulate Ajax Request
    setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();                

            const resdata = JSON.parse(res.data);
            if(resdata.success === true)
            {
                window.open(resdata.pdffilepath);
            }
            else
            {
                const toastWithButton = app.toast.create({
                  text: resdata.msg,
                  closeButton: true,
                  closeTimeout: 3000,
                });
                  
                toastWithButton.open();    
            }
        }).catch(e => {
            console.log(e);
        });

    },500);
  }

	render(){
		return (
			<CustomerOutstandingContext.Provider value={{
			  ...this.state,
				handleUserInput:this.handleUserInput,
				getOutstandingReport:this.getOutstandingReport,
				getOutstandingReportPDF:this.getOutstandingReportPDF,
				setNewFilterData:this.setNewFilterData,
				openFilterPopup:this.openFilterPopup,
				deleteFilter:this.deleteFilter,
				navigateToLink:this.navigateToLink,
				Paging:this.Paging,
				deleteFilterAction:this.deleteFilterAction,
				init:this.init,
				manageOutstandingAction:this.manageOutstandingAction,
				handleUserInputLatePayment:this.handleUserInputLatePayment,
				initLatePaymentReport:this.initLatePaymentReport,
				getOutstandingLatePaymentReport:this.getOutstandingLatePaymentReport,
				PagingLatePaymentReport:this.PagingLatePaymentReport,
				manageCustomerLateAction:this.manageCustomerLateAction,
				getLatepaymentReportPDF:this.getLatepaymentReportPDF,
			}}
			>
			{this.props.children}
			</CustomerOutstandingContext.Provider>
		);
	}
}

const CustomerOutstandingConsumer = CustomerOutstandingContext.Consumer;

export {CustomerOutstandingProvider, CustomerOutstandingConsumer, CustomerOutstandingContext};