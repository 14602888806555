import React,{Component} from 'react';
import AppCardItemLoading from '../pages/appcarditemloading';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    List,
    ListItem,
    Link,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    BlockTitle
  } from 'framework7-react';

  export default class CustomerChanges extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        hasnewsubscription:false,
        newsubscriptionlist:[],
        hasclosesubscription:false,
        closesubscriptionlist:[],
        totalsubscriptioncount:"0",
        hasholidaystartlist:false,
        holidaystartlist:[],
        hasholidayendlist:false,
        holidayendlist:[],
        loaded:false,
        effect:'blink',
      };

      this.navigateToLink     = this.navigateToLink.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.setState(()=>{
              return{
                loaded:false
              }
            },()=>{

              this.Dom7('.waiting').addClass('animate-bottom');

            })

          }, 50)

        ])
        .then(() => {

            this.getCustomerChanges();
          
        })
        
        this.hideSplashScreen();

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    getCustomerChanges(){

      if(!this.$f7.online)
      {
        return;
      }

      this.setState({
        loaded:false
      });

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetCustomerSubscriptionChanges',
        clientid:Number(this.state.clientdetail.id),
      };

      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          app.preloader.hide();
          
          app.ptr.done();

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              hasnewsubscription:resdata.recordset.hasnewsubscription,
              newsubscriptionlist:resdata.recordset.newsubscriptionlist,
              hasclosesubscription:resdata.recordset.hasclosesubscription,
              closesubscriptionlist:resdata.recordset.closesubscriptionlist,
              totalsubscriptioncount:resdata.recordset.totalsubscriptioncount,
              hasholidaystartlist:resdata.recordset.hasholidaystartlist,
              holidaystartlist:resdata.recordset.holidaystartlist,
              hasholidayendlist:resdata.recordset.hasholidayendlist,
              holidayendlist:resdata.recordset.holidayendlist,
              loaded:true,
            });
          }
          else{
            this.setState({
              hastodaystaff:false,
              todaystafflist:[],
              hastomarrowstaff:false,
              tomarrowstafflist:[],
              hasholidaystartlist:false,
              holidaystartlist:[],
              hasholidayendlist:false,
              holidayendlist:[],
              totalsubscriptioncount:"0",
              loaded:true,
            });
          }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
      }

    }

    render(){

      const{hasnewsubscription, newsubscriptionlist, hasclosesubscription, closesubscriptionlist, hasholidaystartlist, holidaystartlist, hasholidayendlist, holidayendlist, loaded} = this.state;

      return(
        <FilterConsumer>{(value)=>{

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Customer Changes</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="CustomerChanges">
                  {
                    loaded ? (
                        <div>
                            <BlockTitle medium>New Subscription</BlockTitle>
                            {
                                hasnewsubscription ? (
                                    <div>
                                    {
                                        newsubscriptionlist.map((detail, i)=>{
                                        return(
                                            <Card outline key={i} className="recordset">
                                              <CardHeader
                                              className="date">{detail.name} (#{detail.customerid})</CardHeader>
                                              <CardContent>
                                                <p><b>Customer ID</b> : {detail.customerid}</p>
                                                <p><b>Name</b> : {detail.name}</p>
                                                <p><b>Phone</b> : {detail.phone}</p>
                                                <p><b>Area</b> : {detail.area}</p>
                                                <p><b>Line</b> : {detail.line}</p>
                                                <p><b>Hawker</b> : {detail.hawker}</p>
                                                <p><b>Address</b> : {detail.fulladdress}</p>
                                                <p><b>Subscription</b> : {detail.subscriptions}</p>
                                              </CardContent>
                                              {
                                                detail.caneditcustomer ? <CardFooter>
                                                  <Link onClick={()=>{this.navigateToLink(`editcustomer/${detail.id}`)}} ignoreCache={true}>Edit</Link>
                                                  <div>&nbsp;</div>
                                                </CardFooter>:null
                                              }
                                            </Card>
                                            );
                                        })
                                    }
                                    </div>
                                ):(
                                  <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                                    <ListItem title="No new subscription found"></ListItem>
                                  </List>
                                )
                            }
                            <BlockTitle medium>Close Subscription</BlockTitle>
                            {
                                hasclosesubscription ? (
                                        <div>
                                        {
                                            closesubscriptionlist.map((detail, i)=>{
                                            return(
                                                <Card outline key={i} className="recordset">
                                                  <CardHeader
                                                  className="date">{detail.name} (#{detail.customerid})</CardHeader>
                                                  <CardContent>
                                                    <p><b>Customer ID</b> : {detail.customerid}</p>
                                                    <p><b>Name</b> : {detail.name}</p>
                                                    <p><b>Phone</b> : {detail.phone}</p>
                                                    <p><b>Area</b> : {detail.area}</p>
                                                    <p><b>Line</b> : {detail.line}</p>
                                                    <p><b>Hawker</b> : {detail.hawker}</p>
                                                    <p><b>Address</b> : {detail.fulladdress}</p>
                                                    <p><b>Subscription</b> : {detail.closesubscriptions}</p>
                                                  </CardContent>
                                                  {
                                                    detail.caneditcustomer ? <CardFooter>
                                                      <Link onClick={()=>{this.navigateToLink(`editcustomer/${detail.id}`)}} ignoreCache={true}>Edit</Link>
                                                      <div>&nbsp;</div>
                                                    </CardFooter>:null
                                                  }
                                                </Card>
                                                );
                                            })
                                        }
                                        </div>
                                ):(
                                  <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                                    <ListItem title="No close subscription found"></ListItem>
                                  </List>
                                )
                            }
                            <BlockTitle medium>Holiday Starting</BlockTitle>
                            {
                              hasholidaystartlist ? (
                                  <div>
                                  {
                                    holidaystartlist.map((detail, i)=>{
                                      return(
                                        <Card outline key={i} className="recordset">
                                          <CardHeader
                                          className="holiday">{detail.name} (#{detail.customerid})</CardHeader>
                                          <CardContent>
                                            <p><b>Customer ID</b> : {detail.customerid}</p>
                                            <p><b>Name</b> : {detail.name}</p>
                                            <p><b>Phone</b> : {detail.phone}</p>
                                            <p><b>Area</b> : {detail.area}</p>
                                            <p><b>Line</b> : {detail.line}</p>
                                            <p><b>Hawker</b> : {detail.hawker}</p>
                                            <p><b>Address</b> : {detail.fulladdress}</p>
                                            <p><b>Subscription</b> : {detail.subscriptions}</p>
                                            <p><b>Holiday</b> : {detail.holidaydate}</p>
                                          </CardContent>
                                          {
                                            detail.caneditcustomer ? <CardFooter>
                                              <Link onClick={()=>{this.navigateToLink(`editcustomer/${detail.id}`)}} ignoreCache={true}>Edit</Link>
                                              <div>&nbsp;</div>
                                            </CardFooter>:null
                                          }
                                        </Card>
                                      );
                                    })
                                  }
                                  </div>
                              ):(
                                <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                                  <ListItem title="No holiday starting found"></ListItem>
                                </List>
                              )
                            }
                            <BlockTitle medium>Holiday Ending</BlockTitle>
                            {
                                hasholidayendlist ? (
                                    <div>
                                    {
                                      holidayendlist.map((detail, i)=>{
                                        return(
                                          <Card outline key={i} className="recordset">
                                            <CardHeader
                                            className="holiday">{detail.name} (#{detail.customerid})</CardHeader>
                                            <CardContent>
                                              <p><b>Customer ID</b> : {detail.customerid}</p>
                                              <p><b>Name</b> : {detail.name}</p>
                                              <p><b>Phone</b> : {detail.phone}</p>
                                              <p><b>Area</b> : {detail.area}</p>
                                              <p><b>Line</b> : {detail.line}</p>
                                              <p><b>Hawker</b> : {detail.hawker}</p>
                                              <p><b>Address</b> : {detail.fulladdress}</p>
                                              <p><b>Subscription</b> : {detail.subscriptions}</p>
                                              <p><b>Holiday</b> : {detail.holidaydate}</p>
                                            </CardContent>
                                            {
                                              detail.caneditcustomer ? <CardFooter>
                                                <Link onClick={()=>{this.navigateToLink(`editcustomer/${detail.id}`)}} ignoreCache={true}>Edit</Link>
                                                <div>&nbsp;</div>
                                              </CardFooter>:null
                                            }
                                          </Card>
                                        );
                                      })
                                    }
                                    </div>
                                ):(
                                  <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                                    <ListItem title="No holiday ending found"></ListItem>
                                  </List>
                                )
                            }
                        </div>
                    ):(
                      <AppCardItemLoading />
                    )
                  }
                  <br/><br/>
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}