import React,{Component} from 'react';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
    ListInput,
    Row,
    Col,
  } from 'framework7-react';

  export default class PurchaseSummaryFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }
      let startdate     = today.getFullYear()+'-'+currmonth+'-01';
      let enddate       = today.getFullYear()+'-'+currmonth+'-'+currday;
  
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        startyear:'',
        hassmartmonthyear:false,
        hassmartline:false,
        hassmartlineman:false,
        hassmarthawker:false,
        hassmartarea:false,
        droppingpointtype:0,
        droppingpointlist:[],
        droppingpointid:'',
        droppingpointname:'Select',
        isstock:0,
        inventorylist:[],
        inventoryid:'',
        inventoryname:'Select',
        filtervars:[],
      };

      this.applyFilter                      = this.applyFilter.bind(this);
      this.handleUserInput                  = this.handleUserInput.bind(this);
      this.droppingpointAutocompletePopup   = this.droppingpointAutocompletePopup.bind(this);
      this.stockAutocompletePopup           = this.stockAutocompletePopup.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{
        
        this.hideSplashScreen();

        let{startdate, enddate, droppingpointtype, droppingpointid, droppingpointname, isstock, inventoryid, inventoryname} = this.state.filtervars;
        
        this.setState({
          startdate:startdate,
          enddate:enddate,
          droppingpointtype:droppingpointtype,
          droppingpointid:droppingpointid,
          droppingpointname:droppingpointname,
          isstock:isstock,
          inventoryid:inventoryid,
          inventoryname:inventoryname,
        },()=>{
          this.$$('#autocomplete-droppingpoint-name2').find('.item-after').text(this.state.droppingpointname);
        })

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.droppingpointAutocomplete.destroy();
      self.autocompleteStockPopup.destroy();

    }

    droppingpointAutocompletePopup(){

        const self = this;
        const app = self.$f7;
    
        self.droppingpointAutocomplete = app.autocomplete.create({
  
          openIn: 'popup',
          valueProperty: 'name',
          textProperty: 'name',
          typeahead: true,
          openerEl: '#autocomplete-droppingpoint-popup a',
          closeOnSelect: true,
          routableModals:true,
          url:'selectdroppingpoint/',
          requestSourceOnOpen:true,
  
          source(query, render) {
            const results = [];
  
            app.preloader.show();

            const accesstoken  = localStorage.getItem('agency_accesstoken');
  
            app.request({
              
              method: 'POST',
              dataType: 'json',
              url: `${app.passedParams.serverurl}api/droppingpoint.php?t=${Date.now()}`,
              data: {
                Mode:'GetDroppingPoint',
                clientid:self.state.clientdetail.id,
              },
              headers: {
                Authorization: `${accesstoken}`
              },
              success(res) {
              // Find matched items
  
                if(res.success === true)
                {
                  self.setState(()=>{
                    return{
                     droppingpointlist:res.recordlist,
                    }
                  },()=>{
  
                    const searchlist  = self.state.droppingpointlist;
  
                    for (let i = 0; i < searchlist.length; i += 1) {
                      if (searchlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlist[i]);
                    }
                    
                  })
                }
  
                // Hide Preoloader
                app.preloader.hide();
                // Render items by passing array with result items
                render(results);
              },
            });
          },
          on: {
            change(value) {
  
              self.Dom7('#autocomplete-droppingpoint-name2').find('.item-after').text(value[0].name);
              
              self.setState({
                droppingpointid:value[0].id,
                droppingpointname:value[0].name,
              });
  
            },
          },
        });
    }

    stockAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteStockPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-stock-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectstock/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetAllInventory',
              clientid:self.state.clientdetail.id,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    inventorylist:res.inventorylist,
                  }
                },()=>{

                  const searchlinelist  = self.state.inventorylist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*self.Dom7('#autocomplete-stock-name2').text(value[0].name);
            $('.autocomplete-stock-popup').removeClass('error');*/
            
            self.setState({
              inventoryid:value[0].id,
              inventoryname:value[0].name
            });

          },
        },
      });
    }

    applyFilter(){

      if(!this.$f7.online)
      {
        return;
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{
        if(name === 'droppingpointtype')
        {
            if(value === "1")
            {
                this.droppingpointAutocompletePopup();
            }
            else
            {
                this.droppingpointAutocomplete.destroy();
            }
        }
        else if(name === 'isstock')
        {
            if(value === "1")
            {
                this.stockAutocompletePopup();
            }
            else
            {
                this.autocompleteStockPopup.destroy();
            }
        }
      });

    }

    render(){

      const{startdate, enddate, droppingpointtype, droppingpointid, droppingpointname, isstock, inventoryid, inventoryname} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{applyPurchaseSummaryFilter}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="PurchaseSummaryFilter">
                        <Block>
                        <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                            <ListInput
                                name="startdate"
                                id="startdate"
                                label="Start Date"
                                placeholder="Start Date"
                                type="date"
                                value={startdate}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                            <ListInput
                                name="enddate"
                                id="enddate"
                                label="End Date"
                                placeholder="End Date"
                                type="date"
                                value={enddate}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                        </List>
                        <Row noGap className="listtype">
                            <Col width="50">
                                <p style={{marginLeft:'10px',marginTop:'1.2em',marginBottom:'1.2em'}}><b>Dropping Point</b></p>
                            </Col>
                            <Col width="25">
                                <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="0" name="droppingpointtype" checked={Number(droppingpointtype) === 0} title="All"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                            <Col width="25">
                                <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="1" name="droppingpointtype" checked={Number(droppingpointtype) === 1} title="Single"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                        </Row>
                        {
                            Number(droppingpointtype) ? (
                            <List noHairlines style={{margin:0,padding:0,marginTop:'5px',listStyle:'none',marginBottom:'0px'}}>
                                <ListItem link="#" id="autocomplete-droppingpoint-popup" className="autocomplete-droppingpoint-popup" title="Choose Dropping Point">
                                    <div slot="after" id="autocomplete-droppingpoint-name2">Select</div>
                                </ListItem>
                            </List>):null
                        }
                        <Row noGap className="listtype">
                            <Col width="50">
                                <p style={{marginLeft:'10px',marginTop:'1.2em',marginBottom:'1.2em'}}><b>Stock</b></p>
                            </Col>
                            <Col width="25">
                                <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="0" name="isstock" checked={Number(isstock) === 0} title="All"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                            <Col width="25">
                                <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="1" name="isstock" checked={Number(isstock) === 1} title="Single"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                        </Row>
                        {
                            Number(isstock) ? (
                            <List noHairlines style={{margin:0,padding:0,marginTop:'5px',listStyle:'none',marginBottom:'0px'}}>
                                <ListItem link="#" id="autocomplete-stock-popup" className="autocomplete-stock-popup" title="Stock">
                                    <div slot="after" id="autocomplete-stock-name2">{inventoryname}</div>
                                </ListItem>
                            </List>):null
                        }
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                            let tempstartdate   = new Date(startdate);
                            let tempenddate     = new Date(enddate);

                            if(tempenddate.getTime() < tempstartdate.getTime())
                            {
                              const toastWithButton = this.$f7.toast.create({
                                text: "Start date can't be less then End date",
                                closeButton: true,
                                closeTimeout: 3000,
                              });
                      
                              toastWithButton.open();
                      
                              return false;
                            }

                            Promise.all([
                                setTimeout(()=> {

                                    applyPurchaseSummaryFilter(startdate, enddate, droppingpointtype, droppingpointid, droppingpointname, isstock, inventoryid, inventoryname);

                                }, 500)
                                
                            ])
                            .then(() => {
                
                                setTimeout(()=> {
                
                                this.$$('.popup-close').trigger('click');
                    
                                }, 500)
                                
                            })

                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}