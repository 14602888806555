import React,{Component} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    BlockTitle,
    Link,
    Block,
    Chip,
    Row,
    Col,
    Icon,
    Page,
    PageContent,
    Fab,
  } from 'framework7-react';

  export default class PaymentRegisterDetail extends Component {
    constructor(props) {
      super(props);

      this.state = {
        clientdetail:[],
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        totalrecord:'',
        paymentdate:'',
        paymentday:'',
        recordset:[],
        filtervars:{areaid:'', filterarea:'', lineid:'', filterline:'', hawkerid:'', filterhawker:'', paymentdate:'',paymenttype:''},
        hasdataavailable:false,
        summarytotal:{},
      };

      this.getDetail = this.getDetail.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{

        this.getDetail();

        this.hideSplashScreen();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }
    
    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
        
    getDetail(){
      const self      = this;
      const app       = self.$f7;

      let{areaid, lineid, hawkerid, paymentdate, paymenttype} = this.state.filtervars;

      const bodyFormData = {
        Mode:'GetPaymentRegisterDetailDataByArea',
        clientid:self.state.clientdetail.id,
        stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        areaid:areaid,
        lineid:lineid,
        hawkerid:hawkerid,
        paymentdate:paymentdate,
        paymenttype:paymenttype,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({

                    recordset:resdata.recordset,
                    totalrecord:resdata.totalrecord,
                    paymentdate:resdata.paymentdate,
                    paymentday:resdata.paymentday,
                    summarytotal:resdata.summarytotal,
                    isptr:false,
                    hasdataavailable:true,

                },()=>{

                    this.Dom7('.subscription').addClass('animate-bottom');
  
                  });
              }
              else
              {
                this.setState({
                  recordset:[],
                  totalrecord:'',
                  paymentdate:'',
                  paymentday:'',
                  isptr:false,
                  hasdataavailable:false

                });
              }

          }).catch(e => {
              console.log(e);
          });

      },1000);
    }

    getPaymentAreawisePDF(){

      const self      = this;
      const app       = self.$f7;

      let{areaid, lineid, hawkerid, paymentdate, paymenttype} = this.state.filtervars;

      const bodyFormData = {
        Mode:'GetPaymentRegisterDetailDataByAreaPDF',
        clientid:self.state.clientdetail.id,
        stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        areaid:areaid,
        lineid:lineid,
        hawkerid:hawkerid,
        paymentdate:paymentdate,
        paymenttype:paymenttype
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                  window.open(resdata.pdffilepath);
              }
              else
              {
                  const toastWithButton = app.toast.create({
                      text: resdata.msg,
                      closeButton: true,
                      closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){

        const{recordset, totalrecord, paymentdate, paymentday, hasdataavailable, summarytotal, filtervars} = this.state;

          return(
            <Popup className="demo-popup">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link popupClose>
                      <i className="icon icon-back"></i>                      
                    </Link>
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                  </NavLeft>
                  <NavTitle>Detail</NavTitle>
                  <NavRight className="right-with-menu">
                    <Link onClick={()=>{
            
                        this.getDetail();
            
                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                <Page className="page" name="SubscriptionLog">
                    {
                        hasdataavailable ? (
                            <Block>
                                <Block strong className="selectedfilter" style={{marginTop:0}}>
                                  {paymentdate ? (<Chip text={`Date : ${paymentdate} - (${paymentday})`} mediaBgColor="blue" media={`D`}/>):null}
                                  {filtervars.areaid !== "" && filtervars.filterarea !== "" ? (<Chip text={`Area : ${filtervars.filterarea}`} mediaBgColor="pink" media={filtervars.filterarea.toUpperCase().substring(0, 1)}/>):null}
                                  {filtervars.lineid !== "" && filtervars.filterline !== "" ? (<Chip text={`Line : ${filtervars.filterline}`} mediaBgColor="pink" media={filtervars.filterline.toUpperCase().substring(0, 1)} />):null}
                                  {Number(filtervars.hawkerid) > 0 && filtervars.filterhawker !== "" ? (<Chip text={`Hawker : ${filtervars.filterhawker}`} mediaBgColor="orange" media={filtervars.filterhawker.toUpperCase().substring(0, 1)} />):null}
                                  {filtervars.paymenttype ? (<Chip text={`Payment Type : ${filtervars.paymenttype.charAt(0).toUpperCase() + filtervars.paymenttype.slice(1)}`} mediaBgColor="green" media={filtervars.paymenttype.toUpperCase().substring(0, 1)}/>):null}
                                </Block>
                                {/*<BlockTitle medium className="zeroleftmargin" style={{fontSize:'18px'}}>Payment Date : {paymentdate}</BlockTitle>*/}
                                    <PageContent style={{paddingTop:0}}>
                                      {
                                        recordset ? (
                                          <div className="data-table">                                        
                                          <table>
                                            <thead>
                                              <tr>
                                                <th className="label-cell">S.No.</th>
                                                <th className="numeric-cell">Area</th>
                                                <th className="numeric-cell">Payment</th>
                                                <th className="numeric-cell">Coupon</th>
                                                <th className="numeric-cell">Discount</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                            {
                                              recordset.map((detail, loop)=>{
                                              return(
                                                  <tr key={loop}>
                                                    <td className="label-cell">{detail.serialno}</td>
                                                    <td className="numeric-cell" style={{paddingRight:'0',paddingLeft:'0'}}>{detail.name}</td>
                                                    <td className="numeric-cell">{detail.amount}</td>
                                                    <td className="numeric-cell">{detail.coupon}</td>
                                                    <td className="numeric-cell">{detail.discount}</td>
                                                  </tr>
                                                );
                                              })
                                            }
                                            <tr>
                                              <td className="label-cell">&nbsp;</td>
                                              <td className="numeric-cell"><b>Total</b></td>
                                              <td className="numeric-cell">{summarytotal.totalamount}</td>
                                              <td className="numeric-cell">{summarytotal.totalcoupon}</td>
                                              <td className="numeric-cell">{summarytotal.totaldiscount}</td>
                                            </tr>
                                          </tbody>
                                          </table>
                                        </div>  
                                        ):(<AppItemNotFound notfoundtext ="No detail available." />)
                                      }

                                    <br /><br />
                                    </PageContent>
                            </Block>
                        ):null
                    }
                    {
                      hasdataavailable ? (
                        <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                              if(this.$f7.online)
                              {
                                this.getPaymentAreawisePDF();
                              }
                            }}>
                            <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                            <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                          </Fab>
                      ):null
                    }
                </Page>
            </Popup>
          );

    }
}