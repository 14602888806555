import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import { FilterConsumer, FilterContext } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Popup,    
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    BlockTitle,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Toggle,
    Link,
    Toolbar,
  } from 'framework7-react';

  export default class QuickAddlineeman extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        phone:'',
        password:'',
        status:1,
        remarks:'',
        frmsuccess:false,
        resid:'',
        resname:'',
      };

      this.handleUserInput = this.handleUserInput.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      });

    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    saveLinemanDetail(){
      
      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const toastWithButton = app.toast.create({
        text: 'Please enter all required field.',
        closeButton: true,
        closeTimeout: 3000,
      });

      app.input.validateInputs('.adddatalineman');

      if(this.state.name === "")
      {
        toastWithButton.open();
        return false;
      }

      const bodyFormData = {
          Mode:'AddLineman',
          clientid:self.state.clientdetail.id,
          name:self.state.name,
          phone:self.state.phone,
          password:self.state.password,
          status:Number(self.state.status),
          remarks:self.state.remarks,
          frmsuccess:true,
          resid:resdata.recordid,
          resname:resdata.name,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/lineman.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();

            if(resdata.success === true)
            {
              
              Promise.all([
                this.setState(()=>{
                  return{
                        name:'',
                        phone:'',
                        status:1,
                        remarks:'',
                    }
                })
              ])
              .then(() => {

                this.Dom7('.item-content').removeClass('item-input-with-error-message');
                this.Dom7('.item-content').removeClass('item-input-invalid');
                /*this.props.fetchNewLineman(resdata.recordid, resdata.name);
                this.props.closePopup();*/
  
              });

            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

      const{resid, resname, frmsuccess} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{setLinemanData}  = value;

          if(frmsuccess === true)
          {
            Promise.all([
              setTimeout(()=> {

                setLinemanData(Number(resid), resname);
    
              }, 500)
              
            ])
            .then(() => {

              setTimeout(()=> {

                this.$$('.popup-close').trigger('click');
    
              }, 500)
              
            })
          }

            return(
                <Popup className="demo-popup">
                    <Navbar sliding={true}>
                      <NavLeft>
                        <Link popupClose>
                          <i className="icon icon-back"></i>                      
                        </Link>
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </NavLeft>
                      <NavTitle>Add New Lineman</NavTitle>
                    </Navbar>
                    <Page pageContent={false} className="page" name="QuickAddlineeman">
                        <PageContent>
                        <Block>
                        <List noHairlinesMd>
        
                            <ListInput
                                name="name"
                                className="adddatalineman"
                                label="Name"
                                floatingLabel
                                type="text"
                                onInput={this.handleUserInput}
                                placeholder="Name"
                                value={this.state.name}
                                clearButton required validate
                            >
                            </ListInput>
                        
                            <ListInput
                                name="phone"
                                className="adddatalineman"
                                label="Phone Number"
                                floatingLabel
                                type="tel"
                                onInput={(e)=>{
                        
                                  let tempphone = e.target.value;
                                  let mobile    = tempphone.replace(/ /g,'');
                                  
                                  if(tempphone.slice(0,1)=='+')
                                  {
                                    mobile = mobile.substring(3);
                                  }
          
                                  if(tempphone.slice(0,1)=='0')
                                  {
                                    mobile = mobile.substring(1);
                                  }
          
                                  if(mobile.length > 10)
                                  {
                                    return;
                                  }
                                  
                                  this.setState({
                                    phone:mobile
                                  })
                                }}
                                placeholder="Phone number"
                                value={this.state.phone}
                                clearButton validate
                            >
                            </ListInput>
                            <ListInput
                                name="password"
                                label="Password"
                                floatingLabel
                                type="password"
                                onInput={this.handleUserInput}
                                placeholder="Your password"
                                value={this.state.password}
                                clearButton validate
                            >
                            </ListInput>
                            <ListInput
                                name="remarks"
                                label="Remarks"
                                floatingLabel
                                type="textarea"
                                onInput={this.handleUserInput}
                                value={this.state.remarks}
                                resizable
                                placeholder="Remarks"
                            >
                            </ListInput>
                            <ListItem title="Status">
                              <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                            </ListItem>
                        </List>
                      </Block>
                      <br /><br />
                      </PageContent>
                      <AppSaveRecordButton saveDetail={this.saveLinemanDetail.bind(this)} />
                </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}