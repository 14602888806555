import React,{Component} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Block,
    Row,
    Col,
  } from 'framework7-react';

  export default class Reports extends Component {
    constructor(props) {
      super(props);

      this._isMounted = false;

      this.state = {
        loaded:false,
        effect:'blink',
      };
      
      this.navigateToLink = this.navigateToLink.bind(this);

    }
    
    componentDidMount(){

      this._isMounted = true;
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
        }
      },()=>{

          setTimeout(()=> {

            this.setState({loaded:true});

          }, 1200);
          
          this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        Promise.all([
          
          this._isMounted = false

        ])
        .then(() => {      
          
          this.$f7router.navigate(`/${link}/`);
  
        })

      }

    }

    componentWillUnmount(){

      this._isMounted = false;

    }

    render(){
        
      const{loaded, clientdetail} = this.state;

      return(
        <Page className="page" name="Reports">
            <Navbar sliding={true}>
              <NavLeft>
                <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                <Link href={`/dashboard/`} className="clearmenupadding">
                  <img className="navbarlogo" src={scanlogo} alt="logo" />
                </Link>
              </NavLeft>
              <NavTitle>Reports</NavTitle>
              <NavRight>
              </NavRight>
            </Navbar>
            {
              loaded ? (
                <Block>
                  {/*
                    Number(clientdetail.outstandingreport) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                        <Link className="button button-fill color-blue" href="/outstandingreport/" animate={true} ignoreCache={true}>Outstanding (Detail)</Link>
                      </Col>
                    </Row>:null
                  */}
                  
                  {/*
                    Number(clientdetail.outstandingreportsummary) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                        <Link className="button button-fill color-blue" href="/outstandingreportsummary/" animate={true} ignoreCache={true}>Outstanding (Summary)</Link>
                      </Col>
                    </Row>:null
                  */}

                  {/*
                    Number(clientdetail.circulationreport) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                        <Link className="button button-fill color-blue" href="/circulationreport/" animate={true} ignoreCache={true}>Circulation</Link>
                      </Col>
                    </Row>:null
                  */}

                  {
                    Number(clientdetail.customerlistbynewspaper) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                        <Link className="button button-fill color-blue" href="/customerlistbynewspaper/" animate={true} ignoreCache={true}>Customer List By Newspaper</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.customerlistbymagazine) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                        <Link className="button button-fill color-blue" href="/customerlistbymagazine/" animate={true} ignoreCache={true}>Customer List By Magazine</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.billstatementbyarealine) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                        <Link className="button button-fill color-blue" href="/billstatementbyarealine/" animate={true} ignoreCache={true}>Bill Statement</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.billprintingreport) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                        <Link className="button button-fill color-blue" href="/billprintingreport/" animate={true} ignoreCache={true}>Bill Printing</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.paymentregister) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                      <Link className="button button-fill color-blue" href="/paymentregister/" animate={true} ignoreCache={true}>Payment Register</Link>
                      </Col>
                    </Row>:null
                  }

                  {/*
                    Number(clientdetail.customerledgerreport) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                      <Link className="button button-fill color-blue" href="/customerledgerreport/" animate={true} ignoreCache={true}>Customer Ledger</Link>
                      </Col>
                    </Row>:null
                  */}

                  {
                    Number(clientdetail.billstatementsummary) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                      <Link className="button button-fill color-blue" href="/billstatementsummary/" animate={true} ignoreCache={true}>Bill & Recovery Summary</Link>
                      </Col>
                    </Row>:null
                  }

                  {/*
                    Number(clientdetail.billcollectionsummary) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                      <Link className="button button-fill color-blue" href="/billcollectionsummary/" animate={true} ignoreCache={true}>Bill Collection Summary</Link>
                      </Col>
                    </Row>:null
                  */}

                  {
                    Number(clientdetail.magazinesaledetail) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                        <Link className="button button-fill color-blue" href="/magazinesaledetail/" animate={true} ignoreCache={true}>Magazine Sale Detail</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.monthlybillofnewspaper) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                      <Link className="button button-fill color-blue" href="/monthlybillofnewspaper/" animate={true} ignoreCache={true}>Price List</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.purchasesummary) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                      <Link className="button button-fill color-blue" href="/purchasesummary/" animate={true} ignoreCache={true}>Purchase Summary</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.closecustomerlist) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                      <Link className="button button-fill color-blue" href="/closecustomerlist/" animate={true} ignoreCache={true}>Close Customer List</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.restartcustomerlist) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                      <Link className="button button-fill color-blue" href="/restartcustomerlist/" animate={true} ignoreCache={true}>Restart Customer List</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.sequence) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                      <Link className="button button-fill color-blue" href="/sequence/" animate={true} ignoreCache={true}>Sequence</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.canmessages) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                      <Link className="button button-fill color-blue" href="/smshistory/" animate={true} ignoreCache={true}>SMS History</Link>
                      </Col>
                    </Row>:null
                  }

                  <Row style={{height: '50px'}}>
                    <Col>
                    <Link className="button button-fill color-blue" href="/latepaymentreport/" animate={true} ignoreCache={true}>Late Payment Report</Link>
                    </Col>
                  </Row>

                  {
                    Number(clientdetail.outstandingreport) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                        <Link className="button button-fill color-blue" animate={true} ignoreCache={true}
                        onClick={()=>{
                          const toastWithButton = this.$f7.toast.create({
                            text: `coming soon...`,
                            closeButton: true,
                            closeTimeout: 3000,
                          });
                  
                          toastWithButton.open();
                  
                          return false;
                        }}
                        >Outstanding (Detail)*</Link>
                      </Col>
                    </Row>:null
                  }

                  {
                    Number(clientdetail.circulationreport) > 0 ? <Row style={{height: '50px'}}>
                      <Col>
                        <Link className="button button-fill color-blue" animate={true} ignoreCache={true}
                        href='/circulationreport/'
                        >Circulation</Link>
                      </Col>
                    </Row>:null
                  }
                </Block>
              ):(<AppListItemLoading />)
            }
        </Page>
      );
    }
}