import React,{Component, Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Link,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    BlockTitle,
    List,
    Row,
    Col,
    ListItem,
    Block,
    Fab,
    Icon,
    Toolbar,
    ListInput,
    PageContent,
  } from 'framework7-react';

  export default class InactiveCustomerOutstanding extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        page:1,
        perpage:'',
        searchkeyword:'',
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        nameandphone:'',
        loaded:false,
        isavailable:false,
        totalpages:'',
        showpages:false,
        effect:'blink',
        toolbarPosition:'bottom',
      };

      this.navigateToLink         = this.navigateToLink.bind(this);
      this.getDetail              = this.getDetail.bind(this);
      this.getInventoryDetailPDF  = this.getInventoryDetailPDF.bind(this);
      this.handleUserInput        = this.handleUserInput.bind(this);
      this.Paging                 = this.Paging.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.subscription').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {
          this.getDetail();
        })

        this.hideSplashScreen();
      });

    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }

        this.setState({
          isdetailfetched:false
        });

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }
  
        const bodyFormData = {
            Mode:'GetInactiveCustomerOutstanding',
            clientid:self.state.clientdetail.id,
            page:this.state.page,
            perpage:this.state.perpage,
            areaid:'',
            lineid:'',
            linemanid:'',
            hawkerid:'',
        };

        app.preloader.show();

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState(()=>{
                return{
                    loaded:true,
                    isavailable:true,
                    customers:resdata.recordlist,
                    allcustomers:resdata.recordlist,
                    perpage:Number(resdata.perpage),
                    totalpages:Number(resdata.totalpages),
                    paginglist:resdata.paginglist,
                    showpages:resdata.showpages,
                    totalrecord:resdata.totalrecord,
                    isptr:false,  
                }
              },()=>{
                this.Dom7('.subscription').addClass('animate-bottom');
              });
            }
            else
            {
              this.setState(()=>{
                return{
                    loaded:true,
                    isavailable:false,
                }
              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    navigateToLink(link){

      if(this.$f7.online)
        {
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/
        }
    }

    getInventoryDetailPDF(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetInactiveCustomerOutstandingPDF',
        clientid:self.state.clientdetail.id,
        stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        inventoryid:self.props.f7route.params.inventoryid,
        areaid:self.props.f7route.params.areaid,
        lineid:self.props.f7route.params.lineid,
        hawkerid:self.props.f7route.params.hawkerid,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                  window.open(resdata.pdffilepath);
              }
              else
              {
                  const toastWithButton = app.toast.create({
                    text: resdata.msg,
                    closeButton: true,
                    closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
            console.log(e);
          });

      },500);
    }

    handleUserInput(e){

        const name  = e.target.name;
        const value = e.target.value;
    
        this.setState(()=>{
          return{
            [name]: value
          }
        },()=>{
    
          this.Paging('select');
    
        });
    
    }
    
    Paging(mode){

    let pageerror = "";

    if(!this.$f7.online)
    {
        return;
    }

    let temppage    = '';

    const currpage  = this.state.page;

    if(mode === 'previous')
    {
        temppage  = Number(this.state.page) - 1;
    }
    if(mode === 'next')
    {
        temppage  = Number(this.state.page) + 1;
    }
    if(mode === 'select')
    {
        temppage  = Number(this.state.page);
    }

    if(temppage < 1)
    {
        pageerror = "You are on first page";
    }

    if(temppage > Number(this.state.totalpages))
    {
        pageerror = "You are on last page";
    }

    if(pageerror !== "")
    {
        const toastWithButton = this.$f7.toast.create({
            text: pageerror,
            closeButton: true,
            closeTimeout: 3000,
        });
            
        toastWithButton.open();
        
        return;
    }

    if(temppage <= 0)
    {
        temppage  = 1;
    }

    if(temppage >= Number(this.state.totalpages))
    {
        temppage  = Number(this.state.totalpages);
    }

    this.setState(()=>{

        return{
        page:temppage,
        loaded:false
        }

    },()=>{

        Promise.all([
            
        setTimeout(()=> {

            this.Dom7('.subscription').addClass('animate-bottom');

        }, 50)

        ])
        .then(() => {

        if(mode === 'select')
        {
            this.getDetail();
        }
        else
        {
            if(Number(this.state.page) !== Number(currpage))
            {
            this.getDetail();
            }
        }

        })

    })

    }

    render(){

       const{isavailable, loaded, customers, paginglist, showpages, totalrecord, hasfilter, filterline, filterlineman, filterhawker, filterarea, filteropeningbalance, hasallcustomer, page, totalpages, areaid, lineid, hawkerid, openingbalanceid, toolbarPosition, isinit, nameandphone} = this.state;

      return(
            <Page pageContent={false} className="page" name="InactiveCustomerOutstanding">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Inactive Customers Outstanding</NavTitle>
                  <NavRight className="right-with-menu">
                  <Link onClick={()=>{

                    Promise.all([
                        
                      setTimeout(()=> {

                        this.Dom7('.subscription').addClass('animate-bottom');

                      }, 50)

                    ])
                    .then(() => {
                      this.getDetail();
                    })

                  }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                <PageContent>
                {
                    loaded ? (
                        <div className="subscription">
                        {
                            isavailable ? (<Block>
                            <BlockTitle className="totalrec">Total Customers : {totalrecord}</BlockTitle>
                            <Row noGap className='rowwithbottomborder'>
                                <Col width='25' className="tableheader textalignleft">
                                    Customer
                                </Col>
                                <Col width='35' className="tableheader textalignleft">
                                    Address
                                </Col>
                                <Col width='20' className="tableheader">
                                    Amount
                                </Col>
                                <Col width='20' className="tableheader textalignleft">
                                    Ledger
                                </Col>
                            </Row>
                            {
                                customers.map((inventory, serial)=>{

                                return(
                                    <Row key={inventory.customerid} noGap className='rowwithbottomborder'>
                                        <Col width='25' className="ledgercolumn textalignleft">
                                        {inventory.name}
                                        {inventory.phone ? (<div>{inventory.phone}
                                            {
                                                inventory.phone !== "---" ? (
                                                <Link external href={`tel:${inventory.phone}`} target="_system">
                                                <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                </Link>
                                            ):null
                                            }
                                        </div>):null}
                                        </Col>
                                        <Col width='35' className="ledgercolumn textalignleft">
                                        {inventory.fulladdress}
                                        </Col>
                                        <Col width='20' className="ledgercolumn">
                                        {` ${inventory.outstandingbalance.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
                                        </Col>
                                        <Col width='20' className="ledgercolumn textalignleft">
                                        <Link className="dashboardlink" onClick={()=>{
                                            if(this.$f7.online)
                                            {
                                              this.$f7router.navigate(`/ledger/${inventory.id}/`);
                                            }
                                          }} ignoreCache={true}>
                                            <Icon f7="arrow_up_right_square_fill"></Icon>
                                          </Link>
                                        </Col>
                                    </Row>
                                    );
                                })
                            }
                            </Block>):null
                        }
                        </div>
                    ):(<AppListItemLoading />)
                }
                <br /><br />
                </PageContent>
            {
                isavailable  && showpages ? (
                <Toolbar position={toolbarPosition}>
                {
                    (()=>{
                    if(Number(page) > 1)
                    {
                        return(
                        <Link className="paging" onClick={()=>{this.Paging('previous')}}>
                        <Icon f7="arrow_left_square_fill" ></Icon>
                        </Link>
                        );
                    }
                    else
                    {
                        return(
                        <Link href="#"></Link>
                        );
                    }
                    })()
                }
                    <List className="droppage" noHairlines>
                        <ListInput
                            name="page"
                            type="select"
                            value={page}
                            onChange={this.handleUserInput}
                            placeholder="Please choose..."
                            >
                            {
                            paginglist.map((pages)=>{
                            return(<option key={pages.index} value={pages.page}>{pages.name}</option>);
                            })
                            }
                        </ListInput>
                    </List>
                    {
                    (()=>{
                        if(Number(page) < Number(totalpages))
                        {
                        return(
                            <Link className="paging" onClick={()=>{this.Paging('next')}}>
                            <Icon f7="arrow_right_square_fill" ></Icon>
                            </Link>
                        );
                        }
                        else
                        {
                        return(
                            <Link href="#"></Link>
                        );
                        }
                    })()
                    }
                </Toolbar>
                ):null
            }
            {/*
              hasdetail ? (
                  <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                      if(this.$f7.online)
                      {
                        this.getInventoryDetailPDF();
                      }
                    }}>
                    <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                    <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                  </Fab>
              ):null
            */}
            </Page>
      );
    }
}