import React,{Component, Fragment} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Block,
    BlockTitle,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Toggle,
    Toolbar,
    Link,
  } from 'framework7-react';

  export default class EditSale extends Component {
    constructor(props) {
      super(props);

      let today = new Date();

      let currmonth = today.getMonth()+1;
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }

      let currdate = today.getDate();
      if(currdate < 10)
      {
          currdate = "0"+currdate;
      }

      let date = today.getFullYear()+'-'+currmonth+'-'+currdate;

      this.state = {
        clientdetail:[],
        inventoryid:'',
        inventoryname:'Select',
        areaid:'',
        filterarea:'All Area',
        lineid:'',
        filterline:'All Line',
        hawkerid:'',
        filterhawker:'All Hawker',
        hascustomer:false,
        saledate:date,
        inventorylist:[],
        customerlist:[],
        arealist:[],
        linelist:[],
        hawkerlist:[],
        catid:606,
        salerate:'',
        totalrecord:'',
        totalsalequantity:'',
        isfetched:false,
        type:'sequence',
      };

      this.handleUserInput            = this.handleUserInput.bind(this);
      this.getDetail                  = this.getDetail.bind(this);
      this.handleInventoryQuantity    = this.handleInventoryQuantity.bind(this);
      this.updateTotal                = this.updateTotal.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
	  stockid:this.props.f7route.params.stockid,
	  saledate:this.props.f7route.params.saledate,
        }
      },()=>{
        /*this.inventoryAutocompletePopup();
        this.areaAutocompletePopup();
        this.lineAutocompletePopup();
        this.hawkerAutocompletePopup();*/
        this.hideSplashScreen();
	this.getDetail();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteInventoryPopup.destroy();
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();
      self.hawkerAutocomplete.destroy();

    }
    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'catid')
      {
        const{catid} = this.state;

        if(Number(catid) !== Number(value))
        {
          this.setState({
            inventoryid:'',
            inventoryname:'Select',
            areaid:'',
            filterarea:'All Area',
            lineid:'',
            filterline:'All Line',
            customerlist:[],
            salerate:'',
            totalrecord:'',
            totalsalequantity:'',
            isfetched:false
          })
        }
      }

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'type')
      {
        this.setState({type:!this.state.type});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    inventoryAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteInventoryPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-inventory-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectinventory/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
            Mode:'GetAllInventory',
            clientid:self.state.clientdetail.id,
            area:'addsale',
            catid:Number(self.state.catid),
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    inventorylist:res.inventorylist,
                  }
                },()=>{

                  const searchinventorylist  = self.state.inventorylist;
                  
                  for (let i = 0; i < searchinventorylist.length; i += 1) {
                    if (searchinventorylist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchinventorylist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.setState({
              inventoryid:value[0].id,
              inventoryname:value[0].name,
              areaid:'',
              filterarea:'All Area',
              lineid:'',
              filterline:'All Line',
              hawkerid:'',
              filterhawker:'All Hawker',
              hascustomer:false,
              customerlist:[],
              salerate:value[0].salerate,
              totalrecord:'',
              totalsalequantity:'',
              isfetched:false,
            },()=>{
              $('.autocomplete-inventory-popup').removeClass('error');
              self.getCustomers();
            });

          },
        },
      });
    }

    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
              Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter_old',
              area:'addsale',
              inventoryid:Number(self.state.inventoryid),
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "All Area";
            }

            self.setState({
              areaid:id,
              lineid:'',
              filterarea:name,
              filterline:'All Line',
              hawkerid:'',
              filterhawker:'All Hawker',
              hascustomer:false,
              linelist:[],
              customerlist:[],
              totalrecord:'',
              totalsalequantity:'',
              isfetched:false,
            },()=>{
              self.getCustomers();
            });
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter_old',
              area:'addsale',
              inventoryid:Number(self.state.inventoryid),
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "Select";
            }

            self.setState({
              lineid:id,
              filterline:name,
              hawkerid:'',
              filterhawker:'All Hawker',
              hascustomer:false,
              customerlist:[],
              totalrecord:'',
              totalsalequantity:'',
              isfetched:false,
            },()=>{
              self.checkSingleHawker();
            });

          },
        },
      });
    }

    hawkerAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      self.hawkerAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-hawker-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selecthawker/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetHawker',
              type:'sequencefilter',
              area:'addsale',
              inventoryid:Number(self.state.inventoryid),
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    hawkerlist:res.recordlist,
                  }
                },()=>{

                  const searchhawkerlist  = self.state.hawkerlist;

                  for (let i = 0; i < searchhawkerlist.length; i += 1) {
                    if (searchhawkerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchhawkerlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            /*$('#autocomplete-hawker-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              hawkerid:id,
              filterhawker:name,
              totalrecord:'',
              totalsalequantity:'',
              isfetched:false
            },()=>{
              self.getCustomers();
            });

          },
        },
      });
    }
    
    checkSingleHawker(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'CheckHawker',
        type:'sequencefilter',
        areaid:self.state.areaid,
        lineid:self.state.lineid,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request

          // Hide Preloader
          app.preloader.hide();

          // Hide Pull To Refresh
          app.ptr.done();            

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              hawkerid:resdata.recordlist.id,
              filterhawker:resdata.recordlist.name,
              hascustomer:false,
              isfetched:false,
            },()=>{
              this.getLatestMagazinePrice();
              this.getCustomers();
            });
          }
          else
          {
            this.setState({
              hawkerid:'',
              filterhawker:'All Hawker',
              hascustomer:false,
              isfetched:false,
            },()=>{
              this.getCustomers();
            });
          }
  
        }).catch(e => {
          console.log(e);
        });
      }, 500);
    }

    getLatestMagazinePrice(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetLatestMagazinePrice',
        areaid:self.state.areaid,
        lineid:self.state.lineid,
        hawkerid:self.state.hawkerid,
        inventoryid:self.state.inventoryid,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/sale.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request

          // Hide Preloader
          app.preloader.hide();

          // Hide Pull To Refresh
          app.ptr.done();            

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              salerate:resdata.recordset.salerate,
            });
          }
          else
          {
            this.setState({
              salerate:'',
            });
          }
        }).catch(e => {
          console.log(e);
        });

      }, 500);

    }

    getCustomers(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
        Mode:'GetAllCustomers',
        clientid:clientdetail.id,
        inventoryid:self.state.inventoryid,
        areaid:self.state.areaid,
        lineid:self.state.lineid,
        hawkerid:this.state.hawkerid,
        type:this.state.type,
        area:'addsale',
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                customerlist:resdata.recordlist,
                totalrecord:resdata.totalrecord,
                totalsalequantity:resdata.totalsalequantity,
                hascustomer:true,
                isfetched:true,
              });
            }
            else
            {
              this.setState({
                customerlist:[],
                totalsubscription:0,
                hascustomer:false,
                isfetched:true,
                totalrecord:'',
                totalsalequantity:'',
              });
            }
        }).catch(e => {
          console.log(e);
        });

      }, 500);

    }

    handleInventoryQuantity(e){

      const value = e.target.value;

      const customerid    = e.target.getAttribute('customerid');
      const fieldname     = e.target.getAttribute('fieldname');

      let tempcustomerlist       = [...this.state.customerlist];
      const tempInventoryList2   = tempcustomerlist.find(customer => Number(customer.id) === Number(customerid));

      tempInventoryList2[fieldname] = value;
      
      this.setState(()=>{
        
        return{
          customerlist: tempcustomerlist
        }

      },()=>{

        this.updateTotal();

      });

    }

    updateTotal(){

      let totalquantity = 0;

			this.state.customerlist.map(item=>{
				return{
					totalquantity: totalquantity += Number(item.stockqty)
				};
			});

      this.setState(()=>{
				return{
					totalsalequantity:totalquantity
				}
			})
    }

    saveDetail(){

      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;
      const router    = self.$f7router;     
      let errmsg    = "";

      if(!this.$f7.online)
      {
        return;
      }

      if(Object.keys(this.state.customerlist).length > 0 && Object.keys(this.state.customerlist.filter(tempcustomer => tempcustomer.id > 0)).length > 0)
      {
      }
      else
      {
        if(errmsg === "")
        {
          errmsg  = "No customer sale quantity entered to edit sale";
        }
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
        Mode:'EditSale',
        inventoryid:Number(self.state.stockid),
        saledate:self.state.saledate,
        customerlist:self.state.customerlist.filter(tempcustomer => tempcustomer.id > 0),
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/sale.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request

          // Hide Preloader
          app.preloader.hide();
	  const resdata = JSON.parse(res.data);
	  console.log(resdata)
          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          if(resdata.success !== true)
          {
            toastWithButton.open();
          }

          if(resdata.success === true)
          {
		router.navigate('/sale/');
          }
        }).catch(e => {
          console.log(e);
        });

      }, 1000);
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        const bodyFormData = {
            Mode:'GetEditSaleDetail',
            stockid:self.state.stockid,
            saledate:self.state.saledate,
        }

        app.preloader.show();

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/sale.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            if(resdata.success === true)
            {
              this.setState(()=>{
                return{
		customerlist:resdata.customerlist,
		totalrecord:resdata.totalrecord,
		totalsalequantity:resdata.totalsalequantity,
		inventoryname:resdata.inventoryname,
		saledate:resdata.saledate,
		}
              });
            }
            else
            {
              this.setState(()=>{
                return{

                  isdetailfetched:true,

                }
              });
            }

        }).catch(e => {
            console.log(e);
        });
    }

    render(){

      const{inventoryname, saledate, salerate, catid, filterarea, filterline, hawkerid, filterhawker, hascustomer, isfetched, totalrecord, customerlist, totalsalequantity} = this.state;
      
      return(
          <Page pageContent={false} className="page" name="AddSale">
            <Navbar sliding={true}>
              <NavLeft>
                <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                <Link href={`/dashboard/`} className="clearmenupadding">
                  <img className="navbarlogo" src={scanlogo} alt="logo" />
                </Link>
              </NavLeft>
              <NavTitle>Edit Sale</NavTitle>
              <NavRight className="right-with-menu">
              </NavRight>
            </Navbar>
            <PageContent>
            <Block>
              <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                <ListInput
                  name="saledate"
                  id="calendar-saledate-input"
                  label="Sale Date"
                  placeholder="Sale Date"
                  type="date"
                  value={saledate}
                  onChange={this.handleUserInput}
                  calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                >
                </ListInput>
              </List>
              
              <List noHairlinesMd style={{marginTop:'5px'}}>
                  <ListItem id="inventoryname" title="Stock">{inventoryname}</ListItem>
                 
              </List>
              {
                customerlist && Object.keys(customerlist).length > 0 ? (<Fragment>
                  <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center'}}>Customer List</BlockTitle>
                  <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center',fontSize:'18px'}}>Total Customers : {totalrecord}</BlockTitle>
                  <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center',fontSize:'18px'}}>Total Quantity : {totalsalequantity}</BlockTitle>
                  <div className="zeromargin">
                  {
		    <Fragment>
                            <Row noGap className='rowwithbottomborder'>
                                <Col width='25' className="tableheader textalignleft" style={{minHeight:'42px'}}>
                                    Area
                                </Col>
                                <Col width='25' className="tableheader textalignleft" style={{minHeight:'42px'}}>
                                    Line
				    <br/>
                                </Col>
                                <Col width='25' className="tableheader textalignleft" style={{minHeight:'42px'}}>
                                    Customer
				    <br/>
                                </Col>
				<Col width='15' className="tableheader textalignleft" style={{minHeight:'42px'}}>
                                    Sale Rate
                                </Col>
                                <Col width='10' className="tableheader textalignleft" style={{minHeight:'42px'}}>
                                    Qty
				    <br/>
                                </Col>
                            </Row>
			    {
				    customerlist.map((customer, index)=>{
				      return(
					<Row key={customer.name2} noGap className='rowwithbottomborder'>
					      <Col width='25' className="ledgercolumn textalignleft">
						{customer.area}
					      </Col>
					      <Col width='25' className="ledgercolumn textalignleft">
						{customer.line}
					      </Col>
					       <Col width='25' className="ledgercolumn textalignleft">
						{customer.name} #{customer.customerid}
					      </Col>
					      <Col width='15' className="ledgercolumn textalignleft">
						<input
					      fieldname="salerate"
					      customerid={customer.id}
					      name={`salerate_${customer.id}`}
					      label="Sale Rate"
					      type="number"
					      placeholder="rate"
					      value={customer.salerate}
					      onChange={this.handleInventoryQuantity}
					      required
					      style={{width:'100px'}}
					    />
					      </Col>
					       <Col width='10' className="ledgercolumn textalignleft">
						<input
					      fieldname="stockqty"
					      customerid={customer.id}
					      name={`stockqty_${customer.id}`}
					      label="Qty"
					      type="number"
					      placeholder="Qty"
					      value={customer.stockqty}
					      onChange={this.handleInventoryQuantity}
					      required
					      style={{width:'100px'}}
					    />
					      </Col>
					</Row>
				      );
				})
			}
		    </Fragment>
                  }
                  </div>
                </Fragment>):null
              }
              <br />
            </Block>
            <br />
            </PageContent>
            <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
          </Page>
      );
    }
}