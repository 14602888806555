import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Link,
    Block,
    Row,
    Col,
    Fab,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton
  } from 'framework7-react';

  export default class BillCollectionSummary extends Component {
    
    constructor(props) {
      
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let lastdate = lastDay.getDate();

      let startdate = today.getFullYear()+'-'+(currmonth-1)+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+lastdate;

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
     
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        filterstartmonthyear:monthNames[Number(today.getMonth()-1)]+' - '+today.getFullYear(),
        filterendmonthyear:monthNames[Number(today.getMonth())]+' - '+today.getFullYear(),
        isfetched:false,
        recordset:[],
        isptr:false,
        ismount:false,
        hasallcustomer:false,
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        hasdataavailable:false,
        effect:'blink',
        totalrecord:0,
      };
      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getPaymentRegister = this.getPaymentRegister.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getPaymentRegister();
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getPaymentRegister(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
    
        const bodyFormData = {
            Mode:'GetBillCollectionSummary',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            startdate:self.state.startdate,
            enddate:self.state.enddate,
            areaid:self.state.areaid,
            lineid:self.state.lineid,
            linemanid:self.state.linemanid,
            hawkerid:self.state.hawkerid,
        };

        this.setState(()=>{

          return{
            isfetched:false
          }
          
        },()=>{

          setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                    isfetched:true,
                    recordset:resdata.paymentlist,
                    totalrecord:resdata.totalrecord,
                    hasdataavailable:true,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });

              }
              else
              {
                this.setState({
                    isfetched:true,
                    recordset:[],
                    hasdataavailable:false,
                    totalrecord:0,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        },500);

      });
    }

    getPaymentRegisterPDF(){

        const self      = this;
        const app       = self.$f7;
   
        const bodyFormData = {
            Mode:'GetBillCollectionSummaryPDF',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            startdate:self.state.startdate,
            enddate:self.state.enddate,
            areaid:self.state.areaid,
            lineid:self.state.lineid,
            linemanid:self.state.linemanid,
            hawkerid:self.state.hawkerid,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                    //console.log(resdata.pdffilepath);
                   // window.location.href= 'http://orlopay/agency/';
                    window.open(resdata.pdffilepath);
                }
                else
                {
                    const toastWithButton = app.toast.create({
                        text: resdata.msg,
                        closeButton: true,
                        closeTimeout: 3000,
                    });
                      
                    toastWithButton.open();    
                }
            }).catch(e => {
                console.log(e);
            });

        },500);
    }

    setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, filterstartmonthyear, filterendmonthyear){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            lineid:lineid,
            linemanid:linemanid,
            hawkerid:hawkerid,
            filterarea:filterarea,
            filterline:filterline,
            filterlineman:filterlineman,
            filterhawker:filterhawker,
            startdate:startdate,
            enddate:enddate,
            filterstartmonthyear:filterstartmonthyear,
            filterendmonthyear:filterendmonthyear,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
          }
  
        },()=>{
  
          if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || (this.state.startdate !== "" && this.state.startdate !== undefined) || (this.state.enddate !== "" && this.state.enddate !== undefined))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getPaymentRegister();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){
        
      const{isfetched, recordset, startdate, enddate, hasallcustomer, areaid, lineid, linemanid, hawkerid, hasfilter, filterarea, filterline, filterlineman, filterhawker, removefiltername, applyremovefilter, ismount, hasdataavailable, totalrecord, filterstartmonthyear, filterendmonthyear} = this.state;

      return(
        <Page className="page" name="BillCollectionSummary">
          <Navbar sliding={true}>
            <NavLeft>
              <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
              <Link href={`/dashboard/`} className="clearmenupadding">
                <img className="navbarlogo" src={scanlogo} alt="logo" />
              </Link>
            </NavLeft>
            <NavTitle>Bill Collection Summary</NavTitle>
            <NavRight className="right-with-menu">
            <Link onClick={()=>{

              this.getPaymentRegister();

            }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
            </NavRight>
          </Navbar>
        <FilterConsumer>{(value)=>{

          const{billcollectionsummarydata, applyBillCollectionSummaryFilter} = value;

          if(ismount === true)
          {
            setTimeout(()=> {

              if(removefiltername !== "" && this.state.applyremovefilter === true)
              {
                if(removefiltername === 'area')
                {
                  applyBillCollectionSummaryFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, '', '', startdate, enddate, filterstartmonthyear, filterendmonthyear);
                }
                else if(removefiltername === 'line')
                {
                  applyBillCollectionSummaryFilter('', linemanid, hawkerid, '', filterlineman, filterhawker, areaid, filterarea, startdate, enddate, filterstartmonthyear, filterendmonthyear);
                }
                else if(removefiltername === 'lineman')
                {
                  applyBillCollectionSummaryFilter(lineid, '', hawkerid, filterline, '', filterhawker, areaid, filterarea, startdate, enddate, filterstartmonthyear, filterendmonthyear);
                }
                else if(removefiltername === 'hawker')
                {
                  applyBillCollectionSummaryFilter(lineid, linemanid, '', filterline, filterlineman, '', areaid, filterarea, startdate, enddate, filterstartmonthyear, filterendmonthyear);
                }
                else if(removefiltername === 'daterange')
                {
                  applyBillCollectionSummaryFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, '', '', '', '');
                }
              }

              if(((billcollectionsummarydata.lineid !== lineid) && billcollectionsummarydata.lineid !== undefined) || ((billcollectionsummarydata.linemanid !== linemanid) && billcollectionsummarydata.linemanid !== undefined) || ((billcollectionsummarydata.hawkerid !== hawkerid) && billcollectionsummarydata.hawkerid !== undefined) || ((billcollectionsummarydata.areaid !== areaid) && billcollectionsummarydata.areaid !== undefined) || ((billcollectionsummarydata.startdate !== startdate) && billcollectionsummarydata.startdate !== undefined) || ((billcollectionsummarydata.enddate !== enddate) && billcollectionsummarydata.enddate !== undefined) || applyremovefilter === true)
              {
                this.setNewFilterData(billcollectionsummarydata.lineid, billcollectionsummarydata.linemanid, billcollectionsummarydata.hawkerid, billcollectionsummarydata.filterline, billcollectionsummarydata.filterlineman, billcollectionsummarydata.filterhawker, billcollectionsummarydata.areaid, billcollectionsummarydata.filterarea, billcollectionsummarydata.startdate, billcollectionsummarydata.enddate, billcollectionsummarydata.filterstartmonthyear, billcollectionsummarydata.filterendmonthyear);

                this.setState({
                  removefiltername:'',
                  applyremovefilter:false
                })

              }

            }, 500)
          }
          
        return(
              <div>
                {
                  hasfilter ? (
                    <Block strong className="selectedfilter" style={{marginTop:0}}>
                      {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                      {filterstartmonthyear !== "" && filterstartmonthyear !== "" ? (<Chip text={`Month Range : (${filterstartmonthyear}) - (${filterendmonthyear})`} mediaBgColor="yellow" media={`D`}
                      onClick={()=>{
                        return;
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'daterange'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                      />):null}
                      {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                      onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'area'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                      />):null}
                      {Number(lineid) > 0 && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="green" media={filterline.toUpperCase().substring(0, 1)} deleteable
                      onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'line'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                      />):null}
                      {filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="red" media={filterlineman.toUpperCase().substring(0, 1)} deleteable 
                      onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'lineman'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                      />):null}
                      {Number(hawkerid) > 0 && filterhawker !== "" ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                      onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'hawker'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                      />):null}
                      &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                        this.$f7.views.main.router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&startdate=${startdate}&filterstartmonthyear=${filterstartmonthyear}&enddate=${enddate}&filterendmonthyear=${filterendmonthyear}`);
                      }}/>
                    </Block>
                  ):null
                }
                {
                    isfetched ? (
                        <Fragment>
                        {
                            hasdataavailable ? (<Fragment>
                              <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center',fontSize:'1.2rem'}}>Total Records : {totalrecord}</BlockTitle>
                              <div className="outstandingwrapper">
                                <Row noGap className='rowwithbottomborder'>
                                    <Col width='30' className="tableheader textalignleft">
                                        Month, Year
                                    </Col>
                                    <Col width='20' className="tableheader textalignleft">
                                        Billing
                                    </Col>
                                    <Col width='20' className="tableheader textalignright">
                                        Tot. Coll
                                    </Col>
                                    <Col width='30' className="tableheader textalignright">
                                        Balance
                                    </Col>
                                </Row>
                                {
                                  recordset.map((paymentlist)=>{
                                      return(
                                      <Row key={paymentlist.serialno} noGap className='rowwithbottomborder'>
                                          <Col width='30' className="ledgercolumn textalignleft">
                                              {paymentlist.month}, {paymentlist.year}
                                          </Col>
                                          <Col width='20' className="ledgercolumn textalignleft">
                                              {paymentlist.billing}
                                          </Col>
                                          <Col width='20' className="ledgercolumn textalignright">
                                              {paymentlist.totcoll}
                                          </Col>
                                          <Col width='30' className="ledgercolumn textalignright">
                                              {paymentlist.balance}
                                          </Col>
                                      </Row>
                                      )
                                  })
                                }
                                </div>
                            </Fragment>):(<AppItemNotFound notfoundtext ="No record found." />)
                        }
                        </Fragment>
                    ):(<AppListItemLoading />)
                }
                <br /><br /><br />
              </div>
            );
          }}
          </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {
            hasdataavailable ? (
              <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                if(this.$f7.online)
                  {
                    this.getPaymentRegisterPDF();
                  }
                }}>
                <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
              </Fab>
            ):null
        }
        </Page>
      );
    }
}