import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Block,
    List,
    TextEditor,
    Link,
    ListItem,
    Input,
    Row,
    Col,
  } from 'framework7-react';

  export default class Aboutus extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        tagline:'',
        aboutusdesc:'',
        status:true,
        imagepreview:'',
        orgfile:'',
        customButtons:[
            ['bold', 'italic', 'underline', 'strikeThrough'],
            ['orderedList', 'unorderedList','link'],
            ['paragraph', 'h1', 'h2', 'h3'],
            ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
            ['subscript', 'superscript'],
            ['indent', 'outdent'],
          ],
      };

      this.handleUserInput = this.handleUserInput.bind(this);
      this.getDetails = this.getDetails.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.getDetails();
      });

    }

    getDetails(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
        Mode:'GetWebsiteDetail',
        clientid:clientdetail.id
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/settings.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                tagline:resdata.details.tagline,
                aboutusdesc:resdata.details.aboutusdesc,
                orgfile:resdata.details.orgfile,
                imagepreview:resdata.details.imagepreview,
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      let errmsg    = "";

      if(!this.$f7.online)
      {
        return;
      }

      app.input.validateInputs('.adddata');
      let filedata    = "";
      filedata = document.getElementById('imagefile').files[0];

      if((this.state.tagline === "" || this.state.tagline === undefined) && errmsg === "")
      {
        errmsg  = "Please enter tag line";
      }
      if((this.state.aboutusdesc === "" || this.state.aboutusdesc === undefined) && errmsg === "")
      {
        errmsg  = "Please enter about us description";
      }

      if((filedata === "" || filedata === undefined) && (this.state.orgfile === "" || this.state.orgfile === undefined || this.state.orgfile === null) && errmsg==='')
      {
        errmsg = "Please select an image";
      }

      if(filedata !== "" && filedata !== undefined  && errmsg === '')
      {
        let fileext = filedata.name.split('.').pop().toLowerCase();
        if(fileext !=='jpg' && fileext !=='jpeg' && fileext !=='png')
        {
            errmsg = "Please select a valid image file, only .jpg, .jpeg and .png file allowed";
        }
      }

      if(errmsg !=='')
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
        return false;
      }

      const formData = new FormData();

      formData.append('Mode','UpdateWebsiteDetail');
      formData.append('clientid',Number(self.state.clientdetail.id));
      formData.append('tagline',self.state.tagline);
      formData.append('aboutusdesc',self.state.aboutusdesc);
      formData.append('imagefile',filedata);

      /*const bodyFormData = {
          Mode:'UpdateWebsiteDetail',
          clientid:self.state.clientdetail.id,
          tagline:self.state.tagline,
          aboutusdesc:self.state.aboutusdesc,
      };*/

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request({
            url: `${app.passedParams.serverurl}api/settings.php?t=${Date.now()}`,
            method: 'POST', 
            data: formData,
            crossDomain: true,
            cache: false, 
            dataType: 'application/json',
            contentType: 'multipart/form-data',
            processData: true,
            headers: {
                Authorization: `${accesstoken}`
            },
            success: (data)=>{

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();
    
                const resdata = JSON.parse(data);

                if(resdata.success === true)
                {
                    this.getDetails();
                  /*Promise.all([
                      this.setState(()=>{
                        return{
                            name:'',
                            previewimage:'',
                            status:1,
                            order:'',
                        }
                    })
                  ])
                  .then(() => {
      
                      $$('.item-content').removeClass('item-input-with-error-message');
                      $$('.item-content').removeClass('item-input-invalid');
                      document.getElementById('imagefile').value='';
                  });*/

                  document.getElementById('imagefile').value='';

                  this.$f7.views.main.router.navigate(`thanks/updatewebsitesuccess/`);
                }
                else
                {
                  const toastWithButton = app.toast.create({
                    text: resdata.toastmsg,
                    closeButton: true,
                    closeTimeout: 3000,
                  });

                  toastWithButton.open();
                }
            }
        });        

        /*app.request.promise.post(`${app.passedParams.serverurl}api/settings.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            if(resdata.success !== true)
            {
              toastWithButton.open();
            }

            if(resdata.success === true)
            {
                $$('.item-content').removeClass('item-input-with-error-message');
                $$('.item-content').removeClass('item-input-invalid');

                this.$f7.views.main.router.navigate(`thanks/updatewebsitesuccess/`);
            }
  
        }).catch(e => {
            console.log(e);
        });*/

      }, 1000);

    }

    render(){

      const{tagline, aboutusdesc, imagepreview} = this.state;
      
      return(
            <Page pageContent={false} className="page" name="AddStock">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>About Us</NavTitle>
                    <NavRight className="right-with-menu">
                    </NavRight>
                  </Navbar>
                  <PageContent>
                  <Block>
                    <Row noGap style={{marginLeft:'15px'}}>
                      <Col className='flddescription' style={{fontWeight:'bold'}}>Tag Line</Col>
                    </Row>
                    <List noHairlinesMd>
                      <TextEditor
                          inputId = 'tagline'
                          name='tagline'
                          placeholder="Tag Line"
                          buttons={this.state.customButtons}
                          onTextEditorChange={(value) => this.setState({ tagline: value })}
                          value={tagline}
                          required validate
                          className='adddata'
                      />
                    </List>
                    <Row noGap style={{marginLeft:'15px'}}>
                      <Col className='flddescription' style={{fontWeight:'bold'}}>About Us</Col>
                    </Row>
                    <List noHairlinesMd>
                      <TextEditor
                          inputId = 'aboutusdesc'
                          name='aboutusdesc'
                          placeholder="About Us"
                          buttons={this.state.customButtons}
                          onTextEditorChange={(value) => this.setState({ aboutusdesc: value })}
                          value={aboutusdesc}
                          required validate
                          className='adddata'
                      />
                      {/*
                      <ListInput
                          name="tagline"
                          inputId="tagline"
                          className="adddata resizable"
                          label="Tag Line"
                          type="textarea"
                          resizable
                          placeholder="Tag Line"
                          onInput={this.handleUserInput}
                          value={tagline}
                          multiple={true}
                          clearButton required validate
                      >
                      </ListInput>                    
                      <ListInput
                          name="aboutusdesc"
                          inputId="aboutusdesc"
                          className="adddata resizable"
                          label="About Us"
                          type="textarea"
                          resizable
                          placeholder="About Us"
                          onInput={this.handleUserInput}
                          value={aboutusdesc}
                          multiple={true}
                          clearButton required validate
                      >
                      </ListInput>*/}
                    <ListItem>
                        <span style={{marginRight:'5px'}}>Logo</span><br/>
                        <Input type="file" name="imagefile" inputId="imagefile" onChange={this.handleUserInput}/>
                        {
                            imagepreview ? ( <img src={`${this.$f7.passedParams.serverurl}${imagepreview}`} width='80px'/>) :null
                        }
                    </ListItem>
                  </List>
              </Block>
              <br />
              </PageContent>
              <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
            </Page>
      );
    }
}