import React,{Component} from 'react';

import {
    Page,
    Navbar,
    Link,
    Block,
  } from 'framework7-react';

  export default class Agentdashboard extends Component {

    render(){
      return(
            <Block strong>
                <div className="row">
                    <div className="col">
                        <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5"><Link href="/distributor/agents" ignoreCache={true}>Agents</Link></div>
                    </div>
                    <div className="col">
                        <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5"><Link href="/distributor/payments" ignoreCache={true}>Payments</Link></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5"><Link href="/distributor/orders" ignoreCache={true}>Orders</Link></div>
                    </div>
                    <div className="col">
                        <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5"><Link href="/distributor/reports" ignoreCache={true}>Reports</Link></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5"><Link href="/distributor/profile" ignoreCache={true}>Profile</Link></div>
                    </div>
                </div>
            </Block>
      );
    }
}