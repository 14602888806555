import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppListItemLoading from '../pages/applistitemloading';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Block,
    Fab,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton
  } from 'framework7-react';

  export default class CustomerLedgerReport extends Component {
    
    constructor(props) {
      
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startdate = today.getFullYear()+'-'+(currmonth - 1)+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+currday;
     
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        isfetched:false,
        ledgerlist:[],
        grandtotal:0,
        isptr:false,
        loaded:false,
        ismount:false,
        hasallcustomer:false,
        customerid:'',
        customername:'',
        hasfilter:true,
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        effect:'blink',
        hasrecord:false,
        hasdatefilter:false,
        areaid:'',
        lineid:'',
        filterarea:'Select',
        filterline:'Select',
        customerphone:'',
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getLedgerByDateRange = this.getLedgerByDateRange.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);

    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        /*this.getLedgerByDateRange();*/
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getLedgerByDateRange(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
            Mode:'GetLedgerByDateRange',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            startdate:self.state.startdate,
            enddate:self.state.enddate,
            customerid:self.state.customerid,
            hasdatefilter:Number(self.state.hasdatefilter),
            customerphone:self.state.customerphone,
        };

        /*if(this.state.isptr !== true)
        {
          app.preloader.show();
        }*/

        this.setState({
          loaded:false
        });

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                  this.setState({

                      ledgerlist:resdata.ledgerlist,
                      grandtotal:resdata.grandtotal,
                      customername:resdata.customername,
                      isptr:false,
                      loaded:true,
                      hasrecord:true,

                  });
                }
                else
                {
                  this.setState({

                    isptr:false,
                    loaded:true,
                    hasrecord:false,

                  });
                }

            }).catch(e => {
                console.log(e);
            });

        },500);
    }

    getLedgerPDF(){

        const self      = this;
        const app       = self.$f7;
   
        const bodyFormData = {
            Mode:'GetLedgerByDateRangePDF',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            startdate:self.state.startdate,
            enddate:self.state.enddate,
            customerid:self.state.customerid,
            areaid:self.state.areaid,
            lineid:self.state.lineid,
            printingtype:self.state.printingtype,
            bulkprinting:self.state.bulkprinting,
            hasdatefilter:Number(self.state.hasdatefilter),
            customerphone:self.state.customerphone,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                  window.open(resdata.pdffilepath);
                }
                else
                {
                  const toastWithButton = app.toast.create({
                    text: resdata.msg,
                    closeButton: true,
                    closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();
                }
            }).catch(e => {
                console.log(e);
            });

        },500);
    }

    setNewFilterData(customerid, customername, startdate, enddate, areaid, lineid, filterarea, filterline, hasdatefilter, customerphone){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            customerid:customerid,
            customername:customername,
            startdate:startdate,
            enddate:enddate,
            areaid:areaid,
            lineid:lineid,
            filterarea:filterarea,
            filterline:filterline,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
            hasdatefilter:Number(hasdatefilter),
            customerphone:customerphone,
          }
  
        },()=>{
  
          if(Number(this.state.customerid) > 0 || (this.state.startdate !== "" && this.state.startdate !== undefined) || (this.state.enddate !== "" && this.state.enddate !== undefined) || (this.state.customerphone !== "" && this.state.customerphone !== undefined))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getLedgerByDateRange();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){

      const{startdate, enddate, hasallcustomer, customerid, hasfilter, customername, removefiltername, applyremovefilter, ismount, loaded, grandtotal, ledgerlist, hasrecord, areaid, lineid, filterarea, filterline, hasdatefilter, customerphone, hawkerid, filterhawker} = this.state;
     
      let datestart     = "";
      let newstartdate  = "";

      if(startdate !== "" && startdate !== undefined)
      {
        newstartdate = new Date(startdate);

        let currmonth = newstartdate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        datestart = newstartdate.getDate()+"-"+currmonth+"-"+newstartdate.getFullYear();
      }

      let dateend     = "";
      let newenddate  = "";

      if(enddate !== "" && enddate !== undefined)
      {
        newenddate = new Date(enddate);

        let currmonth = newenddate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
      }

      return(
        <Page className="page" name="CustomerLedgerReport">
          <Navbar sliding={true}>
              <NavLeft>
                <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                <Link href={`/dashboard/`} className="clearmenupadding">
                  <img className="navbarlogo" src={scanlogo} alt="logo" />
                </Link>
              </NavLeft>
              <NavTitle>Customer Ledger</NavTitle>
              <NavRight className="right-with-menu">
                <Link onClick={()=>{

                  Promise.all([
            
                    setTimeout(()=> {
          
                      this.Dom7('.waiting').addClass('animate-bottom');
          
                    }, 50)
          
                  ])
                  .then(() => {
                    
                    this.getLedgerByDateRange();
          
                  });

                }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
              </NavRight>
          </Navbar>
        <FilterConsumer>{(value)=>{

            const{ledgerfilterdata, applyCustomerLedgerFilter} = value;

            if(ismount === true)
            {
              setTimeout(()=> {

                if(removefiltername !== "" && this.state.applyremovefilter === true)
                {
                  if(removefiltername === 'customer')
                  {
                    applyCustomerLedgerFilter('', '', startdate, enddate, areaid, lineid, filterarea, filterline, hasdatefilter, '');
                  }
                  else if(removefiltername === 'daterange')
                  {
                    applyCustomerLedgerFilter(customerid, customername, '', '', areaid, lineid, filterarea, filterline, '', customerphone);
                  }
                }

                if(((ledgerfilterdata.customerid !== customerid) && ledgerfilterdata.customerid !== undefined) || ((ledgerfilterdata.customerphone !== customerphone) && ledgerfilterdata.customerphone !== undefined) || ((ledgerfilterdata.startdate !== startdate) && ledgerfilterdata.startdate !== undefined) || ((ledgerfilterdata.enddate !== enddate) && ledgerfilterdata.enddate !== undefined) || ((Number(ledgerfilterdata.hasdatefilter) !== Number(hasdatefilter)) && ledgerfilterdata.hasdatefilter !== undefined) || applyremovefilter === true)
                {
                  this.setNewFilterData(ledgerfilterdata.customerid, ledgerfilterdata.customername, ledgerfilterdata.startdate, ledgerfilterdata.enddate, ledgerfilterdata.areaid, ledgerfilterdata.lineid, ledgerfilterdata.filterarea, ledgerfilterdata.filterline, ledgerfilterdata.hasdatefilter, ledgerfilterdata.customerphone);

                  this.setState({
                    removefiltername:'',
                    applyremovefilter:false
                  })

                }

              }, 500)
            }
                               
            return(
                <Block>
                {
                  hasfilter ? (
                    <Block strong className="selectedfilter" style={{marginTop:0}}>
                      {areaid === "" && lineid === "" && (customerid === "" || customerid === undefined) && customerphone === "" ? (<Chip text={`Customer : Select`} mediaBgColor="blue" media="N"/>):null}
                      {hasdatefilter === true && datestart ? (<Chip text={`Date : (${datestart}) - (${dateend})`} mediaBgColor="blue" media={`D`} />):null}
                      {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="yellow" media={`A`} />):null}
                      {Number(lineid) > 0 && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="red" media={`L`} />):null}
                      {Number(customerid) > 0 && customername !== "" ? (<Chip text={`Customer : ${customername}`} mediaBgColor="green" media={customername.toUpperCase().substring(0, 1)} />):null}
                      {customerphone !== "" ? (<Chip text={`Customer Phone : ${customerphone}`} mediaBgColor="pink" media={customerphone.substring(0, 1)} />):null}
                      &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.$f7router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&startdate=${startdate}&enddate=${enddate}&customerphone=${customerphone}&customerid=${customerid}&customername=${customername}`);
                        }
                      /*this.openFilterPopup();*/
                      }}/>
                    </Block>
                  ):null
                }
                {
                  Number(customerid) > 0 || customerphone !== "" ? (
                    <Fragment>
                    {
                      loaded ? (<Fragment>
                        {
                          hasrecord ? (
                        <div className="data-table">
                          <table>
                              <thead>
                                <tr>
                                  <th className="label-cell" colSpan={2}>Date</th>
                                  <th className="numeric-cell" colSpan={2}>Item</th>
                                  <th className="numeric-cell">Due</th>
                                  <th className="numeric-cell">Paid</th>
                                  <th className="numeric-cell">Balance</th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                ledgerlist.map((ledger,index)=>{
                                let balclass ='ledgercolumn ledgercolumnamount ledgerbalancepositive';
                                
                                if(ledger.balance < 1)
                                {
                                  balclass='ledgercolumn ledgercolumnamount ledgerbalancenegative';
                                }
                                let ledgerdue = ledger.due;
                                let ledgerpaid = ledger.paid;
                                if(ledger.item == 'Previous Balance')
                                {
                                  ledgerdue = '--';
                                  ledgerpaid ='--';
                                }
                                return(
                                    <tr key={index}>
                                      <td className="label-cell" colSpan={2} style={{paddingRight:'0',paddingLeft:'0'}}>{ledger.date}</td>
                                      <td className="numeric-cell" colSpan={2} style={{paddingRight:'0',paddingLeft:'0'}}>{ledger.item}</td>
                                      <td className="numeric-cell">{ledgerdue}</td>
                                      <td className="numeric-cell">{ledgerpaid}</td>
                                      <td className={`numeric-cell ${balclass}`}>
                                      {ledger.balance}
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                              <tr>
                                <td className="label-cell" colSpan={2} style={{paddingRight:'0',paddingLeft:'0'}}><b>Final Balance</b></td>
                                <td className="numeric-cell" colSpan={2}>&nbsp;</td>
                                <td className="numeric-cell">&nbsp;</td>
                                <td className="numeric-cell">&nbsp;</td>
                                <td className={`numeric-cell`}>{grandtotal}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                          ):(<AppItemNotFound notfoundtext ="No ledger record found." />)
                        }
                      </Fragment>):(<AppListItemLoading />)
                    }
                    </Fragment>
                  ):(<div className="errmsg">Please select a customer 'or' enter phone</div>)
                }
            <br /><br /><br />
            </Block>
          );
        }}
        </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {
            hasrecord ? (
              <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                  if(this.$f7.online)
                  {
                    this.getLedgerPDF();
                  }
                }}>
                <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
              </Fab>
            ):null
          }
        </Page>

      );
    }
}