import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Block,
    List,
    ListInput,
    ListItem,
    Row,
    Col,
    Toggle,
    Link
  } from 'framework7-react';

  export default class AddPurchase extends Component {
    constructor(props) {
      super(props);

      let today = new Date();

      let currmonth = today.getMonth()+1;
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }

      let currdate = today.getDate();
      if(currdate < 10)
      {
          currdate = "0"+currdate;
      }

      let date = today.getFullYear()+'-'+currmonth+'-'+currdate;
  
      this.state = {
        clientdetail:[],
        droppingpointid:'',
        droppingpointname:'Select',
        inventoryid:'',
        inventoryname:'Select',
        purchasedate:date,
        noofpices:'',
        inventorylist:[],
        bulkinventorylist:[],
        droppingpointlist:[],
        hassmartinventory:false,
        type:true,
        issuedate:date,
        ismount:false,
        ispurchaserateeditable:true,
        hassmartdroppingpoint:false,
        issmartdroppingpoint:false,
        hasstockautocomplete:false,
        purchaserate:'',
        colwidth:80,
        hasbulkentry:false,
        isbulklistloaded:false,
      };

      this.handleUserInput              = this.handleUserInput.bind(this);
      this.onPageInit                   = this.onPageInit.bind(this);
      this.onPageInitInventory          = this.onPageInitInventory.bind(this);
      this.getBulkPurchaseStock         = this.getBulkPurchaseStock.bind(this);
      this.handleInventoryPurchaseRate  = this.handleInventoryPurchaseRate.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
        this.getPurchaseRate();
      });

      setTimeout(()=> {

        this.setState({ismount:true},()=>{
          this.onPageInit();
          this.onPageInitInventory();
        });

      }, 1200);
    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteDroppingPointPopup.destroy();
      self.autocompleteInventoryPopup.destroy();

    }

    onPageInit(){
      
      const self  = this;
      const app   = self.$f7;

      self.autocompleteDroppingPointPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-droppingpoint-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectdroppingpoint/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
              Mode:'GetDroppingPoint',
              clientid:self.state.clientdetail.id,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/droppingpoint.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
              Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    droppingpointlist:res.recordlist,
                  }
                },()=>{

                  const searchdroppingpointlist  = self.state.droppingpointlist;

                  for (let i = 0; i < searchdroppingpointlist.length; i += 1) {
                    if (searchdroppingpointlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchdroppingpointlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.setState({
              droppingpointid:value[0].id,
              droppingpointname:value[0].name,
              issmartdroppingpoint:true,
            },()=>{
              self.getPurchaseRate();
              self.getBulkPurchaseStock();
            });

          },
        },
      });

    }

    onPageInitInventory(){

      const self  = this;
      const app   = self.$f7;

      self.autocompleteInventoryPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-inventory-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectinventory/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
              Mode:'GetAllInventory',
              clientid:self.state.clientdetail.id,
              cattype:Number(self.state.type),
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    inventorylist:res.inventorylist,
                  }
                },()=>{

                  const searchinventorylist  = self.state.inventorylist;
                  

                  for (let i = 0; i < searchinventorylist.length; i += 1) {
                    if (searchinventorylist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchinventorylist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.setState({
              inventoryid:value[0].id,
              inventoryname:value[0].name,
            },()=>{
              self.getPurchaseRate();
            });

          },
        },
      });

    }

    fetchNewDroppingPoint(newdroppingpointid, newdroppingpointname){

      this.setState(()=>{

        return{

          droppingpointid:newdroppingpointid

        }

      },()=>{

        this.Dom7('#autocomplete-droppingpoint-name2').text(newdroppingpointname);

      })
    }

    getPurchaseRate(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
        Mode:'GetPurchaseRate',
        clientid:clientdetail.id,
        inventoryid:self.state.inventoryid,
        purchasedate:self.state.purchasedate
      };
      if(self.state.inventoryid < 1)
      {
        return;
      }
      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/purchase.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                purchaserate:resdata.purchaserate,
              },()=>{

                if(this.state.purchaserate < 1)
                {
                  this.setState({ispurchaserateeditable:true});
                }
                else
                {
                  this.setState({ispurchaserateeditable:false});
                }
                
              });
            }
            else
            {
              this.setState({ispurchaserateeditable:false});
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'type')
      {
        this.setState({
          type:!this.state.type
        },()=>{
          this.getBulkPurchaseStock();
        });
      }
      else if(name === 'hasbulkentry')
      {
        this.setState(()=>{
          return{
            hasbulkentry:!this.state.hasbulkentry
          }
        },()=>{
          if(this.state.hasbulkentry)
          {
            this.getBulkPurchaseStock();
            this.autocompleteInventoryPopup.destroy();
          }
          else
          {
            this.onPageInitInventory();
            this.getPurchaseRate();
          }
        });
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        },()=>{
          if(name == 'purchasedate')
          {
            this.getPurchaseRate();
            this.getBulkPurchaseStock();
          }
        });
      }
    }

    handleInventoryPurchaseRate(e){

      const value = e.target.value;

      const inventoryid   = e.target.getAttribute('inventoryid');
      const fieldname     = e.target.getAttribute('fieldname');

      let tempInventoryList       = [...this.state.bulkinventorylist];
      
      const tempInventoryList2    = tempInventoryList.find(inventory => Number(inventory.id) === Number(inventoryid));

      tempInventoryList2[fieldname]  = value;

      this.setState(()=>{
        
        return{
          inventorylist: tempInventoryList
        }

      });

    }

    saveDetail(){

      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      let errmsg    = "";

      if(!this.$f7.online)
      {        
        return;
      }

      app.input.validateInputs('.adddata');

      if(this.state.droppingpointid === "" || this.state.droppingpointid === undefined)
      {
        errmsg  = "Please select a dropping point";
        this.$$('.autocomplete-droppingpoint-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-droppingpoint-popup').removeClass('error');
      }
      if((this.state.inventoryid === "" || this.state.inventoryid === undefined) && errmsg === "" && Number(this.state.hasbulkentry) < 1)
      {
        errmsg  = "Please select a stock";
      }

      if(this.state.noofpices === "" && errmsg === "" && Number(this.state.hasbulkentry) < 1)
      {
        errmsg  = "Please enter number of pieces";
      }

      if((this.state.purchaserate === "" || this.state.purchaserate < 0.001) && errmsg === "" && Number(this.state.hasbulkentry) < 1)
      {
        errmsg  = "Please enter purchase rate";
      }

      if(Number(this.state.hasbulkentry) < 1)
      {
        if(this.state.inventoryid === "" || this.state.inventoryid === undefined)
        {
          this.$$('.autocomplete-inventory-popup').addClass('error');
        }
        else
        {
          this.$$('.autocomplete-inventory-popup').removeClass('error');
        }
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
          Mode:'AddPurchase',
          clientid:self.state.clientdetail.id,
          inventoryid:self.state.inventoryid,
          purchasedate:self.state.purchasedate,
          noofpices:self.state.noofpices,
          type:Number(self.state.type),
          issuedate:self.state.issuedate,
          droppingpointid:self.state.droppingpointid,
          purchaserate:self.state.purchaserate,
          bulkinventorylist:self.state.bulkinventorylist,
          hasbulkentry:Number(self.state.hasbulkentry),
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/purchase.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
            });

            if(resdata.success !== true)
            {
              toastWithButton.open();
            }

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                    inventoryid:'',
                    inventoryname:'Select',
                    /*droppingpointid:'',
                    droppingpointname:'Select',*/
                    noofpices:'',
                    purchaserate:'',
                  }
                })
              ])
              .then(() => {
                /*this.$$('#autocomplete-droppingpoint-name2').text('Select');*/
                $$('.item-content').removeClass('item-input-with-error-message');
                $$('.item-content').removeClass('item-input-invalid');
                
                this.getBulkPurchaseStock();

                this.$f7.views.main.router.navigate(`thanks/addpurchasesuccess/`);
  
              })
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    getBulkPurchaseStock(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
          Mode:'GetAllInventory',
          clientid:clientdetail.id,
          purchasedate:self.state.purchasedate,
          droppingpointid:self.state.droppingpointid,
          cattype:Number(self.state.type),
          type:"bulkpurchasestock",
      };

      if(!this.state.hasbulkentry)
      {
        return;
      }

      this.setState(()=>{
        return{
          isbulklistloaded:false
        }
      },()=>{

        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
            
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();            

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  isbulklistloaded:true,
                  bulkinventorylist:resdata.inventorylist,
                });
              }
              else
              {
                this.setState({
                  isbulklistloaded:true,
                  bulkinventorylist:[],
                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        }, 500);        

      })

    }

    render(){

    const{ inventoryname, purchasedate, noofpices, type, issuedate, purchaserate, droppingpointname, issmartdroppingpoint,ismount, colwidth, ispurchaserateeditable, hasbulkentry, inventorylist, bulkinventorylist, isbulklistloaded} = this.state;

    return(
            <FilterConsumer>{(value)=>{
              const{setDroppingPointData}  = value;

              const{singledroppingpoint} = value;

              if(ismount === true)
              {
                setTimeout(()=> {

                  if(issmartdroppingpoint === true)
                  {
                    if((singledroppingpoint.id !== this.state.droppingpointid) && this.state.droppingpointid > 0)
                    {
                      Promise.all([

                        setDroppingPointData(Number(this.state.droppingpointid), this.state.droppingpointname, false)

                      ])
                      .then(() => {

                        this.setState({

                          issmartdroppingpoint:false,
                        });
                      
                        //this.Dom7('#autocomplete-line-name2').text('Select');
                      })

                    }

                  }
                  else
                  {
                    if(this.state.droppingpointid !== singledroppingpoint.id)
                    {
                      this.fetchNewDroppingPoint(singledroppingpoint.id, singledroppingpoint.name);
                    }
                  }

                }, 500)
                
              }
            return(
                  <Page pageContent={false} className="page" name="AddPurchase">
                    <Navbar sliding={true}>
                      <NavLeft>
                        <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                        <Link href={`/dashboard/`} className="clearmenupadding">
                          <img className="navbarlogo" src={scanlogo} alt="logo" />
                        </Link>
                      </NavLeft>
                      <NavTitle>Add Purchase</NavTitle>
                      <NavRight className="right-with-menu">
                      </NavRight>
                    </Navbar>
                    <PageContent>
                    <Block>
                      <Row noGap>
                          <Col width="40">
                              <List noHairlinesMd className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListItem radio value="1" name="type" checked={Number(type) === 1} title="Newspaper"
                                onChange={this.handleUserInput}
                                ></ListItem>
                              </List>
                          </Col>
                          <Col width="60">
                              <List noHairlinesMd className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListItem radio value="1" name="type" checked={Number(type) === 0} title="Magazine"
                                onChange={this.handleUserInput}
                                ></ListItem>
                              </List>
                          </Col>
                          <Col width="15"></Col>
                      </Row>
                      <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                        <ListInput
                          name="purchasedate"
                          id="calendar-purchasedate-input"
                          label="Purchase Date"
                          placeholder="Purchase Date"
                          type="date"
                          value={purchasedate}
                          onChange={this.handleUserInput}
                          onClose={()=>{this.getPurchaseRate();this.getBulkPurchaseStock();}}
                          calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                        >
                        </ListInput>
                      </List>
                      <Row>
                          <Col width={colwidth}>
                          <List noHairlinesMd className="zeromargin" style={{marginTop:'5px'}}>
                            <ListItem link="#" id="autocomplete-droppingpoint-popup" className="autocomplete-droppingpoint-popup" title="Dropping Point">
                              <div slot="after" id="autocomplete-droppingpoint-name2">{droppingpointname}</div>
                            </ListItem>
                          </List>
                          </Col>
                          {
                            <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                              <Link onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.$f7router.navigate('adddroppingpointpopup/')
                                }
                              }}>
                                <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                              </Link>
                            </Col>
                          }
                      </Row>
                      <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                          <ListItem title="Bulk Entry">
                            <Toggle slot="after" value="1" name="hasbulkentry" onChange={this.handleUserInput} checked={Number(hasbulkentry) === 1}></Toggle>
                          </ListItem>
                          {
                            hasbulkentry === true && bulkinventorylist && isbulklistloaded === true ? (
                              <div>
                                <Row noGap className='rowwithbottomborder'>
                                  <Col width='50' className="tableheader">
                                        Stock
                                    </Col>
                                    <Col width='25' className="tableheader">
                                        No. of Pcs.
                                    </Col>
                                    <Col width='25' className="tableheader">
                                        P. Rate
                                    </Col>
                                </Row>
                                {
                                  bulkinventorylist.map((inventory,i)=>{

                                  return(
                                      <Row noGap key={i} className='rowwithbottomborder'>
                                          <Col width='50' className="ledgercolumn">
                                            {inventory.name}
                                          </Col>
                                          <Col width='25' className="ledgercolumn">
                                            {
                                              inventory.hasnoofpices ? (
                                                <div>{inventory.numberofpieces}</div>
                                              ):(
                                                <input
                                                  inventoryid={inventory.id}
                                                  fieldname="numberofpieces"
                                                  name={`numberofpieces_${inventory.id}`}
                                                  label="No. of Pcs."
                                                  type="number"
                                                  placeholder="No. of Pcs."
                                                  value={inventory.numberofpieces}
                                                  onChange={this.handleInventoryPurchaseRate}
                                                  required
                                                  style={{width:'75px'}}
                                                />
                                              )
                                            }
                                          </Col>
                                          <Col width='25' className="ledgercolumn">
                                            {
                                              inventory.haspurchaserate ? (
                                                <div>{inventory.purchaserate}</div>
                                              ):(
                                                  <input
                                                    inventoryid={inventory.id}
                                                    fieldname="purchaserate"
                                                    name={`purchaserate_${inventory.id}`}
                                                    label="P. Rate"
                                                    type="number"
                                                    placeholder="P. Rate"
                                                    value={inventory.purchaserate}
                                                    onChange={this.handleInventoryPurchaseRate}
                                                    required
                                                    style={{width:'75px'}}
                                                  />
                                              )
                                            }
                                          </Col>
                                      </Row>
                                      );
                                  })
                                }
                              </div>
                            ):null
                          }
                          {
                            hasbulkentry === false ? (
                              <ListItem link="#" id="autocomplete-inventory-popup" className="autocomplete-inventory-popup" title="Stock">
                                <div slot="after" id="autocomplete-inventory-name2">{inventoryname}</div>
                              </ListItem>
                            ):null 
                          }
                        </List>
                        {
                          hasbulkentry === true && !isbulklistloaded === true ? (<AppListItemLoading />):null
                        }
                        {
                          hasbulkentry ? null:(
                            <List noHairlinesMd className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                              <ListInput
                                name="noofpices"
                                className="adddata"
                                label="Number of Pieces"
                                floatingLabel
                                type="number"
                                onInput={this.handleUserInput}
                                placeholder="Number of Pieces"
                                value={noofpices}
                                maxlength="20"
                                clearButton required validate
                              >
                              </ListInput>
                              {(()=>{
                                if(Number(type) === 0)
                                {
                                  return(
                                      <ListInput
                                        name="issuedate"
                                        id="calendar-issuedate-input"
                                        label="Issue Date"
                                        placeholder="Issue Date"
                                        type="date"
                                        value={issuedate}
                                        onChange={this.handleUserInput}
                                        calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                                      >
                                      </ListInput>
                                  );
                                }
                              })()}
                              {ispurchaserateeditable ? (<ListInput
                                  name="purchaserate"
                                  className="adddata"
                                  label="Purchase Rate"
                                  floatingLabel
                                  type="number"
                                  onInput={this.handleUserInput}
                                  placeholder="Purchase Rate"
                                  value={purchaserate}
                                  maxlength="20"
                                  clearButton required validate
                              >
                              </ListInput>) : (
                                <ListItem title="Purchase Rate">
                                  <div slot="after" className="purchaserate">{`Rs. ${purchaserate}`}</div>
                                </ListItem>
                              )}
                          </List>                                
                          )
                        }
                    </Block>
                    <br /><br />
                    </PageContent>
                    <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
                  </Page>
            );
            }}</FilterConsumer>
          
         )
      }
}