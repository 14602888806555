import React,{Component} from 'react';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
  } from 'framework7-react';

  export default class SubLineFilter extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        filterarea:'Select',
        filterline:'Select',
        filterlineman:'',
        filterhawker:'',
        arealist:[],
        linelist:[],
        linemanlist:[],
        hawkerlist:[],
        hassmartline:false,
        hassmartlineman:false,
        hassmarthawker:false,
        hassmartarea:false,
      };

      this.areaAutocompletePopup     = this.areaAutocompletePopup.bind(this);
      this.lineAutocompletePopup     = this.lineAutocompletePopup.bind(this);
      this.applyFilter               = this.applyFilter.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        
        this.areaAutocompletePopup();
        this.lineAutocompletePopup();
        this.hideSplashScreen();

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-area-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              areaid:id,
              filterarea:name
            });

          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-line-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              lineid:id,
              filterline:name
            });

          },
        },
      });
    }

    applyFilter(){

      if(!this.$f7.online)
      {
        return;
      }

      this.props.setNewFilterData(this.state.lineid, this.state.filterline, this.state.filterhawker);
    }

    render(){

      const{lineid, filterline, areaid, filterarea} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{applySublineFilter}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="SubLineFilter">
                        <Block>
                        <List noHairlinesMd>
                          <ListItem link="#" id="autocomplete-area-popup" title="Area" after={filterarea}></ListItem>
                          <ListItem link="#" id="autocomplete-line-popup" title="Line" after={filterline}></ListItem>
                        </List>
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                          Promise.all([
                            setTimeout(()=> {
              
                                applySublineFilter(lineid, filterline, areaid, filterarea);
                  
                            }, 500)
                            
                          ])
                          .then(() => {
              
                            setTimeout(()=> {
              
                              this.$$('.popup-close').trigger('click');
                  
                            }, 500)
                            
                          })

                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}