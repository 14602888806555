import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Card,
    CardHeader,
    CardContent,
    Link,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Row,
    Col,
    Button,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton
  } from 'framework7-react';

  export default class CustomerDetail extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        detail:[],
        clientdetail:[],
        deleterecordid:'',
        recordid:'',
        isdetailfetched:false,
        effect:'blink',
      };
      this.navigateToLink     = this.navigateToLink.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {
          this.getDetail();
        })

        this.hideSplashScreen();
      });

    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }

        this.setState({
          isdetailfetched:false
        })

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }
  
        const bodyFormData = {
            Mode:'GetCustomerDetail',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.id,
        };

        app.preloader.show();

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState(()=>{
                return{

                  detail:resdata.customerdetail,
                  isptr:false,
                  isdetailfetched:true,
  
                }
              });
            }
            else
            {
              this.setState(()=>{
                return{

                  isdetailfetched:true,
  
                }
              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
          Mode:'DeleteCustomer',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');

        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              this.setState(()=>{
                return{
                  deleterecordid:''
                }
              },()=>{

                this.navigateToLink(`managecustomers`);

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    navigateToLink(link){

        if(this.$f7.online)
        {
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/
        }
    }

    render(){

       const{detail, isdetailfetched, effect, clientdetail} = this.state;

      return(
            <Page className="page" name="CustomerDetail">
                <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Customer Detail</NavTitle>
                    <NavRight className="right-with-menu">
                      <Link onClick={()=>{

                        this.getDetail();

                      }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                    </NavRight>
                </Navbar>
                {
                  isdetailfetched ? (
                    <Card outline className="recordset detail">
                      <CardHeader
                      className="date"><div className="item-title">{detail.name}</div><div className="item-title">{detail.customerid}</div></CardHeader>
                      <CardContent>
                      {detail.phonetxt ? (<p><b>Phone : </b>{detail.phonetxt}</p>):null}
                      {detail.email ? (<p><b>Email : </b>{detail.email}</p>):null}
                      {detail.dobtext ? (<p><b>Date Of Birth : </b>{detail.dobtext}</p>):null}
                      {detail.anniversarytext ? (<p><b>Anniversary Date : </b>{detail.anniversarytext}</p>):null}
                      {detail.linename ? (<p><b>Line : </b>{detail.linename}</p>):null}
                      {detail.linemanname ? (<p><b>Lineman : </b>{detail.linemanname}</p>):null}
                      {detail.hawkername ? (<p><b>Hawker : </b>{detail.hawkername}</p>):null}
                      {detail.address1 ? (<p><b>Address : </b>{detail.address1}</p>):null}
                      {detail.googlemap ? (<p><b>Map : </b> <Link href={detail.googlemap} external>View</Link></p>):null}
                      {detail.discounttxt ? (<p><b>Discount : </b>{detail.discounttxt}</p>):null}
                      {detail.openingbalancetxt ? (<p><b>Opening Balance : </b>{detail.openingbalancetxt}</p>):null}
                      {detail.increasepricingtxt ? (<p><b>Increase Pricing : </b>{detail.increasepricingtxt}</p>):null}
                      {detail.inventorytext ? (<p><b>Stock : </b>{detail.inventorytext}</p>):null}
                      {detail.housenumber ? (<p><b>House No. : </b>{detail.housenumber}</p>):null}
                      {detail.floor ? (<p><b>Floor : </b>{detail.floor}</p>):null}
                      {detail.liftavailabletext ? (<p><b>Lift Available : </b>{detail.liftavailabletext}</p>):null}
                      </CardContent>
                      <br /><br />
                      <Row>
                          <Col width="20"></Col>
                          <Col width="60"><Button onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return{
                                  recordid:this.props.f7route.params.id,
                                }
                              },()=>{
                                this.refs.actionsOneGroup.open();
                              })
                            }
                          }}><Icon ios="f7:chart_bar_fill" aurora="f7:chart_bar_fill" md="material:dashboard"></Icon> Manage</Button></Col>
                          <Col width="20"></Col>
                      </Row>
                  </Card>
                  ):(
                    <Card outline className={`waiting recordset skeleton-text skeleton-effect-${effect}`}>
                      <CardHeader
                      className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
                      <CardContent>
                        <p><b>Phone : </b>xxx xxx xxxx</p>
                        <p><b>Email : </b>xxxx xxxx</p>
                        <p><b>DOB : </b>xx - xx - xxxx</p>
                        <p><b>Anniversary : </b>xx - xx - xxxx</p>
                        <p><b>Customer Date : </b>xx - xx - xxxx</p>
                        <p><b>Line : </b>Line Text</p>
                        <p><b>Lineman : </b>Lineman Text</p>
                        <p><b>Hawker : </b>Hawker Name</p>
                        <p><b>Address : </b>Address will be here</p>
                        <p><b>Discount : </b>Discount</p>
                        <p><b>Opening Balance : </b>Opening Balance</p>
                        <p><b>Increase Pricing : </b>Increase Pricing</p>
                        <p><b>Inventory : </b>xxxx.xx</p>
                      </CardContent>
                      <br /><br />
                      <Row>
                          <Col width="20"></Col>
                          <Col width="60"><Button><Icon ios="f7:chart_bar_fill" aurora="f7:chart_bar_fill" md="material:dashboard"></Icon> Manage</Button></Col>
                          <Col width="20"></Col>
                      </Row>
                  </Card>
                  )
                }
              <br />
              <Actions ref="actionsOneGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Select an action to proceed</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{
                    this.navigateToLink(`editcustomer/${this.state.recordid}`)
                  }}>Edit</ActionsButton>
                  {
                    clientdetail.islineman || clientdetail.ismanager ? null:(
                      <ActionsButton color="blue" bold onClick={()=>{
                        this.navigateToLink(`customerinvoices/${this.state.recordid}`)
                      }}>Bills</ActionsButton>
                    )
                  }
                  <ActionsButton color="blue" bold onClick={()=>{
                    this.navigateToLink(`ledger/${this.state.recordid}`)
                  }}>Ledger</ActionsButton>
                  <ActionsButton color="blue" bold onClick={()=>{
                    this.navigateToLink(`makepayment/${this.state.recordid}`)
                  }}>Make Payment</ActionsButton>
                  <ActionsButton color="blue" bold onClick={()=>{
                    this.navigateToLink(`payments/summary/${this.state.recordid}`)
                  }}>Payment</ActionsButton>
                  <ActionsButton color="blue" bold onClick={()=>{
                    this.navigateToLink(`subscription/${this.state.recordid}`)
                  }}>Subscription</ActionsButton>
                  {
                    !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                      <ActionsButton color="blue" bold onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.setState(()=>{
                            return {
                              deleterecordid:this.state.recordid
                            }
                          },()=>{
                            this.refs.actionsDeleteGroup.open()
                          })
                        }
                      }}>Delete</ActionsButton>
                    ):null
                  }
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">Cancel</ActionsButton>
                </ActionsGroup>
              </Actions>
              <Actions ref="actionsDeleteGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteRecord()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
            </Page>
      );
    }
}