import React,{Component} from 'react';
import { FilterConsumer } from '../context/FilterContext';

import {
    Panel,
    Icon,
    View,
    Page,
    List,
    ListItem,
  } from 'framework7-react';

  export default class AppMenu extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          clientdetail:[],
          isclientloaded:false,
        };

        this.navigateToLink = this.navigateToLink.bind(this);
    }

    componentDidMount(){

        let clientdetail  = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          this.setState(()=>{
              return{
                clientdetail:clientdetail
              }
          });
        }
    }

    appLogOut(useconfirm){

      const self      = this;
      const router    = self.$f7router;

      if(useconfirm)
      {
        this.$f7.actions.create({
          buttons: [
          // First group
            [
              {
                text: 'Are you sure? You want to logout',
                label: true
              },
              {
                text: 'Yes',
                bold: true,
                color:'blue',
                onClick:()=>{

                  localStorage.setItem("agency_accesstoken",'');
                  localStorage.setItem("agency_clientdetail",JSON.stringify([]));
                  localStorage.setItem("agency_isadminlogin",false);
                  localStorage.setItem("agency_adminid","");
                  localStorage.setItem("agency_isadminverified",false);
                  localStorage.setItem("agency_authtoken",'');

                  this.$f7.views.main.router.navigate('/login/');
                }
              }
            ],
            // Second group
            [
              {
                text: 'No',
                color: 'red'
              }
            ]
          ]
        }).open();
      }
      else
      {
        /*const toastWithButton = this.$f7.toast.create({
          text: 'Invalid access, we are logging off you',
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();*/

        localStorage.setItem("agency_accesstoken",'');
        localStorage.setItem("agency_clientdetail",JSON.stringify([]));
        localStorage.setItem("agency_isadminlogin",false);
        localStorage.setItem("agency_adminid","");
        localStorage.setItem("agency_isadminverified",false);
        localStorage.setItem("agency_authtoken",'');

        this.$f7.views.main.router.navigate('/login/');
      }
    }

    navigateToLink(link){
        
        if(this.$f7.online)
        {
          if(link === 'logout')
          {
            this.appLogOut(true);
          }
          else
          {
            this.$f7.views.main.router.navigate(`/${link}/`);
          }
        }

    }

    render(){
      
      const{isbetaaccount, islineman, ismanager} = this.state.clientdetail;
      const{isclientloaded} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{initMenu} = value;

          const{clientdetail, adminid} = value;

          setTimeout(() => {
            if(isclientloaded === false)
            {
              this.setState(()=>{
                return{
                  isclientloaded:true,
                }
              },()=>{

                initMenu();
                
              })
              
            }
          }, 100);

          return(
            <Panel left resizable>
              <View>
                <Page>
                  {Number(ismanager) || Number(clientdetail.islineman) || Number(clientdetail.ishawker) ? (
                    <div style={{textAlign:'center',backgroundColor:'#0D2348',color:'#ffffff',padding:'25px 0',position:'relative'}} onClick={()=>{this.$f7.panel.close('left',true);this.navigateToLink('changepassword');}}>
                      <Icon f7="pencil" style={{position:'absolute',top:'5px',right:'5px'}}></Icon>
                      <Icon f7="person_alt_circle_fill"></Icon>
                      <br />
                      {clientdetail.personname}
                      <br />
                      {clientdetail.clientphone}
                    </div>
                  ):(
                    <div style={{textAlign:'center',backgroundColor:'#0D2348',color:'#ffffff',padding:'25px 0',position:'relative'}} onClick={()=>{this.$f7.panel.close('left',true);this.navigateToLink('profile');}}>
                      <Icon f7="pencil" style={{position:'absolute',top:'5px',right:'5px'}}></Icon>
                      <Icon f7="person_alt_circle_fill"></Icon>
                      <br />
                      {clientdetail.personname}
                      <br />
                      {clientdetail.clientphone}
                    </div>
                  )}
                  <List style={{marginTop:'5px'}}>
                    <ListItem link="#" panelClose onClick={()=>{this.navigateToLink('dashboard')}} title="Dashboard" ignoreCache={true} className="menulink"><Icon slot="media" f7="rectangle_split_3x3_fill" className="menu_dashboard"></Icon></ListItem>
                    {
                      Number(clientdetail.cancustomers) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('customerdashboard')}} title="Customers" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_fill" className="menu_customer"></Icon></ListItem>):null
                    }

                    {
                      Number(clientdetail.canpayments) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('paymenttab')}} title="Payments" ignoreCache={true} className="menulink"><Icon slot="media" f7="creditcard_fill" className="menu_payments"></Icon></ListItem>):null
                    }

                    {
                      Number(clientdetail.caninvoices) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('generateinvoice')}} title="Bills" ignoreCache={true} className="menulink"><Icon slot="media" f7="doc_on_clipboard" className="menu_invoices"></Icon></ListItem>):null
                    }

                    {
                      Number(clientdetail.canreports) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('reports')}} title="Reports" ignoreCache={true} className="menulink"><Icon slot="media" f7="bag_fill" className="menu_reports"></Icon></ListItem>):null
                    }

                    {
                      Number(clientdetail.canpurchase) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('purchase')}} title="Purchase" ignoreCache={true} className="menulink"><Icon slot="media" f7="rectangle_stack_fill" className="menu_payments"></Icon></ListItem>):null
                    }

                    {
                      Number(clientdetail.cansale) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('sale')}} title="Sale" ignoreCache={true} className="menulink"><Icon slot="media" f7="cart_fill_badge_plus" className="menu_sale"></Icon></ListItem>):null
                    }

                    {
                      Number(clientdetail.canmessages) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('messages')}} title="Messages" ignoreCache={true} className="menulink"><Icon slot="media" f7="chat_bubble_text_fill" className="menu_messages"></Icon></ListItem>):null
                    }
                    
                    {
                      Number(clientdetail.canstock) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('inventory')}} title="Stock" ignoreCache={true} className="menulink"><Icon slot="media" f7="cube_box_fill" className="menu_inventory"></Icon></ListItem>):null
                    }
                    
                    {
                      Number(clientdetail.canarea) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('areas')}} title="Area" ignoreCache={true} className="menulink"><Icon slot="media" f7="map_pin_ellipse" className="menu_area"></Icon></ListItem>):null
                    }
                    
                    {Number(clientdetail.candroppingpoint) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('droppingpoint')}} title="Dropping Point" ignoreCache={true} className="menulink"><Icon slot="media" f7="map_pin_ellipse" className="menu_dropping_point"></Icon></ListItem>):null}
                    
                    {
                      Number(clientdetail.canline) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('lines')}} title="Line" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_fill" className="menu_line"></Icon></ListItem>):null
                    }
                    
                    {
                      Number(clientdetail.cansubline) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('sublines')}} title="Sub Line" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_fill" className="menu_subline"></Icon></ListItem>):null
                    }
                    
                    {
                      Number(clientdetail.canlineman) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('lineman')}} title="Lineman" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_fill" className="menu_lineman"></Icon></ListItem>):null
                    }
                    
                    {
                      Number(clientdetail.canhawkers) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('hawkers')}} title="Hawkers" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_2_fill" className="menu_hawkers"></Icon></ListItem>):null
                    }
                    
                    {
                      Number(clientdetail.canholiday) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('holiday')}} title="Holiday" ignoreCache={true} className="menulink"><Icon slot="media" f7="calendar_badge_plus" className="menu_holiday"></Icon></ListItem>):null
                    }
                    
                    {
                      Number(clientdetail.cansubscription) ? (<ListItem link="#" panelClose onClick={()=>{this.navigateToLink('subscription')}} title="Subscription" ignoreCache={true} className="menulink"><Icon slot="media" f7="bag_fill_badge_plus" className="menu_subscription"></Icon></ListItem>):null
                    }
                    
                    {
                      Number(clientdetail.canprofile) ? <ListItem link="#" panelClose onClick={()=>{this.navigateToLink('profile')}} title="Manage Profile" ignoreCache={true} className="menulink"><Icon slot="media" f7="person_alt_circle_fill" className="menu_profile"></Icon></ListItem>:null
                    }
                    
                    {
                      Number(clientdetail.cansettings) ? <ListItem link="#" panelClose onClick={()=>{this.navigateToLink('settings')}} title="Settings" ignoreCache={true} className="menulink"><Icon slot="media" f7="wrench_fill" className="menu_profile"></Icon></ListItem>:null
                    }
                    
                    {
                      Number(clientdetail.changepassword) ? <ListItem link="#" panelClose onClick={()=>{this.navigateToLink('changepassword')}} title="Change Password" ignoreCache={true} className="menulink"><Icon slot="media" f7="lock_circle" className="menu_changepassword"></Icon></ListItem>:null
                    }
                    
                    <ListItem link="#" panelClose onClick={()=>{this.navigateToLink('logout')}} title="Logout" className="menulink"><Icon slot="media" f7="lock_rotation" className="menu_logout"></Icon></ListItem>
                  </List>
                </Page>
              </View>
            </Panel>
          );          

        }}
        </FilterConsumer>
      );

    }
}