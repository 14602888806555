import React,{Component} from 'react';
const HolidayContext = React.createContext();
//Provider
//Consumer

class HolidayProvider extends Component{

	constructor(props){
    super(props);

		this.state = {
            clientdetail:[],
            isfetched:false,
            holidays:[],
            isptr:false,
            ismount:false,
            hasallcustomer:true,
            areaid:'',
            lineid:'',
            linemanid:'',
            hawkerid:'',
            hasfilter:true,
            filterarea:'',
            filterline:'',
            filterlineman:'',
            filterhawker:'',
            removefiltername:'',
            applyremovefilter:false,
            isdatafetched:false,
            effect:'blink',
            isavailable:false,
            recordid:'',
            toolbarPosition:'bottom',
            page:1,
            perpage:'',
            totalpages:'',
            showpages:false,
            paginglist:[],
            totalrecord:'',
            serialindex:1,
            deleterecordid:'',
            holidaytype:0,
		};

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getAllHoliday        = this.getAllHoliday.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
      this.deleteFilterAction   = this.deleteFilterAction.bind(this);
      this.Paging               = this.Paging.bind(this);
      this.init                 = this.init.bind(this);
      this.deleteRecordAction   = this.deleteRecordAction.bind(this);
	}

  componentDidMount(){

    let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

    this.setState(()=>{
      return{
        clientdetail:clientdetail,
        customerid:''
      }
    },()=>{

      setTimeout(()=> {

        this.setState({ismount:true});

      }, 1200);

      this.hideSplashScreen();
    });

  }

  hideSplashScreen(){
    document.getElementById("splash-screen").style.visibility = "hidden";
  }
  
  handleUserInput(e){

    const name  = e.target.name;
    const value = e.target.value;

    if(name === 'holidaytype')
    {
        this.setState(()=>{
            return{
                [name]: value
            }
        });
    }
    else
    {
        this.setState(()=>{
            return{
                [name]: value
            }
        },()=>{

            this.Paging('select');

        });
    }

  }

  init(filtervars){

    let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker, holidaytype} = filtervars;

    if(Number(areaid) < 1 || areaid === "NaN")
    {
      areaid      = "";
      filterarea  = "";
    }

    if(Number(lineid) < 1 || lineid === "NaN")
    {
      lineid      = "";
      filterline  = "";
    }
    
    if(Number(hawkerid) < 1 || hawkerid === "NaN")
    {
      hawkerid      = "";
      filterhawker  = "";
    }

    if(holidaytype === "NaN" || holidaytype === "" || holidaytype === undefined)
    {
        holidaytype = 0;
    }

    this.setState(()=>{
      return{
        stateid:'',
        cityid:'',
        lineid:lineid,
        linemanid:'',
        hawkerid:hawkerid,
        areaid:areaid,
        filterarea:filterarea,
        filterline:filterline,
        filterlineman:'',
        filterhawker:filterhawker,
        holidaytype:holidaytype,
        hasfilter:true,
        hasallcustomer:false,
        page:1,
      }
    },()=>{
      if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== "")
      {
        this.setState({hasfilter:true,hasallcustomer:false});
      }
      else
      {
        this.setState({hasfilter:true,hasallcustomer:true});
      }
      this.getAllHoliday();
    })
  }

  getAllHoliday(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }
  
      const bodyFormData = {
        Mode:'GetHolidayList',
        lineid:self.state.lineid,
        areaid:self.state.areaid,
        hawkerid:self.state.hawkerid,
        customertype:self.state.holidaytype,
        page:this.state.page,
        perpage:this.state.perpage,
        serialindex:this.state.serialindex,
      };

      this.setState(()=>{

        return{
          isfetched:false
        }
        
      },()=>{

        setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/holiday.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                isfetched:true,
                holidays:resdata.recordlist,
                isavailable:true,
                perpage:Number(resdata.perpage),
                totalpages:Number(resdata.totalpages),
                paginglist:resdata.paginglist,
                showpages:resdata.showpages,
                totalrecord:resdata.totalrecord,
                serialindex:resdata.serialindex,
              },()=>{

                console.log(this.state.isavailable);
                console.log(this.state.showpages);

                this.Dom7('.waiting').addClass('animate-bottom');

              });

            }
            else
            {
              this.setState({

                isfetched:true,
                isavailable:false,
                totalrecord:0,

              },()=>{

                this.Dom7('.waiting').addClass('animate-bottom');

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      },500);

    });
  }

  setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, holidaytype){

    if(!this.$f7.online)
    {
      return;
    }

    Promise.all([
          
      setTimeout(()=> {

        this.Dom7('.waiting').addClass('animate-bottom');

      }, 50)

    ])
    .then(() => {

        if(Number(holidaytype) === 0)
        {
            lineid = ""; linemanid = ""; hawkerid = ""; filterline = ""; filterlineman = ""; filterhawker = ""; areaid = ""; filterarea = "";            
        }

      this.setState(()=>{
      
        return{
          areaid:areaid,
          lineid:lineid,
          linemanid:linemanid,
          hawkerid:hawkerid,
          filterarea:filterarea,
          filterline:filterline,
          filterlineman:filterlineman,
          filterhawker:filterhawker,
          holidaytype:holidaytype,
          loaded:false,
          applyfilter:false,
          page:1,
        }

      },()=>{

        if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== '')
        {
          this.setState({hasfilter:true,hasallcustomer:false});
        }
        else
        {
          this.setState({hasfilter:true,hasallcustomer:true});
        }

        this.getAllHoliday();

      });

    })
    
  }

  openFilterPopup(){
      
    if(!this.$f7.online)
    {
      return;
    }

    this.$f7.views.main.router.navigate('filterpopup/');

  }

  deleteFilter(){

    this.setState({
      applyremovefilter:true
    })
  }

  navigateToLink(link){

    if(this.$f7.online)
    {
      this.$f7.views.main.router.navigate(`/${link}/`);
    }

  }

  Paging(mode){

    let pageerror = "";

    /*if(!this.$f7.online)
    {
      return;
    }*/

    let temppage    = '';

    const currpage  = this.state.page;

    if(mode === 'previous')
    {
      temppage  = Number(this.state.page) - 1;
    }
    if(mode === 'next')
    {
      temppage  = Number(this.state.page) + 1;
    }
    if(mode === 'select')
    {
      temppage  = Number(this.state.page);
    }

    if(temppage < 1)
    {
      pageerror = "You are on first page";
    }

    if(temppage > Number(this.state.totalpages))
    {
      pageerror = "You are on last page";
    }

    if(pageerror !== "")
    {
      const toastWithButton = this.$f7.toast.create({
          text: pageerror,
          closeButton: true,
          closeTimeout: 3000,
      });
          
      toastWithButton.open();
      
      return;
    }

    if(temppage <= 0)
    {
      temppage  = 1;
    }

    if(temppage >= Number(this.state.totalpages))
    {
      temppage  = Number(this.state.totalpages);
    }

    this.setState(()=>{

      return{
        page:temppage,
        loaded:false
      }

    },()=>{

      Promise.all([
          
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        if(mode === 'select')
        {
          this.getAllHoliday();
        }
        else
        {
          if(Number(this.state.page) !== Number(currpage))
          {
            this.getAllHoliday();
          }
        }

      })

    })

  }

  deleteFilterAction(filtername){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              if(filtername === "area")
              {
                this.setState(()=>{
                  return{
                    areaid:'',
                    filterarea:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== "")
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
                  
                  this.getAllHoliday();

                })
              }
              else if(filtername === "line")
              {
                this.setState(()=>{
                  return{
                    lineid:'',
                    filterline:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== "")
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getAllHoliday();

                })
              }
              else if(filtername === "lineman")
              {
                this.setState(()=>{
                  return{
                    linemanid:'',
                    filterlineman:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== "")
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getAllHoliday();

                })
              }
              else if(filtername === "hawker")
              {
                this.setState(()=>{
                  return{
                    hawkerid:'',
                    filterhawker:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || this.state.holidaytype !== "")
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getAllHoliday();

                })
              }
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

  deleteRecordAction(recordid){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              this.setState(()=>{
                return{
                  deleterecordid:recordid
                }
              },()=>{

                this.deleteRecord();

              })
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

  deleteRecord(){

    const self      = this;
    const app       = self.$f7;

    if(!this.$f7.online)
    {
      return;
    }

    const bodyFormData = {
      Mode:'DeleteHoliday',
      clientid:this.state.clientdetail.id,
      HolidayID:this.state.deleterecordid,
    };

    app.preloader.show();

    setTimeout(()=> {

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/holiday.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

          if(resdata.success === true)
          {
            this.setState(
              ()=>{
                return {
                  deleterecordid:''
                }
              },()=>{
                this.getAllHoliday();
              }
            );
          }

      }).catch(e => {
          console.log(e);
      });

    }, 500);

  }

	render(){
		return (
			<HolidayContext.Provider value={{
			  ...this.state,
				handleUserInput:this.handleUserInput,
				getAllHoliday:this.getAllHoliday,
				setNewFilterData:this.setNewFilterData,
				openFilterPopup:this.openFilterPopup,
				deleteFilter:this.deleteFilter,
				navigateToLink:this.navigateToLink,
				Paging:this.Paging,
				deleteFilterAction:this.deleteFilterAction,
				init:this.init,
				deleteRecordAction:this.deleteRecordAction,
			}}
			>
			{this.props.children}
			</HolidayContext.Provider>
		);
	}
}

const HolidayConsumer = HolidayContext.Consumer;

export {HolidayProvider, HolidayConsumer, HolidayContext};