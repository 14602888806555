import React,{Component} from 'react';

const ReportContext = React.createContext();
//Provider
//Consumer

class ReportProvider extends Component{

	constructor(props){
		super(props);
		this.state = {
		  teststate: 'I am test state',
		  singlearea: [],
		  singleline: [],
		  singlelineman: [],
		  singlehawker: [],
		  filterdata: [],
		  circulationfilterdata: [],
		  billprintfilterdata: [],
		};

		this.DemoFunction				= this.DemoFunction.bind(this);
		this.setAreaData				= this.setAreaData.bind(this);
		this.setLineData				= this.setLineData.bind(this);
		this.setLinemanData				= this.setLinemanData.bind(this);
		this.setHawkerData				= this.setHawkerData.bind(this);
		this.applyFilter				= this.applyFilter.bind(this);
		this.applyCirculationFilter		= this.applyCirculationFilter.bind(this);
		this.applyBillPrintingFilter	= this.applyBillPrintingFilter.bind(this);
	}

	DemoFunction(areaid){

		console.log("I am areaid "+areaid);

	}

	setAreaData(id, name){

		let dataset	= [...this.state.singlearea];

		dataset.id		= id;
		dataset.name	= name;

		this.setState({

			singlearea:dataset

		});

	}

	setLineData(id, name){

		let dataset	= [...this.state.singleline];

		dataset.id		= id;
		dataset.name	= name;

		this.setState({

			singleline:dataset

		});

	}

	setLinemanData(id, name){

		let dataset	= [...this.state.singlelineman];

		dataset.id		= id;
		dataset.name	= name;

		this.setState({

			singlelineman:dataset

		});

	}

	setHawkerData(id, name){

		let dataset	= [...this.state.singlehawker];

		dataset.id		= id;
		dataset.name	= name;

		this.setState({

			singlehawker:dataset

		});

	}

	applyFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea){

		let dataset	= [...this.state.filterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;

		this.setState({

			filterdata:dataset

		});

	}

	applyCirculationFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, date){

		let dataset	= [...this.state.filterdata];

		dataset.lineid			= lineid;
		dataset.linemanid		= linemanid;
		dataset.hawkerid		= hawkerid;
		dataset.areaid			= areaid;
		dataset.filterline		= filterline;
		dataset.filterlineman	= filterlineman;
		dataset.filterhawker	= filterhawker;
		dataset.filterarea		= filterarea;
		dataset.date			= date;

		this.setState({

			circulationfilterdata:dataset

		});

	}

	applyBillPrintingFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, billnumberfrom,billnumberto,filterbillnumberfrom,filterbillnumberto,isbillnumber,isdatefilter,billstartdate,billenddate, issingledatefilter, billprintingdate, isborderprinting){

		let dataset	= [...this.state.filterdata];
		dataset.lineid					= lineid;
		dataset.linemanid				= linemanid;
		dataset.hawkerid				= hawkerid;
		dataset.areaid					= areaid;
		dataset.filterline				= filterline;
		dataset.filterlineman			= filterlineman;
		dataset.filterhawker			= filterhawker;
		dataset.filterarea				= filterarea;
		dataset.isbillnumber			= isbillnumber;
		dataset.billnumberfrom			= billnumberfrom;
		dataset.billnumberto			= billnumberto;
		dataset.isdatefilter			= isdatefilter;
		dataset.billstartdate			= billstartdate;
		dataset.billenddate				= billenddate;
		dataset.filterbillnumberfrom	= filterbillnumberfrom;
		dataset.filterbillnumberto		= filterbillnumberto;
		dataset.issingledatefilter		= issingledatefilter;
		dataset.billprintingdate		= billprintingdate;
		dataset.isborderprinting		= isborderprinting;

		this.setState({

		billprintfilterdata:dataset

		});
	}

	render(){
		return (
			<ReportContext.Provider value={{
			...this.state,
				DemoFunction:this.DemoFunction,
				setAreaData:this.setAreaData,
				setLineData:this.setLineData,
				setLinemanData:this.setLinemanData,
				setHawkerData:this.setHawkerData,
				applyFilter:this.applyFilter,
				applyCirculationFilter:this.applyCirculationFilter,
				applyBillPrintingFilter:this.applyBillPrintingFilter,
			}}
			>
			{this.props.children}
			</ReportContext.Provider>
		);
	}
}

const ReportConsumer = ReportContext.Consumer;

export {ReportProvider, ReportConsumer, ReportContext};