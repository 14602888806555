import React,{Component} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
    ListInput,
    Row,
    Col,
    BlockTitle
  } from 'framework7-react';

  export default class UpcomingBirthday extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        hastodaystaff:false,
        todaystafflist:[],
        hastomarrowstaff:false,
        tomarrowstafflist:[],
        loaded:false,
        effect:'blink',
      };
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.setState(()=>{
              return{
                loaded:false
              }
            },()=>{

              this.Dom7('.waiting').addClass('animate-bottom');

            })

          }, 50)

        ])
        .then(() => {

            this.getStaffdob();
          
        })
        
        this.hideSplashScreen();

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    getStaffdob(){

      if(!this.$f7.online)
      {
        return;
      }

      this.setState({
        loaded:false
      });

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetStaffDOB',
        clientid:Number(this.state.clientdetail.id),
      };

      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          app.preloader.hide();
          
          app.ptr.done();

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              hastodaystaff:resdata.recordset.hastodaystaff,
              todaystafflist:resdata.recordset.todaystafflist,
              hastomarrowstaff:resdata.recordset.hastomarrowstaff,
              tomarrowstafflist:resdata.recordset.tomarrowstafflist,
              totalbirthdaycount:resdata.recordset.totalbirthdaycount,
              loaded:true,
            });
          }
          else{
            this.setState({
              hastodaystaff:false,
              todaystafflist:[],
              hastomarrowstaff:false,
              tomarrowstafflist:[],
              totalbirthdaycount:"0",
              loaded:true,
            });
          }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
    }

    render(){

      const{hastodaystaff, todaystafflist, hastomarrowstaff, tomarrowstafflist, loaded} = this.state;

      return(
        <FilterConsumer>{(value)=>{

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Upcoming Birthday</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="UpcomingBirthday">
                  {
                    loaded ? (
                        <div>
                            <BlockTitle medium>Today's Birthday</BlockTitle>
                            {
                                hastodaystaff ? (
                                    <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                                    {
                                        todaystafflist.map((todaystaff, i)=>{
                                        return(
                                              <ListItem key={i}>
                                                <div slot="title">{`${todaystaff.name} (${todaystaff.designation})`}
                                                {
                                                  todaystaff.lines ? (<div style={{fontSize:'13px',fontStyle:'italic'}}>{todaystaff.lines}</div>):null
                                                }
                                                </div>
                                                <div slot="after"><Link external href={`tel:${todaystaff.phone}`} target="_system">
                                                <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                </Link></div>
                                              </ListItem>
                                            );
                                        })
                                    }
                                    </List>
                                ):(
                                  <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                                    <ListItem title="Today is no birthday"></ListItem>
                                  </List>
                                )
                            }
                            <BlockTitle medium>Tomorrow's Birthday</BlockTitle>
                            {
                                hastomarrowstaff ? (
                                    <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                                    {
                                        tomarrowstafflist.map((tomarrowstaff, j)=>{
                                        return(
                                              <ListItem key={j} title={`${tomarrowstaff.name} (${tomarrowstaff.designation})`}>
                                                <div slot="after"><Link external href={`tel:${tomarrowstaff.phone}`} target="_system">
                                                <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                </Link></div>
                                              </ListItem>
                                            );
                                        })
                                    }
                                    </List>
                                ):(
                                  <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                                    <ListItem title="Tomorrow is no birthday"></ListItem>
                                  </List>
                                )
                            }
                        </div>                        
                    ):(
                      <AppListItemLoading />
                    )
                  }
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}