import React,{Component} from 'react';

const CampaignContext = React.createContext();
//Provider
//Consumer

class CampaignProvider extends Component{

	constructor(props){
		super(props);

		  this.state = {
			clientdetail:[],
			loaded:false,
			isavailable:true,
			campaigns:[],
			deleterecordid:'',
			toolbarPosition:'bottom',
			page:1,
			perpage:'',
			totalpages:'',
			showpages:false,
			paginglist:[],
			isloaded:false,
			totalrecord:'',
			effect:'blink',
			hasfilter:true,
			hasallrecord:true,
			applyfilter:false,
			statusid:'',
			statusname:'',
			iscampaignloading:false,
			internetstatus:navigator.onLine ? "online" : "offline",
			campid:"",
			campname:"",
			phonenumber:"",
			filterstaffid:"",
			staff:"",
			leadcategoryid:"",
			leadcategory:"",
			statename:"",
			city:"",
			pincode:"",
			isinterested:-1,
			filterisinteresedtext:'',
			filterisinteresedabb:'',
			isreferred:0,
			loadedlead:false,
			isavailablelead:true,
			hasfilterlead:true,
			hasallrecordlead:true,
			campaignleads:[],
			totalleads:0,
			totalinterested:0,
			totalreferred:0,
			graphlabels:[],
			graphdata:[],
			bgcolordata:[],
			leadstatusids:"",
			leadstatusnames:"Call Pending, Call Back Requested, Cold Lead, Hot Lead, Moved to CRM",
			leadtagids:"",
			leadtagnames:"",        
			issummaryloaded:false,
			isavailablesummary:false,
			totalschedulecampaign:0,
			campaignlistbystatus:[],
			hasinprocesscampaign:false,
			totalinprocesscampaign:0,
			isinsufficientcredit:0,
			inprocessmsg:"",
			hascredit:false,
			credits:0,
			clientname:'',
			clientphone:'',
			selectedleadcommonfilter:[],
			isallselected:false,
			allselectedtext:'Select All',
			fourcelogout:false,
			allopeninterests:0,
			groupid:'',
			groupname:'',
			leadtype:'',
			leadtypename:'',
			adminid:'',
		  };

		this.handleUserInput			      = this.handleUserInput.bind(this);
		this.getAllCampaign			        = this.getAllCampaign.bind(this);
		this.updateOnlineStatus		      = this.updateOnlineStatus.bind(this);
		this.deleteRecordAction		      = this.deleteRecordAction.bind(this);
		this.manageCampaignAction		    = this.manageCampaignAction.bind(this);
		this.Paging						          = this.Paging.bind(this);
		this.openFilterPopup			      = this.openFilterPopup.bind(this);
		this.deleteSingleRecord		      = this.deleteSingleRecord.bind(this);
		this.pauseResumeCampaign		    = this.pauseResumeCampaign.bind(this);
		this.init		                    = this.init.bind(this);
		this.getCampaignSummary		      = this.getCampaignSummary.bind(this);
		this.setCampaignFilterData		  = this.setCampaignFilterData.bind(this);
		this.deleteFilterActionCampaign = this.deleteFilterActionCampaign.bind(this);
		this.archiveCampaign		        = this.archiveCampaign.bind(this);
		this.getSMSCredit               = this.getSMSCredit.bind(this);
		this.clearCampaignFilter			  = this.clearCampaignFilter.bind(this);
	}

  componentDidMount(){

      this.hideSplashScreen();
  }

  hideSplashScreen(){

    document.getElementById("splash-screen").style.visibility = "hidden";
    
  }

  updateOnlineStatus() {

    if(this.state.isloaded  === true)
    {
      let intstatus	= "offline";

      intstatus		= navigator.onLine ? "online" : "offline";

      this.setState(()=>{
          return{
              internetstatus: intstatus,
          };
      },()=>{

          const self      = this;
          const app       = self.$f7;

          if(this.state.internetstatus === "offline")
          {
              const toastWithButton = app.toast.create({
                  text: "No internet connection",
              });
                
              toastWithButton.open();
          }
          else
          {
              const toastWithButton = app.toast.create({
                  text: "Internet connected",
                  closeButton: true,
                  closeTimeout: 3000,
              });
                
              toastWithButton.open();
          }
      });
    }
    else
    {
      this.setState({
          isloaded:true
      });
    }
  }

  deleteSingleRecord(){

    const self	= this;
    const app	= self.$f7;

      const bodyFormData = {
          Mode:'DeleteCampaign',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');

        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                  }
                },()=>{
                  this.getAllCampaign();
                }
              );
              
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);	
  }

  pauseResumeCampaign(status){

    const self	= this;
    const app	= self.$f7;

    let bodyFormData  = "";

    if(status === 3)
    {
      bodyFormData = {
          Mode:'ResumeCampaign',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };
    }
    else
    {
      bodyFormData = {
          Mode:'PauseCampaign',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

    }

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                  }
                },()=>{
                  this.getAllCampaign();
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);	
  }

  deleteRecordAction(recordid){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.state.internetstatus === "online")
            {
              this.setState(()=>{
                return{
                  deleterecordid:recordid
                }
              },()=>{

                this.deleteSingleRecord();

              })
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

  archiveCampaign(status){

    const self	= this;
    const app	= self.$f7;

    const bodyFormData = {
        Mode:'ArchiveCampaign',
        clientid:this.state.clientdetail.id,
        recordid:this.state.deleterecordid,
    };

    app.preloader.show();

    // Simulate Ajax Request
    setTimeout(()=> {

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

          if(resdata.success === true)
          {
            this.setState(
              ()=>{
                return {
                  deleterecordid:'',
                }
              },()=>{
                this.getAllCampaign();
              }
            );
          }

      }).catch(e => {
          console.log(e);
      });

    }, 500);
  }

  manageCampaignAction(recordid, status, completed,totalsent){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    let pauseresumetext = "Pause";
    let pauseresumeicon = '<i class="icon f7-icons greenecolor">pause_circle_fill</i>';

    if(status === 3)
    {
      pauseresumetext = "Resume";
      pauseresumeicon = '<i class="icon f7-icons redcolor">play_rectangle_fill</i>';
    }

    const appButton     = [{
      text: 'Select an action to proceed',
      label: true,
    }];
    
    if(status !== 2 && Number(totalsent) < 1)
    {
      appButton.push(/*{
          icon: '<i class="icon f7-icons pinkcolor">pencil_circle_fill</i>',
          text: 'Edit',
          bold: true,
          color:'blue',
          onClick:()=>{
            if(this.state.internetstatus === "online")
            {
              if(status !== 3)
              {
                app.dialog.confirm(`Are you sure? you are going to edit your campaign, hence your campaign will pause and after edit this will resume`,'Edit Campaign Confirmation',() => {
    
                  this.setState(()=>{
                    return{
                      deleterecordid:recordid
                    }
                  },()=>{
                    
                    Promise.all([

                      this.pauseResumeCampaign(1)

                    ]).then(()=>{

                      this.$f7.views.main.router.navigate(`/editcampaign/${recordid}/`);

                    })

                  })
  
                });
              }
              else
              {
                this.$f7.views.main.router.navigate(`/editcampaign/${recordid}/`);
              }
            }
          }
      },*/
      {
        icon: pauseresumeicon,
        text: pauseresumetext,
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.state.internetstatus === "online")
            {
              this.setState(()=>{
                return{
                  deleterecordid:recordid
                }
              },()=>{

                this.pauseResumeCampaign(status);

              })
            }
          }
      });
    }

    appButton.push(/*{
        icon: '<i class="icon f7-icons orangecolor">paperplane_fill</i>',
        text: 'Resend',
        bold: true,
        color:'blue',
        onClick:()=>{

          if(this.state.internetstatus === "online")
          {
            this.$f7.views.main.router.navigate(`/editcampaign/${recordid}/resend/`);
          }
        }
      },*/
      {
        icon: '<i class="icon f7-icons">clock_fill</i>',
        text: 'View History',
        bold: true,
        color:'blue',
        onClick:()=>{

          if(this.state.internetstatus === "online")
          {
            this.$f7.views.main.router.navigate(`/viewlog/${recordid}/`);
          }
        }
    });

    if(status === 2)
    {
      appButton.push({
          icon: '<i class="icon f7-icons archivecolor">archivebox_fill</i>',
          text: 'Archive',
          bold: true,
          color:'blue',
          onClick:()=>{
            if(this.state.internetstatus === "online")
            {
              this.setState(()=>{
                return{
                  deleterecordid:recordid
                }
              },()=>{

                this.archiveCampaign(status);

              })
            }
          }
        });
    }

    app.actions.create({
      grid:true,
      buttons: [
      // First group
      appButton,
        // Second group
        /*[
          {
            text: 'Cancel',
            color: 'red'
          }
        ]*/
      ]
    }).open();
  }

  handleUserInput(e){

    const name  = e.target.name;
    const value = e.target.value;

    this.setState(()=>{
      return{
        [name]: value
      }
    },()=>{

      this.Paging('select');

    });

  }

  Paging(mode){

    let pageerror = "";

    if(this.state.internetstatus === "offline")
    {
          const toastWithButton = this.$f7.toast.create({
              text: "No internet connection",
          });
            
          toastWithButton.open();
          
          return;
    }

    let temppage    = '';

    const currpage  = this.state.page;

    if(mode === 'previous')
    {
      temppage  = Number(this.state.page) - 1;
    }
    if(mode === 'next')
    {
      temppage  = Number(this.state.page) + 1;
    }
    if(mode === 'select')
    {
      temppage  = Number(this.state.page);
    }

    if(temppage < 1)
    {
      pageerror = "You are on first page";
    }

    if(temppage > Number(this.state.totalpages))
    {
      pageerror = "You are on last page";
    }

    if(pageerror !== "")
    {
          const toastWithButton = this.$f7.toast.create({
              text: pageerror,
              closeButton: true,
              closeTimeout: 3000,
          });
            
          toastWithButton.open();
          
          return;
    }

    if(temppage <= 0)
    {
      temppage  = 1;
    }

    if(temppage >= Number(this.state.totalpages))
    {
      temppage  = Number(this.state.totalpages);
    }

    this.setState(()=>{

      return{
        page:temppage,
        loaded:false
      }

    },()=>{

      Promise.all([
          
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        if(mode === 'select')
        {
          this.getAllCampaign();
        }
        else
        {
          if(Number(this.state.page) !== Number(currpage))
          {
            this.getAllCampaign();
          }
        }

      })

    })

  }

  init(statusid, statusname){

    let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
    
    if(clientdetail !== undefined && clientdetail !== null)
    {
        this.setState(()=>{
          return{

            clientdetail:clientdetail,
            loaded:false,
            isavailable:false,
            applyfilter:false,
            page:1,
            statusid:statusid,
            statusname:statusname,

          }
        },()=>{

          this.getAllCampaign();

        });
    }
  }

  getAllCampaign(){

      if(this.state.internetstatus === "offline")
      {
          const toastWithButton = this.$f7.toast.create({
              text: "No internet connection",
          });
            
          toastWithButton.open();
          
          return;
      }

      if(Number(this.state.clientdetail.id) < 1 || this.state.clientdetail.id === undefined || this.state.iscampaignloading === true)
      {
        return;
      }

      this.setState(()=>{
        return{
          iscampaignloading:true,
          loaded:false
        }
      },()=>{

        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');
  
        }, 50);

        const self      = this;
        const app       = self.$f7;
  
        let bodyFormData = {};

        if(Number(self.state.clientdetail.isstaff) > 0)
        {
          bodyFormData = {
              Mode:'GetAllCampaign',
              clientid:this.state.clientdetail.id,
              page:this.state.page,
              perpage:this.state.perpage,
              searchkeyword:this.state.searchkeyword,
              statusids:this.state.statusid,
              campids:this.state.campid,
              staffid:this.state.clientdetail.staffid,
              groupid:this.state.clientdetail.groupid,
          };
        }
        else
        {
          bodyFormData = {
              Mode:'GetAllCampaign',
              clientid:this.state.clientdetail.id,
              page:this.state.page,
              perpage:this.state.perpage,
              searchkeyword:this.state.searchkeyword,
              statusids:this.state.statusid,
              campids:this.state.campid,
              groupid:this.state.groupid,
          };
        }
  
        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
        
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({
                  loaded:true,
                  isavailable:true,
                  campaigns:resdata.recordlist,
                  perpage:Number(resdata.perpage),
                  totalpages:Number(resdata.totalpages),
                  paginglist:resdata.paginglist,
                  showpages:resdata.showpages,
                  totalrecord:resdata.totalrecord,
                  isptr:false,
                  iscampaignloading:false,
                });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  isptr:false,
                  iscampaignloading:false,
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);

      })

  }

  setCampaignFilterData(campid, campname, statusid, statusname, groupid, groupname){

    if(this.state.internetstatus === "offline")
    {
      const toastWithButton = this.$f7.toast.create({
        text: "No internet connection",
      });
  
      toastWithButton.open();
      
      return;
    }

    Promise.all([
          
      setTimeout(()=> {

        this.Dom7('.waiting').addClass('animate-bottom');

      }, 50)

    ])
    .then(() => {

      this.setState(()=>{
      
        return{
          campid:campid,
          campname:campname,
          statusid:statusid,
          statusname:statusname,
          groupid:groupid,
          groupname:groupname,
          loaded:false,
          isavailable:false,
          applyfilter:false,
          page:1,
        }

      },()=>{

        if((this.state.campname !== "" && this.state.campname !== undefined) || (this.state.groupname !== "" && this.state.groupname !== undefined) || (this.state.statusname !== "" && this.state.statusname !== undefined) > 0)
        {
          this.setState({hasfilter:true,hasallrecord:false});
        }
        else
        {
          this.setState({hasfilter:true,hasallrecord:true});
        }

        this.getAllCampaign();

      });
    })
  }

  deleteFilterActionCampaign(filtername){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.state.internetstatus === "online")
            {
              if(filtername === "campaign")
              {
                this.setState(()=>{
                  return{
                    campid:'',
                    campname:'',
                    hasfilter:true,
                    hasallrecord:true,
                    isavailable:false,
                    loaded:false,
                  }
                },()=>{

                  this.getAllCampaign();

                })
              }
              else if(filtername === "status")
              {
                this.setState(()=>{
                  return{
                    statusid:'',
                    statusname:'',
                    hasfilter:true,
                    hasallrecord:true,
                    isavailable:false,
                    loaded:false,
                  }
                },()=>{

                  this.getAllCampaign();

                })
              }
              else if(filtername === "group")
              {
                this.setState(()=>{
                  return{
                    groupid:'',
                    groupname:'',
                    hasfilter:true,
                    hasallrecord:true,
                    isavailable:false,
                    loaded:false,
                  }
                },()=>{

                  this.getAllCampaign();

                })
              }
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

  openFilterPopup(){

    if(this.state.internetstatus === "online")
    {
      this.$f7.views.main.router.navigate('filterpopup/');
    }
  }

  getCampaignSummary(){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
    
    if(clientdetail !== undefined && clientdetail !== null)
    {
        this.setState(()=>{
          return{

            clientdetail:clientdetail,
            loadedlead:false,
            isavailablelead:false,

          }
        },()=>{

          if(this.state.internetstatus === "offline")
          {
              const toastWithButton = this.$f7.toast.create({
                  text: "No internet connection",
              });
                
              toastWithButton.open();
              
              return;
          }
  
          if(Number(this.state.clientdetail.id) < 1 || this.state.clientdetail.id === undefined)
          {
            return;
          }
      
          const self      = this;
          const app       = self.$f7;

          let bodyFormData = {};

          if(Number(self.state.clientdetail.isstaff) > 0)
          {
            bodyFormData = {
                Mode:'GetCampaignSummary',
                clientid:self.state.clientdetail.id,
                staffid:self.state.clientdetail.staffid,
            };
          }
          else
          {
            bodyFormData = {
                Mode:'GetCampaignSummary',
                clientid:self.state.clientdetail.id,
            };
          }

          this.setState(()=>{
            return{
              issummaryloaded:false
            }
          },()=>{

              // Simulate Ajax Request
              setTimeout(()=> {
      
                const accesstoken  = localStorage.getItem('agency_accesstoken');
        
                app.request.setup({
                  headers:{
                    Authorization: `${accesstoken}`
                  }
                });
      
                app.request.promise.post(`${app.passedParams.serverurl}api/campaign.php?t=${Date.now()}`,bodyFormData)
                .then((res)=> {
      
                    // We got user data from request
            
                    // Hide Preloader
                    app.preloader.hide();
                    
                    // Hide Pull To Refresh
                    app.ptr.done();
      
                    const resdata = JSON.parse(res.data);
      
                    if(resdata.success === true)
                    {
                      this.setState(()=>{
                        return{
                          issummaryloaded:true,
                          isavailablesummary:true,
                          loaded:true,
                          totalschedulecampaign:resdata.totalcampaign,
                          campaignlistbystatus:resdata.recordlist,
                          campaignlistbystatus:resdata.recordlist,
                          hasinprocesscampaign:resdata.hasinprocesscampaign,
                          totalinprocesscampaign:resdata.totalinprocesscampaign,
                          isinsufficientcredit:resdata.isinsufficientcredit,
                          inprocessmsg:resdata.inprocessmsg,
                        }
                      },()=>{
                        setTimeout(()=> {
      
                          this.Dom7('.campaigndashboardelev').addClass('animate-bottom');
                          this.Dom7('.campaigndashboardlink').addClass('animate-bottom');
                  
                        }, 100);
                      });
                    }
                    else{
                      this.setState({
                        issummaryloaded:true,
                        isavailablesummary:false,
                        totalschedulecampaign:0,
                        campaignlistbystatus:[],
                        hasinprocesscampaign:false,
                        totalinprocesscampaign:0,
                        isinsufficientcredit:0,
                        inprocessmsg:"",
                      });
                    }
          
                }).catch(e => {
                    console.log(e);
                });
        
              }, 100);

          })

        });
    }
  }

  getSMSCredit(){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
    const accesstoken  = localStorage.getItem('agency_accesstoken');
    let adminid       = Number(localStorage.getItem('agency_adminid'));
    
    if((clientdetail !== undefined && clientdetail !== null) && (accesstoken !== undefined && accesstoken !== null))
    {
        this.setState(()=>{
          return{

            clientdetail:clientdetail,
            adminid:adminid,

          }
        },()=>{

          if(this.state.internetstatus === "offline")
          {
              const toastWithButton = this.$f7.toast.create({
                  text: "No internet connection",
              });
                
              toastWithButton.open();
              
              return;
          }
  
          if(Number(this.state.clientdetail.id) < 1 || this.state.clientdetail.id === undefined)
          {
            return;
          }
      
          const self      = this;
          const app       = self.$f7;

          const bodyFormData = {
              Mode:'GetSMSCredits',
              clientid:this.state.clientdetail.id,
          };
    
          // Simulate Ajax Request
          setTimeout(()=> {
  
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
  
            app.request.promise.post(`${app.passedParams.serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                const resdata = JSON.parse(res.data);
  
                if(resdata.success === true)
                {
                  this.setState(()=>{
                    return{
                      hascredit:true,
                      credits:resdata.credits,
                      clientname:resdata.clientname,
                      clientphone:resdata.clientphone,
                    }
                  });
                }
                else{
                  this.setState({
                    hascredit:true,
                    credits:0,
                  });
                }
      
            }).catch(e => {
                console.log(e);
            });
    
          }, 1500);            

        });
    }
    else
    {
      this.setState({
        fourcelogout:true
      });
    }
  }
  
  clearCampaignFilter(){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.state.internetstatus === "online")
            {
              this.setState(()=>{
                return{
                  statusid:'',
                  statusname:'',
                  campid:'',
                  campname:'',
                  groupid:'',
                  groupname:'',
                  hasfilter:true,
                  hasallrecord:true,
                  isavailable:false,
                  loaded:false,
                }
              },()=>{

                this.getAllCampaign();

              })
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

	render(){
		return (
			<CampaignContext.Provider value={{
			  ...this.state,
				init:this.init,
				setCampaignFilterData:this.setCampaignFilterData,
				deleteRecordAction:this.deleteRecordAction,
				deleteFilterActionCampaign:this.deleteFilterActionCampaign,
				manageCampaignAction:this.manageCampaignAction,
				handleUserInput:this.handleUserInput,
				Paging:this.Paging,
				openFilterPopup:this.openFilterPopup,
				getAllCampaign:this.getAllCampaign,
				getCampaignSummary:this.getCampaignSummary,
        getSMSCredit:this.getSMSCredit,
        clearCampaignFilter:this.clearCampaignFilter,
			}}
			>
			{this.props.children}
			</CampaignContext.Provider>
		);
	}
}

const CampaignConsumer = CampaignContext.Consumer;

export {CampaignProvider, CampaignConsumer, CampaignContext};