import React,{Component} from 'react';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
    ListInput,
    Toggle,
    Row,
    Col,
  } from 'framework7-react';

  export default class BillPrintingReportFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startyear = today.getFullYear();
      let endyear   = today.getFullYear();
      let startmonth = currmonth-1;
      
      if(startmonth < 1)
      {
        startmonth  = 12;
        startyear   = Number(startyear - 1);
      }

      let lastDay = new Date(endyear, currmonth, 0);

      let lastdate = lastDay.getDate();

      let startdate = startyear+'-'+(startmonth)+'-01';
      let enddate   = endyear+'-'+currmonth+'-'+lastdate;

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      let tempbillprintingdate = today.getFullYear()+'-'+currmonth+'-01';

      this.state = {
        clientdetail:[],
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'Select',
        isbillnumber:false,
        filterbillnumberfrom:'',
        filterbillnumberto:'',
        billnumberfrom:'',
        billnumberto:'',
        isdatefilter:false,
        billstartdate:startdate,
        billenddate:enddate,
        filterstartmonthyear:monthNames[Number(startmonth-1)]+' - '+startyear,
        filterendmonthyear:monthNames[Number(currmonth-1)]+' - '+endyear,
        arealist:[],
        linelist:[],
        linemanlist:[],
        hawkerlist:[],
        monthyearlist:[],
        hasbillmonthyear:false,
        issingledatefilter:false,
        billprintingdate:tempbillprintingdate,
        isborderprinting:false,
        filtervars:[],
      };

      this.areaAutocompletePopup     = this.areaAutocompletePopup.bind(this);
      this.lineAutocompletePopup     = this.lineAutocompletePopup.bind(this);
      this.linemanAutocompletePopup  = this.linemanAutocompletePopup.bind(this);
      this.hawkerAutocompletePopup   = this.hawkerAutocompletePopup.bind(this);
      this.applyFilter               = this.applyFilter.bind(this);
      this.handleUserInput           = this.handleUserInput.bind(this);
      this.checkSingleHawker         = this.checkSingleHawker.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{
        
        this.areaAutocompletePopup();
        this.lineAutocompletePopup();
        this.hawkerAutocompletePopup();

        this.hideSplashScreen();

        let{lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, billnumberfrom, billnumberto, filterbillnumberfrom, filterbillnumberto, isbillnumber, isdatefilter, billstartdate, billenddate, filterstartmonthyear, filterendmonthyear, issingledatefilter, billprintingdate, isborderprinting} = this.state.filtervars;

        if(Number(areaid) < 1 || areaid === "NaN")
        {
          areaid    = "";
          filterarea  = "Select";
        }

        if(Number(lineid) < 1 || lineid === "NaN")
        {
          lineid    = "";
          filterline  = "Select";
        }

        if(Number(linemanid) < 1 || linemanid === "NaN")
        {
          linemanid     = "";
          filterlineman = "Select";
        }

        if(Number(hawkerid) < 1 || hawkerid === "NaN")
        {
          hawkerid      = "";
          filterhawker  = "Select";
        }

        if(isbillnumber === "true")
        {
          isbillnumber  = true
        }

        if(isdatefilter === "true")
        {
          isdatefilter  = true
        }

        if(issingledatefilter === "true")
        {
          issingledatefilter  = true;

          if(billprintingdate !== "" && billprintingdate !== undefined && billprintingdate !== null)
          {
            billprintingdate  = billprintingdate;
          }
          
        }

        if(isborderprinting === "true")
        {
          isborderprinting  = true
        }

        this.setState({
          isbillnumber:Number(isbillnumber),
          billnumberfrom:billnumberfrom,
          billnumberto:billnumberto,
          isdatefilter:Number(isdatefilter),
          filterbillnumberfrom:filterbillnumberfrom,
          filterbillnumberto:filterbillnumberto,
          areaid:areaid,
          filterarea:filterarea,
          lineid:lineid,
          filterline:filterline,
          linemanid:linemanid,
          filterlineman:filterlineman,
          hawkerid:hawkerid,
          filterhawker:filterhawker,
          billstartdate:billstartdate,
          billenddate:billenddate,
          filterstartmonthyear:filterstartmonthyear,
          filterendmonthyear:filterendmonthyear,
          issingledatefilter:Number(issingledatefilter),
          billprintingdate:billprintingdate,
          isborderprinting:Number(isborderprinting),
        })
        
      });
    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;

      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();
      self.linemanAutocomplete.destroy();
      self.hawkerAutocomplete.destroy();

      if(this.state.hasbillmonthyear !== true)
      {
        this.autocompleteStartMonthYearPopup.destroy();
        this.autocompleteEndMonthYearPopup.destroy();
      }

    }

    startMonthYearAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteStartMonthYearPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-startmonthyear-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectmonthyear/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetReportMonthYear',
              clientid:self.state.clientdetail.id,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    monthyearlist:res.recordset,
                  }
                },()=>{

                  const searchmonthyearlist  = self.state.monthyearlist;

                  for (let i = 0; i < searchmonthyearlist.length; i += 1) {
                    if (searchmonthyearlist[i].monthname.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchmonthyearlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchmonthyearlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            let month       = Number(value[0].month);
            let year        = Number(value[0].year);

            self.setState({
                billstartdate:month+'/01/'+year,
                filterstartmonthyear:value[0].monthname+" - "+value[0].year
            });
            
          },
        },
      });
    }

    endMonthYearAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteEndMonthYearPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-endmonthyear-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectendmonthyear/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetReportMonthYear',
              clientid:self.state.clientdetail.id,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    monthyearlist:res.recordset,
                  }
                },()=>{

                  const searchmonthyearlist  = self.state.monthyearlist;

                  for (let i = 0; i < searchmonthyearlist.length; i += 1) {
                    if (searchmonthyearlist[i].monthname.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchmonthyearlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchmonthyearlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            let month      = Number(value[0].month);
            let year       = Number(value[0].year);
            let lastdate   = value[0].lastdate;

            self.setState({
                billenddate:month+"/"+lastdate+"/"+year,
                filterendmonthyear:value[0].monthname+" - "+value[0].year
            });
            
          },
        },
      });
    }

    areaAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }
              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-area-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              areaid:id,
              filterarea:name
            });            
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-line-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              lineid:id,
              filterline:name
            },()=>{
              self.checkSingleHawker();
            });

          },
        },
      });
    }

    linemanAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      const searchlinemanlist  = this.state.linemanlist;

      self.linemanAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-lineman-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectlineman/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinemanlist.length; i += 1) {
            if (searchlinemanlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinemanlist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            $('#autocomplete-lineman-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              linemanid:id,
              filterlineman:name
            });

          },
        },
      });
    }

    hawkerAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      self.hawkerAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-hawker-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selecthawker/',
        requestSourceOnOpen:true,
        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetHawker',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    hawkerlist:res.recordlist,
                  }
                },()=>{

                  const searchhawkerlist  = self.state.hawkerlist;

                  for (let i = 0; i < searchhawkerlist.length; i += 1) {
                    if (searchhawkerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchhawkerlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            /*$('#autocomplete-hawker-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              hawkerid:id,
              filterhawker:name
            });

          },
        },
      });
    }

    applyFilter(){

      if(!this.$f7.online)
      {
        return;
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      if(name === 'isbillnumber')
      {
        this.setState({isbillnumber:!this.state.isbillnumber});
      }
      else if(name === 'issingledatefilter')
      {
        this.setState({issingledatefilter:!this.state.issingledatefilter});
      }
      else if(name === 'isborderprinting')
      {
        this.setState({isborderprinting:!this.state.isborderprinting});
      }
     else if(name === 'isdatefilter')
      {
        this.setState({isdatefilter:!this.state.isdatefilter},()=>{
          if(this.state.isdatefilter === true)
          {
            this.setState({hasbillmonthyear:true});

            this.startMonthYearAutocompletePopup();
            this.endMonthYearAutocompletePopup();
          }
          else
          {
            if(this.state.hasbillmonthyear === true)
            {
              this.setState({hasbillmonthyear:false});
              this.autocompleteStartMonthYearPopup.destroy();
              this.autocompleteEndMonthYearPopup.destroy();
            }
          }
        });
      }
      else 
      { 
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
      if(name =='billnumberfrom')
      {
        this.setState(()=>{
            return{
                filterbillnumberfrom: value
            }
          }); 
      }
      if(name =='billnumberto')
      {
        this.setState(()=>{
            return{
                filterbillnumberto: value
            }
        });
      }
    }

    checkSingleHawker(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
        Mode:'CheckHawker',
        type:'sequencefilter',
        clientid:self.state.clientdetail.id,
        areaid:self.state.areaid,
        lineid:self.state.lineid,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({

                hawkerid:resdata.recordlist.id,
                filterhawker:resdata.recordlist.name,
                /*hascustomer:false,
                isfetched:false,*/

              });
            }
            else
            {
              this.setState({

                hawkerid:'',
                filterhawker:'Select',

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }    

    render(){

      const{lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, filterbillnumberfrom, filterbillnumberto, billnumberfrom, billnumberto, isbillnumber, isdatefilter, billstartdate, billenddate, filterstartmonthyear, filterendmonthyear, issingledatefilter, billprintingdate, isborderprinting} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{applyBillPrintingFilter}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight style={{display:'none'}}>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="BillPrintingReportFilter">
                        <Block>
                        <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                          <ListItem link="#" id="autocomplete-area-popup" title="Area" after="Select"></ListItem>
                          <ListItem link="#" id="autocomplete-line-popup" title="Line" after="Select"></ListItem>
                          <ListItem link="#" id="autocomplete-hawker-popup" title="Hawker" after={filterhawker}></ListItem>
                          <ListItem title="Search By Bill Number">
                            <Toggle slot="after" value="1" name="isbillnumber" onChange={this.handleUserInput} checked={Number(this.state.isbillnumber) === 1}></Toggle>
                          </ListItem>
                        </List>  
                          { isbillnumber ? (
                           <Row>
                            <Col>
                              <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListInput
                                      name="billnumberfrom"
                                      id="billnumberfrom"
                                      label="Bill Start Number"
                                      placeholder="Bill Start Number"
                                      type="text"
                                      value={billnumberfrom}
                                      onChange={this.handleUserInput}
                                  >
                                  </ListInput>
                                  <ListInput
                                      name="billnumberto"
                                      id="billnumberto"
                                      label="Bill End Number"
                                      placeholder="Bill End Number"
                                      type="text"
                                      value={billnumberto}
                                      onChange={this.handleUserInput}
                                  >
                                  </ListInput>
                              </List>    
                            </Col>    
                          </Row>  
                          ):null
                          }
                         {/*<List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                          <ListItem title="Search By Bill Month">
                            <Toggle slot="after" value="1" name="isdatefilter" onChange={this.handleUserInput} checked={Number(this.state.isdatefilter) === 1}></Toggle>
                          </ListItem>
                        </List>
                          { isdatefilter ? (
                           <Row>
                            <Col>
                              <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListItem link="#" id="autocomplete-startmonthyear-popup" title="From Month Year" after={filterstartmonthyear}></ListItem>
                                <ListItem link="#" id="autocomplete-endmonthyear-popup" title="End Month Year" after={filterendmonthyear}></ListItem>
                              </List>    
                            </Col>    
                          </Row>  
                          ):null
                          }*/}
                        <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                          <ListItem title="Search By Bill Date">
                            <Toggle slot="after" value="1" name="issingledatefilter" onChange={this.handleUserInput} checked={Number(issingledatefilter) === 1}></Toggle>
                          </ListItem>
                          {
                            Number(issingledatefilter) === 1 ? (
                              <ListInput
                                name="billprintingdate"
                                id="billprintingdate"
                                label="Bill Date"
                                placeholder="Bill Date"
                                type="date"
                                value={billprintingdate}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                              >
                              </ListInput>
                            ):null
                          }
                          <ListItem title="Print Bill With Border">
                            <Toggle slot="after" value="1" name="isborderprinting" onChange={this.handleUserInput} checked={Number(isborderprinting) === 1}></Toggle>
                          </ListItem>
                        </List>
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                            const self      = this;
                            const app       = self.$f7;

                            /*if(Number(this.state.lineid) < 1 && Number(this.state.linemanid) < 1 && Number(this.state.hawkerid) < 1 && Number(this.state.areaid) < 1 && ((this.state.isbillnumber == true && Number(this.state.billnumberfrom) < 1 && Number(this.state.billnumberto < 1)) || this.state.isbillnumber == false) && this.state.isdatefilter == false && this.state.issingledatefilter == false)
                            if(Number(lineid) < 1 && Number(linemanid) < 1 && Number(hawkerid) < 1 && Number(areaid) < 1 && (isbillnumber === false || (isbillnumber === true && (Number(billnumberfrom) < 1 || Number(billnumberto) < 1 ))) && (issingledatefilter === false || (issingledatefilter === true && billprintingdate === "")))*/

                            let errmsg = "";

                            if((!isbillnumber && !issingledatefilter) && errmsg === "")
                            {
                              errmsg  = "Please select search by bill number or search by bill date filter for bill printing.";
                            }

                            if((isbillnumber && (Number(billnumberfrom) < 1 || Number(billnumberto) < 1)) && errmsg === "")
                            {
                              errmsg  = "Please enter bill start and bill end number for bill printing.";
                            }

                            if((issingledatefilter && billprintingdate === "") && errmsg === "")
                            {
                              errmsg  = "Please select bill date for bill printing.";
                            }

                            if(errmsg !== "")
                            {
                              const toastWithButton = app.toast.create({
                                text: errmsg,
                                closeButton: true,
                                closeTimeout: 3000,
                              });
                                
                              toastWithButton.open();
                            }
                            else{
                                Promise.all([
                                setTimeout(()=> {

                                  applyBillPrintingFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, billnumberfrom,billnumberto,filterbillnumberfrom,filterbillnumberto,isbillnumber,isdatefilter, billstartdate, billenddate, filterstartmonthyear, filterendmonthyear, issingledatefilter, billprintingdate, isborderprinting);

                                }, 500)
                            ])
                            .then(() => {
                
                                setTimeout(()=> {
                
                                this.$$('.popup-close').trigger('click');
                    
                                }, 500)
                              })
                            }
                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}