import React,{Component,Fragment} from 'react';
import { CampaignConsumer } from '../context/CampaignContext';
import AppItemNotFound from '../pages/appitemnotfound';
import AppCardItemLoading from '../pages/appcarditemloading';
import AppFav from '../pages/appfav';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    BlockTitle,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    List,
    ListInput,
    Toolbar,
    Block,
    Chip,
    Fab,
  } from 'framework7-react';

  export default class Messages extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        iscampaignloaded:false,
      };
    }

    render(){

        return(
            <CampaignConsumer>{(value)=>{

              const{handleUserInput, deleteRecordAction, deleteFilterActionCampaign, openFilterPopup, Paging, manageCampaignAction, init, clearCampaignFilter} = value;

              const{isavailable, campaigns, paginglist, showpages, totalrecord, page, totalpages, loaded,hasfilter, hasallrecord, effect, toolbarPosition, statusname, campname, groupname} = value;

                setTimeout(() => {
                  if(this.state.iscampaignloaded === false)
                  {
                    this.setState(()=>{
                      return{
                        iscampaignloaded:true
                      }
                    },()=>{

                      init(this.props.f7route.params.statusid, this.props.f7route.params.statusname);

                    })
                    
                  }
                }, 100);

                return(
                    <Page className="page" name="Campaigns">
                      <Navbar sliding={true}>
                        <NavLeft>
                          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                          <Link href={`/dashboard/`} className="clearmenupadding">
                            <img className="navbarlogo" src={scanlogo} alt="logo" />
                          </Link>
                        </NavLeft>
                        <NavTitle>Message</NavTitle>
                        <NavRight className="right-with-menu">
                        <Link onClick={()=>{

                          Promise.all([
                
                            setTimeout(()=> {
                  
                              this.Dom7('.waiting').addClass('animate-bottom');
                  
                            }, 50)
                  
                          ])
                          .then(() => {
                            
                            init();
                  
                          });

                        }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                        </NavRight>
                      </Navbar>
                        {
                          showpages ? (
                            <Toolbar position={toolbarPosition}>
                            {
                              (()=>{
                                if(page > 1)
                                {
                                  return(
                                    <Link className="paging" onClick={()=>{Paging('previous')}}>
                                      <Icon f7="arrow_left_square_fill"></Icon>
                                    </Link>
                                  );
                                }
                                else
                                {
                                  return(
                                    <Link href="#"></Link>
                                  );
                                }
                              })()}
                              <List className="droppage" noHairlines>
                                  <ListInput
                                      name="page"
                                      type="select"
                                      value={page}
                                      onChange={handleUserInput}
                                      placeholder="Please choose..."
                                      >
                                      {
                                        paginglist.map((pages)=>{
                                          return(<option key={pages.page} value={pages.page}>{pages.name}</option>);
                                        })
                                      }
                                  </ListInput>
                              </List>
                              {
                                (()=>{
                                  if(page < totalpages)
                                  {
                                    return(
                                      <Link className="paging" onClick={()=>{Paging('next')}}>
                                      <Icon f7="arrow_right_square_fill" ></Icon>
                                      </Link>
                                    );
                                  }
                                  else
                                  {
                                    return(
                                      <Link href="#"></Link>
                                    );
                                  }
                                })()
                              }
                            </Toolbar>
                          ):null
                        }
                        {
                          loaded ? (<Fragment>
                              {
                                isavailable ? (<BlockTitle className="totalrec">Total campaigns : {totalrecord}</BlockTitle>):null
                              }
                              {/*
                                hasfilter ? (
                                  <Block strong className="selectedfilter" style={{marginTop:0}}>
                                    {hasallrecord ? (<Chip text={`Campaign : All`} mediaBgColor="blue" media="A"/>):null}

                                    {campname ? (<Chip text={`Campaign : ${campname}`} mediaBgColor="orange" media={campname.toUpperCase().substring(0, 1)} deleteable 
                                    onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                              deleteFilterActionCampaign('campaign');
                                            }
                                          }}
                                    />):null}
                                    {statusname ? (<Chip text={`Status : ${statusname}`} mediaBgColor="pink" media={statusname.toUpperCase().substring(0, 1)} deleteable 
                                    onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                              deleteFilterActionCampaign('status');
                                            }
                                          }}
                                    />):null}
                                    {groupname ? (<Chip text={`Campaign Group : ${groupname}`} mediaBgColor="green" media={groupname.toUpperCase().substring(0, 1)} deleteable 
                                    onClick={()=>{
                                            if(internetstatus === "online")
                                            {
                                              deleteFilterActionCampaign('group');
                                            }
                                          }}
                                    />):null}
                                    &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                      openFilterPopup();
                                    }}/>
                                    &nbsp;
                                    <Chip text="Clear Filter" color="blue" onClick={()=>{
                                      clearCampaignFilter();
                                    }}/>
                                  </Block>
                                ):null
                              */}
                              {
                                isavailable ? (
                                  <div className="search-list searchbar-found">
                                  {
                                      campaigns.map((campaign)=>{
                                          return(
                                          <div key={campaign.id}>
                                          <Card outline className="recordset">
                                              <CardHeader
                                              className="date"><div className="item-title">Message # {campaign.id}</div><div className="item-title"></div></CardHeader>
                                              <CardContent>
                                              {campaign.campaigndate ? (<p><b>Message Added : </b>{campaign.campaigndate}</p>):null}
                                              {campaign.scheduleddate ? (<p><b>Sending Date : </b>{campaign.scheduleddate}</p>):null}
                                              {campaign.completeddate ? (<p><b>Completed Date : </b>{campaign.completeddate}</p>):null}
                                              {campaign.totalmessages? (<p><b>Total Messages : </b>{campaign.totalmessages} {Number(campaign.isdataprocessed) < 1 ? ( `(tentative)` ) : null }</p>):null}
                                              {(campaign.totalsent && Number(campaign.isdataprocessed) > 0) ? (<p><b>Total Sent : </b>{campaign.totalsent}</p>):null}
                                              {(campaign.totaldelivered && Number(campaign.isdataprocessed) > 0)  ? (<p><b>Total Delivered : </b>{campaign.totaldelivered}</p>):null}
                                              {campaign.completed ? (<p><b>Status : </b>{campaign.completed}</p>):null}
                                              {campaign.messagetext ? (<p><b>Message : </b>{campaign.messagetext}</p>):null}
                                              </CardContent>
                                              <CardFooter>
                                                <Link onClick={()=>{
                                                  manageCampaignAction(Number(campaign.id), Number(campaign.status), campaign.completed,campaign.totalsent);
                                                }}
                                                  ignoreCache={true}>Manage</Link>
                                                  <Link onClick={() =>{
                                                    if(Number(campaign.status) < 1 || Number(campaign.status) === 3)
                                                    { 
                                                      /*if(internetstatus === "online")
                                                      {
                                                        deleteRecordAction(campaign.id);
                                                      }*/
                                                      deleteRecordAction(campaign.id);
                                                    }
                                                    else
                                                    {
                                                      const self      = this;
                                                      const app       = self.$f7;
                                                    
                                                        const toastWithButton = app.toast.create({
                                                            text: "This campaign is alaready "+campaign.completed+" state, hence cannot be deleted.",
                                                            closeButton: true,
                                                            closeTimeout: 3000,
                                                        });
                                                          
                                                        toastWithButton.open();
                                                      }
                                                  }}>Delete</Link>
                                                      
                                                </CardFooter>
                                          </Card>
                                          </div>
                                          );
                                      })
                                  }
                                  <br /><br /><br />
                              </div>
                              ):(<AppItemNotFound notfoundtext ="No message found." />)
                              }
                              </Fragment>):(<AppCardItemLoading />)
                            }
                        {
                          loaded ? (
                            <Fab position="center-bottom" slot="fixed" text="Add Message" onClick={()=>{
                              if(this.$f7.online)
                              {
                                this.$f7.views.main.router.navigate('/sendmessage/');
                              }
                              
                            }}>
                              <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
                            </Fab>
                          ):null
                        }
                    </Page>
                );
            }}
            </CampaignConsumer>            
        );
    }
}