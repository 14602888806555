import React,{Component} from 'react';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
    ListInput,
  } from 'framework7-react';

  export default class CustomerLedgerReportFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startdate = today.getFullYear()+'-'+(currmonth - 1)+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+currday;
  
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        areaid:'',
        lineid:'',
        customerid:'',
        filterarea:'Select',
        filterline:'Select',
        customername:'Select',
        arealist:[],
        linelist:[],
        customerlist:[],
        hassmartcustomer:false,
        hasdatefilter:true,
        customerphone:'',
        filtervars:[],
      };

      this.customerAutocompletePopup = this.customerAutocompletePopup.bind(this);
      this.handleUserInput           = this.handleUserInput.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{

        this.areaAutocompletePopup();
        this.lineAutocompletePopup();
        this.customerAutocompletePopup();
        this.hideSplashScreen();

        let{lineid, hawkerid, filterline, filterhawker, areaid, filterarea, monthyear, startdate, enddate, customerphone, customerid, customername} = this.state.filtervars;

        if(Number(areaid) < 1 || areaid === "NaN")
        {
          areaid      = "";
          filterarea  = "Select";
        }

        if(Number(lineid) < 1 || lineid === "NaN")
        {
          lineid      = "";
          filterline  = "Select";
        }
        
        if(Number(hawkerid) < 1 || hawkerid === "NaN")
        {
          hawkerid      = "";
          filterhawker  = "Select";
        }

        if(Number(customerid) < 1 || customerid === "NaN" || customerid === "undefined")
        {
          customerid    = "";
          customername  = "Select";
        }

        this.setState({
          areaid:areaid,
          lineid:lineid,
          hawkerid:hawkerid,
          filterarea:filterarea,
          filterline:filterline,
          filterhawker:filterhawker,
          monthyear:monthyear,
          startdate:startdate,
          enddate:enddate,
          customerphone:customerphone,
          customerid:customerid,
          customername:customername,
        },()=>{
          /*this.$$('#autocomplete-startmonthyear-name2').find('.item-after').text(this.state.filtermonthyear);
          this.$$('#autocomplete-area-popup').find('.item-after').text(this.state.filterarea);
          this.$$('#autocomplete-line-popup').find('.item-after').text(this.state.filterline);*/
        })

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();
      self.autocompleteCustomerPopup.destroy();

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'hasdatefilter')
      {
        this.setState({hasdatefilter:!this.state.hasdatefilter});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }

    }

    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "Select";
            }

            self.setState({
              areaid:id,
              lineid:'',
              customerid:'',
              filterarea:name,
              filterline:'Select',
              customername:'Select',
              linelist:[],
              customerlist:[],
            });
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "Select";
            }

            self.setState({
              lineid:id,
              customerid:'',
              filterline:name,
              customername:'Select',
              customerlist:[],
            });

          },
        },
      });
    }

    customerAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteCustomerPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-customer-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectcustomer/',
        requestSourceOnOpen:true,
        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetAllSubscribeCustomer',
              type:'subscribecustomer',
              ordertype:'sequence',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    customerlist:res.recordlist,
                  }
                },()=>{

                  const searchcustomerlist  = self.state.customerlist;

                  for (let i = 0; i < searchcustomerlist.length; i += 1) {
                    if (searchcustomerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchcustomerlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            self.setState(()=>{
              return{
                customerid:value[0].id,
                customername:value[0].name,
              }
            })

          },
        },
      });
    }

    render(){

      const{customerid, areaid, filterarea, lineid, filterline, customername, startdate, enddate, hasdatefilter, customerphone} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{applyCustomerLedgerFilter}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="CustomerLedgerReportFilter">
                    <Block>
                        <List noHairlinesMd>
                          {/*<ListItem title="Search By Date">
                            <Toggle slot="after" value="1" name="hasdatefilter" onChange={this.handleUserInput} checked={Number(hasdatefilter) === 1}></Toggle>
                          </ListItem>*/}
                          {
                            Number(hasdatefilter) === 1 ? (
                            <ListInput
                                name="startdate"
                                id="startdate"
                                label="Start Date"
                                placeholder="Start Date"
                                type="date"
                                value={startdate}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                            ):null
                          }
                          {
                            Number(hasdatefilter) === 1 ? (
                            <ListInput
                                name="enddate"
                                id="enddate"
                                label="End Date"
                                placeholder="End Date"
                                type="date"
                                value={enddate}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                            ):null
                          }
                          <ListItem link="#" id="autocomplete-area-popup" title="Area" after={filterarea}></ListItem>
                          <ListItem link="#" id="autocomplete-line-popup" title="Line" after={filterline}></ListItem>
                          <ListItem link="#" id="autocomplete-customer-popup" title="Customer" after={customername}></ListItem>
                          <ListInput
                              name="customerphone"
                              label="Mobile"
                              floatingLabel
                              type="tel"
                              onInput={(e)=>{
                            
                                let tempphone = e.target.value;
                                let mobile    = tempphone.replace(/ /g,'');
                                
                                if(tempphone.slice(0,1)=='+')
                                {
                                  mobile = mobile.substring(3);
                                }
    
                                if(tempphone.slice(0,1)=='0')
                                {
                                  mobile = mobile.substring(1);
                                }
    
                                if(mobile.length > 10)
                                {
                                  return;
                                }
                                
                                this.setState({
                                  customerphone:mobile
                                })
                              }}
                              placeholder="Mobile Number"
                              value={customerphone}
                              clearButton validate pattern="[0-9]*"
                          >
                          </ListInput>
                        </List>
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                          Promise.all([
                            setTimeout(()=> {
              
                                applyCustomerLedgerFilter(customerid, customername, startdate, enddate, areaid, lineid, filterarea, filterline, hasdatefilter, customerphone);
                  
                            }, 500)
                            
                          ])
                          .then(() => {
              
                            setTimeout(()=> {
              
                              this.$$('.popup-close').trigger('click');
                  
                            }, 500)
                            
                          })

                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}