import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Block,
    Row,
    Col,
    Button,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton
  } from 'framework7-react';

  export default class BillPrintingReport extends Component {
    constructor(props) {
      super(props);
      
      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startyear = today.getFullYear();
      let endyear   = today.getFullYear();
      let startmonth = currmonth-1;
      
      if(startmonth < 1)
      {
        startmonth  = 12;
        startyear   = Number(startyear - 1);
      }

      let lastDay = new Date(endyear, currmonth, 0);

      let lastdate = lastDay.getDate();

      let startdate = startyear+'-'+(startmonth)+'-01';
      let enddate   = endyear+'-'+currmonth+'-'+lastdate;

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      let tempbillprintingdate = today.getFullYear()+'-'+currmonth+'-01';

      this.state = {
        clientdetail:[],
        customerlist:[],
        billinvoicedata:[],
        isptr:false,
        isbillnumber:'',
        billnumberfrom:'',
        billnumberto:'',
        isdatefilter:'',
        billstartdate:startdate,
        billenddate:enddate,
        filterstartmonthyear:monthNames[Number(startmonth-1)]+' - '+startyear,
        filterendmonthyear:monthNames[Number(currmonth-1)]+' - '+endyear,
        ismount:false,
        hasallcustomer:false,
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        filterbillnumberfrom:'',
        filterbillnumberto:'',
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        hasinvoices:false,
        issingledatefilter:false,
        billprintingdate:tempbillprintingdate,
        isborderprinting:true,
        effect:'blink',
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getBillPrintableData = this.getBillPrintableData.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);
        this.openFilterPopup();
        //this.getBillPrintableData();
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getBillPrintableData(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
          Mode:'GetBillPrintableData',
          clientid:self.state.clientdetail.id,
          isbillnumber:self.state.isbillnumber,
          billnumberfrom:self.state.billnumberfrom,
          billnumberto:self.state.billnumberto,
          isdatefilter:self.state.isdatefilter,
          billstartdate:self.state.billstartdate,
          billenddate:self.state.billenddate,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          lineid:self.state.lineid,
          linemanid:self.state.linemanid,
          hawkerid:self.state.hawkerid,
          areaid:self.state.areaid,
          issingledatefilter:Number(self.state.issingledatefilter),
          billprintingdate:self.state.billprintingdate,
          isborderprinting:Number(self.state.isborderprinting),
        };

        this.setState(()=>{

          return{
            isdatafetched:false
          }

        },()=>{

          setTimeout(()=> {
  
            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
    
            app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                let hasinvoices = false;

                if(Number(resdata.billinvoicedata.totalcount) > 0)
                {
                  hasinvoices = true;
                }

                this.setState(()=>{
                  return{
                    billinvoicedata:resdata.billinvoicedata,
                    isptr:false,
                    isdatafetched:true,
                    hasinvoices:hasinvoices
                  }
                })
              }
              else
              {
                this.setState(()=>{
                  return{
                    isptr:false,
                    isdatafetched:true,
                    hasinvoices:false
                  }
                });
              }
      
            }).catch(e => {
                console.log(e);
            });
  
          },500);

        });
    }

    setNewFilterData(lineid, linemanid, hawkerid,filterline, filterlineman, filterhawker, areaid, filterarea, billnumberfrom,billnumberto,filterbillnumberfrom,filterbillnumberto,isbillnumber,isdatefilter, billstartdate, billenddate, filterstartmonthyear, filterendmonthyear, issingledatefilter, billprintingdate, isborderprinting){
      
      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            lineid:lineid,
            linemanid:linemanid,
            hawkerid:hawkerid,
            filterarea:filterarea,
            filterline:filterline,
            filterlineman:filterlineman,
            filterhawker:filterhawker,
            isbillnumber:isbillnumber,
            billnumberfrom:billnumberfrom,
            billnumberto:billnumberto,
            isdatefilter:isdatefilter,
            billstartdate:billstartdate,
            billenddate:billenddate,
            filterstartmonthyear:filterstartmonthyear,
            filterendmonthyear:filterendmonthyear,
            filterbillnumberfrom:filterbillnumberfrom,
            filterbillnumberto:filterbillnumberto,
            issingledatefilter:issingledatefilter,
            billprintingdate:billprintingdate,
            isborderprinting:isborderprinting,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
          }
  
        },()=>{
          if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || Number(this.state.billnumberfrom) > 0 || Number(this.state.billnumberto > 0) || Number(this.state.isbillnumber) > 0 || Number(this.state.isdatefilter) > 0)
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getBillPrintableData();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      let{lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, billnumberfrom, billnumberto, filterbillnumberfrom, filterbillnumberto, isbillnumber, isdatefilter, billstartdate, billenddate, filterstartmonthyear, filterendmonthyear, issingledatefilter, billprintingdate, isborderprinting} = this.state;

      this.$f7router.navigate(`filterpopup/?lineid=${lineid}&linemanid=${linemanid}&hawkerid=${hawkerid}&filterline=${filterline}&filterlineman=${filterlineman}&filterhawker=${filterhawker}&areaid=${areaid}&filterarea=${filterarea}&billnumberfrom=${billnumberfrom}&billnumberto=${billnumberto}&filterbillnumberfrom=${filterbillnumberfrom}&filterbillnumberto=${filterbillnumberto}&isbillnumber=${isbillnumber}&isdatefilter=${isdatefilter}&billstartdate=${billstartdate}&billenddate=${billenddate}&filterstartmonthyear=${filterstartmonthyear}&filterendmonthyear=${filterendmonthyear}&issingledatefilter=${issingledatefilter}&billprintingdate=${billprintingdate}&isborderprinting=${isborderprinting}`);
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }
    
    getPrintableInvoice(printingtype=''){

      const self      = this;
      const app       = self.$f7;
 
      /*const bodyFormData = {
          Mode:'GetInvoicePDF',
          clientid:self.state.clientdetail.id,
          invoiceid:self.state.orderid,
      };*/
      let tempurl = "?&clientid="+self.state.clientdetail.id;
      tempurl += "&areaid="+self.state.areaid;
      tempurl += "&lineid="+self.state.lineid;
      tempurl += "&linemanid="+self.state.linemanid;
      tempurl += "&hawkerid="+self.state.hawkerid;
      tempurl += "&isbillnumber="+self.state.isbillnumber;
      tempurl += "&billnumberfrom="+self.state.billnumberfrom;
      tempurl += "&billnumberto="+self.state.billnumberto;
      tempurl += "&isdatefilter="+self.state.isdatefilter;
      tempurl += "&billstartdate="+self.state.billstartdate;
      tempurl += "&billenddate="+self.state.billenddate;
      tempurl += "&issingledatefilter="+Number(self.state.issingledatefilter);
      tempurl += "&billprintingdate="+self.state.billprintingdate;
      tempurl += "&isborderprinting="+Number(self.state.isborderprinting);
      tempurl += "&printingtype="+printingtype;
      tempurl += "&bulkprinting=1";
     
      /*if(app.device.desktop == false && printingtype != 'pdf')
      {
          const toastWithButton = app.toast.create({
            text: "This functionality is not available on mobile phone.",
            closeButton: true,
            closeTimeout: 3000,
        });
          
        toastWithButton.open();
      }
      else
      {*/
        if(printingtype == 'pdf')
        {
          window.open(app.passedParams.serverurl+"api/download_pdf_invoice.php"+tempurl); 
        }
        else if(printingtype == 'dotmatrix')
        {
          window.open(app.passedParams.serverurl+"api/viewinvoice_dotmatrix.php"+tempurl); 
        }
        else
        {
          window.open(app.passedParams.serverurl+"api/viewinvoice.php"+tempurl); 
        }
      /*}*/
    }
    
    render(){
        
      const{billinvoicedata, hasallcustomer, areaid, lineid, linemanid, hawkerid, hasfilter, filterarea, filterline, filterlineman, filterhawker, removefiltername, applyremovefilter, ismount, isdatafetched, filterbillnumberfrom, filterbillnumberto, hasinvoices, isbillnumber, isdatefilter,billstartdate, billenddate, filterstartmonthyear, filterendmonthyear, billnumberfrom, billnumberto, issingledatefilter, billprintingdate, isborderprinting} = this.state;

      return(
        <Page className="page" name="BillPrintingReport">
          <Navbar sliding={true}>
            <NavLeft>
              <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
              <Link href={`/dashboard/`} className="clearmenupadding">
                <img className="navbarlogo" src={scanlogo} alt="logo" />
              </Link>
            </NavLeft>
            <NavTitle>Bill Printing</NavTitle>
            <NavRight className="right-with-menu">
            <Link onClick={()=>{

              this.getBillPrintableData();

            }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
            </NavRight>
          </Navbar>
          <FilterConsumer>{(value)=>{

            const{billprintfilterdata, applyBillPrintingFilter} = value;
            if(ismount === true)
            {
              setTimeout(()=> {
                if(
                  ((billprintfilterdata.lineid !== this.state.lineid) && billprintfilterdata.lineid !== undefined) ||
                  ((billprintfilterdata.linemanid !== this.state.linemanid) && billprintfilterdata.linemanid !== undefined) ||
                  ((billprintfilterdata.hawkerid !== this.state.hawkerid) && billprintfilterdata.hawkerid !== undefined) ||
                  ((billprintfilterdata.areaid !== this.state.areaid) && billprintfilterdata.areaid !== undefined) ||
                  ((billprintfilterdata.billnumberfrom !== this.state.billnumberfrom) && billprintfilterdata.billnumberfrom !== undefined) ||
                ((billprintfilterdata.billnumberto !== this.state.billnumberto) && billprintfilterdata.billnumberto !== undefined) || ((billprintfilterdata.isbillnumber !== this.state.isbillnumber) && billprintfilterdata.isbillnumber !== undefined) || ((billprintfilterdata.isdatefilter !== this.state.isdatefilter) && billprintfilterdata.isdatefilter !== undefined && ((billprintfilterdata.billstartdate !== billstartdate) || (billprintfilterdata.billenddate !== billenddate))) || ((billprintfilterdata.issingledatefilter !== this.state.issingledatefilter) && billprintfilterdata.issingledatefilter !== undefined && (billprintfilterdata.billprintingdate !== billprintingdate)) || ((billprintfilterdata.isborderprinting !== this.state.isborderprinting) && billprintfilterdata.isborderprinting !== undefined) || this.state.applyremovefilter === true)
                {
                  this.setNewFilterData(billprintfilterdata.lineid, billprintfilterdata.linemanid, billprintfilterdata.hawkerid, billprintfilterdata.filterline, billprintfilterdata.filterlineman, billprintfilterdata.filterhawker, billprintfilterdata.areaid, billprintfilterdata.filterarea, billprintfilterdata.billnumberfrom, billprintfilterdata.billnumberto,billprintfilterdata.filterbillnumberfrom,billprintfilterdata.filterbillnumberto,billprintfilterdata.isbillnumber,billprintfilterdata.isdatefilter,billprintfilterdata.billstartdate,billprintfilterdata.billenddate,billprintfilterdata.filterstartmonthyear,billprintfilterdata.filterendmonthyear,billprintfilterdata.issingledatefilter,billprintfilterdata.billprintingdate,billprintfilterdata.isborderprinting);

                  this.setState({
                    removefiltername:'',
                    applyremovefilter:false
                  })

                }

              }, 500)
            }

            return(
                <Block>
                  {
                    hasfilter ? (
                      <Block strong className="selectedfilter" style={{marginTop:0}}>
                      {filterbillnumberfrom ? (<Chip text={`Bill Start Number : ${filterbillnumberfrom}`} mediaBgColor="orange" media='S' 
                        onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.setState(()=>{
                                    return {
                                      removefiltername:'billnumberfrom'
                                    }
                                  },()=>{
                                    this.refs.actionsFilterGroup.open()
                                  })
                                }
                              }}
                        />):null}
                        {filterbillnumberto ? (<Chip text={`Bill End Number : ${filterbillnumberto}`} mediaBgColor="orange" media='E' 
                        onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.setState(()=>{
                                    return {
                                      removefiltername:'billnumberto'
                                    }
                                  },()=>{
                                    this.refs.actionsFilterGroup.open()
                                  })
                                }
                              }}
                        />):null}
                        {
                          isdatefilter && filterstartmonthyear !== "" && filterstartmonthyear !== "" ?(<Chip text={`Month Range : (${filterstartmonthyear}) - (${filterendmonthyear})`} mediaBgColor="yellow" media={`D`} />):null
                        }
                        {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)}
                        onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.setState(()=>{
                                    return {
                                      removefiltername:'area'
                                    }
                                  },()=>{
                                    this.refs.actionsFilterGroup.open()
                                  })
                                }
                              }}
                        />):null}
                        {Number(lineid) > 0 && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="pink" media={filterline.toUpperCase().substring(0, 1)}
                        onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.setState(()=>{
                                    return {
                                      removefiltername:'line'
                                    }
                                  },()=>{
                                    this.refs.actionsFilterGroup.open()
                                  })
                                }
                              }}
                        />):null}
                        {Number(linemanid) > 0 && filterlineman !== "" ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="green" media={filterlineman.toUpperCase().substring(0, 1)} 
                        onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.setState(()=>{
                                    return {
                                      removefiltername:'lineman'
                                    }
                                  },()=>{
                                    this.refs.actionsFilterGroup.open()
                                  })
                                }
                              }}
                        />):null}
                        {Number(hawkerid) > 0 && filterhawker !== "" ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)}
                        onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.setState(()=>{
                                    return {
                                      removefiltername:'hawker'
                                    }
                                  },()=>{
                                    this.refs.actionsFilterGroup.open()
                                  })
                                }
                              }}
                        />):null}
                        {Number(issingledatefilter) > 0 && billprintingdate !== "" ? (<Chip text={`Bill Date : ${billprintingdate}`} mediaBgColor="orange" media={`B`}
                        onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.setState(()=>{
                                    return {
                                      removefiltername:'billprintingdate'
                                    }
                                  },()=>{
                                    this.refs.actionsFilterGroup.open()
                                  })
                                }
                              }}
                        />):null}
                        &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.$f7router.navigate(`filterpopup/?lineid=${lineid}&linemanid=${linemanid}&hawkerid=${hawkerid}&filterline=${filterline}&filterlineman=${filterlineman}&filterhawker=${filterhawker}&areaid=${areaid}&filterarea=${filterarea}&billnumberfrom=${billnumberfrom}&billnumberto=${billnumberto}&filterbillnumberfrom=${filterbillnumberfrom}&filterbillnumberto=${filterbillnumberto}&isbillnumber=${isbillnumber}&isdatefilter=${isdatefilter}&billstartdate=${billstartdate}&billenddate=${billenddate}&filterstartmonthyear=${filterstartmonthyear}&filterendmonthyear=${filterendmonthyear}&issingledatefilter=${issingledatefilter}&billprintingdate=${billprintingdate}&isborderprinting=${isborderprinting}`);
                          }
                          /*this.openFilterPopup();*/
                        }}/>
                      </Block>
                    ):null
                  }
                  {
                    isdatafetched ? (
                    <Fragment>
                    {
                      (
                        <Fragment>
                          <Row noGap className='rowwithbottomborder printbilllargetext'>
                              <Col width='80' className="ledgercolumn textalignleft">
                                  Customers
                              </Col>
                              <Col width='20' className="ledgercolumn textalignleft">
                                  {billinvoicedata.totalcustomers.toString()}
                              </Col>
                          </Row>
                          <Row noGap className='rowwithbottomborder printbilllargetext'>
                              <Col width='80' className="ledgercolumn textalignleft">
                                  Printed Bill Required
                              </Col>
                              <Col width='20' className="ledgercolumn textalignleft">
                                  {billinvoicedata.totalcount.toString()}
                              </Col>
                          </Row>
                          <Row noGap className='rowwithbottomborder printbilllargetext'>
                              <Col width='80' className="ledgercolumn textalignleft">
                                  {/*Total Bills to be printed*/}
                                  Printed Bill Not Required
                              </Col>
                              <Col width='20' className="ledgercolumn textalignleft">
                                  {billinvoicedata.totalcountnotprinted.toString()}
                              </Col>
                          </Row>
                          {
                            hasinvoices ? (
                              <Row noGap className='rowwithbottomborder printbilllargetext'>
                              <Col width='50' align="center" className="ledgercolumn textalignleft">
                              <Button small fill color="blue" onClick={()=>{
                                          this.getPrintableInvoice();
                                          }
                                      } text='Laser Print' style={{marginBottom:'10px'}}/>
                              </Col>
                              <Col width='50' align="center" className="ledgercolumn textalignleft">
                              <Button small fill color="blue" onClick={()=>{
                                          this.getPrintableInvoice(`dotmatrix`);
                                          }
                                      } text='Dot Matrix Print'/>
                              </Col>
                            </Row>
                            ): null
                          }
                          </Fragment>
                        )
                    }
                    </Fragment>
                    ):(<AppListItemLoading />)
                  }
                  <br /><br /><br />
                </Block>
            );
        }}
        </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {/*
            hasinvoices ? (
               <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                    if(this.$f7.online)
                    {
                      this.getPrintableInvoice('pdf');
                    }
                  }}>
                  <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                  <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                </Fab>
            ):null
                */}
        </Page>

      );
    }
}