import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppCardItemLoading from '../pages/appcarditemloading';
import AppFav from '../pages/appfav';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    BlockTitle,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    Block,
    Chip,
  } from 'framework7-react';

  export default class ManageArea extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        isavailable:false,
        loaded:false,
        areas:[],
        actionGridOpened: false,
        oneGroupOpened: false,
        deleterecordid:'',
        isptr:false,
        effect:'blink',
        totalrecord:0,
        hasallcustomer:true,
        ismount:false,
        droppingpointid:'',
        droppingpointname:'Select',
        areaid:'',
        filterarea:'Select',
        hasfilter:true,
      };

      this.getAllArea         = this.getAllArea.bind(this);
      this.navigateToLink     = this.navigateToLink.bind(this);
      this.openFilterPopup    = this.openFilterPopup.bind(this);
    }

    componentDidMount(){
  
     let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.hideSplashScreen();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          setTimeout(()=> {

            this.getAllArea();
            
          }, 200)

        })
        
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'DeleteArea',
        clientid:this.state.clientdetail.id,
        recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              let tempAreas	= [...this.state.areas];
              tempAreas	= tempAreas.filter(area => area.id !== this.state.deleterecordid);
        
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                    areas:tempAreas
                  }
                },()=>{
                  if(this.state.areas.length < 1)
                  {
                    this.setState(()=>{
                      return {
                        isavailable:false
                      }
                    });
                  }
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    deleteSubstituteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'UpdateAreaSubstitute',
        clientid:this.state.clientdetail.id,
        recordid:this.state.deleterecordid,
        substituteid:0,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              let tempAreas	  = [...this.state.areas];
              const tempAreas2  = tempAreas.find(area => Number(area.id) === Number(this.state.deleterecordid));

              tempAreas2.substitute  = '';
        
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                    areas:tempAreas
                  }
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    getAllArea(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }

        this.setState({
          loaded:false
        });
  
        const bodyFormData = {
            Mode:'GetAllArea',
            clientid:this.state.clientdetail.id,
            areaid:this.state.areaid,
            droppingpointid:this.state.droppingpointid,
        };

        /*if(this.state.isptr !== true)
        {
          app.preloader.show();
        }*/
  
        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  loaded:true,
                  isavailable:true,
                  areas:resdata.recordlist,
                  isptr:false,
                  totalrecord:resdata.totalrecord
                });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  isptr:false,
                  totalrecord:0
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);
    }

    navigateToLink(link){
        
        if(this.$f7.online)
        {
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/
        }

    }

    setNewFilterData(areaid, filterarea, droppingpointid, droppingpointname){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            droppingpointid:droppingpointid,
            filterarea:filterarea,
            droppingpointname:droppingpointname,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
          }
  
        },()=>{
  
          if(Number(this.state.areaid) > 0 || Number(this.state.droppingpointid) > 0)
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getAllArea();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');      
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })

    }

    render(){

       const{isavailable, loaded, areas, totalrecord, areaid, filterarea, droppingpointid, droppingpointname, hasfilter, ismount, hasallcustomer} = this.state;

      return(
            <Page pageContent={false} className="page" name="Line">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Area</NavTitle>
                    <NavRight className="right-with-menu">
                    <Link onClick={()=>{

                      Promise.all([
            
                        setTimeout(()=> {
              
                          this.Dom7('.waiting').addClass('animate-bottom');
              
                        }, 50)
              
                      ])
                      .then(() => {
                        
                        this.getAllArea();
              
                      });

                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                    </NavRight>
                  </Navbar>
                  <FilterConsumer>{(value)=>{

                    const{areafilterdata, applyAreaFilter} = value;

                    if(ismount === true)
                    {
                      setTimeout(()=> {
          
                        if(this.state.removefiltername !== "" && this.state.applyremovefilter === true)
                        {
                          if(this.state.removefiltername === 'area')
                          {
                            applyAreaFilter('', '', this.state.droppingpointid, this.state.droppingpointname);
                          }
                          else if(this.state.removefiltername === 'droppingpoint')
                          {
                            applyAreaFilter(this.state.areaid, this.state.filterarea, '', '');
                          }
                        }
          
                        if(((areafilterdata.droppingpointid !== this.state.droppingpointid) && areafilterdata.droppingpointid !== undefined) || ((areafilterdata.areaid !== this.state.areaid) && areafilterdata.areaid !== undefined) || this.state.applyremovefilter === true)
                        {
                          this.setNewFilterData(areafilterdata.areaid, areafilterdata.filterarea, areafilterdata.droppingpointid, areafilterdata.droppingpointname);
          
                          this.setState({
                            removefiltername:'',
                            applyremovefilter:false
                          })
          
                        }
                        
                      }, 500)
                    }

                    return(
                        <PageContent>
                          {
                            loaded ? (<Fragment>
                              {
                                isavailable ? (<BlockTitle className="totalrec">Total Area : {totalrecord}</BlockTitle>):null
                              }
                              {
                                hasfilter ? (
                                  <Block strong className="selectedfilter" style={{marginTop:0}}>
                                  {hasallcustomer ? (<Chip text={`Area : All`} mediaBgColor="blue" media="A"/>):null}

                                    {Number(droppingpointid) > 0 && droppingpointname !== "" ? (<Chip text={`Dropping Point : ${droppingpointname}`} mediaBgColor="green" media={droppingpointname.toUpperCase().substring(0, 1)} deleteable
                                    onClick={()=>{
                                            if(this.$f7.online)
                                            {
                                              this.setState(()=>{
                                                return {
                                                  removefiltername:'droppingpoint'
                                                }
                                              },()=>{
                                                this.refs.actionsFilterGroup.open()
                                              })
                                            }
                                          }}
                                    />):null}

                                    {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                                    onClick={()=>{
                                            if(this.$f7.online)
                                            {
                                              this.setState(()=>{
                                                return {
                                                  removefiltername:'area'
                                                }
                                              },()=>{
                                                this.refs.actionsFilterGroup.open()
                                              })
                                            }
                                          }}
                                    />):null}

                                    &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                      this.openFilterPopup();
                                    }}/>
                                  </Block>
                                ):null
                              }
                              {
                                isavailable ? (
                                  <Fragment>
                                    {
                                        areas.map((area)=>{
                                          return(
                                            <Card outline key={area.id} className="recordset">
                                              <CardHeader
                                              className="date">{area.addeddate}</CardHeader>
                                              <CardContent>
                                                <p>{area.name}</p>
                                                <p><b>Dropping Point :</b> {area.droppingpointname}</p>
                                                {area.substitute ? (<p><b>Substitute :</b> {area.substitute}
                                                &nbsp;
                                                (<Link onClick={() =>{
                                                  if(this.$f7.online)
                                                  {
                                                    this.setState(()=>{
                                                      return {
                                                        deleterecordid:area.id
                                                      }
                                                    },()=>{
                                                      this.refs.actionsSubstituteGroup.open()
                                                    })
                                                  }
                                                }}>Delete</Link>)
                                                </p>):null}
                                                {area.phone ? (<p>{area.phone}</p>):null}
                                              </CardContent>
                                              <CardFooter>
                                                <Link onClick={()=>{this.navigateToLink(`editarea/${area.id}`)}} ignoreCache={true}>Edit</Link>
                                                {
                                                  !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                                    <Link onClick={() =>{
                                                      if(this.$f7.online)
                                                      {
                                                        this.setState(()=>{
                                                          return {
                                                            deleterecordid:area.id
                                                          }
                                                        },()=>{
                                                          this.refs.actionsOneGroup.open()
                                                        })
                                                      }
                                                    }}>Delete</Link>
                                                  ):(<div>&nbsp;</div>)
                                                }
                                              </CardFooter>
                                            </Card>
                                          );
                                        })
                                    }
                                  </Fragment>
                                ):(<AppItemNotFound notfoundtext ="No Line record found." />)
                              }
                            </Fragment>):(<AppCardItemLoading />)
                          }
                          <br /><br />
                        </PageContent>
                      );
                  }}
                  </FilterConsumer>

              <Actions ref="actionsOneGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteRecord()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <Actions ref="actionsFilterGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <AppFav position="center-bottom" text="Add Area" icon="plus" url="addarea" />
            </Page>
      );
    }
}