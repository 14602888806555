import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Block,
    List,
    ListInput,
    ListItem,
    Fab,
    Icon,
    Toggle,
    Toolbar,
    Row,
    Col,
    Button,
    Link
  } from 'framework7-react';

  export default class EditLine extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        substituteid:'',
        lines:[],
        isptr:false,
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getAllLine           = this.getAllLine.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getDetail();
        this.hideSplashScreen();

      });
      
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(!this.$f7.online)
        {
          return;
        }

        const bodyFormData = {
          Mode:'GetLineDetail',
          clientid:self.state.clientdetail.id,
          recordid:self.props.f7route.params.id,
        };

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
  
                name:resdata.linedetail.name,
                isptr:false,

              },()=>{
                  this.getAllLine();
              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    getAllLine(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
  
        const bodyFormData = {
            Mode:'GetLine',
            clientid:this.state.clientdetail.id,
            type:'substitute',
            recordid:self.props.f7route.params.id,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }
  
        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  lines:resdata.recordlist,
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);
    }

    saveDetail(){
      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(!this.$f7.online)
      {
        return;
      }

      const toastWithButton = app.toast.create({
        text: 'Please enter all required field.',
        closeButton: true,
        closeTimeout: 3000,
      });

      const bodyFormData = {
          Mode:'UpdateLineSubstitute',
          clientid:self.state.clientdetail.id,
          recordid:self.props.f7route.params.id,
          substituteid:self.state.substituteid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
  
            if(resdata.success === true)
            {
                router.navigate('/lines/');             
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){
       
      return(
            <Page className="page" name="Substitute">
                  <Navbar>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Assign Substitute</NavTitle>
                    <NavRight>
                    </NavRight>
                  </Navbar>
                <Block>
                    <List noHairlinesMd>

                      <ListInput
                          name="name"
                          label="Name"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="Name"
                          value={this.state.name}
                          readonly
                      >
                      </ListInput>

                        <ListInput
                            name="substituteid"
                            className="adddata"
                            label="Substitute"
                            type="select"
                            onChange={this.handleUserInput}
                            value={this.state.substituteid}
                            placeholder="Please choose..."
                            validate
                            >
                            <option value="">-Select-</option>
                            {
                                this.state.lines.map((line)=>{
                                    return(<option key={line.id} value={line.id}>{line.name}</option>);
                                })
                            }
                        </ListInput>

                    </List>
                    <br /><br />
                </Block>
                <Fab position="center-bottom" slot="fixed" text="Save" onClick={()=>{
                  if(this.$f7.online)
                  {
                    this.saveDetail();
                  }
                }}>
                  <Icon ios="f7:floppy_disk" aurora="f7:plus" md="material:save"></Icon>
                </Fab>
            </Page>
      );
    }
}