import React,{Component,Fragment} from 'react';
import Distributordashboard from './Distributordashboard';
import scanlogo from '../assets/images/scanlogo.png';
import banner from '../assets/images/banner.jpg';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    Block,
    Navbar,
    NavLeft,
    NavRight,
    Swiper,
    SwiperSlide,
    SkeletonBlock,
    NavTitle,
    BlockTitle,
    List,
    ListItem,
    Link,
    Icon,
    Row,
    Col
  } from 'framework7-react';

  export default class Dashboard extends Component {

    //static contextType = CustomerContext;

    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        showprogress:true,
        effect: 'blink',
        isbannerloaded:false,
        isbetaaccount:0,
        isclientupdated:false,
        hastodaystaff:false,
        todaystafflist:[],
        hastomarrowstaff:false,
        tomarrowstafflist:[],
        totalbirthdaycount:"0",
        hasnewsubscription:false,
        hasclosesubscription:false,
        totalsubscriptioncount:"0",
        pendingcontactcount:0,
      };

      this.bannerLoaded = this.bannerLoaded.bind(this);
      this.navigateToLink = this.navigateToLink.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
      let adminid       = Number(localStorage.getItem('agency_adminid'));

      let receiptbydetail = {"receipttype":"", "receiptbyid":"", "receiptbyname":"", "orgreceiptbyname":""};

      localStorage.setItem("agency_selected_receiptby",JSON.stringify(receiptbydetail));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          isbetaaccount:clientdetail.isbetaaccount,
          adminid:adminid
        }
      });

      this.getStaffdob();
      this.getCustomerChanges();
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    bannerLoaded(){

      this.setState(()=>{

        return{

          isbannerloaded:true

        }
      },()=>{

        if(this.state.isbannerloaded === true)
        {
          Promise.all([
            this.hideSplashScreen()
          ])
          .then(() => {

            setTimeout(()=> {
              this.Dom7('.elevation-3').addClass('animate-bottom');
            }, 50);

          })
        }

      });
    }

    getStaffdob(){

      if(!this.$f7.online)
      {
        return;
      }

      this.setState({
        loaded:false
      });

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetStaffDOB',
        clientid:Number(this.state.clientdetail.id),
      };

      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          app.preloader.hide();
          
          app.ptr.done();

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            if(!resdata.clientstatus)
            {
              localStorage.setItem("agency_accesstoken",'');
              localStorage.setItem("agency_clientdetail",JSON.stringify([]));
              localStorage.setItem("agency_isadminlogin",false);
              localStorage.setItem("agency_adminid","");
              localStorage.setItem("agency_isadminverified",false);
              localStorage.setItem("agency_authtoken",'');

              this.$f7.views.main.router.navigate('/login/');
            }
            else
            {              
              this.setState({
                hastodaystaff:resdata.recordset.hastodaystaff,
                todaystafflist:resdata.recordset.todaystafflist,
                hastomarrowstaff:resdata.recordset.hastomarrowstaff,
                tomarrowstafflist:resdata.recordset.tomarrowstafflist,
                totalbirthdaycount:resdata.recordset.totalbirthdaycount,
                pendingcontactcount:resdata.recordset.pendingcontactcount,
              },()=>{
                localStorage.setItem("agency_accesstoken",resdata.accesstoken);
                localStorage.setItem("agency_clientdetail",JSON.stringify(resdata.clientdetail));
              });
            }
          }
          else
          {
            this.setState({
              hastodaystaff:false,
              todaystafflist:[],
              hastomarrowstaff:false,
              tomarrowstafflist:[],
              totalbirthdaycount:"0",
              pendingcontactcount:0,
            });
          }
  
        }).catch(e => {
          console.log(e);
        });

      }, 500);
    }

    getCustomerChanges(){

      if(!this.$f7.online)
      {
        return;
      }

      this.setState({
        loaded:false
      });

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetCustomerSubscriptionChanges',
        authtoken:localStorage.getItem('agency_authtoken'),
      };

      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          app.preloader.hide();
          
          app.ptr.done();

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              hasnewsubscription:resdata.recordset.hasnewsubscription,
              hasclosesubscription:resdata.recordset.hasclosesubscription,
              totalsubscriptioncount:resdata.recordset.totalsubscriptioncount,
            });
          }
          else{
            this.setState({
              hasnewsubscription:false,
              hasclosesubscription:false,
              totalsubscriptioncount:"0",
            });
          }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
    }

    navigateToLink(link){
        
      if(this.$f7.online)
      {
        this.$f7.views.main.router.navigate(`/${link}/`);
      }
    }

    render(){

      const{clientdetail, isclientupdated, adminid, hastodaystaff, hastomarrowstaff, totalbirthdaycount, hasnewsubscription, hasclosesubscription, totalsubscriptioncount, pendingcontactcount} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{initMenu} = value;

          setTimeout(() => {
            if(isclientupdated === false)
            {
              this.setState(()=>{
                return{
                  isclientupdated:true,
                }
              },()=>{

                initMenu();
                
              })
              
            }
          }, 100);

          return(
            <Page name="Dashboard" className="dashboardpage">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Dashboard</NavTitle>
                  <NavRight className="right-with-menu">
                  </NavRight>
                </Navbar>
                <Block style={{marginBottom:0}}>
                  {
                    this.state.isbannerloaded ? (
                      <Swiper params={{autoplay:{delay:5000}}}>
                        <SwiperSlide>
                          <img src={banner} onLoad={() =>{this.bannerLoaded()}}/>
                        </SwiperSlide>
                        <SwiperSlide>
                          <img src={banner} onLoad={() =>{this.bannerLoaded()}}/>
                        </SwiperSlide>
                      </Swiper>
                    ) : (
                      <List className={`skeleton-banner skeleton-effect-${this.state.effect}`}>
                        <ListItem>
                          <SkeletonBlock style={{ width: '100%', height: '175px' }} slot="media" />
                          <img src={banner} onLoad={() => this.setState({isbannerloaded: true})} style={{ display: 'none'}}/>
                        </ListItem>
                      </List>
                    )
                  }
                </Block>
                {
                  (()=>{
                      if(clientdetail.clienttype === 2)
                      {
                        return(
                          <Fragment>
                          {
                            clientdetail.clientname ? (<BlockTitle medium className="agencyname">{clientdetail.clientname}
                            &nbsp;
                            {
                              adminid ? (<Link href="/admindashboard/"><Icon f7="lock_rotation"></Icon></Link>):null
                            }</BlockTitle>):null
                          }
                          {
                            Number(hastodaystaff) === 1 || Number(hastomarrowstaff) ? (
                              <BlockTitle medium className="upcomingbirthday">Upcoming Birthday ({totalbirthdaycount})&nbsp;
                                <Link href="upcomingbirthday/" color="white"><Icon f7="eye" style={{fontSize:'22px'}}></Icon></Link>
                              </BlockTitle>
                            ):null
                          }
                          {
                            Number(hasnewsubscription) === 1 || Number(hasclosesubscription) ? (
                              <BlockTitle medium className="upcomingbirthday">Customer Changes ({totalsubscriptioncount})&nbsp;
                                <Link href="customerchanges/" color="white"><Icon f7="eye" style={{fontSize:'22px'}}></Icon></Link>
                              </BlockTitle>
                            ):null
                          }
                          {
                            pendingcontactcount && Number(pendingcontactcount) > 0 ? (
                              <BlockTitle medium className="upcomingbirthday">Contact Request ({pendingcontactcount})&nbsp;
                                <Link href="/contactrequest/" color="white"><Icon f7="eye" style={{fontSize:'22px'}}></Icon></Link>
                              </BlockTitle>
                            ):null
                          }
                          <Row>
                            {
                              Number(clientdetail.cancustomers) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('customerdashboard')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon ios="f7:person_2_fill" aurora="f7:person_2_fill" md="material:group" className="dashboard_customer"></Icon>
                                    <div>Customers</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canpayments) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('paymenttab')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="creditcard_fill" className="dashboard_payments"></Icon>
                                    <div>Payments</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.caninvoices) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('generateinvoice')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="doc_on_clipboard" className="dashboard_invoices"></Icon>
                                    <div>Bills</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canreports) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('reports')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="bag_fill" className="dashboard_reports"></Icon>
                                    <div>Reports</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canpurchase) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('purchase')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="rectangle_stack_fill" className="dashboard_payments"></Icon>
                                    <div>Purchase</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.cansale) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('sale')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="cart_fill_badge_plus" className="dashboard_holiday"></Icon>
                                    <div>Sale</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canmessages) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('messages')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="chat_bubble_text_fill" className="dashboard_messages"></Icon>
                                    <div>Messages</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canstock) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('inventory')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="cube_box_fill" className="dashboard_inventory"></Icon>
                                    <div>Stock</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canareamanager) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('areamanagers')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="person_2_fill" className="dashboard_areamanager"></Icon>
                                    <div>Area Manager</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canarea) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('areas')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="map_pin_ellipse" className="dashboard_area"></Icon>
                                    <div>Area</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.candroppingpoint) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('droppingpoint')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="map_pin_ellipse" className="dashboard_dropping_point"></Icon>
                                    <div>Dropping Point</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }
                            
                            {
                              Number(clientdetail.canline) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('lines')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon ios="f7:person_2_fill" aurora="f7:person_2_fill" md="material:group" className="dashboard_delivery_guy"></Icon>
                                    <div>Line</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.cansubline) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('sublines')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon ios="f7:person_2_fill" aurora="f7:person_2_fill" md="material:group" className="dashboard_delivery_guy"></Icon>
                                    <div>Sub Line</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canlineman) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('lineman')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon ios="f7:person_2_fill" aurora="f7:person_2_fill" md="material:group" className="dashboard_delivery_guy"></Icon>
                                    <div>Lineman</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canhawkers) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('hawkers')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon ios="f7:person_2_fill" aurora="f7:person_2_fill" md="material:group" className="dashboard_invoices"></Icon>
                                    <div>Hawkers</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canholiday) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('holiday')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="calendar_badge_plus" className="dashboard_holiday"></Icon>
                                    <div>Holiday</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.cansubscription) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('subscription')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon ios="f7:bag_fill_badge_plus" aurora="f7:bag_fill_badge_plus" md="material:redeem" className="dashboard_subscription"></Icon>
                                    <div>Subscription</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canmessages) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('smscredits')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 dashboardlink elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="rectangle_stack_fill" className="dashboard_sms_credit"></Icon>
                                    <div>SMS Credit</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }

                            {
                              Number(clientdetail.canwebsite) > 0 ? <Col>
                                <Link className="dashboardlink" onClick={()=>{this.navigateToLink('website')}} ignoreCache={true}>
                                  <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5">
                                    <Icon f7="at" className="dashboard_holiday"></Icon>
                                    <div>Website</div>
                                  </div>
                                </Link>
                              </Col>:null
                            }
                          </Row>
                          </Fragment>
                        );
                      }
                      else
                      {
                        return(
                          <Distributordashboard />
                        );
                      }
                  })()
                }
            </Page>
          );
        }}</FilterConsumer>
      );
  }
}