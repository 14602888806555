
import LoginPage from '../pages/login.jsx';
import ForgetPassword from '../pages/forgetpassword.jsx';
import DashboardPage from '../pages/dashboard.jsx';
import ChangePasswordPage from '../pages/ChangePassword.jsx';
import ProfilePage from '../pages/profile.jsx';
import Payments from '../pages/payments.jsx';
import PaymentFilters from '../pages/paymentfilters.jsx';
import ViewPaymentDetail from '../pages/viewpaymentdetail.jsx';

import AddLine from '../pages/addline.jsx';
import ManageLine from '../pages/manageline.jsx';
import EditLine from '../pages/editline.jsx';
import Substitute from '../pages/substitute.jsx';

import AddSubLine from '../pages/addsubline.jsx';
import ManageSubLine from '../pages/managesubline.jsx';
import EditSubLine from '../pages/editsubline.jsx';

import AddLineman from '../pages/addlineman.jsx';
import ManageLineman from '../pages/managelineman.jsx';
import EditLineman from '../pages/editlineman.jsx';
import LinemanFilter from '../pages/linemanfilter.jsx';

import AddHawker from '../pages/addhawker.jsx';
import ManageHawker from '../pages/managehawker.jsx';
import EditHawker from '../pages/edithawker.jsx';

import QuickAddArea from '../pages/quickaddarea.jsx';
import QuickAddDroppingPoint from '../pages/quickadddroppingpoint.jsx';
import QuickAddline from '../pages/quickaddline.jsx';
import QuickAddSubline from '../pages/quickaddsubline.jsx';
import QuickAddlineeman from '../pages/quickaddlineman.jsx';
import QuickAddHawker from '../pages/quickaddhawker.jsx';
import CustomerFilter from '../pages/customerfilter.jsx';
import SubLineFilter from '../pages/sublinefilter.jsx';

import AddCustomer from '../pages/addcustomer.jsx';
import ManageCustomers from '../pages/managecustomers.jsx';
import CustomerDetail from '../pages/customerdetail.jsx';

import ManageInvoices from '../pages/manageinvoices.jsx';
import InvoiceFilter from '../pages/invoicefilter.jsx';

import EditCustomer from '../pages/editcustomer.jsx';
import CustomerDashboard from '../pages/customerdashboard.jsx';

import Subscription from '../pages/subscription.jsx';
import ViewSubscriptionLog from '../pages/viewsubscriptionlog.jsx';

import Ledger from '../pages/ledger.jsx';
import LedgerFilter from '../pages/ledgerfilter.jsx';

import AddHoliday from '../pages/addholiday.jsx';
import ManageHoliday from '../pages/manageholiday.jsx';
import EditHolidayPage from '../pages/editholiday.jsx';

import ManageHolidayFilter from '../pages/manageholidayfilter.jsx';

import CustomerInvoices from '../pages/customerinvoices.jsx';
import GenerateInvoice from '../pages/generateinvoice.jsx';
import SendInvoiceSMS from '../pages/sendinvoicesms.jsx';
import InvoiceDetail from '../pages/invoicedetail.jsx';

import ManageInventory from '../pages/manageinventory.jsx';
import Reports from '../pages/reports.jsx';
import Outstandingreport from '../pages/outstandingreport.jsx';
import OutstandingReportFilter from '../pages/outstandingreportfilter.jsx';
import CirculationReport from '../pages/circulationreport.jsx';
import CirculationReportFilter from '../pages/circulationreportfilter.jsx';
import BillPrintingReport from '../pages/billprintingreport.jsx';
import BillPrintingReportFilter from '../pages/billprintingreportfilter.jsx';

import CustomerListNewspaper from '../pages/customerlistbynewspaper.jsx';
import CustomerListNewspaperFilter from '../pages/customerlistbynewspaperfilter.jsx';

import CustomerListMagazine from '../pages/customerlistbymagazine.jsx';
import CustomerListMagazineFilter from '../pages/customerlistbymagazinefilter.jsx';

import NotFoundPage from '../pages/404.jsx';

import SendMessage from '../pages/sendmessage.jsx';
import InvoiceSMSTab from '../pages/invoicesmstab.jsx';
import GeneralSMSTab from '../pages/generalsmstab.jsx';

import Messages from '../pages/messages.jsx';
import ViewLog from '../pages/viewlog.jsx';

import Updatedateprice from '../pages/updatedateprice.jsx';
import Updatemonthprice from '../pages/updatemonthprice.jsx';

import AddArea from '../pages/addarea.jsx';
import ManageArea from '../pages/managearea.jsx';
import EdiArea from '../pages/editarea.jsx';
import AreaFilter from '../pages/areafilter.jsx';

import AddDroppingPoint from '../pages/adddroppingpoint.jsx';
import ManageDroppingPoint from '../pages/managedroppingpoint.jsx';
import EditDroppingPoint from '../pages/editdroppingpoint.jsx';

import AddStock from '../pages/addstock.jsx';
import ManageStock from '../pages/managestock.jsx';
import EditStock from '../pages/editstock.jsx';

import MakePayment from '../pages/makepayments.jsx';
import EditPayment from '../pages/editpayment.jsx';
import ViewLedger from '../pages/viewledger.jsx';
import InventoryDetail from '../pages/inventorydetail.jsx';

import AddPurchase from '../pages/addpurchase.jsx';
import ManagePurchase from '../pages/managepurchase.jsx';
import EditPurchase from '../pages/editpurchase.jsx';

import AddSale from '../pages/addsale.jsx';
import EditSale from '../pages/editsale.jsx';
import ManageSale from '../pages/managesale.jsx';

import ManageSaleFilter from '../pages/managesalefilter.jsx';

import BillStatement from '../pages/billstatement.jsx';
import BillStatementByAreaLine from '../pages/billstatementbyarealine.jsx';
import BillStatementFilter from '../pages/billstatementfilter.jsx';

import Sequence from '../pages/sequence.jsx';

import PaymentRegister from '../pages/paymentregister.jsx';
import PaymentRegisterDetail from '../pages/paymentregisterdetail.jsx';
import PaymentRegisterFilter from '../pages/paymentregisterfilter.jsx';

import CustomerLedgerReport from '../pages/customerledgerreport.jsx';
import CustomerLedgerReportFilter from '../pages/customerledgerreportfilter.jsx';

import BillStatementSummary from '../pages/billstatementsummary.jsx';
import BillStatementSummaryFilter from '../pages/billstatementsummaryfilter.jsx';

import OutStandingReportSummary from '../pages/outstandingreportsummary.jsx';
import OutStandingReportSummaryFilter from '../pages/outstandingreportsummaryfilter.jsx';

import BillCollectionSummary from '../pages/billcollectionsummary.jsx';
import BillCollectionSummaryFilter from '../pages/billcollectionsummaryfilter.jsx';

import MagazineSaleDetail from '../pages/magazinesaledetail.jsx';
import MagazineSaleDetailFilter from '../pages/magazinesaledetailfilter.jsx';
import MagazineDetail from '../pages/magazinedetail.jsx';
import MagazineDetailByLine from '../pages/magazinedetailbyline.jsx';

import MonthlyBillOfNewspaper from '../pages/monthlybillofnewspaper.jsx';
import MonthlyBillOfNewspaperDetail from '../pages/monthlybillofnewspaperdetail.jsx';
import MonthlyBillOfNewspaperFilter from '../pages/monthlybillofnewspaperfilter.jsx';

import Settings from '../pages/settings.jsx';
import Managewebsite from '../pages/Managewebsite.jsx';
import Aboutus from '../pages/Aboutus.jsx';
import Contactdetail from '../pages/contactdetail.jsx';

import AddAreaManager from '../pages/addareamanager.jsx';
import ManageAreaManager from '../pages/manageareamanager.jsx';
import EdiAreaManager from '../pages/editareamanager.jsx';

import BulkAddThanks from '../pages/bulkaddthanks.jsx';
import VerifyAdmin from '../pages/verifyadmin.jsx';

import AdminDashboardPage from '../pages/admindashboard.jsx';

import PurchaseSummary from '../pages/purchasesummary.jsx';
import PurchaseSummaryFilter from '../pages/purchasesummaryfilter.jsx';

import CloseCustomerList from '../pages/closecustomerlist.jsx';
import CloseCustomerListFilter from '../pages/closecustomerlistfilter.jsx';

import UpcomingBirthday from '../pages/upcomingbirthday.jsx';
import CustomerChanges from '../pages/customerchanges.jsx';

import CustomersOutstanding from '../pages/customersoutstanding.jsx';
import CustomerOutstandingFilter from '../pages/customeroutstandingfilter.jsx';

import RestartCustomerList from '../pages/restartcustomerlist.jsx';
import RestartCustomerListFilter from '../pages/restartcustomerlistfilter.jsx';

import PaymentTab from '../pages/paymenttab.jsx';

import SMSHistory from '../pages/smshistory.jsx';
import SMSHistoryFilter from '../pages/smshistoryfilter.jsx';

/*import SMSCredit from '../pages/smscredit.jsx';*/
import SMSCreditTab from '../pages/smscredittab.jsx';
import SMSCreditPending from '../pages/smscreditpending.jsx';
import PaymentProcessing from '../pages/paymentprocessing.jsx';
import AddSMSCredit from '../pages/addsmscredit.jsx';
import AddLeadCredit from '../pages/addleadcredit.jsx';
import LeadCreditTab from '../pages/leadcredittab.jsx';

import InactiveCustomerOutstanding from '../pages/inactivecustomeroutstanding.jsx';

import Addgallery from '../pages/addgallery.jsx';
import Managegallery from '../pages/managegallery.jsx';
import Editgallery from '../pages/editgallery.jsx';

import Managecontactrequest from '../pages/managecontactrequest.jsx';

import LatePaymentReport from '../pages/latepaymentreport.jsx';

import SMSCredit from '../pages/smscredit.jsx';
import SMSCreditFilter from '../pages/smscreditfilter.jsx';

var routes = [
  {
    path: '/',
    /*options:{
      reloadAll:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      var app = this.app;
      /*app.preloader.show();*/
      setTimeout(()=> {

        app.preloader.hide();

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: DashboardPage
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }, 500);
    },
    routes: [
      {
        path: 'forgetpassword/',
        popup: {
          component: ForgetPassword,
        },
      },
      {
        path: 'verifyadmin/',
        popup: {
          component: VerifyAdmin,
        },
      },
      {
        path: 'upcomingbirthday/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: UpcomingBirthday,
        },
      },
      {
        path: 'customerchanges/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: CustomerChanges,
        },
      }
    ],

  },
  {
    path: '/login/',
    async: function (routeTo, routeFrom, resolve, reject) {

      var app = this.app;
      /*app.preloader.show();*/
      setTimeout(()=> {

        app.preloader.hide();

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: DashboardPage
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }, 500);
    },
    routes: [
      {
        path: 'forgetpassword/',
        popup: {
          component: ForgetPassword,
        },
      },
      {
        path: 'verifyadmin/',
        popup: {
          component: VerifyAdmin,
        },
      }
    ],

  },
  {
    path: '/profile/',
    async: function (routeTo, routeFrom, resolve, reject) {
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ProfilePage
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/changepassword/',
    async: function (routeTo, routeFrom, resolve, reject) {
      
      var app = this.app;
      /*app.preloader.show();*/

      setTimeout(()=> {

        app.preloader.hide();

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
              resolve({
                  component: ChangePasswordPage
              });
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }, 500);
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/dashboard/',
    /*options:{
      reloadAll:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {

        if(clientdetail.id > 0)
        {

          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: DashboardPage
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
        resolve({
          component: LoginPage
        });
      }

    },
    routes: [
      {
        path: 'upcomingbirthday/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: UpcomingBirthday,
        },
      },
      {
        path: 'customerchanges/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: CustomerChanges,
        }
      }
    ],
  },
  {
    path: '/addcustomer/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: AddCustomer
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'selectline/',
      },
      {
        path: 'selectlineman/',
      },
      {
        path: 'selecthawker/',
      },
      {
        path: 'selectquickarea/',
      },
      {
        path: 'addareapopup/',
        popup: {
          component: QuickAddArea,
        },
      },
      {
        path: 'addlinepopup/',
        popup: {
          component: QuickAddline,
        },
      },
      {
        path: 'addsublinepopup/',
        popup: {
          component: QuickAddSubline,
        },
      },
      {
        path: 'addlinemanpopup/',
        popup: {
          component: QuickAddlineeman,
        },
      },
      {
        path: 'addhawkerpopup/',
        popup: {
          component: QuickAddHawker,
        },
      },
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  {
    path: '/managecustomers/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: ManageCustomers
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CustomerFilter,
        },
      },
    ],
  },
  {
    path: '/managecustomers/:searchkeyword/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: ManageCustomers
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CustomerFilter,
        },
      },
    ],
  },
  {
    path: '/managecustomers/line/:lineid/:linename/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: ManageCustomers
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CustomerFilter,
        },
      },
    ],
  },
  {
    path: '/managecustomers/area/:areaid/:areaname/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: ManageCustomers
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CustomerFilter,
        },
      },
    ],
  },
  {
    path: '/editcustomer/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditCustomer
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }

    },
    routes: [
      {
        path: 'selectline/',
      },
      {
        path: 'selectlineman/',
      },
      {
        path: 'selecthawker/',
      },
      {
        path: 'addareapopup/',
        popup: {
          component: QuickAddArea,
        },
      },
      {
        path: 'addlinepopup/',
        popup: {
          component: QuickAddline,
        },
      },
      {
        path: 'addsublinepopup/',
        popup: {
          component: QuickAddSubline,
        },
      },
      {
        path: 'addlinemanpopup/',
        popup: {
          component: QuickAddlineeman,
        },
      },
      {
        path: 'addhawkerpopup/',
        popup: {
          component: QuickAddHawker,
        },
      },
    ],
  },
  {
    path: '/customerdetail/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: CustomerDetail
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }

    },
  },
  {
    path: '/customerdashboard/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: CustomerDashboard
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }

    },
  },
  {
    path: '/subscription/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Subscription
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'viewlog/:id/',
        popup: {
          component: ViewSubscriptionLog,
        },
      },
    ],
  },
  {
    path: '/subscription/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Subscription
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'viewlog/:id/',
        popup: {
          component: ViewSubscriptionLog,
        },
      },
    ],
  },
  {
    path: '/ledger/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Ledger
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/ledger/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Ledger
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'ledgerfilter/',
        popup: {
          component: LedgerFilter,
        },
      },
    ],
  },
  {
    path: '/inventory/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageInventory
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/addline/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddLine
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'addareapopup/',
        popup: {
          component: QuickAddArea,
        },
      },
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  {
    path: '/lines/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageLine
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: SubLineFilter,
        },
      },
    ],
  },
  {
    path: '/editline/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditLine
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'addareapopup/',
        popup: {
          component: QuickAddArea,
        },
      },
    ],
  },
  {
    path: '/substitute/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Substitute
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/addsubline/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddSubLine
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'addareapopup/',
        popup: {
          component: QuickAddArea,
        },
      },
      {
        path: 'addlinepopup/',
        popup: {
          component: QuickAddline,
        },
      },
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
    ],
  },
  {
    path: '/sublines/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageSubLine
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: SubLineFilter,
        },
      },
    ],
  },
  {
    path: '/editsubline/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditSubLine
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'addareapopup/',
        popup: {
          component: QuickAddArea,
        },
      },
      {
        path: 'addlinepopup/',
        popup: {
          component: QuickAddline,
        },
      },
    ],
  },
  {
    path: '/addlineman/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddLineman
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'addlinepopup/',
        popup: {
          component: QuickAddline,
        },
      },
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/lineman/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageLineman
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: LinemanFilter,
        },
      },
    ],
  },
  {
    path: '/editlineman/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditLineman
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'addlinepopup/',
        popup: {
          component: QuickAddline,
        },
      }
    ],
  },
  {
    path: '/addhawker/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddHawker
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/hawkers/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageHawker
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: SubLineFilter,
        },
      },
    ],
  },
  { 
    path: '/edithawker/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditHawker
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/addholiday/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddHoliday
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/addholiday/:type/:year/:month/:monthname/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddHoliday
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/holiday/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageHoliday
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: ManageHolidayFilter,
        },
      },
    ],
  },
  {
    path: '/editholiday/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditHolidayPage
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/customerinvoices/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: CustomerInvoices
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/invoicedetail/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: InvoiceDetail
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/reports/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Reports
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/outstandingreport/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Outstandingreport
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: OutstandingReportFilter,
        },
      },
    ],
  },
  {
    path: '/generateinvoice/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: GenerateInvoice
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/payments/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Payments
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: PaymentFilters,
        },
      },
      {
        path: 'paymentfilter/',
        popup: {
          component: PaymentFilters,
        },
      },
      {
        path: 'viewpaymentdetail/:id/',
        popup: {
          component: ViewPaymentDetail,
        },
      },
    ],
  },
  {
    path: '/payments/:action/:customerid/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Payments
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CustomerFilter,
        },
      },
      {
        path: 'paymentfilter/',
        popup: {
          component: PaymentFilters,
        },
      },
      {
        path: 'viewpaymentdetail/:id/',
        popup: {
          component: ViewPaymentDetail,
        },
      },
    ],
  },
  {
    path: '/addarea/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddArea
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'adddroppingpointpopup/',
        popup: {
          component: QuickAddDroppingPoint,
        },
      }
    ],
  },
  {
    path: '/areas/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageArea
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: AreaFilter,
        },
      },
    ],
  },
  {
    path: '/editarea/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EdiArea
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'adddroppingpointpopup/',
        popup: {
          component: QuickAddDroppingPoint,
        },
      }
    ],
  },
  {
    path: '/adddroppingpoint/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddDroppingPoint
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/droppingpoint/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageDroppingPoint
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/editdroppingpoint/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditDroppingPoint
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/manageinvoices/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: ManageInvoices
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: InvoiceFilter,
        },
      },
    ],
  },
  {
    path: '/manageinvoices/:year/:month/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: ManageInvoices
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: InvoiceFilter,
        },
      },
    ],
  },
  {
  path: '/sendinvoicesms/',
  async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: SendInvoiceSMS
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    }
  },
  {
    path: '/messages/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: Messages
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
  },
  {
    path: '/viewlog/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ViewLog
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/sendmessage/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: SendMessage
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    tabs: [
      {
        path: '/',
        id: 'invoicesms',
        component: InvoiceSMSTab
      },
      {
        path: '/generalsms/',
        id: 'generalsms',
        component: GeneralSMSTab
      }
    ],
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/circulationreport/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: CirculationReport
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CirculationReportFilter,
        },
      },
    ],
  },
  {
    path: '/customerlistbynewspaper/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: CustomerListNewspaper
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CustomerListNewspaperFilter,
        },
      },
    ],
  },
  {
    path: '/customerlistbymagazine/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: CustomerListMagazine
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CustomerListMagazineFilter,
        },
      },
    ],
  },
  {
    path: '/billstatementbyarealine/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: BillStatementByAreaLine
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: BillStatementFilter,
        },
      },
    ],
  },
  {
    path: '/billstatement/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: BillStatement
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: BillStatementFilter,
        },
      },
    ],
  },
  {
    path: '/customersoutstanding/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: CustomersOutstanding
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CustomerOutstandingFilter,
        },
      },
    ],
  },
  {
    path: '/paymentregister/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: PaymentRegister
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: PaymentRegisterFilter,
        },
      },
      {
        path: 'paymentregisterdetail/',
        popup: {
          component: PaymentRegisterDetail,
        },
      },
    ],
  },
  {
    path: '/smshistory/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: SMSHistory
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: SMSHistoryFilter,
        },
      },
    ],
  },
  {
    path: '/customerledgerreport/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: CustomerLedgerReport
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CustomerLedgerReportFilter,
        },
      },
    ],
  },
  {
    path: '/billstatementsummary/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: BillStatementSummary
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: BillStatementSummaryFilter,
        },
      },
    ],
  },
  {
    path: '/outstandingreportsummary/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: OutStandingReportSummary
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: OutStandingReportSummaryFilter,
        },
      },
    ],
  },
  {
    path: '/billcollectionsummary/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: BillCollectionSummary
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: BillCollectionSummaryFilter,
        },
      },
    ],
  },
  {
    path: '/magazinesaledetail/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: MagazineSaleDetail
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: MagazineSaleDetailFilter,
        },
      },
      {
        path: 'detail/',
        popup: {
          component: MagazineDetail,
        },
      },
	  {
        path: 'linedetail/',
        popup: {
          component: MagazineDetailByLine,
        },
      },
    ],
  },
  {
    path: '/monthlybillofnewspaper/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: MonthlyBillOfNewspaper
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: MonthlyBillOfNewspaperFilter,
        },
      },
      {
        path: 'monthlybillofnewspaperdetail/',
        popup: {
          component: MonthlyBillOfNewspaperDetail,
        },
      },
    ],
  },
  {
    path: '/inventorydetail/:inventoryid/:areaid/:lineid/:hawkerid/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: InventoryDetail
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
  },
  {
    path: '/updatedateprice/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: Updatedateprice
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
  },
  {
    path: '/updatemonthprice/:year/:month/:monthname/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: Updatemonthprice
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/makepayment/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: MakePayment
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'viewledger/:id/',
        popup: {
          component: ViewLedger,
        },
      },
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/makepayment/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: MakePayment
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'viewledger/:id/',
        popup: {
          component: ViewLedger,
        },
      },
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/addpurchase/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: AddPurchase
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'adddroppingpointpopup/',
        popup: {
          component: QuickAddDroppingPoint,
        },
      }
    ],
  },
  {
    path: '/purchase/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManagePurchase
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
        resolve({
          component: LoginPage
        });
      }
    },
  },
  {
    path: '/editpurchase/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: EditPurchase
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'adddroppingpointpopup/',
        popup: {
          component: QuickAddDroppingPoint,
        },
      }
    ],
  },
  {
    path: '/addsale/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                component: AddSale
              });
            }
            else
            {
              resolve({
                component: ChangePasswordPage
              });
            }
          }
          else
          {
            resolve({
              component: LoginPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/editsale/:stockid/:saledate/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: EditSale
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
  },
  {
    path: '/sale/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: ManageSale
              });
            }
            else
            {
              resolve({
                component: ChangePasswordPage
              });
            }
          }
          else
          {
            resolve({
              component: LoginPage
            });
          }
        }
        else
        {
          resolve({
            component: LoginPage
          });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: ManageSaleFilter,
        },
      },
    ],
  },
  {
    path: '/sequence/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: Sequence
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
  },
  {
    path: '/billprintingreport/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: BillPrintingReport
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: BillPrintingReportFilter,
        },
      },
    ],
  },
  {
    path: '/settings/',
    async: function (routeTo, routeFrom, resolve, reject) {
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Settings
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/website/',
    async: function (routeTo, routeFrom, resolve, reject) {
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Managewebsite
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/contactrequest/',
    async: function (routeTo, routeFrom, resolve, reject) {
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Managecontactrequest
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/aboutus/',
    async: function (routeTo, routeFrom, resolve, reject) {
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Aboutus
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/contact-detail/',
    async: function (routeTo, routeFrom, resolve, reject) {
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Contactdetail
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/addareamanager/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddAreaManager
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/areamanagers/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageAreaManager
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/editareamanager/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EdiAreaManager
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/addstock/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddStock
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/stock/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: ManageStock
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/editstock/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: EditStock
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/admindashboard/',
    /*options:{
      reloadAll:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let adminid        = Number(localStorage.getItem('agency_adminid'));

      if(adminid && Number(adminid) > 0)
      {
        resolve({
            component: AdminDashboardPage
        });
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }

    },

  },
  {
    path: '/purchasesummary/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: PurchaseSummary
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: PurchaseSummaryFilter,
        },
      },
    ],
  },
  {
    path: '/closecustomerlist/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: CloseCustomerList
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: CloseCustomerListFilter,
        },
      },
    ],
  },
  {
    path: '/restartcustomerlist/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: RestartCustomerList
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'filterpopup/',
        popup: {
          component: RestartCustomerListFilter,
        },
      },
    ],
  },
  {
    path: '/paymenttab/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: PaymentTab
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/smscredits_bak/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: SMSCredit
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    tabs: [
      {
        path: '/',
        id: 'smscredit',
        component: SMSCreditTab
      },
      {
        path: '/leadcredit/',
        id: 'leadcredit',
        component: LeadCreditTab
      }
    ],
  },
  {
    path: '/smscredits/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: SMSCredit
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'smscreditfilter/',
        popup: {
          component: SMSCreditFilter,
        },
      },
    ],
  },
  {
    path: '/smscreditpending/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: SMSCreditPending
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'payments/:paymentid/:frompage/',
        popup: {
          component: PaymentProcessing,
        },
      },
    ],
  },
  {
    path: '/addsmscredit/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddSMSCredit
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'payments/:paymentid/',
        popup: {
          component: PaymentProcessing,
        },
      },
    ],
  },
  {
    path: '/addleadcredit/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: AddLeadCredit
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      },
      {
        path: 'payments/:paymentid/',
        popup: {
          component: PaymentProcessing,
        },
      },
    ],
  },
  {
    path: '/inactivecustomeroutstanding/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: InactiveCustomerOutstanding
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
  },
  {
    path: '/addgallery/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Addgallery
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
    routes: [
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/gallery/',
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Managegallery
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/editgallery/:id/',
    /*options:{
      pushState:true,
      history:true
    },*/
    async: function (routeTo, routeFrom, resolve, reject) {

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: Editgallery
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '/editpayment/:id/',
    async: function (routeTo, routeFrom, resolve, reject) {

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        if(clientdetail)
        {
          if(clientdetail.id > 0)
          {
            if(clientdetail.ispasswordupdate > 0)
            {
              resolve({
                  component: EditPayment
              });
            }
            else
            {
              resolve({
                  component: ChangePasswordPage
              });
            }
          }
          else
          {
              resolve({
                  component: LoginPage
              });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
    },
    routes: [
      {
        path: 'viewledger/:id/',
        popup: {
          component: ViewLedger,
        },
      },
      {
        path: 'thanks/:type/',
        history:false,
        pushState:false,
        reloadPrevious:true,
        popup: {
          component: BulkAddThanks,
        },
      }
    ],
  },
  {
    path: '/latepaymentreport/',
    async: function (routeTo, routeFrom, resolve, reject) {
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      if(clientdetail)
      {
        if(clientdetail.id > 0)
        {
          if(clientdetail.ispasswordupdate > 0)
          {
            resolve({
                component: LatePaymentReport
            });
          }
          else
          {
            resolve({
                component: ChangePasswordPage
            });
          }
        }
        else
        {
            resolve({
                component: LoginPage
            });
        }
      }
      else
      {
          resolve({
              component: LoginPage
          });
      }
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
