import React,{Component} from 'react';

import {
    Fab,
    Icon,
  } from 'framework7-react';

  export default class DownloadAppFav extends Component {
    constructor(props) {
      super(props);

      this.state = {

      };
    }

    navigateToLink(link){
        
      if(this.$f7.online)
      {
        this.$f7.views.main.router.navigate(`/${link}/`);
      }

    }
    
    render(){

        const{position, text} = this.props;

          return(
            <Fab position={position} slot="fixed" text={text} onClick={()=>{
                if(this.$f7.online)
                {
                    this.props.getLatepaymentReportPDF();
                }
              }}>
                <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
            </Fab>
          );

    }
}