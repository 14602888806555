import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer, FilterContext } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
    ListInput,
    Row,
    Col,
  } from 'framework7-react';

  export default class PaymentRegisterFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startdate = today.getFullYear()+'-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+currday;
 
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'Select',
        arealist:[],
        linelist:[],
        linemanlist:[],
        hawkerlist:[],
        hassmartline:false,
        hassmartlineman:false,
        hassmarthawker:false,
        hassmartarea:false,
        filtervars:[],
        paymenttype:'all',
      };

      this.areaAutocompletePopup     = this.areaAutocompletePopup.bind(this);
      this.lineAutocompletePopup     = this.lineAutocompletePopup.bind(this);
      this.linemanAutocompletePopup  = this.linemanAutocompletePopup.bind(this);
      this.hawkerAutocompletePopup   = this.hawkerAutocompletePopup.bind(this);
      this.applyFilter               = this.applyFilter.bind(this);
      this.handleUserInput           = this.handleUserInput.bind(this);
      this.checkSingleHawker         = this.checkSingleHawker.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{

        this.areaAutocompletePopup();
        this.lineAutocompletePopup();
        this.hawkerAutocompletePopup();

        this.hideSplashScreen();

        let{lineid, hawkerid, filterline, filterhawker, areaid, filterarea, monthyear, startdate, enddate, paymenttype} = this.state.filtervars;

        if(Number(areaid) < 1 || areaid === "NaN")
        {
          areaid      = "";
          filterarea  = "Select";
        }

        if(Number(lineid) < 1 || lineid === "NaN")
        {
          lineid      = "";
          filterline  = "Select";
        }
        
        if(Number(hawkerid) < 1 || hawkerid === "NaN")
        {
          hawkerid      = "";
          filterhawker  = "Select";
        }

        this.setState({
          areaid:areaid,
          lineid:lineid,
          hawkerid:hawkerid,
          filterarea:filterarea,
          filterline:filterline,
          filterhawker:filterhawker,
          monthyear:monthyear,
          startdate:startdate,
          enddate:enddate,
          paymenttype:paymenttype,
        },()=>{
          /*this.$$('#autocomplete-startmonthyear-name2').find('.item-after').text(this.state.filtermonthyear);*/
          this.$$('#autocomplete-area-popup').find('.item-after').text(this.state.filterarea);
          this.$$('#autocomplete-line-popup').find('.item-after').text(this.state.filterline);
        })

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();
      /*self.linemanAutocomplete.destroy();*/
      self.hawkerAutocomplete.destroy();

    }

    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              fromarea:'paymentregister',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-area-popup').find('.item-after').text(value[0].name);

            let id    = value[0].id;
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              areaid:id,
              filterarea:name
            },()=>{
              if(self.state.areaid === "-1")
              {
                self.setState({
                  lineid:'-1',
                  filterline:'All Line'
                },()=>{
                  $('#autocomplete-line-popup').find('.item-after').text("All Line");
                })
              }
              else
              {
                self.setState({
                  lineid:'',
                  filterline:'Select'
                },()=>{
                  $('#autocomplete-line-popup').find('.item-after').text("Select");
                })
              }
            });
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              fromarea:'paymentregister',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-line-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              lineid:id,
              filterline:name
            },()=>{
              self.checkSingleHawker();
            });

          },
        },
      });
    }

    linemanAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      const searchlinemanlist  = this.state.linemanlist;

      self.linemanAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-lineman-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectlineman/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinemanlist.length; i += 1) {
            if (searchlinemanlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinemanlist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            $('#autocomplete-lineman-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              linemanid:id,
              filterlineman:name
            });

          },
        },
      });
    }

    hawkerAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      self.hawkerAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-hawker-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selecthawker/',
        requestSourceOnOpen:true,
        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetHawker',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    hawkerlist:res.recordlist,
                  }
                },()=>{

                  const searchhawkerlist  = self.state.hawkerlist;

                  for (let i = 0; i < searchhawkerlist.length; i += 1) {
                    if (searchhawkerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchhawkerlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            /*$('#autocomplete-hawker-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              hawkerid:id,
              filterhawker:name
            });

          },
        },
      });
    }

    applyFilter(){

      if(!this.$f7.online)
      {
        return;
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    getLineman(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetLineman',
          type:'customerfilter',
          clientid:self.state.clientdetail.id,
      };

      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/lineman.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              app.preloader.hide();

              app.ptr.done();            

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({

                  linemanlist:resdata.recordlist,
                  isptr:false

                },()=>{

                  if(this.state.hassmartlineman === false)
                  {
                    this.setState({hassmartlineman:true});
                    this.linemanAutocompletePopup();
                  }
                  
                });
              }
              else
              {
                this.setState({

                    linemanlist:[],
                    isptr:false

                },()=>{

                  if(this.state.hassmartlineman === false)
                  {
                    this.setState({hassmartlineman:true});
                    this.linemanAutocompletePopup();
                  }
                  
                });
              }

          }).catch(e => {

              console.log(e);

          });

      }, 500);

    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    checkSingleHawker(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
        Mode:'CheckHawker',
        type:'sequencefilter',
        clientid:self.state.clientdetail.id,
        areaid:self.state.areaid,
        lineid:self.state.lineid,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({

                hawkerid:resdata.recordlist.id,
                filterhawker:resdata.recordlist.name

              });
            }
            else
            {
              this.setState({

                hawkerid:'',
                filterhawker:'Select',

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){

      const{lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, paymenttype} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{applyPaymentRegisterFilter}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="PaymentRegisterFilter">
                        <Block>
                        <List noHairlinesMd style={{marginBottom:'5px'}}>
                            <ListInput
                                name="startdate"
                                id="startdate"
                                label="Start Date"
                                placeholder="Start Date"
                                type="date"
                                value={startdate}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                            <ListInput
                                name="enddate"
                                id="enddate"
                                label="End Date"
                                placeholder="End Date"
                                type="date"
                                value={enddate}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                          <ListItem link="#" id="autocomplete-area-popup" title="Area" after="Select"></ListItem>
                          <ListItem link="#" id="autocomplete-line-popup" title="Line" after="Select"></ListItem>
                          {/*<ListItem link="#" id="autocomplete-lineman-popup" title="Lineman" after="Select"></ListItem>*/}
                          <ListItem link="#" id="autocomplete-hawker-popup" title="Hawker" after={filterhawker}></ListItem>
                        </List>
                        <p style={{marginLeft:'15px'}}>Payment Type</p>
                        <Row noGap>
                            <Col width="20">
                                <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="all" name="paymenttype" checked={paymenttype === 'all'} title="All"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                            <Col width="40">
                                <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="automatic" name="paymenttype" checked={paymenttype === 'automatic'} title="Automatic"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                            <Col width="40">
                                <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="manual" name="paymenttype" checked={paymenttype === 'manual'} title="Manual"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                        </Row>                        
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                          let tempstartdate   = new Date(startdate);
                          let tempenddate     = new Date(enddate);

                          let checkenddate = tempstartdate;
                          checkenddate.setDate(checkenddate.getDate() + 31);
                    
                          if(tempenddate.getTime() > checkenddate.getTime())
                          {
                              const toastWithButton = this.$f7.toast.create({
                                  text: "Start and End date difference can not be larger than 31 days",
                                  closeButton: true,
                                  closeTimeout: 3000,
                              });
                      
                              toastWithButton.open();
                      
                              return false;
                          }
                          
                          Promise.all([
                            setTimeout(()=> {
              
                                applyPaymentRegisterFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, paymenttype);
                  
                            }, 500)
                            
                          ])
                          .then(() => {
              
                            setTimeout(()=> {
              
                              this.$$('.popup-close').trigger('click');
                  
                            }, 500)
                            
                          })

                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}