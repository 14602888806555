import React,{Component,Fragment} from 'react';
import AppItemNotFound from './appitemnotfound';
import AppCardItemLoading from './appcarditemloading';
import AppFav from './appfav';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
  } from 'framework7-react';

  export default class Managegallery extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        isavailable:false,
        loaded:false,
        imagelist:[],
        actionGridOpened: false,
        oneGroupOpened: false,
        deleterecordid:'',
        isptr:false,
        effect:'blink',
      };

      this.getAllGalleryImage   = this.getAllGalleryImage.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);      
    }

    componentDidMount(){
  
     let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.hideSplashScreen();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          setTimeout(()=> {

            this.getAllGalleryImage();
            
          }, 200)

        })
        
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
          Mode:'DeleteImage',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/gallery.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              let tempImages	= [...this.state.imagelist];
              tempImages	= tempImages.filter(singleitem => singleitem.id !== this.state.deleterecordid);
        
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                    imagelist:tempImages
                  }
                },()=>{
                  if(this.state.imagelist.length < 1)
                  {
                    this.setState(()=>{
                      return {
                        isavailable:false
                      }
                    });
                  }
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }
    getAllGalleryImage(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }

        this.setState({
          loaded:false
        });
  
        const bodyFormData = {
            Mode:'GetAllImages',
            clientid:this.state.clientdetail.id,
        };

        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/gallery.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  loaded:true,
                  isavailable:true,
                  imagelist:resdata.recordlist,
                  isptr:false,
                });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  isptr:false,
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);
    }
    navigateToLink(link){
        
        if(this.$f7.online)
        {
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/
        }

    }

    render(){

       const{isavailable, loaded, imagelist} = this.state;

      return(
            <Page pageContent={false} className="page" name="ManageStock">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Gallery</NavTitle>
                    <NavRight className="right-with-menu">
                    <Link onClick={()=>{

                      this.getAllGalleryImage();

                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                    </NavRight>
                  </Navbar>
                  <PageContent>
                  {
                    loaded ? (<Fragment>
                      {
                        isavailable ? (
                          <Fragment>
                            {
                                imagelist.map((list, loop)=>{
                                  return(
                                    <Card outline key={loop} className="recordset">
                                      <CardHeader
                                      className="date">{list.name}</CardHeader>
                                      <CardContent>
                                      {list.image && list.image !== "" ? (<div style={{display:'flex',justifyContent:'center'}}><img src={`${this.$f7.passedParams.serverurl}${list.image}`} style={{width:'70%'}}/></div>):null}
                                      {list.status ? (<p><b>Status:</b> {list.status}</p>):null}
                                      {list.order ? (<p><b>Order By:</b> {list.order}</p>):null}
                                      </CardContent>
                                      <CardFooter>
                                        <Link onClick={()=>{this.navigateToLink(`editgallery/${list.id}`)}} ignoreCache={true}>Edit</Link>
                                        {
                                          !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                            <Link onClick={() =>{
                                              if(this.$f7.online)
                                              {
                                                this.setState(()=>{
                                                  return {
                                                    deleterecordid:list.id
                                                  }
                                                },()=>{
                                                  this.refs.actionsOneGroup.open()
                                                })
                                              }
                                            }}>Delete</Link>
                                          ):(<div>&nbsp;</div>)
                                        }
                                      </CardFooter>
                                    </Card>
                                  );
                                })
                            }
                          </Fragment>
                        ):(<AppItemNotFound notfoundtext ="No gallery image found." />)
                      }
                    </Fragment>):(<AppCardItemLoading />)
                  }
                  </PageContent>
              <Actions ref="actionsOneGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteRecord()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <Actions ref="actionsSubstituteGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteSubstituteRecord()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <br /><br />
              <AppFav position="center-bottom" text="Add Image" icon="plus" url="addgallery" />
            </Page>
      );
    }
}