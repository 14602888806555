import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import { FilterConsumer } from '../context/FilterContext';
import AppFav from '../pages/appfav';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    BlockTitle,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Toolbar,
    Link,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Icon,
    Chip,
  } from 'framework7-react';

  export default class Payments extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startdate = today.getFullYear()+'-'+currmonth+'-'+currday;
      let enddate2   = today.getFullYear()+'-'+currmonth+'-'+currday;

      let filtervars  = {};

      if(this.props.$f7route.query)
      {
        let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker, openingbalanceid, filteropeningbalance, nameandphone, page, fromarea} = this.props.$f7route.query;

        if(Number(areaid) < 1 || areaid === "NaN" || areaid === undefined)
        {
          areaid      = "";
          filterarea  = "Select";
        }
  
        if(Number(lineid) < 1 || lineid === "NaN" || lineid === undefined)
        {
          lineid      = "";
          filterline  = "Select";
        }
        
        if(Number(hawkerid) < 1 || hawkerid === "NaN" || hawkerid === undefined)
        {
          hawkerid      = "";
          filterhawker  = "Select";
        }
       
        if(Number(openingbalanceid) < 1 || openingbalanceid === "NaN" || openingbalanceid === "undefined" || openingbalanceid === undefined)
        {
          openingbalanceid      = "";
          filteropeningbalance  = "Select";
        }
  
        if(nameandphone === "" || nameandphone === undefined)
        {
          nameandphone  = "";
        }

        if(Number(page) < 1 || page === "NaN" || page === undefined)
        {
          page      = "";
        }

        if(fromarea === "" || fromarea === undefined)
        {
          fromarea  = "";
        }

        filtervars  = {areaid:areaid, filterarea:filterarea, lineid:lineid, filterline:filterline, hawkerid:hawkerid, filterhawker:filterhawker, openingbalanceid:openingbalanceid, filteropeningbalance:filteropeningbalance, nameandphone:nameandphone, page:page, fromarea:fromarea};
      }
     
      this.state = {
        clientdetail:[],
        isfetched:false,
        isavailable:false,
        paymentlist:[],
        isptr:false,
        /*circulationdate:date,*/
        circulationdate:'',
        ismount:false,
        hasallcustomer:false,
        customerid:'',
        customername:'',
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        effect:'blink',
        page:1,
        perpage:'',
        totalpages:'',
        showpages:false,
        totalrecord:'',
        toolbarPosition:'bottom',
        deleterecordid:'',
        totalpayment:'',
        totaldiscount:'',
        totalcoupon:'',
        monthyear:'',
        filtermonthyear:'',
        enddate:'',
        startdate:startdate,
        enddate2:enddate2,
        paymenttype:'all',
        showpayment:true,
        showdiscount:true,
        showcoupon:true,
        filtervars:filtervars,
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getPaymentCustomer   = this.getPaymentCustomer.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.deleteRecordAction   = this.deleteRecordAction.bind(this);
      this.deleteFilterAction   = this.deleteFilterAction.bind(this);
      this.deleteRecord         = this.deleteRecord.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          action:this.props.f7route.params.action,
          customerid:this.props.f7route.params.customerid,
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getPaymentCustomer();
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        this.Paging('select');

      });

    }

    Paging(mode){

      let pageerror = "";

      if(!this.$f7.online)
      {
        return;
      }

      let temppage    = '';

      const currpage  = this.state.page;

      if(mode === 'previous')
      {
        temppage  = Number(this.state.page) - 1;
      }
      if(mode === 'next')
      {
        temppage  = Number(this.state.page) + 1;
      }
      if(mode === 'select')
      {
        temppage  = Number(this.state.page);
      }

      if(temppage < 1)
      {
        pageerror = "You are on first page";
      }

      if(temppage > Number(this.state.totalpages))
      {
        pageerror = "You are on last page";
      }

      if(pageerror !== "")
      {
           const toastWithButton = this.$f7.toast.create({
               text: pageerror,
               closeButton: true,
               closeTimeout: 3000,
           });
             
           toastWithButton.open();
           
           return;
      }

      if(temppage <= 0)
      {
        temppage  = 1;
      }

      if(temppage >= Number(this.state.totalpages))
      {
        temppage  = Number(this.state.totalpages);
      }

      this.setState(()=>{

        return{
          page:temppage,
          loaded:false
        }

      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          if(mode === 'select')
          {
            this.getPaymentCustomer();
          }
          else
          {
            if(Number(this.state.page) !== Number(currpage))
            {
              this.getPaymentCustomer();
            }
          }

        })

      })

    }

    getPaymentCustomer(){

        const self      = this;
        const app       = self.$f7;

        let temlinemanid  = self.state.clientdetail.linemanid;

        if(Number(self.state.clientdetail.linemanid) > 0)
        {
          temlinemanid  = Number(self.state.clientdetail.linemanid);
        }
  
        const bodyFormData = {
          Mode:'GetAllCustomerPayment',
          clientid:self.state.clientdetail.id,
          customerid:self.state.customerid,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          lineid:self.state.lineid,
          linemanid:temlinemanid,
          hawkerid:self.state.hawkerid,
          areaid:self.state.areaid,
          page:self.state.page,
          perpage:self.state.perpage,
          /*monthyear:self.state.monthyear,
          filtermonthyear:self.state.filtermonthyear,
          enddate:self.state.enddate,*/
          startdate:self.state.startdate,
          enddate2:self.state.enddate2,
          paymenttype:self.state.paymenttype,
          showamountpayment:Number(this.state.showpayment),
          showdiscount:Number(this.state.showdiscount),
          showcoupon:Number(this.state.showcoupon),
        };

        this.setState({
          isfetched:false
        });

        if(!this.$f7.online)
        {
          return false;
        }

        // Simulate Ajax Request
        setTimeout(()=> {
  
            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
  
            app.request.promise.post(`${app.passedParams.serverurl}api/payments.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                // We got user data from request
  
                // Hide Preloader
                app.preloader.hide();
  
                // Hide Pull To Refresh
                app.ptr.done();                
  
                const resdata = JSON.parse(res.data);
  
                if(resdata.success === true)
                {
                  this.setState({
  
                    paymentlist:resdata.recordset.paymentlist,
                    isptr:false,
                    isfetched:true,
                    isavailable:true,
                    perpage:Number(resdata.perpage),
                    totalpages:Number(resdata.totalpages),
                    paginglist:resdata.paginglist,
                    showpages:resdata.showpages,
                    totalrecord:resdata.totalrecord,
                    totalpayment:resdata.totalpayment,
                    totaldiscount:resdata.totaldiscount,
                    totalcoupon:resdata.totalcoupon,
  
                  },()=>{
  
                    this.Dom7('.subscription').addClass('animate-bottom');
  
                  });
                }
                else
                {
                  this.setState({

                    paymentlist:[],
                    totalrecord:"0",
                    totalpayment:"0",
                    totaldiscount:"0",
                    totalcoupon:"0",
                    isptr:false,
                    isfetched:true,
                    isavailable:false,
  
                  });
                }
  
            }).catch(e => {
                console.log(e);
            });
  
        },1000);
    }

    setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, startdate, enddate2, paymenttype, showpayment, showdiscount, showcoupon){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            lineid:lineid,
            linemanid:linemanid,
            hawkerid:hawkerid,
            filterarea:filterarea,
            filterline:filterline,
            filterlineman:filterlineman,
            filterhawker:filterhawker,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
            /*monthyear:monthyear,
            filtermonthyear:filtermonthyear,
            enddate:enddate,*/
            startdate:startdate,
            enddate2:enddate2,
            paymenttype:paymenttype,
            showpayment:showpayment,
            showdiscount:showdiscount,
            showcoupon:showcoupon
          }
  
        },()=>{
  
          /*if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || (this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined) || (this.state.monthyear !== "" && this.state.monthyear !== undefined) || (this.state.enddate !== "" && this.state.enddate !== undefined))*/
          if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || (this.state.searchkeyword !== "" && this.state.searchkeyword !== undefined) || (this.state.startdate !== "" && this.state.startdate !== undefined) || (this.state.enddate2 !== "" && this.state.enddate2 !== undefined))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getPaymentCustomer();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('paymentfilter/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    deleteRecordAction(recordid){

      const self	= this;
      const app	= self.$f7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.$f7.online)
              {
                this.setState(()=>{
                  return{
                    deleterecordid:recordid
                  }
                },()=>{

                  this.deleteRecord();

                })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'DeletePayment',
        clientid:this.state.clientdetail.id,
        recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/payments.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                  }
                },()=>{
                  this.getPaymentCustomer();
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    deleteFilterAction(){

      const self	= this;
      const app	= self.$f7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
            text: 'Yes',
            bold: true,
            color:'blue',
            onClick:()=>{
              if(this.$f7.online)
              {
                this.deleteFilter();
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    resendMessage(recordid, smscredits){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'ResendPaymentMessage',
        recordid:recordid,
        smscredits:smscredits,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/payments.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

        }).catch(e => {
          console.log(e);
        });

      }, 500);

    }

    getMessagePreviewToSend(recordid){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'GetPaymentMessagePreview',
        recordid:recordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/payments.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          if(resdata.success)
          {
            /*const parser        = new DOMParser();
            const msgpreviewhtml  = parser.parseFromString(resdata.preview, 'text/html').documentElement.textContent;*/
            
            app.dialog.create({
              title: 'Message Preview',
              text: resdata.preview,
              closeByBackdropClick: 'true',
              buttons: [
                {
                  text: 'Cancel',
                },
                {
                  text: 'Send',
                  onClick: () => {
                    this.resendMessage(recordid, resdata.smscredits);
                  },
                },
              ],
            }).open();
          }
          else
          {
            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();
          }

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){
        
      const{isfetched, hasallcustomer, hasfilter, filterarea, filterline, filterlineman, filterhawker, ismount, customerid, customername, paymentlist, isavailable, showpages, page, paginglist, totalpages, totalrecord, totalpayment, totaldiscount, totalcoupon, areaid, lineid, hawkerid, monthyear, filtermonthyear, enddate, startdate, enddate2, paymenttype, showpayment, showdiscount, showcoupon, filtervars} = this.state;

      let tempurl = "";

      if(customerid !== ""){tempurl = `makepayment/${customerid}/?areaid=${filtervars.areaid}&filterarea=${filtervars.filterarea}&lineid=${filtervars.lineid}&filterline=${filtervars.filterline}&hawkerid=${filtervars.hawkerid}&filterhawker=${filtervars.filterhawker}&openingbalanceid=${filtervars.openingbalanceid}&filteropeningbalance=${filtervars.filteropeningbalance}&nameandphone=${filtervars.nameandphone}&page=${filtervars.page}&fromarea=${filtervars.fromarea}`;}else{tempurl = `makepayment/${customerid}?areaid=${filtervars.areaid}&filterarea=${filtervars.filterarea}&lineid=${filtervars.lineid}&filterline=${filtervars.filterline}&hawkerid=${filtervars.hawkerid}&filterhawker=${filtervars.filterhawker}&openingbalanceid=${filtervars.openingbalanceid}&filteropeningbalance=${filtervars.filteropeningbalance}&nameandphone=${filtervars.nameandphone}&page=${filtervars.page}&fromarea=${filtervars.fromarea}`;}

      let datestart     = "";
      let newstartdate  = "";

      if(startdate !== "" && startdate !== undefined)
      {
        newstartdate = new Date(startdate);

        let currmonth = newstartdate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        datestart = newstartdate.getDate()+"-"+currmonth+"-"+newstartdate.getFullYear();
      }

      let dateend     = "";
      let newenddate  = "";

      if(enddate2 !== "" && enddate2 !== undefined)
      {
        newenddate = new Date(enddate2);

        let currmonth = newenddate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
      }

      let datarange = datestart+" - "+dateend;

      return(

      <FilterConsumer>{(value)=>{

        const{paymentfilterdata, applyPaymentFilter} = value;

        let tempfilterby  = {showpayment:paymentfilterdata.showpayment,showdiscount:paymentfilterdata.showdiscount,showcoupon:paymentfilterdata.showcoupon};

        if(ismount === true)
        {
          setTimeout(()=> {

            if(this.state.removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(this.state.removefiltername === 'area')
              {
                applyPaymentFilter(this.state.lineid, this.state.linemanid, this.state.hawkerid, this.state.filterline, this.state.filterlineman, this.state.filterhawker, '', '', monthyear, filtermonthyear, enddate, startdate, enddate2, paymenttype, tempfilterby);
              }
              else if(this.state.removefiltername === 'line')
              {
                applyPaymentFilter('', this.state.linemanid, this.state.hawkerid, '', this.state.filterlineman, this.state.filterhawker, this.state.areaid, this.state.filterarea, monthyear, filtermonthyear, enddate, startdate, enddate2, paymenttype, tempfilterby);
              }
              else if(this.state.removefiltername === 'lineman')
              {
                applyPaymentFilter(this.state.lineid, '', this.state.hawkerid, this.state.filterline, '', this.state.filterhawker, this.state.areaid, this.state.filterarea, monthyear, filtermonthyear, enddate, startdate, enddate2, paymenttype, tempfilterby);
              }
              else if(this.state.removefiltername === 'hawker')
              {
                applyPaymentFilter(this.state.lineid, this.state.linemanid, '', this.state.filterline, this.state.filterlineman, '', this.state.areaid, this.state.filterarea, monthyear, filtermonthyear, enddate, startdate, enddate2, paymenttype, tempfilterby);
              }
            }

            if(((paymentfilterdata.lineid !== this.state.lineid) && paymentfilterdata.lineid !== undefined) || ((paymentfilterdata.linemanid !== this.state.linemanid) && paymentfilterdata.linemanid !== undefined) || ((paymentfilterdata.hawkerid !== this.state.hawkerid) && paymentfilterdata.hawkerid !== undefined) || ((paymentfilterdata.areaid !== this.state.areaid) && paymentfilterdata.areaid !== undefined) || ((paymentfilterdata.startdate !== this.state.startdate) && paymentfilterdata.startdate !== undefined) || ((paymentfilterdata.enddate2 !== this.state.enddate2) && paymentfilterdata.enddate2 !== undefined) || ((paymentfilterdata.paymenttype !== paymenttype) && paymentfilterdata.paymenttype !== undefined) || ((paymentfilterdata.showpayment !== showpayment) && paymentfilterdata.showpayment !== undefined) || ((paymentfilterdata.showdiscount !== showdiscount) && paymentfilterdata.showdiscount !== undefined) || ((paymentfilterdata.showcoupon !== showcoupon) && paymentfilterdata.showcoupon !== undefined) || this.state.applyremovefilter === true)
            {
              this.setNewFilterData(paymentfilterdata.lineid, paymentfilterdata.linemanid, paymentfilterdata.hawkerid, paymentfilterdata.filterline, paymentfilterdata.filterlineman, paymentfilterdata.filterhawker, paymentfilterdata.areaid, paymentfilterdata.filterarea, paymentfilterdata.monthyear, paymentfilterdata.filtermonthyear, paymentfilterdata.enddate, paymentfilterdata.startdate, paymentfilterdata.enddate2, paymentfilterdata.paymenttype, paymentfilterdata.showpayment, paymentfilterdata.showdiscount, paymentfilterdata.showcoupon);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }
            
          }, 500)
        }
        
      return(
        <Page pageContent={false} className="page" name="Payments">
            <Navbar sliding={true}>
              <NavLeft>
                <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                <Link href={`/dashboard/`} className="clearmenupadding">
                  <img className="navbarlogo" src={scanlogo} alt="logo" />
                </Link>
              </NavLeft>
              <NavTitle>Payments</NavTitle>
              <NavRight className="right-with-menu">
              <Link onClick={()=>{

                Promise.all([
            
                  setTimeout(()=> {
        
                    this.Dom7('.waiting').addClass('animate-bottom');
        
                  }, 50)
        
                ])
                .then(() => {
        
                  this.getPaymentCustomer();

                });

              }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
              </NavRight>
            </Navbar>
                <PageContent>
                  {
                    hasfilter ? (
                      <Block strong className="selectedfilter" style={{marginTop:0}}>
                        {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                        {datarange ? (<Chip text={`Date : (${datarange})`} mediaBgColor="yellow" media={`D`} 
                        />):null}
                        {/*filtermonthyear ? (<Chip text={`Payment Month : ${filtermonthyear}`} mediaBgColor="green" media={filtermonthyear.toUpperCase().substring(0, 1)}/>):null*/}
                        {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                        onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'area'
                                }
                              },()=>{
                                /*this.refs.actionsFilterGroup.open()*/
                                this.deleteFilterAction();
                              })
                            }
                          }}
                        />):null}
                        {Number(lineid) > 0 && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="pink" media={filterline.toUpperCase().substring(0, 1)} deleteable
                        onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'line'
                                }
                              },()=>{
                                /*this.refs.actionsFilterGroup.open()*/
                                this.deleteFilterAction();
                              })
                            }
                          }}
                        />):null}
                        {Number(this.state.linemanid) > 0 && filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="green" media={filterlineman.toUpperCase().substring(0, 1)} deleteable 
                        onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'lineman'
                                }
                              },()=>{
                                /*this.refs.actionsFilterGroup.open()*/
                                this.deleteFilterAction();
                              })
                            }
                          }}
                        />):null}
                        {Number(hawkerid) > 0 && filterhawker !== "" ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                        onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'hawker'
                                }
                              },()=>{
                                /*this.refs.actionsFilterGroup.open()*/
                                this.deleteFilterAction();
                              })
                            }
                          }}
                        />):null}
                        {customerid && customername !== "" && customername !== undefined && customername !== null ? (<Chip text={`Customer : ${customername}`} mediaBgColor="orange" media={customername.toUpperCase().substring(0, 1)} deleteable 
                        onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'inventory'
                                }
                              },()=>{
                                /*this.refs.actionsFilterGroup.open()*/
                                this.deleteFilterAction();
                              })
                            }
                          }}
                        />):null}
                        {` `}
                        {/*paymenttype ? (<Chip text={`Payment Type : ${paymenttype.charAt(0).toUpperCase() + paymenttype.slice(1)}`} mediaBgColor="green" media={paymenttype.toUpperCase().substring(0, 1)}/>):null*/}
                        {paymenttype ? (<Chip text={`Payment Type : ${paymenttype === 'cashpaymentonly' ? `Cash Payment Only`:`${paymenttype.charAt(0).toUpperCase() + paymenttype.slice(1)}`}`} mediaBgColor="green" media={paymenttype.toUpperCase().substring(0, 1)}/>):null}
                        {
                          !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                            <Fragment>
                              &nbsp;<Chip text={`Payment : ${showpayment ? `Yes`:`No`}`} mediaBgColor="purple" media={showpayment ? `Y`:`N`}
                              />&nbsp;<Chip text={`Discount : ${showdiscount ? `Yes`:`No`}`} mediaBgColor="orange" media={showdiscount ? `Y`:`N`}
                              />&nbsp;<Chip text={`Coupon : ${showcoupon ? `Yes`:`No`}`} mediaBgColor="teal" media={showcoupon ? `Y`:`N`}
                              />
                            </Fragment>
                          ):null
                        }&nbsp;
                        <Chip text="Modify" color="blue" onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.$f7.views.main.router.navigate(`paymentfilter/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&monthyear=${monthyear}&filtermonthyear=${filtermonthyear}&enddate=${enddate}&startdate=${startdate}&enddate2=${enddate2}&paymenttype=${paymenttype}&showpayment=${Number(showpayment)}&showdiscount=${Number(showdiscount)}&showcoupon=${Number(showcoupon)}&fromarea=${filtervars.fromarea}&fromarea2=payment`);
                            }
                          /*this.openFilterPopup();*/
                        }}/>
                      </Block>
                    ):null
                  }

                  {
                    isfetched ? (<Fragment>
                    {
                      isavailable ? (<BlockTitle className="totalrec">Total Payments : {totalrecord}</BlockTitle>):null
                    }
                    {
                      isavailable ? (<BlockTitle className="totalrec">Total Amount : {totalpayment}</BlockTitle>):null
                    }
                    {
                      isavailable ? (<BlockTitle className="totalrec">Total Discount : {totaldiscount}</BlockTitle>):null
                    }
                    {
                      isavailable ? (<BlockTitle className="totalrec">Total Coupon : {totalcoupon}</BlockTitle>):null
                    }
                    {
                      isavailable ? (
                        <div className="subscription">
                        {
                          paymentlist ? (<Fragment>
                            <Row noGap className='rowwithbottomborder'>
                                <Col width='20' className="tableheader textalignleft">
                                    Date
                                </Col>
                                <Col width='20' className="tableheader textalignleft">
                                    Customer
                                </Col>
                                <Col width='15' className="tableheader textalignright">
                                    Pay. ID
                                </Col>
                                <Col width='20' className="tableheader textalignright">
                                    Payment
                                </Col>
                                <Col width='25' className="tableheader textalignright">
                                    &nbsp;
                                </Col>
                            </Row>
                            {
                              paymentlist.map((payment)=>{

                                return(
                                  <Row key={payment.serialno} noGap className='rowwithbottomborder'>
                                      <Col width='20' className="ledgercolumn textalignleft">
                                        {payment.date}
                                      </Col>
                                      <Col width='20' className="ledgercolumn textalignleft">
                                        {payment.name}
                                      </Col>
                                      <Col width='15' className="ledgercolumn">
                                        {payment.customerpaymentid}
                                      </Col>
                                      <Col width='15' className="ledgercolumn">
                                        <div>Payment : {payment.amount.toFixed()}</div>
                                        <div style={{color:Number(payment.discount) > 0 ?`#ff0000`:``}}>Discount : {payment.discount.toFixed()}</div>
                                        <div style={{color:Number(payment.coupon) > 0 ?`#ff0000`:``}}>Coupon : {payment.coupon.toFixed()}</div>
                                      </Col>
                                      <Col width='30' className="ledgercolumn">
                                        <Link onClick={() =>{
                                          
                                          if(this.$f7.online)
                                          {
                                            this.$f7router.navigate(`viewpaymentdetail/${payment.paymentid}/`);
                                          }

                                        }}><Icon f7="doc_text_fill"></Icon></Link>
                                        {
                                          !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                            <Link onClick={() =>{

                                              this.deleteRecordAction(payment.paymentid);
                                              
                                            }}><Icon f7="trash_circle_fill" color="red"></Icon></Link>
                                          ):(<span>&nbsp;</span>)
                                        }

                                        {
                                          payment.caneditpayment ? <Link onClick={() =>{

                                            this.navigateToLink(`editpayment/${payment.paymentid}`)
                                            
                                          }}><Icon f7="pencil_circle_fill"></Icon></Link>:<span>&nbsp;</span>
                                        }

                                        {
                                          !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                            <Link onClick={() =>{

                                              this.getMessagePreviewToSend(payment.paymentid);
                                              
                                            }}><Icon f7="envelope_fill"></Icon></Link>
                                          ):(<span>&nbsp;</span>)
                                        }

                                        <Link onClick={() =>{

                                          this.navigateToLink(`ledger/${payment.id}`)
                                          
                                        }}><Icon f7="chevron_right_circle_fill"></Icon></Link>
                                        {/*<Icon f7="chevron_right"></Icon>*/}
                                      </Col>
                                  </Row>
                                  );
                              })
                            }
                          </Fragment>):null
                        }
                        </div>
                      ):(<AppItemNotFound notfoundtext ="No Payment record found." />)
                    }
                    </Fragment>):(<AppListItemLoading />)
                  }
                  <br /><br /><br /><br /><br />
                </PageContent>
                  {
                    isavailable  && showpages ? (
                      <Toolbar position={this.state.toolbarPosition}>
                      {
                        (()=>{
                          if(page > 1)
                          {
                            return(
                              <Link className="paging" onClick={()=>{this.Paging('previous')}}>
                              <Icon f7="arrow_left_square_fill" ></Icon>
                              </Link>
                            );
                          }
                          else
                          {
                            return(
                              <Link href="#"></Link>
                            );
                          }
                        })()
                      }
                      <List className="droppage" noHairlines>
                        <ListInput
                          name="page"
                          type="select"
                          value={this.state.page}
                          onChange={this.handleUserInput}
                          placeholder="Please choose..."
                          >
                          {
                            paginglist.map((pages)=>{
                              return(<option key={pages.page} value={pages.page}>{pages.name}</option>);
                            })
                          }
                          </ListInput>
                        </List>
                        {
                          (()=>{
                            if(page < totalpages)
                            {
                              return(
                                <Link className="paging" onClick={()=>{this.Paging('next')}}>
                                <Icon f7="arrow_right_square_fill" ></Icon>
                                </Link>
                              );
                            }
                            else
                            {
                              return(
                                <Link href="#"></Link>
                              );
                            }
                          })()
                        }
                      </Toolbar>
                    ):null
                  }
                  <Fragment>
                    <AppFav position="center-bottom" text="Make Payment" icon="plus" url={`${tempurl}`} />
                    <AppFav position="right-bottom" text="" icon="doc_text_fill" url="customersoutstanding" />
                    <br />
                  </Fragment>
                </Page>
          );

        }}
        </FilterConsumer>

      );
    }
}