import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Block,
    BlockTitle,
    List,
    ListItem,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Row,
    Col,
    Icon,
    Toolbar,
    Button
  } from 'framework7-react';

  export default class AdminDashboard extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          showprogress:true,
          effect: 'blink',
          isloaded:false,
          adminid:'',
          clientlist:[],
          clientid:'',
          clientname:'',
          singleclient:[],
          accesstoken:'',
        };

        this.getClientList = this.getClientList.bind(this);
        this.applyFilter   = this.applyFilter.bind(this);
    }

    componentDidMount(){

      let adminid   = Number(localStorage.getItem('agency_adminid'));

      this.setState(()=>{
        return{
          adminid:adminid,
        }
      },()=>{
          /*this.getClientList();*/
          this.onPageInit();
          this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteClientPopup.destroy();

    }

    onPageInit(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      self.autocompleteClientPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-clientlist-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectclient/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/clientlist.php?t=${Date.now()}`,
            data: {
              Mode:'GetAllClients',
              adminid:self.state.adminid,
            },
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    clientlist:res.recordlist,
                  }
                },()=>{

                  const searchstatuslist  = self.state.clientlist;

                  for (let i = 0; i < searchstatuslist.length; i += 1) {
                    if (searchstatuslist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchstatuslist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-clientlist-popup').find('.item-after').text(value[0].name);

            let id            = Number(value[0].id);
            let name          = value[0].name;
            let singleclient  = value[0].clientdetail;
            let accesstoken   = value[0].accesstoken;

            self.setState({
                clientid:id,
                clientname:name,
                singleclient:singleclient,
                accesstoken:accesstoken,
            });

          },
        },
      });
    }

    getClientList(){

      setTimeout(() => {
          
        if(this.state.adminid !== undefined && this.state.adminid > 0)
        {
          const self      = this;
          const app       = self.$f7;

          const bodyFormData = {
              Mode:'GetAllClients',
              adminid:this.state.adminid,
          };
    
          // Simulate Ajax Request
          setTimeout(()=> {
  
            const accesstoken  = localStorage.getItem('agency_accesstoken');
  
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
  
            app.request.promise.post(`${app.passedParams.serverurl}api/clientlist.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState(()=>{
                  return{
                    loaded:true,
                    isavailable:true,
                    clientlist:resdata.recordlist,
                  }
                });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  clientlist:[],
                });
              }
      
            }).catch(e => {
                console.log(e);
            });
    
          }, 100);

        }

      }, 500);
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    applyFilter(){

        const self    = this;
        const app     = self.$f7;

        if(Number(this.state.clientid) > 0)
        {
          localStorage.setItem("agency_clientdetail",JSON.stringify(this.state.singleclient));
          localStorage.setItem("agency_accesstoken",this.state.accesstoken);
          this.$f7.views.main.router.navigate(`/dashboard/`);
        }
        else
        {
          const toastWithButton = app.toast.create({
              text: 'Please select a client',
              closeButton: true,
              closeTimeout: 3000,
          });

          toastWithButton.open();

          return false;
        }

    }

    render(){

        const{loaded, isavailable, clientlist, effect} = this.state;

      return(
          <Page name="AdminDashboard">
            <Navbar sliding={true}>
                <NavLeft>
                    <Link href="/admindashboard/" className="clearmenupadding"><img className="navbarlogo" src={scanlogo} alt="logo" /></Link>
                </NavLeft>
                <NavTitle>Dashboard</NavTitle>
                <NavRight className="right-with-menu">
                </NavRight>
            </Navbar>
            <Block>
              <BlockTitle medium>Clients List</BlockTitle>
              <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                  <ListItem link="#" id="autocomplete-clientlist-popup" title="Client" after="Select"></ListItem>
              </List>
            </Block>
            <br />
            <Toolbar position="bottom">
                <Row style={{width:'100%'}}>
                    <Col width="40"></Col>
                    <Col width="60"><Button fill color="blue" style={{marginRight:'.4rem'}}
                    onClick={()=>{
                        this.applyFilter();
                    }}
                    ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Login As Client</Button></Col>
                </Row>
            </Toolbar>
          </Page>
        );
    }
}