import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Block,
    List,
    ListInput,
    ListItem,
    Link
  } from 'framework7-react';

  export default class AddStock extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        shortcode:'',
        phone:'',
        status:1,
        shortcode:'',
        categoryid:'',
        categorylist:[],
        categoryname:'Select',
        hassmartcategory:false,
        issmartcategory:false,
        frequencyid:'',
        frequencylist:[],
        frequencyname:'Select',
        hassmartfrequency:false,
        issmartfrequency:false,
      };

      this.handleUserInput = this.handleUserInput.bind(this);

      this.categoryAutocompletePopup    = this.categoryAutocompletePopup.bind(this);
      this.frequencyAutocompletePopup   = this.frequencyAutocompletePopup.bind(this);
      this.getAllStockCategory          = this.getAllStockCategory.bind(this);
      this.getAllStockFrequency         = this.getAllStockFrequency.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.getAllStockCategory();
        this.getAllStockFrequency();
        this.hideSplashScreen();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteCategoryPopup.destroy();
      self.autocompleteFrequencyPopup.destroy();

    }

    categoryAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchcategorylist  = this.state.categorylist;

      self.autocompleteCategoryPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-category-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectcategory/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchcategorylist.length; i += 1) {
            if (searchcategorylist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchcategorylist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-category-name2').text(value[0].name);
            $('.autocomplete-category-popup').removeClass('error');
            
            self.setState({
              categoryid:value[0].id,
              categoryname:value[0].name,
              issmartcategory:true,
            });

          },
        },
      });
    }
    getAllStockCategory(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
          Mode:'GetStockCategory',
          clientid:clientdetail.id
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                categorylist:resdata.recordlist,
              },()=>{

                if(this.state.hassmartcategory === false)
                {
                  this.setState({hassmartcategory:true});
                  this.categoryAutocompletePopup();
                }
                
              });
            }
            else
            {
              this.setState({
                categorylist:[],
              },()=>{

                if(this.state.hassmartcategory === false)
                {
                  this.setState({hassmartcategory:true});
                  this.categoryAutocompletePopup();
                }

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }
    frequencyAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchfrequencylist  = this.state.frequencylist;

      self.autocompleteFrequencyPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-frequency-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectfrequency/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchfrequencylist.length; i += 1) {
            if (searchfrequencylist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchfrequencylist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-frequency-name2').text(value[0].name);
            $('.autocomplete-frequency-popup').removeClass('error');
            
            self.setState({
              frequencyid:value[0].id,
              frequencyname:value[0].name,
              issmartfrequency:true,
            });

          },
        },
      });
    }
    getAllStockFrequency(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
          Mode:'GetStockFrequency',
          clientid:clientdetail.id
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
            
            if(resdata.success === true)
            {
              this.setState({
                frequencylist:resdata.recordlist,
              },()=>{

                if(this.state.hassmartfrequency === false)
                {
                  this.setState({hassmartfrequency:true});
                  this.frequencyAutocompletePopup();
                }
                
              });
            }
            else
            {
              this.setState({
                frequencylist:[],
              },()=>{

                if(this.state.hassmartfrequency === false)
                {
                  this.setState({hassmartfrequency:true});
                  this.frequencyAutocompletePopup();
                }

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      let errmsg    = "";

      if(!this.$f7.online)
      {
        return;
      }

      app.input.validateInputs('.adddata');

      if(this.state.categoryid === "" || this.state.categoryid === undefined)
      {
        errmsg  = "Please select a category";
        this.$$('.autocomplete-category-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-category-popup').removeClass('error');
      }
      if((this.state.frequencyid === "" || this.state.frequencyid === undefined))
      {
        this.$$('.autocomplete-frequency-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-frequency-popup').removeClass('error');
      }
      if((this.state.frequencyid === "" || this.state.frequencyid === undefined) && errmsg === "")
      {
        errmsg  = "Please select a frequency";
      }
      if(this.state.name === "" && errmsg === "")
      {
        errmsg  = "Please enter stock name";
      }
      if(this.state.shortcode === "" && errmsg === "")
      {
        errmsg  = "Please enter a short code";
      }
      if(errmsg !=='')
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
        return false;
      }

      const bodyFormData = {
          Mode:'AddStock',
          clientid:self.state.clientdetail.id,
          cityid:self.state.clientdetail.cityid,
          stateid:self.state.clientdetail.stateid,
          name:self.state.name,
          categoryid:self.state.categoryid,
          frequencyid:self.state.frequencyid,
          status:Number(self.state.status),
          shortcode:self.state.shortcode,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            if(resdata.success !== true)
            {
              toastWithButton.open();
            }

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                    name:'',
                    /*categoryid:'',
                    categoryname:'Select',
                    frequencyid:'',
                    frequencyname:'Select',*/
                    shortcode:'',
                  }
                })
              ])
              .then(() => {
  
                $$('.item-content').removeClass('item-input-with-error-message');
                $$('.item-content').removeClass('item-input-invalid');

                this.$f7.views.main.router.navigate(`thanks/addstocksuccess/`);
  
              })
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){
      const{categoryname,frequencyname} = this.state; 
      return(
            <Page pageContent={false} className="page" name="AddStock">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Add Stock</NavTitle>
                    <NavRight className="right-with-menu">
                    </NavRight>
                  </Navbar>
                  <PageContent>
                  <Block>
                  <List noHairlinesMd>
                      <ListItem link="#" id="autocomplete-category-popup" className="autocomplete-category-popup" title="Category">
                          <div slot="after" id="autocomplete-category-name2">{categoryname}</div>
                      </ListItem>
                      <ListItem link="#" id="autocomplete-frequency-popup" className="autocomplete-frequency-popup" title="Frequency">
                          <div slot="after" id="autocomplete-frequency-name2">{frequencyname}</div>
                      </ListItem>
                      <ListInput
                          name="name"
                          className="adddata"
                          label="Name"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="Name"
                          value={this.state.name}
                          clearButton required validate
                      >
                      </ListInput>
                  
                      <ListInput
                          name="shortcode"
                          label="Short Code"
                          floatingLabel
                          type="textarea"
                          onInput={this.handleUserInput}
                          value={this.state.shortcode}
                          resizable
                          placeholder="Short Code"
                          className="adddata"
                          clearButton required validate
                      >
                      </ListInput>
                  </List>
              </Block>
              <br />
              </PageContent>
              <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
            </Page>
      );
    }
}