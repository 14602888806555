import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer, FilterContext } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Block,
    List,
    ListInput,
    ListItem,
    Fab,
    Icon,
    Toggle,
    Row,
    Col,
    Link,
    Popup,
    Toolbar,
    Button
  } from 'framework7-react';

  export default class EditLine extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        phone:'',
        status:1,
        remarks:'',
        isptr:false,
        areaid:'',
        arealist:[],
        colwidth:80,
        popupOpenedArea:false,
        ismount:false,
        areaname:false,
        issmartarea:false,
        hassmartarea:false
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.fetchNewArea       = this.fetchNewArea.bind(this);
      this.openAreaPopup      = this.openAreaPopup.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        if(Number(this.state.clientdetail.islineman) === 1 || Number(this.state.clientdetail.ismanager) === 1)
        {
          this.setState({colwidth:100});
        }
        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getArea();

        this.getDetail();
        this.hideSplashScreen();

      });
      
    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(!this.$f7.online)
        {
          return;
        }

        const bodyFormData = {
            Mode:'GetLineDetail',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.id,
        };

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
  
                name:resdata.linedetail.name,
                phone:resdata.linedetail.phone,
                status:resdata.linedetail.status,
                remarks:resdata.linedetail.remark,
                areaid:resdata.linedetail.areaid,
                isptr:false,

              },()=>{
                this.Dom7('#autocomplete-area-name').text(resdata.linedetail.areaname);
              });
            }
            else
            {
              this.setState({
  
                isptr:false,

              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchlinelist  = this.state.arealist;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinelist.length; i += 1) {
            if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-area-name').text(value[0].name);
            $('.autocomplete-area-popup').removeClass('error');
            
            self.setState({
              areaid:value[0].id
            });

          },
        },
      });
    }

    fetchNewArea(newareaid, newarea){

      this.setState(()=>{

        return{

          areaid:newareaid

        }

      },()=>{

        this.Dom7('#autocomplete-area-name').text(newarea);
        this.getArea();

      })

    }

    getArea(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
          Mode:'GetArea',
          clientid:clientdetail.id,
          ismanager:Number(this.state.clientdetail.ismanager),
          areamanagerid:Number(this.state.clientdetail.areamanagerid),
          areaids:this.state.clientdetail.areaids,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                arealist:resdata.recordlist,
              },()=>{
                if(this.state.hassmartarea === false)
                {
                  this.setState({hassmartarea:true});
                  this.areaAutocompletePopup();
                }
              });
            }
            else
            {
              this.setState({
                arealist:[],
              },()=>{
                if(this.state.hassmartarea === false)
                {
                  this.setState({hassmartarea:true});
                  this.areaAutocompletePopup();
                }
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    openAreaPopup(){

      this.setState(()=>{

        return{
          popupOpenedArea:!this.state.popupOpenedArea
        }

      });

    }

    saveDetail(){
      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      let errmsg    = "";

      if(!this.$f7.online)
      {
        return;
      }

      app.input.validateInputs('.adddata');

      if(this.state.areaid === "" && errmsg === "")
      {
        errmsg  = "Please select an Area";
      }

      if(this.state.areaid === "")
      {
        this.$$('.autocomplete-area-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-area-popup').removeClass('error');
      }

      if(this.state.name === "" && errmsg === "")
      {
        errmsg  = "Please enter name";
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
          Mode:'EditLine',
          clientid:self.state.clientdetail.id,
          recordid:self.props.f7route.params.id,
          name:self.state.name,
          phone:self.state.phone,
          status:Number(self.state.status),
          remarks:self.state.remarks,
          areaid:self.state.areaid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
  
            if(resdata.success === true)
            {
                router.navigate('/lines/');             
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){
      
      const{colwidth, clientdetail, areaid, ismount} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{singlearea} = value;

          if(ismount === true)
          {
            setTimeout(()=> {

              if((singlearea.id !== this.state.areaid) && singlearea.id > 0)
              {
                this.fetchNewArea(singlearea.id, singlearea.name);
              }
  
            }, 500) 
          }

      return(
            <Page pageContent={false} className="page" name="EditLine">
                <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Edit Line</NavTitle>
                    <NavRight className="right-with-menu">
                    </NavRight>
                </Navbar>
                <PageContent>
                <Block>
                    <Row>
                        <Col width={colwidth}>
                        <List noHairlinesMd className="zeromargin">
                          <ListItem link="#" id="autocomplete-area-popup" className="autocomplete-area-popup" title="Area">
                            <div slot="after" id="autocomplete-area-name">Select</div>
                          </ListItem>
                        </List>
                        </Col>
                        {
                          clientdetail.islineman || clientdetail.ismanager ? null:(
                            <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                              <Link onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.$f7router.navigate('addareapopup/')
                                }
                              }}>
                                <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                              </Link>
                            </Col>
                          )
                        }
                    </Row>
                    <List noHairlinesMd style={{marginBottom:'5px'}} className="zeromargin">

                      <ListInput
                          name="name"
                          label="Name"
                          floatingLabel
                          type="text"
                          onInput={this.handleUserInput}
                          placeholder="Name"
                          value={this.state.name}
                          clearButton required validate
                      >
                      </ListInput>

                      {/*<ListInput
                          name="phone"
                          label="Phone Number"
                          floatingLabel
                          type="tel"
                            onInput={(e)=>{
                            
                              let tempphone = e.target.value;
                              let mobile    = tempphone.replace(/ /g,'');
                              
                              if(tempphone.slice(0,1)=='+')
                              {
                                mobile = mobile.substring(3);
                              }
  
                              if(tempphone.slice(0,1)=='0')
                              {
                                mobile = mobile.substring(1);
                              }
  
                              if(mobile.length > 10)
                              {
                                return;
                              }
                              
                              this.setState({
                                phone:mobile
                              })
                          }}
                          placeholder="Phone number"
                          value={this.state.phone}
                          clearButton required validate
                      >
                      </ListInput>*/}

                      <ListInput
                          name="remarks"
                          label="Remarks"
                          floatingLabel
                          type="textarea"
                          onInput={this.handleUserInput}
                          value={this.state.remarks}
                          resizable
                          placeholder="Remarks"
                      >
                      </ListInput>

                      <ListItem title="Status">
                        <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                      </ListItem>

                    </List>
                </Block>
                <br /><br />
                </PageContent>
                <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
            </Page>
          );          
          
        }}</FilterConsumer>
      );
    }
}