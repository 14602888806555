import React,{Component, Fragment} from 'react';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
    ListInput,
    Row,
    Col,
  } from 'framework7-react';

  export default class CloseCustomerListFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }
      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let lastdate = lastDay.getDate();

      let startdate = today.getFullYear()+'-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+lastdate;
  
      this.state = {
        clientdetail:[],
        reporttype:0,
        startdate:startdate,
        enddate:enddate,
        startyear:'',
        inventorylist:[],
        inventoryid:'',
        inventoryname:'Select',
        areaid:'',
        arealist:[],
        areaname:'Select',
        lineid:'',
        linelist:[],
        linename:'Select',
        filtervars:[],
      };

      this.applyFilter            = this.applyFilter.bind(this);
      this.handleUserInput        = this.handleUserInput.bind(this);
      this.stockAutocompletePopup = this.stockAutocompletePopup.bind(this);
      this.onPageInit             = this.onPageInit.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{
        
        this.hideSplashScreen();
        this.stockAutocompletePopup();
        this.onPageInit();

        let{startdate, enddate, areaid, areaname, inventoryid, inventoryname, lineid, linename,reporttype} = this.state.filtervars;

        if(Number(areaid) < 1 || areaid === "NaN")
        {
          areaid    = "";
          areaname  = "Select";
        }

        if(Number(lineid) < 1 || lineid === "NaN")
        {
          lineid    = "";
          linename  = "Select";
        }

        if(Number(inventoryid) < 1 || inventoryid === "NaN")
        {
          inventoryid    = "";
          inventoryname  = "Select";
        }
        
        this.setState({
          reporttype:reporttype,
          startdate:startdate,
          enddate:enddate,
          areaid:areaid,
          areaname:areaname,
          lineid:lineid,
          linename:linename,
          inventoryid:inventoryid,
          inventoryname:inventoryname,
        })

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteStockPopup.destroy();
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();

    }

    stockAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteStockPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-stock-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectstock/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetAllInventory',
              clientid:self.state.clientdetail.id,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    inventorylist:res.inventorylist,
                  }
                },()=>{

                  const searchlinelist  = self.state.inventorylist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*self.Dom7('#autocomplete-stock-name2').text(value[0].name);
            $('.autocomplete-stock-popup').removeClass('error');*/
            
            self.setState({
              inventoryid:value[0].id,
              inventoryname:value[0].name
            });

          },
        },
      });
    }

    onPageInit(){
      
      const self  = this;
      const app   = self.$f7;

      self.autocompleteAreaPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
              Mode:'GetArea',
              clientid:self.state.clientdetail.id,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.setState({
              areaid:value[0].id,
              areaname:value[0].name,
              lineid:'',
              linename:'Select'
            });
          },
        },
      });

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            let id    = Number(value[0].id);
            let name  = value[0].name;

            self.setState({
              lineid:id,
              linename:name,
            });

          },
        },
      });
    }

    applyFilter(){

      if(!this.$f7.online)
      {
        return;
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    render(){

      const{startdate, enddate, inventoryid, inventoryname, areaid, areaname, lineid, linename,reporttype} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{applyCloseCustomerFilter}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="CloseCustomerListFilter">
                        <Block>
                        <Row noGap>
                            <Col width="50">
                              <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value={0} name="reporttype"
                                    onChange={this.handleUserInput} title="Closed Subscriptions"
                                    checked={Number(reporttype) !== 1}
                                    >
                                    </ListItem>
                                </List>
                            </Col>
                            <Col width="50">
                              <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListItem radio value={1} name="reporttype"
                                onChange={this.handleUserInput} title="In-Active Customers"
                                checked={Number(reporttype) === 1}
                                >
                                </ListItem>
                              </List>
                            </Col>
                          </Row>
                          {(()=>{
                              if(Number(this.state.reporttype) !== 1)
                              {
                                return( 
                                  <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                                    <ListInput
                                        name="startdate"
                                        id="startdate"
                                        label="Start Date"
                                        placeholder="Start Date"
                                        type="date"
                                        value={startdate}
                                        onChange={this.handleUserInput}
                                        calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                                    >
                                    </ListInput>
                                    <ListInput
                                        name="enddate"
                                        id="enddate"
                                        label="End Date"
                                        placeholder="End Date"
                                        type="date"
                                        value={enddate}
                                        onChange={this.handleUserInput}
                                        calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                                    >
                                    </ListInput>
                                </List>
                               );
                             }
                          })()}
                          <List noHairlinesMd style={{marginTop:'5px',marginBottom:'5px'}}>
                            <ListItem link="#" id="autocomplete-area-popup" className="autocomplete-area-popup" title="Area">
                              <div slot="after" id="autocomplete-area-name">{areaname}</div>
                            </ListItem>

                            <ListItem link="#" id="autocomplete-line-popup" className="autocomplete-line-popup" title="Line">
                              <div slot="after" id="autocomplete-line-name">{linename}</div>
                            </ListItem>

                            <ListItem link="#" id="autocomplete-stock-popup" className="autocomplete-stock-popup" title="Stock">
                                <div slot="after" id="autocomplete-stock-name2">{inventoryname}</div>
                            </ListItem>
                        </List>
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                            let tempstartdate   = new Date(startdate);
                            let tempenddate     = new Date(enddate);

                            if(tempenddate.getTime() < tempstartdate.getTime())
                            {
                                const toastWithButton = this.$f7.toast.create({
                                    text: "Start date can't be less then End date",
                                    closeButton: true,
                                    closeTimeout: 3000,
                                });
                        
                                toastWithButton.open();
                        
                                return false;
                            }

                            Promise.all([
                                setTimeout(()=> {

                                  applyCloseCustomerFilter(startdate, enddate, areaid, areaname, inventoryid, inventoryname, lineid, linename,reporttype);

                                }, 500)
                                
                            ])
                            .then(() => {
                
                                setTimeout(()=> {
                
                                this.$$('.popup-close').trigger('click');
                    
                                }, 500)
                                
                            })

                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}