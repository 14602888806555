import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Link
  } from 'framework7-react';

  export default class AddDroppingPoint extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        phone:'',
        status:1,
        remarks:'',
      };

      this.handleUserInput = this.handleUserInput.bind(this);
      
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      if(!this.$f7.online)
      {
          return;
      }

      const toastWithButton = app.toast.create({
        text: 'Please enter all required field.',
        closeButton: true,
        closeTimeout: 3000,
      });

      app.input.validateInputs('.adddata');

      if(this.state.name === "")
      {
        toastWithButton.open();
        return false;
      }

      const bodyFormData = {
          Mode:'AddDroppingPoint',
          clientid:self.state.clientdetail.id,
          name:self.state.name,
          phone:self.state.phone,
          status:Number(self.state.status),
          remarks:self.state.remarks,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/droppingpoint.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            if(resdata.success !== true)
            {
              toastWithButton.open();
            }

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                    name:'',
                    phone:'',
                    status:1,
                    remarks:'',
                  }
                })
              ])
              .then(() => {
  
                $$('.item-content').removeClass('item-input-with-error-message');
                $$('.item-content').removeClass('item-input-invalid');

                this.$f7.views.main.router.navigate(`thanks/adddroppingpointsuccess/`);
  
              })
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){
       
      return(
            <Page pageContent={false} className="page" name="AddDroppingPoint">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Add Dropping Point</NavTitle>
                  <NavRight className="right-with-menu">
                  </NavRight>
                </Navbar>
                <PageContent>
                  <Block>
                    <List noHairlinesMd>
                        <ListInput
                            name="name"
                            className="adddata"
                            label="Name"
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="Name"
                            value={this.state.name}
                            clearButton required validate
                        >
                        </ListInput>
                    
                        <ListInput
                            name="remarks"
                            label="Remarks"
                            floatingLabel
                            type="textarea"
                            onInput={this.handleUserInput}
                            value={this.state.remarks}
                            resizable
                            placeholder="Remarks"
                        >
                        </ListInput>

                        <ListItem title="Status">
                          <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                    </List>
                  </Block>
                </PageContent>
              <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
            </Page>
      );
    }
}