import React,{Component, Fragment} from 'react';
import { CommonConsumer } from '../context/CommonContext';
import logo404 from '../assets/images/orlo404.png';

import {
    BlockTitle,
    Link,
    Block,
    List,
    Row,
    Col,
    ListItem,
    Chip,
    Button,
    Fab,
    Icon,
    Page,
  } from 'framework7-react';

  export default class SMSCreditTab extends Component {
    constructor(props) {
      super(props);

      this.state = {
        smsloglist:[],
        clientdetail:'',
        isptr:false,
        isloaded:false,
        internetstatus:navigator.onLine ? "online" : "offline",
        ismount:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
        totalavailable:0,
        totalpending:0,
        totalpurchase:0,
        totalrefunds:0,
        totalused:0,
        totalbalance:0,
        hascredit:false,
        haspendingcredit:false,
        totalcampaignused:0,
        totalpaymentused:0,
        totalmiscused:0,
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
      this.getSMSCreditLog    = this.getSMSCreditLog.bind(this);

    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getSMSCreditLog();
        this.hideSplashScreen();
      });

      this.updateOnlineStatus();

      window.addEventListener('online',  this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    updateOnlineStatus(){

      if(this.state.isloaded  === true)
      {
        let intstatus	= "offline";

        intstatus		= navigator.onLine ? "online" : "offline";

        this.setState(()=>{
            return{
                internetstatus: intstatus,
            };
        },()=>{

            const self      = this;
            const app       = self.$f7;

            if(this.state.internetstatus === "offline")
            {
                const toastWithButton = app.toast.create({
                    text: "No internet connection",
                });
                  
                toastWithButton.open();
            }
            else
            {
                const toastWithButton = app.toast.create({
                    text: "Internet connected",
                    closeButton: true,
                    closeTimeout: 3000,
                });
                  
                toastWithButton.open();
            }
        });
      }
      else
      {
        this.setState({
            isloaded:true
        });
      }
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getSMSCreditLog(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetSMSCreditLog',
            clientid:self.state.clientdetail.id,
        };

        this.setState(()=>{

          return{
            isdatafetched:false
          }

        },()=>{

          setTimeout(()=> {
  
            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
    
            app.request.promise.post(`${app.passedParams.serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                // Hide Pull To Refresh
                app.ptr.done();
  
                const resdata = JSON.parse(res.data);
      
                if(resdata.success === true)
                {
                    this.setState(()=>{
                        return{
                            smsloglist:resdata.recordlist,
                            totalavailable:resdata.availablecredit,
                            totalused:resdata.totalused,
                            totalpending:resdata.totalpending,
                            totalpurchase:resdata.totalpurchase,
                            totalrefunds:resdata.totalrefunds,
                            totalbalance:resdata.totalbalance,
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:true,
                            hascredit:resdata.hascredit,
                            haspendingcredit:resdata.haspendingcredit,
                            totalcampaignused:resdata.totalcampaignused,
                            totalpaymentused:resdata.totalpaymentused,
                            totalmiscused:resdata.totalmiscused,
                        }
                    })
                }
                else
                {
                    this.setState(()=>{
                        return{
                            isptr:false,
                            isdatafetched:true,
                            hasdataavailable:false,
                            totalpurchase:0,
                            totalrefunds:0,
                            totalused:0,
                            totalbalance:0,
                            totalcampaignused:0,
                            totalpaymentused:0,
                            totalmiscused:0,
                        }
                    });
                }
      
            }).catch(e => {
                console.log(e);
            });
  
          },500);

        });
    }

    render(){

      const{smsloglist, isdatafetched, hasdataavailable, totalused, totalpending, totalpurchase,totalrefunds, totalbalance, hascredit, totalavailable, totalcampaignused, totalpaymentused, totalmiscused} = this.state;

      return(
        <CommonConsumer>{(value)=>{
          
          const{navigateToLink, internetstatus} = value;
       
          return(
              <Page>
              {
                isdatafetched ? (
                  <Fragment>
                  {
                    hasdataavailable ? (
                        <Fragment>
                        {
                          hascredit ? (<Fragment>
                            <br />
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='70' className="tableheader">Summary</Col>
                              <Col width='30' className="tableheader">Count</Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='70' className="ledgercolumn textalignleft">Total Purchase</Col>
                              <Col width='30' className={`ledgercolumn`}>{totalpurchase}</Col>
                            </Row>
                            {/*<Row noGap className='rowwithbottomborder'>
                              <Col width='70' className="ledgercolumn textalignleft">Total Used</Col>
                              <Col width='30' className={`ledgercolumn`}>{totalused}</Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='70' className="ledgercolumn textalignleft">Total Refund</Col>
                              <Col width='30' className={`ledgercolumn`}>{totalrefunds}</Col>
                            </Row>*/}
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='70' className="ledgercolumn textalignleft">Total Campaign Credits</Col>
                              <Col width='30' className={`ledgercolumn`}>{totalcampaignused}</Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='70' className="ledgercolumn textalignleft">Total Payment Credits</Col>
                              <Col width='30' className={`ledgercolumn`}>{totalpaymentused}</Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='70' className="ledgercolumn textalignleft">Total Re-Send SMS Credits</Col>
                              <Col width='30' className={`ledgercolumn`}>{totalmiscused}</Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='70' className="ledgercolumn textalignleft">Total Balance</Col>
                              <Col width='30' className={`ledgercolumn`}>{totalbalance}</Col>
                            </Row>

                          </Fragment>):<Block>
                          <br/><br/>
                          <p style={{textAlign:'center',color:'#ff0000',fontSize:'1.2rem'}}>You don't have credit in your account</p>
                          <br/>
                          <Row>
                              <Col width="20"></Col>
                              <Col width="60"><Button fill color="blue" onClick={()=>{
                                if(internetstatus === "online")
                                {
                                  navigateToLink('addsmscredit');
                                }
                              }}>Recharge Now</Button></Col>
                              <Col width="20"></Col>
                          </Row>
                          <br />
                          <Row>
                            <Col>
                                <img src={logo404} className="norecimg" />
                                <BlockTitle className="errmsg">No record found.</BlockTitle>
                            </Col>
                          </Row>
                        </Block>
                        }
                        </Fragment>
                    ):(
                        <Block>
                          <br/><br/>
                          <p style={{textAlign:'center',color:'#ff0000',fontSize:'1.2rem'}}>You don't have credit in your account</p>
                          <br/>
                          <Row>
                              <Col width="20"></Col>
                              <Col width="60"><Button fill color="blue" onClick={()=>{
                                if(internetstatus === "online")
                                {
                                  navigateToLink('addsmscredit');
                                }
                              }}>Recharge Now</Button></Col>
                              <Col width="20"></Col>
                          </Row>
                          <br />
                          <Row>
                            <Col>
                                <img src={logo404} className="norecimg" />
                                <BlockTitle className="errmsg">No record found.</BlockTitle>
                            </Col>
                          </Row>
                        </Block>
                      )
                  }
                  </Fragment>
                ):(
                  <Fragment>
                  <List mediaList className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>                              
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>                              
                    <ListItem
                      title="Title Title Title Title Title Title Title Title Title"
                    >
                    </ListItem>
                  </List>
                </Fragment>
                )
              }
              {
                totalavailable ? (
                  <Fab position="center-bottom" slot="fixed" text="Recharge" onClick={()=>{
                      if(internetstatus === "online")
                      {
                        navigateToLink('addsmscredit');
                      }
                    }}>
                    <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
                  </Fab>
                ):null
              }
              <br /><br /><br />
              </Page>
          );

        }}</CommonConsumer>

      )
    }
}