import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';

import { SMSCreditConsumer } from '../context/SMSCreditContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Toolbar,
    Link,
    Block,
    List,
    Row,
    Col,
    ListInput,
    Icon,
    Chip,
  } from 'framework7-react';

  export default class SMSCredit extends Component {

    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let lastdate = lastDay.getDate();

      /*let startdate = today.getFullYear()+'-'+currmonth+'-01';*/
      let startdate = '2020-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+lastdate;

      this.state = {
        isdataloaded:false,
        clientdetail:[],
        filtervars:{startdate:startdate,enddate:enddate},
        startdate:startdate,
        enddate:enddate,
      }

    }

    componentDidMount(){

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        this.setState(()=>{
            return{
              clientdetail:clientdetail,
              filtervars:this.props.$f7route.query
            }
        })
    }

    render(){
      
        return(
            <SMSCreditConsumer>{(value)=>{
              const{handleUserInput, getSMSCreditReport, init, Paging, deleteFilterAction, manageOutstandingAction} = value;

              const{hasfilter, hasallcustomer, isfetched, hasdataavailable, recordset, showpages, page, paginglist, totalpages, toolbarPosition, startdate, enddate} = value;
              
              const{smsloglist, isdatafetched, totalused, totalpending, totalpurchase,totalrefunds, totalbalance, hascredit, totalavailable, totalcampaignused, totalpaymentused, totalmiscused, totalcreditused, datetype} = value;

              let extraspaceclass = "";

              if(showpages)
              {
                extraspaceclass = "withtoolbar";
              }

              let datestart     = "";
              let newstartdate  = "";
        
              if(startdate !== "" && startdate !== undefined)
              {
                newstartdate = new Date(startdate);
        
                let currmonth = newstartdate.getMonth()+1;
                if(currmonth < 10)
                {
                    currmonth = "0"+currmonth;
                }
                datestart = newstartdate.getDate()+"-"+currmonth+"-"+newstartdate.getFullYear();
              }
        
              let dateend     = "";
              let newenddate  = "";
        
              if(enddate !== "" && enddate !== undefined)
              {
                newenddate = new Date(enddate);
        
                let currmonth = newenddate.getMonth()+1;
                if(currmonth < 10)
                {
                    currmonth = "0"+currmonth;
                }
                dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
              }

                setTimeout(() => {
                  if(this.state.isdataloaded === false)
                  {
                    this.setState(()=>{
                      return{
                        isdataloaded:true,
                      }
                    },()=>{

                      init(this.state.filtervars);
                      
                    })
                    
                  }
                }, 100);

                return(
                    <Page pageContent={false} className="page" name="SMSCredit">
                        <Navbar sliding={true}>
                          <NavLeft>
                            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                            <Link href={`/dashboard/`} className="clearmenupadding">
                              <img className="navbarlogo" src={scanlogo} alt="logo" />
                            </Link>
                          </NavLeft>
                          <NavTitle>SMS Credit</NavTitle>
                          <NavRight className="right-with-menu">
                          <Link onClick={()=>{

                            getSMSCreditReport();

                          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                          </NavRight>
                        </Navbar>
                        <PageContent>
                        {
                          isfetched ? (<Fragment>
                              {
                                  hasfilter ? (
                                  <Block strong className="selectedfilter" style={{marginTop:0}}>
                                    {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}

                                    {datetype !== '' ? (<Chip text={`Date Type : ${datetype === 'begining' ? `Begining`:`Selected Date`}`} mediaBgColor="pink" media={`D`}/>):null}

                                    {datetype === 'selectdate' && datestart ? (<Chip text={`Date : (${datestart}) - (${dateend})`} mediaBgColor="blue" media={`D`}/>):null}

                                    &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                      if(this.$f7.online)
                                      {
                                        this.$f7router.navigate(`smscreditfilter/?datetype=${datetype}&startdate=${startdate}&enddate=${enddate}`);
                                      }
                                      /*openFilterPopup();*/
                                    }}/>
                                  </Block>
                                ):null
                              }
                              {
                                hasdataavailable ? (
                                  <div className="outstandingwrapper">
                                    <Row noGap className='rowwithbottomborder' style={{borderTop:'1px solid #000000', borderBottom:'1px solid #000000', fontWeight:'bold'}}>
                                        <Col width='70' className="tableheader1 textalignleft">
                                            Summary
                                        </Col>
                                        <Col width='30' className="tableheader1 textalignleft">
                                            Count
                                        </Col>
                                    </Row>
                                    {/*<Row noGap className='rowwithbottomborder'>
                                      <Col width='70' className="ledgercolumn textalignleft">Total Purchase</Col>
                                      <Col width='30' className={`ledgercolumn`}>{totalpurchase}</Col>
                                    </Row>*/}
                                    <Row noGap className='rowwithbottomborder'>
                                      <Col width='70' className="ledgercolumn textalignleft">Total Campaign Credits</Col>
                                      <Col width='30' className={`ledgercolumn`}>{totalcampaignused}</Col>
                                    </Row>
                                    <Row noGap className='rowwithbottomborder'>
                                      <Col width='70' className="ledgercolumn textalignleft">Total Payment Credits</Col>
                                      <Col width='30' className={`ledgercolumn`}>{totalpaymentused}</Col>
                                    </Row>
                                    <Row noGap className='rowwithbottomborder'>
                                      <Col width='70' className="ledgercolumn textalignleft">Total Re-Send SMS Credits</Col>
                                      <Col width='30' className={`ledgercolumn`}>{totalmiscused}</Col>
                                    </Row>
                                    <Row noGap className='rowwithbottomborder'>
                                      <Col width='70' className="ledgercolumn textalignleft"><b>Total Credits Used</b></Col>
                                      <Col width='30' className={`ledgercolumn`}>{totalcreditused}</Col>
                                    </Row>
                                    {/*<Row noGap className='rowwithbottomborder'>
                                      <Col width='70' className="ledgercolumn textalignleft">Total Balance</Col>
                                      <Col width='30' className={`ledgercolumn`}>{totalbalance}</Col>
                                    </Row>*/}
                                    <br /><br /><br />
                                  </div>
                                ):(<AppItemNotFound notfoundtext ="No record found."/>)
                              }
                              </Fragment>):(<AppListItemLoading />)
                        }
                        <br /><br />
                        </PageContent>
                        {
                          hasdataavailable  && showpages ? (
                            <Toolbar position={toolbarPosition}>
                            {
                              (()=>{
                                if(Number(page) > 1)
                                {
                                  return(
                                    <Link className="paging" onClick={()=>{Paging('previous')}}>
                                    <Icon f7="arrow_left_square_fill" ></Icon>
                                    </Link>
                                  );
                                }
                                else
                                {
                                  return(
                                    <Link href="#"></Link>
                                  );
                                }
                              })()
                            }
                              <List className="droppage" noHairlines>
                                  <ListInput
                                      name="page"
                                      type="select"
                                      value={page}
                                      onChange={handleUserInput}
                                      placeholder="Please choose..."
                                      >
                                      {
                                        paginglist.map((pages)=>{
                                        return(<option key={pages.index} value={pages.page}>{pages.name}</option>);
                                        })
                                      }
                                  </ListInput>
                              </List>
                              {
                                (()=>{
                                  if(Number(page) < Number(totalpages))
                                  {
                                    return(
                                      <Link className="paging" onClick={()=>{Paging('next')}}>
                                      <Icon f7="arrow_right_square_fill" ></Icon>
                                      </Link>
                                    );
                                  }
                                  else
                                  {
                                    return(
                                      <Link href="#"></Link>
                                    );
                                  }
                                })()
                              }
                            </Toolbar>
                          ):null
                        }
                        {/*
                          hasdataavailable ? (
                            <AppFav position="center-bottom" text="Make Payment" icon="plus" url="makepayment" />
                          ):null
                        */}
                    </Page>
                );
            }}
            </SMSCreditConsumer>            
        );
    }
}