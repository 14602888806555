import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Block,
    List,
    Row,
    Col,
    ListItem,
  } from 'framework7-react';

  export default class Reports extends Component {
    constructor(props) {
      super(props);

      this._isMounted = false;

      this.state = {
        loaded:false,
        effect:'blink',
      };
      
      this.navigateToLink = this.navigateToLink.bind(this);

    }
    
    componentDidMount(){

      this._isMounted = true;
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
        }
      },()=>{

          setTimeout(()=> {

            this.setState({loaded:true});

          }, 1200);
          
          this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        Promise.all([
          
          this._isMounted = false

        ])
        .then(() => {      
          
            this.$f7router.navigate(`/${link}/`);
  
        })

      }

    }

    componentWillUnmount(){

      this._isMounted = false;

    }

    render(){
        
      const{loaded, clientdetail} = this.state;

      return(
            <Page className="page" name="Reports">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Website</NavTitle>
                  <NavRight>
                  </NavRight>
                </Navbar>
                {
                  loaded ? (
                    <Block>
                      <Row style={{height: '50px'}}>
                        <Col>
                          <Link className="button button-fill color-blue" href="/aboutus/" animate={true} ignoreCache={true}>About Us</Link>
                        </Col>
                      </Row>
                      <Row style={{height: '50px'}}>
                        <Col>
                          <Link className="button button-fill color-blue" href="/contact-detail/" animate={true} ignoreCache={true}>Contact Detail</Link>
                        </Col>
                      </Row>
                      <Row style={{height: '50px'}}>
                        <Col>
                          <Link className="button button-fill color-blue" href="/gallery/" animate={true} ignoreCache={true}>Gallery</Link>
                        </Col>
                      </Row>
                      <Row style={{height: '50px'}}>
                        <Col>
                          <Link className="button button-fill color-blue" href="/contactrequest/" animate={true} ignoreCache={true}>Contact Request</Link>
                        </Col>
                      </Row>
                    </Block>
                  ):(<AppListItemLoading />)
                }
            </Page>
      );
    }
}