import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppListItemLoading from '../pages/applistitemloading';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Block,
    Row,
    Col,
    Fab,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    BlockTitle
  } from 'framework7-react';

  export default class BillStatementByAreaLine extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startyear = today.getFullYear();
      let endyear   = today.getFullYear();
      let startmonth = currmonth-1;
      
      if(startmonth < 1)
      {
        startmonth  = 12;
        startyear   = Number(startyear - 1);
      }

      let startdate = startyear+'-'+Number(startmonth)+'-01';
      let enddate   = endyear+'-'+currmonth+'-'+currday;

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
     
      this.state = {
        clientdetail:[],
        monthyear:startdate,
        filtermonthyear:monthNames[startmonth-1]+' - '+startyear,
        isfetched:false,
        recordset:[],
        isptr:false,
        /*circulationdate:date,*/
        circulationdate:'',
        ismount:false,
        hasallcustomer:false,
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
        recordid:'',
        toolbarPosition:'bottom',
        page:1,
        perpage:'',
        totalpages:'',
        showpages:false,
        paginglist:[],
        totalrecord:'',
        serialindex:1,
        totalrecord:0
      };
      this.handleUserInput        = this.handleUserInput.bind(this);
      this.getBillStatementReport = this.getBillStatementReport.bind(this);
      this.openFilterPopup        = this.openFilterPopup.bind(this);
      this.setNewFilterData       = this.setNewFilterData.bind(this);
      this.navigateToLink         = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getBillStatementReport();
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        this.Paging('select');

      });

    }

    getBillStatementReport(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
    
        const bodyFormData = {
          Mode:'GetBillStatementByAreaLineReport',
          clientid:self.state.clientdetail.id,
          monthyear:self.state.monthyear,
          filtermonthyear:self.state.filtermonthyear,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          lineid:self.state.lineid,
          linemanid:self.state.linemanid,
          hawkerid:self.state.hawkerid,
          areaid:self.state.areaid,
          page:this.state.page,
          perpage:this.state.perpage,
          serialindex:this.state.serialindex,
        };

        this.setState(()=>{

          return{
            isfetched:false
          }
          
        },()=>{

          setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  isfetched:true,
                  recordset:resdata.recordset,
                  hasdataavailable:true,
                  perpage:Number(resdata.perpage),
                  totalpages:Number(resdata.totalpages),
                  paginglist:resdata.paginglist,
                  showpages:resdata.showpages,
                  totalrecord:resdata.totalrecord,
                  serialindex:resdata.serialindex,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });

              }
              else
              {
                this.setState({

                  isfetched:true,
                  hasdataavailable:false,
                  totalrecord:'0',

                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        },500);

      });
    }

    getBillStatementReportPDF(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetBillStatementReportPDF',
        clientid:self.state.clientdetail.id,
        monthyear:self.state.monthyear,
        filtermonthyear:self.state.filtermonthyear,
        stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        lineid:self.state.lineid,
        linemanid:self.state.linemanid,
        hawkerid:self.state.hawkerid,
        areaid:self.state.areaid,
        isfulldownload:Number(true),
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                /*window.open(resdata.pdffilepath);*/
                window.open(resdata.pdffilepath,"_blank");
              }
              else
              {
                  const toastWithButton = app.toast.create({
                      text: resdata.msg,
                      closeButton: true,
                      closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            lineid:lineid,
            linemanid:linemanid,
            hawkerid:hawkerid,
            filterarea:filterarea,
            filterline:filterline,
            filterlineman:filterlineman,
            filterhawker:filterhawker,
            monthyear:monthyear,
            filtermonthyear:filtermonthyear,
            loaded:false,
            applyfilter:false,
            page:1,
          }
  
        },()=>{
  
          if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || (this.state.monthyear !== "" && this.state.monthyear !== undefined))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getBillStatementReport();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    Paging(mode){

      let pageerror = "";

      if(!this.$f7.online)
      {
        return;
      }

      let temppage    = '';

      const currpage  = this.state.page;

      if(mode === 'previous')
      {
        temppage  = Number(this.state.page) - 1;
      }
      if(mode === 'next')
      {
        temppage  = Number(this.state.page) + 1;
      }
      if(mode === 'select')
      {
        temppage  = Number(this.state.page);
      }

      if(temppage < 1)
      {
        pageerror = "You are on first page";
      }

      if(temppage > Number(this.state.totalpages))
      {
        pageerror = "You are on last page";
      }

      if(pageerror !== "")
      {
        const toastWithButton = this.$f7.toast.create({
          text: pageerror,
          closeButton: true,
          closeTimeout: 3000,
        });
            
        toastWithButton.open();
        
        return;
      }

      if(temppage <= 0)
      {
        temppage  = 1;
      }

      if(temppage >= Number(this.state.totalpages))
      {
        temppage  = Number(this.state.totalpages);
      }

      this.setState(()=>{

        return{
          page:temppage,
          loaded:false
        }

      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          if(mode === 'select')
          {
            this.getBillStatementReport();
          }
          else
          {
            if(Number(this.state.page) !== Number(currpage))
            {
              this.getBillStatementReport();
            }
          }

        })

      })

    }

    render(){
        
      const{isfetched, recordset, monthyear, filtermonthyear, hasallcustomer, areaid, lineid, hawkerid, hasfilter, filterarea, filterline, filterlineman, filterhawker, ismount, hasdataavailable, clientdetail, paginglist, showpages, page, totalpages, totalrecord, removefiltername, linemanid, applyremovefilter} = this.state;

      return(
        <Page className="page" name="BillStatementByAreaLine">
          <Navbar sliding={true}>
            <NavLeft>
              <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
              <Link href={`/dashboard/`} className="clearmenupadding">
                <img className="navbarlogo" src={scanlogo} alt="logo" />
              </Link>
            </NavLeft>
            <NavTitle>Bill Statement</NavTitle>
            <NavRight className="right-with-menu">
            <Link onClick={()=>{

              this.getBillStatementReport();

            }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
            </NavRight>
          </Navbar>
      <FilterConsumer>{(value)=>{

        const{billstatementfilterdata, applyBillStatementFilter} = value;

        if(ismount === true)
        {
          setTimeout(()=> {

            if(removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(removefiltername === 'area')
              {
                applyBillStatementFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, '', '', monthyear, filtermonthyear);
              }
              else if(removefiltername === 'line')
              {
                applyBillStatementFilter('', linemanid, hawkerid, '', filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear);
              }
              else if(removefiltername === 'lineman')
              {
                applyBillStatementFilter(lineid, '', hawkerid, filterline, '', filterhawker, areaid, filterarea, monthyear, filtermonthyear);
              }
              else if(removefiltername === 'hawker')
              {
                applyBillStatementFilter(lineid, linemanid, '', filterline, filterlineman, '', areaid, filterarea, monthyear, filtermonthyear);
              }
            }

            if(((billstatementfilterdata.lineid !== lineid) && billstatementfilterdata.lineid !== undefined) || ((billstatementfilterdata.linemanid !== linemanid) && billstatementfilterdata.linemanid !== undefined) || ((billstatementfilterdata.hawkerid !== hawkerid) && billstatementfilterdata.hawkerid !== undefined) || ((billstatementfilterdata.areaid !== areaid) && billstatementfilterdata.areaid !== undefined) || ((billstatementfilterdata.monthyear !== monthyear) && billstatementfilterdata.monthyear !== undefined) || applyremovefilter === true)
            {
              this.setNewFilterData(billstatementfilterdata.lineid, billstatementfilterdata.linemanid, billstatementfilterdata.hawkerid, billstatementfilterdata.filterline, billstatementfilterdata.filterlineman, billstatementfilterdata.filterhawker, billstatementfilterdata.areaid, billstatementfilterdata.filterarea, billstatementfilterdata.monthyear, billstatementfilterdata.filtermonthyear);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }

          }, 500)
        }
        
      return(
            <Block>
            {
              hasfilter ? (
                <Block strong className="selectedfilter" style={{marginTop:0}}>
                  {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                  {filtermonthyear ? (<Chip text={`Bill Month : ${filtermonthyear}`} mediaBgColor="green" media={filtermonthyear.toUpperCase().substring(0, 1)}/>):null}
                  {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)}
                  />):null}
                  {Number(lineid) > 0 && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="yellow" media={filterline.toUpperCase().substring(0, 1)} deleteable
                  onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'line'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                  />):null}
                  {filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="green" media={filterlineman.toUpperCase().substring(0, 1)} deleteable 
                  onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'lineman'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                  />):null}
                  {Number(hawkerid) > 0 && filterhawker !== "" ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                  onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'hawker'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                  />):null}
                  &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.$f7router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&monthyear=${monthyear}&filtermonthyear=${filtermonthyear}`);
                        }
                      /*this.openFilterPopup();*/
                  }}/>
                </Block>
              ):null
            }
            {
              isfetched ? (
                <Fragment>
                {
                    hasdataavailable ? (<Fragment>
                        <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center',fontSize:'1.2rem'}}>Total Records : {totalrecord}</BlockTitle>
                        <div className="data-table">
                            {
                                recordset.map((linedetail)=>{
                                    return(<Fragment key={linedetail.areaid}>
                                        <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center'}}>{linedetail.name} <br/> <div style={{fontSize:'1.2rem'}}>(Rs. {linedetail.invoiceamount})</div></BlockTitle>
                                          <Row noGap className='rowwithbottomborder'>
                                              <Col width='15' className="tableheader textalignleft">
                                                  S.No.
                                              </Col>
                                              <Col width='40' className="tableheader textalignleft">
                                                  Line
                                              </Col>
                                              <Col width='35' className="tableheader">
                                                  Amount
                                              </Col>
                                              <Col width='10' className="tableheader">
                                                  &nbsp;
                                              </Col>
                                          </Row>
                                          {
                                            linedetail.details.map((detail)=>{
    
                                                return(
                                                  <Row key={detail.serialno} noGap className='rowwithbottomborder' onClick={()=>{this.$f7.views.main.router.navigate(`/billstatement/?monthyear=${monthyear}&filtermonthyear=${filtermonthyear}&areaid=${linedetail.areaid}&filterarea=${linedetail.name}&lineid=${detail.id}&filterline=${detail.name}&hawkerid=${hawkerid}&filterhawker=${filterhawker}`)}}>
                                                      <Col width='15' className="ledgercolumn textalignleft">
                                                        {detail.serialno}
                                                      </Col>
                                                      <Col width='40' className="ledgercolumn textalignleft">
                                                        {detail.name}
                                                      </Col>
                                                      <Col width='35' className="ledgercolumn">
                                                        {detail.invoiceamount} ({detail.invoicecount})
                                                      </Col>
                                                      <Col width='10' className="ledgercolumn">
                                                        <Icon f7="chevron_right"></Icon>
                                                      </Col>
                                                  </Row>
                                                );
                                            })
                                          }
                                    </Fragment>)
                                })
                            }
                        </div>
                    </Fragment>):(<AppItemNotFound notfoundtext ="No record found." />)
                }
                </Fragment>
              ):(<AppListItemLoading />)
            }
            <br /><br /><br />
            </Block>
          );

        }}
        </FilterConsumer>
          <Actions ref="actionsOneGroup">
            <ActionsGroup>
              <ActionsLabel bold>Select an action to proceed</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{
                this.$f7router.navigate(`/editcustomer/${this.state.recordid}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=&filteropeningbalance=&page=`);
                /*this.navigateToLink(`editcustomer/${this.state.recordid}`)*/
              }}>Edit</ActionsButton>
              {
                clientdetail.islineman || clientdetail.ismanager ? null:(
                  <ActionsButton color="blue" bold onClick={()=>{
                    this.navigateToLink(`customerinvoices/${this.state.recordid}`)
                  }}>Bills</ActionsButton>
                )
              }
              <ActionsButton color="blue" bold onClick={()=>{
                this.navigateToLink(`ledger/${this.state.recordid}`)
              }}>Ledger</ActionsButton>
              <ActionsButton color="blue" bold onClick={()=>{
                this.navigateToLink(`makepayment/${this.state.recordid}`)
              }}>Make Payment</ActionsButton>
              <ActionsButton color="blue" bold onClick={()=>{
                this.navigateToLink(`payments/summary/${this.state.recordid}`)
              }}>Payment</ActionsButton>
              <ActionsButton color="blue" bold onClick={()=>{
                this.navigateToLink(`subscription/${this.state.recordid}`)
              }}>Subscription</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">Cancel</ActionsButton>
            </ActionsGroup>
          </Actions>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {
            hasdataavailable ? (
               <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                    if(this.$f7.online)
                    {
                      this.getBillStatementReportPDF();
                    }
                  }}>
                  <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                  <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
               </Fab>
            ):null
        }
        </Page>

      );
    }
}