import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Toggle,
    Link,
  } from 'framework7-react';

  export default class QuickAddline extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        quickareaid: '',
        name:'',
        phone:'',
        status:1,
        remarks:'',
        frmsuccess:false,
        colwidth:80,
        popupOpenedArea:false,
        quickareaname:'Select',
        isquicksmartarea:false,
        hasquicksmartarea:false,
        resid:'',
        resname:'',
      };

      this.handleUserInput = this.handleUserInput.bind(this);
      this.fetchNewArea     = this.fetchNewArea.bind(this);
      this.getArea = this.getArea.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
        this.getArea();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();

    }    

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchlinelist  = this.state.arealist;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-quickarea-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectquickarea/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinelist.length; i += 1) {
            if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
          }

          render(results);
        },
        on: {
          change(value) {
            self.Dom7('#autocomplete-quickarea-name').text(value[0].name);
            $('.autocomplete-quickarea-popup').removeClass('error');
            self.setState({
              quickareaid:value[0].id,
              quickareaname:value[0].name,
              isquicksmartarea:true,
            });
          },
        },
      });
    }
    getArea(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
          Mode:'GetArea',
          clientid:clientdetail.id,
          ismanager:Number(this.state.clientdetail.ismanager),
          areamanagerid:Number(this.state.clientdetail.areamanagerid),
          areaids:this.state.clientdetail.areaids,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                arealist:resdata.recordlist,
              },()=>{
                if(this.state.hasquicksmartarea === false)
                {
                  this.setState({hasquicksmartarea:true});
                  this.areaAutocompletePopup();
                }
              });
            }
            else
            {
              this.setState({
                arealist:[],
              },()=>{
                if(this.state.hasquicksmartarea === false)
                {
                  this.setState({hasquicksmartarea:true});
                  this.areaAutocompletePopup();
                }
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    openAreaPopup(){

      this.setState(()=>{

        return{
          popupOpenedArea:!this.state.popupOpenedArea
        }

      });

    }
    saveLineDetail(){
      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const toastWithButton = app.toast.create({
        text: 'Please enter all required field.',
        closeButton: true,
        closeTimeout: 3000,
      });

      app.input.validateInputs('.quickadddata');

      if(this.state.name === "")
      {
        toastWithButton.open();
        return false;
      }

      const bodyFormData = {
          Mode:'AddLine',
          clientid:self.state.clientdetail.id,
          name:self.state.name,
          phone:self.state.phone,
          status:Number(self.state.status),
          remarks:self.state.remarks,
          areaid:self.state.quickareaid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                        name:'',
                        phone:'',
                        status:1,
                        remarks:'',
                        frmsuccess:true,
                        resid:resdata.recordid,
                        resname:resdata.name,
                    }
                })
              ])
              .then(() => {

                this.Dom7('.item-content').removeClass('item-input-with-error-message');
                this.Dom7('.item-content').removeClass('item-input-invalid');
                /*this.props.fetchNewLine(resdata.recordid, resdata.name);
                this.props.closePopup();*/
  
              });

            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }
    fetchNewArea(newareaid, newarea){

      const self = this;

      this.setState(()=>{

        return{
          quickareaid:newareaid
        }

      },()=>{

        self.Dom7('#autocomplete-quickarea-name').text(newarea);
        //this.getArea();
        /*this.getLine();*/

      })

    }
    render(){

      const{quickareaid, quickareaname, resid, resname, frmsuccess, isquicksmartarea} = this.state;
      return(
        <FilterConsumer>{(value)=>{

          const{setAreaData, setLineData, setHawkerData, singlearea}  = value;

          if(frmsuccess === true)
          {
            Promise.all([
              setTimeout(()=> {

                setLineData(Number(resid), resname, true);
                setHawkerData('', 'Select', false);
    
              }, 500)
              
            ])
            .then(() => {

              setTimeout(()=> {

                this.$$('.popup-close').trigger('click');
    
              }, 500)
              
            })
          }
          
          setTimeout(()=> {

            if(isquicksmartarea === true)
              {
                if((singlearea.id !== quickareaid) && quickareaid > 0)
                {
                  Promise.all([

                    setAreaData(Number(quickareaid), quickareaname, false)

                  ])
                  .then(() => {

                    this.setState({

                      isquicksmartarea:false

                    });

                  })

                }
              }
              else
              {
                if(quickareaid !== singlearea.id)
                {
                  this.fetchNewArea(singlearea.id, singlearea.name);
                }
              }
          }, 500)

          return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link popupClose>
                        <i className="icon icon-back"></i>                      
                      </Link>
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </NavLeft>
                    <NavTitle>Add New Line</NavTitle>
                  </Navbar>
                  <Page pageContent={false} className="page" name="QuickAddline">
                    <PageContent>
                    <Block>
                      <Row>
                          <Col width='100'>
                          <List noHairlinesMd className="zeromargin">
                            <ListItem link="#" id="autocomplete-quickarea-popup" className="autocomplete-quickarea-popup" title='Area' >
                              <div slot="after" id="autocomplete-quickarea-name">{quickareaname}</div>
                            </ListItem>
                          </List>
                          </Col>
                      </Row>
                        <List noHairlinesMd className='zeromargin'>
        
                            <ListInput
                                name="name"
                                className="quickadddata"
                                label="Name"
                                floatingLabel
                                type="text"
                                onInput={this.handleUserInput}
                                placeholder="Name"
                                value={this.state.name}
                                clearButton required validate
                            >
                            </ListInput>

                            <ListInput
                                name="remarks"
                                label="Remarks"
                                floatingLabel
                                type="textarea"
                                onInput={this.handleUserInput}
                                value={this.state.remarks}
                                resizable
                                placeholder="Remarks"
                            >
                            </ListInput>
                            <ListItem title="Status">
                              <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                            </ListItem>
                        </List>
                    </Block>
                    <br /><br />
                    </PageContent>
                    <AppSaveRecordButton saveDetail={this.saveLineDetail.bind(this)} />
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}