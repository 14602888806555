import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Row,
    Col,
    Link
  } from 'framework7-react';

  export default class AddLine extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        phone:'',
        status:1,
        remarks:'',
        areaid:'',
        arealist:[],
        colwidth:80,
        ismount:false,
        areaname:'Select',
        issmartarea:false,
        hassmartarea:false
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.fetchNewArea       = this.fetchNewArea.bind(this);
      this.onPageInit         = this.onPageInit.bind(this);
      
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        if(Number(this.state.clientdetail.islineman) === 1 || Number(this.state.clientdetail.ismanager) === 1)
        {
          this.setState({colwidth:100});
        }
        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.hideSplashScreen();
        this.onPageInit();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    onPageInit(){
      
      const self  = this;
      const app   = self.$f7;

      self.autocompleteAreaPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
              Mode:'GetArea',
              clientid:self.state.clientdetail.id,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.setState({
              areaid:value[0].id,
              areaname:value[0].name,
              issmartarea:true,
            });
          },
        },
      });
    }

    fetchNewArea(newareaid, newarea){

      this.setState(()=>{
        return{
          areaid:newareaid,
          areaname:newarea
        }
      })
    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      let errmsg    = "";

      if(!this.$f7.online)
      {
        return;
      }

      app.input.validateInputs('.adddata');

      if((this.state.areaid === "" || this.state.areaid === undefined) && errmsg === "")
      {
        errmsg  = "Please select an Area";
      }

      if(this.state.areaid === "" || this.state.areaid === undefined)
      {
        this.$$('.autocomplete-area-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-area-popup').removeClass('error');
      }

      if(this.state.name === "" && errmsg === "")
      {
        errmsg  = "Please enter name";
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
          Mode:'AddLine',
          clientid:self.state.clientdetail.id,
          name:self.state.name,
          phone:self.state.phone,
          status:Number(self.state.status),
          remarks:self.state.remarks,
          areaid:self.state.areaid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            if(resdata.success !== true)
            {
              toastWithButton.open();
            }

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                    name:'',
                    /*areaid:'',
                    areaname:'Select',*/
                    phone:'',
                    status:1,
                    remarks:'',
                  }
                })
              ])
              .then(() => {
  
                $$('.item-content').removeClass('item-input-with-error-message');
                $$('.item-content').removeClass('item-input-invalid');

                this.$f7.views.main.router.navigate(`thanks/addlinesuccess/`);
  
              })
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

      const{colwidth, clientdetail, areaid, areaname, ismount} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{singlearea} = value;

          if(ismount === true)
          {
            setTimeout(()=> {

              if((singlearea.id !== areaid) && singlearea.id > 0)
              {
                this.fetchNewArea(singlearea.id, singlearea.name);
              }
  
            }, 500)
            
          }

          return(
              <Page pageContent={false} className="page" name="AddLine">
                    <Navbar sliding={true}>
                      <NavLeft>
                        <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                        <Link href={`/dashboard/`} className="clearmenupadding">
                          <img className="navbarlogo" src={scanlogo} alt="logo" />
                        </Link>
                      </NavLeft>
                      <NavTitle>Add Line</NavTitle>
                      <NavRight className="right-with-menu">
                      </NavRight>
                    </Navbar>
                    <PageContent>
                    <Block>
                    <Row>
                        <Col width={colwidth}>
                        <List noHairlinesMd className="zeromargin">
                          <ListItem link="#" id="autocomplete-area-popup" className="autocomplete-area-popup" title="Area">
                            <div slot="after" id="autocomplete-area-name">{areaname}</div>
                          </ListItem>
                        </List>
                        </Col>
                        {
                          clientdetail.islineman || clientdetail.ismanager ? null:(
                            <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                              <Link onClick={()=>{
                                if(this.$f7.online)
                                {
                                  this.$f7router.navigate('addareapopup/')
                                }
                              }}>
                                <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                              </Link>
                            </Col>
                          )
                        }
                    </Row>
                    <List noHairlinesMd style={{marginBottom:'5px'}} className="zeromargin">
    
                        <ListInput
                            name="name"
                            className="adddata"
                            label="Name"
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="Name"
                            value={this.state.name}
                            clearButton required validate
                        >
                        </ListInput>
                    
                        <ListInput
                            name="remarks"
                            label="Remarks"
                            floatingLabel
                            type="textarea"
                            onInput={this.handleUserInput}
                            value={this.state.remarks}
                            resizable
                            placeholder="Remarks"
                        >
                        </ListInput>

                        <ListItem title="Status">
                          <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>

                    </List>
                </Block>
                <br /><br />
                </PageContent>
                <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
              </Page>
          );
        }}</FilterConsumer>
      );
    }
}