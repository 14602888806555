import React,{Component} from 'react';

import {
    Page,
    PageContent,
    Block,
    List,
    ListItem,
    ListInput,
    Row,
    Col,
    Fab,
    Icon  } from 'framework7-react';

  export default class InvoiceSMSTab extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startyear = today.getFullYear();
      let endyear   = today.getFullYear();
      let startmonth = currmonth-1;
      
      if(startmonth < 1)
      {
        startmonth  = 12;
        startyear   = Number(startyear - 1);
      }

      let startdate = startyear+'-'+Number(startmonth)+'-01';

      let scheduledate  = endyear+'-'+currmonth+'-'+today.getDate();
      let currtimehours = today.getHours();   
      let currtimeminutes = today.getMinutes();   
      if(currtimehours < 10)
      {
        currtimehours = '0'+currtimehours;
      }
      if(currtimeminutes < 10)
      {
        currtimeminutes = '0'+currtimeminutes;
      }
      let tempscheduletime = currtimehours+':'+currtimeminutes;      

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
     
      this.state = {
        clientdetail:[],
        areaid:'',
        lineid:'',
        filterarea:'Select',
        filterline:'Select',
        filterarealist:[],
        filterlinelist:[],
        monthyear:startdate,
        filtermonthyear:monthNames[Number(startmonth-1)]+' - '+startyear,
        monthyearlist:[],
        customercount:'0',
        customerid:'',
        customername:'Select',
        customerlist:[],
        message:'',
        messagecharcount:0,
        smscredit:0,
        isscheduled:'',
        scheduleddate:scheduledate,
        scheduledtime:tempscheduletime,
        smstobesendcount:0,
        sendertype:1,
      };
      this.handleUserInput  = this.handleUserInput.bind(this);
      this.getCustomerCount = this.getCustomerCount.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const message = "Dear User,\n\nYour monthly bill is generated.\n\nView Bill: %invoicelink%\n\nBalance Now: %outstanding%\n\n%agency%\nTeam Orlo";

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id,
          message:message
        }
      },()=>{

          this.hideSplashScreen();

          this.monthYearAutocompletePopup();
          this.areaAutocompletePopup();
          this.lineAutocompletePopup();
          this.customerAutocompletePopup();
          this.countMessageChars();

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }
    
    onPageBeforeRemove(){

        const self = this;

        self.autocompleteMonthYearPopup.destroy();
        self.autocompleteAreaPopup.destroy();
        self.autocompleteLinePopup.destroy();
        self.autocompleteCustomerPopup.destroy();

    }    

    monthYearAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteMonthYearPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-monthyear-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectmonthyear/',
        requestSourceOnOpen:true,
        source(query, render) {

          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetReportMonthYear',
              type:'billsms',
              clientid:self.state.clientdetail.id,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    monthyearlist:res.recordset,
                  }
                },()=>{

                  const searchmonthyearlist  = self.state.monthyearlist;

                  for (let i = 0; i < searchmonthyearlist.length; i += 1) {
                    if (searchmonthyearlist[i].monthname.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchmonthyearlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchmonthyearlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-startmonthyear-name2').text(value[0].monthname+" - "+value[0].year);*/

            let month       = Number(value[0].month);
            let year        = Number(value[0].year);

            self.setState({
                monthyear:year+'-'+month+'-01',
                filtermonthyear:value[0].monthname+" - "+value[0].year
            },()=>{
              self.getCustomerCount();
            })
          },
        },
      });
    }

    areaAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
              Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              fromarea:'messagearea',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    filterarealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.filterarealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-area-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              areaid:id,
              filterarea:name,
            },()=>{

                if(self.state.areaid === -1)
                {
                  self.setState({
                    lineid:'-1',
                    filterline:'All Line',
                    customerid:'-1',
                    customername:'All Customer',
                  },()=>{
                    self.getCustomerCount();
                  })
                }
                else
                {
                  self.setState({
                    lineid:'',
                    filterline:'Select',
                    customerid:'',
                    customername:'Select',
                  },()=>{
                    self.getCustomerCount();
                  })
                }
            });
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              fromarea:'messagearea',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    filterlinelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.filterlinelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-line-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            /*if(id < 1)
            {
              name  = "";
            }*/

            self.setState({
              lineid:id,
              filterline:name
            },()=>{

                if(self.state.areaid === -1 || self.state.lineid === -1)
                {
                  self.setState({
                    customerid:'-1',
                    customername:'All Customer',
                  },()=>{
                    self.getCustomerCount();
                  })
                }
                else
                {
                  self.setState({
                    customerid:'',
                    customername:'Select',
                  },()=>{
                    self.getCustomerCount();
                  })
                }
            });

          },
        },
      });
    }

    customerAutocompletePopup(){

      const self = this;
      const app = self.$f7;

      self.autocompleteCustomerPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-customer-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectcustomer/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
            Mode:'GetAllSubscribeCustomer',
            type:'subscribecustomer',
            fromarea:'messagearea',
            clientid:self.state.clientdetail.id,
            areaid:self.state.areaid,
            lineid:self.state.lineid,
            billingmonthyear:self.state.monthyear,

          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
              Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    customerlist:res.recordlist,
                  }
                },()=>{

                  const searchcustomerlist  = self.state.customerlist;

                  for (let i = 0; i < searchcustomerlist.length; i += 1) {
                    if (searchcustomerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchcustomerlist[i].phone.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchcustomerlist[i].customerid.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchcustomerlist[i]);
                  }
                  
                })
              }

              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            self.setState(()=>{
              return{
                customerid:value[0].id,
                customername:value[0].name,
              }
            },()=>{
              self.getCustomerCount();
            })

          },
        },
      });
    }

    getCustomerCount(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
          Mode:'GetCustomerCount',
          clientid:self.state.clientdetail.id,
          fetchtype:'invoicemonth',
          billingmonthyear:self.state.monthyear,
          areaid:self.state.areaid,
          lineid:self.state.lineid,
          customerid:self.state.customerid,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);

                if(resdata.success === true)
                {
                  this.setState({

                    customercount:resdata.recordset.customercount

                  },()=>{
                    this.setState({
                      smstobesendcount:Number(this.state.customercount)*Number(this.state.smscredit)
                    })
                  });
                }
                else
                {
                  this.setState({

                    customercount:'0'

                  },()=>{
                    this.setState({
                      smstobesendcount:Number(this.state.customercount)*Number(this.state.smscredit)
                    })
                  });
                }

            }).catch(e => {
                console.log(e);
            });

        },1000);
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{
        this.countMessageChars();
      });

    }

    countMessageChars(){

        let messagecharcount = this.state.message.length;
        let language = Number(this.state.language);
        let charcountlimitpermessage = 160;
        let smscredit = 0;
        let trackablechar = 0;
        if(messagecharcount > 160)
        {
            charcountlimitpermessage = 153;
        }
       
        if (language === 2)
        {
            charcountlimitpermessage = 70;
            if(messagecharcount > 70)
            {
                charcountlimitpermessage = 67;
            }
        }
        if(this.state.istrackable > 0)
        {
            trackablechar = 25;
        } 
        smscredit        = Math.ceil(((messagecharcount+trackablechar) / charcountlimitpermessage));
        
        this.setState(()=>{
             return{
              messagecharcount : messagecharcount,  
              smscredit : smscredit  
             }
         },()=>{
          this.setState({
            smstobesendcount:Number(this.state.customercount)*Number(this.state.smscredit)
          })
        });
    }

    saveDetail(){

        const self    = this;
        const app     = self.$f7;
        const $$      = self.Dom7;
        const router  = self.$f7router;

        let errmsg    = "";

        app.input.validateInputs('.adddata');

        if((this.state.areaid === "" || this.state.areaid === undefined) && errmsg === "")
        {
          errmsg  = "Please select an area";
        }
        if(this.state.areaid === "" || this.state.areaid === undefined)
        {
            this.$$('.autocomplete-area-popup').addClass('error');
        }
        else
        {
            this.$$('.autocomplete-area-popup').removeClass('error');
        }

        if((this.state.lineid === "" || this.state.lineid === undefined) && errmsg === "")
        {
          errmsg  = "Please select a line";
        }
        if(this.state.lineid === "" || this.state.lineid === undefined)
        {
            this.$$('.autocomplete-line-popup').addClass('error');
        }
        else
        {
            this.$$('.autocomplete-line-popup').removeClass('error');
        }

        if((this.state.customerid === "" || this.state.customerid === undefined) && errmsg === "")
        {
          errmsg  = "Please select a customer";
        }
        if(this.state.customerid === "" || this.state.customerid === undefined)
        {
            this.$$('.autocomplete-customer-popup').addClass('error');
        }
        else
        {
            this.$$('.autocomplete-customer-popup').removeClass('error');
        }

        if(this.state.message === "" && errmsg === "")
        {
            errmsg  = "Please enter message";
        }

        if(this.state.isscheduled === "" && errmsg === "")
        {
            errmsg  = "Please select campaign schedule type.";
        }
        
        if(this.state.isscheduled == true)
        {
            if(this.state.scheduleddate === "" && errmsg === "")
            {
                errmsg  = "Please select a scheduled date.";
            }
            else if(this.state.scheduledtime ==='')
            {
                errmsg  = "Please select a scheduled time.";
            }
        }

        if(errmsg !== "")
        {
            const toastWithButton = app.toast.create({
                text: errmsg,
                closeButton: true,
                closeTimeout: 3000,
            });

            toastWithButton.open();
    
            return false;
        }

        app.dialog.preloader('Creating Campaign');

        /*app.preloader.show();*/

        // Simulate Ajax Request
        setTimeout(()=> {

            const formData = new FormData();

            formData.append('Mode','AddCampaign');
            formData.append('clientid',Number(self.state.clientdetail.id));
            formData.append('smstype','invoicesms');
            formData.append('monthyear',self.state.monthyear);
            formData.append('areaid',self.state.areaid);
            formData.append('lineid',self.state.lineid);
            formData.append('customerid',self.state.customerid);
            formData.append('smscredit',self.state.smscredit);
            formData.append('message',self.state.message);
            formData.append('isscheduled',Number(self.state.isscheduled));
            formData.append('scheduleddate',self.state.scheduleddate);
            formData.append('scheduledtime',self.state.scheduledtime);
            formData.append('sendertype',self.state.sendertype);

            const accesstoken  = localStorage.getItem('agency_accesstoken');

            app.request({
                url: `${app.passedParams.serverurl}api/campaign.php?t=${Date.now()}`,
                method: 'POST', 
                data: formData,
                crossDomain: true,
                cache: false, 
                dataType: 'application/json',
                contentType: 'multipart/form-data',
                processData: true,
                headers: {
                    Authorization: `${accesstoken}`
                },
                success: (data)=>{

                  const resdata = JSON.parse(data);

                  /*app.preloader.hide();*/
                  app.dialog.close();

                  if(resdata.success === true)
                  {
                    let today = new Date();
                    let currmonth = today.getMonth()+1;
                    if(currmonth < 10)
                    {
                        currmonth = "0"+currmonth;
                    }
                    let date = today.getFullYear()+'-'+currmonth+'-'+today.getDate();
                    let currtimehours = today.getHours();   
                    let currtimeminutes = today.getMinutes();   
                    if(currtimehours < 10)
                    {
                        currtimehours = '0'+currtimehours;
                    }
                    if(currtimeminutes < 10)
                    {
                        currtimeminutes = '0'+currtimeminutes;
                    }
                    let tempscheduletime = currtimehours+':'+currtimeminutes;

                    Promise.all([
                        this.setState(()=>{
                            return{
                                /*areaid:'',
                                lineid:'',
                                customerid:'',
                                filterarea:'Select',
                                filterline:'Select',
                                customername:'Select',
                                message:'',*/
                                messagecharcount:0,
                                isscheduled:'',
                                scheduleddate:date,
                                scheduledtime:tempscheduletime,
                                issuccess:true,
                            }
                        },()=>{
                            this.$f7.views.main.router.navigate(`thanks/addcampaignsuccess/`);
                        })
                    ])
                    .then(() => {
    
                        $$('.item-content').removeClass('item-input-with-error-message');
                        $$('.item-content').removeClass('item-input-invalid');
    
                    })
                  }
                  else
                  {
                      const toastWithButton2 = app.toast.create({
                          text: resdata.msg,
                          closeButton: true,
                          closeTimeout: 3000,
                      });
  
                      toastWithButton2.open();
                  }

                }
            });

        }, 200);
    }

    render(){
      const{filterarea, filterline, filtermonthyear, customername, message, messagecharcount, smscredit, isscheduled, scheduleddate, scheduledtime, customercount, smstobesendcount} = this.state;

      return(
        <Page className="page" name="InvoiceSMSTab" pageContent={false}>
          <PageContent>
          <Block>
              <List noHairlinesMd style={{margin:0,padding:0,marginTop:'0px',listStyle:'none',marginBottom:'0px'}}>
                  <ListItem link="#" id="autocomplete-monthyear-popup" className="autocomplete-monthyear-popup" title="Bill Month" after={filtermonthyear}></ListItem>
                  <ListItem link="#" id="autocomplete-area-popup" className="autocomplete-area-popup" title="Area" after={filterarea}></ListItem>
                  <ListItem link="#" id="autocomplete-line-popup" className="autocomplete-line-popup" title="Line" after={filterline}></ListItem>
                  <ListItem link="#" id="autocomplete-customer-popup" className="autocomplete-customer-popup" title="Customer" after={customername}></ListItem>
              </List>
              <Row style={{margin:'0 15px'}}>
                  <Col style={{textAlign:'center',fontWeight:'bold',fontSize:'16px'}}>
                      Selected Customers : <span style={{color:'red'}}>{customercount.toString()}</span>
                  </Col>
              </Row>
              <List noHairlinesMd style={{margin:0,padding:0,marginTop:'0px',listStyle:'none',marginBottom:'0px'}}>
                  <ListInput
                      name="message"
                      inputId="messagearea"
                      className="adddata resizable"
                      label="Message"
                      type="textarea"
                      resizable
                      placeholder="Message"
                      onInput={this.handleUserInput}
                      value={message}
                      multiple={true}
                      disabled
                      required validate
                  >
                  </ListInput>
              </List>
              <Row style={{margin:'0 15px'}}>
                  <Col style={{textAlign:'right',fontWeight:'bold'}}>
                      Char Count : <span style={{color:'red'}}>{messagecharcount.toString()}</span> , Sms Credit : <span style={{color:'red'}}>{smscredit.toString()}</span>
                  </Col>
              </Row>
              <Block className='campaignradiolableblock'>
                  <Row noGap>
                      <Col width="50">
                          <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                              <ListItem radio value="0" name="isscheduled" checked={isscheduled === false} title="Send Now"
                              onChange={(e) => {
                                  this.setState({isscheduled: false});
                              }}
                              ></ListItem>
                          </List>
                      </Col>
                      <Col width="50">
                          <List noHairlines className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                              <ListItem radio value="1" name="isscheduled" checked={isscheduled === true} title="Schedule Date"
                              onChange={(e) => {
                                  this.setState({isscheduled: true});
                              }}
                              ></ListItem>
                          </List>
                      </Col>
                  </Row>
              </Block>
              {(()=>{
              if(Number(isscheduled) > 0)
              {
                  return(
                      <List noHairlines style={{marginTop:'5px',marginBottom:'5px'}}>
                          <ListInput
                          name="scheduleddate"
                          id="scheduleddate"
                          label="Select Date"
                          placeholder="Select Date"
                          type="date"
                          value={scheduleddate}
                          onChange={this.handleUserInput}
                          calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                          >
                          </ListInput>
                          <Row noGap>
                              <Col style={{marginLeft:'15px',marginRight:'15px'}}>
                                  <div className="item-title item-label">Time</div>
                                  <input type="time" id="scheduledtime" name="scheduledtime" min="09:00" max="18:00" onChange={this.handleUserInput} placeholder='select a time' value={scheduledtime} required style={{borderBottom:'1px solid #f1f1f1'}}/>
                              </Col>
                          </Row>
                      </List>
                  )
              }
              })()}              
          </Block>
          <br /><br /><br />
          </PageContent>
          <Fab position="center-bottom" slot="fixed" text="Send SMS" onClick={()=>{
              if(this.$f7.online)
              {
                this.saveDetail();
              }
            }}>
            <Icon f7="floppy_disk"></Icon>
          </Fab>
        </Page>

      );
    }
}