import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppListItemLoading from '../pages/applistitemloading';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Link,
    Block,
    Row,
    Col,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton
  } from 'framework7-react';

  export default class CirculationReport extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startdate = today.getFullYear()+'-'+currmonth+'-'+currday;
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+currday;
     
      this.state = {
        clientdetail:[],
        customerlist:[],
        inventorylist:[],
        isptr:false,
        startdate:startdate,
        enddate:enddate,
        catid:'605',
        catname:'Newpapers',
        ismount:false,
        hasallcustomer:false,
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
        droppingpointid:'',
        droppingpointname:'Select',
        useenddate:false,
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getCirulationData    = this.getCirulationData.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        /*this.getCirulationData();*/
	 this.openFilterPopup();
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getCirulationData(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetCirculationData',
            clientid:self.state.clientdetail.id,
            startdate:self.state.startdate,
            enddate:self.state.enddate,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            lineid:self.state.lineid,
            linemanid:self.state.linemanid,
            hawkerid:self.state.hawkerid,
            areaid:self.state.areaid,
            catid:self.state.catid,
            droppingpointid:self.state.droppingpointid,
            useenddate:Number(self.state.useenddate),
        };

        this.setState(()=>{

          return{
            isdatafetched:false
          }

        },()=>{

          setTimeout(()=> {
  
            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });
    
            app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {
  
                // Hide Pull To Refresh
                app.ptr.done();
  
                const resdata = JSON.parse(res.data);
      
                if(resdata.success === true)
                {
                    this.setState(()=>{
                        return{
                          inventorylist:resdata.data,
                          isptr:false,
                          isdatafetched:true,
                          hasdataavailable:true
                        }
                    })
                }
                else
                {
                    this.setState(()=>{
                        return{
                          isptr:false,
                          isdatafetched:true,
                          hasdataavailable:false
                        }
                    });
                }
      
            }).catch(e => {
              console.log(e);
            });
  
          },500);

        });
    }

    getCirulationDataPDF(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetCirculationDataPDF',
        clientid:self.state.clientdetail.id,
        startdate:self.state.startdate,
        enddate:self.state.enddate,
        stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        lineid:self.state.lineid,
        linemanid:self.state.linemanid,
        hawkerid:self.state.hawkerid,
        areaid:self.state.areaid,
        catid:self.state.catid,
        droppingpointid:self.state.droppingpointid,
        droppingpointname:self.state.droppingpointname,
        useenddate:Number(self.state.useenddate),
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                  window.open(resdata.pdffilepath);
              }
              else
              {
                  const toastWithButton = app.toast.create({
                    text: resdata.msg,
                    closeButton: true,
                    closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
            console.log(e);
          });

      },500);
    }

    setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, catid, catname, droppingpointid, droppingpointname, useenddate){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            lineid:lineid,
            linemanid:linemanid,
            hawkerid:hawkerid,
            filterarea:filterarea,
            filterline:filterline,
            filterlineman:filterlineman,
            filterhawker:filterhawker,
            startdate:startdate,
            enddate:enddate,
            catid:catid,
            catname:catname,
            droppingpointid:droppingpointid,
            droppingpointname:droppingpointname,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
            useenddate: Number(useenddate),
          }
  
        },()=>{
  
          if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || Number(this.state.droppingpointid) > 0 || (this.state.startdate !== "" && this.state.startdate !== undefined) || (this.state.enddate !== "" && this.state.enddate !== undefined) || (this.state.catid !== "" && this.state.catid !== undefined))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getCirulationData();
  
        });

      })
      
    }

    openFilterPopup(){
      let {inventorylist, startdate, enddate, hasallcustomer, hasfilter, filterarea, filterline, filterlineman, filterhawker, ismount, isdatafetched, hasdataavailable, lineid, linemanid, areaid, hawkerid, removefiltername, applyremovefilter, catid, catname, droppingpointid, droppingpointname, useenddate} = this.state
      if(this.$f7.online)
	{
	  this.$f7router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&startdate=${startdate}&enddate=${enddate}&catid=${catid}&droppingpointid=${droppingpointid}&droppingpointname=${droppingpointname}&useenddate=${Number(useenddate)}`);
	}
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    render(){
        
      const{inventorylist, startdate, enddate, hasallcustomer, hasfilter, filterarea, filterline, filterlineman, filterhawker, ismount, isdatafetched, hasdataavailable, lineid, linemanid, areaid, hawkerid, removefiltername, applyremovefilter, catid, catname, droppingpointid, droppingpointname, useenddate} = this.state;

      let datestart     = "";
      let newstartdate  = "";

      if(startdate !== "" && startdate !== undefined)
      {
        newstartdate = new Date(startdate);

        let currmonth = newstartdate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        datestart = newstartdate.getDate()+"-"+currmonth+"-"+newstartdate.getFullYear();
      }

      let dateend     = "";
      let newenddate  = "";

      if(enddate !== "" && enddate !== undefined && Number(useenddate) > 0)
      {
        newenddate = new Date(enddate);

        let currmonth = newenddate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
      }

      let datarange = datestart+" - "+dateend;

      if(Number(useenddate) < 1)
      {
        datarange = datestart;
      }

      return(
        <Page className="page" name="CirculationReport">
        <Navbar sliding={true}>
            <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
            <Link href={`/dashboard/`} className="clearmenupadding">
              <img className="navbarlogo" src={scanlogo} alt="logo" />
            </Link>
            </NavLeft>
            <NavTitle>Circulation</NavTitle>
            <NavRight className="right-with-menu">
              <Link onClick={()=>{

                this.getCirulationData();

              }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
            </NavRight>
        </Navbar>
      <FilterConsumer>{(value)=>{

        const{circulationfilterdata, applyCirculationFilter} = value;

        if(ismount === true)
        {
          setTimeout(()=> {

            if(removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(removefiltername === 'area')
              {
                applyCirculationFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, '', '', startdate, enddate, catid, catname, droppingpointid, droppingpointname, useenddate);
              }
              else if(removefiltername === 'line')
              {
                applyCirculationFilter('', linemanid, hawkerid, '', filterlineman, filterhawker, areaid, filterarea, startdate, enddate, catid, catname, droppingpointid, droppingpointname, useenddate);
              }
              else if(removefiltername === 'lineman')
              {
                applyCirculationFilter(lineid, '', hawkerid, filterline, '', filterhawker, areaid, filterarea, startdate, enddate, catid, catname, droppingpointid, droppingpointname, useenddate);
              }
              else if(removefiltername === 'hawker')
              {
                applyCirculationFilter(lineid, linemanid, '', filterline, filterlineman, '', areaid, filterarea, startdate, enddate, catid, catname, droppingpointid, droppingpointname, useenddate);
              }
              else if(removefiltername === 'daterange')
              {
                applyCirculationFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, '', '', catid, catname, droppingpointid, droppingpointname, useenddate);
              }
              else if(removefiltername === 'droppingpoint')
              {
                applyCirculationFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, catid, catname, '', '', useenddate);
              }
            }

            if(((circulationfilterdata.lineid !== lineid) && circulationfilterdata.lineid !== undefined) || ((circulationfilterdata.linemanid !== linemanid) && circulationfilterdata.linemanid !== undefined) || ((circulationfilterdata.hawkerid !== hawkerid) && circulationfilterdata.hawkerid !== undefined) || ((circulationfilterdata.areaid !== areaid) && circulationfilterdata.areaid !== undefined) || ((circulationfilterdata.startdate !== startdate) && circulationfilterdata.startdate !== undefined) || ((circulationfilterdata.enddate !== enddate) && circulationfilterdata.enddate !== undefined) || ((circulationfilterdata.catid !== catid) && circulationfilterdata.catid !== undefined) || ((circulationfilterdata.droppingpointid !== droppingpointid) && circulationfilterdata.droppingpointid !== undefined) || ((circulationfilterdata.useenddate !== useenddate) && circulationfilterdata.useenddate !== undefined) || applyremovefilter === true)
            {
              this.setNewFilterData(circulationfilterdata.lineid, circulationfilterdata.linemanid, circulationfilterdata.hawkerid, circulationfilterdata.filterline, circulationfilterdata.filterlineman, circulationfilterdata.filterhawker, circulationfilterdata.areaid, circulationfilterdata.filterarea, circulationfilterdata.startdate, circulationfilterdata.enddate, circulationfilterdata.catid, circulationfilterdata.catname, circulationfilterdata.droppingpointid, circulationfilterdata.droppingpointname, circulationfilterdata.useenddate);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }

          }, 500)
        }

      return(
          <Block>
              {
                hasfilter ? (
                  <Block strong className="selectedfilter" style={{marginTop:0}}>
                    {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                    {Number(catid) > 0 && catname ? (<Chip text={`Stock Type : ${catname}`} mediaBgColor="blue" media="S"/>):null}
                    {datestart ? (<Chip text={`Date : (${datarange})`} mediaBgColor="yellow" media={`D`}
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'daterange'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'area'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(lineid) > 0 && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="pink" media={filterline.toUpperCase().substring(0, 1)} deleteable
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'line'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="green" media={filterlineman.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'lineman'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(hawkerid) > 0 && filterhawker !== "" ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'hawker'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(droppingpointid) > 0 && droppingpointname !== "" ? (<Chip text={`Dropping Point : ${droppingpointname}`} mediaBgColor="green" media={droppingpointname.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'droppingpoint'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                        
                      this.openFilterPopup();
                    }}/>
                  </Block>
                ):null
              }
              {
                isdatafetched ? (
                  <Fragment>
                  {
                    hasdataavailable ? (
                      <Fragment>
                      {
                          inventorylist.map((category)=>{
                              let GrandPurchase = Number(0);
                              let GrandCirculation = Number(0);
                              let GrandBalance = Number(0);
                              let SNo = 0;
                              return(
                              <Fragment key={category.droppingpointid}>
                                  <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px'}}>{category.droppingpointname}</BlockTitle>
                                  <Row noGap className='rowwithbottomborder'>
                                      <Col width='10' className="tableheader">
                                          S.No.
                                      </Col>
                                      <Col width='45' className="tableheader textalignleft">
                                          Stock Name
                                      </Col>
                                      <Col width='15' className="tableheader">
                                          Pur.
                                      </Col>
                                      <Col width='15' className="tableheader">
                                          Cir.
                                      </Col>
                                      <Col width='15' className="tableheader">
                                          Bal.
                                      </Col>
                                  </Row>
                                  {
                                      category.inventorydata.map((inventory)=>{
                                          if(!isNaN(inventory.purchase))
                                          {
                                            GrandPurchase += Number(inventory.purchase);
                                          }
                                          if(!isNaN(inventory.circulation))
                                          {
                                              GrandCirculation += Number(inventory.circulation);
                                          }
                                          if(!isNaN(inventory.balance))
                                          {
                                            GrandBalance  += Number(inventory.balance);
                                          }
                                          SNo += 1;
                                        return(
                                          <Row key={inventory.id} noGap className='rowwithbottomborder'>
                                              <Col width='10' className="ledgercolumn textalignleft">
                                                  {SNo}
                                              </Col>
                                              <Col width='45' className="ledgercolumn textalignleft">
                                                  {inventory.name}
                                              </Col>
                                              <Col width='15' className="ledgercolumn">
                                                  {inventory.purchase}
                                              </Col>
                                              <Col width='15' className="ledgercolumn">
                                                  {inventory.circulation}
                                              </Col>
                                              <Col width='15' className="ledgercolumn">
                                                  {inventory.balance}
                                              </Col>
                                          </Row>
                                          );
                                      })
                                  }
                                  <Row noGap className='rowwithbottomborder'>
                                      <Col width='55' className="tableheader textalignright">
                                          Grand Total
                                      </Col>
                                      <Col width='15' className="tableheader">
                                          {(()=>{
                                            if(Number(GrandPurchase) > 0)
                                            {
                                              return (GrandPurchase.toString())
                                            }
                                            else
                                            {
                                              return (`-`);
                                            }
                                          }
                                        )()}
                                      </Col>
                                      <Col width='15' className="tableheader">
                                        {(()=>{
                                            if(Number(GrandCirculation) > 0)
                                            {
                                              return (GrandCirculation.toString())
                                            }
                                            else
                                            {
                                              return (`-`);
                                            }
                                          }
                                        )()}
                                      </Col>
                                      <Col width='15' className="tableheader">
                                        {(()=>{
                                            if(Number(GrandBalance) != 0)
                                            {
                                              return (GrandBalance.toString())
                                            }
                                            else
                                            {
                                              return (`-`);
                                            }
                                          }
                                        )()}
                                      </Col>
                                  </Row>
                              </Fragment>
                              );
                          })
                      }
                      </Fragment>                                
                    ):(<AppItemNotFound notfoundtext ="No record found." />)
                  }
                  </Fragment>
                ):(<AppListItemLoading />)
              }
              <br /><br /><br />
          </Block>
          );

        }}
        </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {/*
            hasdataavailable ? (
                <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                    if(this.$f7.online)
                    {
                      this.getCirulationDataPDF();
                    }
                  }}>
                  <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                  <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                </Fab>
            ):null
        */}
        </Page>

      );
    }
}