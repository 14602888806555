import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Link,
    Block,
    List,
    ListItem,
    Icon
  } from 'framework7-react';

  export default class Invoices extends Component {
    constructor(props) {
      super(props);

      let today = new Date();

      this.state = {
        clientdetail:[],
        customerid:'',
        customername:'Select',
        customerlist:[],
        invoicelist:[],
        isptr:false,
        hasinvoice:false,
        isfetched:false,
        effect:'blink',
      };
      this.handleUserInput            = this.handleUserInput.bind(this);
      this.customerAutocompletePopup  = this.customerAutocompletePopup.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        this.getAllCustomers();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          this.getINvoices();
          
        })

        this.hideSplashScreen();

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteCustomerPopup.destroy();

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    customerAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchlinelist  = this.state.customerlist;

      self.autocompleteCustomerPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-customer-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectcustomer/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinelist.length; i += 1) {
            if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchlinelist[i].phone.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchlinelist[i].customerid.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            $('#autocomplete-customer-popup').find('.item-title').text(value[0].name);
            $('#autocomplete-customer-popup').find('.item-content').removeClass('error');
            
            /*$('#autocomplete-line-popup').find('input').val(value[0].id);*/

            self.setState(()=>{
              return{
                customerid:value[0].id,
                customername:value[0].name,
              }
            },()=>{
              self.getINvoices();
            })

          },
        },
      });
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        if(name === 'customerid')
        {
          this.getINvoices();
        }

      });

    }

    getAllCustomers(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetAllSubscribeCustomer',
            clientid:self.state.clientdetail.id,
            loginlinemanid:Number(self.state.clientdetail.linemanid),
            ismanager:Number(this.state.clientdetail.ismanager),
            areamanagerid:Number(this.state.clientdetail.areamanagerid),
            areaids:this.state.clientdetail.areaids,
        };
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                  this.setState(()=>{
                      return{
                          customerlist:resdata.recordlist,
                          isptr:false
                      }
                  },()=>{
                    this.customerAutocompletePopup();
                  });
              }
              else
              {
                  this.setState(()=>{
                      return{
                          isptr:false
                      }
                  });
              }
    
          }).catch(e => {
              console.log(e);
          });
    }
    
    getINvoices(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
            Mode:'GetCustomerInvoices',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            pincode:self.state.clientdetail.pincode,
            customerid:self.state.customerid,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        this.setState({
          isfetched:false
        })

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);

                if(resdata.success === true)
                {
                  this.setState({

                      invoicelist:resdata.invoicelist,
                      customername:resdata.customername,
                      hasinvoice:true,
                      isfetched:true,
                      isptr:false

                  });
                }
                else
                {
                  this.setState({

                    invoicelist:[],
                    customername:resdata.customername,
                    hasinvoice:false,
                    isfetched:true,
                    isptr:false,

                  });
                }

            }).catch(e => {
                console.log(e);
            });

        },500);
    }

    navigateToLink(link){
        
        if(this.$f7.online)
        {
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/
        }

    }

    render(){
        
        const{customerid, invoicelist, hasinvoice, isfetched} = this.state;

      return(
            <Page className="page" name="Bills">
                <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Bills</NavTitle>
                    <NavRight className="right-with-menu">
                    <Link onClick={()=>{

                      this.getINvoices();

                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                    </NavRight>
                </Navbar>
                <Block>
                    <BlockTitle medium style={{marginLeft:'10px'}}>Customer</BlockTitle>
                    <List noHairlinesMd className="zeromargin">
                      <ListItem link="#" id="autocomplete-customer-popup" title={this.state.customername} after="Change">
                      </ListItem>
                    </List>
                    {
                      customerid ? (
                        <Fragment>
                        {
                          isfetched ? (<Fragment>{hasinvoice ? (<Fragment>
                            <BlockTitle medium style={{marginLeft:'10px'}}>Bills</BlockTitle>
                            <List noHairlinesMd className="zeromargin">
                            {
                              invoicelist.map((invoice)=>{
                                return(
                                      <ListItem link="#" key={invoice.id} title={`${invoice.createdon} - Rs. ${invoice.amount}`} onClick={()=>{this.navigateToLink(`invoicedetail/${invoice.id}`)}} after="View">
                                      </ListItem>
                                    );
                                })
                            }
                            </List>
                          </Fragment>):(<AppItemNotFound notfoundtext ="No bill found." />)
                        }</Fragment>):(<Fragment>
                          <List mediaList className={`waiting skeleton-text skeleton-effect-${this.state.effect}`}>
                            <ListItem
                              title="Title Title Title Title Title Title Title Title Title"
                            >
                            </ListItem>
                            <ListItem
                              title="Title Title Title Title Title Title Title Title Title"
                            >
                            </ListItem>
                            <ListItem
                              title="Title Title Title Title Title Title Title Title Title"
                            >
                            </ListItem>
                            <ListItem
                              title="Title Title Title Title Title Title Title Title Title"
                            >
                            </ListItem>
                            <ListItem
                              title="Title Title Title Title Title Title Title Title Title"
                            >
                            </ListItem>
                          </List>
                        </Fragment>)
                        }
                        </Fragment>
                        ):(<div className="errmsg">Please select a customer</div>)
                    }
                    <br /><br /><br />
                </Block>
            </Page>
      );
    }
}