import React,{Component} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    BlockTitle,
    Link,
    Block,
    Row,
    Col,
    Icon,
    Page,
    PageContent,
  } from 'framework7-react';

  export default class ViewSubscriptionLog extends Component {
    constructor(props) {
      super(props);

      this.state = {
        clientdetail:[],
        customerid:'',
        customername:'',
        customerlist:[],
        inventoryloglist:[],
        isptr:false,
        deleterecordid:'',
        deleteinventoryid:'',
      };

      this.getInventoryLogDetail  = this.getInventoryLogDetail.bind(this);
      this.deleteRecordAction     = this.deleteRecordAction.bind(this);
      this.deleteRecord           = this.deleteRecord.bind(this);
    }
    
    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        if(Number(this.state.customerid) > 0)
        {          
          this.getInventoryLogDetail();
        }
        this.hideSplashScreen();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }
    
    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
        
    getInventoryLogDetail(){
      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetCustomerInventoryLog',
          clientid:self.state.clientdetail.id,
          customerid:self.state.customerid,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/subscription.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({

                    inventoryloglist:resdata.recordlist,
                    customername:resdata.customername,
                    isptr:false,

                },()=>{

                    this.Dom7('.subscription').addClass('animate-bottom');
  
                  });
              }
              else
              {
                this.setState({
                  inventoryloglist:'',
                  isptr:false

                });
              }

          }).catch(e => {
              console.log(e);
          });

      },1000);
    }

    deleteRecordAction(recordid,inventoryid){

      const self	= this;
      const app	= self.$f7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.$f7.online)
              {
                this.setState(()=>{
                  return{
                    deleterecordid:recordid,
                    deleteinventoryid:inventoryid,
                  }
                },()=>{

                  this.deleteRecord();

                })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'DeleteSubscriptionLog',
        clientid:this.state.clientdetail.id,
        recordid:this.state.deleterecordid,
        customerid:this.state.customerid,
        inventoryid:this.state.deleteinventoryid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/subscription.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                  }
                },()=>{
                  this.getInventoryLogDetail();
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){

        const{customerid, customername, inventoryloglist} = this.state;

          return(
            <Popup className="demo-popup">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link popupClose>
                      <i className="icon icon-back"></i>                      
                    </Link>
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                  </NavLeft>
                  <NavTitle>Subscription Log</NavTitle>
                  <NavRight className="right-with-menu">
                    <Link onClick={()=>{
            
                        this.getInventoryLogDetail();
            
                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                <Page className="page" name="SubscriptionLog">
                    <Block>
                        <BlockTitle medium className="zeroleftmargin" style={{fontSize:'18px'}}>{customername}</BlockTitle>
                            <PageContent style={{paddingTop:0}}>
                            {
                            Number(customerid) ? (
                                <div className="subscription">
                                <Row noGap className='rowwithbottomborder'>
                                    {/*}<Col width='20' className="tableheader">
                                        Date
                                    </Col>{*/}
                                    <Col width='40' className="tableheader">
                                        Item
                                    </Col>
                                    <Col width='25' className="tableheader">
                                        Sub
                                    </Col>
                                    <Col width='25' className="tableheader">
                                        UnSub
                                    </Col>
                                    <Col width='10' className="tableheader">
                                        &nbsp;
                                    </Col>
                                </Row>
                                {
                                inventoryloglist ? (
                                    inventoryloglist.map((logarr,i)=>{
                                    
                                    let statusclass = 'ledgercolumn textgreencolor';
                                    let statusclass2 = 'ledgercolumn';
                                    if(logarr.unsubscribedate !== '-')
                                    {
                                        statusclass2 = 'ledgercolumn textredcolor';
                                    }
                                    return(
                                        <Row noGap key={i} className='rowwithbottomborder'>
                                        {/*}<Col width='20' className="ledgercolumn">
                                                {logarr.activitydate}
                                        </Col>{*/}
                                            <Col width='40' className="ledgercolumn">
                                                {logarr.name}
                                            </Col>
                                            <Col width='25' className={statusclass}>
                                                <div>{logarr.activitydate}</div>
                                            </Col>
                                            <Col width='25' className={statusclass2}>
                                                <div>{logarr.unsubscribedate}</div>
                                            </Col>
                                            <Col width='10' className="ledgercolumn">
                                                {
                                                !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                                    <Link onClick={() =>{

                                                    this.deleteRecordAction(logarr.logid,logarr.inventoryid);
                                                    
                                                    }}><Icon f7="trash_circle_fill" color="red"></Icon></Link>
                                                ):(<div>&nbsp;</div>)
                                                }
                                            </Col>
                                        </Row>
                                        );
                                    })
                                ) : (<AppItemNotFound notfoundtext ="No logs available." />)
                                }
                                </div>
                            ):(<div className="errmsg">Please select a customer</div>)
                            }
                            <br /><br />
                            </PageContent>
                    </Block>
                </Page>
            </Popup>
          );

    }
}