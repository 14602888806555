import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppCardItemLoading from '../pages/appcarditemloading';
import AppFav from '../pages/appfav';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
  } from 'framework7-react';

  export default class ManageArea extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        isavailable:false,
        loaded:false,
        stocklist:[],
        actionGridOpened: false,
        oneGroupOpened: false,
        deleterecordid:'',
        isptr:false,
        effect:'blink',
      };

      this.getAllStock         = this.getAllStock.bind(this);
      this.navigateToLink     = this.navigateToLink.bind(this);      
    }

    componentDidMount(){
  
     let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.hideSplashScreen();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          setTimeout(()=> {

            this.getAllStock();
            
          }, 200)

        })
        
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
          Mode:'DeleteStock',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              let tempStock	= [...this.state.stocklist];
              tempStock	= tempStock.filter(area => area.id !== this.state.deleterecordid);
        
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                    stocklist:tempStock
                  }
                },()=>{
                  if(this.state.stocklist.length < 1)
                  {
                    this.setState(()=>{
                      return {
                        isavailable:false
                      }
                    });
                  }
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }
    getAllStock(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }

        this.setState({
          loaded:false
        });
  
        const bodyFormData = {
            Mode:'GetAllClientStock',
            clientid:this.state.clientdetail.id,
        };

        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  loaded:true,
                  isavailable:true,
                  stocklist:resdata.recordlist,
                  isptr:false,
                });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  isptr:false,
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);
    }
    navigateToLink(link){
        
        if(this.$f7.online)
        {
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/
        }

    }

    render(){

       const{isavailable, loaded, stocklist} = this.state;

      return(
            <Page className="page" name="ManageStock">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>My Stock</NavTitle>
                    <NavRight className="right-with-menu">
                    <Link onClick={()=>{

                      this.getAllStock();

                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                    </NavRight>
                  </Navbar>
                  {
                    loaded ? (<Fragment>
                      {
                        isavailable ? (
                          <Fragment>
                            {
                                stocklist.map((stock)=>{
                                  return(
                                    <Card outline key={stock.id} className="recordset">
                                      <CardHeader
                                      className="date">{stock.name}</CardHeader>
                                      <CardContent>
                                      {stock.categoryname ? (<p><b>Category:</b> {stock.categoryname}</p>):null}
                                      {stock.frequencyname ? (<p><b>Frequency:</b> {stock.frequencyname}</p>):null}
                                      {stock.stockcode ? (<p><b>Short Code:</b> {stock.shortcode}</p>):null}
                                      </CardContent>
                                      <CardFooter>
                                        <Link onClick={()=>{this.navigateToLink(`editstock/${stock.id}`)}} ignoreCache={true}>Edit</Link>
                                        {
                                          !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                            <Link onClick={() =>{
                                              if(this.$f7.online)
                                              {
                                                this.setState(()=>{
                                                  return {
                                                    deleterecordid:stock.id
                                                  }
                                                },()=>{
                                                  this.refs.actionsOneGroup.open()
                                                })
                                              }
                                            }}>Delete</Link>
                                          ):(<div>&nbsp;</div>)
                                        }
                                      </CardFooter>
                                    </Card>
                                  );
                                })
                            }
                          </Fragment>
                        ):(<AppItemNotFound notfoundtext ="No stock found." />)
                      }
                    </Fragment>):(<AppCardItemLoading />)
                  }
              {/*</PageContent>*/}
              <Actions ref="actionsOneGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteRecord()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <Actions ref="actionsSubstituteGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteSubstituteRecord()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <br /><br />
              <AppFav position="center-bottom" text="Add Stock" icon="plus" url="addstock" />
            </Page>
      );
    }
}