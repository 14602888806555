import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Toolbar,
    Tabs,
    Tab,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Row,
    Col,
    Link,
    Button,
  } from 'framework7-react';

  export default class EditCustomer extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        phone:'',
        phone2:'',
        email:'',
        pincode:'',
        stateid:'',
        cityid:'',
        lineid:'',
        sublineid:'',
        linemanid:'',
        hawkerid:'',
        address1:'',
        address2:'',
        isdiscount:1,
        discount:'',
        status:1,
        statelist:[],
        citylist:[],
        linelist:[],
        sublinelist:[],
        linemanlist:[],
        hawkerlist:[],
        isptr:false,
        subscription:1,
        toolbarPosition:'top',
        inventorylist:[],
        isopeningbalance:1,
        openingbalance:'',
        isincreasepricing:0,
        increasepricing:'',
        dob:'',
        anniversarydate:'',
        customersince:'',
        colwidth:80,
        linename:'',
        linemanname:'',
        hawkername:'',
        areaid:'',
        arealist:[],
        ismount:false,
        areaname:'',
        sublinename:'Select',
        issmartarea:false,
        hassmartsubline:false,
        latitude:'',
        longitude:'',
        isgpslocation:0,
        gpslocationtext:'',
        gpslocationfetched:0,
        housenumber:'',
        floorlist:[],
        floor:'Select',
        liftavailable:false,
        filtervars:[],
        dobavailable:false,
        anniversaryavailable:false,
        customerdateavailable:false,
        canprintinvoice:true,
        caneditopeningbalance:true,
      };

      this.handleUserInput            = this.handleUserInput.bind(this);
      this.fetchNewLine               = this.fetchNewLine.bind(this);
      this.fetchNewSubLine            = this.fetchNewSubLine.bind(this);
      this.fetchNewLineman            = this.fetchNewLineman.bind(this);
      this.fetchNewHawker             = this.fetchNewHawker.bind(this);
      this.handleInventoryInput       = this.handleInventoryInput.bind(this);
      this.lineAutocompletePopup      = this.lineAutocompletePopup.bind(this);
      this.sublineAutocompletePopup   = this.sublineAutocompletePopup.bind(this);
      this.hawkerAutocompletePopup    = this.hawkerAutocompletePopup.bind(this);
      this.goToSubsription            = this.goToSubsription.bind(this);
      this.fetchNewArea               = this.fetchNewArea.bind(this);
      this.getLocationCordinates      = this.getLocationCordinates.bind(this);
      this.onPageInit                 = this.onPageInit.bind(this);
      this.checkSingleHawker          = this.checkSingleHawker.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{
        if(Number(this.state.clientdetail.islineman) === 1 || Number(this.state.clientdetail.ismanager) === 1)
        {
          this.setState({colwidth:100});
        }
        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.hideSplashScreen();
        this.getDetail();

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;

      self.autocompleteFloorPopup.destroy();
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();
      self.autocompleteSublinePopup.destroy();
      self.autocompleteLinemanPopup.destroy();
      self.autocompleteHawkerPopup.destroy();
    }

    getLocationCordinates() {

      const self      = this;
     
      if(Number(this.state.isgpslocation) < 1)
      {
        self.setState({
          latitude:'',
          longitude:'',
          gpslocationfetched:0
        });
        return;
      }
      else
      {
        self.setState({
          gpslocationtext : 'fetching...'
        });
        if ("geolocation" in navigator) {
          let options = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
          }
          let error;
          navigator.geolocation.getCurrentPosition(function(position) {
            self.setState({
              latitude:position.coords.latitude,
              longitude:position.coords.longitude,
              gpslocationfetched:1,
              gpslocationtext:'Success '+'('+position.coords.latitude+', '+position.coords.longitude+')'
            },error,options);
          
          });
        } else {
          self.setState({
            latitude:'',
            longitude:'',
            gpslocationfetched:0,
            gpslocationtext:'Failure'
          },()=>{
            setTimeout(()=> {
              this.setState({isgpslocation:0});
            }, 1200);
          });
      } 
    }
    }

    onPageInit(){
      
      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      self.autocompleteFloorPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-floor-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectfloor/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
              Mode:'GetFloorList',
              clientid:self.state.clientdetail.id,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    floorlist:res.recordset,
                  }
                },()=>{

                  const searchfloorlist  = self.state.floorlist;

                  for (let i = 0; i < searchfloorlist.length; i += 1) {
                    if (searchfloorlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchfloorlist[i]);
                  }
                  
                })
              }

              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.setState({
              floor:value[0].name
            });
          },
        },
      });

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const results = [];

          app.preloader.show();

          const bodyFormData = {
            Mode:'GetArea',
            clientid:self.state.clientdetail.id,
            ismanager:Number(self.state.clientdetail.ismanager),
            areamanagerid:Number(self.state.clientdetail.areamanagerid),
            areaids:self.state.clientdetail.areaids,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-area-name2').text(value[0].name);
            $('.autocomplete-area-popup').removeClass('error');
            
            self.setState({
              areaid:value[0].id,
              areaname:value[0].name,
              issmartarea:true,
            });

          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-line-name2').text(value[0].name);
            $('.autocomplete-line-popup').removeClass('error');
            
            self.setState({
              lineid:value[0].id
            },()=>{
              self.checkSingleHawker();
            });

          },
        },
      });
    }

    sublineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteSublinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-subline-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectsubline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/subline.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetSubLine',
              type:'addeditcustomer',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    sublinelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.sublinelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-subline-name2').text(value[0].name);
            $('.autocomplete-subline-popup').removeClass('error');
            
            self.setState({
              sublineid:value[0].id,
              sublinename:value[0].name
            });

          },
        },
      });
    }

    linemanAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      self.autocompleteLinemanPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-lineman-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectlineman/',
        requestSourceOnOpen:true,
        source(query, render) {

          const results = [];

          app.preloader.show();

          const bodyFormData = {
            Mode:'GetLineman',
            clientid:self.state.clientdetail.id,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${serverurl}api/customer.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linemanlist:res.recordlist,
                  }
                },()=>{

                  const searchlinemanlist  = self.state.linemanlist;

                  for (let i = 0; i < searchlinemanlist.length; i += 1) {
                    if (searchlinemanlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinemanlist[i]);
                  }
                  
                })
              }

              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-lineman-name2').text(value[0].name);
            $('.autocomplete-lineman-popup').removeClass('error');

            self.setState({
              linemanid:value[0].id
            });

          },
        },
      });
    }

    hawkerAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteHawkerPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-hawker-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selecthawker/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetHawker',
              type2:'addeditcustomer',
              clientid:self.state.clientdetail.id,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    hawkerlist:res.recordlist,
                  }
                },()=>{

                  const searchhawkerlist  = self.state.hawkerlist;

                  for (let i = 0; i < searchhawkerlist.length; i += 1) {
                    if (searchhawkerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchhawkerlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-hawker-name2').text(value[0].name);
            $('.autocomplete-hawker-popup').removeClass('error');
            
            self.setState({
              hawkerid:value[0].id,
            });

          },
        },
      });
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      let value   = e.target.value;

      if(name === 'name')
      {
        value = value.toUpperCase();
      }

      if(name === 'isdiscount')
      {
        this.setState({isdiscount:!this.state.isdiscount});
      }
      else if(name === 'isopeningbalance')
      {
        this.setState({isopeningbalance:!this.state.isopeningbalance});
      }
      else if(name === 'isincreasepricing')
      {
        this.setState({isincreasepricing:!this.state.isincreasepricing});
      }
      else if(name === 'subscription')
      {
        this.setState({subscription:!this.state.subscription});
      }
      else if(name === 'liftavailable')
      {
        this.setState({liftavailable:!this.state.liftavailable});
      }
      else if(name === 'dobavailable')
      {
        this.setState({dobavailable:!this.state.dobavailable});
      }
      else if(name === 'anniversaryavailable')
      {
        this.setState({anniversaryavailable:!this.state.anniversaryavailable});
      }
      else if(name === 'customerdateavailable')
      {
        this.setState({customerdateavailable:!this.state.customerdateavailable});
      }
      else if(name === 'canprintinvoice')
      {
        this.setState({canprintinvoice:!this.state.canprintinvoice});
      }
      else if(name === 'isgpslocation')
      {
        this.setState({
          isgpslocation:!this.state.isgpslocation
        },()=>{
           this.getLocationCordinates();
        });
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }

    }

    check_email(elementValue){
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(elementValue);
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {          
          return;
        }

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }
  
        const bodyFormData = {
          Mode:'GetCustomerDetail',
          clientid:self.state.clientdetail.id,
          recordid:self.props.f7route.params.id,
        };

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              let tempisopeningbalance = 1;
              if(resdata.customerdetail.openingbalance !=='')
              {
                tempisopeningbalance = 1;
              }
              this.setState(()=>{
                return{
                  name:resdata.customerdetail.name,
                  phone:resdata.customerdetail.phone,
                  phone2:resdata.customerdetail.phone2,
                  email:resdata.customerdetail.email,
                  pincode:resdata.customerdetail.pincode,
                  stateid:resdata.customerdetail.stateid,
                  cityid:resdata.customerdetail.cityid,
                  lineid:resdata.customerdetail.lineid,
                  sublineid:resdata.customerdetail.sublineid,
                  linemanid:resdata.customerdetail.linemanid,
                  hawkerid:resdata.customerdetail.hawkerid,
                  areaid:resdata.customerdetail.areaid,
                  linename:resdata.customerdetail.linename,
                  sublinename:resdata.customerdetail.sublinename,
                  linemanname:resdata.customerdetail.linemanname,
                  hawkername:resdata.customerdetail.hawkername,
                  areaname:resdata.customerdetail.areaname,
                  address1:resdata.customerdetail.address1,
                  address2:resdata.customerdetail.address2,
                  isgpslocation:resdata.customerdetail.isgpslocation,
                  latitude:resdata.customerdetail.latitude,
                  longitude:resdata.customerdetail.longitude,
                  status:resdata.customerdetail.status,
                  isdiscount:resdata.customerdetail.isdiscount,
                  discount:resdata.customerdetail.discount,
                  isopeningbalance:Number(tempisopeningbalance),
                  openingbalance:resdata.customerdetail.openingbalance,
                  isincreasepricing:resdata.customerdetail.isincreasepricing,
                  increasepricing:resdata.customerdetail.increasepricing,
                  dob:resdata.customerdetail.dob,
                  anniversarydate:resdata.customerdetail.anniversarydate,
                  customersince:resdata.customerdetail.customersince,
                  liftavailable:resdata.customerdetail.liftavailable,
                  housenumber:resdata.customerdetail.housenumber,
                  floor:resdata.customerdetail.floor,
                  dobavailable:Number(resdata.customerdetail.dobavailable),
                  anniversaryavailable:Number(resdata.customerdetail.anniversaryavailable),
                  customerdateavailable:Number(0),
                  statelist:resdata.statelist,
                  citylist:resdata.citylist,
                  linelist:resdata.linelist,
                  linemanlist:resdata.linemanlist,
                  hawkerlist:resdata.hawkerlist,
                  arealist:resdata.arealist,
                  isptr:false,
                  canprintinvoice:Number(resdata.customerdetail.canprintinvoice),
                  caneditopeningbalance:resdata.customerdetail.caneditopeningbalance,
  
                }
              },()=>{

                Promise.all([
                  this.sublineAutocompletePopup(),
                  this.lineAutocompletePopup(),
                  /*this.linemanAutocompletePopup(),*/
                  this.hawkerAutocompletePopup(),
                  this.onPageInit()
                ])
                .then(() => {

                  this.Dom7('#autocomplete-area-name2').text(this.state.areaname);
                  this.Dom7('#autocomplete-line-name2').text(this.state.linename);
                  this.Dom7('#autocomplete-subline-name2').text(this.state.sublinename);
                  /*this.Dom7('#autocomplete-lineman-name2').text(this.state.linemanname);*/
                  this.Dom7('#autocomplete-hawker-name2').text(this.state.hawkername);
                  this.Dom7('#autocomplete-floor-name2').text(this.state.floor);
                  
                })

              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    fetchNewLine(newlineid, newline){

      this.setState(()=>{

        return{

          lineid:newlineid

        }

      },()=>{

        this.Dom7('#autocomplete-line-name2').text(newline);

      })

    }

    fetchNewSubLine(newsublineid, newsubline){

      this.setState(()=>{

        return{

          sublineid:newsublineid

        }

      },()=>{

        this.Dom7('#autocomplete-subline-name2').text(newsubline);

      })

    }

    fetchNewLineman(newlinemanid, newlineman){

      this.setState(()=>{

        return{

          linemanid:newlinemanid

        }

      },()=>{

        this.Dom7('#autocomplete-lineman-name2').text(newlineman);

      })

    }

    fetchNewHawker(newhawkerid, newhawker){

      this.setState(()=>{

        return{

          hawkerid:newhawkerid

        }

      },()=>{

        this.Dom7('#autocomplete-hawker-name2').text(newhawker);

      })

    }

    fetchNewArea(newareaid, newarea){

      this.setState(()=>{

        return{

          areaid:newareaid

        }

      },()=>{

        this.Dom7('#autocomplete-area-name2').text(newarea);

      })

    }

    getInventoryDetail(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetCustomerInventory',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          pincode:self.state.clientdetail.pincode,
          customerid:self.props.f7route.params.id,
      };

      /*if(this.state.isptr !== true)
      {
        app.preloader.show();
      }*/

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({

                    inventorylist:resdata.inventorylist,
                    isptr:false

                });
              }
              else
              {
                this.setState({

                  isptr:false

                });
              }

          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    handleInventoryInput(catid,inventoryid){

        let tempInventoryList       = [...this.state.inventorylist];
        const tempInventoryList2    = tempInventoryList.find(category => Number(category.id) === Number(catid));
        const tempInventory         = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(inventoryid));

        tempInventory.isassigned  = !tempInventory.isassigned;

        this.setState(()=>{
          
          return{

            inventorylist: tempInventoryList

          }

        });

    }

    saveDetail(){
      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      let errmsg    = "";

      if(!this.$f7.online)
      {        
        return;
      }

      app.input.validateInputs('.adddata');      

      if(this.state.email != "" && !this.check_email(this.state.email)  && errmsg === "")
      {
        errmsg  = "Please enter a valid email.";
      }

      if((this.state.areaid === "" || this.state.areaid === undefined || Number(this.state.areaid) < 1 || Number(this.state.areaid) === 'NaN') && errmsg === "")
      {
        errmsg  = "Please select an area";
      }

      if((this.state.lineid === "" || this.state.lineid === undefined || Number(this.state.lineid) < 1 || Number(this.state.lineid) === 'NaN') && errmsg === "")
      {
        errmsg  = "Please select a line";
      }

      if((this.state.sublineid === "" || this.state.sublineid === undefined || Number(this.state.sublineid) < 1 || Number(this.state.sublineid) === 'NaN') && errmsg === "")
      {
        errmsg  = "Please select a sub line";
      }

      /*if((this.state.linemanid === "" || this.state.linemanid === undefined) && errmsg === "")
      {
        errmsg  = "Please select a lineman";
      }*/

      if((this.state.hawkerid === "" || this.state.hawkerid === undefined || Number(this.state.hawkerid) < 1 || Number(this.state.hawkerid) === 'NaN') && errmsg === "")
      {
        errmsg  = "Please select a hawker";
      }

      if(this.state.areaid === "" || this.state.areaid === undefined || Number(this.state.areaid) < 1 || Number(this.state.areaid) === 'NaN')
      {
        this.$$('.autocomplete-area-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-area-popup').removeClass('error');
      }

      if(this.state.lineid === "" || this.state.lineid === undefined || Number(this.state.lineid) < 1 || Number(this.state.lineid) === 'NaN')
      {
        this.$$('.autocomplete-line-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-line-popup').removeClass('error');
      }

      if(this.state.sublineid === "" || this.state.sublineid === undefined || Number(this.state.sublineid) < 1 || Number(this.state.sublineid) === 'NaN')
      {
        this.$$('.autocomplete-subline-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-subline-popup').removeClass('error');
      }

      /*if(this.state.linemanid === "" || this.state.linemanid === undefined)
      {
        this.$$('.autocomplete-lineman-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-lineman-popup').removeClass('error');
      }*/

      if(this.state.hawkerid === "" || this.state.hawkerid === undefined || Number(this.state.hawkerid) < 1 || Number(this.state.hawkerid) === 'NaN')
      {
        this.$$('.autocomplete-hawker-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-hawker-popup').removeClass('error');
      }

      if((this.state.floor === "" || this.state.floor === undefined || this.state.floor === 'Select') && errmsg === "")
      {
        errmsg  = "Please select a floor";
      }

      if(this.state.floor === "" || this.state.floor === undefined || this.state.floor === 'Select')
      {
        this.$$('.autocomplete-floor-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-floor-popup').removeClass('error');
      }

      if(this.state.housenumber === "" && errmsg === "")
      {
        errmsg  = "Please enter House No.";
      }
      if((this.state.address1 === "" || this.state.address1 === undefined) && errmsg === "")
      {
        errmsg  = "Please enter address line 1.";
      }
      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      if(Number(this.state.isdiscount) === 1)
      {
        if(this.state.discount > 0)
        {

        }
        else
        {
          const toastWithButton = app.toast.create({
            text: 'Please enter discount.',
            closeButton: true,
            closeTimeout: 3000,
          });
  
          toastWithButton.open();
  
          return false;
        }
      }
      else
      {
        this.setState({
          discount:''
        });
      }
      if(Number(this.state.isopeningbalance) === 1)
      {
        if(this.state.openingbalance !=='')
        {

        }
        else
        {
          const toastWithButton = app.toast.create({
            text: 'Please enter opening balance.',
            closeButton: true,
            closeTimeout: 3000,
          });
  
          toastWithButton.open();
  
          return false;
        }
      }
      else
      {
        this.setState({
          openingbalance:''
        });
      }

      const bodyFormData = {
        Mode:'EditCustomer',
        clientid:self.state.clientdetail.id,
        recordid:self.props.f7route.params.id,
        name:self.state.name,
        phone:self.state.phone,
        phone2:self.state.phone2,
        email:self.state.email,
        pincode:self.state.pincode,
        isgpslocation:self.state.isgpslocation,
        latitude:self.state.latitude,
        longitude:self.state.longitude,
        stateid:self.state.stateid,
        cityid:self.state.cityid,
        areaid:self.state.areaid,
        lineid:self.state.lineid,
        sublineid:Number(self.state.sublineid),
        sublinename:self.state.sublinename,
        linemanid:self.state.linemanid,
        hawkerid:self.state.hawkerid,
        address1:self.state.address1,
        address2:self.state.address2,
        status:Number(self.state.status),
        isdiscount:Number(self.state.isdiscount),
        discount:self.state.discount,
        subscription:Number(self.state.subscription),
        inventorylist:self.state.inventorylist,
        isopeningbalance:Number(self.state.isopeningbalance),
        openingbalance:self.state.openingbalance,
        caneditopeningbalance:self.state.caneditopeningbalance,
        isincreasepricing:Number(self.state.isincreasepricing),
        increasepricing:self.state.increasepricing,
        dob:self.state.dob,
        anniversarydate:self.state.anniversarydate,
        customersince:self.state.customersince,
        loginlinemanid:Number(self.state.clientdetail.linemanid),
        ismanager:Number(self.state.clientdetail.ismanager),
        areamanagerid:Number(self.state.clientdetail.areamanagerid),
        housenumber:self.state.housenumber,
        floor:self.state.floor,
        liftavailable:Number(self.state.liftavailable),
        dobavailable:Number(self.state.dobavailable),
        anniversaryavailable:Number(self.state.anniversaryavailable),
        customerdateavailable:Number(0),
        canprintinvoice:Number(self.state.canprintinvoice),
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
 
            const resdata = JSON.parse(res.data);

            /*app.dialog.alert(resdata.msg);*/

            const toastWithButton2 = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton2.open();

            if(resdata.success === true)
            {
              let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker, openingbalanceid, filteropeningbalance, page} = this.state.filtervars;

              if(Number(areaid) < 1 || areaid === "NaN")
              {
                areaid      = "";
                filterarea  = "";
              }

              if(Number(lineid) < 1 || lineid === "NaN")
              {
                lineid      = "";
                filterline  = "";
              }
              
              if(Number(hawkerid) < 1 || hawkerid === "NaN")
              {
                hawkerid      = "";
                filterhawker  = "";
              }
             
              if(Number(openingbalanceid) < 1 || openingbalanceid === "NaN" || openingbalanceid === "undefined" || openingbalanceid === undefined)
              {
                openingbalanceid      = "";
                filteropeningbalance  = "";
              }
              
              if(Number(page) < 1 || page === "NaN")
              {
                page = "";
              }

              router.navigate(`/managecustomers/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&page=${page}`);
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    goToSubsription(){
      this.$f7router.navigate(`/subscription/${this.props.f7route.params.id}/`);
      /*this.$f7.views.main.router.navigate(`/${link}/`);*/
    }

    checkSingleHawker(){

      const self      = this;
      const app       = self.$f7;


      const bodyFormData = {
        Mode:'CheckHawker',
        type:'sequencefilter',
        clientid:self.state.clientdetail.id,
        areaid:self.state.areaid,
        lineid:self.state.lineid,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({

                hawkerid:resdata.recordlist.id,
                /*filterhawker:resdata.recordlist.name,
                hascustomer:false,
                isfetched:false,*/

              },()=>{
                  this.Dom7('#autocomplete-hawker-name2').text(resdata.recordlist.name);
                  this.Dom7('.autocomplete-hawker-popup').removeClass('error');
              });
            }
            else
            {
              this.setState({

                hawkerid:'',
                /*filterhawker:'Select',*/

              },()=>{
                  this.Dom7('#autocomplete-hawker-name2').text("Select");
                  this.Dom7('.autocomplete-hawker-popup').removeClass('error');
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){
      
      const{colwidth, clientdetail, areaid, areaname, lineid, ismount, issmartarea, issmartline, isgpslocation, gpslocationfetched, gpslocationtext, floor, dobavailable, anniversaryavailable, canprintinvoice, caneditopeningbalance}  = this.state;

      return(
        <FilterConsumer>{(value)=>{


          const{setAreaData, setLineData}  = value;

          const{singlearea, singleline, singlesubline, singlehawker} = value;

          if(ismount === true)
          {
            setTimeout(()=> {

              if(issmartarea === true)
              {
                if((Number(singlearea.id) !== Number(areaid)) && areaid > 0)
                {
                  Promise.all([

                    setAreaData(Number(areaid), areaname, false)

                  ])
                  .then(() => {
 
                    this.setState({

                      issmartarea:false,
                      lineid:'',
                      sublineid:'',
                      hawkerid:'',

                    });

                    this.fetchNewLine('', 'Select');
                    this.fetchNewSubLine('', 'Select');
                    this.checkSingleHawker();
                  })

                }
              }
              else
              {
                if(Number(this.state.areaid) !== Number(singlearea.id) && (singlearea.id !== undefined) && Number(singlearea.id) > 0)
                {
                  this.setState({
                    lineid:'',
                    sublineid:'',
                    hawkerid:''
                  });

                  this.fetchNewArea(singlearea.id, singlearea.name);
                  this.fetchNewLine('', 'Select');
                  this.fetchNewSubLine('', 'Select');
                  this.checkSingleHawker();                  
                }
              }

              if(issmartline === true)
              {
                if((Number(singleline.id) !== Number(lineid)) && lineid > 0)
                {
                  Promise.all([

                    setLineData(Number(lineid), linename, false)

                  ])
                  .then(() => {
 
                    this.setState({

                      issmartline:false,
                      sublineid:'',
                      hawkerid:''

                    });
 
                    this.fetchNewSubLine('', 'Select');
                    this.checkSingleHawker();
                  })

                }
              }
              else
              {
                if((Number(lineid) !== Number(singleline.id)) && (singleline.id !== undefined) && Number(singleline.id) > 0)
                {
                  this.setState({
                    sublineid:'',
                    hawkerid:''
                  });
                  
                  this.fetchNewLine(singleline.id, singleline.name);
                  this.fetchNewSubLine('', 'Select');
                  this.checkSingleHawker();                  
                }
              }

              if((Number(singlesubline.id) !== Number(this.state.sublineid)) && singlesubline.id > 0)
              {
                this.fetchNewSubLine(singlesubline.id, singlesubline.name);
              }

              if((Number(singlehawker.id) !== Number(this.state.hawkerid)) && singlehawker.id > 0)
              {
                this.fetchNewHawker(singlehawker.id, singlehawker.name);
              }
  
            }, 500)
            
          }

          return(
                <Page pageContent={false} className="page" name="EditCustomer">
                  <Navbar sliding={true}>
                      <NavLeft>
                        <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                        <Link href={`/dashboard/`} className="clearmenupadding">
                          <img className="navbarlogo" src={scanlogo} alt="logo" />
                        </Link>
                      </NavLeft>
                      <NavTitle>Edit Customer</NavTitle>
                      <NavRight className="right-with-menu">
                      </NavRight>
                  </Navbar>
                  <Toolbar tabbar labels position={this.state.toolbarPosition}>
                    <Link tabLink="#tab-1" tabLinkActive text="Profile" iconIos="f7:envelope_fill" iconAurora="f7:envelope_fill" iconMd="material:email"></Link>
                    <Link tabLink="#tab-2" text="Subscription" iconIos="f7:calendar_fill" iconAurora="f7:calendar_fill" iconMd="material:today"></Link>
                  </Toolbar>
                  <Tabs swipeable>
                    <Tab id="tab-1" tabActive>
                    <Page pageContent={false} className="page">
                      <PageContent>
                        <List noHairlinesMd style={{marginBottom:'5px'}}>

                          <ListInput
                            name="name"
                            label="Name"
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="Name"
                            value={this.state.name}
                            maxlength="200"
                            clearButton validate
                          >
                          </ListInput>

                          <ListInput
                            name="phone"
                            label="Mobile"
                            floatingLabel
                            type="tel"
                            onInput={(e)=>{
                            
                              let tempphone = e.target.value;
                              let mobile    = tempphone.replace(/ /g,'');
                              
                              if(tempphone.slice(0,1)=='+')
                              {
                                mobile = mobile.substring(3);
                              }
  
                              if(tempphone.slice(0,1)=='0')
                              {
                                mobile = mobile.substring(1);
                              }
  
                              if(mobile.length > 10)
                              {
                                return;
                              }
                              
                              this.setState({
                                phone:mobile
                              })
                            }}
                            placeholder="Mobile Number"
                            value={this.state.phone}
                            clearButton validate pattern="[0-9]*"
                          >
                          </ListInput>

                          <ListInput
                            name="phone2"
                            label="Alt. Mobile"
                            floatingLabel
                            type="tel"
                            onInput={(e)=>{
                            
                              let tempphone = e.target.value;
                              let mobile    = tempphone.replace(/ /g,'');
                              
                              if(tempphone.slice(0,1)=='+')
                              {
                                mobile = mobile.substring(3);
                              }
  
                              if(tempphone.slice(0,1)=='0')
                              {
                                mobile = mobile.substring(1);
                              }
  
                              if(mobile.length > 10)
                              {
                                return;
                              }
                              
                              this.setState({
                                phone2:mobile
                              })
                            }}
                            placeholder="Alt. Mobile Number"
                            value={this.state.phone2}
                            clearButton validate pattern="[0-9]*"
                          >
                          </ListInput>

                          <ListInput
                            name="email"
                            label="Email"
                            floatingLabel
                            type="email"
                            onInput={this.handleUserInput}
                            placeholder="Email"
                            value={this.state.email}
                            clearButton validate
                          >
                          </ListInput>
                        </List>
                        <Row>
                            <Col width={colwidth}>
                            <List noHairlinesMd className="zeromargin">
                              <ListItem link="#" id="autocomplete-area-popup" className="autocomplete-area-popup" title="Area">
                                <div slot="after" id="autocomplete-area-name2">Select</div>
                              </ListItem>
                            </List>
                            </Col>
                            {
                              clientdetail.islineman || clientdetail.ismanager ? null:(
                                <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                                  <Link onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.$f7router.navigate('addareapopup/')
                                    }
                                  }}>
                                    <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                                  </Link>
                                </Col>
                              )
                            }
                        </Row>
                        <Row>
                            <Col width={colwidth}>
                            <List noHairlinesMd className="zeromargin">
                              <ListItem link="#" id="autocomplete-line-popup" className="autocomplete-line-popup" title="Line">
                                <div slot="after" id="autocomplete-line-name2">Select</div>
                              </ListItem>
                            </List>
                            </Col>
                            {
                              this.state.clientdetail.islineman || this.state.clientdetail.ismanager ? null:(
                                <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                                  <Link onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.$f7router.navigate('addlinepopup/');
                                    }
                                  }}>
                                    <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                                  </Link>
                                </Col>
                              )
                            }
                        </Row>
                        <Row>
                            <Col width={colwidth}>
                            <List noHairlinesMd className="zeromargin">
                              <ListItem link="#" id="autocomplete-subline-popup" className="autocomplete-subline-popup" title="Sub Line">
                                <div slot="after" id="autocomplete-subline-name2">Select</div>
                              </ListItem>
                            </List>
                            </Col>
                            {
                              this.state.clientdetail.islineman || this.state.clientdetail.ismanager ? null:(
                                <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                                  <Link onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.$f7router.navigate('addsublinepopup/');
                                    }
                                  }}>
                                    <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                                  </Link>
                                </Col>
                              )
                            }
                        </Row>
                        <Row>
                            <Col width={colwidth}>
                            <List noHairlinesMd className="zeromargin">
                              <ListItem link="#" id="autocomplete-hawker-popup" className="autocomplete-hawker-popup" title="Hawker">
                                <div slot="after" id="autocomplete-hawker-name2">Select</div>
                              </ListItem>
                            </List>
                            </Col>
                            {
                              this.state.clientdetail.islineman || this.state.clientdetail.ismanager ? null:(
                                <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                                  <Link onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.$f7router.navigate('addhawkerpopup/')
                                    }
                                  }}>
                                    <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                                  </Link>
                                </Col>
                              )
                            }
                        </Row>
                        <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                        <ListInput
                            name="housenumber"
                            className="adddata"
                            label="House No."
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="House No."
                            value={this.state.housenumber}
                            clearButton required validate
                          >
                          </ListInput>
                          <ListItem link="#" id="autocomplete-floor-popup" className="autocomplete-floor-popup" title="Floor">
                            <div slot="after" id="autocomplete-floor-name2">{floor}</div>
                          </ListItem>
                          <ListInput
                            name="address1"
                            className="adddata"
                            label="Address Line 1"
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="Address Line 1"
                            value={this.state.address1}
                            clearButton required validate
                          >
                          </ListInput>
                          </List>
                      {/*}
                    <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                      <ListItem title="Opening Balance">
                        <Toggle slot="after" value="1" name="isopeningbalance" onChange={this.handleUserInput} checked={Number(this.state.isopeningbalance) === 1}></Toggle>
                      </ListItem>
                    </List>
                    {*/}
                {
                  caneditopeningbalance ? (
                    <Row>
                        <Col width="50">
                          <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                            {(()=>{
                              if(this.state.isopeningbalance > 0)
                              {
                                return(
                                    <ListInput
                                        className="smallinputnumber adddata"
                                        name="openingbalance"
                                        label="Opening Balance"
                                        floatingLabel
                                        type="number"
                                        onInput={this.handleUserInput}
                                        placeholder="Opening Balance"
                                        value={this.state.openingbalance}
                                        clearButton required validate
                                    >
                                    </ListInput>
                                );
                              }
                            })()}
                          </List>
                        </Col>
                        <Col width="50">
                        </Col>
                    </Row>
                  ):null
                }
                    <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                          <ListItem title="Date Of Birth?">
                            <Toggle slot="after" value="1" name="dobavailable" onChange={this.handleUserInput} checked={Number(dobavailable) === 1}></Toggle>
                          </ListItem>
                          {
                            Number(dobavailable) ? (
                              <ListInput
                                name="dob"
                                id="calendar-dob-input"
                                label="Date Of Birth"
                                placeholder="Date Of Birth"
                                type="date"
                                value={this.state.dob}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                              >
                              </ListInput>
                            ):null
                          }
                          <ListItem title="Anniversary Date?">
                            <Toggle slot="after" value="1" name="anniversaryavailable" onChange={this.handleUserInput} checked={Number(anniversaryavailable) === 1}></Toggle>
                          </ListItem>
                          {
                            Number(anniversaryavailable) ? (
                              <ListInput
                                name="anniversarydate"
                                label="Anniversary Date"
                                placeholder="Anniversary Date"
                                type="date"
                                onChange={this.handleUserInput}
                                value={this.state.anniversarydate}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                              >
                              </ListInput>
                            ):null
                          }
                          {/*<ListItem title="Customer Date?">
                            <Toggle slot="after" value="1" name="customerdateavailable" onChange={this.handleUserInput} checked={Number(customerdateavailable) === 1}></Toggle>
                          </ListItem>
                          {
                            Number(customerdateavailable) ? (
                              <ListInput
                                name="customersince"
                                id="customersince"
                                label="Customer Date"
                                placeholder="Customer Date"
                                type="date"
                                value={this.state.customersince}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                              >
                              </ListInput>
                            ):null
                          }*/}
                          <ListItem title="Lift Available">
                            <Toggle slot="after" value="1" name="liftavailable" onChange={this.handleUserInput} checked={Number(this.state.liftavailable) === 1}></Toggle>
                          </ListItem>
                          <ListItem title="GPS Location">
                          <Toggle slot="after" value="1" name="isgpslocation" onChange={this.handleUserInput} checked={Number(this.state.isgpslocation) === 1}></Toggle>
                          </ListItem>
                        </List>
                      {Number(isgpslocation) > 0 ? 
                      (
                      <Row>
                        <Col width={90} style={{textAlign:'left',paddingLeft:'15px',fontWeight:'bold'}}>
                        {Number(gpslocationfetched > 0) ? (<span className='textlightgreencolor'>{gpslocationtext}</span>) : (<span className='textredcolor'>{gpslocationtext}</span>)}
                        </Col>
                        <Col width={10}></Col>
                      </Row>): null}      
                      <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>      
                        <ListItem title="Apply Discount">
                          <Toggle slot="after" value="1" name="isdiscount" onChange={this.handleUserInput} checked={Number(this.state.isdiscount) === 1}></Toggle>
                        </ListItem>
                      </List>
                      <Row>
                          <Col width="50">
                          </Col>
                          <Col width="50">
                            <List noHairlinesMd style={{marginBottom:'10px',marginTop:'10px'}}>
                              {(()=>{
                                if(this.state.isdiscount > 0)
                                {
                                  return(
                                    <ListInput
                                    name="discount"
                                    className="adddata"
                                    label="Discount (%)"
                                    floatingLabel
                                    type="number"
                                    onInput={this.handleUserInput}
                                    placeholder="Discount"
                                    value={this.state.discount}
                                    clearButton validate
                                    >
                                    </ListInput>
                                  );
                                }
                              })()}
                            </List>
                          </Col>
                      </Row>
                      <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                        <ListItem title="Increase Pricing">
                          <Toggle slot="after" value="1" name="isincreasepricing" onChange={this.handleUserInput} checked={Number(this.state.isincreasepricing) === 1}></Toggle>
                        </ListItem>
                      </List>
                      <Row>
                          <Col width="50">
                          </Col>
                          <Col width="50">
                            <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                              {(()=>{
                                if(this.state.isincreasepricing > 0)
                                {
                                  return(
                                      <ListInput
                                          className="smallinputnumber"
                                          name="increasepricing"
                                          label="Pricing %"
                                          floatingLabel
                                          type="number"
                                          onInput={this.handleUserInput}
                                          placeholder="Pricing %"
                                          value={this.state.increasepricing}
                                          clearButton validate
                                      >
                                      </ListInput>
                                  );
                                }
                              })()}
                            </List>
                          </Col>
                      </Row>

                      <List noHairlinesMd className="zeromargin">
                        <ListItem title="Subscription">
                          <Toggle slot="after" name="subscription" checked={Number(this.state.subscription) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                        <ListItem title="Print Invoice?">
                          <Toggle slot="after" name="canprintinvoice" checked={Number(canprintinvoice) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                      </List>
                        <br /><br /><br />
                      </PageContent>
                        <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
                      </Page>
                    </Tab>
                    <Tab id="tab-2" className="page-content">
                      <Block>
                        <br /><br /><br /><br /><br /><br />
                        <Button fill color="blue" onClick={()=>{this.goToSubsription()}}>Proceed To Subsription</Button>
                      </Block>
                    </Tab>
                  </Tabs>
                </Page>
          );

        }}</FilterConsumer>
      );
    }
}