import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    BlockTitle,
    Link,
    Block,
    Row,
    Col,
    Icon,
    div,
    Page,
    PageContent,
  } from 'framework7-react';

  export default class magazinedetailbyline extends Component {
    constructor(props) {
      super(props);

      this.state = {
        clientdetail:[],
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        totalrecord:'',
        paymentdate:'',
        inventoryname:'',
        recordset:[],
        filtervars:[],
        hasdataavailable:false,
      };

      this.getDetail = this.getDetail.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{

        this.getDetail();

        this.hideSplashScreen();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }
    
    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
        
    getDetail(){
      const self      = this;
      const app       = self.$f7;

      let{areaid, lineid, hawkerid, startdate, enddate, inventoryid,checklineid,checkareaid} = this.state.filtervars;

      const bodyFormData = {
        Mode:'GetSingleMagazineSaleByLineID',
        frompage:'linedetails',
        clientid:self.state.clientdetail.id,
        stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        areaid:areaid,
        lineid:lineid,
        hawkerid:hawkerid,
        inventoryid:inventoryid,
        startdate:startdate,
        enddate:enddate,
        checkareaid:checkareaid,
        checklineid:checklineid,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
	      if(resdata.success === true)
              {
                this.setState({

                    recordset:resdata.recordset,
                    totalrecord:resdata.totalrecord,
                    /*paymentdate:resdata.paymentdate,*/
                    isptr:false,
                    hasdataavailable:true,

                },()=>{

                    /*this.Dom7('.subscription').addClass('animate-bottom');*/
  
                  });
              }
              else
              {
                this.setState({
                  recordset:[],
                  totalrecord:'',
                  paymentdate:'',
                  isptr:false,
                  hasdataavailable:false

                });
              }

          }).catch(e => {
              console.log(e);
          });

      },1000);
    }

    navigateToLink(link){
      console.log(link)
      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){

        const{recordset, hasdataavailable, filtervars} = this.state;
	  return(
            <Popup className="demo-popup">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link popupClose>
                      <i className="icon icon-back"></i>                      
                    </Link>
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                  </NavLeft>
                  <NavTitle>Detail</NavTitle>
                  <NavRight className="right-with-menu">
                    <Link onClick={()=>{
            
                        this.getDetail();
            
                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                <Page className="page" name="SubscriptionLog">
                    {
                        hasdataavailable ? (
                            <Block>
                                <BlockTitle medium className="zeroleftmargin" style={{fontSize:'18px'}}>Magazine : {filtervars.inventoryname}</BlockTitle>
                                    <PageContent style={{paddingTop:0}}>
                                        
                                            <div className="data-table">
                                               
                                                    {
                                                        recordset.map((detail, arealoop)=>{
                            
                                                        return(
								<Fragment key={arealoop}>
								<BlockTitle small style={{textAlign:'center'}}>Area: {detail.areaname} <br/><br/>Line: {detail.linename} ({detail.totalstock}) 
								<br/><br/>Total Records: {detail.totalrecords} </BlockTitle>

								<Row noGap className='rowwithbottomborder'>
								    <Col width='70' className="tableheader textalignleft">
									Customer
								    </Col>
								    <Col width='30' className="tableheader textalignleft">
									Qty
								    </Col>
								</Row>
								{
								detail.linedata.map((linedetail, lineloop)=>{
                            
									return(	<Row key={lineloop} noGap className='rowwithbottomborder'>
										    <Col width='70' className="ledgercolumn textalignleft">
										    {linedetail.customername}
										    </Col>
										    <Col width='30' className="ledgercolumn textalignleft">
										    {linedetail.itemqty}
										    </Col>
										</Row>
									);
									})
								}
                                                        </Fragment>
							
							);
                                                        
							})
                                                    }
                                                                                       
                                            </div>
                                        
                                    <br /><br />
                                    </PageContent>
                            </Block>
                        ):null
                    }
                </Page>
            </Popup>
          );
    }
}