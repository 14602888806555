import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppCardItemLoading from '../pages/appcarditemloading';
import AppFav from '../pages/appfav';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Block,
    BlockTitle,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    Chip
  } from 'framework7-react';

  export default class ManageHawker extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        isavailable:false,
        loaded:false,
        actionGridOpened: false,
        oneGroupOpened: false,
        deleterecordid:'',
        isptr:false,
        effect:'blink',
        totalrecord:0,
        applyfilter:false,
        areaid:'',
        lineid:'',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        hasallcustomer:true,
        ismount:false,
        removefiltername:'',
        applyremovefilter:false,
        linemans:[]
      };

      this.getAllLineman      = this.getAllLineman.bind(this);
      this.navigateToLink     = this.navigateToLink.bind(this);
      this.setNewFilterData   = this.setNewFilterData.bind(this);
      this.openFilterPopup    = this.openFilterPopup.bind(this);      
    }

    componentDidMount(){
  
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.hideSplashScreen();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          setTimeout(()=> {

            this.getAllLineman();
            
          }, 200)

        })
        
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
          Mode:'DeleteLineman',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/lineman.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              this.getAllLineman();
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    getAllLineman(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }

        this.setState({
          loaded:false
        });
  
        const bodyFormData = {
          Mode:'GetAllLineman',
          clientid:this.state.clientdetail.id,
          areaid:this.state.areaid,
          lineid:this.state.lineid,
        };

        /*if(this.state.isptr !== true)
        {
          app.preloader.show();
        }*/
  
        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/lineman.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  loaded:true,
                  isavailable:true,
                  linemans:resdata.recordlist,
                  isptr:false,
                  totalrecord:resdata.totalrecord
                });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  isptr:false,
                  totalrecord:0
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);
    }

    navigateToLink(link){
        
        if(this.$f7.online)
        {
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/
        }

    }

    setNewFilterData(lineid, filterline, areaid, filterarea){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            lineid:lineid,
            filterarea:filterarea,
            filterline:filterline,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
          }
  
        },()=>{
  
          if(Number(this.state.lineid) > 0 || Number(this.state.areaid) > 0)
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getAllLineman();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');      
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })

    }

    render(){

      const{isavailable, loaded, linemans, totalrecord} = this.state;

      const{hasfilter, filterline, filterarea, hasallcustomer, ismount, areaid, lineid} = this.state;

      return(
            <Page pageContent={false} className="page" name="ManageLineman">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Lineman</NavTitle>
                    <NavRight className="right-with-menu">
                    <Link onClick={()=>{

                      Promise.all([
                
                        setTimeout(()=> {
              
                          this.Dom7('.waiting').addClass('animate-bottom');
              
                        }, 50)
              
                      ])
                      .then(() => {

                        this.getAllLineman();
                                      
                      });

                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                    </NavRight>
                  </Navbar>
                  <FilterConsumer>{(value)=>{

                    const{linemanfilterdata, applyLinemanFilter} = value;
                    
                    if(ismount === true)
                    {
                      setTimeout(()=> {
          
                        if(this.state.removefiltername !== "" && this.state.applyremovefilter === true)
                        {
                          if(this.state.removefiltername === 'area')
                          {
                            applyLinemanFilter(lineid, filterline, '', '');
                          }
                          else if(this.state.removefiltername === 'line')
                          {
                            applyLinemanFilter('', '', areaid, filterarea);
                          }
                        }
          
                        if(((linemanfilterdata.lineid !== lineid) && linemanfilterdata.lineid !== undefined) || ((linemanfilterdata.areaid !== areaid) && linemanfilterdata.areaid !== undefined) || this.state.applyremovefilter === true)
                        {
                          this.setNewFilterData(linemanfilterdata.lineid, linemanfilterdata.filterline, linemanfilterdata.areaid, linemanfilterdata.filterarea);
          
                          this.setState({
                            removefiltername:'',
                            applyremovefilter:false
                          })
          
                        }
                        
                      }, 500)
                    }
                    return(
                  <PageContent>                                        
                  {
                    loaded ? (<Fragment>
                      {
                        isavailable ? (<BlockTitle className="totalrec">Total Lineman : {totalrecord}</BlockTitle>):null
                      }
                      {
                        hasfilter ? (
                          <Block strong className="selectedfilter" style={{marginTop:0}}>
                          {hasallcustomer ? (<Chip text={`Lineman : All`} mediaBgColor="blue" media="A"/>):null}

                            {Number(areaid) ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                            onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.setState(()=>{
                                        return {
                                          removefiltername:'area'
                                        }
                                      },()=>{
                                        this.refs.actionsFilterGroup.open()
                                      })
                                    }
                                  }}
                            />):null}

                            {Number(lineid) ? (<Chip text={`Line : ${filterline}`} mediaBgColor="green" media={filterline.toUpperCase().substring(0, 1)} deleteable
                            onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.setState(()=>{
                                        return {
                                          removefiltername:'line'
                                        }
                                      },()=>{
                                        this.refs.actionsFilterGroup.open()
                                      })
                                    }
                                  }}
                            />):null}

                            &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                              this.openFilterPopup();
                            }}/>
                          </Block>
                        ):null
                      }
                      {
                        isavailable ? (
                          <Fragment>
                            {
                                linemans.map((lineman)=>{
                                  return(
                                    <Card outline key={lineman.id} className="recordset">
                                      <CardHeader
                                      className="date">{lineman.addeddate}</CardHeader>
                                      <CardContent>
                                        <p><b>Name : </b>{lineman.name}</p>
                                        {lineman.phone ? (<div><b>Phone : </b>{lineman.phone}
                                          &nbsp;
                                          {
                                            lineman.phone !== "---" ? (
                                              <Link external href={`tel:${lineman.phone}`} target="_system">
                                                <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                              </Link>
                                            ):null
                                          }
                                        </div>):null}
                                        <p><b>Area : </b>{lineman.area}</p>
                                        <p><b>Line : </b>{lineman.lines}</p>
                                        {lineman.dobtext ? (<p><b>Date Of Birth :</b> {lineman.dobtext}</p>):null}
                                      </CardContent>
                                      <CardFooter>
                                        <Link onClick={()=>{this.navigateToLink(`editlineman/${lineman.id}`)}} ignoreCache={true}>Edit</Link>
                                        {
                                          !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                            <Link onClick={() =>{
                                              if(this.$f7.online)
                                              {
                                                this.setState(()=>{
                                                  return {
                                                    deleterecordid:lineman.id
                                                  }
                                                },()=>{
                                                  this.refs.actionsOneGroup.open()
                                                })
                                              }
                                            }}>Delete</Link>
                                          ):(<div>&nbsp;</div>)
                                        }
                                      </CardFooter>
                                    </Card>
                                  );
                                })
                            }
                          </Fragment>
                        ):(<AppItemNotFound notfoundtext ="No Lineman record found." />)
                      }
                    </Fragment>):(<AppCardItemLoading />)
                  }
                  <br /><br />
                  </PageContent>
                );
            }}
            </FilterConsumer>
              <Actions ref="actionsOneGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteRecord()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <Actions ref="actionsFilterGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <AppFav position="center-bottom" text="Add Lineman" icon="plus" url="addlineman" />
            </Page>
      );
    }
}