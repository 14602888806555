import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';
import AppFav from '../pages/appfav';

import { SaleConsumer } from '../context/SaleContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Row,
    Col,
    Link,
    Block,
    Icon,
    Chip  } from 'framework7-react';

  export default class ManageHoliday extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isdataloaded:false,
        clientdetail:[],
        filtervars:[],
      }

    }

    componentDidMount(){

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        this.setState(()=>{
            return{
              clientdetail:clientdetail,
              filtervars:this.props.$f7route.query
            }
        })
    }

    resendInvoiceMessage(customerid, smscredits){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'ResendInvoiceMessage',
        customerid:customerid,
        smscredits:smscredits,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    getMessagePreviewToSend(customerid){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'GetInvoiceMessagePreview',
        customerid:customerid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          if(resdata.success)
          {
            /*const parser        = new DOMParser();
            const msgpreviewhtml  = parser.parseFromString(resdata.preview, 'text/html').documentElement.textContent;*/
            
            app.dialog.create({
              title: 'Message Preview',
              text: resdata.preview,
              closeByBackdropClick: 'true',
              buttons: [
                {
                  text: 'Cancel',
                },
                {
                  text: 'Send',
                  onClick: () => {
                    this.resendInvoiceMessage(customerid, resdata.smscredits);
                  },
                },
              ],
            }).open();
          }
          else
          {
            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();
          }

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }
    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }
    render(){
      
        return(
            <SaleConsumer>{(value)=>{
              const{getAllSale, init, deleteFilterAction,deleteRecordAction,deleteRecord,salesfilterdata} = value;

              const{hasfilter, hasallcustomer, filterstock, filterarea, filterline, filterhawker, isfetched, isavailable, totalrecord, sales, showpages, stockid, areaid, lineid, hawkerid, saletype, startdate, enddate} = value;

              let extraspaceclass = "";

              if(showpages)
              {
                extraspaceclass = "withtoolbar";
              }

              let datestart     = "";
              let newstartdate  = "";
        
              if(startdate !== "" && startdate !== undefined)
              {
                newstartdate = new Date(startdate);
        
                let currmonth = newstartdate.getMonth()+1;
                if(currmonth < 10)
                {
                    currmonth = "0"+currmonth;
                }
                datestart = newstartdate.getDate()+"-"+currmonth+"-"+newstartdate.getFullYear();
              }
        
              let dateend     = "";
              let newenddate  = "";
        
              if(enddate !== "" && enddate !== undefined)
              {
                newenddate = new Date(enddate);
        
                let currmonth = newenddate.getMonth()+1;
                if(currmonth < 10)
                {
                    currmonth = "0"+currmonth;
                }
                dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
              }

                setTimeout(() => {
                  if(this.state.isdataloaded === false)
                  {
                    this.setState(()=>{
                      return{
                        isdataloaded:true,
                      }
                    },()=>{

                      init(salesfilterdata);
                      
                    })
                    
                  }
                }, 100);

                return(
                    <Page pageContent={false} className="page" name="ManageSale">
                        <Navbar sliding={true}>
                          <NavLeft>
                            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                            <Link href={`/dashboard/`} className="clearmenupadding">
                              <img className="navbarlogo" src={scanlogo} alt="logo" />
                            </Link>
                          </NavLeft>
                          <NavTitle>Manage Sale</NavTitle>
                          <NavRight className="right-with-menu">
                          <Link onClick={()=>{

                            getAllSale();

                          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                          </NavRight>
                        </Navbar>
                        <PageContent>
                        {
                          isfetched ? (<Fragment>
                            {
                              isavailable ? (<Fragment>
                                <BlockTitle className="totalrec">Total Records : {totalrecord}</BlockTitle>
                              </Fragment>):null
                            }
                            {
                              hasfilter ? (
                                <Block strong className="selectedfilter" style={{marginTop:0}}>
                                  {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                                  <Chip text={`Sale Type : ${Number(saletype) === 606 ? `Magazine`: `Newspaper`}`} mediaBgColor="blue" media="S"/>

                                  {stockid !== "" && filterstock !== "" ? (<Chip text={`Stock : ${filterstock}`} mediaBgColor="green" media={filterstock.toUpperCase().substring(0, 1)}
                                  />):null}

                                  {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                                  onClick={()=>{
                                      if(this.$f7.online)
                                      {
                                        deleteFilterAction('area');
                                      }
                                    }}
                                  />):null}

                                  {Number(lineid) > 0 && filterline ? (<Chip text={`Line : ${filterline}`} mediaBgColor="yellow" media={filterline.toUpperCase().substring(0, 1)} deleteable
                                  onClick={()=>{
                                          if(this.$f7.online)
                                          {
                                            deleteFilterAction('line');
                                          }
                                        }}
                                  />):null}

                                  {Number(hawkerid) > 0 && filterhawker ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                                  onClick={()=>{
                                          if(this.$f7.online)
                                          {
                                            deleteFilterAction('hawker');
                                          }
                                        }}
                                  />):null}

                                  {datestart ? (<Chip text={`Date : (${datestart}) - (${dateend})`} mediaBgColor="blue" media={`D`} />):null}

                                  &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.$f7router.navigate(`filterpopup/?stockid=${stockid}&filterstock=${filterstock}&areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&saletype=${saletype}&startdate=${startdate}&enddate=${enddate}`);
                                    }
                                    /*openFilterPopup();*/
                                  }}/>
                                </Block>
                              ):null
                            }
                            {
                              isavailable ? (
                                <Fragment>
                                  <Row noGap className='rowwithbottomborder' style={{borderBottom:'1px solid #000000',borderTop:'1px solid #000000'}}>
                                    <Col width='40' className="textalignleft">
                                      <b>Stock</b>
                                    </Col>
                                    <Col width='30' style={{textAlign:'center'}}>
                                      <b>Date</b>
                                    </Col>
                                    <Col width='15' style={{textAlign:'center'}}>
                                      <b>Qty</b>
                                    </Col>
                                    <Col width='15' style={{textAlign:'center'}}>
                                      <b>Options</b>
                                    </Col>
                                  </Row>                                
                                  {
                                    sales.map((item, index)=>{
                                      return(
                                        <Row key={index} noGap className='rowwithbottomborder'>
                                          <Col width='40' className="ledgercolumn textalignleft">
                                          {item.name}
                                          </Col>
                                          <Col width='30' className="ledgercolumn">
                                          {item.saledate}
                                          </Col>
                                          <Col width='15' className="ledgercolumn">
                                          {item.count}
                                          </Col>
					   <Col width='15' className="ledgercolumn">
						{!this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (<Link onClick={() =>{

						    this.navigateToLink(`editsale/${item.stockid}/${item.saledateunix}`)
						    
						  }}><Icon f7="pencil_circle_fill"></Icon></Link>):(<span>&nbsp;</span>)}

						  {!this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (<Link onClick={() =>{

						   deleteRecordAction(item.stockid,item.saledateunix);
						    
						  }}><Icon f7="trash_circle_fill" color="red"></Icon></Link>):(<span>&nbsp;</span>)}
                                          </Col>
                                        </Row>
                                      );
                                    })
                                  }
                                </Fragment>
                              ):(<AppItemNotFound notfoundtext ="No Sale Added Yet." />)
                            }
                        </Fragment>):(<AppListItemLoading />)
                      }
                      <br /><br />
                      </PageContent>
                      <AppFav position="center-bottom" text="Add Sale" icon="plus" url="addsale" />
                    </Page>
                );
            }}
            </SaleConsumer>            
        );
    }
}