import React,{Component,Fragment} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Block,
    Row,
    Col,
    Button,
    Icon
  } from 'framework7-react';

  export default class InvoiceDetail extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

      this.state = {
        orderid:'',
        customerlist:[],
        isptr:false,
        isinvoiceloaded:false,
        hasrecord:false,
      };

      this.handleUserInput  = this.handleUserInput.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          orderid:this.props.f7route.params.id
        }
      },()=>{

        this.hideSplashScreen();
        this.getInvoiceDetail();

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{

        if(name === 'customerid')
        {
          this.getLedgerDetail();
        }

      });

    }

    getInvoiceDetail(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
            Mode:'GetInvoiceDetail',
            clientid:self.state.clientdetail.id,
            orderid:self.state.orderid,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        this.setState({
            isinvoiceloaded:false
        });

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                  this.setState({

                      isptr:false,
                      isinvoiceloaded:true,
                      hasrecord:true,
                      invoicedetail:resdata.invoicedetail,

                  });
                }
                else
                {
                  this.setState({

                    isptr:false,
                    isinvoiceloaded:true,
                    hasrecord:false,
                    invoicedetail:[]

                  });
                }

            }).catch(e => {
                console.log(e);
            });

        },500);
    }
    getInvoicePDF(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
            Mode:'GetInvoicePDF',
            clientid:self.state.clientdetail.id,
            invoiceid:self.state.orderid,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                    //console.log(resdata.pdffilepath);
                   // window.location.href= 'http://orlopay/agency/';
                    window.open(resdata.pdffilepath);
                }
                else
                {
                    const toastWithButton = app.toast.create({
                        text: resdata.msg,
                        closeButton: true,
                        closeTimeout: 3000,
                    });
                      
                    toastWithButton.open();    
                }
            }).catch(e => {
                console.log(e);
            });

        },500);
    }
    render(){
        
        const{isinvoiceloaded, hasrecord, invoicedetail} = this.state;

        return(
            <Page className="page" name="InvoiceDetail">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Bill Detail</NavTitle>
                  <NavRight className="right-with-menu">
                  <Link onClick={()=>{

                    this.getInvoiceDetail();

                  }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                <Block>
                    {
                      isinvoiceloaded ? (<Fragment>
                        {
                            hasrecord ? (<Fragment>
                                <Row noGap >
                                    <Col width='60' className='textaligleft'>
                                    </Col>
                                    <Col className="textalignright" width='40'>
                                        <Button small fill color="blue" onClick={()=>{
                                            this.getInvoicePDF();
                                            }
                                        } text='Download PDF'/>
                                       <br/>
                                    </Col>
                                </Row>
                                <Row noGap className='rowwithbottomborder'>
                                    <Col className="tableheader">
                                        Customer Details
                                    </Col>
                                </Row>
                                <Row noGap>
                                    <Col className="ledgercolumn">
                                        <Row noGap className='rowwithbottomborder'>
                                            <Col width='40' className="ledgercolumn">
                                                Cust #
                                            </Col>
                                            <Col width='60' className="ledgercolumn">
                                            {invoicedetail.custnumber}
                                            </Col>
                                        </Row>
                                        <Row noGap className='rowwithbottomborder'>
                                            <Col width='40' className="ledgercolumn">
                                                Name
                                            </Col>
                                            <Col width='60' className="ledgercolumn">
                                            {invoicedetail.custname}
                                            </Col>
                                        </Row>
                                        <Row noGap className='rowwithbottomborder'>
                                            <Col width='40' className="ledgercolumn">
                                                Address
                                            </Col>
                                            <Col width='60' className="ledgercolumn">
                                            {invoicedetail.custaddress}
                                            </Col>
                                        </Row>
                                        <Row noGap className='rowwithbottomborder'>
                                            <Col width='40' className="ledgercolumn">
                                                Phone(s)
                                            </Col>
                                            <Col width='60' className="ledgercolumn">
                                            {invoicedetail.custphone}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row noGap className='rowwithbottomborder'>
                                    <Col className="tableheader">
                                        Order Details
                                    </Col>
                                </Row>
                                <Row noGap>
                                    <Col className="ledgercolumn">
                                         <Row noGap className='rowwithbottomborder'>
                                           <Col width='40' className="ledgercolumn">
                                                Bill No
                                            </Col>
                                            <Col width='60' className="ledgercolumn">
                                            {invoicedetail.invoicenumber}
                                            </Col>
                                        </Row>
                                        <Row noGap className='rowwithbottomborder'>
                                           <Col width='40' className="ledgercolumn">
                                                Bill Date
                                            </Col>
                                            <Col width='60' className="ledgercolumn">
                                            {invoicedetail.invoicedate}
                                            </Col>
                                        </Row>
                                        <Row noGap className='rowwithbottomborder'>
                                           <Col width='40' className="ledgercolumn">
                                                Bill Month
                                            </Col>
                                            <Col width='60' className="ledgercolumn">
                                            {invoicedetail.invoicemonth} - {invoicedetail.invoiceyear}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row noGap className='rowwithbottomborder'>
                                    <Col className="tableheader">
                                        Item Details
                                    </Col>
                                </Row>
                                <Row noGap className='rowwithbottomborder'>
                                    <Col width='10' className="tableheader">
                                        S.No.
                                    </Col>
                                    <Col width='40' className="tableheader">
                                        Description
                                    </Col>
                                    <Col width='15' className="tableheader">
                                        Qty
                                    </Col>
                                    <Col width='15' className="tableheader">
                                        Days
                                    </Col>
                                    <Col width='20' className="tableheader">
                                        Line Total
                                    </Col>
                                </Row>
                                {
                                    invoicedetail.itemdetails.map((itemdetail)=>{
                                    return(
                                            <Row noGap key={itemdetail.index} className='rowwithbottomborder'>
                                                <Col width='10' className="ledgercolumn">
                                                    {itemdetail.index}
                                                </Col>
                                                <Col width='40' className="ledgercolumn">
                                                    {itemdetail.itemname}
                                                   <br/> <span className='invoicenaration'>{itemdetail.itemnarration}</span>
                                                </Col>
                                                <Col width='15' className="ledgercolumn">
                                                    {itemdetail.quantity}
                                                </Col>
                                                 <Col width='15' className="ledgercolumn">
                                                    {itemdetail.noofdays}
                                                </Col>
                                                <Col width='20' className="ledgercolumn textalignright">
                                                    {itemdetail.linetotal}
                                                </Col>
                                            </Row>
                                        );
                                    })
                                }
                                <Row noGap className='rowwithbottomborder'>
                                    <Col className="tableheader">
                                        Summary
                                    </Col>
                                </Row>
                                <Row noGap className='rowwithbottomborder'>
                                    <Col className="ledgercolumn">
                                        <Row noGap className='rowwithbottomborder'>
                                            <Col width='40' className="ledgercolumn">
                                                Sub Total
                                            </Col>
                                            <Col width='60' className="ledgercolumn">
                                            {invoicedetail.subtotal}
                                            </Col>
                                        </Row>
                                        {(()=>{
                                                if(invoicedetail.conveniencecharge > 0)
                                                {
                                                return(
                                                    <Row noGap className='rowwithbottomborder'>
                                                        <Col width='40' className="ledgercolumn">
                                                            Convenience Charge
                                                        </Col>
                                                        <Col width='60' className="ledgercolumn">
                                                        {invoicedetail.conveniencecharge}
                                                        </Col>
                                                    </Row>
                                                    )
                                                }
                                            })()}
                                            {(()=>{        
                                                if(invoicedetail.servicecharge > 0)
                                                {
                                                   return(
                                                    <Row noGap className='rowwithbottomborder'>
                                                        <Col width='40' className="ledgercolumn">
                                                            Service Charge
                                                        </Col>
                                                        <Col width='60' className="ledgercolumn">
                                                        {invoicedetail.servicecharge}
                                                        </Col>
                                                    </Row>
                                                    )
                                                }
                                            })()}
                                            {(()=>{        
                                                if(invoicedetail.discount > 0)
                                                {
                                                   return(
                                                    <Row noGap className='rowwithbottomborder'>
                                                        <Col width='40' className="ledgercolumn">
                                                            Discount
                                                        </Col>
                                                        <Col width='60' className="ledgercolumn">
                                                        - {invoicedetail.discount}
                                                        </Col>
                                                    </Row>
                                                    )
                                                }
                                        })()}
                                        {(()=>{        
                                                if(invoicedetail.invoicetotal > 0)
                                                {
                                                   return(
                                                    <Row noGap className='rowwithbottomborder'>
                                                        <Col width='40' className="ledgercolumn">
                                                            Invoice Total
                                                        </Col>
                                                        <Col width='60' className="ledgercolumn">
                                                        {invoicedetail.invoicetotal}
                                                        </Col>
                                                    </Row>
                                                    )
                                                }
                                        })()}
                                      {/*}  {(()=>{        
                                                if(invoicedetail.previousbalance !=='' && invoicedetail.previousbalance !=='0'  && invoicedetail.previousbalance !=='0.00')
                                                {
                                                   return(
                                                    <Row noGap className='rowwithbottomborder'>
                                                        <Col width='40' className="ledgercolumn">
                                                            Previous Balance
                                                        </Col>
                                                        <Col width='60' className="ledgercolumn">
                                                        {invoicedetail.previousbalance}
                                                        </Col>
                                                    </Row>
                                                    )
                                                }
                                        })()} {*/}
                                        <Row noGap className='rowwithbottomborder'>
                                            <Col width='40' className="ledgercolumn">
                                                Total Outstanding
                                            </Col>
                                            <Col width='60' className="ledgercolumn ">
                                            {invoicedetail.outstandingbalance}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Fragment>):(<div className="errmsg">No record found</div>)
                        }
                      </Fragment>):null
                    }
                    <br /><br /><br />
                </Block>
            </Page>
      );
    }
}