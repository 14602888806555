import React,{Component, Fragment} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Link,
    Input
  } from 'framework7-react';

  export default class AddAreaManager extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        phone:'',
        password:'',
        arealist:[],
        hasarealist:false,
        droppingpointlist:[],
        hasdroppingpointlist:false,
        status:1,
        remarks:'',
        permissions:[
                    {"id":"cancustomers","name":'Customers',"ischecked":false},
                    {"id":"canpayments","name":'Payments',"ischecked":false},
                    {"id":"caninvoices","name":'Bills',"ischecked":false},
                    {"id":"canreports","name":'Reports',"ischecked":false,reportlist:[
                      {
                        "id":"outstandingreport",
                        "name":'Outstanding (Detail)',
                        "ischecked":true
                      },
                      {
                        "id":"outstandingreportsummary",
                        "name":'Outstanding (Summary)',
                        "ischecked":true
                      },
                      {
                        "id":"circulationreport",
                        "name":'Circulation',
                        "ischecked":true
                      },
                      {
                        "id":"customerlistbynewspaper",
                        "name":'Customer List By Newspaper',
                        "ischecked":true
                      },
                      {
                        "id":"customerlistbymagazine",
                        "name":'Customer List By Magazine',
                        "ischecked":true
                      },
                      {
                        "id":"billstatementbyarealine",
                        "name":'Bill Statement',
                        "ischecked":true
                      },
                      {
                        "id":"billprintingreport",
                        "name":'Bill Printing',
                        "ischecked":true
                      },
                      {
                        "id":"paymentregister",
                        "name":'Payment Register',
                        "ischecked":true
                      },
                      {
                        "id":"customerledgerreport",
                        "name":'Customer Ledger',
                        "ischecked":true
                      },
                      {
                        "id":"billstatementsummary",
                        "name":'Bill & Recovery Summary',
                        "ischecked":true
                      },
                      {
                        "id":"billcollectionsummary",
                        "name":'Bill Collection Summary',
                        "ischecked":true
                      },
                      {
                        "id":"magazinesaledetail",
                        "name":'Magazine Sale Detail',
                        "ischecked":true
                      },
                      {
                        "id":"monthlybillofnewspaper",
                        "name":'Price List',
                        "ischecked":true
                      },
                      {
                        "id":"purchasesummary",
                        "name":'Purchase Summary',
                        "ischecked":true
                      },
                      {
                        "id":"closecustomerlist",
                        "name":'Close Customer List',
                        "ischecked":true
                      },
                      {
                        "id":"restartcustomerlist",
                        "name":'Restart Customer List',
                        "ischecked":true
                      },
                      {
                        "id":"sequence",
                        "name":'Sequence',
                        "ischecked":true
                      },
                      {
                        "id":"smshistory",
                        "name":'SMS History',
                        "ischecked":true
                      }
                    ]},
                    {"id":"canpurchase","name":'Purchase',"ischecked":false},
                    {"id":"cansale","name":'Sale',"ischecked":false},
                    {"id":"canmessages","name":'Messages',"ischecked":false},
                    {"id":"canstock","name":'Stock',"ischecked":false},
                    {"id":"canarea","name":'Area',"ischecked":false},
                    {"id":"candroppingpoint","name":'Dropping Point',"ischecked":false},
                    {"id":"canline","name":'Line',"ischecked":false},
                    {"id":"cansubline","name":'Sub Line',"ischecked":false},
                    {"id":"canlineman","name":'Lineman',"ischecked":false},
                    {"id":"canhawkers","name":'Hawkers',"ischecked":false},
                    {"id":"canholiday","name":'Holiday',"ischecked":false},
                    {"id":"cansubscription","name":'Subscription',"ischecked":false},
                  ],
        hasdob:false,
        dob:'',
        isidproofrequired:false,
        idproofdetail:'',
        imagefile1:'',
        imagefile2:'',
      };

      this.handleUserInput = this.handleUserInput.bind(this);
      this.handlePermissionInput = this.handlePermissionInput.bind(this);
      this.handleAreaInput = this.handleAreaInput.bind(this);
      this.handleDroppingPointInput = this.handleDroppingPointInput.bind(this);
      this.getAllDroppingPoint = this.getAllDroppingPoint.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
        this.getAllArea();
        this.getAllDroppingPoint();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handlePermissionInput(area, permissionid){

      let tempPermList       = [...this.state.permissions];

      if(area === 'permission')
      {
        const tempPerm      = tempPermList.find(perm => perm.id === permissionid);
        tempPerm.ischecked  = !tempPerm.ischecked;
      }
      else
      {
        const tempPermList2 = tempPermList.find(perm => perm.id === 'canreports');

        if(tempPermList2)
        {
          let reportpermissionlist  = [...tempPermList2.reportlist];

          const tempReportPerm      = reportpermissionlist.find(reportperm => reportperm.id === permissionid);
          tempReportPerm.ischecked  = !tempReportPerm.ischecked;
        }
      }

      this.setState(()=>{
        
        return{

          permissions: tempPermList

        }

      });
    }

    handleUserInput(e){

      const name  = e.target.name;
      let value   = e.target.value;

      if(name === 'name')
      {
        value = value.toUpperCase();
      }

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'hasdob')
      {
        this.setState({hasdob:!this.state.hasdob});
      }
      else if(name === 'isidproofrequired')
      {
        this.setState({isidproofrequired:!this.state.isidproofrequired});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    handleAreaInput(areaid){

        let temparealist       = [...this.state.arealist];
        const temparealist2    = temparealist.find(area => Number(area.id) === Number(areaid));

        temparealist2.isassigned   = !temparealist2.isassigned;

        this.setState(()=>{
          
          return{

            arealist: temparealist

          }

        });
    }

    handleDroppingPointInput(droppingpointid){

      let tempdroppingpointlist       = [...this.state.droppingpointlist];
      const tempdroppingpointlist2    = tempdroppingpointlist.find(droppingpoint => Number(droppingpoint.id) === Number(droppingpointid));

      tempdroppingpointlist2.isassigned   = !tempdroppingpointlist2.isassigned;

      this.setState(()=>{
        
        return{

          droppingpointlist: tempdroppingpointlist

        }

      });
    }

    getAllArea(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {          
          return;
        }
  
        const bodyFormData = {
          Mode:'GetAreaByAreaManager',
          clientid:this.state.clientdetail.id,
          recordid:'',
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }
  
        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/areamanager.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  arealist:resdata.recordlist,
                  hasarealist:true
                });
              }
              else{
                this.setState({
                  arealist:[],
                  hasarealist:false
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);
    }
    
    getAllDroppingPoint(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {        
        return;
      }

      const bodyFormData = {
        Mode:'GetDroppingPointByAreaManager',
        clientid:this.state.clientdetail.id,
        recordid:'',
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/areamanager.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();
            
            // Hide Pull To Refresh
            app.ptr.done();
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                droppingpointlist:resdata.recordlist,
                hasdroppingpointlist:true
              });
            }
            else{
              this.setState({
                droppingpointlist:[],
                hasdroppingpointlist:false
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
    }

    saveDetail(){

      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;
      let errmsg  = "";

      app.input.validateInputs('.adddata');

      if(this.state.name === "" && errmsg === "")
      {
        errmsg  = "Please enter name";
      }
      if(this.state.phone === "" && errmsg === "")
      {
        errmsg  = "Please enter phone";
      }
      if(this.state.password === "" && errmsg === "")
      {
        errmsg  = "Please enter password";
      }

      let hasarea   = false;

      this.state.arealist.forEach(area => {

        if(Number(area.isassigned) === 1)
        {
          hasarea  = true;
        }

      });

      if(hasarea !== true && errmsg === "")
      {
        errmsg  = "Please assign an area";
      }

      let hasdroppingpoint = false;

      this.state.droppingpointlist.forEach(droppingpoint => {

        if(Number(droppingpoint.isassigned) === 1)
        {
          hasdroppingpoint  = true;
        }

      });

      if(hasdroppingpoint !== true && errmsg === "")
      {
        errmsg  = "Please assign a dropping point";
      }

      let ispermissioned  = false;
      let hasreport       = false;

      this.state.permissions.forEach(perm => {

        if(Number(perm.ischecked) === 1)
        {
          ispermissioned  = true;
        }

        if(Number(perm.ischecked) === 1 && perm.id === 'canreports')
        {
          perm.reportlist.forEach(report => {

            if(Number(report.ischecked) === 1)
            {
              hasreport  = true;
            }

          });
        }

      });

      if(!ispermissioned && errmsg === "")
      {
        errmsg = "Please select atleast one permission";
      }

      if(hasreport !== true && this.state.permissions[3].ischecked === true && errmsg === "")
      {
        errmsg  = "Please assign a report";
      }

      if((this.state.hasdob === true && this.state.dob === "") && errmsg === "")
      {
        errmsg  = "Please select date of birth";
      }

      let filedata1 = "";
      let filedata2 = "";

      if(Number(this.state.isidproofrequired))
      {
        filedata1     = document.getElementById('imagefile1').files[0];
        filedata2     = document.getElementById('imagefile2').files[0];  
      }

      /*if(filedata !== "" && filedata !==undefined  && errmsg==='')
      { 
        let fileext = filedata.name.split('.').pop().toLowerCase();
        if(fileext !=='jpg' && fileext !=='jpeg' && fileext !=='png' && fileext !=='bmp')
        {
          errmsg = "Please attach a valid file";
        }
      }*/

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const formData = new FormData();

      formData.append('Mode','AddAreaManager');
      formData.append('clientid',self.state.clientdetail.id);
      formData.append('name',self.state.name);
      formData.append('phone',self.state.phone);
      formData.append('password',self.state.password);
      formData.append('arealist',JSON.stringify(self.state.arealist));
      formData.append('droppingpointlist',JSON.stringify(self.state.droppingpointlist));
      formData.append('remarks',self.state.remarks);
      formData.append('permissions',JSON.stringify(self.state.permissions));
      formData.append('status',Number(self.state.status));
      formData.append('hasdob',Number(self.state.hasdob));
      formData.append('dob',self.state.dob);
      formData.append('isidproofrequired',Number(self.state.isidproofrequired));
      formData.append('idproofdetail',self.state.idproofdetail);
      formData.append('imagefile1',filedata1);
      formData.append('imagefile2',filedata2);

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        const accesstoken = localStorage.getItem('agency_accesstoken');
        
        app.request({
          url: `${app.passedParams.serverurl}api/areamanager.php?t=${Date.now()}`,
          method: 'POST', 
          data: formData,
          crossDomain: true,
          cache: false, 
          dataType: 'application/json',
          contentType: 'multipart/form-data',
          processData: true,
          headers: {
              Authorization: `${accesstoken}`
          },
          success: (data)=>{

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();
  
              const resdata = JSON.parse(data);

              if(resdata.success === true)
              {
                Promise.all([
                    this.setState(()=>{
                      return{
                        name:'',
                        phone:'',
                        password:'',
                        status:1,
                        remarks:'',
                        hasdob:false,
                        dob:'',
                        isidproofrequired:false,
                        idproofdetail:'',
                      }
                    })
                ])
                .then(() => {
    
                    $$('.item-content').removeClass('item-input-with-error-message');
                    $$('.item-content').removeClass('item-input-invalid');
                    document.getElementById('imagefile1').value='';
                    document.getElementById('imagefile2').value='';
                });

                this.$f7.views.main.router.navigate(`thanks/addmanagersuccess/`);
              }
              else
              {
                const toastWithButton = app.toast.create({
                  text: resdata.toastmsg,
                  closeButton: true,
                  closeTimeout: 3000,
                });

                toastWithButton.open();
              }
          }
        });

      }, 1000);

    }

    render(){

      const {arealist, hasarealist,droppingpointlist,hasdroppingpointlist, permissions, hasdob, dob, isidproofrequired}  = this.state;
       
      return(
            <Page pageContent={false} className="page" name="AddAreaManager">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Add Area Manager</NavTitle>
                    <NavRight className="right-with-menu">
                    </NavRight>
                  </Navbar>
                  <PageContent>
                    <Block>
                    <List noHairlinesMd style={{marginBottom:'5px'}}>
                        <ListInput
                            name="name"
                            className="adddata"
                            label="Name"
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="Name"
                            value={this.state.name}
                            maxlength="20"
                            clearButton required validate
                        >
                        </ListInput>
                        <ListInput
                            name="phone"
                            className="adddata"
                            label="Phone Number"
                            floatingLabel
                            type="tel"
                            onInput={(e)=>{
                            
                              let tempphone = e.target.value;
                              let mobile    = tempphone.replace(/ /g,'');
                              
                              if(tempphone.slice(0,1)=='+')
                              {
                                mobile = mobile.substring(3);
                              }
  
                              if(tempphone.slice(0,1)=='0')
                              {
                                mobile = mobile.substring(1);
                              }
  
                              if(mobile.length > 10)
                              {
                                return;
                              }
                              
                              this.setState({
                                phone:mobile
                              })
                            }}
                            placeholder="Phone number"
                            value={this.state.phone}
                            clearButton required validate
                        >
                        </ListInput>
                        <ListInput
                            name="password"
                            className="adddata"
                            label="Password"
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="Your password"
                            value={this.state.password}
                            clearButton required validate
                        >
                        </ListInput>
                        </List>
                        {
                          hasarealist ? (<Fragment>
                          <Block><b>Assign Area</b></Block>
                          <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                          {
                            arealist.map((area, arealoop)=>{
                              return(
                                <ListItem key={arealoop} checkbox title={area.name} name={`area_${area.id}`} checked={Number(area.isassigned) === 1} onChange={()=>{this.handleAreaInput(area.id)}}></ListItem>
                              )
                            })
                          }  
                          </List>
                          </Fragment>):null
                        }
                        {
                          hasdroppingpointlist ? (<Fragment>
                          <Block><b>Assign Droping Point</b></Block>
                          <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                          {
                            droppingpointlist.map((droppingpoint, droppingpointloop)=>{
                              return(
                                <ListItem key={droppingpointloop} checkbox title={droppingpoint.name} name={`area_${droppingpoint.id}`} checked={Number(droppingpoint.isassigned) === 1} onChange={()=>{this.handleDroppingPointInput(droppingpoint.id)}}></ListItem>
                              )
                            })
                          }  
                          </List>
                          </Fragment>):null
                        }
                        <List noHairlinesMd style={{marginTop:'5px'}}>
                        <ListInput
                            name="remarks"
                            label="Remarks"
                            floatingLabel
                            type="textarea"
                            onInput={this.handleUserInput}
                            value={this.state.remarks}
                            resizable
                            placeholder="Remarks"
                        >
                        </ListInput>
                      </List>

                      <Block><b>Permissions</b></Block>

                      <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                        {
                          permissions.map((perm, permloop)=>{
                              return (
                                <Fragment key={permloop}>
                                <ListItem checkbox title={perm.name} name="permissionsarr[]" checked={perm.ischecked} onChange={()=>{this.handlePermissionInput('permission', perm.id)}}  id={`perm`+ perm.id}>
                                </ListItem>
                                {
                                  perm.ischecked && perm.id === 'canreports' ? (
                                    <List noHairlines style={{margin:0,padding:0,listStyle:'none',paddingLeft:'35px'}}>
                                    {
                                      perm.reportlist.map((reportperm, reportpermloop)=>{
                                        return(
                                          <ListItem key={`${permloop}-${reportpermloop}`} checkbox title={reportperm.name} name="reportpermissionsarr[]" checked={reportperm.ischecked} onChange={()=>{this.handlePermissionInput('reportpermission', reportperm.id)}}  id={`reportperm${reportperm.id}`}></ListItem>
                                        )
                                      })
                                    }
                                    </List>
                                  ):null
                                }
                                </Fragment>
                              );
                          })
                        }
                        </List>
                        <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                        <ListItem title="Status">
                          <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                        
                        <ListItem title="Has Birth Date?">
                          <Toggle slot="after" name="hasdob" checked={Number(hasdob) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                        {
                          hasdob ? (
                            <ListInput
                                name="dob"
                                id="calendar-dob-input"
                                label="Date Of Birth"
                                placeholder="Date Of Birth"
                                type="date"
                                value={dob}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                          ):null
                        }
                        <ListItem title="ID Proof Required?">
                          <Toggle slot="after" name="isidproofrequired" checked={Number(isidproofrequired) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                        {
                          Number(isidproofrequired) ? (
                            <ListInput
                              name="idproofdetail"
                              label="ID Proof Detail"
                              floatingLabel
                              type="textarea"
                              onInput={this.handleUserInput}
                              value={this.state.idproofdetail}
                              resizable
                              placeholder="ID Proof Detail"
                            >
                            </ListInput>
                          ):null
                        }
                      </List>
                      {
                        Number(isidproofrequired) ? (
                          <Block><b>ID Proof Attachment</b></Block>
                        ):null
                      }
                      {
                        Number(isidproofrequired) ? (
                          <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                            <ListItem>
                              <span style={{marginRight:'5px',minWidth:'100px'}}>Attachment 1</span><br/>
                              <Input type="file" name="imagefile1" inputId="imagefile1" onChange={this.handleUserInput}/>
                            </ListItem>
                            <ListItem>
                              <span style={{marginRight:'5px',minWidth:'100px'}}>Attachment 2</span><br/>
                              <Input type="file" name="imagefile2" inputId="imagefile2" onChange={this.handleUserInput}/>
                            </ListItem>
                          </List>
                        ):null
                      }
                      <br /><br />
                </Block>
              </PageContent>
              {/*</PageContent>*/}
              <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
            </Page>
      );
    }
}