import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppCardItemLoading from '../pages/appcarditemloading';
import AppFav from '../pages/appfav';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    BlockTitle,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
  } from 'framework7-react';

  export default class ManageAreaManager extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        isavailable:false,
        loaded:false,
        areamanagerlist:[],
        actionGridOpened: false,
        oneGroupOpened: false,
        deleterecordid:'',
        isptr:false,
        effect:'blink',
        totalrecord:0,
      };

      this.getAllAreaManager  = this.getAllAreaManager.bind(this);
      this.navigateToLink     = this.navigateToLink.bind(this);
    }

    componentDidMount(){
  
     let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.hideSplashScreen();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          setTimeout(()=> {

            this.getAllAreaManager();
            
          }, 200)

        })
        
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'DeleteAreaManager',
        clientid:this.state.clientdetail.id,
        recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/areamanager.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              let tempAreaManages = [...this.state.areamanagerlist];
              tempAreaManages     = tempAreaManages.filter(manager => manager.id !== this.state.deleterecordid);
        
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                    areamanagerlist:tempAreaManages,
                    totalrecord:Number(this.state.totalrecord) - 1
                  }
                },()=>{
                  if(this.state.areamanagerlist.length < 1)
                  {
                    this.setState(()=>{
                      return {
                        isavailable:false
                      }
                    });
                  }
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    getAllAreaManager(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }

        this.setState({
          loaded:false
        });
  
        const bodyFormData = {
            Mode:'GetAllAreaManager',
            clientid:this.state.clientdetail.id,
        };

        /*if(this.state.isptr !== true)
        {
          app.preloader.show();
        }*/
  
        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/areamanager.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  loaded:true,
                  isavailable:true,
                  areamanagerlist:resdata.recordlist,
                  isptr:false,
                  totalrecord:resdata.totalrecord
                });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  areamanagerlist:[],
                  isptr:false,
                  totalrecord:0
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);
    }

    navigateToLink(link){
        
        if(this.$f7.online)
        {
          this.$f7router.navigate(`/${link}/`);
        }

    }

    render(){

       const{isavailable, loaded, areamanagerlist, totalrecord} = this.state;

      return(
            <Page pageContent={false} className="page" name="ManageAreaManager">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Area Manager</NavTitle>
                    <NavRight className="right-with-menu">
                    <Link onClick={()=>{

                      this.getAllAreaManager();

                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                    </NavRight>
                  </Navbar>
                  <PageContent>
                  {
                    loaded ? (<Fragment>
                      {
                        isavailable ? (
                          <Fragment>
                            {
                              isavailable ? (<BlockTitle className="totalrec">Total Area Manager : {totalrecord}</BlockTitle>):null
                            }
                            {
                              areamanagerlist.map((area)=>{
                                  return(
                                    <Card outline key={area.id} className="recordset">
                                      <CardHeader
                                      className="date">{area.addeddate}</CardHeader>
                                      <CardContent>
                                        <p>{area.name}</p>
                                        {area.phone ? (<div><b>Phone : </b>{area.phone}
                                          &nbsp;
                                          {
                                            area.phone !== "---" ? (
                                              <Link external href={`tel:${area.phone}`} target="_system">
                                                <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                              </Link>
                                            ):null
                                          }
                                        </div>):null}
                                        {area.dobtext ? (<p><b>Date Of Birth :</b> {area.dobtext}</p>):null}
                                      </CardContent>
                                      <CardFooter>
                                        <Link onClick={()=>{this.navigateToLink(`editareamanager/${area.id}`)}} ignoreCache={true}>Edit</Link>
                                        {
                                          !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                            <Link onClick={() =>{
                                              if(this.$f7.online)
                                              {
                                                this.setState(()=>{
                                                  return {
                                                    deleterecordid:area.id
                                                  }
                                                },()=>{
                                                  this.refs.actionsOneGroup.open()
                                                })
                                              }
                                            }}>Delete</Link>
                                          ):(<div>&nbsp;</div>)
                                        }
                                      </CardFooter>
                                    </Card>
                                  );
                                })
                            }
                          </Fragment>
                        ):(<AppItemNotFound notfoundtext ="No Area Manager record found." />)
                      }
                    </Fragment>):(<AppCardItemLoading />)
                  }
                <br /><br />
              </PageContent>
              <Actions ref="actionsOneGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteRecord()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <AppFav position="center-bottom" text="Add Manager" icon="plus" url="addareamanager" />
            </Page>
      );
    }
}