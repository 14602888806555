import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import { FilterConsumer, FilterContext } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    BlockTitle,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Toggle,
    Link,
    Toolbar,
  } from 'framework7-react';

  export default class QuickAddSubline extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        phone:'',
        status:1,
        remarks:'',
        lineid:'',
        linelist:[],
        colwidth:100,
        ismount:false,
        linename:'Select',
        issmartline:false,
        hassmartline:false,
        frmsuccess:false,
        resid:'',
        resname:'',
      };

      this.handleUserInput    = this.handleUserInput.bind(this);
      this.fetchNewLine       = this.fetchNewLine.bind(this);      
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        if(Number(this.state.clientdetail.islineman) === 1 || Number(this.state.clientdetail.ismanager) === 1)
        {
          this.setState({colwidth:100});
        }
        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.hideSplashScreen();
        this.lineAutocompletePopup();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteLinePopup.destroy();

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              clientid:self.state.clientdetail.id,
              areaid:'',
              type:'subline',
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-line-name2').text(value[0].name);
            $('.autocomplete-line-popup').removeClass('error');
            
            self.setState({
              lineid:value[0].id,
              linename:value[0].name
            });

          },
        },
      });
    }

    fetchNewLine(newlineid, newline){

      this.setState(()=>{

        return{

          lineid:newlineid,
          linename:newline

        }

      },()=>{

        /*this.Dom7('#autocomplete-line-name2').text(newline);
        this.getLine();*/

      })

    }

    getLine(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
          Mode:'GetLine',
          clientid:clientdetail.id,
          areaid:'',
          type:'subline',
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                linelist:resdata.recordlist,
                isptr:false
              },()=>{
                if(this.state.hassmartline === false)
                {
                  this.setState({hassmartline:true});
                  this.lineAutocompletePopup();
                }
              });
            }
            else
            {
              this.setState({
                linelist:[],
                isptr:false
              },()=>{
                
                if(this.state.hassmartline === false)
                {
                  this.setState({hassmartline:true});
                  this.lineAutocompletePopup();
                }
                
              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;

      let errmsg    = "";

      if(!this.$f7.online)
      {
        return;
      }

      app.input.validateInputs('.adddata');

      if((this.state.lineid === "" || this.state.lineid === undefined) && errmsg === "")
      {
        errmsg  = "Please select Line";
      }

      if(this.state.lineid === "" || this.state.lineid === undefined)
      {
        this.$$('.autocomplete-line-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-line-popup').removeClass('error');
      }

      if(this.state.name === "" && errmsg === "")
      {
        errmsg  = "Please enter name";
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
          Mode:'AddSubLine',
          clientid:self.state.clientdetail.id,
          name:self.state.name,
          phone:self.state.phone,
          status:Number(self.state.status),
          remarks:self.state.remarks,
          lineid:self.state.lineid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/subline.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();

            if(resdata.success === true)
            {
              Promise.all([
                this.setState(()=>{
                  return{
                    name:'',
                    phone:'',
                    status:1,
                    remarks:'',
                    frmsuccess:true,
                    resid:resdata.recordid,
                    resname:resdata.name,
                  }
                })
              ])
              .then(() => {
  
                $$('.item-content').removeClass('item-input-with-error-message');
                $$('.item-content').removeClass('item-input-invalid');
  
              })
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

      const{colwidth, quickareaid, quickareaname, resid, resname, frmsuccess, clientdetail, isquicksmartarea, ismount, lineid, linename} = this.state;

      return(
        <FilterConsumer>{(value)=>{

            const{setSubLineData, setLineData, singleline}  = value;

            if(ismount === true)
            {

              if(frmsuccess === true)
              {
                Promise.all([
                    setTimeout(()=> {

                        setLineData(Number(lineid), linename, false);
                        setSubLineData(Number(resid), resname);
            
                    }, 500)
                    
                    ])
                    .then(() => {

                    setTimeout(()=> {

                        this.$$('.popup-close').trigger('click');
            
                    }, 500)
                
                })

              }
              else
              {
                setTimeout(()=>{
  
                  if((singleline.id !== lineid) && singleline.id > 0)
                  {
                    this.fetchNewLine(singleline.id, singleline.name);
                  }
      
                }, 500);
              }
              
            }

          return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                    <NavLeft>
                      <Link popupClose>
                        <i className="icon icon-back"></i>                      
                      </Link>
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </NavLeft>
                    <NavTitle>Add New Sub Line</NavTitle>
                  </Navbar>
                  <Page pageContent={false} className="page" name="QuickAddline">
                    <PageContent>
                    <Block>
                        <Row>
                            <Col width={colwidth}>
                            <List noHairlinesMd className="zeromargin">
                            <ListItem link="#" id="autocomplete-line-popup" className="autocomplete-line-popup" title="Line">
                                <div slot="after" id="autocomplete-line-name2">{linename}</div>
                            </ListItem>
                            </List>
                            </Col>
                        </Row>
                        <List noHairlinesMd style={{marginBottom:'5px'}}>
                        
                            <ListInput
                                name="name"
                                className="adddata"
                                label="Name"
                                floatingLabel
                                type="text"
                                onInput={this.handleUserInput}
                                placeholder="Name"
                                value={this.state.name}
                                clearButton required validate
                            >
                            </ListInput>
                        
                            {/*<ListInput
                                name="phone"
                                label="Phone Number"
                                floatingLabel
                                type="tel"
                                onInput={(e)=>{
                            
                                  let tempphone = e.target.value;
                                  let mobile    = tempphone.replace(/ /g,'');
                                  
                                  if(tempphone.slice(0,1)=='+')
                                  {
                                    mobile = mobile.substring(3);
                                  }
          
                                  if(tempphone.slice(0,1)=='0')
                                  {
                                    mobile = mobile.substring(1);
                                  }
          
                                  if(mobile.length > 10)
                                  {
                                    return;
                                  }
                                  
                                  this.setState({
                                    phone:mobile
                                  })
                                }}
                                placeholder="Phone number"
                                value={this.state.phone}
                                clearButton validate
                            >
                            </ListInput>*/}

                            <ListInput
                                name="remarks"
                                label="Remarks"
                                floatingLabel
                                type="textarea"
                                onInput={this.handleUserInput}
                                value={this.state.remarks}
                                resizable
                                placeholder="Remarks"
                            >
                            </ListInput>

                            <ListItem title="Status">
                            <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                            </ListItem>

                        </List>
                    </Block>
                    <br /><br />
                    </PageContent>
                    <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}