import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Block,
    Link,
    BlockTitle,
    List,
    ListItem,
    Toggle,
    Icon,
  } from 'framework7-react';

  export default class ManageInventory extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        inventorylist:[],
        isptr:false,
        isrequestdone:false,
        effect:'blink',
        totalrecord:'',
      };
      
      this.getDetail  = this.getDetail.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getDetail();
        this.hideSplashScreen();
        
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }

        this.setState({
          isrequestdone:false
        });

        const bodyFormData = {
            Mode:'GetAvailableInventory',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            pincode:self.state.clientdetail.pincode,
        };

        /*if(this.state.isptr !== true)
        {
          app.preloader.show();
        }*/

        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {
            
              // Simulate Ajax Request
            
              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();
  
              const resdata = JSON.parse(res.data);
  
              if(resdata.success === true)
              {
                this.setState({
  
                  inventorylist:resdata.inventorylist,
                  totalrecord:resdata.totalrecord,
                  isptr:false,
                  isrequestdone:true,
  
                },()=>{

                  this.Dom7('.inventorywrapper').addClass('animate-bottom');

                });
              }
              else
              {
                this.setState({
  
                  isptr:false,
                  isrequestdone:true,
                  totalrecord:0,
  
                },()=>{

                  this.Dom7('.inventorywrapper').addClass('animate-bottom');

                });
              }
  
          }).catch(e => {
              console.log(e);
          });

        }, 500);

    }

    assignInventory(id,isassigned,price,categoryid,stockname,isconfirm){
      
      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
          Mode:'AssignInventory',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          inventoryid:id,
          isassigned:isassigned,
          price:price,
          categoryid:categoryid,
          isconfirm:isconfirm,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);

            if(resdata.success !== true)
            {
              if(resdata.showconfirm)
              {
                app.actions.create({
                  buttons: [
                  // First group
                  [
                    {
                      text: `${stockname} has ${resdata.subcount} active customers? Do you want to remove their subscription and make it inactive?`,
                      label: true
                    },
                    {
                    text: 'Yes',
                    bold: true,
                    color:'blue',
                    onClick:()=>{
            
                        if(this.$f7.online)
                        {
                          this.assignInventory(id,isassigned,price,categoryid,stockname,1);
                        }
                      }
                    }
                  ],
                  // Second group
                  [
                    {
                      text: 'No',
                      color: 'red',
                      onClick:()=>{
              
                          if(this.$f7.online)
                          {
                            this.getDetail();
                          }
                        }
                    }
                  ]
                  ]
                }).open();
              }
              else
              {
                const toastWithButton = app.toast.create({
                  text: resdata.msg,
                  closeButton: true,
                  closeTimeout: 3000,
                });
      
                toastWithButton.open();
              }
            }
            else
            {
              this.getDetail();
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);
    }

    render(){

       const{inventorylist, isrequestdone, totalrecord} = this.state;

      return(
            <Page name="Inventory">
              <Navbar>
                <NavLeft>
                  <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                  <Link href={`/dashboard/`} className="clearmenupadding">
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                  </Link>
                </NavLeft>
                <NavTitle>Stock</NavTitle>
                <NavRight className="right-with-menu">
                <Link onClick={()=>{

                  this.getDetail();

                }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                </NavRight>
              </Navbar>
              <Block>
              {
                isrequestdone ? (<BlockTitle className="totalrec">Total Stock : {totalrecord}</BlockTitle>):null
              }
              {
                isrequestdone ? (<div className="inventorywrapper">
                  {
                    inventorylist.map((category)=>{
                      return(
                        <Fragment key={category.id}>
                            <BlockTitle medium>{category.title}</BlockTitle>
                             {
                              category.frequencylist.map((frequency)=>{
                                return(
                                  <Fragment key={frequency.name}>
                                  {category.showfrequencyname ? (<BlockTitle medium style={{textAlign:'left',fontSize:'18px',textDecoration:'underline',fontWeight:'bold'}}>{frequency.name}</BlockTitle>):null}
                                  <List>
                                  {frequency.recordlist.map((inventory)=>{
                                  return(
                                      <ListItem title={inventory.name} key={inventory.id}>
                                      {
                                        inventory.isassigned ? (
                                        <Toggle slot="after" defaultChecked value={inventory.isassigned} onChange={()=>{
                                          if(this.$f7.online)
                                          {
                                            this.assignInventory(inventory.id,inventory.isassigned,inventory.price,category.id,inventory.name,0);
                                          }
                                        }}></Toggle>
                                        ):(
                                        <Toggle slot="after" value={inventory.isassigned} onChange={()=>{
                                          if(this.$f7.online)
                                          {
                                            this.assignInventory(inventory.id,inventory.isassigned,inventory.price,category.id,inventory.name,0);
                                          }
                                        }}></Toggle>
                                        )
                                    }
                                    </ListItem>
                                    
                                    );
                                  
                                })}</List>
                                 </Fragment>
                               );
                              })     
                            }
                        </Fragment>
                        );
                    })
                }
                </div>):(<AppListItemLoading />)
              }
              </Block>
            </Page>

      );
    }
}