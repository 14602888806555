import React,{Fragment, Component} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    BlockTitle,
    Link,
    Block,
    Row,
    Col,
    Icon,
    Page,
    PageContent,
  } from 'framework7-react';

  export default class MonthlyBillOfNewspaperDetail extends Component {
    constructor(props) {
      super(props);

      this.state = {
        clientdetail:[],
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        totalrecord:'',
        paymentdate:'',
        recordset:[],
        filtervars:[],
        hasdataavailable:false,
      };

      this.getDetail = this.getDetail.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{

        this.getDetail();

        this.hideSplashScreen();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }
    
    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;

        let{inventoryid, catid, startdate} = this.state.filtervars;

        if(!this.$f7.online)
        {
          return;
        }
    
        const bodyFormData = {
          Mode:'GetMonthlyBillOfNewspaperDetail',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          startdate:startdate,
          catid:catid,
          inventoryid:inventoryid,
        };

        this.setState(()=>{

          return{
            isfetched:false
          }
          
        },()=>{

          setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  isfetched:true,
                  inventorylist:resdata.inventorylist,
                  hasdataavailable:true,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });

              }
              else
              {
                this.setState({
                  isfetched:true,
                  inventorylist:[],
                  hasdataavailable:false,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        },500);

      });
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){

        const{inventorylist, isfetched} = this.state;

          return(
            <Popup className="demo-popup">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link popupClose>
                      <i className="icon icon-back"></i>                      
                    </Link>
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                  </NavLeft>
                  <NavTitle>Detail</NavTitle>
                  <NavRight className="right-with-menu">
                    <Link onClick={()=>{
            
                        this.getDetail();
            
                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                <Page className="page" name="MonthlyBillOfNewspaperDetail">
                {
                  isfetched? (<div>{

                          <Fragment key={inventorylist.id}>
                            <BlockTitle medium>{inventorylist.name}</BlockTitle>
                            <div className="data-table">
                              <table>
                                <thead>
                                  <tr>
                                    <th className="label-cell">S.No.</th>
                                    <th className="numeric-cell">Date</th>
                                    <th className="numeric-cell">Date Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {
                                  inventorylist.datepricing.map((dateprice)=>{

                                  return(
                                      <tr key={dateprice.date}>
                                        <td className="label-cell">{dateprice.date}</td>
                                        <td className="numeric-cell">{dateprice.displayname}</td>
                                        <td className="numeric-cell">
                                        {
                                            dateprice.hasholiday ? (<div style={{width:'100px',color:'#ff0000'}}>Holiday</div>):(dateprice.dateprice)
                                        }
                                        </td>
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                              </table>
                            </div>                              

                          </Fragment>


                  }</div>):(<AppListItemLoading />)
                }
                </Page>
            </Popup>
          );

    }
}