import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    BlockTitle,
    Button,
    Fab,
    Icon,
    Toolbar,
    Link
  } from 'framework7-react';

  export default class EditHoliday extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        holidaytype:0,
        customerlist:[],
        customerid:'',
        customername:'Select',
        inventorytype:0,
        inventorylist:[],
        inventoryid:'',
        inventoryname:'Select',
        startdate:'',
        enddate:'',
        reason:'',
      };

      this.handleUserInput = this.handleUserInput.bind(this);
      this.customerAutocompletePopup  = this.customerAutocompletePopup.bind(this);
      this.inventoryAutocompletePopup  = this.inventoryAutocompletePopup.bind(this);
  }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getDetail();
        this.hideSplashScreen();

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteCustomerPopup.destroy();
      self.autocompleteInventoryPopup.destroy();

    }

    customerAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchlinelist  = this.state.customerlist;
      self.autocompleteCustomerPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-customer-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectcustomer/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinelist.length; i += 1) {
            if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchlinelist[i].phone.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchlinelist[i].customerid.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            $('#autocomplete-customer-popup').find('.item-title').text(value[0].name);
            $('#autocomplete-customer-popup').find('.item-content').removeClass('error');
            
            /*$('#autocomplete-line-popup').find('input').val(value[0].id);*/

            self.setState(()=>{
              return{
                customerid:value[0].id,
                customername:value[0].name,
              }
            },()=>{
              //self.getInventoryDetail();
            })

          },
        },
      });
    }

    inventoryAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchlinelist  = this.state.inventorylist;

      self.autocompleteInventoryPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-inventory-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectinventory/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinelist.length; i += 1) {
            if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchlinelist[i].phone.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchlinelist[i].inventoryid.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            $('#autocomplete-inventory-popup').find('.item-title').text(value[0].name);
            $('#autocomplete-inventory-popup').find('.item-content').removeClass('error');
            
            /*$('#autocomplete-line-popup').find('input').val(value[0].id);*/

            self.setState(()=>{
              return{
                inventoryid:value[0].id,
                inventoryname:value[0].name,
              }
            },()=>{
              //self.getInventoryDetail();
            })

          },
        },
      });
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{
        if(name === 'holidaytype')
        {
          if(value > 0)
          {
            this.customerAutocompletePopup();
          }
          else
          {
            this.inventoryAutocompletePopup();
          }
        }
        if(name === 'inventorytype' && value > 0)
        {
            this.inventoryAutocompletePopup();
        }
      });
    }

    handleCalendarChange(name,value){

        this.setState(()=>{
            return{
              [name]: value
            }
        });
    }

    getAllCustomers(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetAllSubscribeCustomer',
            clientid:self.state.clientdetail.id,
            loginlinemanid:Number(self.state.clientdetail.linemanid),
            ismanager:Number(this.state.clientdetail.ismanager),
            areamanagerid:Number(this.state.clientdetail.areamanagerid),
            areaids:this.state.clientdetail.areaids,
        };

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
                this.setState(()=>{
                    return{
                        customerlist:resdata.recordlist,
                        hascustomer:true,
                        isptr:false
                    }
                });
            }
            else
            {
              this.setState(()=>{
                  return{
                      hascustomer:false,
                      isptr:false
                  }
              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    getAllInventory(){

        const self      = this;
        const app       = self.$f7;
  
        const bodyFormData = {
            Mode:'GetAllInventory',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
        };

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            const resdata = JSON.parse(res.data);

            if(resdata.success === true)
            {
                this.setState(()=>{
                    return{
                        inventorylist:resdata.inventorylist,
                        hasinventory:true,
                    }
                });
            }
            else
            {
                this.setState(()=>{
                    return{
                        hasinventory:false,
                    }
                });
            }

        }).catch(e => {
            console.log(e);
        });
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(!this.$f7.online)
        {
          return;
        }

        const bodyFormData = {
            Mode:'GetHolidayDetail',
            clientid:self.state.clientdetail.id,
            stateid:self.state.clientdetail.stateid,
            cityid:self.state.clientdetail.cityid,
            recordid:self.props.f7route.params.id,
        };

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/holiday.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();
  
            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState(()=>{
                  return{
                    holidaytype:resdata.detail.holidaytype,
                    customerid:resdata.detail.customerid,
                    inventorytype:resdata.detail.inventorytype,
                    inventoryid:resdata.detail.inventoryid,
                    startdate:resdata.detail.startdate,
                    enddate:resdata.detail.enddate,
                    reason:resdata.detail.reason,
                    customerlist:resdata.customerlist,
                    customername:resdata.customername,
                    inventoryname:resdata.inventoryname,
                    inventorylist:resdata.inventorylist,
                    isptr:false

                  }
              },()=>{
                    if(this.state.holidaytype > 0)
                    {
                      this.customerAutocompletePopup()
                    }
                    if(this.state.inventorytype > 0)
                    {
                      this.inventoryAutocompletePopup()
                    }
                });
          
              /*app.calendar.update({
                calendarParams:{closeOnSelect:true,defaultValue:[startdate],dateFormat: 'mm/dd/yyyy'},
              });*/

            }
            else
            {
              this.setState(()=>{
                return{

                  isptr:false

                }
              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    saveDetail(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(!this.$f7.online)
      {
        return;
      }

      let errmsg    = "";

      app.input.validateInputs('.adddata');

      if((Number(this.state.holidaytype) > 0 && this.state.customerid === ""))
      {
        this.$$('#autocomplete-customer-popup').find('.item-content').addClass('error');
        errmsg  = "Please select a customer.";
      }
      else
      {
        this.$$('#autocomplete-customer-popup').find('.item-content').removeClass('error');
      }
      if((Number(this.state.inventorytype) > 0 && this.state.inventoryid === "") && Number(this.state.holidaytype) > 0 && errmsg == '')
      {
        this.$$('#autocomplete-inventory-popup').find('.item-content').addClass('error');
        errmsg  = "Please select an inventory.";
      }
      else
      {
        this.$$('#autocomplete-inventory-popup').find('.item-content').removeClass('error');
      }
      if((this.state.startdate === "" || this.state.enddate === "") && errmsg == "")
      {
        errmsg  = "Start Date and End Date is required.";
      }

      if(errmsg != "")
      {
        const toastWithButton = app.toast.create({
            text: errmsg,
            closeButton: true,
            closeTimeout: 3000,
        });

        toastWithButton.open();
        return false;
      }

      /*let startdate       = new Date(this.state.startdate);
      if(this.state.newstartdate != "")
      {
        startdate       = new Date(this.state.newstartdate);
      }
      const tempStartDate   = startdate.getMonth()+"/"+startdate.getDate()+"/"+startdate.getFullYear();

      let enddate       = new Date(this.state.enddate);
      if(this.state.newenddate != "")
      {
        enddate       = new Date(this.state.newenddate);
      }
      const tempEndDate   = enddate.getMonth()+"/"+enddate.getDate()+"/"+enddate.getFullYear();
      */

      const bodyFormData = {
          Mode:'EditHoliday',
          ClientID:self.state.clientdetail.id,
          CustomerType:self.state.holidaytype,
          CustomerID:self.state.customerid,
          InventoryType:self.state.inventorytype,
          InventoryID:self.state.inventoryid,
          StartDate:self.state.startdate,
          EndDate:self.state.enddate,
          Reason:self.state.reason,
          id:self.props.f7route.params.id,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/holiday.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
  
            if(resdata.success === true)
            {
                router.navigate('/holiday/');             
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

        const{holidaytype, customerid, customerlist,customername, inventorytype,inventoryname, inventoryid, inventorylist, startdate, enddate, reason} = this.state;

      return(
            <Page pageContent={false} className="page" name="EditHoliday">
                  <Navbar sliding={true}>
                      <NavLeft>
                        <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                        <Link href={`/dashboard/`} className="clearmenupadding">
                          <img className="navbarlogo" src={scanlogo} alt="logo" />
                        </Link>
                      </NavLeft>
                      <NavTitle>Edit Holiday</NavTitle>
                      <NavRight className="right-with-menu">
                      </NavRight>
                  </Navbar>
                  <PageContent>
                  <Block>
                  <List noHairlinesMd>
                      <BlockTitle style={{marginLeft:'15px'}}>Holiday Type</BlockTitle>
                      <Row noGap>
                        <Col width="50">
                          <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                              <ListItem radio value={0} name="holidaytype"
                              onChange={this.handleUserInput} title="All Customer"
                              checked={Number(holidaytype) === 0}
                              >
                              </ListItem>
                            </List>
                        </Col>
                        <Col width="50">
                        <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                              <ListItem radio value={1} name="holidaytype" 
                                onChange={this.handleUserInput} title="Single Customer" 
                                checked={Number(holidaytype) === 1}
                                >
                              </ListItem>
                            </List>
                        </Col>
                      </Row>  

                          {(()=>{
                              if(Number(holidaytype) > 0)
                              {
                                  return(
                                    <ListItem link="#" id="autocomplete-customer-popup" title={customername} after="Select">
                                    <input name="customerid" type="hidden" value={this.state.customerid}/>
                                  </ListItem>  
                                  );
                              }
                          })()}
                      </List>
                      {(()=>{
                        if(Number(holidaytype) < 1)
                        {
                            return (    
                            <List noHairlinesMd>
                              <BlockTitle style={{marginLeft:'15px'}}>Inventory Type</BlockTitle>
                                <Row noGap>
                                  <Col width="50">
                                    <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                      <ListItem radio value={0} name="inventorytype"
                                      onChange={this.handleUserInput} title="Global"
                                      checked={Number(inventorytype) === 0}
                                      >
                                      </ListItem>
                                    </List>  
                                  </Col>
                                  <Col width="50">
                                      <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                        <ListItem radio value={1} name="inventorytype"
                                        onChange={this.handleUserInput} title="Single Stock" 
                                        checked={Number(inventorytype) === 1}
                                        >
                                        </ListItem>
                                      </List>
                                  </Col>
                                </Row>
                                {(()=>{
                                    if(Number(inventorytype) > 0)
                                    {
                                    return(
                                      <ListItem link="#" id="autocomplete-inventory-popup" title={inventoryname} after="Select">
                                      <input name="inventoryid" type="hidden" value={this.state.inventoryid}/>
                                    </ListItem> 
                                    );
                                    }
                                })()}
                              </List>
                            )
                          }
                      })()}
                      <List noHairlinesMd>
                        <ListInput
                            name="startdate"
                            id="startdate"
                            className="adddata"
                            label="Start Date"
                            placeholder="Start Date"
                            type="date"
                            value={startdate}
                            onChange={this.handleUserInput}
                            calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            required validate
                          >
                          </ListInput>

                         <ListInput
                            name="enddate"
                            id="enddate"
                            className="adddata"
                            label="End Date"
                            placeholder="End Date"
                            type="date"
                            value={enddate}
                            onChange={this.handleUserInput}
                            calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            required validate
                          >
                          </ListInput> 
                          <ListInput
                            name="reason"
                            label="Reason"
                            floatingLabel
                            type="textarea"
                            onInput={this.handleUserInput}
                            value={reason}
                            resizable
                            placeholder="Remarks"
                          >
                          </ListInput>
                      </List>
                  </Block>
                  <br /><br /><br />
                </PageContent>
                <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
            </Page>
      );
    }
}