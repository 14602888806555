import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';

import {
    Toolbar,
    Row,
    Col,
    Button,
    Icon,
  } from 'framework7-react';

  export default class AppAddRecordButton extends Component {
    constructor(props) {
      super(props);

      this.state = {

      };
    }
    
    render(){

        const{saveDetail} = this.props;

          return(
             <Toolbar position="bottom">
                <Row style={{width:'100%'}}>
                    <Col width="60"></Col>
                    <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                    onClick={()=>{
                      if(this.$f7.online)
                      {
                        saveDetail();
                      }
                    }}
                    ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                </Row>
              </Toolbar>
          );

    }
}