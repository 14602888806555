import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppCardItemLoading from '../pages/appcarditemloading';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Link,
    Block,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Fab,
    List,
    ListItem,
    AccordionContent,
  } from 'framework7-react';

  export default class CloseCustomerList extends Component {
    
    constructor(props) {
      
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let lastdate = lastDay.getDate();

      let startdate = today.getFullYear()+'-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+lastdate;
     
      this.state = {
        clientdetail:[],
        startdate:startdate,
        reporttype:0,
        enddate:enddate,
        isfetched:false,
        isptr:false,
        ismount:false,
        hasallcustomer:false,
        hasfilter:true,
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        hasdataavailable:false,
        effect:'blink',
        closecustomerlist:[],
        areaid:'',
        areaname:'',
        inventoryid:'',
        inventoryname:'',
        lineid:'',
        linename:'',
        totalstock:0,
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getCloseCustomerList = this.getCloseCustomerList.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.hideSplashScreen();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          setTimeout(()=> {

            this.getCloseCustomerList();
            
          }, 200)

        })

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getCloseCustomerList(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
    
        const bodyFormData = {
          Mode:'GetCloseCustomerList',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          reporttype:self.state.reporttype,
          startdate:self.state.startdate,
          enddate:self.state.enddate,
          areaid:self.state.areaid,
          inventoryid:self.state.inventoryid,
          lineid:self.state.lineid,
        };

        this.setState(()=>{

          return{
            isfetched:false
          }
          
        },()=>{

          setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                    isfetched:true,
                    closecustomerlist:resdata.recordset,
                    totalrecord:resdata.totalrecord,
                    hasdataavailable:true,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });

              }
              else
              {
                this.setState({
                    isfetched:true,
                    closecustomerlist:[],
                    totalrecord:'0',
                    hasdataavailable:false,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        },500);

      });
    }

    downloadReportPDF(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
          Mode:'GetCloseCustomerListPDF',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          reporttype:self.state.reporttype,
          startdate:self.state.startdate,
          enddate:self.state.enddate,
          areaid:self.state.areaid,
          inventoryid:self.state.inventoryid,
          lineid:self.state.lineid,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                    //console.log(resdata.pdffilepath);
                   // window.location.href= 'http://orlopay/agency/';
                   if(resdata.filepath != '')
                   {
                    window.open(resdata.filepath);
                   }
                   else
                   {
                    window.open(resdata.pdffilepath);
                   }
                }
                else
                {
                    const toastWithButton = app.toast.create({
                      text: resdata.msg,
                      closeButton: true,
                      closeTimeout: 3000,
                    });

                    toastWithButton.open();    
                }
            }).catch(e => {
                console.log(e);
            });

        },500);
    }

    setNewFilterData(startdate, enddate, areaid, areaname, inventoryid, inventoryname, lineid, linename,reporttype){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            reporttype:reporttype,
            startdate:startdate,
            enddate:enddate,
            areaid:areaid,
            areaname:areaname,
            inventoryid:inventoryid,
            inventoryname:inventoryname,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
            lineid:lineid,
            linename:linename,
          }
  
        },()=>{

         const{startdate, enddate, areaid, inventoryid, lineid,reporttype} = this.state;
  
          if((startdate !== "" && startdate !== undefined) || (enddate !== "" && enddate !== undefined) || (areaid !== "" && areaid !== undefined) || (inventoryid !== "" && inventoryid !== undefined) || (lineid !== "" && lineid !== undefined) || (reporttype !== "" && reporttype !== undefined))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getCloseCustomerList();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){

      const{isfetched, startdate, enddate, hasallcustomer, hasfilter, removefiltername, applyremovefilter, ismount, hasdataavailable, areaid, areaname, inventoryid, inventoryname, closecustomerlist, lineid, linename, totalrecord,reporttype} = this.state;

      let datestart     = "";
      let newstartdate  = "";

      if(startdate !== "" && startdate !== undefined)
      {
        newstartdate = new Date(startdate);

        let currmonth = newstartdate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        datestart = newstartdate.getDate()+"-"+currmonth+"-"+newstartdate.getFullYear();
      }

      let dateend     = "";
      let newenddate  = "";

      if(enddate !== "" && enddate !== undefined)
      {
        newenddate = new Date(enddate);

        let currmonth = newenddate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
      }

      return(
        <Page pageContent={false} className="page" name="CloseCustomerList">
        <Navbar sliding={true}>
          <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
            <Link href={`/dashboard/`} className="clearmenupadding">
              <img className="navbarlogo" src={scanlogo} alt="logo" />
            </Link>
          </NavLeft>
          <NavTitle>Close Customer List</NavTitle>
          <NavRight className="right-with-menu">
          <Link onClick={()=>{

            Promise.all([
                
              setTimeout(()=> {

                this.Dom7('.waiting').addClass('animate-bottom');

              }, 50)

            ])
            .then(() => {

              setTimeout(()=> {

                this.getCloseCustomerList();
                
              }, 200)

            })
            
          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
          </NavRight>
        </Navbar>
      <FilterConsumer>{(value)=>{

        const{closecustomerfilterdata, applyCloseCustomerFilter} = value;
        if(ismount === true)
        {
          setTimeout(()=> {

            if(removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(removefiltername === 'daterange')
              {
                applyCloseCustomerFilter('', '', areaid, areaname, inventoryid, inventoryname, lineid, linename,reporttype);
              }
              else if(removefiltername === 'area')
              {
                applyCloseCustomerFilter(startdate, enddate, '', '', inventoryid, inventoryname, lineid, linename,reporttype);
              }
              else if(removefiltername === 'stock')
              {
                applyCloseCustomerFilter(startdate, enddate, areaid, areaname, '', '', lineid, linename,reporttype);
              }
              else if(removefiltername === 'line')
              {
                applyCloseCustomerFilter(startdate, enddate, areaid, areaname, inventoryid, inventoryname, '', '',reporttype);
              }
            }

            if(((closecustomerfilterdata.startdate !== startdate) && closecustomerfilterdata.startdate !== undefined) || ((closecustomerfilterdata.enddate !== enddate) && closecustomerfilterdata.enddate !== undefined) || ((closecustomerfilterdata.areaid !== areaid) && closecustomerfilterdata.areaid !== undefined) || ((closecustomerfilterdata.inventoryid !== inventoryid) && closecustomerfilterdata.inventoryid !== undefined) || ((closecustomerfilterdata.lineid !== lineid) && closecustomerfilterdata.lineid !== undefined) || ((closecustomerfilterdata.reporttype !== reporttype) && closecustomerfilterdata.reporttype !== undefined) || applyremovefilter === true)
            {
              this.setNewFilterData(closecustomerfilterdata.startdate, closecustomerfilterdata.enddate, closecustomerfilterdata.areaid, closecustomerfilterdata.areaname, closecustomerfilterdata.inventoryid, closecustomerfilterdata.inventoryname, closecustomerfilterdata.lineid, closecustomerfilterdata.linename, closecustomerfilterdata.reporttype);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }

          }, 500)
        }
        
          return(
            <PageContent>
              {
                hasfilter ? (
                    <Block strong className="selectedfilter" style={{marginTop:0}}>
                        {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                        {
                        (Number(reporttype) < 1) ? (<Chip text={`Report Type : Closed Subscritions`} mediaBgColor="blue" media={`R`}
                        />):(<Chip text={`Report Type : In-Active Customers`} mediaBgColor="blue" media={`R`}
                          />)}
                        {
                        (Number(reporttype) < 1) ? (<Chip text={`Date :${reporttype} (${datestart}) - (${dateend})`} mediaBgColor="blue" media={`D`}
                        />):null}
                        {Number(areaid) ? (<Chip text={`Area : ${areaname}`} mediaBgColor="pink" media={areaname.toUpperCase().substring(0, 1)} deleteable
                        onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'area'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                        />):null}
                        {Number(inventoryid) ? (<Chip text={`Inventory : ${inventoryname}`} mediaBgColor="yellow" media={inventoryname.toUpperCase().substring(0, 1)} deleteable
                        onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'stock'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                        />):null}
                        {Number(lineid) ? (<Chip text={`Line : ${linename}`} mediaBgColor="green" media={linename.toUpperCase().substring(0, 1)} deleteable
                        onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'line'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                        />):null}
                        &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.$f7router.navigate(`filterpopup/?startdate=${startdate}&enddate=${enddate}&areaid=${areaid}&areaname=${areaname}&inventoryid=${inventoryid}&inventoryname=${inventoryname}&lineid=${lineid}&linename=${linename}&reporttype=${reporttype}`);
                            }
                            /*this.openFilterPopup();*/
                        }}/>
                    </Block>
                ):null
              }
              {
                isfetched? (<Fragment>
                {
                  hasdataavailable ? (<Fragment>
                      <BlockTitle className="totalrec">Total Records : {totalrecord}</BlockTitle>
                        <List accordionList>
                        {
                            closecustomerlist.map((inventory)=>{

                            return(
                                  <ListItem accordionItem title={`${inventory.name}`} after={`${inventory.qty}`} key={inventory.id} className="accordion-item-opened2">
                                    <AccordionContent>
                                        <Block style={{marginLeft:'0',marginRight:'0'}}>
                                          <BlockTitle>Close Customer List</BlockTitle>
                                          <div className="search-list searchbar-found">
                                            {
                                              (Number(reporttype) < 1) ? (
                                                inventory.detail.map((detail)=>{
                                                    return(
                                                        <Card outline key={detail.logid} className="recordset" style={{marginLeft:'0',marginRight:'0'}}>
                                                          <CardHeader
                                                          className="date"><div className="item-title">{detail.name2}</div><div className="item-title"></div></CardHeader>
                                                          <CardContent>
                                                              {detail.phone ? (<div style={{color:detail.blockcolor}}><b>Phone : </b>{detail.phone}
                                                                &nbsp;
                                                                {
                                                                  detail.phone !== "---" ? (
                                                                    <Link external href={`tel:${detail.phone}`} target="_system">
                                                                      <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                                    </Link>
                                                                  ):null
                                                                }
                                                              </div>):null}
                                                            {detail.area ? (<p><b>Area : </b>{detail.area}</p>):null}
                                                            {detail.line ? (<p><b>Line : </b>{detail.line}</p>):null}
                                                            {detail.fulladdress ? (<p><b>Address : </b>{detail.fulladdress}</p>):null}
                                                            {detail.logsubscriptiondate ? (<p><b>Subscription Date : </b>{detail.logsubscriptiondate}</p>):null}
                                                            {detail.logunsubscribedate ? (<p><b>Close Date : </b>{detail.logunsubscribedate}</p>):null}
                                                          </CardContent>
                                                          <CardFooter>
                                                          <Link onClick={()=>{this.navigateToLink(`editcustomer/${detail.custid}`)}} ignoreCache={true}>Edit</Link>
                                                          <div>&nbsp;</div>
                                                        </CardFooter>
                                                        </Card>
                                                    );
                                                })
                                              ):(
                                                  inventory.detail.map((detail)=>{
                                                    return(
                                                        <Card outline key={detail.custid} className="recordset" style={{marginLeft:'0',marginRight:'0'}}>
                                                          <CardHeader
                                                          className="date"><div className="item-title">{detail.name2}</div><div className="item-title"></div></CardHeader>
                                                          <CardContent>
                                                              {detail.phone ? (<div style={{color:detail.blockcolor}}><b>Phone : </b>{detail.phone}
                                                                &nbsp;
                                                                {
                                                                  detail.phone !== "---" ? (
                                                                    <Link external href={`tel:${detail.phone}`} target="_system">
                                                                      <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                                    </Link>
                                                                  ):null
                                                                }
                                                              </div>):null}
                                                            {detail.area ? (<p><b>Area : </b>{detail.area}</p>):null}
                                                            {detail.line ? (<p><b>Line : </b>{detail.line}</p>):null}
                                                            {detail.fulladdress ? (<p><b>Address : </b>{detail.fulladdress}</p>):null}
                                                          </CardContent>
                                                          <CardFooter>
                                                          <Link onClick={()=>{this.navigateToLink(`editcustomer/${detail.custid}`)}} ignoreCache={true}>Edit</Link>
                                                          <div>&nbsp;</div>
                                                        </CardFooter>
                                                        </Card>
                                                    );
                                                })
                                              )
                                            }
                                          </div>
                                          <br/>
                                        </Block>
                                    </AccordionContent>
                                  </ListItem>
                                );
                            })
                        }
                        </List>
                  </Fragment>):(<AppItemNotFound notfoundtext ="No record found." />)
                }
                </Fragment>):(<AppCardItemLoading />)
              }
              <br />
            </PageContent>
          );
        }}
      </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {
            hasdataavailable ? (
               <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                    if(this.$f7.online)
                      {
                        this.downloadReportPDF();
                      }
                  }}>
                  <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                  <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                </Fab>
            ):null
         }
        </Page>

      );
    }
}