import React,{Component} from 'react';
import AppSaveRecordButton from './appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Input,
    Link
  } from 'framework7-react';

  export default class Editgallery extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        imagefile:'',
        imagepreview:'',
        orgfile:'',
        status:1,
        order:'',
      };

      this.handleUserInput = this.handleUserInput.bind(this);
      this.getDetail   = this.getDetail.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.getDetail();
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }
    getDetail(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
          Mode:'GetImageDetail',
          clientid:self.state.clientdetail.id,
          recordid:self.props.f7route.params.id,
      };

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/gallery.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          // Hide Pull To Refresh
          app.ptr.done();

          const resdata = JSON.parse(res.data);
          if(resdata.success === true)
          {
            this.setState(()=>{
                return{
                  name:resdata.detail.name,
                  status:resdata.detail.status,
                  order:resdata.detail.order,
                  orgfile:resdata.detail.orgfile,
                  imagepreview:resdata.detail.imagepreview,
                  isptr:false

                }
            });
          }
          else
          {
            this.setState(()=>{
              return{

                isptr:false

              }
            });
          }

      }).catch(e => {
          console.log(e);
      });
    }

    saveDetail(){
      const self  = this;
      const app   = self.$f7;
      const $$    = self.Dom7;
      const router    = self.$f7router;

      if(this.state.internetstatus === "offline")
      {
        const toastWithButton = app.toast.create({
          text: "No internet connection",
        });
          
        toastWithButton.open();
        
        return;
      }

      let errmsg    = "";
      app.input.validateInputs('.adddata');
      let filedata    = "";
      filedata = document.getElementById('imagefile').files[0];

      if(this.state.name === "")
      {
        errmsg = "Please enter name";
      }

      if((filedata === "" || filedata === undefined) && (this.state.orgfile === "" || this.state.orgfile === undefined || this.state.orgfile === null) && errmsg==='')
      {
        errmsg = "Please select an image";
      }

      if(filedata !== "" && filedata !== undefined  && errmsg==='')
      {
        let fileext = filedata.name.split('.').pop().toLowerCase();
        if(fileext !=='jpg' && fileext !=='jpeg' && fileext !=='png')
        {
            errmsg = "Please select a valid image file, only .jpg, .jpeg and .png file allowed";
        }
      }
      
      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const formData = new FormData();

      formData.append('Mode','EditImage');
      formData.append('imagefile',filedata);
      formData.append('clientid',Number(self.state.clientdetail.id));
      formData.append('recordid',Number(self.props.f7route.params.id));
      formData.append('name',self.state.name);
      formData.append('status',Number(self.state.status));
      formData.append('order',Number(self.state.order));

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
          const accesstoken = localStorage.getItem('agency_accesstoken');

          app.request({
            url: `${app.passedParams.serverurl}api/gallery.php?t=${Date.now()}`,
            method: 'POST', 
            data: formData,
            crossDomain: true,
            cache: false, 
            dataType: 'application/json',
            contentType: 'multipart/form-data',
            processData: true,
            headers: {
                Authorization: `${accesstoken}`
            },
            success: (data)=>{

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();
    
                const resdata = JSON.parse(data);

                if(resdata.success === true)
                {
                  router.navigate('/gallery/');

                  /*Promise.all([
                      this.setState(()=>{
                        return{
                            name:'',
                            previewimage:'',
                            status:1,
                            order:'',
                        }
                      })
                  ])
                  .then(() => {
      
                      $$('.item-content').removeClass('item-input-with-error-message');
                      $$('.item-content').removeClass('item-input-invalid');
                      document.getElementById('imagefile').value='';
                  });

                  this.$f7.views.main.router.navigate(`thanks/addgallerysuccess/`);*/
                }
                else
                {
                  const toastWithButton = app.toast.create({
                    text: resdata.toastmsg,
                    closeButton: true,
                    closeTimeout: 3000,
                  });

                  toastWithButton.open();
                }
            }
        });

      }, 500);

    }

    render(){
      
      const{imagepreview} = this.state;

      return(
            <Page pageContent={false} className="page" name="AddDroppingPoint">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Edit Gallery</NavTitle>
                  <NavRight className="right-with-menu">
                  </NavRight>
                </Navbar>
                <PageContent>
                  <Block>
                    <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                        <ListInput
                            name="name"
                            className="adddata"
                            label="Name"
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="Name"
                            value={this.state.name}
                            clearButton required validate
                        >
                        </ListInput>
                      </List>
                      <List noHairlines style={{marginBottom:'5px',marginTop:'5px'}}> 
                        <ListItem>
                          <span style={{marginRight:'5px'}}>Image</span><br/>
                          <Input type="file" name="imagefile" inputId="imagefile" onChange={this.handleUserInput}/>
                          {
                            imagepreview ? ( <img src={`${this.$f7.passedParams.serverurl}${imagepreview}`} width='80px'/>) :null
                          }
                        </ListItem>
                     </List>
                     <List noHairlines style={{marginBottom:'5px',marginTop:'5px'}}>
                        <ListItem title="Status">
                          <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                        <ListInput
                            name="order"
                            className="adddata"
                            label="Order By"
                            floatingLabel
                            type="number"
                            onInput={this.handleUserInput}
                            placeholder="Oredr By"
                            value={this.state.order}
                            clearButton required validate
                        >
                        </ListInput>
                    </List>
                  </Block>
                </PageContent>
              <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
            </Page>
      );
    }
}