import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppFav from '../pages/appfav';
import AppCardItemLoading from '../pages/appcarditemloading';
import scanlogo from '../assets/images/scanlogo.png';
import { CustomerConsumer } from '../context/CustomerContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Block,
    BlockTitle,
    Chip,
    Row,
    Col,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    List,
    ListInput,
    Toolbar,
    Button,
  } from 'framework7-react';

  export default class ManageCustomers extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        filtervars:[],
        iscustomerloaded:false,
      };
    }

    componentDidMount(){
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query,
          searchkeyword:this.props.f7route.params.searchkeyword
        }
      })

    }

    render(){

        return(
            <CustomerConsumer>{(value)=>{
              const{init, handleUserInput, getAllCustomers, openFilterPopup, handleOpeningBalance, changeOpeningBalance, updateOpeningBalance, Paging, deleteFilterAction, manageCustomerAction, deleteRecordAction} = value;

              const{isavailable, loaded, customers, paginglist, showpages, totalrecord, hasfilter, filterline, filterlineman, filterhawker, filterarea, filteropeningbalance, hasallcustomer, page, totalpages, areaid, lineid, hawkerid, openingbalanceid, toolbarPosition, searchkeyword, isinit, nameandphone} = value;

              let tempnameandphone  = "";

              if(nameandphone !== "" && nameandphone !== undefined && nameandphone !== 'undefined')
              {
                tempnameandphone  = "";
              }

              let extraspaceclass = "";

              if(showpages)
              {
                extraspaceclass = "withtoolbar";
              }

              setTimeout(() => {

                if(this.state.iscustomerloaded === false)
                {
                  this.setState({
                    iscustomerloaded:true
                  },()=>{
                    init(this.state.filtervars, this.state.searchkeyword);
                  })
                }

              }, 100);

              return(
                  <Page pageContent={false} className="page" name="ManageCustomers">
                      <Navbar sliding={true}>
                        <NavLeft>
                          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                          <Link href={`/dashboard/`} className="clearmenupadding">
                            <img className="navbarlogo" src={scanlogo} alt="logo" />
                          </Link>
                        </NavLeft>
                        <NavTitle>Customers</NavTitle>
                        <NavRight className="right-with-menu">
                        <Link onClick={()=>{

                            Promise.all([
                        
                              setTimeout(()=> {
                    
                                this.Dom7('.waiting').addClass('animate-bottom');
                    
                              }, 50)
                    
                            ])
                            .then(() => {

                              getAllCustomers();
                    
                            });

                          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                        </NavRight>
                      </Navbar>
                      <PageContent>
                      {
                        loaded ? (<Fragment>
                        {
                          isavailable ? (<BlockTitle className="totalrec">Total Customers : {totalrecord}</BlockTitle>):null
                        }
                        {
                          hasfilter ? (
                            <Block strong className="selectedfilter" style={{marginTop:0}}>
                            {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                              {searchkeyword ? (<Chip text={`Keyword : ${searchkeyword}`} mediaBgColor="blue" media={searchkeyword.toUpperCase().substring(0, 1)} deleteable
                              onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      deleteFilterAction('searchkeyword');
                                    }
                                }}
                              />):null}
                              {nameandphone !== "" && nameandphone !== undefined ? (<Chip text={`Name / Mobile : ${nameandphone}`} mediaBgColor="blue" media={nameandphone.toUpperCase().substring(0, 1)} deleteable
                              onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    deleteFilterAction('nameandphone');
                                  }
                                }}
                              />):null}
                              {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                              onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    deleteFilterAction('area');
                                  }
                                }}
                              />):null}
                              {Number(lineid) > 0 && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="green" media={filterline.toUpperCase().substring(0, 1)} deleteable
                              onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    deleteFilterAction('line');
                                  }
                                }}
                              />):null}
                              {filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="orange" media={filterlineman.toUpperCase().substring(0, 1)} deleteable
                              onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    deleteFilterAction('lineman');
                                  }
                                }}
                              />):null}
                              {Number(hawkerid) > 0 && filterhawker !== "" ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="yellow" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                              onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    deleteFilterAction('hawker');
                                  }
                                }}
                              />):null}
                              {openingbalanceid !== "" && filteropeningbalance ? (<Chip text={`Opening Balance : ${filteropeningbalance}`} mediaBgColor="blue" media={filteropeningbalance.toUpperCase().substring(0, 1)} deleteable
                              onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    deleteFilterAction('openingbalance');
                                  }
                                }}
                              />):null}
                              &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    this.$f7router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&openingbalanceid=${openingbalanceid}&filteropeningbalance=${filteropeningbalance}&nameandphone=${tempnameandphone}`);
                                  }
                                /*openFilterPopup();*/
                              }}/>
                            </Block>
                          ):null
                        }

                        {
                          isavailable ? (
                            <div className="search-list searchbar-found">
                            {
                              customers.map((person)=>{
                                return(
                                <div key={person.id} onClick={()=>{/*this.navigateToLink(`customerdetail/${person.id}`)*/}}>
                                  <Card outline className="recordset">
                                    <CardHeader
                                    className="date" style={{backgroundColor:person.blockcolor}}><div className="item-title">{person.name}</div><div className="item-title">{person.customerid}</div></CardHeader>
                                    <CardContent>
                                      {person.phone ? (<div style={{color:person.blockcolor}}><b>Phone : </b>{person.phone}
                                        &nbsp;
                                        {
                                          person.phone !== "---" ? (
                                            <Link external href={`tel:${person.phone}`} target="_system">
                                              <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                            </Link>
                                          ):null
                                        }
                                      </div>):null}
                                      {person.phone2 ? (<p style={{color:person.blockcolor}}><b>Alt. Phone : </b>{person.phone2}
                                        &nbsp;
                                        {
                                          person.phone2 !== "---" ? (
                                            <Link external href={`tel:${person.phone2}`} target="_system">
                                              <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                            </Link>
                                          ):null
                                        }
                                      </p>):null}
                                      {person.area ? (<p style={{color:person.blockcolor}}><b>Area : </b>{person.area}</p>):null}
                                      {person.line ? (<p style={{color:person.blockcolor}}><b>Line : </b>{person.line}</p>):null}
                                      {person.subline ? (<p style={{color:person.blockcolor}}><b>Sub Line : </b>{person.subline}</p>):null}
                                      {person.lineman ? (<p style={{color:person.blockcolor}}><b>Lineman : </b>{person.lineman}</p>):null}
                                      {person.hawker ? (<p style={{color:person.blockcolor}}><b>Hawker : </b>{person.hawker}</p>):null}
                                      {/*person.discount ? (<p><b>Discount : </b>{person.discount}</p>):null*/}
                                      {person.fulladdress ? (<p style={{color:person.blockcolor}}><b>Address : </b>{person.fulladdress}</p>):null}
                                      {person.subscriptions ? (<p style={{color:person.blockcolor}}><b>Subscriptions : </b>{person.subscriptions}</p>):null}
                                      {person.openingbalancetxt ? (<p style={{color:person.blockcolor}}><b>Opening Balance : </b>{person.openingbalancetxt}&nbsp;{person.caneditopeningbalance ?<Link onClick={()=>{handleOpeningBalance(person.id)}}><Icon f7="pencil_circle_fill"></Icon></Link>:null}</p>):null}
                                      {
                                        person.canchangebalance ? (
                                          <Row key={person.id}>
                                            <Col width="70">
                                              <List noHairlinesMd className="searchwrapper">
                                                <ListInput
                                                  className="smallinputnumber searchbox"
                                                  name={`openingbalance_${person.id}`}
                                                  inputId={person.id}
                                                  label="Opening Balance"
                                                  floatingLabel
                                                  type="number"
                                                  personid={person.id}
                                                  onInput={changeOpeningBalance}
                                                  placeholder="Opening Balance"
                                                  value={person.openingbalance}
                                                  clearButton validate
                                                >
                                                </ListInput>
                                              </List>
                                            </Col>
                                            <Col width="30">
                                            <Button fill color="blue" 
                                                onClick={()=>{
                                                  if(this.$f7.online)
                                                  {
                                                    updateOpeningBalance(person.id);
                                                  }
                                                }}
                                            >Update</Button>
                                            </Col>
                                          </Row>
                                        ):null
                                      }
                                      {person.googlemap ? (<p><b>Map : </b> <Link href={person.googlemap} external>View</Link></p>):null}
                                      {person.hascustomerholiday ? (<p style={{color:person.blockcolor}}><b>Holiday : </b>{person.customerholidays}</p>):null}
                                    </CardContent>
                                    <CardFooter>
                                      <Link onClick={()=>{
                                        if(this.$f7.online)
                                        {
                                          manageCustomerAction(person.id, person.phone);
                                        }
                                      }}><Icon ios="f7:chart_bar_fill" aurora="f7:chart_bar_fill" md="material:dashboard"></Icon> Manage
                                      </Link>
                                      {
                                        !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                          <Link onClick={()=>{
                                            if(this.$f7.online)
                                            {
                                              deleteRecordAction(person.id);
                                            }
                                          }}>Delete</Link>
                                        ):<Link>&nbsp;</Link>
                                      }
                                    </CardFooter>
                                  </Card>
                                </div>
                                );
                              })
                            }
                          </div>
                          ):(<AppItemNotFound notfoundtext ="No customer record found." />)
                        }
                        </Fragment>):(<AppCardItemLoading />)
                      }
                    <br /><br />
                    </PageContent>
                    {
                      isavailable  && showpages ? (
                        <Toolbar position={toolbarPosition}>
                        {
                          (()=>{
                            if(Number(page) > 1)
                            {
                              return(
                                <Link className="paging" onClick={()=>{Paging('previous')}}>
                                <Icon f7="arrow_left_square_fill" ></Icon>
                                </Link>
                              );
                            }
                            else
                            {
                              return(
                                <Link href="#"></Link>
                              );
                            }
                          })()
                        }
                          <List className="droppage" noHairlines>
                              <ListInput
                                  name="page"
                                  type="select"
                                  value={page}
                                  onChange={handleUserInput}
                                  placeholder="Please choose..."
                                  >
                                  {
                                    paginglist.map((pages)=>{
                                    return(<option key={pages.index} value={pages.page}>{pages.name}</option>);
                                    })
                                  }
                              </ListInput>
                          </List>
                          {
                            (()=>{
                              if(Number(page) < Number(totalpages))
                              {
                                return(
                                  <Link className="paging" onClick={()=>{Paging('next')}}>
                                  <Icon f7="arrow_right_square_fill" ></Icon>
                                  </Link>
                                );
                              }
                              else
                              {
                                return(
                                  <Link href="#"></Link>
                                );
                              }
                            })()
                          }
                        </Toolbar>
                      ):null
                    }
                    <AppFav position="center-bottom" text="Add Customer" icon="plus" url="addcustomer" />
                  </Page>
              );
            }}
            </CustomerConsumer>            
        );
    }
}