import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    List,
    ListItem,
    Block,
    ListInput,
    Link
  } from 'framework7-react';

  export default class Contactdetail extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        contactname:'',
        websiteemail:'',
        websitephone1:'',
        websiteiswhatsapp1:0,
        websitephone2:'',
        websiteiswhatsapp2:0,
        websiteaddress:'',
        profiledetail:[],
      };
      this.getProfileDetail = this.getProfileDetail.bind(this);
      this.handleUserInput = this.handleUserInput.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getProfileDetail();
        this.hideSplashScreen();

      })

      const app = this.$f7;
      const $$  = this.Dom7;
      const ptrContent = $$('.ptr-content');

      // Add 'refresh' listener on it
      ptrContent.on('ptr:refresh', (e)=> {
        // Emulate 2s loading

        setTimeout(()=> {
      
          this.getProfileDetail();
      
          app.ptr.done(); // or e.detail();
        }, 2000);

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){
      const name  = e.target.name;
      const value = e.target.value;

      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    check_email(elementValue){
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(elementValue);
    }

    getProfileDetail(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'GetProfileDetail',
        recordid:self.state.clientdetail.id,
      };

      const accesstoken  = localStorage.getItem('agency_accesstoken');
          
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/profile.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              websiteemail:resdata.profiledetail.websiteemail,
              websitephone1:resdata.profiledetail.websitephone1,
              websiteiswhatsapp1:resdata.profiledetail.websiteiswhatsapp1,
              websitephone2:resdata.profiledetail.websitephone2,
              websiteiswhatsapp2:resdata.profiledetail.websiteiswhatsapp2,
              websiteaddress:resdata.profiledetail.websiteaddress,
              profiledetail:resdata.profiledetail,
            });
          }

      }).catch(e => {
          console.log(e);
      });
    }

    saveProfileDetail(){
      
      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const toastWithButton = app.toast.create({
        text: 'Please enter all required field.',
        closeButton: true,
        closeTimeout: 3000,
      });

      app.input.validateInputs('.adddata');

      if(this.state.websiteemail === "" || this.state.websitephone1 === "" || this.state.websiteaddress === "")
      {
        toastWithButton.open();
        return false;
      }

      if(this.state.websiteemail != "" && !this.check_email(this.state.websiteemail))
      {
        const toastWithButton = app.toast.create({
          text: 'Please enter a valid email.',
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();

        return false;
      }

      const bodyFormData = {
          Mode:'SaveWebsiteDetail',
          recordid:self.state.clientdetail.id,
          websiteemail:self.state.websiteemail,
          websitephone1:self.state.websitephone1,
          websiteiswhatsapp1:Number(self.state.websiteiswhatsapp1),
          websitephone2:self.state.websitephone2,
          websiteiswhatsapp2:Number(self.state.websiteiswhatsapp2),
          websiteaddress:self.state.websiteaddress,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/profile.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);

            const toastWithButton2 = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton2.open();

            /*if(resdata.success === true)
            {
              localStorage.setItem("agency_clientdetail",JSON.stringify(resdata.clientdetail));
              localStorage.setItem("agency_accesstoken",resdata.accesstoken);
            }*/
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){
       
      return(
        <Page pageContent={false} className="page" name="Contactdetail">
          <Navbar>
            <NavLeft>
              <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
              <Link href={`/dashboard/`} className="clearmenupadding">
                <img className="navbarlogo" src={scanlogo} alt="logo" />
              </Link>
            </NavLeft>
            <NavTitle>Contact Detail</NavTitle>
            <NavRight className="right-with-menu">
            </NavRight>
          </Navbar>
          <PageContent>
            <Block>
              <List noHairlinesMd>
                <ListInput
                  name="websiteemail"
                  label="Contact Email"
                  floatingLabel
                  type="email"
                  onInput={this.handleUserInput}
                  placeholder="Contact Email"
                  value={this.state.websiteemail}
                  clearButton required validate
                >
                </ListInput>

                <ListInput
                  name="websitephone1"
                  label="Phone Number"
                  floatingLabel
                  type="tel"
                  onInput={(e)=>{
                  
                    let tempphone = e.target.value;
                    let mobile    = tempphone.replace(/ /g,'');
                    
                    if(tempphone.slice(0,1)=='+')
                    {
                      mobile = mobile.substring(3);
                    }

                    if(tempphone.slice(0,1)=='0')
                    {
                      mobile = mobile.substring(1);
                    }

                    if(mobile.length > 10)
                    {
                      return;
                    }
                    
                    this.setState({
                      websitephone1:mobile
                    })
                  }}
                  placeholder="Phone number"
                  value={this.state.websitephone1}
                  clearButton required validate pattern="[0-9]*"
                >
                </ListInput>

                <ListItem checkbox title="Is Whatsapp Enabled ?" 
                  name="websiteiswhatsapp1"
                  onChange={()=>{
                    this.setState({websiteiswhatsapp1:!this.state.websiteiswhatsapp1})
                  }} 
                  checked={Number(this.state.websiteiswhatsapp1) === 1}
                  value="1"
                >
                </ListItem>

                <ListInput
                  name="websitephone2"
                  label="Alternate Phone"
                  floatingLabel
                  type="tel"
                  onInput={(e)=>{
                  
                    let tempphone = e.target.value;
                    let mobile    = tempphone.replace(/ /g,'');
                    
                    if(tempphone.slice(0,1)=='+')
                    {
                      mobile = mobile.substring(3);
                    }

                    if(tempphone.slice(0,1)=='0')
                    {
                      mobile = mobile.substring(1);
                    }

                    if(mobile.length > 10)
                    {
                      return;
                    }
                    
                    this.setState({
                      websitephone2:mobile
                    })
                  }}
                  placeholder="Alternate Phone"
                  value={this.state.websitephone2}
                  clearButton validate pattern="[0-9]*"
                >
                </ListInput>

                <ListItem checkbox title="Is Whatsapp Enabled ?" 
                  name="websiteiswhatsapp2"
                  onChange={()=>{
                    this.setState({websiteiswhatsapp2:!this.state.websiteiswhatsapp2})
                  }} 
                  checked={Number(this.state.websiteiswhatsapp2) === 1}
                  value="1"
                >
                </ListItem>

                <ListInput
                  name="websiteaddress"
                  label="Address"
                  floatingLabel
                  type="text"
                  onInput={this.handleUserInput}
                  placeholder="Address"
                  value={this.state.websiteaddress}
                  clearButton required validate
                >
                </ListInput>
              </List>
              <br />
            </Block>
          </PageContent>
          <AppSaveRecordButton saveDetail={this.saveProfileDetail.bind(this)} />
        </Page>
      );
    }
}