import React,{Component, Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    BlockTitle,
    Link,
    Block,
    List,
    Row,
    Col,
    ListItem,
    Page,
    PageContent,
    Toolbar,
    Icon,
    Button,
  } from 'framework7-react';

  export default class ViewLedger extends Component {
    constructor(props) {
      super(props);

      let today = new Date();

      this.state = {
        clientdetail:[],
        customerid:'',
        customername:'',
        customerlist:[],
        grandtotal:0,
        ledgerlist:[],
        /*Ledgerdate:date,*/
        isptr:false,
        Ledgertype:1,
        Ledgerdate:'',
        loaded:false,
        effect:'blink',
      };
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.setState(()=>{
              return{
                loaded:false
              }
            },()=>{

              this.Dom7('.waiting').addClass('animate-bottom');

            })

          }, 50)

        ])
        .then(() => {

          this.getLedgerDetail();
          
        })

        this.hideSplashScreen();

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    getLedgerDetail(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
            Mode:'GetLedger',
            clientid:self.state.clientdetail.id,
            customerid:self.state.customerid,
        };

        this.setState({
          loaded:false
        });

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                  this.setState({

                      ledgerlist:resdata.ledgerlist,
                      grandtotal:resdata.grandtotal,
                      customername:resdata.customername,
                      isptr:false,
                      loaded:true,

                  });
                }
                else
                {
                  this.setState({

                    isptr:false,
                    loaded:true,

                  });
                }

            }).catch(e => {
                console.log(e);
            });

        },1000);
    }

    getLedgerPDF(){

      const self      = this;
      const app       = self.$f7;
  
      const bodyFormData = {
          Mode:'GetCustomerLederPDF',
          clientid:self.state.clientdetail.id,
          customerid:self.state.customerid,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
      
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request

          // Hide Preloader
          app.preloader.hide();

          // Hide Pull To Refresh
          app.ptr.done();                

          const resdata = JSON.parse(res.data);
          if(resdata.success === true)
          {
            window.open(resdata.pdffilepath);
          }
          else
          {
            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
              
            toastWithButton.open();
          }
        }).catch(e => {
            console.log(e);
        });

      },500);
    }

    resendInvoiceMessage(smscredits){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'ResendInvoiceMessage',
        customerid:self.state.customerid,
        smscredits:smscredits,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    getMessagePreviewToSend(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'GetInvoiceMessagePreview',
        customerid:self.state.customerid,
        clientid:this.state.clientdetail.id,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          if(resdata.success)
          {
            /*const parser        = new DOMParser();
            const msgpreviewhtml  = parser.parseFromString(resdata.preview, 'text/html').documentElement.textContent;*/
            
            app.dialog.create({
              title: 'Message Preview',
              text: resdata.preview,
              closeByBackdropClick: 'true',
              buttons: [
                {
                  text: 'Cancel',
                },
                {
                  text: 'Send',
                  onClick: () => {
                    this.resendInvoiceMessage(resdata.smscredits);
                  },
                },
              ],
            }).open();
          }
          else
          {
            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();
          }

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

  render(){

    const{customerid, ledgerlist, loaded} = this.state;

    return(
      <Popup className="demo-popup">
          <Navbar sliding={true}>
            <NavLeft>
              <Link popupClose>
                <i className="icon icon-back"></i>                      
              </Link>
              <img className="navbarlogo" src={scanlogo} alt="logo" />
            </NavLeft>
            <NavTitle>Ledger</NavTitle>
            <NavRight>
                <Link popupClose>Close</Link>
            </NavRight>
          </Navbar>
          <Page className="page" name="SubscriptionLog">
            <PageContent style={{paddingTop:0}}>
              <Block>
              <BlockTitle medium className="zeroleftmargin" style={{fontSize:'18px'}}>{this.state.customername}</BlockTitle>
              {
                  customerid ? (
                      <Fragment>
                      {
                      loaded ? (
                          <Fragment>
                            <Row noGap className='rowwithbottomborder' style={{borderTop:'1px solid #000000', borderBottom:'1px solid #000000', fontWeight:'bold'}}>
                              <Col width='15' className="tableheader1">Date</Col>
                              <Col width='15' className="tableheader1">Item</Col>
                              <Col width='15' className="tableheader1 tableheaderamount1 textalignright">Pay. ID</Col>
                              <Col width='15' className="tableheader1 tableheaderamount1 textalignright">Due</Col>
                              <Col width='15' className="tableheader1 tableheaderamount1 textalignright">Paid</Col>
                              <Col width='15' className="tableheader1 tableheaderamount1 textalignright">Bal.</Col>
                              <Col width='10' className="tableheader1 tableheaderamount1">&nbsp;</Col>
                            </Row>
                            {
                              ledgerlist.map((ledger,index)=>{

                                let balclass ='ledgercolumn ledgercolumnamount ledgerbalancepositive';
                                
                                if(ledger.balance < 1)
                                {
                                  balclass='ledgercolumn ledgercolumnamount ledgerbalancenegative';
                                }
                                
                                return(
                                  <Row noGap key={index} className='rowwithbottomborder'>
                                    <Col width='15' className="ledgercolumn">{ledger.date}</Col>
                                    <Col width='15' className="ledgercolumn">{ledger.item}</Col>
                                    <Col width='15' className="ledgercolumn ledgercolumnamount">{ledger.paymentid}</Col>
                                    <Col width='15' className="ledgercolumn ledgercolumnamount">{ledger.due}</Col>
                                    <Col width='15' className="ledgercolumn ledgercolumnamount">{ledger.paid}</Col>
                                    <Col width='15' className={balclass}>{ledger.balance}</Col>
                                    <Col width='10' className="ledgercolumn">
                                    {
                                      ledger.invoiceid > 0 ? <Link onClick={() =>{
                                        this.$f7router.navigate(`/invoicedetail/${ledger.invoiceid}/`);
                                      }}><Icon f7="chevron_right_circle_fill"></Icon></Link>:<div>&nbsp;</div>
                                    }
                                    </Col>
                                  </Row>
                                );
                              })
                            }
                          <Row noGap className='rowwithbottomborder'>
                              <Col width='80' className="tableheader tableheaderamount">Final Balance</Col>
                              <Col width='20'className="tableheader tableheaderamount">{this.state.grandtotal}</Col>
                          </Row>
                      </Fragment>
                      ):(<AppListItemLoading />)
                    }
                    </Fragment>
                  ):(<div className="errmsg">Please select a customer</div>)
              }
              </Block>
            </PageContent>
            <Toolbar position="bottom">
              <Row style={{width:'100%'}}>
                  <Col width="40">
                    <Button fill color="blue" style={{marginRight:'.4rem'}} popupClose
                    ><Icon f7="plus" style={{display:'inline-block'}}></Icon>&nbsp; Payment</Button>
                  </Col>
                  <Col width="30"><Button fill color="blue" style={{marginRight:'.4rem'}}
                  onClick={()=>{
                    if(this.$f7.online)
                    {
                      this.getLedgerPDF();
                    }
                  }}
                  ><Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>&nbsp;</Button></Col>
                  {
                    !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                      <Col width="30"><Button fill color="blue" style={{marginRight:'.4rem'}}
                      onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.getMessagePreviewToSend();
                        }
                      }}
                      ><Icon f7="envelope_fill" style={{display:'inline-block'}}></Icon>&nbsp;Send</Button></Col>
                    ):null
                  }
              </Row>
            </Toolbar>            
          </Page>
      </Popup>
    );
  }
}