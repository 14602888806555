import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppFav from '../pages/appfav';
import AppCardItemLoading from '../pages/appcarditemloading';
import scanlogo from '../assets/images/scanlogo.png';
import { WebsiteConsumer } from '../context/WebsiteContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Block,
    BlockTitle,
    Chip,
    Row,
    Col,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
    List,
    ListInput,
    Toolbar,
    Button,
  } from 'framework7-react';

  export default class Managecontactrequest extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        filtervars:[],
        iscustomerloaded:false,
      };
    }

    componentDidMount(){
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query,
          searchkeyword:this.props.f7route.params.searchkeyword
        }
      })

    }

    render(){

        return(
            <WebsiteConsumer>{(value)=>{
              const{init, handleUserInput, getAllContacts, Paging, manageContactAction, deleteRecordAction} = value;

              const{isavailable, loaded, contacts, paginglist, showpages, totalrecord, page, totalpages, toolbarPosition, isinit} = value;

              let extraspaceclass = "";

              if(showpages)
              {
                extraspaceclass = "withtoolbar";
              }

              setTimeout(() => {

                if(this.state.iscustomerloaded === false)
                {
                  this.setState({
                    iscustomerloaded:true
                  },()=>{
                    init(this.state.filtervars, this.state.searchkeyword);
                  })
                }

              }, 100);

              return(
                  <Page pageContent={false} className="page" name="ManageContacts">
                      <Navbar sliding={true}>
                        <NavLeft>
                          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                          <Link href={`/dashboard/`} className="clearmenupadding">
                            <img className="navbarlogo" src={scanlogo} alt="logo" />
                          </Link>
                        </NavLeft>
                        <NavTitle>Contact Request</NavTitle>
                        <NavRight className="right-with-menu">
                        <Link onClick={()=>{

                            Promise.all([
                        
                              setTimeout(()=> {
                    
                                this.Dom7('.waiting').addClass('animate-bottom');
                    
                              }, 50)
                    
                            ])
                            .then(() => {

                              getAllContacts();
                    
                            });

                          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                        </NavRight>
                      </Navbar>
                      <PageContent>
                      {
                        loaded ? (<Fragment>
                        {
                          isavailable ? (<BlockTitle className="totalrec">Total Contacts : {totalrecord}</BlockTitle>):null
                        }
                        {
                          isavailable ? (
                            <div className="search-list searchbar-found">
                            {
                              contacts.map((contact)=>{
                                return(
                                <div key={contact.id}>
                                  <Card outline className="recordset">
                                    <CardHeader
                                    className="date" style={{backgroundColor:contact.blockcolor}}><div className="item-title">{contact.date}</div></CardHeader>
                                    <CardContent>
                                      {contact.phone ? (<div style={{color:contact.blockcolor}}><b>Phone : </b>{contact.phone}
                                        &nbsp;
                                        {
                                          contact.phone !== "---" ? (
                                            <Link external href={`tel:${contact.phone}`} target="_system">
                                              <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                            </Link>
                                          ):null
                                        }
                                      </div>):null}
                                      {contact.email ? (<p style={{color:contact.blockcolor}}><b>Email : </b>{contact.email}</p>):null}
                                      {contact.message ? (<p style={{color:contact.blockcolor}}><b>Message : </b>{contact.message}</p>):null}
                                      {contact.status ? (<p style={{color:contact.blockcolor}}><b>Status : </b>Completed</p>):(<p style={{color:contact.blockcolor}}><b>Status : </b>Pending</p>)}
                                    </CardContent>
                                    <CardFooter>
                                      <Link onClick={()=>{
                                        if(this.$f7.online)
                                        {
                                          manageContactAction(contact.id);
                                        }
                                      }}><Icon ios="f7:chart_bar_fill" aurora="f7:chart_bar_fill" md="material:dashboard"></Icon> Manage
                                      </Link>
                                      {
                                        !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                          <Link onClick={()=>{
                                            if(this.$f7.online)
                                            {
                                              deleteRecordAction(contact.id);
                                            }
                                          }}>Delete</Link>
                                        ):<Link>&nbsp;</Link>
                                      }
                                    </CardFooter>
                                  </Card>
                                  </div>
                                );
                              })
                            }
                          </div>
                          ):(<AppItemNotFound notfoundtext ="No contact record found." />)
                        }
                        </Fragment>):(<AppCardItemLoading />)
                      }
                    <br /><br />
                    </PageContent>
                    {
                      isavailable  && showpages ? (
                        <Toolbar position={toolbarPosition}>
                        {
                          (()=>{
                            if(Number(page) > 1)
                            {
                              return(
                                <Link className="paging" onClick={()=>{Paging('previous')}}>
                                <Icon f7="arrow_left_square_fill" ></Icon>
                                </Link>
                              );
                            }
                            else
                            {
                              return(
                                <Link href="#"></Link>
                              );
                            }
                          })()
                        }
                          <List className="droppage" noHairlines>
                              <ListInput
                                  name="page"
                                  type="select"
                                  value={page}
                                  onChange={handleUserInput}
                                  placeholder="Please choose..."
                                  >
                                  {
                                    paginglist.map((pages)=>{
                                    return(<option key={pages.index} value={pages.page}>{pages.name}</option>);
                                    })
                                  }
                              </ListInput>
                          </List>
                          {
                            (()=>{
                              if(Number(page) < Number(totalpages))
                              {
                                return(
                                  <Link className="paging" onClick={()=>{Paging('next')}}>
                                  <Icon f7="arrow_right_square_fill" ></Icon>
                                  </Link>
                                );
                              }
                              else
                              {
                                return(
                                  <Link href="#"></Link>
                                );
                              }
                            })()
                          }
                        </Toolbar>
                      ):null
                    }
                  </Page>
              );
            }}
            </WebsiteConsumer>            
        );
    }
}