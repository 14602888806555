import React,{Component,Fragment} from 'react';
import AppCardItemLoading from '../pages/appcarditemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import scanlogo from '../assets/images/scanlogo.png';
import AppFav from '../pages/appfav';

import { HolidayConsumer } from '../context/HolidayContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Toolbar,
    Link,
    Block,
    List,
    ListInput,
    Icon,
    Chip,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton
  } from 'framework7-react';

  export default class ManageHoliday extends Component {

    constructor(props) {
      super(props);

      this.state = {
        isdataloaded:false,
        clientdetail:[],
        filtervars:[],
      }

    }

    componentDidMount(){

        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        this.setState(()=>{
            return{
              clientdetail:clientdetail,
              filtervars:this.props.$f7route.query
            }
        })
    }

    resendInvoiceMessage(customerid, smscredits){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'ResendInvoiceMessage',
        customerid:customerid,
        smscredits:smscredits,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    getMessagePreviewToSend(customerid){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'GetInvoiceMessagePreview',
        customerid:customerid,
        clientid:this.state.clientdetail.id,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          // We got user data from request
  
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          if(resdata.success)
          {
            /*const parser        = new DOMParser();
            const msgpreviewhtml  = parser.parseFromString(resdata.preview, 'text/html').documentElement.textContent;*/
            
            app.dialog.create({
              title: 'Message Preview',
              text: resdata.preview,
              closeByBackdropClick: 'true',
              buttons: [
                {
                  text: 'Cancel',
                },
                {
                  text: 'Send',
                  onClick: () => {
                    this.resendInvoiceMessage(customerid, resdata.smscredits);
                  },
                },
              ],
            }).open();
          }
          else
          {
            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();
          }

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){
      
        return(
            <HolidayConsumer>{(value)=>{
              const{handleUserInput, getAllHoliday, init, Paging, deleteFilterAction, navigateToLink, deleteRecordAction} = value;

              const{hasfilter, hasallcustomer, filterarea, filterline, filterlineman, filterhawker, isfetched, isavailable, totalrecord, holidays, showpages, page, paginglist, totalpages, toolbarPosition, areaid, lineid, hawkerid, holidaytype} = value;

              let extraspaceclass = "";

              if(showpages)
              {
                extraspaceclass = "withtoolbar";
              }

                setTimeout(() => {
                  if(this.state.isdataloaded === false)
                  {
                    this.setState(()=>{
                      return{
                        isdataloaded:true,
                      }
                    },()=>{

                      init(this.state.filtervars);
                      
                    })
                    
                  }
                }, 100);

                return(
                    <Page pageContent={false} className="page" name="ManageHoliday">
                        <Navbar sliding={true}>
                          <NavLeft>
                            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                            <Link href={`/dashboard/`} className="clearmenupadding">
                              <img className="navbarlogo" src={scanlogo} alt="logo" />
                            </Link>
                          </NavLeft>
                          <NavTitle>Manage Holiday</NavTitle>
                          <NavRight className="right-with-menu">
                          <Link onClick={()=>{

                            getAllHoliday();

                          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                          </NavRight>
                        </Navbar>
                        <PageContent>
                        {
                          isfetched ? (<Fragment>
                            {
                              isavailable ? (<Fragment>
                                <BlockTitle className="totalrec">Total Records : {totalrecord}</BlockTitle>
                              </Fragment>):null
                            }
                            {
                              hasfilter ? (
                                <Block strong className="selectedfilter" style={{marginTop:0}}>
                                  {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                                  <Chip text={`Holiday Type : ${Number(holidaytype) === 0 ? `Global`: `Individual`}`} mediaBgColor="blue" media="H"/>

                                  {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                                  onClick={()=>{
                                          if(this.$f7.online)
                                          {
                                            deleteFilterAction('area');
                                          }
                                        }}
                                  />):null}

                                  {Number(lineid) > 0 && filterline ? (<Chip text={`Line : ${filterline}`} mediaBgColor="yellow" media={filterline.toUpperCase().substring(0, 1)} deleteable
                                  onClick={()=>{
                                          if(this.$f7.online)
                                          {
                                            deleteFilterAction('line');
                                          }
                                        }}
                                  />):null}

                                  {filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="green" media={filterlineman.toUpperCase().substring(0, 1)} deleteable 
                                  onClick={()=>{
                                      if(this.$f7.online)
                                      {
                                        deleteFilterAction('lineman');
                                      }
                                    }}
                                  />):null}

                                  {Number(hawkerid) > 0 && filterhawker ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                                  onClick={()=>{
                                          if(this.$f7.online)
                                          {
                                            deleteFilterAction('hawker');
                                          }
                                        }}
                                  />):null}

                                  &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.$f7router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&holidaytype=${holidaytype}`);
                                    }
                                    /*openFilterPopup();*/
                                  }}/>
                                </Block>
                              ):null
                            }
                            {
                              isavailable ? (
                                <Fragment>
                                  {
                                      holidays.map((holiday)=>{
                                      return(
                                        <Card outline key={holiday.id} className="recordset">
                                          <CardHeader
                                          className="date">{holiday.addeddate}</CardHeader>
                                          <CardContent>
                                          <p><b>Holiday Type :</b> {holiday.customertype}</p>
                                          {holiday.customername ? (<p><b>Customer :</b> {holiday.customername}</p>):null}
                                          {holiday.inventorytype ? (<p><b>Stock Type :</b> {holiday.inventorytype}</p>):null}
                                          {holiday.inventoryname ? (<p><b>Stock :</b> {holiday.inventoryname}</p>):null}
                                          {holiday.startdate ? (<p><b>Start Date :</b> {holiday.startdate}</p>):null}
                                          {holiday.enddate ? (<p><b>End Date :</b> {holiday.enddate}</p>):null}
                                          {holiday.reason ? (<p><b>Reason :</b> {holiday.reason}</p>):null}
                                          {holiday.addedby ? (<p><b>Added By :</b> {holiday.addedby}</p>):null}
                                          </CardContent>
                                          <CardFooter>
                                            <Link onClick={()=>{navigateToLink(`editholiday/${holiday.id}`)}} ignoreCache={true}>Edit</Link>
                                            {
                                              !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                                <Link onClick={() =>{
                                                  if(this.$f7.online)
                                                  {
                                                    deleteRecordAction(holiday.id);
                                                  }
                                                }}>Delete</Link>
                                              ):(<div>&nbsp;</div>)
                                            }
                                          </CardFooter>
                                        </Card>
                                        );
                                      })
                                  }
                                </Fragment>
                              ):(<AppItemNotFound notfoundtext ="No Holiday(s) Added Yet." />)
                            }
                        </Fragment>):(<AppCardItemLoading />)
                      }
                      <br /><br />
                      </PageContent>
                      {
                        isavailable  && showpages ? (
                          <Toolbar position={toolbarPosition}>
                          {
                            (()=>{
                              if(Number(page) > 1)
                              {
                                return(
                                  <Link className="paging" onClick={()=>{Paging('previous')}}>
                                  <Icon f7="arrow_left_square_fill" ></Icon>
                                  </Link>
                                );
                              }
                              else
                              {
                                return(
                                  <Link href="#"></Link>
                                );
                              }
                            })()
                          }
                            <List className="droppage" noHairlines>
                                <ListInput
                                    name="page"
                                    type="select"
                                    value={page}
                                    onChange={handleUserInput}
                                    placeholder="Please choose..."
                                    >
                                    {
                                      paginglist.map((pages)=>{
                                      return(<option key={pages.index} value={pages.page}>{pages.name}</option>);
                                      })
                                    }
                                </ListInput>
                            </List>
                            {
                              (()=>{
                                if(Number(page) < Number(totalpages))
                                {
                                  return(
                                    <Link className="paging" onClick={()=>{Paging('next')}}>
                                    <Icon f7="arrow_right_square_fill" ></Icon>
                                    </Link>
                                  );
                                }
                                else
                                {
                                  return(
                                    <Link href="#"></Link>
                                  );
                                }
                              })()
                            }
                          </Toolbar>
                        ):null
                      }
                      <AppFav position="center-bottom" text="Add Holiday" icon="plus" url="addholiday" />
                    </Page>
                );
            }}
            </HolidayConsumer>            
        );
    }
}