import React,{Component} from 'react';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Row,
    Col,
    Link,
  } from 'framework7-react';

  export default class MonthlyBillOfNewspaperFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startyear = today.getFullYear();
      let endyear   = today.getFullYear();
      let startmonth = currmonth-1;
      
      if(startmonth < 1)
      {
        startmonth  = 12;
        startyear   = Number(startyear - 1);
      }

      let lastDay = new Date(endyear, currmonth, 0);

      let lastdate = lastDay.getDate();

      let startdate = startyear+'-'+startmonth+'-01';
      let enddate   = endyear+'-'+currmonth+'-'+lastdate;

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        filtermonthyear:monthNames[Number(startmonth-1)]+' - '+startyear,
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        arealist:[],
        linelist:[],
        linemanlist:[],
        hawkerlist:[],
        reportmonthyearlist:[],
        hassmartmonthyear:false,
        catid:'0',
        catname:'Both',
        filtervars:[],
      };

      this.startMonthYearAutocompletePopup  = this.startMonthYearAutocompletePopup.bind(this);
      this.applyFilter                      = this.applyFilter.bind(this);
      this.handleUserInput                  = this.handleUserInput.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{
        
        this.startMonthYearAutocompletePopup();
        this.hideSplashScreen();

        let{startdate, enddate, filtermonthyear, catid, catname} = this.state.filtervars;
       
        this.setState({
          startdate:startdate,
          enddate:enddate,
          filtermonthyear:filtermonthyear,
          catid:catid,
          catname:catname,
        })

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompletestartMonthYearPopup.destroy();

    }    

    startMonthYearAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompletestartMonthYearPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-startmonthyear-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectstartmonthyear/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetReportMonthYear',
              clientid:self.state.clientdetail.id,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    reportmonthyearlist:res.recordset,
                  }
                },()=>{

                  const searchmonthyearlist  = self.state.reportmonthyearlist;

                  for (let i = 0; i < searchmonthyearlist.length; i += 1) {
                    if (searchmonthyearlist[i].monthname.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchmonthyearlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchmonthyearlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-startmonthyear-name2').text(value[0].monthname+" - "+value[0].year);*/

            let month      = Number(value[0].month);
            let year       = Number(value[0].year);
            let lastdate   = value[0].lastdate;

            self.setState({
                startdate:month+"/01"+"/"+year,
                enddate:month+"/"+lastdate+"/"+year,
                filtermonthyear:value[0].monthname+" - "+value[0].year
            });
          },
        },
      });
    }

    applyFilter(){

      if(!this.$f7.online)
      {
        return;
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      this.setState(()=>{
        return{
          [name]: value
        }
      },()=>{
        if(name === "catid")
        {
          let tempcatname = "Both";

          if(Number(value) === 605)
          {
            tempcatname = "Newpapers";
          }
          else if(Number(value) === 606)
          {
            tempcatname = "Magazines";
          }

          this.setState({
            catname:tempcatname
          })
        }
      });

    }

    render(){

      const{startdate, enddate, filtermonthyear, catid, catname} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{applyMonthlyBillOfNewspaperFilter}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="MonthlyBillOfNewspaperFilter">
                    <Block>
                        <List>
                          <ListItem link="#" id="autocomplete-startmonthyear-popup" className="autocomplete-startmonthyear-popup" title="Price Month">
                            <div slot="after" id="autocomplete-startmonthyear-name2">{filtermonthyear}</div>
                          </ListItem>
                        </List>
                        <p style={{marginLeft:'15px'}}>Stock Type</p>
                        <Row noGap>
                            <Col width="30">
                                <List className="logintype pricelistfilter" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="0" name="catid" checked={Number(catid) === 0} title="Both"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                            <Col width="35">
                                <List className="logintype pricelistfilter" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem className="pricelistitem" radio value="605" name="catid" checked={Number(catid) === 605} title="Newpapers"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                            <Col width="35">
                                <List className="logintype pricelistfilter" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem className="pricelistitem" radio value="606" name="catid" checked={Number(catid) === 606} title="Magazines"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                            <Col width="15"></Col>
                        </Row>
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                            let tempstartdate   = new Date(startdate);
                            let tempenddate     = new Date(enddate);

                            if(tempenddate.getTime() < tempstartdate.getTime())
                            {
                                const toastWithButton = this.$f7.toast.create({
                                text: "Start date can't be less then End date",
                                closeButton: true,
                                closeTimeout: 3000,
                                });
                        
                                toastWithButton.open();
                        
                                return false;
                            }

                            Promise.all([
                                setTimeout(()=> {

                                  applyMonthlyBillOfNewspaperFilter(startdate, enddate, filtermonthyear, catid, catname);

                                }, 500)
                                
                            ])
                            .then(() => {
                
                                setTimeout(()=> {
                
                                this.$$('.popup-close').trigger('click');
                    
                                }, 500)
                                
                            })

                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}