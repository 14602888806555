import React,{Component} from 'react';

import {
    Fab,
    Icon,
  } from 'framework7-react';

  export default class AppFav extends Component {
    constructor(props) {
      super(props);

      this.state = {

      };
    }

    navigateToLink(link){
        
      if(this.$f7.online)
      {
        this.$f7.views.main.router.navigate(`/${link}/`);
      }

    }
    
    render(){

        const{position, text, icon, url} = this.props;

          return(
            <Fab position={position} slot="fixed" text={text} onClick={()=>{this.navigateToLink(url)}}>
                <Icon f7={icon}></Icon>
            </Fab>
          );

    }
}