import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Block,
    List,
    ListInput,
    ListItem,
    Toggle,
    Row,
    Col,
    Link
  } from 'framework7-react';

  export default class AddArea extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        phone:'',
        status:1,
        remarks:'',
        issmartdroppingpoint:false,
        ismount:false,
        droppingpointlist:[],
        colwidth:80,
        droppingpointid:'',
        droppingpointname:'Select',
        isonlinepayment:false,
        isinitialload:false,
      };

      this.handleUserInput = this.handleUserInput.bind(this);
      
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.getDetail();
        this.hideSplashScreen();
      });

      setTimeout(()=> {

        this.setState({ismount:true},()=>{
          this.onPageInit();
        });

      }, 1200);

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteDroppingPointPopup.destroy();

      this.setState(()=>{

        return{

          droppingpointid:'',
          droppingpointname:'',
          name:'',
          status:1,
          remarks:'',
          isonlinepayment:false,

        }

      })

    }

    onPageInit(){
      
      const self  = this;
      const app   = self.$f7;

      self.autocompleteDroppingPointPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-droppingpoint-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectdroppingpoint/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
              Mode:'GetDroppingPoint',
              clientid:self.state.clientdetail.id,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/droppingpoint.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
              Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    droppingpointlist:res.recordlist,
                  }
                },()=>{

                  const searchdroppingpointlist  = self.state.droppingpointlist;

                  for (let i = 0; i < searchdroppingpointlist.length; i += 1) {
                    if (searchdroppingpointlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchdroppingpointlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            self.setState({
              droppingpointid:value[0].id,
              droppingpointname:value[0].name,
              issmartdroppingpoint:true,
            },()=>{
              self.Dom7('.autocomplete-droppingpoint-popup').removeClass('error');
            });

          },
        },
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'isonlinepayment')
      {
        this.setState({isonlinepayment:!this.state.isonlinepayment});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(!this.$f7.online)
        {
          return;
        }

        const bodyFormData = {
            Mode:'GetAreaDetail',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.id,
        };

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
  
                name:resdata.areadetail.name,
                status:resdata.areadetail.status,
                remarks:resdata.areadetail.remark,
                droppingpointid:resdata.areadetail.droppingpointid,
                droppingpointname:resdata.areadetail.droppingpointname,
                isinitialload:true,
                isonlinepayment:Number(resdata.areadetail.isonlinepayment)

              });
            }
            else
            {
              this.setState({
  
                isinitialload:true

              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    saveDetail(){
      const self   = this;
      const app    = self.$f7;
      const $$     = self.Dom7;
      const router = self.$f7router;

      let errmsg    = "";

      if(!this.$f7.online)
      {
        return;
      }

      app.input.validateInputs('.adddata');

      if(this.state.droppingpointid === "" || this.state.droppingpointid === undefined)
      {
        errmsg  = "Please select a dropping point";
        this.$$('.autocomplete-droppingpoint-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-droppingpoint-popup').removeClass('error');
      }

      if(this.state.name === "" && errmsg === "")
      {
        errmsg  = "Please enter name";
      }      

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      const bodyFormData = {
          Mode:'EditArea',
          clientid:self.state.clientdetail.id,
          recordid:self.props.f7route.params.id,
          name:self.state.name,
          phone:self.state.phone,
          status:Number(self.state.status),
          remarks:self.state.remarks,
          droppingpointid:self.state.droppingpointid,
          isonlinepayment:Number(self.state.isonlinepayment),
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.toastmsg,
              closeButton: true,
              closeTimeout: 3000,
            });

            if(resdata.success !== true)
            {
              toastWithButton.open();
            }

            if(resdata.success === true)
            {
              router.navigate('/areas/');
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    fetchNewDroppingPoint(newdroppingpointid, newdroppingpointname){

      this.setState(()=>{

        return{

          droppingpointid:newdroppingpointid,
          droppingpointname:newdroppingpointname

        }

      })
    }

    render(){
       
      const {droppingpointname, colwidth, ismount, issmartdroppingpoint, isonlinepayment, isinitialload}  = this.state;

      return(
        <FilterConsumer>{(value)=>{
          const{setDroppingPointData}  = value;

          const{singledroppingpoint} = value;

          if(ismount === true)
          {
            /*setTimeout(()=> {

              if(isinitialload)
              {
                this.setState(()=>{
                  return{
                    isinitialload:false
                  }
                },()=>{
  
                  setDroppingPointData('', 'Select', false)
  
                })
  
              }

            }, 500)*/

            setTimeout(()=> {

              if(issmartdroppingpoint === true)
              {
                console.log(`I am here`);

                if((singledroppingpoint.id !== this.state.droppingpointid) && this.state.droppingpointid > 0)
                {
                  console.log(`I am here 2`);
                  Promise.all([

                    setDroppingPointData(Number(this.state.droppingpointid), this.state.droppingpointname, false)

                  ])
                  .then(() => {

                    console.log(`I am here 3`);
                    console.log(`${singledroppingpoint}`);

                    this.setState({

                      issmartdroppingpoint:false,
                    });
                  
                    //this.Dom7('#autocomplete-line-name2').text('Select');
                  })

                }

              }
              else
              {
                console.log(`I am here 4`);
                console.log(this.state.droppingpointid);
                console.log(singledroppingpoint);

                if((this.state.droppingpointid !== singledroppingpoint.id) && singledroppingpoint.id > 0)
                {
                  console.log(`I am here 5`);

                  this.fetchNewDroppingPoint(singledroppingpoint.id, singledroppingpoint.name);
                }
              }

            }, 700)
            
          }
        return(
          <Page pageContent={false} className="page" name="AddArea">
            <Navbar sliding={true}>
              <NavLeft>
                <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                <Link href={`/dashboard/`} className="clearmenupadding">
                  <img className="navbarlogo" src={scanlogo} alt="logo" />
                </Link>
              </NavLeft>
              <NavTitle>Edit Area</NavTitle>
              <NavRight className="right-with-menu">
              </NavRight>
            </Navbar>
            <PageContent>
            <Block>
              <Row>
                  <Col width={colwidth}>
                  <List noHairlinesMd className="zeromargin" style={{marginTop:'5px'}}>
                    <ListItem link="#" id="autocomplete-droppingpoint-popup" className="autocomplete-droppingpoint-popup" title="Dropping Point">
                      <div slot="after" id="autocomplete-droppingpoint-name2">{droppingpointname}</div>
                    </ListItem>
                  </List>
                  </Col>
                  {
                    <Col width="15" style={{marginTop:'8px',marginRight:'15px'}}>
                      <Link onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.$f7router.navigate('adddroppingpointpopup/')
                        }
                      }}>
                        <Icon ios="f7:plus" aurora="f7:plus" md="material:add_circle" style={{fontSize:'30px'}}></Icon>
                      </Link>
                    </Col>
                  }
              </Row>
              <List noHairlinesMd>
              
                  <ListInput
                      name="name"
                      className="adddata"
                      label="Name"
                      floatingLabel
                      type="text"
                      onInput={this.handleUserInput}
                      placeholder="Name"
                      value={this.state.name}
                      clearButton required validate
                  >
                  </ListInput>
                  <ListInput
                      name="remarks"
                      label="Remarks"
                      floatingLabel
                      type="textarea"
                      onInput={this.handleUserInput}
                      value={this.state.remarks}
                      resizable
                      placeholder="Remarks"
                  >
                  </ListInput>
                  <ListItem title="Status">
                    <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                  </ListItem>
                  <ListItem title="Can Accept Online Payment ?">
                    <Toggle slot="after" name="isonlinepayment" checked={Number(isonlinepayment) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                  </ListItem>
              </List>
            </Block>
            </PageContent>
            <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
          </Page>
        );
        }}</FilterConsumer>
      
     )
    }
}