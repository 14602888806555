	import React,{Component, Fragment} from 'react';
import AppFav from '../pages/appfav';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    List,
    ListInput,
    Button,
    Row,
    Col,
    Icon,
    Link,
    Block,
    BlockTitle,
    ListItem,
    SkeletonBlock,
  } from 'framework7-react';

  export default class CustomerDashboard extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          clientdetail:[],
          showprogress:true,
          effect: 'blink',
          isbannerloaded:false,
          isbetaaccount:0,
          searchkeyword:'',
          isavailable:false,
          loaded:false,
          lines:[],
          totalcustomer:'',
          isdashboardloaded:false,
          haslinelist:true,
        };

        this.handleUserInput    = this.handleUserInput.bind(this);
        this.getCustomerLine    = this.getCustomerLine.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          isbetaaccount:clientdetail.isbetaaccount
        }
      },()=>{
        Promise.all([
            this.hideSplashScreen()
          ])
          .then(() => {
    
            setTimeout(()=> {
              this.Dom7('.elevation-3').addClass('animate-bottom');
            }, 100);
    
          });
    
          this.getCustomerLine();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

        this.setState(()=>{
          return{
            [name]: value
          }
        });

    }

    getCustomerLine(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
  
        const bodyFormData = {
            Mode:'GetCustomerLine',
            clientid:this.state.clientdetail.id,
            linemanid:Number(this.state.clientdetail.linemanid),
            ismanager:Number(this.state.clientdetail.ismanager),
            areamanagerid:Number(this.state.clientdetail.areamanagerid),
            areaids:this.state.clientdetail.areaids,
        };

        this.setState({
          isdashboardloaded:false
        });
  
        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  loaded:true,
                  isavailable:true,
                  lines:resdata.recordlist,
                  totalcustomer:resdata.totalcustomer,
                  haslinelist:resdata.haslinelist,
                  isdashboardloaded:true,
                });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  totalcustomer:0,
                  isdashboardloaded:true
                });
              }
    
          }).catch(e => {

              console.log(e);

          });
  
        }, 500);
    }

    render(){

        const{searchkeyword, isavailable, loaded, lines, totalcustomer, isdashboardloaded, haslinelist} = this.state;

      return(
            <Page pageContent={false} name="Dashboard" className="customerdashboard page">
              <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Customer Dashboard</NavTitle>
                  <NavRight className="right-with-menu">
                    <Link onClick={()=>{

                      this.getCustomerLine();

                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
              </Navbar>
              <PageContent>
              {
                isdashboardloaded ? (
                  <Fragment>
                    <Block>
                        <Row>
                            <Col width="60">
                            <List noHairlinesMd className="searchwrapper">
                                <ListInput
                                    name="searchkeyword"
                                    className="searchbox"
                                    outline
                                    label="Keyword"
                                    floatingLabel
                                    type="text"
                                    placeholder="Keyword"
                                    onInput={this.handleUserInput}
                                    value={searchkeyword}
                                    clearButton
                                >
                                </ListInput>
                            </List>
                            </Col>
                            <Col width="40">
                                <Button fill color="blue" onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                        this.$f7router.navigate(`/managecustomers/${searchkeyword}/`);
                                    }
                                }}><Icon ios="f7:search" aurora="f7:search" md="material:search"></Icon> Search</Button>
                                <div style={{marginTop:'5px',textAlign:'center'}}>
                                <Link onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                        this.$f7router.navigate(`/managecustomers/advancesearch/`);
                                    }
                                }}>Advanced Search</Link>
                                </div>
                            </Col>
                        </Row>
                    </Block>
                    <Row>
                        <Col>
                          <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5" onClick={()=>{
                            if(this.$f7.online)
                            {
                                this.$f7router.navigate(`/managecustomers/`);
                            }
                            }} style={{color:'#2196f3'}}>
                              <div style={{fontSize:'3.5rem',fontWeight:'bold',paddingTop:'8px'}}>{totalcustomer}</div>
                              <div style={{fontSize:'1.8rem',marginTop:'12px'}}>Customers</div>
                          </div>
                        </Col>
                    </Row>
                    {
                        loaded ? (<Fragment>
                          {
                            isavailable ? (
                                <Block>
                                {
                                  haslinelist ? (<Fragment>
                                        <BlockTitle medium>Customers by Line</BlockTitle>
                                        <List noHairlinesMd>
                                        {
                                            lines.map((line)=>{
                                              return(
                                                <ListItem key={line.id} link="#" title={`${line.name} (${line.totalcustomer})`} onClick={()=>{
                                                    if(this.$f7.online)
                                                    {
                                                        this.$f7router.navigate(`/managecustomers/line/${line.id}/${line.name}/`);
                                                    }
                                                }}>
                                                    <div slot="after">View</div>
                                                </ListItem>
                                              );
                                            })
                                        }
                                      </List>
                                    </Fragment>):(
                                      <Fragment>
                                        <BlockTitle medium>Customers by Area</BlockTitle>
                                        <List noHairlinesMd>
                                        {
                                            lines.map((line)=>{
                                              return(
                                                <ListItem key={line.id} link="#" title={`${line.name} (${line.totalcustomer})`} onClick={()=>{
                                                    if(this.$f7.online)
                                                    {
                                                        this.$f7router.navigate(`/managecustomers/?areaid=${line.id}&filterarea=${line.name}`);
                                                    }
                                                }}>
                                                    <div slot="after">View</div>
                                                </ListItem>
                                              );
                                            })
                                        }
                                      </List>
                                    </Fragment>
                                    )
                                }
                              </Block>
                            ):null
                          }
                        </Fragment>):null
                    }
                  </Fragment>
                ):(
                  <Fragment>
                    <List noHairlinesMd className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                        <ListItem title="Title Title Title Title Title Title Title Title Title"></ListItem>
                    </List>
                    <Row>
                        <Col>
                          <div className={`elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5`} style={{padding:'0'}}>
                            <SkeletonBlock style={{ width: '100%', height: '95px', margin:'auto', borderRadius:'10px'}} slot="media" className={`skeleton-effect-${this.state.effect}`}/>
                            </div>
                        </Col>
                    </Row>
                    <AppListItemLoading />
                  </Fragment>
                )
              }
              <br/><br/>
              </PageContent>
              <AppFav position="center-bottom" text="Add Customer" icon="plus" url="addcustomer" />
            </Page>
        );
    }
}