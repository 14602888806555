import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Toolbar,
    Link,
    Tabs,
    Tab,
  } from 'framework7-react';

  export default class SendMessage extends Component {
    constructor(props) {
      super(props);

      this.state = {
      };

    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

          this.hideSplashScreen();

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    render(){
        
        return(
            <Page pageContent={false} className="page" name="SendMessage">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Send Message</NavTitle>
                  <NavRight>
                  </NavRight>
                </Navbar>
                <Toolbar tabbar bottom>
                    <Link tabLink href="./" routeTabId="invoicesms">Bill SMS</Link>
                    <Link tabLink href="generalsms/" routeTabId="generalsms">General SMS</Link>
                </Toolbar>
                <Tabs routable>
                    <Tab id="invoicesms" className="page-content" tabActive></Tab>
                    <Tab id="generalsms" className="page-content"></Tab>
                </Tabs>
                {/*<AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />*/}
            </Page>
      );
    }
}