import React,{Component,Fragment} from 'react';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Link,
    Block,
    Row,
    Col,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    Gauge,
    Toolbar,
    List,
    ListItem,
    ListInput,
    Fab,
    Toggle,
    Button,
  } from 'framework7-react';

  export default class Outstandingreport extends Component {
    constructor(props) {
      super(props);

      const today   = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
        currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
        currday = "0"+currday;
      }

      let startyear = today.getFullYear();
      let endyear   = today.getFullYear();

      let startmonth = currmonth-1;

      if(startmonth < 1)
      {
        startmonth  = 12;
        startyear   = Number(startyear - 1);
      }

      let startdate = startyear+'-'+Number(startmonth)+'-01';
      let enddate   = endyear+'-'+currmonth+'-'+currday;
      let paymentstartdate = endyear+'-'+currmonth+'-01';

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
     
      this.state = {
        clientdetail:[],
        isfetched:false,
        recordset:[],
        isptr:false,
        ismount:false,
        hasallcustomer:false,
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        hasfilter:true,
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        effect:'blink',
        toolbarPosition:'bottom',
        page:1,
        perpage:'',
        totalpages:'',
        showpages:false,
        paginglist:[],
        totalrecord:'',
        serialindex:1,
        totalrecord:0,
        monthyear:startdate,
        filtermonthyear:monthNames[startmonth-1]+' - '+startyear,
        monthyearlist:[],
        enddate:enddate,
        viewdetail:false,
        usefromdate:false,
        paymentstartdate:paymentstartdate,
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getOutstandingReport = this.getOutstandingReport.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getOutstandingReport();
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'viewdetail')
      {
        this.setState({
          viewdetail: !this.state.viewdetail
        })
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        },()=>{
  
          this.Paging('select');
  
        });
      }

    }

    getOutstandingReport(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
    
        const bodyFormData = {
          Mode:'GetOutstandingReport',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          lineid:self.state.lineid,
          linemanid:self.state.linemanid,
          hawkerid:self.state.hawkerid,
          areaid:self.state.areaid,
          page:this.state.page,
          perpage:this.state.perpage,
          serialindex:this.state.serialindex,
          monthyear:self.state.monthyear,
          filtermonthyear:self.state.filtermonthyear,
          enddate:self.state.enddate,
          viewdetail:Number(self.state.viewdetail),
          usefromdate:Number(self.state.usefromdate),
          paymentstartdate:self.state.paymentstartdate,
        };

        this.setState(()=>{

          return{
            isfetched:false
          }
          
        },()=>{

          setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                    isfetched:true,
                    recordset:resdata.recordset,
                    totalrecord:resdata.totalrecord,
                    hasrecord:true,
                    perpage:Number(resdata.perpage),
                    totalpages:Number(resdata.totalpages),
                    paginglist:resdata.paginglist,
                    showpages:resdata.showpages,
                    serialindex:resdata.serialindex,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });

              }
              else
              {
                this.setState({
                    isfetched:true,
                    recordset:[],
                    totalrecord:'0',
                    hasrecord:false,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        },500);

      });
    }

    getOutstandingReportPDF(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetOutstandingReportPDF',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          lineid:self.state.lineid,
          linemanid:self.state.linemanid,
          hawkerid:self.state.hawkerid,
          areaid:self.state.areaid,
          monthyear:self.state.monthyear,
          filtermonthyear:self.state.filtermonthyear,
          enddate:self.state.enddate,
          usefromdate:Number(self.state.usefromdate),
          paymentstartdate:self.state.paymentstartdate,
      };

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();                

              const resdata = JSON.parse(res.data);
              if(resdata.success === true)
              {
                  window.open(resdata.pdffilepath);
              }
              else
              {
                  const toastWithButton = app.toast.create({
                      text: resdata.msg,
                      closeButton: true,
                      closeTimeout: 3000,
                  });
                    
                  toastWithButton.open();    
              }
          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            areaid:areaid,
            lineid:lineid,
            linemanid:linemanid,
            hawkerid:hawkerid,
            filterarea:filterarea,
            filterline:filterline,
            filterlineman:filterlineman,
            filterhawker:filterhawker,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
            monthyear:monthyear,
            filtermonthyear:filtermonthyear,
            enddate:enddate,
            usefromdate:usefromdate,
            paymentstartdate:paymentstartdate,
          }
  
        },()=>{
  
          if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0 || (this.state.monthyear !== "" && this.state.monthyear !== undefined) || (this.state.enddate !== "" && this.state.enddate !== undefined) || (Number(this.state.usefromdate) > 0 && (this.state.paymentstartdate !== "" && this.state.paymentstartdate !== undefined)))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getOutstandingReport();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    Paging(mode){

      let pageerror = "";

      if(!this.$f7.online)
      {
        return;
      }

      let temppage    = '';

      const currpage  = this.state.page;

      if(mode === 'previous')
      {
        temppage  = Number(this.state.page) - 1;
      }
      if(mode === 'next')
      {
        temppage  = Number(this.state.page) + 1;
      }
      if(mode === 'select')
      {
        temppage  = Number(this.state.page);
      }

      if(temppage < 1)
      {
        pageerror = "You are on first page";
      }

      if(temppage > Number(this.state.totalpages))
      {
        pageerror = "You are on last page";
      }

      if(pageerror !== "")
      {
        const toastWithButton = this.$f7.toast.create({
          text: pageerror,
          closeButton: true,
          closeTimeout: 3000,
        });
            
        toastWithButton.open();
        
        return;
      }

      if(temppage <= 0)
      {
        temppage  = 1;
      }

      if(temppage >= Number(this.state.totalpages))
      {
        temppage  = Number(this.state.totalpages);
      }

      this.setState(()=>{

        return{
          page:temppage,
          loaded:false
        }

      },()=>{

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          if(mode === 'select')
          {
            this.getOutstandingReport();
          }
          else
          {
            if(Number(this.state.page) !== Number(currpage))
            {
              this.getOutstandingReport();
            }
          }

        })

      })

    }

    render(){
        
      const{isfetched, recordset, hasallcustomer, areaid, lineid, linemanid, hawkerid, hasfilter, filterarea, filterline, filterlineman, filterhawker, removefiltername, applyremovefilter, ismount, hasrecord, paginglist, showpages, page, totalpages, totalrecord, filtermonthyear, monthyear, enddate, usefromdate, paymentstartdate} = this.state;

      let dateend     = "";
      let newenddate  = "";

      if(enddate !== "" && enddate !== undefined)
      {
        newenddate = new Date(enddate);

        let currmonth = newenddate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
      }

      let date_paymentstartdate   = "";
      let new_paymentstartdate    = "";

      if(paymentstartdate !== "" && paymentstartdate !== undefined && Number(usefromdate) > 0)
      {
        new_paymentstartdate = new Date(paymentstartdate);

        let currmonth = new_paymentstartdate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        date_paymentstartdate = new_paymentstartdate.getDate()+"-"+currmonth+"-"+new_paymentstartdate.getFullYear();
      }

      return(
        <Page className="page" name="Outstandingreport">
        <Navbar sliding={true}>
          <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
            <Link href={`/dashboard/`} className="clearmenupadding">
              <img className="navbarlogo" src={scanlogo} alt="logo" />
            </Link>
          </NavLeft>
          <NavTitle>Outstanding (Detail)</NavTitle>
          <NavRight className="right-with-menu">
          <Link onClick={()=>{

            this.getOutstandingReport();

          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
          </NavRight>
        </Navbar>
      <FilterConsumer>{(value)=>{

        const{billoutstandingfilterdata, applyOutstandingFilter} = value;

        if(ismount === true)
        {
          setTimeout(()=> {

            if(removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(removefiltername === 'area')
              {
                applyOutstandingFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, '', '', monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate);
              }
              else if(removefiltername === 'line')
              {
                applyOutstandingFilter('', linemanid, hawkerid, '', filterlineman, filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate);
              }
              else if(removefiltername === 'lineman')
              {
                applyOutstandingFilter(lineid, '', hawkerid, filterline, '', filterhawker, areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate);
              }
              else if(removefiltername === 'hawker')
              {
                applyOutstandingFilter(lineid, linemanid, '', filterline, filterlineman, '', areaid, filterarea, monthyear, filtermonthyear, enddate, usefromdate, paymentstartdate);
              }
            }

            if(((billoutstandingfilterdata.lineid !== lineid) && billoutstandingfilterdata.lineid !== undefined) || ((billoutstandingfilterdata.linemanid !== linemanid) && billoutstandingfilterdata.linemanid !== undefined) || ((billoutstandingfilterdata.hawkerid !== hawkerid) && billoutstandingfilterdata.hawkerid !== undefined) || ((billoutstandingfilterdata.areaid !== areaid) && billoutstandingfilterdata.areaid !== undefined) || ((billoutstandingfilterdata.monthyear !== monthyear) && billoutstandingfilterdata.monthyear !== undefined) || ((billoutstandingfilterdata.enddate !== enddate) && billoutstandingfilterdata.enddate !== undefined) || ((billoutstandingfilterdata.usefromdate !== usefromdate) && billoutstandingfilterdata.usefromdate !== undefined) || ((billoutstandingfilterdata.paymentstartdate !== paymentstartdate) && billoutstandingfilterdata.paymentstartdate !== undefined) || applyremovefilter === true)
            {
              this.setNewFilterData(billoutstandingfilterdata.lineid, billoutstandingfilterdata.linemanid, billoutstandingfilterdata.hawkerid, billoutstandingfilterdata.filterline, billoutstandingfilterdata.filterlineman, billoutstandingfilterdata.filterhawker, billoutstandingfilterdata.areaid, billoutstandingfilterdata.filterarea, billoutstandingfilterdata.monthyear, billoutstandingfilterdata.filtermonthyear, billoutstandingfilterdata.enddate, billoutstandingfilterdata.usefromdate, billoutstandingfilterdata.paymentstartdate);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }

          }, 500)
        }
        
      return(
            <Block>
              {
                hasfilter ? (
                  <Block strong className="selectedfilter" style={{marginTop:0}}>
                    {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                    {filtermonthyear ? (<Chip text={`Bill Month : ${filtermonthyear}`} mediaBgColor="green" media={filtermonthyear.toUpperCase().substring(0, 1)}/>):null}
                    {usefromdate ? (<Chip text={`Payment Start Date : (${date_paymentstartdate})`} mediaBgColor="yellow" media={`S`}/>):null}
                    {dateend ? (<Chip text={`Payment Upto Date : (${dateend})`} mediaBgColor="red" media={`E`}/>):null}
                    {Number(areaid) > 0 && filterarea !== "" ? (<Chip text={`Area : ${filterarea}`} mediaBgColor="pink" media={filterarea.toUpperCase().substring(0, 1)} deleteable
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'area'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(lineid) > 0 && filterline !== "" ? (<Chip text={`Line : ${filterline}`} mediaBgColor="pink" media={filterline.toUpperCase().substring(0, 1)} deleteable
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'line'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {filterlineman ? (<Chip text={`Lineman : ${filterlineman}`} mediaBgColor="green" media={filterlineman.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'lineman'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    {Number(hawkerid) > 0 && filterhawker !== "" ? (<Chip text={`Hawker : ${filterhawker}`} mediaBgColor="orange" media={filterhawker.toUpperCase().substring(0, 1)} deleteable 
                    onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.setState(()=>{
                                return {
                                  removefiltername:'hawker'
                                }
                              },()=>{
                                this.refs.actionsFilterGroup.open()
                              })
                            }
                          }}
                    />):null}
                    &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                        if(this.$f7.online)
                        {
                          this.$f7.views.main.router.navigate(`filterpopup/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&monthyear=${monthyear}&filtermonthyear=${filtermonthyear}&enddate=${enddate}&usefromdate=${Number(usefromdate)}&paymentstartdate=${paymentstartdate}`);
                        }
                      /*this.openFilterPopup();*/
                    }}/>
                  </Block>
                ):null
              }

              {
                  isfetched ? (
                      <div className="outstandingwrapper">
                          <Row>
                              <Col className="text-align-center">
                                  <Gauge
                                  type="circle"
                                  value={recordset.graphpayments}
                                  valueText={recordset.remaingbalance}
                                  valueTextColor="#000"
                                  borderColor="#ff9800"
                                  labelText={`of ${recordset.graphoutstanding} outstanding`}
                                  labelTextColor="#4caf50"
                                  labelFontWeight={800}
                                  labelFontSize={12}
                                  borderWidth={20}
                                  />
                              </Col>
                          </Row>
                          <br />
                          <Row>
                              <Col width={60}>
                                  <b>Previous Balance</b>
                              </Col>
                              <Col width={40}>
                                  <b>{recordset.openingbalance}</b>
                              </Col>
                          </Row>
                          <br />
                          <Row>
                              <Col width={60}>
                                  <b>Total Bill Amount</b>
                              </Col>
                              <Col width={40}>
                                  <b>{recordset.totalinvoicebalance} ({recordset.totalinvoice})</b>
                              </Col>
                          </Row>
                          <br />
                          <Row>
                              <Col width={60}>
                                  <b>Sub Total</b>
                              </Col>
                              <Col width={40}>
                                  <b>{recordset.subtotalsummary}</b>
                              </Col>
                          </Row>
                          <br />
                          <Row>
                              <Col width={60}>
                                  <b>Total Payments</b>
                              </Col>
                              <Col width={40}>
                                  <b>{recordset.totalpayments} ({recordset.totalpaymentcount})</b>
                              </Col>
                          </Row>
                          <br />
                          <Row>
                              <Col width={60}>
                                  <b>Outstanding Balance</b>
                              </Col>
                              <Col width={40}>
                                  <b>{recordset.outstandingbalance} {/*({recordset.totalcustomer})*/}</b>
                              </Col>
                          </Row>
                          <br />
                          <Row>
                              <Col width={60}>
                                  <b>% of recovery done</b>
                              </Col>
                              <Col width={40}>
                                  <b>{recordset.recoverydoneprecent} %</b>
                              </Col>
                          </Row>
                          <br />
                          {
                            !this.state.viewdetail ? (
                              <Row style={{width:'100%'}}>
                                  <Col width="40"></Col>
                                  <Col width="60"><Button fill color="blue" style={{marginRight:'.4rem'}}
                                  onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                      this.setState({
                                        viewdetail:true
                                      },()=>{
                                        this.getOutstandingReport();
                                      })
                                    }
                                  }}
                                  ><Icon f7="doc_on_clipboard_fill" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;View Detail</Button></Col>
                              </Row>
                            ):null
                          }
                          {
                            Number(this.state.viewdetail) > 0 && recordset.outstandingdetail ? (<Fragment>
                              <br />
                              <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center',fontSize:'1.2rem'}}>Total Outstanding : {totalrecord}</BlockTitle>
                              <BlockTitle medium style={{marginTop:'10px',marginBottom:'10px',textAlign:'center'}}>Detail</BlockTitle>
                              <Row noGap className='rowwithbottomborder'>
                                  <Col width='15' className="tableheader">
                                      S.No.
                                  </Col>
                                  <Col width='45' className="tableheader textalignleft">
                                      Customer
                                  </Col>
                                  <Col width='30' className="tableheader textalignright">
                                      Amount
                                  </Col>
                                  <Col width='10' className="tableheader textalignright">
                                      &nbsp;
                                  </Col>
                              </Row>
                              {
                                recordset.outstandingdetail.map((detail)=>{

                                  return(
                                    <Row key={detail.id} noGap className='rowwithbottomborder' onClick={()=>{this.navigateToLink(`customerdetail/${detail.id}`)}}>
                                        <Col width='15' className="ledgercolumn textalignleft">
                                          {detail.serialno}
                                        </Col>
                                        <Col width='45' className="ledgercolumn textalignleft">
                                          {detail.name}
                                        </Col>
                                        <Col width='30' className="ledgercolumn textalignright">
                                          {detail.amount}
                                        </Col>
                                        <Col width='10' className="ledgercolumn">
                                          <Icon f7="chevron_right"></Icon>
                                        </Col>
                                    </Row>
                                    );
                                })
                              }
                            </Fragment>):null
                          }
                      </div>
                  ):(
                      <div className={`skeleton-text skeleton-effect-${this.state.effect}`}>
                          <BlockTitle className="totalrec">Total Opening Balance : xxxx.xx</BlockTitle>
                          <br />
                          <Row>
                              <Col className="text-align-center">
                                  <Gauge
                                  type="circle"
                                  value=""
                                  valueText=""
                                  valueTextColor="#000"
                                  borderColor="#ff9800"
                                  labelText=""
                                  labelTextColor="#4caf50"
                                  labelFontWeight={800}
                                  labelFontSize={12}
                                  borderWidth={20}
                                  />
                              </Col>
                          </Row>
                          <br />
                          <Row>
                              <Col width={70}>
                                  <b>Total Customer</b>
                              </Col>
                              <Col width={30}>
                                  <b>xxxx.xx</b>
                              </Col>
                          </Row>
                          <br />
                          <Row>
                              <Col width={70}>
                                  <b>Total Bill</b>
                              </Col>
                              <Col width={30}>
                                  <b>xxxx.xx</b>
                              </Col>
                          </Row>
                          <br />
                          <Row>
                              <Col width={70}>
                                  <b>Total Payments</b>
                              </Col>
                              <Col width={30}>
                                  <b>xxxx.xx</b>
                              </Col>
                          </Row>
                          <br />
                          <Row>
                              <Col width={70}>
                                  <b>Outstanding Balance</b>
                              </Col>
                              <Col width={30}>
                                  <b>xxxx.xx</b>
                              </Col>
                          </Row>
                      </div>
                  )
              }
              <br /><br /><br />
            </Block>
          );

        }}
        </FilterConsumer>
          {
            Number(this.state.viewdetail) > 0 && hasrecord  && showpages ? (
              <Toolbar position={this.state.toolbarPosition}>
              {
                (()=>{
                  if(Number(page) > 1)
                  {
                    return(
                      <Link className="paging" onClick={()=>{this.Paging('previous')}}>
                        <Icon f7="arrow_left_square_fill" ></Icon>
                      </Link>
                    );
                  }
                  else
                  {
                    return(
                      <Link href="#"></Link>
                    );
                  }
                })()
              }
                <List className="droppage" noHairlines>
                    <ListInput
                        name="page"
                        type="select"
                        value={this.state.page}
                        onChange={this.handleUserInput}
                        placeholder="Please choose..."
                        >
                        {
                          paginglist.map((pages)=>{
                          return(<option key={pages.index} value={pages.page}>{pages.name}</option>);
                          })
                        }
                    </ListInput>
                </List>
                {
                  (()=>{
                    if(Number(page) < Number(totalpages))
                    {
                      return(
                        <Link className="paging" onClick={()=>{this.Paging('next')}}>
                        <Icon f7="arrow_right_square_fill" ></Icon>
                        </Link>
                      );
                    }
                    else
                    {
                      return(
                        <Link href="#"></Link>
                      );
                    }
                  })()
                }
              </Toolbar>
            ):null
          }
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {
            hasrecord ? (
                <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                    if(this.$f7.online)
                    {
                      this.getOutstandingReportPDF();
                    }
                  }}>
                  <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                  <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                </Fab>
            ):null
          }
        </Page>

      );
    }
}