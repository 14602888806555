import React,{Component, Fragment} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Fab,
    Icon,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    List,
    ListItem,
    Block,
    Row,
    Col,
    ListInput,
    Button,
    Toolbar,
    Toggle,
    Link
  } from 'framework7-react';

  export default class Settings extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        profiledetail:[],
        clientname:'',
        isservicecharge:0,
        servicechargetype:0,
        servicecharge:'',
      };

      this.getSettingDetail     = this.getSettingDetail.bind(this);
      this.handleUserInput      = this.handleUserInput.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getSettingDetail();
        this.hideSplashScreen();

      })

      const app = this.$f7;
      const $$  = this.Dom7;
      const ptrContent = $$('.ptr-content');

      // Add 'refresh' listener on it
      ptrContent.on('ptr:refresh', (e)=> {
        // Emulate 2s loading

        setTimeout(()=> {
      
          this.getSettingDetail();
      
          app.ptr.done(); // or e.detail();
        }, 2000);

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'isservicecharge')
      {
        this.setState({isservicecharge:!this.state.isservicecharge});
      }
      else if(name === 'servicechargetype')
      {
        this.setState({servicechargetype:!this.state.servicechargetype});
      }
      else
      {
        this.setState(()=>{

            return{
              [name]: value
            }

          });
      }
    }

    check_email(elementValue){
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(elementValue);
    }

    getSettingDetail(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'GetSettingDetail',
        recordid:self.state.clientdetail.id,
      };

      const accesstoken  = localStorage.getItem('agency_accesstoken');
          
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/settings.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

          const resdata = JSON.parse(res.data);

          if(resdata.success === true)
          {
            this.setState({
              isservicecharge:resdata.settings.isservicecharge,
              servicechargetype:resdata.settings.servicechargetype,
              servicecharge:resdata.settings.servicecharge,
            });
          }

      }).catch(e => {
          console.log(e);
      });
    }

    saveSettingDetail(){
      
      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      if(Number(this.state.isservicecharge) > 0 )
      {
          if(Number(this.state.servicecharge) > 0)
          {
          }
          else
          {
            const toastWithButton = app.toast.create({
            text: 'Please enter extra charges.',
            closeButton: true,
            closeTimeout: 3000,
            });

            toastWithButton.open();
            return false;
          }
      }

      const bodyFormData = {
          Mode:'SaveSettingDetail',
          recordid:self.state.clientdetail.id,
          isservicecharge:Number(self.state.isservicecharge),
          servicechargetype:Number(self.state.servicechargetype),
          servicecharge:self.state.servicecharge,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/settings.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
  
            const resdata = JSON.parse(res.data);

            const toastWithButton2 = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton2.open();

            if(resdata.success === true)
            {
              /*app.dialog.alert(resdata.msg);*/
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 1000);

    }

    render(){

        const{isservicecharge, servicechargetype, servicecharge} = this.state;
       
      return(
            <Page pageContent={false} className="page" name="Settings">
              <PageContent className="ptr-content">
                  <Navbar>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Settings</NavTitle>
                    <NavRight className="right-with-menu">
                    </NavRight>
                  </Navbar>
                  <div className="ptr-preloader">
                    <div className="preloader"></div>
                    <div className="ptr-arrow"></div>
                  </div>
                  <Block>
                  <List noHairlinesMd>
                      <ListItem title="Apply Service Charges">
                        <Toggle slot="after" value="1" name="isservicecharge" onChange={this.handleUserInput} checked={Number(isservicecharge) === 1}></Toggle>
                      </ListItem>
                  </List>
                    {(()=>{
                        if(isservicecharge > 0)
                        {
                            return(
                            <Fragment>
                                <div style={{marginLeft:'1.2rem',marginBottom:'1rem'}}>Charges Type</div>
                                <Row noGap>
                                    <Col width="40">
                                        <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                            <ListItem radio value="0" name="servicechargetype" title="Flat Rupee" checked={Number(servicechargetype) === 0}
                                            onChange={this.handleUserInput}
                                            ></ListItem>
                                        </List>
                                    </Col>
                                    <Col width="60">
                                        <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                            <ListItem radio value="1" name="servicechargetype" title="Percentage" checked={Number(servicechargetype) === 1}
                                            onChange={this.handleUserInput}
                                            ></ListItem>
                                        </List>
                                    </Col>
                                </Row>
                            </Fragment>
                            )
                        }
                    })()}
                    {(()=>{
                        if(isservicecharge > 0 && Number(servicechargetype) > 0)
                        {
                            return(
                                <Row>
                                    <Col width="40">
                                    </Col>
                                    <Col width="60">
                                        <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                                            <ListInput
                                                className="smallinputnumber"
                                                name="servicecharge"
                                                label="Service Charge (%)"
                                                floatingLabel
                                                type="number"
                                                onInput={this.handleUserInput}
                                                placeholder="Service Charge (%)"
                                                value={servicecharge}
                                                clearButton validate
                                            >
                                            </ListInput>
                                        </List>
                                    </Col>
                                </Row>
                            );
                        }
                        else if(isservicecharge > 0 && Number(servicechargetype) < 1)
                        {
                            return(
                                <Row>
                                    <Col width="40">
                                    </Col>
                                    <Col width="60">
                                        <List noHairlinesMd style={{marginBottom:'5px',marginTop:'5px'}}>
                                            <ListInput
                                                className="smallinputnumber"
                                                name="servicecharge"
                                                label="Service Charge (Rs.)"
                                                floatingLabel
                                                type="number"
                                                onInput={this.handleUserInput}
                                                placeholder="Service Charge (Rs.)"
                                                value={servicecharge}
                                                clearButton validate
                                            >
                                            </ListInput>
                                        </List>
                                    </Col>
                                </Row>
                            );
                        }
                    })()}
                  </Block>
                  <br /><br />
              </PageContent>
              <AppSaveRecordButton saveDetail={this.saveSettingDetail.bind(this)} />
            </Page>
      );
    }
}