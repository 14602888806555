import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    LoginScreen,
    LoginScreenTitle,
    ListInput,
    BlockFooter,
    List,
    ListItem,
    ListButton,
    Button,
    Popup,
    Navbar,
    NavRight,
    NavLeft,
    NavTitle,
    Link,
    Block,
    Row,
    Col,
    View
  } from 'framework7-react';

  export default class ForgetPassword extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        phone: '',
        password: '',
        forgetphone:'',
        logintype:0,
        passwordrecoverytype:0,
      };

      this.handleUserInput = this.handleUserInput.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      alert(name)
      this.setState(()=>{
        return{
          [name]: value
        }
      });
    }

    sendPasswordRecoverMessage(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(!this.$f7.online)
        {
          return;
        }

        if(self.state.forgetphone === '')
        {
            const toastWithButton = app.toast.create({
                text: `Please enter your mobile number to get password`,
                closeButton: true,
                closeTimeout: 3000,
            });
              
            toastWithButton.open();

            app.input.validateInputs('.forgetpassphone');
            
            return;
        }
        else
        {
          const bodyFormData = {
            Mode:'RecoverPassword',
            forgetphone:self.state.forgetphone,
            logintype:self.state.passwordrecoverytype,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');
        
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/recoverpassword.php?t=${Date.now()}`,bodyFormData)
            .then(function (res) {

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                // Create notification with close button
                let notificationWithButton = app.notification.create({
                    icon: '',
                    title: 'OrloPay',
                    subtitle: 'Success',
                    text: resdata.msg,
                    closeButton: true,
                });

                notificationWithButton.open();

                router.back();
              }
              else
              {
                // Create notification with close button
                let notificationWithButton = app.notification.create({
                    icon: '',
                    title: 'OrloPay',
                    subtitle: 'Error',
                    text: resdata.msg,
                    closeButton: true,
                });

                notificationWithButton.open();
              }

            }).catch(e => {
              console.log(e);
          });
        }
    }

    render(){

        return(
            <Popup className="demo-popup">
                <Navbar sliding={true}>
                <NavLeft>
                    <Link popupClose>
                    <i className="icon icon-back"></i>                      
                    </Link>
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                </NavLeft>
                <NavTitle>Forget Password</NavTitle>
                </Navbar>
                <Page loginScreen>
                    <Row>
                        <Col>
                            <img className="loginlogo" src={scanlogo} alt="logo" />
                        </Col>
                    </Row>
                    <LoginScreenTitle>Password Recovery</LoginScreenTitle>
                    <Row noGap>
                        <Col width="10"></Col>
                        <Col width="80">
                            <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListInput
                                    name="passwordrecoverytype"
                                    label="User Type"
                                    type="select"
                                    onChange={this.handleUserInput}
                                    value={this.state.passwordrecoverytype}
                                    placeholder="Please choose..."
                                    required validate
                                    >
                                    <option value="0">Owner</option>
                                    <option value="2">Area Manager</option>
                                    <option value="1">Lineman</option>
                                    <option value="3">Hawker</option>
                                </ListInput>
                            </List>
                        </Col>
                        <Col width="10"></Col>
                    </Row>
                    <Row noGap>
                        <Col width="10"></Col>
                        <Col width="80">                        
                            <List form>
                                <ListInput
                                className="forgetpassphone"
                                label="Mobile"
                                floatingLabel
                                type="tel"
                                placeholder="Mobile Number"
                                value={this.state.forgetphone}
                                onInput={(e)=>{
                            
                                  let tempphone = e.target.value;
                                  let mobile    = tempphone.replace(/ /g,'');
                                  
                                  if(tempphone.slice(0,1)=='+')
                                  {
                                    mobile = mobile.substring(3);
                                  }
      
                                  if(tempphone.slice(0,1)=='0')
                                  {
                                    mobile = mobile.substring(1);
                                  }
      
                                  if(mobile.length > 10)
                                  {
                                    return;
                                  }
                                  
                                  this.setState({
                                    forgetphone:mobile
                                  })
                                }}
                                required validate
                                />
                            </List>
                            <List>
                                <Row>
                                    <Col width="20"></Col>
                                    <Col width="60"><Button fill color="blue" onClick={this.sendPasswordRecoverMessage.bind(this)}>Recover Password</Button></Col>
                                    <Col width="20"></Col>
                                </Row>
                            </List>
                        </Col>
                        <Col width="10"></Col>
                    </Row>
                </Page>
            </Popup>
        );
    }
}