import React,{Component, Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Toolbar,
    Tabs,
    Tab,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Link,
    Block,
    List,
    ListInput,
    ListItem,
    Button,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    Chip,
    Toggle,
  } from 'framework7-react';

  export default class GenerateInvoice extends Component {
    constructor(props) {
      super(props);

      let today = new Date();

      let currmonth = today.getMonth()+1;
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }

      let currdate = today.getDate();
      if(currdate < 10)
      {
          currdate = "0"+currdate;
      }

      let date = today.getFullYear()+'-'+currmonth+'-'+currdate;
  
      this.state = {
        clientdetail:[],
        invoiceyear:[],
        invoicemonth:[],
        inventorylist:[],
        hasmonth:false,
        year:'',
        month:'',
        monthname:'',
        monthcount:'',
        monthhasinvoice:false,
        popupOpened:false,
        isptr:false,
        bypass:0,
        toolbarPosition:'top',
        totaldays:"",
        ismonthdataloaded:false,
        isrequestdone:false,
        effect:'blink',
        billdate:date,
        totalamount:0,
        totaloutstanding:0,
        inactiveoutstanding:0,
        customercount:0,
        cansendsms:true,
        hasinvoicerequestinqueue:false,
      };

      this.handleUserInput          = this.handleUserInput.bind(this);
      this.handleInventoryInput     = this.handleInventoryInput.bind(this);
      this.handleInventoryDatePrice = this.handleInventoryDatePrice.bind(this);
      this.saveInventoryPricing     = this.saveInventoryPricing.bind(this);
      this.handlePricingType        = this.handlePricingType.bind(this);
      this.updatePrice              = this.updatePrice.bind(this);
      this.generateInvoices         = this.generateInvoices.bind(this);
      this.reGenerateInvoiceQueue   = this.reGenerateInvoiceQueue.bind(this);
      this.createInvoices           = this.createInvoices.bind(this);
      this.navigateToLink           = this.navigateToLink.bind(this);
      this.deleteInvoices           = this.deleteInvoices.bind(this);
      this.sendInvoiceSMS           = this.sendInvoiceSMS.bind(this);
      this.stopInvoicesGeneration   = this.stopInvoicesGeneration.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getInvoiceYear();
        /*this.getActiveInventoryDetail();*/
        this.hideSplashScreen();

      });

    }

    componentWillReceiveProps(){
      if(this.props.reload === true)
      {
        Promise.all([this.getInvoiceYear()])
        .then(() => {
          if(this.state.year !== "")
          {
            this.getInvoiceMonth();
            /*this.getActiveInventoryDetail();*/
          }
    	  })
      } 
    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    getInvoiceYear(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetInvoiceYear',
      };

      /*if(this.state.isptr !== true)
      {
        // Simulate Ajax Request
        app.preloader.show();
      }*/

      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData).then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();
  
            const resdata = JSON.parse(res.data);

            if(resdata.success === true)
            {
              this.setState(()=>{
                return{
                  invoiceyear:resdata.recordset,
                  year:resdata.defaultyear,
                  isptr:false
                }
              },()=>{
                this.getInvoiceMonth();
              });
            }
            else
            {
              this.setState({
                isptr:false
              });
            }
  
          }).catch(e => {
            console.log(e);
          });

      }, 500);
    }

    getInvoiceMonth(){

      const self      = this;
      const app       = self.$f7;

      this.setState({
        isrequestdone:false
      })

      const bodyFormData = {
        Mode:'GetInvoiceMonthByYear',
        year:self.state.year,
        clientid:self.state.clientdetail.id,
      };

      /*app.preloader.show();*/

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData).then((res)=> {

            // Hide Preloader
            app.preloader.hide();
  
            const resdata = JSON.parse(res.data);

            if(resdata.success === true)
            {
              this.setState({
                invoicemonth:resdata.recordset,
                totalamount:resdata.totalamount,
                totaloutstanding:resdata.totaloutstanding,
                inactiveoutstanding:resdata.inactiveoutstanding,
                customercount:resdata.customercount,
                hasmonth:true,
                isrequestdone:true,
              });
            }
            else
            {
              this.setState({
                hasmonth:false,
                isrequestdone:false,
                totalamount:0,
                totaloutstanding:0,
                inactiveoutstanding:0,
                customercount:0,
              });
            }
  
          }).catch(e => {
            console.log(e);
          });

      }, 500);
    }

    getActiveInventoryDetail(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
        Mode:'GetSubscribeInventory',
        clientid:self.state.clientdetail.id,
        stateid:self.state.clientdetail.stateid,
        cityid:self.state.clientdetail.cityid,
        year:self.state.year,
        month:self.state.month,
      };

      // Simulate Ajax Request

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              // Hide Preloader
              app.preloader.hide();

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({

                    inventorylist:resdata.inventorylist,
                    totaldays:resdata.totaldays,
                    isptr:false,
                    ismonthdataloaded:true,

                });
              }
              else
              {
                this.setState({

                  isptr:false,
                  ismonthdataloaded:true,

              });
              }

          }).catch(e => {
              console.log(e);
          });

      },500);
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'cansendsms')
      {
        this.setState({cansendsms:!this.state.cansendsms});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        },()=>{
          if(name === "year")
          {
            this.getInvoiceMonth();
            /*this.getActiveInventoryDetail();*/
          }
        });
      }
    }

    handlePricingType(catid, inventoryid, value){

      let tempInventoryList       = [...this.state.inventorylist];
      const tempInventoryList2    = tempInventoryList.find(category => Number(category.id) === Number(catid));
      const tempInventory         = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(inventoryid));

      tempInventory.pricingtype  = value;

      this.setState(()=>{
        
        return{

          inventorylist: tempInventoryList

        }

      });

    }

    handleInventoryInput(e){

        const value = e.target.value;

        const catid         = e.target.getAttribute('catid');
        const inventoryid   = e.target.getAttribute('inventoryid');
        const fieldname     = e.target.getAttribute('fieldname');

        let tempInventoryList       = [...this.state.inventorylist];
        const tempInventoryList2    = tempInventoryList.find(category => Number(category.id) === Number(catid));
        const tempInventory         = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(inventoryid));

        tempInventory[fieldname]  = value;

        this.setState(()=>{
          
          return{
            inventorylist: tempInventoryList
          }

        });

    }

    handleInventoryDatePrice(e){

      const value = e.target.value;

      const catid         = e.target.getAttribute('catid');
      const inventoryid   = e.target.getAttribute('inventoryid');
      const date          = e.target.getAttribute('date');
      const fieldname     = e.target.getAttribute('fieldname');

      let tempInventoryList       = [...this.state.inventorylist];
      const tempInventoryList2    = tempInventoryList.find(category => Number(category.id) === Number(catid));
      const tempInventoryList3    = tempInventoryList2.recordlist.find(inventory => Number(inventory.id) === Number(inventoryid));
      const tempInventory         = tempInventoryList3.datepricing.find(pricing => Number(pricing.date) === Number(date));

      tempInventory[fieldname]  = value;

      this.setState(()=>{
        
        return{
          inventorylist: tempInventoryList
        }

      });

    }

    reGenerateInvoiceQueue(objmonth,objyear){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const toastWithButton = app.toast.create({
        text: 'Unable to process your request, Please try latter.',
        closeButton: true,
        closeTimeout: 3000,
      });
      app.input.validateInputs('.adddata');

      if(objmonth === "" || objyear === "")
      {
        toastWithButton.open();
        return false;
      }

      const bodyFormData = {
          Mode:'ReGenerateInvoiceRequest',
          clientid:self.state.clientdetail.id,
          bypass:self.state.bypass,
          month:objmonth,
          year:objyear,
          cansendsms:Number(self.state.cansendsms),
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
 
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            if(resdata.pricingerror == true)
            {
              app.dialog.create({
                title: 'Warning',
                text: resdata.msg,
                closeByBackdropClick: 'true',
                buttons: [
                  {
                    text: 'Cancel',
                  },
                  {
                    text: 'Update Pricing',
                    onClick: () => {
                      /*this.updatePrice();*/
                      this.navigateToLink(`updatemonthprice/${this.state.year}/${this.state.month}/${this.state.monthname}`);
                    },
                  },
                ],
              }).open();
            }
            else if(resdata.openingbalanceerror == true)
            {
              app.dialog.create({
                title: 'Warning',
                text: resdata.msg,
                closeByBackdropClick: 'true',
                buttons: [
                  {
                    text: 'Proceed',
                    onClick: () => {

                      this.setState(()=>{

                        return{
                          bypass:1,
                        }
                
                      },()=>{
                        this.reGenerateInvoiceQueue(objmonth,objyear);
                      })
                    },
                  },
                  {
                    text: 'Update Balance',
                    onClick: () => {
                      /*this.updatePrice();*/
                      if(this.$f7.online)
                      {
                        this.$f7router.navigate(`/managecustomers/?openingbalanceid=3&filteropeningbalance=No Entry`);
                      }
                    },
                  },
                ],
              }).open();
            }
            else
            {
              const toastWithButton = app.toast.create({
                text: resdata.msg,
                closeButton: true,
                closeTimeout: 3000,
              });

              toastWithButton.open();
              this.setState(()=>{

                return{
                  bypass:0,
                }
        
              },()=>{
                  if(resdata.success === true)
                  {
                    if(resdata.canswitchrun === true)
                    {
                      app.tab.show("#tab-2");
                    }
                    
                    this.getInvoiceMonth();
                  }
                  else
                  {
                    app.tab.show("#tab-2");
                  }
                }
              )
            }
            //toastWithButton.open();

        }).catch(e => {
            console.log(e);
        });

      }, 500); 
    }

    generateInvoices(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const toastWithButton = app.toast.create({
        text: 'Unable to process your request, Please try latter.',
        closeButton: true,
        closeTimeout: 3000,
      });

      app.input.validateInputs('.adddata');
      if(this.state.year === "" || self.state.month === "")
      {
        toastWithButton.open();
        return false;
      }

      const bodyFormData = {
        Mode:'GenerateInvoiceRequest',
        clientid:self.state.clientdetail.id,
        bypass:self.state.bypass,
        month:self.state.month,
        year:self.state.year,
        cansendsms:Number(self.state.cansendsms),
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
 
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          if(resdata.pricingerror == true)
          {
            app.dialog.create({
              title: 'Warning',
              text: resdata.msg,
              closeByBackdropClick: 'true',
              buttons: [
                  {
                    text: 'Cancel',
                  },
                  {
                    text: 'Update Pricing',
                    onClick: () => {
                      /*this.updatePrice();*/
                      this.navigateToLink(`updatemonthprice/${this.state.year}/${this.state.month}/${this.state.monthname}`);
                    },
                  },
              ],
            }).open();
          }
          else if(resdata.openingbalanceerror == true)
          {
            app.dialog.create({
              title: 'Warning',
              text: resdata.msg,
              closeByBackdropClick: 'true',
              buttons: [
                  {
                      text: 'Proceed',
                      onClick: () => {

                        this.setState(()=>{

                          return{
                            bypass:1,
                          }
                  
                        },()=>{
                          this.generateInvoices();
                      })
                    },
                  },
                  {
                    text: 'Update Balance',
                    onClick: () => {
                      /*this.updatePrice();*/
                      if(this.$f7.online)
                      {
                        this.$f7router.navigate(`/managecustomers/?openingbalanceid=3&filteropeningbalance=No Entry`);
                      }
                    },
                  },
              ],
            }).open();
          }
          else
          {
            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();

            this.setState(()=>{

              return{
                bypass:0,
              }
      
            },()=>{
                if(resdata.success === true)
                {
                  this.getInvoiceMonth();

                  if(resdata.canswitchrun === true)
                  {
                    app.tab.show("#tab-2");
                  }
                }
                else
                {
                  app.tab.show("#tab-2");
                }
              }
            )
          }
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    createInvoices(){
      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {        
        return;
      }

      const bodyFormData = {
        Mode:'CreateInvoices',
        clientid:self.state.clientdetail.id,
        billdate:self.state.billdate,
        cansendsms:Number(self.state.cansendsms),
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
 
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();
            this.getInvoiceMonth();
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    deleteInvoices(){
      const self  = this;
      const app   = self.$f7;

      if(!this.$f7.online)
      {        
        return;
      }

      const bodyFormData = {
        Mode:'DeleteAgencyInvoice',
        clientid:self.state.clientdetail.id,
        month:self.state.month,
        year:self.state.year,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
 
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

          if(resdata.success === true)
          {
            this.getInvoiceMonth();
          }

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    sendInvoiceSMS(){
      const self  = this;
      const app   = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
        Mode:'SendCustomerInvoiceSMS',
        clientid:self.state.clientdetail.id,
        month:self.state.month,
        year:self.state.year,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
 
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

          if(resdata.success === true)
          {
            this.getInvoiceMonth();
          }

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    saveInventoryPricing(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {        
        return;
      }

      const bodyFormData = {
          Mode:'SaveInventoryPricing',
          clientid:self.state.clientdetail.id,
          cityid:self.state.clientdetail.cityid,
          stateid:self.state.clientdetail.stateid,
          month:self.state.month,
          year:self.state.year,
          inventorylist:self.state.inventorylist,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
 
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            if(resdata.success !== true)
            {
              toastWithButton.open();
            }

            if(resdata.success === true)
            {
              this.$f7.views.main.router.navigate(`thanks/generateinvoicesuccess/`);
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);
    }

    updatePrice(){
      
      this.setState(()=>{

        return{
          popupOpened:true,
        }

      },()=>{

        if(this.state.popupOpened === true && this.$f7.online)
        {
          this.getActiveInventoryDetail();
        }

      });
    }

    stopInvoicesGeneration(){
      const self  = this;
      const app   = self.$f7;

      if(!this.$f7.online)
      {        
        return;
      }

      const bodyFormData = {
        Mode:'MarkInvoiceRequestCompleted',
        clientid:self.state.clientdetail.id,
        month:self.state.month,
        year:self.state.year,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {
        // We got user data from request

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/invoice.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {
 
          // Hide Preloader
          app.preloader.hide();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton: true,
            closeTimeout: 3000,
          });

          toastWithButton.open();

          if(resdata.success === true)
          {
            this.getInvoiceMonth();
          }

        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){
       
      const{invoiceyear, year, invoicemonth, hasmonth, isrequestdone, billdate, totalamount, cansendsms, totaloutstanding, inactiveoutstanding, customercount, hasinvoicerequestinqueue}  = this.state;

      return(
            <Page className="page" name="GenerateInvoice" pageContent={false} >
              <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Bill</NavTitle>
                  <NavRight className="right-with-menu">
                  <Link onClick={()=>{

                    this.getInvoiceMonth();

                  }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
              </Navbar>
              <Toolbar tabbar labels position={this.state.toolbarPosition}>
                <Link tabLink="#tab-1" tabLinkActive text="Generate Bill" iconIos="f7:assignment_fill" iconAurora="f7:assignment_fill" iconMd="material:assignment"></Link>
                <Link tabLink="#tab-2" text="Run Wizard" iconIos="f7:autorenew_fill" iconAurora="f7:calendar_fill" iconMd="material:autorenew"></Link>
              </Toolbar>
               <Tabs swipeable>
                  <Tab id="tab-1" tabActive>
                    <PageContent>
                      <List noHairlinesMd>
                        <ListInput
                            name="year"
                            className="adddata"
                            label="Year"
                            type="select"
                            onChange={this.handleUserInput}
                            value={year}
                            placeholder="Please choose..."
                            required validate
                            >
                            <option value="">-Select-</option>
                            {
                              invoiceyear.map((yearlist)=>{
                                return(<option key={yearlist.index} value={yearlist.year}>{yearlist.year}</option>);
                              })
                            }
                        </ListInput>
                      </List>
                      {
                        isrequestdone ? (<Fragment>
                        {
                          hasmonth ? (<List noHairlinesMd>
                            {
                              invoicemonth.map((invoicemonth)=>{
                                
                                let temptitle = invoicemonth.name;
                                let exttitle = "";
                                let iscount = false;
                                
                                if(Number(invoicemonth.total) > 0)
                                {
                                  exttitle += ' Rs. '+invoicemonth.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+' ('+invoicemonth.count+')';
                                  iscount = true;
                                }

                                if(invoicemonth.hasinvoicerequestinqueue)
                                {
                                  exttitle  = "Process in queue";
                                  iscount   = true;
                                }

                              return(
                                  <ListItem title={temptitle} key={invoicemonth.index}>
                                  { iscount ? (<Chip text={exttitle} outline color="blue"/>) : null}
                                  <Button fill color="blue" 
                                      onClick={()=>{
                                        if(this.$f7.online)
                                        {
                                          this.setState(()=>{
                                            return{
                                              month:invoicemonth.id,
                                              monthname:invoicemonth.name,
                                              monthcount:invoicemonth.count,
                                              monthhasinvoice:invoicemonth.hasinvoice,
                                              canregeneratelink:invoicemonth.canregeneratelink,
                                              hasinvoicerequestinqueue:invoicemonth.hasinvoicerequestinqueue,
                                            }
                                          },()=>{
                                            this.refs.actionsOneGroup.open();
                                          })
                                        }
                                      }}
                                  >Manage</Button>
                                  </ListItem>
                                  );
                              })
                            }
                            <ListItem title={`Total Bills`} style={{fontWeight:'bold'}}>
                              {` Rs. ${totalamount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
                            </ListItem>
                            <ListItem title={`Total Outstanding`} style={{fontWeight:'bold'}}>
                              {` Rs. ${totaloutstanding.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
                            </ListItem>
                            <ListItem className='inactivecustomers'>
                            <div style={{fontWeight:'bold'}}>
                            Outstanding By Inactive Customer
                            </div>
                            <div>
                            {` Rs. ${inactiveoutstanding.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`} ({customercount})
                            </div>
                            <div>
                              <Button fill color="blue" 
                                onClick={()=>{
                                  if(this.$f7.online)
                                  {
                                    this.navigateToLink(`inactivecustomeroutstanding`)
                                  }
                                }}
                              >View</Button>
                            </div>
                            </ListItem>
                          </List>):null
                        }
                        </Fragment>):(<AppListItemLoading />)
                      }
                    </PageContent>
                  </Tab>
                  <Tab id="tab-2" className="page-content">
                  <Block>
                  <br />
                  <br />
                    <br />
                    <br />
                    <br />
                      <List noHairlinesMd style={{marginTop:'5px'}}>
                        <ListInput
                          name="billdate"
                          id="calendar-billdate-input"
                          label="Bill Date"
                          placeholder="Bill Date"
                          type="date"
                          value={billdate}
                          onChange={this.handleUserInput}
                          calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                        >
                        </ListInput>
                        <ListItem title="Send SMS ?">
                          <Toggle slot="after" name="cansendsms" checked={Number(cansendsms) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                      </List>
                      <Button raised fill onClick={()=>{this.createInvoices()}}>Create Bills</Button>
                  </Block>
                  <br /><br /><br />
                </Tab>
                </Tabs>     
              {/*</PageContent>*/}
              <Actions ref="actionsOneGroup">
                <ActionsGroup>
                  <ActionsLabel bold>Select an action to proceed</ActionsLabel>
                  {
                    !hasinvoicerequestinqueue ? <ActionsButton color="blue" bold onClick={()=>{this.navigateToLink(`updatemonthprice/${this.state.year}/${this.state.month}/${this.state.monthname}`)}}>Update Price By Month</ActionsButton>:null
                  }
                  {
                    !hasinvoicerequestinqueue ? <ActionsButton color="blue" bold onClick={()=>{this.navigateToLink('updatedateprice')}}>Update Price By Date</ActionsButton>:null
                  }
                  {
                    hasinvoicerequestinqueue ? (<ActionsButton color="blue" bold onClick={()=>{
                      if(this.$f7.online)
                      {
                        this.refs.actionsStopInvoiceGeneration.open();
                      }
                    }}>Stop Bill Generation Request</ActionsButton>):null
                  }
                </ActionsGroup>
                {
                  hasinvoicerequestinqueue ? <ActionsGroup><ActionsButton color="red">Cancel</ActionsButton></ActionsGroup>:null
                }
                {
                  !hasinvoicerequestinqueue ? (
                    <Fragment>
                    {
                      this.state.monthhasinvoice? (
                        <ActionsGroup>
                          <ActionsButton color="blue" bold onClick={()=>{this.$f7router.navigate(`/manageinvoices/${this.state.year}/${this.state.month}/`)}}>View Invoices</ActionsButton>
                          {this.state.canregeneratelink ? ( <ActionsButton color="blue" bold onClick={()=>{this.reGenerateInvoiceQueue(this.state.month,this.state.year)}}>Re-Generate Invoices</ActionsButton>): null}
    
                          {this.state.canregeneratelink ? ( <ActionsButton color="blue" bold onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.refs.actionsDeleteInvoice.open();
                            }
                          }}>Delete Bills</ActionsButton>): null}
                        </ActionsGroup>
                      ) : (
                        <ActionsGroup>
                          <ActionsButton color="blue" bold onClick={()=>{this.generateInvoices()}}>Create Bills</ActionsButton>
                        </ActionsGroup>
                      )   
                    }
                    </Fragment>
                  ):null
                }
               </Actions>
              <Actions ref="actionsDeleteInvoice">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure?</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.deleteInvoices()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              <Actions ref="actionsStopInvoiceGeneration">
                <ActionsGroup>
                  <ActionsLabel bold>Are you sure? you want to stop bill generation request.</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.stopInvoicesGeneration()}}>Yes</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">No</ActionsButton>
                </ActionsGroup>
              </Actions>
              
              <Actions ref="actionsSMSInvoice">
                <ActionsGroup>
                  <ActionsLabel bold>
                    There are about {this.state.monthcount} bill(s) SMS to be sent out.</ActionsLabel>
                  <ActionsButton color="blue" bold onClick={()=>{this.sendInvoiceSMS()}}>Proceed</ActionsButton>
                </ActionsGroup>
                <ActionsGroup>
                  <ActionsButton color="red">Cancel</ActionsButton>
                </ActionsGroup>
              </Actions>
            </Page>
      );
    }
}