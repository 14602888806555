import React,{Component, Fragment} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Toolbar,
    Link,
    Tabs,
    Tab,
    Block,
    BlockTitle,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Fab,
    Icon,
    Toggle,
    Input,
    PhotoBrowser,
  } from 'framework7-react';

  export default class EditLineman extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        clientdetail:[],
        name:'',
        phone:'',
        password:'',
        status:1,
        remarks:'',
        isptr:false,
        arealist:[],
        linelist:[],
        defaultline:[],
        isrequestdone:false,
        effect:'blink',
        hasautocomplete:false,
        areaid:'',
        areaname:'Select',
        hasdob:false,
        dob:'',
        isidproofrequired:false,
        idproofdetail:'',
        imagefile1:'',
        imagefile2:'',
        preimagefile1:'',
        preimagefile2:'',
        permissions:[
                    {"id":"cancustomers","name":'Customers',"ischecked":false},
                    {"id":"canpayments","name":'Payments',"ischecked":false},
                    {"id":"caninvoices","name":'Bills',"ischecked":false},
                    {"id":"canreports","name":'Reports',"ischecked":false,reportlist:[
                      {
                        "id":"outstandingreport",
                        "name":'Outstanding (Detail)',
                        "ischecked":true
                      },
                      {
                        "id":"outstandingreportsummary",
                        "name":'Outstanding (Summary)',
                        "ischecked":true
                      },
                      {
                        "id":"circulationreport",
                        "name":'Circulation',
                        "ischecked":true
                      },
                      {
                        "id":"customerlistbynewspaper",
                        "name":'Customer List By Newspaper',
                        "ischecked":true
                      },
                      {
                        "id":"customerlistbymagazine",
                        "name":'Customer List By Magazine',
                        "ischecked":true
                      },
                      {
                        "id":"billstatementbyarealine",
                        "name":'Bill Statement',
                        "ischecked":true
                      },
                      {
                        "id":"billprintingreport",
                        "name":'Bill Printing',
                        "ischecked":true
                      },
                      {
                        "id":"paymentregister",
                        "name":'Payment Register',
                        "ischecked":true
                      },
                      {
                        "id":"customerledgerreport",
                        "name":'Customer Ledger',
                        "ischecked":true
                      },
                      {
                        "id":"billstatementsummary",
                        "name":'Bill & Recovery Summary',
                        "ischecked":true
                      },
                      {
                        "id":"billcollectionsummary",
                        "name":'Bill Collection Summary',
                        "ischecked":true
                      },
                      {
                        "id":"magazinesaledetail",
                        "name":'Magazine Sale Detail',
                        "ischecked":true
                      },
                      {
                        "id":"monthlybillofnewspaper",
                        "name":'Price List',
                        "ischecked":true
                      },
                      {
                        "id":"purchasesummary",
                        "name":'Purchase Summary',
                        "ischecked":true
                      },
                      {
                        "id":"closecustomerlist",
                        "name":'Close Customer List',
                        "ischecked":true
                      },
                      {
                        "id":"restartcustomerlist",
                        "name":'Restart Customer List',
                        "ischecked":true
                      },
                      {
                        "id":"sequence",
                        "name":'Sequence',
                        "ischecked":true
                      },
                      {
                        "id":"smshistory",
                        "name":'SMS History',
                        "ischecked":true
                      }
                    ]},
                    {"id":"canpurchase","name":'Purchase',"ischecked":false},
                    {"id":"cansale","name":'Sale',"ischecked":false},
                    {"id":"canmessages","name":'Messages',"ischecked":false},
                    {"id":"canstock","name":'Stock',"ischecked":false},
                    {"id":"canarea","name":'Area',"ischecked":false},
                    {"id":"candroppingpoint","name":'Dropping Point',"ischecked":false},
                    {"id":"canline","name":'Line',"ischecked":false},
                    {"id":"cansubline","name":'Sub Line',"ischecked":false},
                    {"id":"canlineman","name":'Lineman',"ischecked":false},
                    {"id":"canhawkers","name":'Hawkers',"ischecked":false},
                    {"id":"canholiday","name":'Holiday',"ischecked":false},
                    {"id":"cansubscription","name":'Subscription',"ischecked":false},
                  ],
      };

      this.handleUserInput        = this.handleUserInput.bind(this);
      this.getLineDetail          = this.getLineDetail.bind(this);
      this.handlePermissionInput  = this.handlePermissionInput.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.getDetail();
        this.hideSplashScreen();

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handlePermissionInput(area, permissionid){

      let tempPermList       = [...this.state.permissions];

      if(area === 'permission')
      {
        const tempPerm      = tempPermList.find(perm => perm.id === permissionid);
        tempPerm.ischecked  = !tempPerm.ischecked;
      }
      else
      {
        const tempPermList2 = tempPermList.find(perm => perm.id === 'canreports');

        if(tempPermList2)
        {
          let reportpermissionlist  = [...tempPermList2.reportlist];

          const tempReportPerm      = reportpermissionlist.find(reportperm => reportperm.id === permissionid);
          tempReportPerm.ischecked  = !tempReportPerm.ischecked;
        }
      }

      this.setState(()=>{
        
        return{

          permissions: tempPermList

        }

      });
    }

    handleUserInput(e){

      const name  = e.target.name;
      let value   = e.target.value;

      if(name === 'name')
      {
        value = value.toUpperCase();
      }

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else if(name === 'hasdob')
      {
        this.setState({hasdob:!this.state.hasdob});
      }
      else if(name === 'isidproofrequired')
      {
        this.setState({isidproofrequired:!this.state.isidproofrequired});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    onPageInit(){

      const self  = this;
      const app   = self.$f7;

      self.autocompleteAreaPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const bodyFormData = {
              Mode:'GetArea',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
          };

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            data: bodyFormData,
            headers: {
                Authorization: `${accesstoken}`
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*$('#autocomplete-area-popup').find('.item-after').text(value[0].name);*/

            self.setState({
              areaid:value[0].id,
              areaname:value[0].name,
              defaultline:[]
            },()=>{
              
              /*for(let i = 0; i < app.smartSelect.get('.smart-select').$selectEl[0].length; i++) {
                app.smartSelect.get('.smart-select').$selectEl[0][i].selected = false;
              }
              app.smartSelect.get('.smart-select').$selectEl.val();*/

              app.smartSelect.get('.smart-select').setValue([]);

              self.getLineDetail();
            });

          },
        },
      });      

    }

    getLineDetail(){

        const self      = this;
        const app       = self.$f7;

        let bodyFormData = {
          Mode:'GetLine',
          clientid:self.state.clientdetail.id,
          recordid:self.props.f7route.params.id,
          areaid:self.state.areaid,
          /*type:'subline',*/
          type:'lineman',
          type2:'addeditlineman',
        };
 
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                  this.setState(()=>{
                      return{
                          linelist:resdata.recordlist,
                          isrequestdone:true,
                      }
                  },()=>{
                    if(this.state.hasautocomplete === false)
                    {
                      this.setState({
                        hasautocomplete:true
                      },()=>{

                        this.onPageInit();

                      })
                    }
                  });
              }
              else
              {
                  this.setState(()=>{
                      return{
                        linelist:[],
                        isrequestdone:true
                      }
                  },()=>{
                    if(this.state.hasautocomplete === false)
                    {
                      this.setState({
                        hasautocomplete:true
                      },()=>{

                        this.onPageInit();

                      })
                    }
                  });
              }
    
          }).catch(e => {
              console.log(e);
          });
    }

    getDetail(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {          
          return;
        }

        const bodyFormData = {
            Mode:'GetLinemanDetail',
            clientid:self.state.clientdetail.id,
            recordid:self.props.f7route.params.id,
        };

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/lineman.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
  
                name:resdata.linemandetail.name,
                phone:resdata.linemandetail.phone,
                password:resdata.linemandetail.password,
                status:resdata.linemandetail.status,
                remarks:resdata.linemandetail.remark,
                defaultline:resdata.linemandetail.lineids,
                areaid:resdata.linemandetail.areaid,
                areaname:resdata.linemandetail.areaname,
                hasdob:Number(resdata.linemandetail.hasdob),
                dob:resdata.linemandetail.dob,
                isidproofrequired:Number(resdata.linemandetail.isidproofrequired),
                idproofdetail:resdata.linemandetail.idproofdetail,
                preimagefile1:resdata.linemandetail.imagefile1,
                preimagefile2:resdata.linemandetail.imagefile2,
                isptr:false,
                permissions:resdata.linemandetail.permissions,

              },()=>{
                this.getLineDetail();
              });
            }
            else
            {
              this.setState({
  
                isptr:false,

              },()=>{
                this.getLineDetail();
              });
            }
  
        }).catch(e => {
            console.log(e);
        });
    }

    saveDetail(){

      const self      = this;
      const app       = self.$f7;
      const router    = self.$f7router;

      let errmsg    = "";

      let selectedline  = "";
      let haslist       = false;

      if(!this.$f7.online)
      {
        return;
      }

      app.input.validateInputs('.adddata');

      selectedline = app.smartSelect.get('.smart-select').$selectEl.val();

      var lineloop;
      for (lineloop = 0; lineloop < Number(selectedline.length); lineloop++) {
        if(Number(selectedline[lineloop]) > 0)
        {
          haslist  = true;
        }
      }

      if(haslist)
      {
        this.$$('.smartselectlist').removeClass('error');
      }
      else
      {
        this.$$('.smartselectlist').addClass('error');
      }

      if(haslist !== true && errmsg === "")
      {
          errmsg  = "Please select atleast one line to add lineman.";
      }

      if(this.state.name === "" && errmsg === "")
      {
        errmsg  = "Please enter name";
      }

      if(this.state.phone === "" && errmsg === "")
      {
        errmsg  = "Please enter phone";
      }
      
      if(this.state.password === "" && errmsg === "")
      {
        errmsg  = "Please enter password";
      }

      let ispermissioned  = false;
      let hasreport       = false;

      this.state.permissions.forEach(perm => {

        if(Number(perm.ischecked) === 1)
        {
          ispermissioned  = true;
        }

        if(Number(perm.ischecked) === 1 && perm.id === 'canreports')
        {
          perm.reportlist.forEach(report => {

            if(Number(report.ischecked) === 1)
            {
              hasreport  = true;
            }

          });
        }

      });

      if(!ispermissioned && errmsg === "")
      {
        errmsg = "Please select atleast one permission";
      }

      if(hasreport !== true && this.state.permissions[3].ischecked === true && errmsg === "")
      {
        errmsg  = "Please assign a report";
      }

      if((this.state.hasdob === true && this.state.dob === "") && errmsg === "")
      {
        errmsg  = "Please select date of birth";
      }

      let filedata1 = "";
      let filedata2 = "";

      if(Number(this.state.isidproofrequired))
      {
        filedata1     = document.getElementById('imagefile1').files[0];
        filedata2     = document.getElementById('imagefile2').files[0];  
      }

      const formData = new FormData();

      formData.append('Mode','EditLineman');
      formData.append('clientid',self.state.clientdetail.id);
      formData.append('recordid',self.props.f7route.params.id);
      formData.append('selectedline',JSON.stringify(selectedline));
      formData.append('name',self.state.name);
      formData.append('phone',self.state.phone);
      formData.append('password',self.state.password);
      formData.append('status',Number(self.state.status));
      formData.append('remarks',self.state.remarks);
      formData.append('areaid',self.state.areaid);
      formData.append('hasdob',Number(self.state.hasdob));
      formData.append('dob',self.state.dob);
      formData.append('isidproofrequired',Number(self.state.isidproofrequired));
      formData.append('idproofdetail',self.state.idproofdetail);
      formData.append('imagefile1',filedata1);
      formData.append('imagefile2',filedata2);
      formData.append('preimagefile1',self.state.preimagefile1);
      formData.append('preimagefile2',self.state.preimagefile2);
      formData.append('permissions',JSON.stringify(self.state.permissions));

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');

        app.request({
          url: `${app.passedParams.serverurl}api/lineman.php?t=${Date.now()}`,
          method: 'POST', 
          data: formData,
          crossDomain: true,
          cache: false, 
          dataType: 'application/json',
          contentType: 'multipart/form-data',
          processData: true,
          headers: {
              Authorization: `${accesstoken}`
          },
          success: (data)=>{

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });

            toastWithButton.open();

            if(resdata.success === true)
            {
              router.navigate('/lineman/');
            }
          }
        });

      }, 1000);

    }

    deleteFileRecord(fieldname, attachment){

      const self	= this;
      const app	= self.$f7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.$f7.online)
              {
                const bodyFormData = {
                  Mode:'DeleteAttachment',
                  clientid:this.state.clientdetail.id,
                  recordid:self.props.f7route.params.id,
                  [fieldname]:attachment,
                };
        
                app.preloader.show();
        
                // Simulate Ajax Request
                setTimeout(()=> {
                    
                  const accesstoken  = localStorage.getItem('agency_accesstoken');
        
                  app.request.setup({
                    headers:{
                      Authorization: `${accesstoken}`
                    }
                  });
        
                  app.request.promise.post(`${app.passedParams.serverurl}api/lineman.php?t=${Date.now()}`,bodyFormData)
                  .then((res)=> {
        
                      // We got user data from request
              
                      // Hide Preloader
                      app.preloader.hide();
        
                      const resdata = JSON.parse(res.data);
        
                      const toastWithButton = app.toast.create({
                        text: resdata.msg,
                        closeButton: true,
                        closeTimeout: 3000,
                      });
            
                      toastWithButton.open();
        
                      if(resdata.success === true)
                      {
                        this.setState(
                          ()=>{
                            return {
                              [fieldname]: ''
                            }
                        });
                        
                      }
            
                  }).catch(e => {
                      console.log(e);
                  });
        
                }, 500);

              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }    

    render(){

      const{isrequestdone, linelist, areaname, defaultline, hasdob, dob, isidproofrequired, preimagefile1, preimagefile2, permissions} = this.state;
       
        return(
            <Page pageContent={false} className="page" name="EditLineman">
                <Navbar sliding={true}>
                    <NavLeft>
                      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                      <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                      </Link>
                    </NavLeft>
                    <NavTitle>Edit Lineman</NavTitle>
                    <NavRight className="right-with-menu">
                    </NavRight>
                </Navbar>
                <PageContent>
                <Block>
                {
                  isrequestdone ? (
                    <Fragment>
                      <List noHairlinesMd style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                        <ListItem link="#" id="autocomplete-area-popup" className="autocomplete-area-popup" title="Area">
                          <div slot="after" id="autocomplete-area-name2">{areaname}</div>
                        </ListItem>
                      </List>
                      <Row noGap>
                          <Col>
                              <List noHairlines style={{margin:0,padding:0,listStyle:'none',marginBottom:'0px'}}>
                              <ListItem
                                  title="Choose Line(s)"
                                  smartSelect
                                  smartSelectParams={{openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search Line'}}
                                  name='LineIds'
                                  id='LineIds'
                                  className="smartselectlist"
                                  after='Select'
                                  PlaceHolder='Select'
                                  >
                                  <select name="Line" multiple onChange={this.handleUserInput} defaultValue={defaultline}>
                                  {
                                    linelist.map((line)=>{
                                      return( 
                                              <option value={line.id} key={line.id}>{line.name}</option>
                                          );
                                      })
                                  }
                                  </select>
                              </ListItem>
                              </List>
                          </Col>
                      </Row>
                      <List noHairlinesMd style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>

                        <ListInput
                            name="name"
                            className="adddata"
                            label="Name"
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="Name"
                            value={this.state.name}
                            clearButton required validate
                        >
                        </ListInput>

                        <ListInput
                            name="phone"
                            className="adddata"
                            label="Phone Number"
                            floatingLabel
                            type="tel"
                            onInput={(e)=>{
                            
                              let tempphone = e.target.value;
                              let mobile    = tempphone.replace(/ /g,'');
                              
                              if(tempphone.slice(0,1)=='+')
                              {
                                mobile = mobile.substring(3);
                              }
  
                              if(tempphone.slice(0,1)=='0')
                              {
                                mobile = mobile.substring(1);
                              }
  
                              if(mobile.length > 10)
                              {
                                return;
                              }
                              
                              this.setState({
                                phone:mobile
                              })
                            }}
                            placeholder="Phone number"
                            value={this.state.phone}
                            clearButton required validate
                        >
                        </ListInput>
                        <ListInput
                            name="password"
                            className="adddata"
                            label="Password"
                            floatingLabel
                            type="text"
                            onInput={this.handleUserInput}
                            placeholder="Your password"
                            value={this.state.password}
                            clearButton required validate
                        >
                        </ListInput>
                      </List>

                      <Block><b>Permissions</b></Block>
                      <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                      {
                        permissions.map((perm, permloop)=>{
                            return (
                              <Fragment key={permloop}>
                              <ListItem checkbox title={perm.name} name="permissionsarr[]" checked={perm.ischecked} onChange={()=>{this.handlePermissionInput('permission', perm.id)}}  id={`perm`+ perm.id}>
                              </ListItem>
                              {
                                perm.ischecked && perm.id === 'canreports' ? (
                                  <List noHairlines style={{margin:0,padding:0,listStyle:'none',paddingLeft:'35px'}}>
                                  {
                                    perm.reportlist.map((reportperm, reportpermloop)=>{
                                      return(
                                        <ListItem key={`${permloop}-${reportpermloop}`} checkbox title={reportperm.name} name="reportpermissionsarr[]" checked={reportperm.ischecked} onChange={()=>{this.handlePermissionInput('reportpermission', reportperm.id)}}  id={`reportperm${reportperm.id}`}></ListItem>
                                      )
                                    })
                                  }
                                  </List>
                                ):null
                              }
                              </Fragment>
                            );
                        })
                      }
                    </List>
                    <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>                      
                        <ListInput
                            name="remarks"
                            label="Remarks"
                            floatingLabel
                            type="textarea"
                            onInput={this.handleUserInput}
                            value={this.state.remarks}
                            resizable
                            placeholder="Remarks"
                        >
                        </ListInput>
                        <ListItem title="Status">
                          <Toggle slot="after" name="status" checked={Number(this.state.status) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>

                        <ListItem title="Has Birth Date?">
                          <Toggle slot="after" name="hasdob" checked={Number(hasdob) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                        {
                          hasdob ? (
                            <ListInput
                                name="dob"
                                id="calendar-dob-input"
                                label="Date Of Birth"
                                placeholder="Date Of Birth"
                                type="date"
                                value={dob}
                                onChange={this.handleUserInput}
                                calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                            >
                            </ListInput>
                          ):null
                        }
                        <ListItem title="ID Proof Required?">
                          <Toggle slot="after" name="isidproofrequired" checked={Number(isidproofrequired) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                        {
                          Number(isidproofrequired) ? (
                            <ListInput
                              name="idproofdetail"
                              label="ID Proof Detail"
                              floatingLabel
                              type="textarea"
                              onInput={this.handleUserInput}
                              value={this.state.idproofdetail}
                              resizable
                              placeholder="ID Proof Detail"
                            >
                            </ListInput>
                          ):null
                        }
                      </List>
                      {
                        Number(isidproofrequired) ? (
                          <Block><b>ID Proof Attachment</b></Block>
                        ):null
                      }
                      {
                        Number(isidproofrequired) ? (
                          <Fragment>
                            <div className="attachmentblock">
                            <List noHairlines style={{margin:0,padding:0,marginTop:'-15px',listStyle:'none',marginBottom:'0px'}}>
                              <ListItem>
                                <span style={{marginRight:'5px',minWidth:'100px'}}>Attachment 1</span><br/>
                                <Input type="file" name="imagefile1" inputId="imagefile1" onChange={this.handleUserInput}/>
                              </ListItem>
                            </List>
                            {
                              preimagefile1 ? ( 
                                <Row noGap style={{marginTop:'5px',marginBottom:'5px',marginLeft:'12px',marginRight:'15px'}}>
                                  <Col width={45} style={{textAlign:'left'}}>
                                    <Link onClick={()=>{

                                      this.deleteFileRecord('preimagefile1', preimagefile1);

                                    }}><Icon f7="trash_circle_fill" style={{fontSize:'22px'}}></Icon>&nbsp;Remove Image
                                    </Link>
                                  </Col>
                                  <Col width={10}></Col>
                                  <Col width={45} style={{textAlign:'right'}}>
                                    <PhotoBrowser
                                      type="popup"
                                    ref={(el) => {this.popup = el}}
                                      photos={[
                                        {
                                          url: this.$f7.passedParams.serverurl+"api/"+preimagefile1,
                                          caption: '',
                                        },
                                      ]}
                                    />
                                    <Link onClick={()=>{
                                      if(this.$f7.online)
                                      {
                                        this.popup.open();
                                      }
                                    }}><Icon f7="eye" style={{fontSize:'22px'}}></Icon>&nbsp;View Image</Link>
                                  </Col>
                                </Row>
                                ) :null
                            }
                            </div>
                            <br />
                            <div className="attachmentblock">
                            <List noHairlines style={{margin:0,padding:0,listStyle:'none',marginBottom:'0px'}}>                                    
                              <ListItem>
                                <span style={{marginRight:'5px',minWidth:'100px'}}>Attachment 2</span><br/>
                                <Input type="file" name="imagefile2" inputId="imagefile2" onChange={this.handleUserInput}/>
                              </ListItem>
                            </List>
                            {
                              preimagefile2 ? ( 
                                <Row noGap style={{marginTop:'5px',marginBottom:'5px',marginLeft:'12px',marginRight:'15px'}}>
                                  <Col width={45} style={{textAlign:'left'}}>
                                    <Link onClick={()=>{

                                      this.deleteFileRecord('preimagefile2', preimagefile2);

                                    }}><Icon f7="trash_circle_fill" style={{fontSize:'22px'}}></Icon>&nbsp;Remove Image</Link>
                                  </Col>
                                  <Col width={10}></Col>
                                  <Col width={45} style={{textAlign:'right'}}>
                                    <PhotoBrowser
                                      type="popup"
                                      ref={(e2) => {this.popup2 = e2}}
                                      photos={[
                                        {
                                          url: this.$f7.passedParams.serverurl+"api/"+preimagefile2,
                                          caption: '',
                                        },
                                      ]}
                                    />
                                    <Link onClick={()=>{
                                      if(this.$f7.online)
                                      {
                                        this.popup2.open();
                                      }
                                    }}><Icon f7="eye" style={{fontSize:'22px'}}></Icon>&nbsp;View Image</Link>
                                  </Col>
                                </Row>
                                ) :null
                            }
                            </div>
                          </Fragment>
                        ):null
                      }
                    </Fragment>
                  ):(<AppListItemLoading />)
                }
                </Block>
                <br /><br />
                </PageContent>
                <AppSaveRecordButton saveDetail={this.saveDetail.bind(this)} />
            </Page>
          );
    }
}