import React,{Component,Fragment} from 'react';
import scanlogo from '../assets/images/scanlogo.png';
import {
  Page,
  LoginScreen,
  LoginScreenTitle,
  ListInput,
  BlockFooter,
  List,
  ListItem,
  ListButton,
  Button,
  Popup,
  Navbar,
  NavRight,
  NavLeft,
  NavTitle,
  Link,
  Block,
  Row,
  Col,
  View
} from 'framework7-react';

export default class extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        phone: '',
        password: '',
        forgetphone:'',
        logintype:0,
        passwordrecoverytype:0,
      };

      this.handleUserInput = this.handleUserInput.bind(this);
    }

    componentDidMount(){

      this.hideSplashScreen();

    }
    
    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

        this.setState(()=>{
            return{
            [name]: value
            }
        });
    }

    signIn(){
        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(!this.$f7.online)
        {
          return;
        }

        let errmsg  = "";

        if(self.state.phone === '' && self.state.password === '')
        {
            errmsg  = "Mobile Number and password can not be blank";
        }

        if(self.state.phone === '' && errmsg == "")
        {
            errmsg  = "Mobile number can't be blank";
        }

        if(self.state.password === ''  && errmsg == "")
        {
            errmsg  = "Password can't be blank";
        }

        if(errmsg !== "")
        {
            const toastWithButton = app.toast.create({
                text: errmsg,
                closeButton: true,
                closeTimeout: 3000,
            });
              
            toastWithButton.open();

            app.input.validateInputs('.logindata');
        }
        else
        {
            app.preloader.show();

           const bodyFormData = {
              Mode:'AppLogin',
              phone:self.state.phone,
              logintype:self.state.logintype,
              password:self.state.password
           };

            // Simulate Ajax Request
            setTimeout(()=> {
                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                app.request.promise.post(`${app.passedParams.serverurl}api/applogin.php?t=${Date.now()}`,bodyFormData)
                .then(function (res) {

                    const resdata = JSON.parse(res.data);

                    if(resdata.success === true)
                    {
                        localStorage.setItem("agency_accesstoken",resdata.accesstoken);
                        if(resdata.isadminlogin === true)
                        {
                            localStorage.setItem("agency_isadminlogin",resdata.isadminlogin);
                            localStorage.setItem("agency_adminid",resdata.adminid);
                            localStorage.setItem("agency_isadminverified",false);
                        }
                        else
                        {
                            localStorage.setItem("agency_isadminlogin",false);
                            localStorage.setItem("agency_adminid","");
                            localStorage.setItem("agency_isadminverified",false);
                        }
                        localStorage.setItem("agency_clientdetail",JSON.stringify(resdata.clientdetail));
                        localStorage.setItem("agency_authtoken",resdata.authtoken);

                        if(resdata.isadminlogin === true)
                        {
                            router.navigate('verifyadmin/');
                        }
                        else
                        {
                            if(resdata.ispasswordupdate === 1)
                            {
                                router.navigate('/dashboard/');
                            }
                            else
                            {
                                router.navigate('/changepassword/');
                            }
                        }
                    }
                    else
                    {
                        const toastWithButton = app.toast.create({
                            text: resdata.msg,
                            closeButton: true,
                            closeTimeout: 3000,
                        });
                        
                        toastWithButton.open();            
                    }
                    }).catch(e => {
                    console.log(e);
                });                

            }, 1000);

        }
    }
  
    render() {
      return (
            <Page noToolbar noNavbar noSwipeback loginScreen>
            <Row>
                <Col>
                    <img className="loginlogo" src={scanlogo} alt="logo" />
                </Col>
            </Row>
            <LoginScreenTitle>Agent Login</LoginScreenTitle>
            <Row noGap>
                <Col width="10"></Col>
                <Col width="80">
                    <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                        <ListInput
                            name="logintype"
                            label="Login Type"
                            type="select"
                            onChange={this.handleUserInput}
                            value={this.state.logintype}
                            placeholder="Please choose..."
                            required validate
                            >
                            <option value="0">Owner</option>
                            <option value="2">Area Manager</option>
                            <option value="1">Lineman</option>
                            <option value="3">Hawker</option>
                        </ListInput>
                    </List>
                </Col>
                <Col width="10"></Col>
            </Row>
            <Row noGap>
                <Col width="10"></Col>
                <Col width="80">
                <List form style={{marginBottom:'5px',marginTop:'5px'}}>
                <ListInput
                    className="logindata"
                    label="Mobile"
                    floatingLabel
                    type="tel"
                    placeholder="Mobile Number"
                    value={this.state.phone}
                    onInput={(e)=>{
                        
                        let tempphone = e.target.value;
                        let mobile    = tempphone.replace(/ /g,'');
                        
                        if(tempphone.slice(0,1)=='+')
                        {
                          mobile = mobile.substring(3);
                        }

                        if(tempphone.slice(0,1)=='0')
                        {
                          mobile = mobile.substring(1);
                        }

                        if(mobile.length > 10)
                        {
                          return;
                        }
                        
                        this.setState({
                          phone:mobile
                        })
                    }}
                    required validate pattern="[0-9]*"
                />
                <ListInput
                    className="logindata"
                    label="Password"
                    floatingLabel
                    type="password"
                    placeholder="Your password"
                    value={this.state.password}
                    onInput={(e) => {
                        this.setState({ password: e.target.value});
                    }}
                    required validate
                />
                </List>
                </Col>
                <Col width="10"></Col>
            </Row>
                <Row>
                    <Col width="20"></Col>
                    <Col width="60"><Button fill color="blue" onClick={this.signIn.bind(this)}>Sign In</Button></Col>
                    <Col width="20"></Col>
                </Row>
                <br />
                <Row>
                    <Col width="20"></Col>
                    <Col width="60">
                        <List>
                            <ListButton
                                onClick={()=>{
                                    if(this.$f7.online)
                                    {
                                        this.$f7router.navigate('forgetpassword/');
                                    }
                                }}
                            >Forgot Password?</ListButton>
                        </List>
                    </Col>
                    <Col width="20"></Col>
                </Row>
                <BlockFooter>&copy; 2020 OrloPay.com</BlockFooter>
            </Page>
      )
    }
  }