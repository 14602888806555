import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import { FilterConsumer, FilterContext } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';
import logo404 from '../assets/images/orlo404.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Toggle,
    Toolbar,
    Link,
    Tabs,
    Tab,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Fab,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    Gauge,
    AccordionContent
  } from 'framework7-react';
import { number } from 'prop-types';

  export default class MonthlyBillOfNewspaper extends Component {
    
    constructor(props) {
      
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startyear = today.getFullYear();
      let endyear   = today.getFullYear();
      let startmonth = currmonth-1;
      
      if(startmonth < 1)
      {
        startmonth  = 12;
        startyear   = Number(startyear - 1);
      }

      let lastDay = new Date(endyear, currmonth, 0);

      let lastdate = lastDay.getDate();

      let startdate = startyear+'-'+startmonth+'-01';
      let enddate   = endyear+'-'+currmonth+'-'+lastdate;

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
     
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        filtermonthyear:monthNames[Number(startmonth-1)]+' - '+startyear,
        isfetched:false,
        isptr:false,
        ismount:false,
        hasallcustomer:false,
        hasfilter:true,
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        hasdataavailable:false,
        effect:'blink',
        inventorylist:[],
        monthname:'',
        catid:'0',
        catname:'Both',
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getPaymentRegister = this.getPaymentRegister.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
      
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.getPaymentRegister();
        this.hideSplashScreen();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
    
    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getPaymentRegister(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
    
        const bodyFormData = {
          Mode:'GetMonthlyBillOfNewspaperSummary',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          startdate:self.state.startdate,
          catid:self.state.catid,
          catname:self.state.catname,
        };

        this.setState(()=>{

          return{
            isfetched:false
          }
          
        },()=>{

          setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  isfetched:true,
                  inventorylist:resdata.inventorylist,
                  monthname:resdata.monthname,
                  hasdataavailable:true,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });

              }
              else
              {
                this.setState({
                  isfetched:true,
                  inventorylist:[],
                  hasdataavailable:false,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        },500);

      });
    }

    getPaymentRegisterPDF(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
          Mode:'GetMonthlyBillOfNewspaperPDF',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          startdate:self.state.startdate,
          catid:self.state.catid,
          catname:self.state.catname,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                    //console.log(resdata.pdffilepath);
                   // window.location.href= 'http://orlopay/agency/';
                    window.open(resdata.pdffilepath);
                }
                else
                {
                    const toastWithButton = app.toast.create({
                        text: resdata.msg,
                        closeButton: true,
                        closeTimeout: 3000,
                    });
                      
                    toastWithButton.open();    
                }
            }).catch(e => {
                console.log(e);
            });

        },500);
    }

    setNewFilterData(startdate, enddate, filtermonthyear, catid, catname){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            startdate:startdate,
            enddate:enddate,
            filtermonthyear:filtermonthyear,
            catid:catid,
            catname:catname,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
          }
  
        },()=>{
  
          if((this.state.startdate !== "" && this.state.startdate !== undefined) || (this.state.enddate !== "" && this.state.enddate !== undefined) || (this.state.catid !== "" && this.state.catid !== undefined))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getPaymentRegister();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){

      const{isfetched, recordset, startdate, enddate, hasallcustomer, hasfilter, removefiltername, applyremovefilter, ismount, hasdataavailable, inventorylist, monthname, filtermonthyear, catid, catname} = this.state;

      return(
        <Page className="page" name="MonthlyBillOfNewspaper">
        <Navbar sliding={true}>
          <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
            <Link href={`/dashboard/`} className="clearmenupadding">
              <img className="navbarlogo" src={scanlogo} alt="logo" />
            </Link>
          </NavLeft>
          <NavTitle>Price List</NavTitle>
          <NavRight className="right-with-menu">
          <Link onClick={()=>{

            this.getPaymentRegister();

          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
          </NavRight>
        </Navbar>
      <FilterConsumer>{(value)=>{

        const{monthlybillofnewspaperdata, applyMonthlyBillOfNewspaperFilter} = value;

        if(ismount === true)
        {
          setTimeout(()=> {

            if(removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(removefiltername === 'daterange')
              {
                applyMonthlyBillOfNewspaperFilter('', '', '', catid, catname);
              }
            }

            if(((monthlybillofnewspaperdata.startdate !== startdate) && monthlybillofnewspaperdata.startdate !== undefined) || ((monthlybillofnewspaperdata.enddate !== enddate) && monthlybillofnewspaperdata.enddate !== undefined) || ((monthlybillofnewspaperdata.catid !== catid) && monthlybillofnewspaperdata.catid !== undefined) || applyremovefilter === true)
            {
              this.setNewFilterData(monthlybillofnewspaperdata.startdate, monthlybillofnewspaperdata.enddate, monthlybillofnewspaperdata.filtermonthyear, monthlybillofnewspaperdata.catid, monthlybillofnewspaperdata.catname);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }

          }, 500)
        }
        
      return(
            <Block>
            {
              hasfilter ? (
                <Block strong className="selectedfilter" style={{marginTop:0}}>
                  {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                  {filtermonthyear !== "" ? (<Chip text={`Price Month : ${filtermonthyear}`} mediaBgColor="blue" media={`D`}
                  />):null}
                  {filtermonthyear !== "" ? (<Chip text={`Stock Type : ${catname}`} mediaBgColor="red" media={`S`}
                  />):null}
                  &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                    this.$f7.views.main.router.navigate(`filterpopup/?startdate=${startdate}&enddate=${enddate}&filtermonthyear=${filtermonthyear}&catid=${catid}&catname=${catname}`);
                    /*this.openFilterPopup();*/
                  }}/>
                </Block>
              ):null
            }
            {
              isfetched? (<div>{
                  inventorylist.map((category)=>{
                  return(
                      <Fragment key={category.id}>
                        <BlockTitle medium>{category.title}</BlockTitle>
                        <div className="data-table">
                          <table>
                            <thead>
                              <tr>
                                <th className="label-cell">S.No.</th>
                                <th className="numeric-cell">Inventory</th>
                                <th className="numeric-cell">Price</th>
                                <th className="numeric-cell">Days</th>
                                <th className="numeric-cell">&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                            {
                              category.recordlist.map((inventory)=>{

                              return(
                                  <tr key={inventory.serial}>
                                    <td className="label-cell">{inventory.serial}</td>
                                    <td className="numeric-cell" style={{paddingRight:'0',paddingLeft:'0'}}>{inventory.name}</td>
                                    <td className="numeric-cell">{inventory.price}</td>
                                    <td className="numeric-cell">{inventory.days}</td>
                                    <td className="numeric-cell">
                                      <Link className="dashboardlink" onClick={()=>{
                                        if(this.$f7.online)
                                        {
                                          this.$f7router.navigate(`monthlybillofnewspaperdetail/?inventoryid=${inventory.id}&startdate=${startdate}&catid=${catid}`);
                                        }
                                      }} ignoreCache={true}>
                                        <Icon f7="arrow_up_right_square_fill"></Icon>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                          </table>
                        </div>                              

                      </Fragment>
                      );
                  })
              }</div>):(<AppListItemLoading />)
            }
            <br /><br />
            </Block>
          );

        }}
        </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {
            hasdataavailable ? (
               <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                    if(this.$f7.online)
                        {
                          this.getPaymentRegisterPDF();
                        }
                  }}>
                  <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                  <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                </Fab>
            ):null
          }
        </Page>

      );
    }
}