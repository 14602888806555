import React,{Component} from 'react';
const SMSCreditContext = React.createContext();
//Provider
//Consumer

class SMSCreditProvider extends Component{

    constructor(props){
    super(props);

    let today = new Date();
    let currmonth = today.getMonth()+1;

    if(currmonth < 10)
    {
        currmonth = "0"+currmonth;
    }
    let currday = today.getDate();

    if(currday < 10)
    {
        currday = "0"+currday;
    }
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    let lastdate = lastDay.getDate();

    /*let startdate = today.getFullYear()+'-'+currmonth+'-01';*/
    let startdate = '2020-'+currmonth+'-01';
    let enddate   = today.getFullYear()+'-'+currmonth+'-'+lastdate;

    this.state = {
        clientdetail:[],
        isfetched:false,
        recordset:[],
        isptr:false,
        startdate:startdate,
        enddate:enddate,
        ismount:false,
        hasallcustomer:true,
        hasfilter:true,
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        effect:'blink',
        hasdataavailable:false,
        toolbarPosition:'bottom',
        page:1,
        perpage:'',
        totalpages:'',
        showpages:false,
        paginglist:[],
        hasdataavailable:false,
        totalavailable:0,
        totalpending:0,
        totalpurchase:0,
        totalrefunds:0,
        totalused:0,
        totalbalance:0,
        hascredit:false,
        haspendingcredit:false,
        totalcampaignused:0,
        totalpaymentused:0,
        totalmiscused:0,
        totalcreditused:0,
        datetype:'begining',
    };

        this.handleUserInput          = this.handleUserInput.bind(this);
        this.getSMSCreditReport     = this.getSMSCreditReport.bind(this);
        this.openFilterPopup          = this.openFilterPopup.bind(this);
        this.setNewFilterData         = this.setNewFilterData.bind(this);
        this.navigateToLink           = this.navigateToLink.bind(this);
        this.deleteFilterAction       = this.deleteFilterAction.bind(this);
        this.Paging                   = this.Paging.bind(this);
        this.init                     = this.init.bind(this);
        this.resendInvoiceMessage     = this.resendInvoiceMessage.bind(this);
        this.manageOutstandingAction          = this.manageOutstandingAction.bind(this);
    }

  componentDidMount(){

    let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

    this.setState(()=>{
      return{
        clientdetail:clientdetail,
        customerid:''
      }
    },()=>{

      setTimeout(()=> {

        this.setState({ismount:true});

      }, 1200);

      this.hideSplashScreen();
    });

  }

  hideSplashScreen(){
    document.getElementById("splash-screen").style.visibility = "hidden";
  }
  
  handleUserInput(e){

    const name  = e.target.name;
    const value = e.target.value;

    this.setState(()=>{
      return{
        [name]: value
      }
    },()=>{
        this.Paging('select');
    });

  }

  init(filtervars){

    let{startdate, enddate} = filtervars;

    this.setState(()=>{
      return{
        /*startdate:startdate,
        enddate:enddate,*/
        hasfilter:true,
        hasallcustomer:false,
        page:1,
      }
    },()=>{
      if(this.state.startdate !== "" || this.state.enddate !== "")
      {
        this.setState({hasfilter:true,hasallcustomer:false});
      }
      else
      {
        this.setState({hasfilter:true,hasallcustomer:true});
      }
      this.getSMSCreditReport();
    })
  }

  getSMSCreditReport(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }
  
      const bodyFormData = {
        Mode:'GetSMSCreditLog',
        datetype:self.state.datetype,
        startdate:self.state.startdate,
        enddate:self.state.enddate,
      };

      this.setState(()=>{

        return{
          isfetched:false
        }
        
      },()=>{

        setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
        
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/smscredit.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // Hide Pull To Refresh
            app.ptr.done();

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                isfetched:true,
                smsloglist:resdata.recordlist,
                totalavailable:resdata.availablecredit,
                totalused:resdata.totalused,
                totalpending:resdata.totalpending,
                totalpurchase:resdata.totalpurchase,
                totalrefunds:resdata.totalrefunds,
                totalbalance:resdata.totalbalance,
                isptr:false,
                isdatafetched:true,
                hasdataavailable:true,
                hascredit:resdata.hascredit,
                haspendingcredit:resdata.haspendingcredit,
                totalcampaignused:resdata.totalcampaignused,
                totalpaymentused:resdata.totalpaymentused,
                totalmiscused:resdata.totalmiscused,
                totalcreditused:resdata.totalcreditused,
              },()=>{

                this.Dom7('.outstandingwrapper').addClass('animate-bottom');

              });

            }
            else
            {
              this.setState({

                isfetched:true,
                isdatafetched:true,
                hasdataavailable:false,
                totalpurchase:0,
                totalrefunds:0,
                totalused:0,
                totalbalance:0,
                totalcampaignused:0,
                totalpaymentused:0,
                totalmiscused:0,
                totalcreditused:0,

              },()=>{

                this.Dom7('.outstandingwrapper').addClass('animate-bottom');

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      },500);

    });
  }

  setNewFilterData(datetype, startdate, enddate){

    if(!this.$f7.online)
    {
      return;
    }

    Promise.all([
          
      setTimeout(()=> {

        this.Dom7('.waiting').addClass('animate-bottom');

      }, 50)

    ])
    .then(() => {

      this.setState(()=>{
      
        return{
          datetype:datetype,
          startdate:startdate,
          enddate:enddate,
          loaded:false,
          applyfilter:false,
          page:1,
        }

      },()=>{

        if(this.state.datetype !== "" || this.state.startdate !== "" || this.state.enddate !== "")
        {
          this.setState({hasfilter:true,hasallcustomer:false});
        }
        else
        {
          this.setState({hasfilter:true,hasallcustomer:true});
        }

        this.getSMSCreditReport();

      });

    })
    
  }

  openFilterPopup(){
      
    if(!this.$f7.online)
    {
      return;
    }

    this.$f7.views.main.router.navigate('filterpopup/');

  }

  deleteFilter(){

    this.setState({
      applyremovefilter:true
    })
  }

  navigateToLink(link){

    if(this.$f7.online)
    {
      this.$f7.views.main.router.navigate(`/${link}/`);
    }

  }

  Paging(mode){

    let pageerror = "";

    /*if(!this.$f7.online)
    {
      return;
    }*/

    let temppage    = '';

    const currpage  = this.state.page;

    if(mode === 'previous')
    {
      temppage  = Number(this.state.page) - 1;
    }
    if(mode === 'next')
    {
      temppage  = Number(this.state.page) + 1;
    }
    if(mode === 'select')
    {
      temppage  = Number(this.state.page);
    }

    if(temppage < 1)
    {
      pageerror = "You are on first page";
    }

    if(temppage > Number(this.state.totalpages))
    {
      pageerror = "You are on last page";
    }

    if(pageerror !== "")
    {
      const toastWithButton = this.$f7.toast.create({
          text: pageerror,
          closeButton: true,
          closeTimeout: 3000,
      });
          
      toastWithButton.open();
      
      return;
    }

    if(temppage <= 0)
    {
      temppage  = 1;
    }

    if(temppage >= Number(this.state.totalpages))
    {
      temppage  = Number(this.state.totalpages);
    }

    this.setState(()=>{

      return{
        page:temppage,
        loaded:false
      }

    },()=>{

      Promise.all([
          
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        if(mode === 'select')
        {
          this.getSMSCreditReport();
        }
        else
        {
          if(Number(this.state.page) !== Number(currpage))
          {
            this.getSMSCreditReport();
          }
        }

      })

    })

  }

  deleteFilterAction(filtername){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    app.actions.create({
      buttons: [
      // First group
      [
        {
          text: 'Are you sure?',
          label: true
        },
        {
        text: 'Yes',
        bold: true,
        color:'blue',
        onClick:()=>{

            if(this.$f7.online)
            {
              if(filtername === "area")
              {
                this.setState(()=>{
                  return{
                    areaid:'',
                    filterarea:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
                  
                  this.getSMSCreditReport();

                })
              }
              else if(filtername === "line")
              {
                this.setState(()=>{
                  return{
                    lineid:'',
                    filterline:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getSMSCreditReport();

                })
              }
              else if(filtername === "lineman")
              {
                this.setState(()=>{
                  return{
                    linemanid:'',
                    filterlineman:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getSMSCreditReport();

                })
              }
              else if(filtername === "hawker")
              {
                this.setState(()=>{
                  return{
                    hawkerid:'',
                    filterhawker:'',
                    loaded:false,
                    applyfilter:false,
                    page:1,
                  }
                },()=>{

                  if(Number(this.state.lineid) > 0 || Number(this.state.linemanid) > 0 || Number(this.state.hawkerid) > 0 || Number(this.state.areaid) > 0)
                  {
                    this.setState({hasfilter:true,hasallcustomer:false});
                  }
                  else
                  {
                    this.setState({hasfilter:true,hasallcustomer:true});
                  }
          
                  this.getSMSCreditReport();

                })
              }
            }
          }
        }
      ],
      // Second group
      [
        {
          text: 'No',
          color: 'red'
        }
      ]
      ]
    }).open();
  }

  resendInvoiceMessage(customerid, smscredits){

    const self      = this;
    const app       = self.$f7;

    if(!this.$f7.online)
    {
      return;
    }

    const bodyFormData = {
      Mode:'ResendInvoiceMessage',
      customerid:customerid,
      smscredits:smscredits,
    };

    app.preloader.show();

    // Simulate Ajax Request
    setTimeout(()=> {

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/ledger.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

        // We got user data from request

        // Hide Preloader
        app.preloader.hide();

        const resdata = JSON.parse(res.data);

        const toastWithButton = app.toast.create({
          text: resdata.msg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();

      }).catch(e => {
          console.log(e);
      });

    }, 500);

  }

  manageOutstandingAction(recordid, phone, hasinvoiceid, invoiceid){

    const self	= this;
    const app	= self.$f7;
    const $$	= self.Dom7;

    const clientdetail  = localStorage.getItem('agency_clientdetail') !== undefined && localStorage.getItem('agency_clientdetail') !== null ? JSON.parse(localStorage.getItem('agency_clientdetail')):{};

    const{areaid, filterarea, lineid, filterline, hawkerid, filterhawker} = this.state;

    const appButton     = [{
      text: 'Select an action to proceed',
      label: true,
    }];
    
    appButton.push({
      text: 'Make Payment',
      bold: true,
      color:'blue',
      onClick:()=>{

        if(this.$f7.online)
        {
          /*this.$f7.views.main.router.navigate(`/makepayment/${recordid}/`);*/
          this.$f7.views.main.router.navigate(`/makepayment/${recordid}/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&fromarea=customersoutstanding`);
        }
      }
    });

    
    appButton.push({
      text: 'View Ledger',
      bold: true,
      color:'blue',
      onClick:()=>{

        if(this.$f7.online)
        {
          this.$f7.views.main.router.navigate(`/ledger/${recordid}/`);
        }
      }
    });

    {
      hasinvoiceid && Number(invoiceid) > 0 ? (
        appButton.push({
          text: 'View Last Invoice',
          bold: true,
          color:'blue',
          onClick:()=>{
    
            if(this.$f7.online)
            {
              this.$f7.views.main.router.navigate(`/invoicedetail/${invoiceid}/`);
            }
          }
        })
      ):null
    }

    app.actions.create({
      buttons: [
      // First group
      appButton,
      // Second group
      [
        {
          text: 'Cancel',
          color: 'red'
        }
      ]]
    }).open();
  }

	render(){
		return (
			<SMSCreditContext.Provider value={{
			  ...this.state,
				handleUserInput:this.handleUserInput,
				getSMSCreditReport:this.getSMSCreditReport,
				setNewFilterData:this.setNewFilterData,
				openFilterPopup:this.openFilterPopup,
				deleteFilter:this.deleteFilter,
				navigateToLink:this.navigateToLink,
				Paging:this.Paging,
				deleteFilterAction:this.deleteFilterAction,
				init:this.init,
				manageOutstandingAction:this.manageOutstandingAction,
			}}
			>
			{this.props.children}
			</SMSCreditContext.Provider>
		);
	}
}

const SMSCreditConsumer = SMSCreditContext.Consumer;

export {SMSCreditProvider, SMSCreditConsumer, SMSCreditContext};