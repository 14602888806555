import React,{Component} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppFav from '../pages/appfav';
import scanlogo from '../assets/images/scanlogo.png';
import logo404 from '../assets/images/orlo404.png';

import {
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    BlockTitle,
    Link,
    Block,
    List,
    Row,
    Col,
    ListItem,
    Chip,
    Button,
    Fab,
    Icon,
    Page,
    PageContent,
  } from 'framework7-react';

  export default class ViewPaymentDetail extends Component {
    constructor(props) {
      super(props);

      this.state = {
        clientdetail:[],
        paymentid:'',
        paymentdetail:[],
        isptr:false,
        deleterecordid:'',
        isdataloaded:false
      };

      this.getPaymentDetail       = this.getPaymentDetail.bind(this);
    }
    
    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          paymentid:this.props.f7route.params.id
        }
      },()=>{

        if(Number(this.state.paymentid) > 0)
        {
          this.getPaymentDetail();
        }
        this.hideSplashScreen();
      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }
    
    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }
        
    getPaymentDetail(){
      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetPaymentDetail',
          clientid:self.state.clientdetail.id,
          paymentid:self.state.paymentid,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/payments.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({

                    paymentdetail:resdata.paymentdetail,
                    isptr:false,
                    isdataloaded:true

                },()=>{

                    this.Dom7('.subscription').addClass('animate-bottom');
  
                  });
              }
              else
              {
                this.setState({
                    paymentdetail:[],
                    isptr:false,
                    isdataloaded:true
                });
              }

          }).catch(e => {
              console.log(e);
          });

      },1000);
    }

    render(){

        const{paymentid, paymentdetail, isdataloaded} = this.state;

          return(
            <Popup className="demo-popup">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link popupClose>
                      <i className="icon icon-back"></i>                      
                    </Link>
                    <img className="navbarlogo" src={scanlogo} alt="logo" />
                  </NavLeft>
                  <NavTitle>Payment Detail</NavTitle>
                  <NavRight className="right-with-menu">
                    <Link onClick={()=>{
            
                        this.getPaymentDetail();
            
                    }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                  </NavRight>
                </Navbar>
                <Page className="page" name="PaymentDetail">
                <PageContent style={{paddingTop:0}}>
                    {
                        isdataloaded ? (
                            <Block>
                                <BlockTitle medium className="zeroleftmargin" style={{fontSize:'18px'}}>{paymentdetail.name}</BlockTitle>
                                <List>
                                    <ListItem title="Customer ID">
                                        <div slot="after" style={{width:'100px'}}>#{paymentdetail.customerid}</div>
                                    </ListItem>
                                    <ListItem title="Name">
                                        <div slot="after" style={{width:'100px'}}>{paymentdetail.name}</div>
                                    </ListItem>
                                    <ListItem title="Phone">
                                        <div slot="after" style={{width:'100px'}}>{paymentdetail.phone}</div>
                                    </ListItem>
                                    <ListItem title="Receipt By">
                                        <div slot="after" style={{width:'100px'}}>{paymentdetail.receiptby}</div>
                                    </ListItem>
                                    <ListItem title="Payment Date">
                                        <div slot="after" style={{width:'100px'}}>{paymentdetail.paymentdate}</div>
                                    </ListItem>
                                    <ListItem title="Amount Paid">
                                        <div slot="after" style={{width:'100px'}}>{paymentdetail.amountpaid}</div>
                                    </ListItem>
                                    <ListItem title="Payment Method">
                                        <div slot="after" style={{width:'100px'}}>{paymentdetail.paymentmethod}</div>
                                    </ListItem>
                                    <ListItem title="Discount">
                                        <div slot="after" style={{width:'100px'}}>{paymentdetail.discount}</div>
                                    </ListItem>
                                    <ListItem title="Coupon">
                                        <div slot="after" style={{width:'100px'}}>{paymentdetail.coupon}</div>
                                    </ListItem>
                                    <ListItem title="Remark">
                                        <div slot="after" style={{width:'100px'}}>{paymentdetail.remark}</div>
                                    </ListItem>
                                </List>
                                <br />
                            </Block>
                        ):(<AppListItemLoading />)
                    }
                    <br /><br />
                    </PageContent>
                    <AppFav position="center-bottom" text="Make Payment" icon="plus" url="makepayment" />
                </Page>
            </Popup>
          );

    }
}