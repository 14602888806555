import React,{Component} from 'react';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
  } from 'framework7-react';

  export default class BillCollectionSummaryFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let lastdate = lastDay.getDate();

      let startdate = today.getFullYear()+'-'+(currmonth-1)+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+lastdate;

      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        filterstartmonthyear:monthNames[Number(today.getMonth()-1)]+' - '+today.getFullYear(),
        filterendmonthyear:monthNames[Number(today.getMonth())]+' - '+today.getFullYear(),
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'Select',
        arealist:[],
        linelist:[],
        linemanlist:[],
        hawkerlist:[],
        reportmonthyearlist:[],
        hassmartmonthyear:false,
        hassmartline:false,
        hassmartlineman:false,
        hassmarthawker:false,
        hassmartarea:false,
        filtervars:[],
      };

      this.areaAutocompletePopup            = this.areaAutocompletePopup.bind(this);
      this.lineAutocompletePopup            = this.lineAutocompletePopup.bind(this);
      this.linemanAutocompletePopup         = this.linemanAutocompletePopup.bind(this);
      this.hawkerAutocompletePopup          = this.hawkerAutocompletePopup.bind(this);
      this.startMonthYearAutocompletePopup  = this.startMonthYearAutocompletePopup.bind(this);
      this.endMonthYearAutocompletePopup    = this.endMonthYearAutocompletePopup.bind(this);
      this.applyFilter                      = this.applyFilter.bind(this);
      this.handleUserInput                  = this.handleUserInput.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{
        
        this.getReportMonthYear();
        this.areaAutocompletePopup();
        this.lineAutocompletePopup();
        this.hawkerAutocompletePopup();

        this.hideSplashScreen();

        let{lineid, hawkerid, filterline, filterhawker, areaid, filterarea, startdate, filterstartmonthyear, filterendmonthyear, enddate} = this.state.filtervars;

        if(Number(areaid) < 1 || areaid === "NaN")
        {
          areaid      = "";
          filterarea  = "Select";
        }

        if(Number(lineid) < 1 || lineid === "NaN")
        {
          lineid      = "";
          filterline  = "Select";
        }
        
        if(Number(hawkerid) < 1 || hawkerid === "NaN")
        {
          hawkerid      = "";
          filterhawker  = "Select";
        }

        this.setState({
          areaid:areaid,
          lineid:lineid,
          hawkerid:hawkerid,
          filterarea:filterarea,
          filterline:filterline,
          filterhawker:filterhawker,
          startdate:startdate,
          filterstartmonthyear:filterstartmonthyear,
          enddate:enddate,
          filterendmonthyear:filterendmonthyear,
        },()=>{
          this.$$('#autocomplete-area-popup').find('.item-after').text(this.state.filterarea);
          this.$$('#autocomplete-line-popup').find('.item-after').text(this.state.filterline);
        })

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;

      self.autocompleteStartMonthYearPopup.destroy();
      self.autocompleteEndMonthYearPopup.destroy();
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();
      self.hawkerAutocomplete.destroy();
    }

    startMonthYearAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchreportmonthyearlist  = this.state.reportmonthyearlist;

      self.autocompleteStartMonthYearPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-startmonthyear-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectstartmonthyear/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchreportmonthyearlist.length; i += 1) {
            if (searchreportmonthyearlist[i].monthname.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchreportmonthyearlist[i].year.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchreportmonthyearlist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            /*$('#autocomplete-startmonthyear-name2').text(value[0].monthname+" - "+value[0].year);*/

            let month       = Number(value[0].month);
            let year        = Number(value[0].year);

            self.setState({
                startdate:month+"/01"+"/"+year,
                filterstartmonthyear:value[0].monthname+" - "+value[0].year
            });
          },
        },
      });
    }

    endMonthYearAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;
      const searchreportmonthyearlist  = this.state.reportmonthyearlist;

      self.autocompleteEndMonthYearPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-endmonthyear-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectendmonthyear/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchreportmonthyearlist.length; i += 1) {
            if (searchreportmonthyearlist[i].monthname.toLowerCase().indexOf(query.toLowerCase()) >= 0 || searchreportmonthyearlist[i].year.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchreportmonthyearlist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            /*$('#autocomplete-endmonthyear-name2').text(value[0].monthname+" - "+value[0].year);*/

            let month       = Number(value[0].month);
            let year        = Number(value[0].year);
            let lastdate   = value[0].lastdate;

            self.setState({
                enddate:month+"/"+lastdate+"/"+year,
                filterendmonthyear:value[0].monthname+" - "+value[0].year
            });
          },
        },
      });
    }

    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-area-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              areaid:id,
              filterarea:name
            });            
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
              Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-line-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              lineid:id,
              filterline:name
            },()=>{
              self.checkSingleHawker();
            });

          },
        },
      });
    }

    linemanAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      const searchlinemanlist  = this.state.linemanlist;

      self.linemanAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-lineman-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectlineman/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinemanlist.length; i += 1) {
            if (searchlinemanlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinemanlist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            $('#autocomplete-lineman-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              linemanid:id,
              filterlineman:name
            });

          },
        },
      });
    }

    hawkerAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      self.hawkerAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-hawker-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selecthawker/',
        requestSourceOnOpen:true,
        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetHawker',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    hawkerlist:res.recordlist,
                  }
                },()=>{

                  const searchhawkerlist  = self.state.hawkerlist;

                  for (let i = 0; i < searchhawkerlist.length; i += 1) {
                    if (searchhawkerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchhawkerlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            /*$('#autocomplete-hawker-popup').find('.item-after').text(value[0].name);*/

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              hawkerid:id,
              filterhawker:name
            });

          },
        },
      });
    }

    applyFilter(){

      if(!this.$f7.online)
      {
        return;
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    getReportMonthYear(){

        const self      = this;
        const app       = self.$f7;
  
        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));
  
        const bodyFormData = {
            Mode:'GetReportMonthYear',
            clientid:clientdetail.id,
            areaid:self.state.areaid,
        };
  
        setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {
  
              app.preloader.hide();
  
              app.ptr.done();            
  
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({

                  reportmonthyearlist:resdata.recordset,
                  isptr:false

                },()=>{

                  if(this.state.hassmartmonthyear === false)
                  {
                    this.setState({hassmartmonthyear:true});
                    this.startMonthYearAutocompletePopup();
                    this.endMonthYearAutocompletePopup();
                  }

                });
              }
              else
              {
                this.setState({

                  reportmonthyearlist:[],
                  isptr:false

                },()=>{

                  if(this.state.hassmartmonthyear === false)
                  {
                    this.setState({hassmartmonthyear:true});
                    this.startMonthYearAutocompletePopup();
                    this.endMonthYearAutocompletePopup();
                  }

                });
              }
    
          }).catch(e => {

              console.log(e);

          });
  
        }, 500);
  
    }

    getLineman(){

      const self      = this;
      const app       = self.$f7;

      const bodyFormData = {
          Mode:'GetLineman',
          type:'customerfilter',
          clientid:self.state.clientdetail.id,
      };

      setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/lineman.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              app.preloader.hide();

              app.ptr.done();            

              const resdata = JSON.parse(res.data);

              if(resdata.success === true)
              {
                this.setState({

                  linemanlist:resdata.recordlist,
                  isptr:false

                },()=>{

                  if(this.state.hassmartlineman === false)
                  {
                    this.setState({hassmartlineman:true});
                    this.linemanAutocompletePopup();
                  }
                  
                });
              }
              else
              {
                this.setState({

                    linemanlist:[],
                    isptr:false

                },()=>{

                  if(this.state.hassmartlineman === false)
                  {
                    this.setState({hassmartlineman:true});
                    this.linemanAutocompletePopup();
                  }
                  
                });
              }

          }).catch(e => {

              console.log(e);

          });

      }, 500);

    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    checkSingleHawker(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
        Mode:'CheckHawker',
        type:'sequencefilter',
        clientid:self.state.clientdetail.id,
        areaid:self.state.areaid,
        lineid:self.state.lineid,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({

                hawkerid:resdata.recordlist.id,
                filterhawker:resdata.recordlist.name,
                /*hascustomer:false,
                isfetched:false,*/

              });
            }
            else
            {
              this.setState({

                hawkerid:'',
                filterhawker:'Select',

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    render(){

      const{lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, filterstartmonthyear, filterendmonthyear} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{applyBillCollectionSummaryFilter}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="BillCollectionSummaryFilter">
                        <Block>
                        <List noHairlinesMd>
                          <ListItem link="#" id="autocomplete-startmonthyear-popup" className="autocomplete-startmonthyear-popup" title="From Month Year">
                            <div slot="after" id="autocomplete-startmonthyear-name2">{filterstartmonthyear}</div>
                          </ListItem>
                          <ListItem link="#" id="autocomplete-endmonthyear-popup" className="autocomplete-endmonthyear-popup" title="End Month Year">
                            <div slot="after" id="autocomplete-endmonthyear-name2">{filterendmonthyear}</div>
                          </ListItem>
                          <ListItem link="#" id="autocomplete-area-popup" title="Area" after="Select"></ListItem>
                          <ListItem link="#" id="autocomplete-line-popup" title="Line" after="Select"></ListItem>
                          {/*<ListItem link="#" id="autocomplete-lineman-popup" title="Lineman" after="Select"></ListItem>*/}
                          <ListItem link="#" id="autocomplete-hawker-popup" className="autocomplete-hawker-popup" title="Hawker" after={filterhawker}></ListItem>
                        </List>
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                            let tempstartdate   = new Date(startdate);
                            let tempenddate     = new Date(enddate);

                            if(tempenddate.getTime() < tempstartdate.getTime())
                            {
                                const toastWithButton = this.$f7.toast.create({
                                text: "Start date can't be less then End date",
                                closeButton: true,
                                closeTimeout: 3000,
                                });
                        
                                toastWithButton.open();
                        
                                return false;
                            }

                            Promise.all([
                                setTimeout(()=> {

                                    applyBillCollectionSummaryFilter(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, startdate, enddate, filterstartmonthyear, filterendmonthyear);
                                                    
                                }, 500)
                                
                            ])
                            .then(() => {
                
                                setTimeout(()=> {
                
                                this.$$('.popup-close').trigger('click');
                    
                                }, 500)
                                
                            })

                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}