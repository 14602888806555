import React,{Component} from 'react';
import AppSaveRecordButton from '../pages/appsaverecordbutton';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Block,
    List,
    ListInput,
    Link
  } from 'framework7-react';

  export default class Dashboard extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          clientdetail:[],
          password: '',
          newpassword: '',
          confirmpassword:'',
        };

        this.handleUserInput = this.handleUserInput.bind(this);        
    }

    componentDidMount(){
    
        let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

        this.setState(()=>{
            return{
            clientdetail:clientdetail
            }
        },()=>{
            this.hideSplashScreen();
        });

    }

    hideSplashScreen(){
        document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){
        const name  = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value});
    }

    changeDefaultPassword(){

        const self      = this;
        const app       = self.$f7;
        const router    = self.$f7router;

        if(!this.$f7.online)
        {
         return;
        }

        let errorstr    = "";

        app.input.validateInputs('.adddata');

        if(self.state.clientdetail.ispasswordupdate === 1)
        {
            if(self.state.password === '' || self.state.newpassword === '' || self.state.confirmpassword === '')
            {
                errorstr    = "Please enter all required field.";
            }
        }
        else
        {
            if(self.state.newpassword === '' || self.state.confirmpassword === '')
            {
                errorstr    = "Please enter all required field.";
            }
        }

        if(errorstr === "")
        {
            if(self.state.newpassword.length < 6)
            {
                errorstr    = "Password must have at least 6 characters.";
            }
            if((self.state.newpassword !== self.state.confirmpassword) && errorstr === "")
            {
                errorstr    = "Passwords do not match.";                
            }
        }

        if(errorstr !== "")
        {
            const toastWithButton = app.toast.create({
                text: errorstr,
                closeButton: true,
                closeTimeout: 3000,
            });
    
            toastWithButton.open();
            /*router.back();*/
            return false;
        }

        app.preloader.show();

        let bodyFormData = "";

        let passchangemode  = "";
        let recordid        = "";

        if(self.state.clientdetail.islineman === 1)
        {
            passchangemode  = "ChangeLinemanPassword";
            recordid        = self.state.clientdetail.linemanid;
        }
        else if(self.state.clientdetail.ismanager === 1)
        {
            passchangemode  = "ChangeManagerPassword";
            recordid        = self.state.clientdetail.managerid;
        }
        else if(self.state.clientdetail.ishawker === 1)
        {
            passchangemode  = "ChangeHawkerPassword";
            recordid        = self.state.clientdetail.hawkerid;
        }
        else
        {
            passchangemode  = "ChangeAgencyPassword";
            recordid        = self.state.clientdetail.id;
        }

        bodyFormData = {
            Mode:passchangemode,
            password:self.state.password,
            newpassword:self.state.newpassword,
            confirmpassword:self.state.confirmpassword,
            recordid:recordid,
            ispasswordupdate:self.state.clientdetail.ispasswordupdate
        };

        // Simulate Ajax Request
        setTimeout(()=> {
            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/changepassword.php?t=${Date.now()}`,bodyFormData)
            .then(function (res) {

                app.preloader.hide();

                const resdata = JSON.parse(res.data);

                const toastWithButton = app.toast.create({
                    text: resdata.msg,
                    closeButton: true,
                    closeTimeout: 3000,
                });

                toastWithButton.open();

                if(resdata.success === true)
                {
                    /*if(Number(self.state.clientdetail.islineman) !== 1 && Number(self.state.clientdetail.ismanager) !== 1 && Number(self.state.clientdetail.ishawker) !== 1)
                    {
                        localStorage.setItem("agency_clientdetail",JSON.stringify(resdata.clientdetail));
                    }

                    router.navigate('/dashboard/');*/

                    setTimeout(() => {

                        localStorage.setItem("agency_accesstoken",'');
                        localStorage.setItem("agency_clientdetail",JSON.stringify([]));
                        localStorage.setItem("agency_isadminlogin",false);
                        localStorage.setItem("agency_adminid","");
                        localStorage.setItem("agency_isadminverified",false);
                        localStorage.setItem("agency_authtoken",'');
         
                        router.navigate('/login/');

                    }, 1000);
                }

                }).catch(e => {
                console.log(e);
            });

        }, 1000);           

    }

    render(){
      return(
            <Page pageContent={false} name="ChangePassword">
                <Navbar sliding={true}>
                    <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                        <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                    </NavLeft>
                    <NavTitle>Change Password</NavTitle>
                    <NavRight className="right-with-menu">
                    </NavRight>
                </Navbar>
                <PageContent>
                <Block strong>
                    {
                        this.state.clientdetail.ispasswordupdate ?null:(<p><b>Note</b> : For security reasons, Please change your password. Password can not be same as existing password.</p>)
                    }
                    <List noHairlinesMd>
                    {
                        this.state.clientdetail.ispasswordupdate ?(
                        <ListInput
                            name="password"
                            className="adddata"
                            label="Existing Password"
                            floatingLabel
                            type="password"
                            onInput={this.handleUserInput}
                            placeholder="Existing Password"
                            clearButton required validate
                        >
                        </ListInput>
                        ):null
                    }
                        <ListInput
                            name="newpassword"
                            className="adddata"
                            label="New Password"
                            floatingLabel
                            type="password"
                            onInput={this.handleUserInput}
                            placeholder="New Password"
                            clearButton required validate
                        >
                        </ListInput>
                        <ListInput
                            name="confirmpassword"
                            className="adddata"
                            label="Confirm Password"
                            floatingLabel
                            type="password"
                            onInput={this.handleUserInput}
                            placeholder="Retype Password"
                            clearButton required validate
                        >
                        </ListInput>
                    </List>
                </Block>
                </PageContent>
                <AppSaveRecordButton saveDetail={this.changeDefaultPassword.bind(this)} />
            </Page>
      );
    }
}