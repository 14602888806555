import React,{Component,Fragment} from 'react';
import AppItemNotFound from '../pages/appitemnotfound';
import AppCardItemLoading from '../pages/appcarditemloading';
import { FilterConsumer } from '../context/FilterContext';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    BlockTitle,
    Link,
    Block,
    Icon,
    Chip,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Fab,
    List,
    ListItem,
    AccordionContent,
  } from 'framework7-react';

  export default class RestartCustomerList extends Component {
    
    constructor(props) {
      
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let lastdate = lastDay.getDate();

      let startdate = today.getFullYear()+'-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+lastdate;
     
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        isfetched:false,
        isptr:false,
        ismount:false,
        hasallcustomer:false,
        hasfilter:true,
        removefiltername:'',
        applyremovefilter:false,
        isdatafetched:false,
        hasdataavailable:false,
        effect:'blink',
        restartcustomerlist:[],
        areaid:'',
        areaname:'',
        inventoryid:'',
        inventoryname:'',
        lineid:'',
        linename:'',
        totalstock:0,
      };

      this.handleUserInput      = this.handleUserInput.bind(this);
      this.getRestartCustomerList = this.getRestartCustomerList.bind(this);
      this.openFilterPopup      = this.openFilterPopup.bind(this);
      this.setNewFilterData     = this.setNewFilterData.bind(this);
      this.navigateToLink       = this.navigateToLink.bind(this);
    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          customerid:this.props.f7route.params.id
        }
      },()=>{

        setTimeout(()=> {

          this.setState({ismount:true});

        }, 1200);

        this.hideSplashScreen();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          setTimeout(()=> {

            this.getRestartCustomerList();
            
          }, 200)

        })

      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    getRestartCustomerList(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
    
        const bodyFormData = {
          Mode:'GetRestartCustomerList',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          startdate:self.state.startdate,
          enddate:self.state.enddate,
          areaid:self.state.areaid,
          inventoryid:self.state.inventoryid,
          lineid:self.state.lineid,
        };

        this.setState(()=>{

          return{
            isfetched:false
          }
          
        },()=>{

          setTimeout(()=> {
  
          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });
  
          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // Hide Pull To Refresh
              app.ptr.done();

              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                    isfetched:true,
                    restartcustomerlist:resdata.recordset,
                    totalrecord:resdata.totalrecord,
                    hasdataavailable:true,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });

              }
              else
              {
                this.setState({
                    isfetched:true,
                    restartcustomerlist:[],
                    totalrecord:'0',
                    hasdataavailable:false,
                },()=>{

                  this.Dom7('.outstandingwrapper').addClass('animate-bottom');

                });
              }
    
          }).catch(e => {
              console.log(e);
          });

        },500);

      });
    }

    downloadReportPDF(){

        const self      = this;
        const app       = self.$f7;

        const bodyFormData = {
          Mode:'GetRestartCustomerListPDF',
          clientid:self.state.clientdetail.id,
          stateid:self.state.clientdetail.stateid,
          cityid:self.state.clientdetail.cityid,
          startdate:self.state.startdate,
          enddate:self.state.enddate,
          areaid:self.state.areaid,
          inventoryid:self.state.inventoryid,
          lineid:self.state.lineid,
        };

        if(this.state.isptr !== true)
        {
          app.preloader.show();
        }

        // Simulate Ajax Request
        setTimeout(()=> {

            const accesstoken  = localStorage.getItem('agency_accesstoken');
          
            app.request.setup({
              headers:{
                Authorization: `${accesstoken}`
              }
            });

            app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
            .then((res)=> {

                // We got user data from request

                // Hide Preloader
                app.preloader.hide();

                // Hide Pull To Refresh
                app.ptr.done();                

                const resdata = JSON.parse(res.data);
                if(resdata.success === true)
                {
                    //console.log(resdata.pdffilepath);
                   // window.location.href= 'http://orlopay/agency/';
                    window.open(resdata.pdffilepath);
                }
                else
                {
                    const toastWithButton = app.toast.create({
                      text: resdata.msg,
                      closeButton: true,
                      closeTimeout: 3000,
                    });

                    toastWithButton.open();    
                }
            }).catch(e => {
                console.log(e);
            });

        },500);
    }

    setNewFilterData(startdate, enddate, areaid, areaname, inventoryid, inventoryname, lineid, linename){

      if(!this.$f7.online)
      {
        return;
      }

      Promise.all([
            
        setTimeout(()=> {

          this.Dom7('.waiting').addClass('animate-bottom');

        }, 50)

      ])
      .then(() => {

        this.setState(()=>{
        
          return{
            startdate:startdate,
            enddate:enddate,
            areaid:areaid,
            areaname:areaname,
            inventoryid:inventoryid,
            inventoryname:inventoryname,
            loaded:false,
            applyfilter:false,
            popupOpened:false,
            page:1,
            lineid:lineid,
            linename:linename,
          }
  
        },()=>{

         const{startdate, enddate, areaid, inventoryid, lineid} = this.state;
  
          if((startdate !== "" && startdate !== undefined) || (enddate !== "" && enddate !== undefined) || (areaid !== "" && areaid !== undefined) || (inventoryid !== "" && inventoryid !== undefined) || (lineid !== "" && lineid !== undefined))
          {
            this.setState({hasfilter:true,hasallcustomer:false});
          }
          else
          {
            this.setState({hasfilter:true,hasallcustomer:true});
          }
  
          this.getRestartCustomerList();
  
        });

      })
      
    }

    openFilterPopup(){
      
      if(!this.$f7.online)
      {
        return;
      }

      this.$f7router.navigate('filterpopup/');
    }

    deleteFilter(){

      this.setState({
        applyremovefilter:true
      })
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        this.$f7router.navigate(`/${link}/`);
        /*this.$f7.views.main.router.navigate(`/${link}/`);*/
      }

    }

    render(){

      const{isfetched, startdate, enddate, hasallcustomer, hasfilter, removefiltername, applyremovefilter, ismount, hasdataavailable, areaid, areaname, inventoryid, inventoryname, restartcustomerlist, lineid, linename, totalrecord} = this.state;

      let datestart     = "";
      let newstartdate  = "";

      if(startdate !== "" && startdate !== undefined)
      {
        newstartdate = new Date(startdate);

        let currmonth = newstartdate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        datestart = newstartdate.getDate()+"-"+currmonth+"-"+newstartdate.getFullYear();
      }

      let dateend     = "";
      let newenddate  = "";

      if(enddate !== "" && enddate !== undefined)
      {
        newenddate = new Date(enddate);

        let currmonth = newenddate.getMonth()+1;
        if(currmonth < 10)
        {
            currmonth = "0"+currmonth;
        }
        dateend = newenddate.getDate()+"-"+currmonth+"-"+newenddate.getFullYear();
      }

      return(
        <Page pageContent={false} className="page" name="RestartCustomerList">
        <Navbar sliding={true}>
          <NavLeft>
            <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
            <Link href={`/dashboard/`} className="clearmenupadding">
              <img className="navbarlogo" src={scanlogo} alt="logo" />
            </Link>
          </NavLeft>
          <NavTitle>Restart Customer List</NavTitle>
          <NavRight className="right-with-menu">
          <Link onClick={()=>{

            Promise.all([
                
              setTimeout(()=> {

                this.Dom7('.waiting').addClass('animate-bottom');

              }, 50)

            ])
            .then(() => {

              setTimeout(()=> {

                this.getRestartCustomerList();
                
              }, 200)

            })
            
          }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
          </NavRight>
        </Navbar>
      <FilterConsumer>{(value)=>{

        const{restartcustomerfilterdata, applyRestartCustomerFilter} = value;

        if(ismount === true)
        {
          setTimeout(()=> {

            if(removefiltername !== "" && this.state.applyremovefilter === true)
            {
              if(removefiltername === 'daterange')
              {
                applyRestartCustomerFilter('', '', areaid, areaname, inventoryid, inventoryname, lineid, linename);
              }
              else if(removefiltername === 'area')
              {
                applyRestartCustomerFilter(startdate, enddate, '', '', inventoryid, inventoryname, lineid, linename);
              }
              else if(removefiltername === 'stock')
              {
                applyRestartCustomerFilter(startdate, enddate, areaid, areaname, '', '', lineid, linename);
              }
              else if(removefiltername === 'line')
              {
                applyRestartCustomerFilter(startdate, enddate, areaid, areaname, inventoryid, inventoryname, '', '');
              }
            }

            if(((restartcustomerfilterdata.startdate !== startdate) && restartcustomerfilterdata.startdate !== undefined) || ((restartcustomerfilterdata.enddate !== enddate) && restartcustomerfilterdata.enddate !== undefined) || ((restartcustomerfilterdata.areaid !== areaid) && restartcustomerfilterdata.areaid !== undefined) || ((restartcustomerfilterdata.inventoryid !== inventoryid) && restartcustomerfilterdata.inventoryid !== undefined) || ((restartcustomerfilterdata.lineid !== lineid) && restartcustomerfilterdata.lineid !== undefined) || applyremovefilter === true)
            {
              this.setNewFilterData(restartcustomerfilterdata.startdate, restartcustomerfilterdata.enddate, restartcustomerfilterdata.areaid, restartcustomerfilterdata.areaname, restartcustomerfilterdata.inventoryid, restartcustomerfilterdata.inventoryname, restartcustomerfilterdata.lineid, restartcustomerfilterdata.linename);

              this.setState({
                removefiltername:'',
                applyremovefilter:false
              })

            }

          }, 500)
        }
        
          return(
            <PageContent>
              {
                hasfilter ? (
                  <Block strong className="selectedfilter" style={{marginTop:0}}>
                      {hasallcustomer ? (<Chip text={`Customer : All`} mediaBgColor="blue" media="A"/>):null}
                      {datestart ? (<Chip text={`Date : (${datestart}) - (${dateend})`} mediaBgColor="blue" media={`D`}
                      />):null}
                      {Number(areaid) ? (<Chip text={`Area : ${areaname}`} mediaBgColor="pink" media={areaname.toUpperCase().substring(0, 1)} deleteable
                      onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'area'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                      />):null}
                      {Number(inventoryid) ? (<Chip text={`Inventory : ${inventoryname}`} mediaBgColor="yellow" media={inventoryname.toUpperCase().substring(0, 1)} deleteable
                      onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'stock'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                      />):null}
                      {Number(lineid) ? (<Chip text={`Line : ${linename}`} mediaBgColor="green" media={linename.toUpperCase().substring(0, 1)} deleteable
                      onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.setState(()=>{
                              return {
                                removefiltername:'line'
                              }
                            },()=>{
                              this.refs.actionsFilterGroup.open()
                            })
                          }
                        }}
                      />):null}
                      &nbsp;<Chip text="Modify" color="blue" onClick={()=>{
                          if(this.$f7.online)
                          {
                            this.$f7router.navigate(`filterpopup/?startdate=${startdate}&enddate=${enddate}&areaid=${areaid}&areaname=${areaname}&inventoryid=${inventoryid}&inventoryname=${inventoryname}&lineid=${lineid}&linename=${linename}`);
                          }
                          /*this.openFilterPopup();*/
                      }}/>
                  </Block>
                ):null
              }
              {
                isfetched? (<Fragment>
                {
                  hasdataavailable ? (<Fragment>
                    <BlockTitle className="totalrec">Total Records : {totalrecord}</BlockTitle>
                    <List accordionList>
                    {
                      restartcustomerlist.map((inventory)=>{
                          
                        return(
                          <ListItem accordionItem title={`${inventory.name}`} after={`${inventory.qty}`} key={inventory.id} className="accordion-item-opened2">
                            <AccordionContent>
                              <Block style={{marginLeft:'0',marginRight:'0'}}>
                                <BlockTitle>Restart Customer List</BlockTitle>
                                <div className="search-list searchbar-found">
                                  {
                                    inventory.detail.map((detail)=>{
                                        return(
                                            <Card outline key={detail.logid} className="recordset" style={{marginLeft:'0',marginRight:'0'}}>
                                              <CardHeader
                                              className="date"><div className="item-title">{detail.name2}</div><div className="item-title"></div></CardHeader>
                                              <CardContent>
                                                {detail.phone ? (<div style={{color:detail.blockcolor}}><b>Phone : </b>{detail.phone}
                                                  &nbsp;
                                                  {
                                                    detail.phone !== "---" ? (
                                                      <Link external href={`tel:${detail.phone}`} target="_system">
                                                        <Icon f7="phone_fill" style={{fontSize:'20px'}}></Icon>
                                                      </Link>
                                                    ):null
                                                  }
                                                </div>):null}
                                                {detail.area ? (<p><b>Area : </b>{detail.area}</p>):null}
                                                {detail.line ? (<p><b>Line : </b>{detail.line}</p>):null}
                                                {detail.fulladdress ? (<p><b>Address : </b>{detail.fulladdress}</p>):null}
                                                {detail.subscriptiondate ? (<p><b>Subscription Date : </b>{detail.subscriptiondate}</p>):null}
                                              </CardContent>
                                              <CardFooter>
                                              <Link onClick={()=>{this.navigateToLink(`editcustomer/${detail.custid}`)}} ignoreCache={true}>Edit</Link>
                                              <div>&nbsp;</div>
                                            </CardFooter>
                                            </Card>
                                        );
                                    })
                                  }
                                </div>
                                <br/>
                              </Block>
                            </AccordionContent>
                          </ListItem>
                        );
                      })
                    }
                    </List>
                  </Fragment>):(<AppItemNotFound notfoundtext ="No record found." />)
                }
                </Fragment>):(<AppCardItemLoading />)
              }
              <br />
            </PageContent>
          );
        }}
      </FilterConsumer>
          <Actions ref="actionsFilterGroup">
            <ActionsGroup>
              <ActionsLabel bold>Are you sure?</ActionsLabel>
              <ActionsButton color="blue" bold onClick={()=>{this.deleteFilter()}}>Yes</ActionsButton>
            </ActionsGroup>
            <ActionsGroup>
              <ActionsButton color="red">No</ActionsButton>
            </ActionsGroup>
          </Actions>
          {
            hasdataavailable ? (
              <Fab position="center-bottom" slot="fixed" text="Download" onClick={()=>{
                  if(this.$f7.online)
                    {
                      this.downloadReportPDF();
                    }
                }}>
                <Icon f7="printer_fill" style={{display:'inline-block'}}></Icon>
                <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
              </Fab>
            ):null
         }
        </Page>
    );
  }
}