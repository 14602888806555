import React,{Component} from 'react';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer, FilterContext } from '../context/FilterContext';

import {
    Page,
    Popup,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
    ListInput,
    Row,
    Col,
  } from 'framework7-react';

  export default class LedgerFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }

      let startdate = today.getFullYear()+'-'+currmonth+'-01';
      let enddate   = today.getFullYear()+'-'+currmonth+'-'+currday;
 
      this.state = {
        clientdetail:[],
        startdate:startdate,
        enddate:enddate,
        datetype:'begining',
        filtervars:[],
      };

      this.applyFilter               = this.applyFilter.bind(this);
      this.handleUserInput           = this.handleUserInput.bind(this);
    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{

        this.hideSplashScreen();

        let{datetype, startdate} = this.state.filtervars;

        this.setState({
          startdate:startdate,
          datetype:datetype,
        })

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;

    }

    applyFilter(){

      if(!this.$f7.online)
      {
        return;
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    render(){

      const{datetype, startdate} = this.state;

      return(
        <FilterConsumer>{(value)=>{

          const{applyLedgerFilter}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="LedgerFilter">
                    <Block>
                        <p style={{marginLeft:'15px'}}>Date Type</p>
                        <Row noGap>
                            <Col width="40">
                                <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="begining" name="datetype" checked={datetype === 'begining'} title="Begining"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                            <Col width="60">
                                <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                    <ListItem radio value="selectdate" name="datetype" checked={datetype === 'selectdate'} title="Selected Date"
                                    onChange={this.handleUserInput}
                                    ></ListItem>
                                </List>
                            </Col>
                        </Row>
                        {
                            datetype === 'selectdate' ? (
                                <List noHairlinesMd style={{marginBottom:'5px'}}>
                                    <ListInput
                                        name="startdate"
                                        id="startdate"
                                        label="Start Date"
                                        placeholder="Start Date"
                                        type="date"
                                        value={startdate}
                                        onChange={this.handleUserInput}
                                        calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                                    >
                                    </ListInput>
                                </List>
                            ):null
                        }                    
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                          /*let tempstartdate   = new Date(startdate);
                          let tempenddate     = new Date(enddate);

                          let checkenddate = tempstartdate;
                          checkenddate.setDate(checkenddate.getDate() + 31);
                    
                          if(tempenddate.getTime() > checkenddate.getTime())
                          {
                              const toastWithButton = this.$f7.toast.create({
                                  text: "Start and End date difference can not be larger than 31 days",
                                  closeButton: true,
                                  closeTimeout: 3000,
                              });
                      
                              toastWithButton.open();
                      
                              return false;
                          }*/
                          
                          Promise.all([
                            setTimeout(()=> {
              
                                applyLedgerFilter(datetype, startdate);
                  
                            }, 500)
                            
                          ])
                          .then(() => {
              
                            setTimeout(()=> {
              
                              this.$$('.popup-close').trigger('click');
                  
                            }, 500)
                            
                          })

                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </FilterConsumer>
      );
    }
}