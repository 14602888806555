import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import AppItemNotFound from '../pages/appitemnotfound';
import AppFav from '../pages/appfav';
import scanlogo from '../assets/images/scanlogo.png';
import { FilterConsumer } from '../context/FilterContext';

import {
    Page,
    PageContent,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Row,
    Col,
    Block,
    BlockTitle,
    Link,
    List,
    ListItem,
    ListInput,
    Button,
  } from 'framework7-react';

  export default class ManagePurchase extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;

      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let currday = today.getDate();

      if(currday < 10)
      {
          currday = "0"+currday;
      }
      let enddate       = today.getFullYear()+'-'+currmonth+'-'+currday;
      let purchasedate  = today.getFullYear()+'-'+currmonth+'-'+currday;
  
      this.state = {
        clientdetail:[],
        droppingpointid:'',
        droppingpointname:'Select',
        ismount:false,
        hassmartdroppingpoint:false,
        issmartdroppingpoint:false,
        isavailable:false,
        loaded:false,
        purchaselist:[],
        purchasedate:'',
        purchasestartdate:purchasedate,
        purchaseenddate:enddate,
        actionGridOpened: false,
        oneGroupOpened: false,
        deleterecordid:'',
        isptr:false,
        effect:'blink',
        inventorylist:[],
        inventoryid:'',
        inventoryname:'Select',
        hasfilter:false,
        totalrecord:'',
      };

      this.getAllPurchase                 = this.getAllPurchase.bind(this);
      this.navigateToLink                 = this.navigateToLink.bind(this);
      this.handleUserInput                = this.handleUserInput.bind(this);
      this.droppingPointAutocompletePopup = this.droppingPointAutocompletePopup.bind(this);
      this.getDroppingPoint               = this.getDroppingPoint.bind(this);
      this.deleteRecordAction             = this.deleteRecordAction.bind(this);
      this.stockAutocompletePopup         = this.stockAutocompletePopup.bind(this);
    }

    componentDidMount(){
  
     let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail
        }
      },()=>{

        this.hideSplashScreen();

        Promise.all([
            
          setTimeout(()=> {

            this.Dom7('.waiting').addClass('animate-bottom');

          }, 50)

        ])
        .then(() => {

          setTimeout(()=> {

            this.getAllPurchase();
            
          }, 200)

        })
        
      });

      this.stockAutocompletePopup();

      setTimeout(()=> {

        this.setState({ismount:true});

      }, 1200);
    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteStockPopup.destroy();
      self.autocompleteDroppingPointPopup.destroy();

    }

    stockAutocompletePopup(){

      const self = this;
      const app = self.$f7;

      self.autocompleteStockPopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-stock-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectstock/',
        requestSourceOnOpen:true,

        source(query, render) {
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/inventory.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetAllInventory',
              clientid:self.state.clientdetail.id,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    inventorylist:res.inventorylist,
                  }
                },()=>{

                  const searchlinelist  = self.state.inventorylist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            /*self.Dom7('#autocomplete-stock-name2').text(value[0].name);
            $('.autocomplete-stock-popup').removeClass('error');*/
            
            self.setState({
              inventoryid:value[0].id,
              inventoryname:value[0].name
            });

          },
        },
      });
    }

    droppingPointAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const searchdroppingpointlist  = this.state.droppingpointlist;

      self.autocompleteDroppingPointPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-droppingpoint-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectdroppingpoint/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchdroppingpointlist.length; i += 1) {
            if (searchdroppingpointlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchdroppingpointlist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            self.Dom7('#autocomplete-droppingpoint-filtername2').text(value[0].name);
            
            self.setState({
              droppingpointid:value[0].id,
              droppingpointname:value[0].name,
              issmartdroppingpoint:true,
            });

          },
        },
      });
    }

    fetchNewDroppingPoint(newdroppingpointid, newdroppingpointname){

      this.setState(()=>{

        return{

          droppingpointid:newdroppingpointid

        }

      },()=>{

        this.Dom7('#autocomplete-droppingpoint-filtername2').text(newdroppingpointname);
        this.getDroppingPoint();
        /*this.getLine();*/

      })

    }

    getDroppingPoint(){

      const self      = this;
      const app       = self.$f7;

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      const bodyFormData = {
          Mode:'GetDroppingPoint',
          clientid:clientdetail.id,
          ismanager:this.state.clientdetail.ismanager,
          areamanagerid:this.state.clientdetail.areamanagerid,
      };

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/droppingpoint.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request

            // Hide Preloader
            app.preloader.hide();

            // Hide Pull To Refresh
            app.ptr.done();            

            const resdata = JSON.parse(res.data);
  
            if(resdata.success === true)
            {
              this.setState({
                droppingpointlist:resdata.recordlist,
              },()=>{

                if(this.state.hassmartdroppingpoint === false)
                {
                  this.setState({hassmartdroppingpoint:true});
                  this.droppingPointAutocompletePopup();
                }
                
              });
            }
            else
            {
              this.setState({
                droppingpointlist:[],
              },()=>{

                if(this.state.hassmartdroppingpoint === false)
                {
                  this.setState({hassmartdroppingpoint:true});
                  this.droppingPointAutocompletePopup();
                }

              });
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'status')
      {
        this.setState({status:!this.state.status});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        });
      }
    }

    getAllPurchase(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
  
        const bodyFormData = {
            Mode:'GetAllPurchase',
            clientid:this.state.clientdetail.id,
            /*purchasedate:this.state.purchasedate,*/
            ismanager:this.state.clientdetail.ismanager,
            areamanagerid:this.state.clientdetail.areamanagerid,
            purchasestartdate:this.state.purchasestartdate,
            /*purchaseenddate:this.state.purchaseenddate,*/
            droppingpointid:self.state.droppingpointid,
            inventoryid:self.state.inventoryid,
        };

        this.setState({
          loaded:false
        })

        /*if(this.state.isptr !== true)
        {
          app.preloader.show();
        }*/
  
        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/purchase.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                this.setState({
                  loaded:true,
                  isavailable:true,
                  purchaselist:resdata.recordlist,
                  isptr:false,
                  totalqty:resdata.totalqty,
                  totalrecord:resdata.totalrecord
                });
              }
              else{
                this.setState({
                  loaded:true,
                  isavailable:false,
                  isptr:false,
                  totalqty:resdata.totalqty,
                  totalrecord:0
                });
              }
    
          }).catch(e => {
              console.log(e);
          });
  
        }, 500);
    }

    deleteRecordAction(recordid){

      const self	= this;
      const app	= self.$f7;

		  app.actions.create({
        buttons: [
        // First group
        [
          {
            text: 'Are you sure?',
            label: true
          },
          {
          text: 'Yes',
          bold: true,
          color:'blue',
          onClick:()=>{

              if(this.$f7.online)
              {
                this.setState(()=>{
                  return{
                    deleterecordid:recordid
                  }
                },()=>{

                  this.deleteRecord();

                })
              }
            }
          }
        ],
        // Second group
        [
          {
            text: 'No',
            color: 'red'
          }
        ]
        ]
      }).open();
    }

    deleteRecord(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      const bodyFormData = {
          Mode:'DeletePurchase',
          clientid:this.state.clientdetail.id,
          recordid:this.state.deleterecordid,
      };

      app.preloader.show();

      // Simulate Ajax Request
      setTimeout(()=> {

        const accesstoken  = localStorage.getItem('agency_accesstoken');
          
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/purchase.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

            // We got user data from request
    
            // Hide Preloader
            app.preloader.hide();

            const resdata = JSON.parse(res.data);

            const toastWithButton = app.toast.create({
              text: resdata.msg,
              closeButton: true,
              closeTimeout: 3000,
            });
  
            toastWithButton.open();

            if(resdata.success === true)
            {
              this.setState(
                ()=>{
                  return {
                    deleterecordid:'',
                  }
                },()=>{
                  this.getAllPurchase()
                }
              );
            }
  
        }).catch(e => {
            console.log(e);
        });

      }, 500);

    }

    navigateToLink(link){
        
        if(this.$f7.online)
        {
          this.$f7router.navigate(`/${link}/`);
          /*this.$f7.views.main.router.navigate(`/${link}/`);*/
        }

    }

    render(){

       const{isavailable, loaded, purchaselist, droppingpointid, droppingpointname,issmartdroppingpoint,ismount, purchasestartdate, inventoryname, totalqty, totalrecord} = this.state;

       return(
        <FilterConsumer>{(value)=>{
          const{setDroppingPointData}  = value;

          const{singledroppingpoint} = value;

          if(ismount === true)
          {
            setTimeout(()=> {

              if(issmartdroppingpoint === true)
              {
                if((singledroppingpoint.id !== droppingpointid) && droppingpointid > 0)
                {
                  Promise.all([

                    setDroppingPointData(Number(droppingpointid), droppingpointname, false)

                  ])
                  .then(() => {

                    this.setState({

                      issmartdroppingpoint:false,
                    });
                  
                    //this.Dom7('#autocomplete-line-name2').text('Select');
                  })

                }
              }
              else
              {
                if(droppingpointid !== singledroppingpoint.id)
                {
                  this.fetchNewDroppingPoint(singledroppingpoint.id, singledroppingpoint.name);
                }
              }

            }, 500)
            
          }
          return(
                <Page pageContent={false} className="page" name="ManagePurchase">
                    <Navbar sliding={true}>
                        <NavLeft>
                          <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                          <Link href={`/dashboard/`} className="clearmenupadding">
                            <img className="navbarlogo" src={scanlogo} alt="logo" />
                          </Link>
                        </NavLeft>
                        <NavTitle>Purchase</NavTitle>
                        <NavRight className="right-with-menu">
                        <Link onClick={()=>{

                          this.getAllPurchase();
  
                        }} className="smallbox"><Icon f7="arrow_clockwise"/></Link>
                        </NavRight>
                    </Navbar>
                    <PageContent>
                    <Block>
                    <Row noGap >
                      <Col width="100" >  
                      <List className="zeromargin">
                        <ListItem link="#" id="autocomplete-droppingpoint-popup" className="autocomplete-droppingpoint-popup" title="Dropping Point">
                          <div slot="after" id="autocomplete-droppingpoint-filtername2">{droppingpointname}</div>
                        </ListItem>
                        <ListItem link="#" id="autocomplete-stock-popup" className="autocomplete-stock-popup" title="Stock">
                            <div slot="after" id="autocomplete-stock-name2">{inventoryname}</div>
                        </ListItem>
                        <ListInput
                            name="purchasestartdate"
                            id="purchasestartdate"
                            label="Purchase Date"
                            placeholder="Purchase Date"
                            type="date"
                            value={purchasestartdate}
                            onChange={this.handleUserInput}
                            calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                        >
                        </ListInput>
                      </List>
                      </Col>
                  </Row>
                  <br />
                  <Row>
                      <Col width="35">
                      </Col>
                      <Col width="30">
                      <Button fill color="blue" 
                          onClick={()=>{
                              if(this.$f7.online)
                              {
                                this.setState({
                                  hasfilter:true
                                },()=>{
                                  this.getAllPurchase();
                                })
                              }
                          }}
                      >Fetch</Button>
                      </Col>
                      <Col width="35">
                      </Col>
                  </Row>
                      {
                        loaded ? (<Fragment>
                          {
                            isavailable ? (<BlockTitle className="totalrec">Total Records : {totalrecord}</BlockTitle>):null
                          }
                          {
                            isavailable ? (
                              <Fragment>
                                {
                                    purchaselist.map((inventory)=>{

                                    return(<div key={inventory.index}>
                                        {
                                            inventory.purchase ? (<Fragment key={inventory.index}>
                                            <BlockTitle medium style={{textAlign:'center'}}>{inventory.date}</BlockTitle>
                                            <Row noGap className='rowwithbottomborder'>
                                                <Col width='15' className="tableheader textalignleft">
                                                    S.No.
                                                </Col>
                                                <Col width='35' className="tableheader textalignleft">
                                                    Stock
                                                </Col>
                                                <Col width='15' className="tableheader">
                                                    Price
                                                </Col>
                                                <Col width='15' className="tableheader">
                                                    Qty
                                                </Col>
                                                {/*<Col width='15' className="tableheader">
                                                    Rate
                                            </Col>*/}
                                                <Col width='20' className="tableheader">&nbsp;</Col>
                                            </Row>
                                            {
                                                inventory.purchase.map((item)=>{

                                                return(
                                                    <Row key={item.serial} noGap className='rowwithbottomborder'>
                                                        <Col width='15' className="ledgercolumn textalignleft">
                                                        {item.serial}
                                                        </Col>
                                                        <Col width='35' className="ledgercolumn textalignleft">
                                                        {item.name}
                                                        {(()=>{
                                                          if(this.state.hasfilter === true && droppingpointid > 0)
                                                          {
                                                            return null;
                                                          }
                                                          else
                                                          {
                                                            return(
                                                              <div>(Drop Point : {item.droppingpoint})</div>
                                                            );
                                                          }
                                                        })()}
                                                        {
                                                          item.issuedate ? (<div>(Issue Date : {item.issuedate})</div>):null
                                                        }
                                                        </Col>
                                                        <Col width='15' className="ledgercolumn">
                                                        {item.purchaserate}
                                                        </Col>
                                                        <Col width='15' className="ledgercolumn">
                                                        {item.qty}
                                                        </Col>
                                                       {/*<Col width='15' className="ledgercolumn">
                                                        {item.purchaserate}
                                                      </Col>*/}
                                                        <Col width='20' className="ledgercolumn">
                                                        {
                                                          Number(item.purchaseid) > 0 && !this.state.clientdetail.ismanager && !this.state.clientdetail.ishawker && !this.state.clientdetail.islineman ? (
                                                            <Link onClick={() =>{

                                                              this.deleteRecordAction(item.purchaseid);
                                                              
                                                            }}><Icon f7="trash_circle_fill"></Icon></Link>
                                                          ):(<div>&nbsp;</div>)
                                                        }
                                                        </Col>
                                                    </Row>
                                                    );
                                                })
                                            }
                                            </Fragment>):null
                                        }
                                        </div>);
                                    })
                                }
                                <Row noGap className='rowwithbottomborder'>
                                    <Col width='15' className="tableheader textalignleft">
                                      &nbsp;
                                    </Col>
                                    <Col width='35' className="tableheader textalignleft">
                                        <b>Total</b>
                                    </Col>
                                    <Col width='15' className="tableheader">
                                        &nbsp;
                                    </Col>
                                    <Col width='15' className="tableheader">
                                        {totalqty.toString()}
                                    </Col>
                                    <Col width='20' className="tableheader">&nbsp;</Col>
                                </Row>
                              </Fragment>
                            ):(<AppItemNotFound notfoundtext ="No Purchase record found." />)
                          }
                        </Fragment>):(<AppListItemLoading />)
                      }
                      </Block>
                      <br /><br />
                    </PageContent>
                  {/*</PageContent>*/}
                  <AppFav position="center-bottom" text="Add Purchase" icon="plus" url="addpurchase" />
                </Page>
          );
        }}</FilterConsumer>
      )    
    }
}