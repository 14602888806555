import React,{Component} from 'react';
import { CustomerOutstandingConsumer } from '../context/CustomerOutstandingContext';

import {
    Page,
    Popup,
    Navbar,
    NavTitle,
    NavRight,
    Fab,
    Icon,
    Block,
    List,
    ListItem,
    Link,
  } from 'framework7-react';

  export default class CustomerOutstandingFilter extends Component {
    constructor(props) {
      super(props);

      let today = new Date();
      let currmonth = today.getMonth()+1;
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }
      let date = today.getFullYear()+'-'+currmonth+'-'+today.getDate();
  
      this.state = {
        clientdetail:[],
        areaid:'',
        lineid:'',
        linemanid:'',
        hawkerid:'',
        filterarea:'',
        filterline:'',
        filterlineman:'',
        filterhawker:'',
        arealist:[],
        linelist:[],
        linemanlist:[],
        hawkerlist:[],
        hassmartline:false,
        hassmartlineman:false,
        hassmarthawker:false,
        hassmartarea:false,
        circulationdate:date,
        filtervars:[],
      };

      this.areaAutocompletePopup     = this.areaAutocompletePopup.bind(this);
      this.lineAutocompletePopup     = this.lineAutocompletePopup.bind(this);
      this.linemanAutocompletePopup  = this.linemanAutocompletePopup.bind(this);
      this.hawkerAutocompletePopup   = this.hawkerAutocompletePopup.bind(this);
      this.applyFilter               = this.applyFilter.bind(this);
      this.handleUserInput           = this.handleUserInput.bind(this);

    }

    componentDidMount(){

      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query
        }
      },()=>{
        this.areaAutocompletePopup();
        this.lineAutocompletePopup();
        this.hawkerAutocompletePopup();

        this.hideSplashScreen();

        let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker} = this.state.filtervars;

        if(Number(areaid) < 1 || areaid === "NaN" || areaid === "undefined")
        {
          areaid      = "";
          filterarea  = "Select";
        }

        if(Number(lineid) < 1 || lineid === "NaN" || lineid === "undefined")
        {
          lineid      = "";
          filterline  = "Select";
        }
        
        if(Number(hawkerid) < 1 || hawkerid === "NaN" || hawkerid === "undefined")
        {
          hawkerid      = "";
          filterhawker  = "Select";
        }

        this.setState({
          areaid:areaid,
          lineid:lineid,
          hawkerid:hawkerid,
          filterarea:filterarea,
          filterline:filterline,
          filterhawker:filterhawker,
        },()=>{
          this.$$('#autocomplete-area-popup').find('.item-after').text(this.state.filterarea);
          this.$$('#autocomplete-line-popup').find('.item-after').text(this.state.filterline);
          this.$$('#autocomplete-hawker-popup').find('.item-after').text(this.state.filterhawker);
        })

      });

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();
      self.hawkerAutocomplete.destroy();

    }

    areaAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-area-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              areaid:id,
              filterarea:name
            });            
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            $('#autocomplete-line-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              lineid:id,
              filterline:name
            });

          },
        },
      });
    }

    linemanAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      const searchlinemanlist  = this.state.linemanlist;

      self.linemanAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-lineman-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectlineman/',
        requestSourceOnOpen:true,
        source(query, render) {
          const results = [];

          for (let i = 0; i < searchlinemanlist.length; i += 1) {
            if (searchlinemanlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinemanlist[i]);
          }

          render(results);
        },
        on: {
          change(value) {

            $('#autocomplete-lineman-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              linemanid:id,
              filterlineman:name
            });

          },
        },
      });
    }

    hawkerAutocompletePopup(){

      const self  = this;
      const app   = self.$f7;
      const $     = self.$$;
      
      self.hawkerAutocomplete = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-hawker-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selecthawker/',
        requestSourceOnOpen:true,
        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/hawker.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetHawker',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    hawkerlist:res.recordlist,
                  }
                },()=>{

                  const searchhawkerlist  = self.state.hawkerlist;

                  for (let i = 0; i < searchhawkerlist.length; i += 1) {
                    if (searchhawkerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchhawkerlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            $('#autocomplete-hawker-popup').find('.item-after').text(value[0].name);

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "";
            }

            self.setState({
              hawkerid:id,
              filterhawker:name
            });

          },
        },
      });
    }

    applyFilter(){

      if(!this.$f7.online)
      {
        return;
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;
      
      this.setState(()=>{
        return{
          [name]: value
        }
      });

    }

    render(){

      const{lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea} = this.state;

      return(
        <CustomerOutstandingConsumer>{(value)=>{

          const{setNewFilterData}  = value;

            return(
              <Popup className="demo-popup">
                  <Navbar sliding={true}>
                      <NavTitle>Filter</NavTitle>
                      <NavRight>
                        <Link popupClose>Close</Link>
                      </NavRight>
                  </Navbar>
                  <Page className="page" name="BillStatementFilter">
                        <Block>
                        <List noHairlinesMd>
                          <ListItem link="#" id="autocomplete-area-popup" title="Area" after="Select"></ListItem>
                          <ListItem link="#" id="autocomplete-line-popup" title="Line" after="Select"></ListItem>
                          {/*<ListItem link="#" id="autocomplete-lineman-popup" title="Lineman" after="Select"></ListItem>*/}
                          <ListItem link="#" id="autocomplete-hawker-popup" title="Hawker" after="Select"></ListItem>
                        </List>
                    </Block>
                    <br />
                    <Fab position="center-bottom" slot="fixed" text="Apply Filter" onClick={()=>{
                        if(this.$f7.online)
                        {
                          Promise.all([
                            setTimeout(()=> {
              
                              setNewFilterData(lineid, linemanid, hawkerid, filterline, filterlineman, filterhawker, areaid, filterarea, '');
                  
                            }, 500)
                            
                          ])
                          .then(() => {
              
                            setTimeout(()=> {
              
                              this.$$('.popup-close').trigger('click');
                  
                            }, 500)
                            
                          })

                        };
                      }}>
                      <Icon ios="f7:line_horizontal_3_decrease" aurora="f7:line_horizontal_3_decrease" md="material:filter_list"></Icon>
                    </Fab>
                  </Page>
              </Popup>
            );
        }}
        </CustomerOutstandingConsumer>
      );
    }
}