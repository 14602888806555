import React,{Component,Fragment} from 'react';
import AppListItemLoading from '../pages/applistitemloading';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Link,
    Block,
    List,
    Row,
    Col,
    ListItem,
  } from 'framework7-react';

  export default class PaymentTab extends Component {
    constructor(props) {
      super(props);

      this.state = {
        loaded:false,
        effect:'blink',
        recordset:[],
      };
      
      this.navigateToLink = this.navigateToLink.bind(this);
      this.getCustomerPaymentSummary = this.getCustomerPaymentSummary.bind(this);

    }
    
    componentDidMount(){
      
      let clientdetail   = JSON.parse(localStorage.getItem('agency_clientdetail'));

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
        }
      },()=>{

          /*setTimeout(()=> {

            this.Dom7('.elevation-3').addClass('animate-bottom');

          }, 1200);*/
          
          this.hideSplashScreen();
          this.getCustomerPaymentSummary();
      });

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    navigateToLink(link){

      if(this.$f7.online)
      {
        Promise.all([
          
          this._isMounted = false

        ])
        .then(() => {      
          
            this.$f7router.navigate(`/${link}/`);
  
        })

      }

    }

    getCustomerPaymentSummary(){

        const self      = this;
        const app       = self.$f7;

        if(!this.$f7.online)
        {
          return;
        }
  
        const bodyFormData = {
            Mode:'GetCustomerPaymentSummary',
            clientid:this.state.clientdetail.id,
            linemanid:Number(this.state.clientdetail.linemanid),
            ismanager:Number(this.state.clientdetail.ismanager),
            areamanagerid:Number(this.state.clientdetail.areamanagerid),
            areaids:this.state.clientdetail.areaids,
        };

        this.setState({
            loaded:false
        });
  
        // Simulate Ajax Request
        setTimeout(()=> {

          const accesstoken  = localStorage.getItem('agency_accesstoken');
          
          app.request.setup({
            headers:{
              Authorization: `${accesstoken}`
            }
          });

          app.request.promise.post(`${app.passedParams.serverurl}api/report.php?t=${Date.now()}`,bodyFormData)
          .then((res)=> {

              // We got user data from request
      
              // Hide Preloader
              app.preloader.hide();
              
              // Hide Pull To Refresh
              app.ptr.done();
    
              const resdata = JSON.parse(res.data);
    
              if(resdata.success === true)
              {
                //document.querySelector('.elevation-3').style.display = 'block';

                
                this.setState({
                  loaded:true,
                  recordset:resdata.recordset,
                });
              }
              else{
                this.setState({
                  loaded:true,
                  recordset:[],
                });
              }
              setTimeout(()=> {

                this.Dom7('.elevation-3').addClass('animate-bottom');
    
              }, 500);
    
          }).catch(e => {

              console.log(e);

          });
  
        }, 500);
    }    

    render(){
        
      const{loaded, recordset} = this.state;

      return(
            <Page className="page" name="PaymentTab">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Payment Tab</NavTitle>
                  <NavRight>
                  </NavRight>
                </Navbar>
                {
                  loaded ? (
                    <Block>
                        <Row>
                            <Col>
                            <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5" onClick={()=>{
                                if(this.$f7.online)
                                {
                                    this.$f7router.navigate(`/payments/`);
                                }
                                }} style={{color:'#2196f3',paddingTop:'0px'}}>
                                <div style={{fontSize:'1.1rem'}}>{recordset.paymentamount}</div>
                                <div style={{fontSize:'2.0rem',fontWeight:'bold'}}>({recordset.paymentcount})</div>
                                <div style={{fontSize:'1.1rem'}}>Payments</div>
                            </div>
                            </Col>
                            <Col>
                            <div className="elevation-demo elevation-3 elevation-hover-5 elevation-pressed-5" onClick={()=>{
                                if(this.$f7.online)
                                {
                                    this.$f7router.navigate(`/customersoutstanding/`);
                                }
                                }} style={{color:'#2196f3',paddingTop:'0px'}}>
                                <div style={{fontSize:'1.1rem'}}>{recordset.outstandingbalance} ({recordset.outstandingpercentage}%)</div>
                                <div style={{fontSize:'2.0rem',fontWeight:'bold'}}>({recordset.outstandingcount})</div>
                                <div style={{fontSize:'1.1rem'}}>Outstanding</div>
                            </div>
                            </Col>                            
                        </Row>
                        <Row style={{height: '50px'}}>
                            <Col>
                                <Link className="button button-fill color-blue" href="/makepayment/" animate={true} ignoreCache={true}>Make Payment</Link>
                            </Col>
                        </Row>
                        <Row style={{height: '50px'}}>
                            <Col>
                                <Link className="button button-fill color-blue" href="/payments/" animate={true} ignoreCache={true}>Manage Payment History</Link>
                            </Col>
                        </Row>
                        <Row style={{height: '50px'}}>
                            <Col>
                                <Link className="button button-fill color-blue" href="/customersoutstanding/" animate={true} ignoreCache={true}>Make Payment Area wise</Link>
                            </Col>
                        </Row>
                        {
                          recordset.showcurrentmonthinfo ? (<Fragment>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='60' className="ledgercolumn textalignleft">
                                <b>Last Bill Total ({recordset.lastbillgdate})</b>
                              </Col>
                              <Col width='40' className="ledgercolumn" style={{textAlign:'right'}}>
                              Rs. {recordset.lastbilltotalbalance}
                              </Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='60' className="ledgercolumn textalignleft">
                                <b>Total Online ({recordset.totalonlinepaymentpercentage}%)</b>
                              </Col>
                              <Col width='40' className="ledgercolumn" style={{textAlign:'right'}}>
                              Rs. {recordset.totalonlinepayment}
                              </Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder' style={{borderBottom:'0px solid lightgray'}}>
                              <Col width='5' className="ledgercolumn textalignleft">
                              </Col>
                              <Col width='95' className="ledgercolumn textalignleft">
                                <b>Online Payment (Manual) ({recordset.onlinemanualpaymentpercentage}%)</b>
                                &nbsp;- Rs. {recordset.onlinemanualpayment}
                              </Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='5' className="ledgercolumn textalignleft">
                              </Col>
                              <Col width='95' className="ledgercolumn textalignleft">
                                <b>Online Payment (Automatic) ({recordset.onlineautomaticpaymentpercentage}%)</b>
                                &nbsp;- Rs. {recordset.onlineautomaticpayment}
                              </Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='60' className="ledgercolumn textalignleft">
                                <b>Total Cash Payment ({recordset.cashpaymentpercentage}%)</b>
                              </Col>
                              <Col width='40' className="ledgercolumn" style={{textAlign:'right'}}>
                              Rs. {recordset.cashpayment}
                              </Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='60' className="ledgercolumn textalignleft">
                                <b>Discount ({recordset.discountpercentage}%)</b>
                              </Col>
                              <Col width='40' className="ledgercolumn" style={{textAlign:'right'}}>
                              Rs. {recordset.discount}
                              </Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='60' className="ledgercolumn textalignleft">
                                <b>Coupon ({recordset.couponpercentage}%)</b>
                              </Col>
                              <Col width='40' className="ledgercolumn" style={{textAlign:'right'}}>
                              Rs. {recordset.coupon}
                              </Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='60' className="ledgercolumn textalignleft">
                                <b>Payment Received ({recordset.latestpaymentreceivedpercentage}%)</b>
                              </Col>
                              <Col width='40' className="ledgercolumn" style={{textAlign:'right'}}>
                              Rs. {recordset.latestpaymenttotal}
                              </Col>
                            </Row>
                            <Row noGap className='rowwithbottomborder'>
                              <Col width='60' className="ledgercolumn textalignleft">
                                <b>Running Balance ({recordset.remaingpercentage}%)</b>
                              </Col>
                              <Col width='40' className="ledgercolumn" style={{textAlign:'right'}}>
                              Rs. {recordset.runningbalance}
                              </Col>
                            </Row>
                          </Fragment>):``
                        }
                    </Block>
                  ):(<AppListItemLoading />)
                }
            </Page>
      );
    }
}