import React,{Component, Fragment} from 'react';

import {
    BlockTitle,
    Card,
    CardHeader,
    CardContent,
    CardFooter,
    Link,
  } from 'framework7-react';

  export default class AppCardItemLoading extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          effect:'blink',
        };
    }
    
    render(){

        return(
            <Fragment>
                <BlockTitle className={`totalrec recordset skeleton-text skeleton-effect-${this.state.effect}`} style={{width:'50%',margin:'12px auto'}}>xxxxxxxxxxxxxxxxxxxx</BlockTitle>
                <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
                    <CardHeader
                    className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
                    <CardContent>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                    </CardContent>
                    <CardFooter>
                        <Link >xxxxx</Link>
                        <Link >xxxxx</Link>
                        <Link >xxxxx</Link>
                    </CardFooter>
                </Card>
                <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
                    <CardHeader
                    className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
                    <CardContent>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                    </CardContent>
                    <CardFooter>
                        <Link >xxxxx</Link>
                        <Link >xxxxx</Link>
                        <Link >xxxxx</Link>
                    </CardFooter>
                </Card>
                <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
                    <CardHeader
                    className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
                    <CardContent>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                    </CardContent>
                    <CardFooter>
                        <Link >xxxxx</Link>
                        <Link >xxxxx</Link>
                        <Link >xxxxx</Link>
                    </CardFooter>
                </Card>
                <Card className={`waiting recordset skeleton-text skeleton-effect-${this.state.effect}`}>
                    <CardHeader
                    className="date"><div className="item-title">Customer Name</div><div className="item-title">xxxxx</div></CardHeader>
                    <CardContent>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                        <p><b>xxxxx : </b>xxx xxx xxxx</p>
                    </CardContent>
                    <CardFooter>
                        <Link >xxxxx</Link>
                        <Link >xxxxx</Link>
                        <Link >xxxxx</Link>
                    </CardFooter>
                </Card>
            </Fragment>
        );
    }
}