import React,{Component,Fragment} from 'react';
import scanlogo from '../assets/images/scanlogo.png';

import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Icon,
    Block,
    List,
    Row,
    Col,
    ListInput,
    ListItem,
    Button,
    Toggle,
    Toolbar,
    Link,
  } from 'framework7-react';

  export default class MakePayments extends Component {
    constructor(props) {
      super(props);

      let today = new Date();

      let currmonth = today.getMonth()+1;
      if(currmonth < 10)
      {
          currmonth = "0"+currmonth;
      }

      let currdate = today.getDate();
      if(currdate < 10)
      {
          currdate = "0"+currdate;
      }

      let date = today.getFullYear()+'-'+currmonth+'-'+currdate;

      let filtervars  = {};

      if(this.props.$f7route.query)
      {
        let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker, openingbalanceid, filteropeningbalance, nameandphone, page, fromarea} = this.props.$f7route.query;

        if(Number(areaid) < 1 || areaid === "NaN" || areaid === undefined)
        {
          areaid      = "";
          filterarea  = "Select";
        }
  
        if(Number(lineid) < 1 || lineid === "NaN" || lineid === undefined)
        {
          lineid      = "";
          filterline  = "Select";
        }
        
        if(Number(hawkerid) < 1 || hawkerid === "NaN" || hawkerid === undefined)
        {
          hawkerid      = "";
          filterhawker  = "Select";
        }
       
        if(Number(openingbalanceid) < 1 || openingbalanceid === "NaN" || openingbalanceid === "undefined" || openingbalanceid === undefined)
        {
          openingbalanceid      = "";
          filteropeningbalance  = "Select";
        }
  
        if(nameandphone === "" || nameandphone === undefined)
        {
          nameandphone  = "";
        }

        if(Number(page) < 1 || page === "NaN" || page === undefined)
        {
          page      = "";
        }

        if(fromarea === "" || fromarea === undefined)
        {
          fromarea  = "";
        }

        filtervars  = {areaid:areaid, filterarea:filterarea, lineid:lineid, filterline:filterline, hawkerid:hawkerid, filterhawker:filterhawker, openingbalanceid:openingbalanceid, filteropeningbalance:filteropeningbalance, nameandphone:nameandphone, page:page, fromarea:fromarea};
      }

      this.state = {
        clientdetail:[],
        paymentamount:'',
        paymentrecordid:'',
        detail:[],
        customerlist:[],
        areaid:filtervars.areaid,
        lineid:filtervars.lineid,
        recordid:'',
        filterarea:filtervars.filterarea,
        filterline:filtervars.filterline,
        customername:'Select',
        hassmartcustomer:false,
        toolbarPosition:'bottom',
        paymentmethod:true,
        discount:'',
        coupon:'',
        remark:'',
        paymentdate:date,
        hasreceiptbyautocomplete:false,
        receipttype:'',
        receiptbyid:'',
        receiptbyname:'Select',
        orgreceiptbyname:'',
        receiptbylist:[],
        filtervars:[],
        cansendsms:true,
        canprintinvoice:true,
        ispaymentadding:false,
      };

      this.handleUserInput            = this.handleUserInput.bind(this);
      /*this.setPaymentData           = this.setPaymentData.bind(this);*/
      this.AddPaymentToDB             = this.AddPaymentToDB.bind(this);
      this.getDetail                  = this.getDetail.bind(this);
      this.areaAutocompletePopup      = this.areaAutocompletePopup.bind(this);
      this.lineAutocompletePopup      = this.lineAutocompletePopup.bind(this);
      this.customerAutocompletePopup  = this.customerAutocompletePopup.bind(this);
      this.receiptByAutocompletePopup = this.receiptByAutocompletePopup.bind(this);
      this.refreshOutstanding         = this.refreshOutstanding.bind(this);
    }

    componentDidMount(){

      let clientdetail    = JSON.parse(localStorage.getItem('agency_clientdetail'));
      let receiptbydetail = JSON.parse(localStorage.getItem('agency_selected_receiptby'));

      let receipttype = "", receiptbyid = "", receiptbyname = "", orgreceiptbyname = "";

      if(receiptbydetail !== null && receiptbydetail !== undefined)
      {
        receipttype = receiptbydetail.receipttype, receiptbyid = receiptbydetail.receiptbyid, receiptbyname = receiptbydetail.receiptbyname, orgreceiptbyname = receiptbydetail.orgreceiptbyname;
      }

      this.setState(()=>{
        return{
          clientdetail:clientdetail,
          filtervars:this.props.$f7route.query,
          receipttype:receipttype,
          receiptbyid:receiptbyid,
          receiptbyname:receiptbyname,
          orgreceiptbyname:orgreceiptbyname,
        }
      },()=>{

        this.areaAutocompletePopup();
        this.lineAutocompletePopup();
        this.customerAutocompletePopup();

        if(Number(this.props.f7route.params.id) > 0)
        {
          this.setState(()=>{
            return{
              recordid:this.props.f7route.params.id
            }
          },()=>{
            this.getDetail();
          })
        }

      });

      this.hideSplashScreen();

    }

    componentwillunmount(){

      this.onPageBeforeRemove();

    }

    onPageBeforeRemove(){

      const self = this;
      self.autocompleteAreaPopup.destroy();
      self.autocompleteLinePopup.destroy();
      self.autocompleteCustomerPopup.destroy();

      if(self.state.hasreceiptbyautocomplete === true)
      {
        self.setState({
          hasreceiptbyautocomplete:false
        },()=>{
          self.autocompleteReceiptbyPopup.destroy();
        })
      }

    }

    hideSplashScreen(){
      document.getElementById("splash-screen").style.visibility = "hidden";
    }

    areaAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteAreaPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-area-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectarea/',
        requestSourceOnOpen:true,
        source(query, render) {

          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/area.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetArea',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              ismanager:Number(self.state.clientdetail.ismanager),
              areamanagerid:Number(self.state.clientdetail.areamanagerid),
              areaids:self.state.clientdetail.areaids,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    arealist:res.recordlist,
                  }
                },()=>{

                  const searcharealist  = self.state.arealist;

                  for (let i = 0; i < searcharealist.length; i += 1) {
                    if (searcharealist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searcharealist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "Select";
            }

            self.setState({
              areaid:id,
              lineid:'',
              recordid:'',
              filterarea:name,
              filterline:'Select',
              customername:'Select',
              linelist:[],
              customerlist:[],
              receipttype:'',
              receiptbyid:'',
              receiptbyname:'Select',
              orgreceiptbyname:'',
              receiptbylist:[],
            },()=>{
              if(self.state.hasreceiptbyautocomplete === true)
              {
                self.setState({
                  hasreceiptbyautocomplete:false
                },()=>{
                  self.autocompleteReceiptbyPopup.destroy();
                })
              }
            });
          },
        },
      });
    }

    lineAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteLinePopup = app.autocomplete.create({

        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        typeahead: true,
        openerEl: '#autocomplete-line-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectline/',
        requestSourceOnOpen:true,

        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/line.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetLine',
              type:'customerfilter',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    linelist:res.recordlist,
                  }
                },()=>{

                  const searchlinelist  = self.state.linelist;

                  for (let i = 0; i < searchlinelist.length; i += 1) {
                    if (searchlinelist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchlinelist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {

            let id    = Number(value[0].id);
            let name  = value[0].name;

            if(id < 1)
            {
              name  = "Select";
            }

            self.setState({
              lineid:id,
              recordid:'',
              filterline:name,
              customername:'Select',
              customerlist:[],
              receipttype:'',
              receiptbyid:'',
              receiptbyname:'Select',
              orgreceiptbyname:'',
              receiptbylist:[],
            },()=>{
              if(self.state.hasreceiptbyautocomplete === true)
              {
                self.setState({
                  hasreceiptbyautocomplete:false
                },()=>{
                  self.autocompleteReceiptbyPopup.destroy();
                })
              }
            });

          },
        },
      });
    }

    customerAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteCustomerPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-customer-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectcustomer/',
        requestSourceOnOpen:true,
        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetAllSubscribeCustomer',
              type:'subscribecustomer',
              ordertype:'sequence',
              clientid:self.state.clientdetail.id,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    customerlist:res.recordlist,
                  }
                },()=>{

                  const searchcustomerlist  = self.state.customerlist;

                  for (let i = 0; i < searchcustomerlist.length; i += 1) {
                    if (searchcustomerlist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchcustomerlist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            self.setState(()=>{
              return{
                recordid:value[0].id,
                customername:value[0].name,
                receipttype:'',
                receiptbyid:'',
                receiptbyname:'Select',
                orgreceiptbyname:'',
                receiptbylist:[],
              }
            },()=>{

              if(self.state.hasreceiptbyautocomplete === true)
              {
                self.setState({
                  hasreceiptbyautocomplete:false
                },()=>{
                  self.autocompleteReceiptbyPopup.destroy();
                })
              }
              self.getDetail();

            })

          },
        },
      });
    }

    receiptByAutocompletePopup(){

      const self = this;
      const app = self.$f7;
      const $ = self.$$;

      self.autocompleteReceiptbyPopup = app.autocomplete.create({
        openIn: 'popup',
        valueProperty: 'name',
        textProperty: 'name',
        openerEl: '#autocomplete-receiptby-popup a',
        closeOnSelect: true,
        routableModals:true,
        url:'selectreceiptby/',
        requestSourceOnOpen:true,
        source(query, render) {
          const autocomplete = this;
          const results = [];

          app.preloader.show();

          const accesstoken  = localStorage.getItem('agency_accesstoken');

          app.request({
            
            method: 'POST',
            dataType: 'json',
            url: `${app.passedParams.serverurl}api/clientlist.php?t=${Date.now()}`,
            headers: {
                Authorization: `${accesstoken}`
            },
            data: {
              Mode:'GetAvailableStaffList',
              clientid:self.state.clientdetail.id,
              customerid:self.state.recordid,
              areaid:self.state.areaid,
              lineid:self.state.lineid,
            },
            success(res) {
            // Find matched items

              if(res.success === true)
              {
                self.setState(()=>{
                  return{
                    receiptbylist:res.recordlist,
                  }
                },()=>{

                  const searchreceiptbylist  = self.state.receiptbylist;

                  for (let i = 0; i < searchreceiptbylist.length; i += 1) {
                    if (searchreceiptbylist[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(searchreceiptbylist[i]);
                  }
                  
                })
              }

              // Hide Preoloader
              app.preloader.hide();
              // Render items by passing array with result items
              render(results);
            },
          });
        },
        on: {
          change(value) {
            
            self.setState(()=>{
              return{
                receipttype:value[0].type,
                receiptbyid:value[0].id,
                receiptbyname:value[0].name,
                orgreceiptbyname:value[0].orgname,
              }
            },()=>{

              const { receipttype, receiptbyid, receiptbyname, orgreceiptbyname } = self.state;

              let receiptbydetail = {"receipttype":receipttype, "receiptbyid":receiptbyid, "receiptbyname":receiptbyname, "orgreceiptbyname":orgreceiptbyname};

              localStorage.setItem("agency_selected_receiptby",JSON.stringify(receiptbydetail));

            })

          },
        },
      });
    }

    handleUserInput(e){

      const name  = e.target.name;
      const value = e.target.value;

      if(name === 'paymentmethod')
      {
        this.setState({paymentmethod:!this.state.paymentmethod});
      }
      else if(name === 'cansendsms')
      {
        this.setState({cansendsms:!this.state.cansendsms});
      }
      else if(name === 'canprintinvoice')
      {
        this.setState({canprintinvoice:!this.state.canprintinvoice});
      }
      else
      {
        this.setState(()=>{
          return{
            [name]: value
          }
        },()=>{

            /*this.setPaymentData(name, value);*/

        });
      }

    }

    getDetail(){

      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      const bodyFormData = {
        Mode:'GetCustomerDetail',
        clientid:self.state.clientdetail.id,
        recordid:self.state.recordid,
      };

      app.preloader.show();

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

        app.preloader.hide();

        const resdata = JSON.parse(res.data);

        if(resdata.success === true)
        {
          this.setState(()=>{
            return{
              areaid:Number(resdata.customerdetail.areaid),
              lineid:Number(resdata.customerdetail.lineid),
              filterarea:resdata.customerdetail.areaname,
              filterline:resdata.customerdetail.linename,
              detail:resdata.customerdetail,
              isptr:false,
              isdetailfetched:true,
              hasreceiptbyautocomplete:true,
              canprintinvoice:resdata.customerdetail.canprintinvoice,
            }
          },()=>{
            if(Number(this.props.f7route.params.id) > 0)
            {
              this.setState({
                customername:"#"+this.state.detail.customerid+" "+this.state.detail.name
              });
              /*this.$$('#autocomplete-customer-popup').find('.item-title').text();*/
            }
            this.receiptByAutocompletePopup();
            this.refreshOutstanding();
          });
        }
        else
        {
          this.setState(()=>{
            return{

              isdetailfetched:true,

            }
          });
        }

      }).catch(e => {
        console.log(e);
      });
    }

    AddPaymentToDB(){

      const self      = this;
      const app       = self.$f7;
      const $$        = self.Dom7;

      let errmsg      = "";

      if(this.state.ispaymentadding)
      {
        errmsg  = "Please wait, Another payment is processing";

        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      if(!this.$f7.online)
      {        
        return;
      }
      
      app.input.validateInputs('.adddata');

      if(this.state.recordid === "" && errmsg === "")
      {
        errmsg  = "Please select a customer";
      }

      if((this.state.receiptbyid === "" || this.state.receiptbyid === undefined) && errmsg === "")
      {
        errmsg  = "Please select a receipt by";
      }

      if(this.state.receiptbyid === "" || this.state.receiptbyid === undefined)
      {
        this.$$('.autocomplete-receiptby-popup').addClass('error');
      }
      else
      {
        this.$$('.autocomplete-receiptby-popup').removeClass('error');
      }

      if(this.state.paymentamount === "" && errmsg === "")
      {
        errmsg  = "Please enter amount paid";
      }

      const{showsendsmstoggle} = this.state.detail;

      let totaldiscount = Number(self.state.coupon) + Number(self.state.discount);

      if(!showsendsmstoggle)
      {
        if(totaldiscount >= 50)
        {
          errmsg  = "Sorry, discount not allowed for more than 50 rupee";
        }
      }

      if(errmsg !== "")
      {
        const toastWithButton = app.toast.create({
          text: errmsg,
          closeButton: true,
          closeTimeout: 3000,
        });

        toastWithButton.open();
  
        return false;
      }

      this.setState(()=>{
        return{
          ispaymentadding:true
        }
      },()=>{
        const bodyFormData = {
          Mode:'AddCustomerPayment',
          clientid:self.state.clientdetail.id,
          recordid:self.state.recordid,
          paymentamount:self.state.paymentamount,
          paymentmethod:Number(self.state.paymentmethod),
          discount:self.state.discount,
          coupon:self.state.coupon,
          remark:self.state.remark,
          paymentdate:self.state.paymentdate,
          receipttype:self.state.receipttype,
          receiptbyid:self.state.receiptbyid,
          receiptbyname:self.state.orgreceiptbyname,
          cansendsms:Number(self.state.cansendsms),
          canprintinvoice:Number(self.state.canprintinvoice),
        };

        const accesstoken  = localStorage.getItem('agency_accesstoken');
            
        app.request.setup({
          headers:{
            Authorization: `${accesstoken}`
          }
        });

        app.request.promise.post(`${app.passedParams.serverurl}api/payments.php?t=${Date.now()}`,bodyFormData)
        .then((res)=> {

          this.setState(()=>{
            return{
              ispaymentadding:false
            }
          })

          // Hide Pull To Refresh
          app.ptr.done();

          const resdata = JSON.parse(res.data);

          const toastWithButton = app.toast.create({
            text: resdata.msg,
            closeButton:true,
            closeTimeout:3000,
          });

          if(resdata.success !== true)
          {
            toastWithButton.open();
          }

          if(resdata.success === true)
          {
            Promise.all([
              this.setState(()=>{
                return{
                  paymentamount:'',
                  discount:'',
                  coupon:'',
                  remark:'',
                }
              })
            ])
            .then(() => {

              $$('.item-content').removeClass('item-input-with-error-message');
              $$('.item-content').removeClass('item-input-invalid');

              let{areaid, filterarea, lineid, filterline, hawkerid, filterhawker, fromarea} = this.state.filtervars;

              if(Number(areaid) < 1 || areaid === "NaN")
              {
                areaid      = "";
                filterarea  = "";
              }

              if(Number(lineid) < 1 || lineid === "NaN")
              {
                lineid      = "";
                filterline  = "";
              }
              
              if(Number(hawkerid) < 1 || hawkerid === "NaN")
              {
                hawkerid      = "";
                filterhawker  = "";
              }

              if(fromarea === undefined)
              {
                fromarea  = "";
              }

              this.$f7.views.main.router.navigate(`thanks/addpaymentsuccess/?areaid=${areaid}&filterarea=${filterarea}&lineid=${lineid}&filterline=${filterline}&hawkerid=${hawkerid}&filterhawker=${filterhawker}&fromarea=${fromarea}`);

            })
          }
        }).catch(e => {
            console.log(e);
        });
      })
    }

    clearFormData(){

      const $$    = this.Dom7;

      Promise.all([
        this.setState(()=>{
          return{
            paymentamount:'',
            discount:'',
            coupon:'',
            remark:'',
          }
        })
      ])
      .then(() => {

        $$('.item-content').removeClass('item-input-with-error-message');
        $$('.item-content').removeClass('item-input-invalid');

      })
    }

    refreshOutstanding(){
      const self      = this;
      const app       = self.$f7;

      if(!this.$f7.online)
      {
        return;
      }

      if(this.state.isptr !== true)
      {
        app.preloader.show();
      }

      const bodyFormData = {
        Mode:'UpdateCustomerOutstanding',
        recordid:self.state.recordid,
      };

      app.preloader.show();

      const accesstoken  = localStorage.getItem('agency_accesstoken');
        
      app.request.setup({
        headers:{
          Authorization: `${accesstoken}`
        }
      });

      app.request.promise.post(`${app.passedParams.serverurl}api/customer.php?t=${Date.now()}`,bodyFormData)
      .then((res)=> {

        app.preloader.hide();

        const resdata = JSON.parse(res.data);

        if(resdata.success === true)
        {
          let tempdetail  = {...this.state.detail, outstanding:resdata.outstanding};
          this.setState(()=>{
            return{
              detail:tempdetail,
            }
          });
        }

      }).catch(e => {
        console.log(e);
      });
    }

    render(){
       
      /*const{name,phone, customerid} = this.props.persondata;*/
      
      const{customername, paymentamount, paymentmethod, discount, coupon, remark, toolbarPosition, paymentdate, filterarea, filterline, recordid, receiptbyname, canprintinvoice, cansendsms} = this.state;
      const{name, phone, customerid, outstanding, latestpayment, latestpaymentdate, inventorylist, showsendsmstoggle, minimumpaymentdate, maximumpaymentdate} = this.state.detail;

      return(
              <Page className="page" name="MakePayments">
                <Navbar sliding={true}>
                  <NavLeft>
                    <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="left" className="smallbox"/>
                    <Link href={`/dashboard/`} className="clearmenupadding">
                      <img className="navbarlogo" src={scanlogo} alt="logo" />
                    </Link>
                  </NavLeft>
                  <NavTitle>Make Payment</NavTitle>
                  <NavRight>
                  </NavRight>
                </Navbar>
                <Block>
                <List noHairlinesMd className="zeromargin">
                  <ListItem link="#" id="autocomplete-area-popup" title="Area" after={filterarea}></ListItem>
                  <ListItem link="#" id="autocomplete-line-popup" title="Line" after={filterline}></ListItem>
                  <ListItem link="#" id="autocomplete-customer-popup" title="Customer" after={customername}></ListItem>
                </List>
                {
                  Number(customerid) && Number(recordid) ? (
                    <Fragment >
                      <Row style={{paddingLeft:'15px',paddingRight:'15px'}}>
                        <Col width="40"><b>Customer ID</b></Col>
                        <Col width="60">#{customerid} (
                          <Link onClick={()=>{
                              if(this.$f7.online)
                              {
                                this.$f7router.navigate(`/editcustomer/${recordid}/`);
                              }
                            }}>Edit</Link>
                          )</Col>
                      </Row>
                      <Row style={{paddingLeft:'15px',paddingRight:'15px'}}>
                        <Col width="40"><b>Name</b></Col>
                        <Col width="60">{name}</Col>
                      </Row>
                      <Row style={{paddingLeft:'15px',paddingRight:'15px'}}>
                        <Col width="40"><b>Phone</b></Col>
                        <Col width="60">{phone}</Col>
                      </Row>
                      <Row style={{paddingLeft:'15px',paddingRight:'15px'}}>
                        <Col width="40"><b>Latest Payment</b></Col>
                        <Col width="60">{
                          latestpayment ? (<span>{latestpayment}<br/></span>):(<span>0.00</span>)
                        }</Col>
                      </Row>
                      <Row style={{paddingLeft:'15px',paddingRight:'15px'}}>
                        <Col width="40"><b>Payment Date</b></Col>
                        <Col width="60">{
                          latestpaymentdate ? (<span>{latestpaymentdate}<br/></span>):(<span>---</span>)
                        }</Col>
                      </Row>
                      <Row style={{paddingLeft:'15px',paddingRight:'15px'}}>
                        <Col width="40" style={{alignItems:'center'}}><b>Outstanding</b></Col>
                        <Col width="60" style={{display:'flex',alignItems:'center'}}>{outstanding}&nbsp;(<Link onClick={()=>{if(this.$f7.online){this.$f7router.navigate(`viewledger/${recordid}/`);}}}>Ledger</Link>)&nbsp;(<Link onClick={()=>{this.refreshOutstanding();}} className="smallbox">Refresh</Link>)</Col>
                      </Row>
                      <Row style={{paddingLeft:'15px',paddingRight:'15px'}}>
                        <Col width="40"><b>Subscription</b></Col>
                        <Col width="60" className='stockitemlist'>{inventorylist && inventorylist.map((inventory, index)=>{
                          return(<span key={index} className='stockname'>{inventory.name}{Number(inventory.quantity) > 1 ?<span style={{color:'#ff0000'}}> x {inventory.quantity}</span>:null}</span>)
                        })} (<Link onClick={()=>{if(this.$f7.online){this.$f7router.navigate(`/subscription/${recordid}/?accessarea=makepayment`);
                              }
                            }}>Edit Subscription</Link>
                          )</Col>
                      </Row>
                      <List noHairlinesMd className="zeromargin">
                        <ListItem link="#" id="autocomplete-receiptby-popup" className="autocomplete-receiptby-popup" title="Receipt By" after={receiptbyname}></ListItem>
                        <ListInput
                          name="paymentdate"
                          id="calendar-paymentdate-input"
                          label="Date"
                          placeholder="Date"
                          type="date"
                          value={paymentdate}
                          onChange={this.handleUserInput}
                          calendarParams={{dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric'},closeOnSelect:true}}
                          min={`${minimumpaymentdate}`} max={`${maximumpaymentdate}`}
                        >
                        </ListInput>
                        <ListInput
                          name="paymentamount"
                          className="adddata"
                          label="Amount Paid"
                          floatingLabel
                          type="number"
                          onInput={this.handleUserInput}
                          placeholder="Amount Paid"
                          value={paymentamount}
                          autofocus ={true}
                          clearButton required
                        >
                        </ListInput>
                      </List>
                        <p style={{marginLeft:'15px'}}>Payment Method</p>
                        <Row noGap>
                            <Col width="40">
                              <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListItem radio value="1" name="paymentmethod" checked={Number(paymentmethod) === 1} title="Cash"
                                onChange={this.handleUserInput}
                                ></ListItem>
                              </List>
                            </Col>
                            <Col width="60">
                              <List className="logintype" style={{marginBottom:'5px',marginTop:'5px'}}>
                                <ListItem radio value="0" name="paymentmethod" checked={Number(paymentmethod) === 0} title="Online Wallet"
                                onChange={this.handleUserInput}
                                ></ListItem>
                              </List>
                            </Col>
                            <Col width="15"></Col>
                        </Row>
                      <List noHairlinesMd className="zeromargin">
                        <ListInput
                          name="discount"
                          label="Discount (If Any)"
                          floatingLabel
                          type="number"
                          onChange={this.handleUserInput}
                          value={discount}
                          placeholder="Discount (If Any)"
                          clearButton
                        >
                        </ListInput>
                        <ListInput
                          name="coupon"
                          label="Coupon (If Any)"
                          floatingLabel
                          type="number"
                          onChange={this.handleUserInput}
                          value={coupon}
                          placeholder="Coupon (If Any)"
                          clearButton
                        >
                        </ListInput>
                        <ListInput
                          name="remark"
                          label="Remark"
                          floatingLabel
                          type="textarea"
                          onInput={this.handleUserInput}
                          value={remark}
                          resizable
                          placeholder="Remark"
                          clearButton
                        >
                        </ListInput>
                        <ListItem title="Bill Printing">
                          <Toggle slot="after" name="canprintinvoice" checked={Number(canprintinvoice) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                    {
                      showsendsmstoggle ? (
                        <ListItem title="Send SMS ?">
                          <Toggle slot="after" name="cansendsms" checked={Number(cansendsms) === 1} value="1" onChange={this.handleUserInput}></Toggle>
                        </ListItem>
                      ):null
                    }
                        {/*<ListInput
                        name="paymentamount"
                        label="Enter Amount to pay"
                        floatingLabel
                        type="number"
                        onChange={this.handleUserInput}
                        value={paymentamount}
                        placeholder="Enter Payment amount"
                        autofocus ={true}
                        >
                        </ListInput>*/}
                      </List>
                      <br /><br />
                    </Fragment>
                  ):(<div className="errmsg">Please select a customer</div>)
                }
                </Block>
                {
                  Number(customerid) && Number(recordid) ? (
                    <Toolbar position={toolbarPosition}>
                      <Row style={{width:'100%'}}>
                          <Col width="60"></Col>
                          <Col width="40"><Button fill color="blue" style={{marginRight:'.4rem'}}
                          onClick={()=>{
                            if(this.$f7.online)
                            {
                              this.AddPaymentToDB();
                            }
                          }}
                          ><Icon f7="floppy_disk" style={{display:'inline-block'}}></Icon>&nbsp;&nbsp;Save</Button></Col>
                      </Row>
                    </Toolbar>
                  ):null
                }
              </Page>
      )
    }
}